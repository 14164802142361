import { IMAGES_LINK } from "../../../global/api/api_links";

import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  pictureContainer: {},
});

export const BuildingImg = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Box className={classes.pictureContainer}>
        <img
          style={{ maxWidth: 400 }}
          src={IMAGES_LINK + `/homepage/hero/${"building"}.svg`}
          alt="filter-icon"
        />
      </Box>
    </Grid>
  );
};
