import React, { useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Grid from "@material-ui/core/Grid";
import { hexToRgbA } from "../../services/helpers";
import MortgageLineChart from "./MortgageLineChart";
import MortgageDonutChart from "./MortgageDonutChart";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  darkElements,
  darkMortgageColors,
  mortgageDonutColors,
} from "../../services/colors";
import BarLeverageRate from "./BarLeverageRate";
import { useIsMobileScreen } from "common-components";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import TilesContainer from "../../components/TilesContainer";
import InputMortgageDetails from "./InputMortgageDetails";
import MortgageResultRow from "./MortgageResultRow";
import AskFundBox from "../../components/AskFundBox";
import { PropertyIdContext } from "../../context/PropertyIdContext";
import { doFetch } from "common-components";
import { LG_PLUS_FONT } from "../../services/fonts";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core";
import DemoExample from "../../components/DemoExample";
import { useIsIncludes } from "common-components";
import { useNotistack } from "common-components";
import { LoadingCharts, SPACING_FACTOR } from "../../components/layout/Layout";
import { OpenAccessContext } from "../../context/OpenAccessContext";
import { useTranslation } from "react-i18next";
import TitleGrid from "../../components/TitleGrid";
import { DirectionContext } from "../../context/DirectionContext";

export const useStylesFontMortgage = makeStyles({
  resultHeader: (props) => ({
    padding: props.paddingLeft ? "10px 0px" : "10px 20px",
    display: "flex",
    justifyContent: "flex-start",
    fontSize: LG_PLUS_FONT,
    whiteSpace: "nowrap",
    margin: 0,
  }),
});

function creatingLine(dataObj, years, colors, dataLabels, gradientColors) {
  const labelYears = [];
  let year = 0;
  while (year < years) {
    let date = new Date().getFullYear();
    labelYears.push((date + year).toString());
    year++;
  }
  labelYears.push("");

  const data = {};
  data.labels = labelYears;
  const datasets = [];
  let index = 0;
  for (const [key, value] of Object.entries(dataObj)) {
    const obj = {
      label: dataLabels[index],
      yAxisID: dataLabels[index] === "יתרת קרן" ? key : "mixedData",
      type: dataLabels[index] !== "יתרת קרן" ? "bar" : "",
      barThickness: dataLabels[index] !== "יתרת קרן" ? "flex" : "",
      fill: true,
      lineTension: 0.1,
      backgroundColor:
        dataLabels[index] !== "יתרת קרן"
          ? hexToRgbA(colors[index], 1)
          : function (context) {
              const chart = context.chart;
              const { ctx } = chart;

              const gradient = ctx.createLinearGradient(0, 0, 0, 500);
              gradient.addColorStop(0, gradientColors[0]);
              gradient.addColorStop(1, gradientColors[1]);

              return gradient;
            },
      borderColor: colors[index],
      borderCapStyle: "round",
      borderDash: [1],
      borderWidth: dataLabels[index] !== "יתרת קרן" ? 1 : 2,
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: colors[index],
      pointBackgroundColor: colors[index],
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBackgroundColor: colors[index],
      pointHoverBorderColor: colors[index],
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: value.map((num) => Number(num.toFixed(0))),
    };
    datasets.push(obj);
    index++;
  }

  data.datasets = datasets;

  return data;
}
function dataDoughnut(labels, data1, data2, isDarkTheme) {
  const darkColors = darkMortgageColors.slice(0, 2);
  const colors = mortgageDonutColors.slice(0, 2);
  const darkHoverColors = darkMortgageColors
    .map((item) => hexToRgbA(item, 0.7))
    .slice(0, 2);
  const hoverColors = mortgageDonutColors
    .map((item) => hexToRgbA(item, 0.7))
    .slice(0, 2);
  return {
    labels: labels,
    datasets: [
      {
        data: [data1 ? Number(data1) : 0, data2 ? Number(data2) : 100],
        backgroundColor: isDarkTheme ? darkColors : colors,
        hoverBackgroundColor: isDarkTheme ? darkHoverColors : hoverColors,
        borderWidth: 1,
        borderColor: isDarkTheme ? darkElements : "#fff",
      },
    ],
  };
}

const getMaxPeriod = (paths) => {
  let maxPeriod = 0;
  for (let path of paths) {
    if (path.period > maxPeriod) maxPeriod = path.period;
  }
  return maxPeriod;
};

// calculate the different mortgage paths and total mortgage.
function calculateMortgagePath(mortgagePrincipal, rate, inflation, years) {
  /* Mortgage formula = [P*r*(1+r)^n] / [(1+r)^n - 1]
  Where:
  P = Principal or money lent by the bank)
  r = rate according to number of payments
  n = number of payments. */
  const payments = [];
  const interests = [];
  const refunds = [];
  let balances = [];
  let maxPayment = 0;
  let totalPayment = 0;
  let firstMonthPayment = 0;
  balances.push(mortgagePrincipal);

  for (let i = 0; i < years * 12; i++) {
    let interestMonth = balances[i] * (1 + inflation / 12) * (rate / 12);
    let paymentMonth = rate
      ? (((balances[i] * (1 + inflation / 12) * rate) / 12) *
          Math.pow(1 + rate / 12, 12 * years - i)) /
        (Math.pow(1 + rate / 12, 12 * years - i) - 1)
      : (balances[i] * (1 + inflation / 12)) / (12 * years - i);
    let balanceMonth =
      balances[i] * (1 + inflation / 12) - (paymentMonth - interestMonth);
    let refundMonth = paymentMonth - interestMonth;

    if (paymentMonth > maxPayment) {
      maxPayment = paymentMonth;
    }
    totalPayment += paymentMonth;

    interests.push(interestMonth);
    payments.push(paymentMonth);
    balances.push(balanceMonth);
    refunds.push(refundMonth);
  }
  firstMonthPayment = payments[0];
  return {
    totalPayment,
    maxPayment,
    interests,
    refunds,
    payments,
    balances,
    firstMonthPayment,
  };
}

// Because each path can have different periods of time to return the prime we need a  function to sum values of many arrays with different lengths (https://www.codegrepper.com/code-examples/javascript/sum+of+two+array+in+javascript)
function sumArrays(a, b) {
  const c = [];
  for (let i = 0; i < Math.max(a.length, b.length); i++) {
    c.push((a[i] || 0) + (b[i] || 0));
  }
  return c;
}

function prepareMortgageData(paths) {
  let totalPrincipal = 0;
  let firstMonthPayment = 0;
  let firstMonthInterest = 0;
  let maxPayment = 0;
  let totalPayment = 0;
  let totalRefund = 0;
  let payments = [];
  let interests = [];
  let balances = [];
  let refunds = [];

  for (let path of paths) {
    let mortgagePathData = calculateMortgagePath(
      path.principal,
      path.rate / 100,
      path.inflation / 100,
      path.period
    );
    totalPayment += mortgagePathData.totalPayment;
    payments = sumArrays(payments, mortgagePathData.payments);
    interests = sumArrays(interests, mortgagePathData.interests);
    balances = sumArrays(balances, mortgagePathData.balances);
    refunds = sumArrays(refunds, mortgagePathData.refunds);
    totalPrincipal += path.principal;
  }
  firstMonthPayment = payments[0];
  firstMonthInterest = interests[0];
  maxPayment = Math.max(...payments);
  totalRefund +=
    ((firstMonthPayment - firstMonthInterest) / firstMonthPayment) * 100;

  return {
    totalPayment,
    maxPayment,
    interests,
    payments,
    balances,
    refunds,
    firstMonthPayment,
    totalPrincipal,
    totalRefund,
  };
}

function dataPerMonth(balance) {
  const res = [];
  res.push(balance[0]);
  for (let i = 11; i < balance.length; i += 12) {
    res.push(balance[i]);
  }
  return res;
}

function dataAccumulated(arr, years) {
  let month = years * 12;
  let monthEnd = 12;
  let monthStart = 0;
  const res = [0];
  let accumulated = 0;
  while (month > 0) {
    const arrPart = arr.slice(monthStart, monthEnd);
    accumulated += arrPart.reduce((item1, item2) => item1 + item2);
    res.push(accumulated);
    monthEnd += 12;
    monthStart += 12;
    month -= 12;
  }
  return res;
}

function creatingData(paths) {
  const mortgageData = prepareMortgageData(paths);
  const { balances, interests, payments, refunds } = mortgageData;
  const maxPeriod = getMaxPeriod(paths);
  const dataBalance = dataPerMonth(balances);
  const dataInterest = dataAccumulated(interests, maxPeriod);
  const dataRefunds = dataAccumulated(refunds, maxPeriod);
  return { dataBalance, dataInterest, dataRefunds };
}

const MortgageCalculatorChart = ({ colors, rawData, cv }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState([
    {
      key: uuidv4(),
      path: t("Mortgage.paths.options.selectPath"),
      principal: 0,
      rate: 0,
      inflation: 0,
      period: 0,
    },
  ]);
  const [mortgagePurpose, setMortgagePurpose] = useState(" ");
  const [firstMonthPayment, setFirstMonthPayment] = useState(undefined);
  const [maximumPayment, setMaximumPayment] = useState(undefined);
  const [totalPrincipal, setTotalPrincipal] = useState(0);
  const [errorsPrincipal, setErrorsPrincipal] = useState([]);
  const [totalPayment, setTotalPayment] = useState(undefined);
  const [totalMortgage, setTotalMortgage] = useState(undefined);
  const [maxPeriod, setMaxPeriod] = useState(undefined);
  const [lineChartData, setLineChartData] = useState(undefined);
  const [, setData] = useState([]);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const pallet = [
    {
      color: colors[0],
      label: t("Mortgage.LineGraph.balance"),
    },
    {
      color: colors[1],
      label: t("Mortgage.LineGraph.fundPayment"),
    },
    {
      color: colors[2],
      label: t("Mortgage.LineGraph.interestPayment"),
    },
  ];
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [ratioPayment, setRatioPayment] = useState(undefined);
  const [fundRefund, setFundRefund] = useState(undefined);
  const [dataDonut, setDataDonut] = useState(
    dataDoughnut(["יתרת", "תשלום"], totalPayment, totalPrincipal, isDarkTheme)
  );

  const [isLoading, setIsLoading] = useState(false);
  const isDemo = useIsIncludes("demo");
  const isMobileScreen = useIsMobileScreen("xs");
  const [propertyId] = useContext(PropertyIdContext);
  const percentageOfCV =
    mortgagePurpose === t("Mortgage.paths.options.firstApartment")
      ? 0.75
      : mortgagePurpose === t("Mortgage.paths.options.housingImprovement")
      ? 0.7
      : mortgagePurpose === t("Mortgage.paths.options.anotherApartment")
      ? 0.5
      : 0;

  const MAX_MORTGAGE = (cv * percentageOfCV).toFixed(0);

  const theme = useTheme();

  const handleSuccess = () => {
    setUpdateSuccess(true);
  };
  const handleFailed = () => {
    setUpdateFailed(true);
  };

  useNotistack(
    `${t("updateSucceedMsg")}`,
    updateSuccess,
    setUpdateSuccess,
    "success"
  );
  useNotistack(`${t("updateFailedMsg")}`, saveError, setSaveError, "error");
  useNotistack(
    `${t("updateFailedMsg")}`,
    updateFailed || fetchError,
    setUpdateFailed,
    "error"
  );

  const gradientColors = useMemo(() => {
    return isDarkTheme
      ? ["rgba(66,216,230,0.5)", "rgba(66,216,230,0)"]
      : ["rgba(31,78,121,0.5)", "rgba(31,78,121,0)"];
  }, [isDarkTheme]);

  useEffect(() => {
    let mortgageData = {};

    if (values[0].principal) {
      mortgageData = prepareMortgageData(values);
      setTotalPrincipal(mortgageData.totalPrincipal);
    } else {
      setTotalPrincipal(0);
    }
  }, [values]);

  const calculateMortgage = (myValues) => {
    let mortgageData = {};
    let myMaxPeriod = 0;
    if (myValues[0].principal && myValues[0].period) {
      mortgageData = prepareMortgageData(myValues);
      myMaxPeriod = getMaxPeriod(myValues);
      setMaxPeriod(myMaxPeriod);
    }

    setLineChartData(
      creatingLine(
        [],
        myMaxPeriod + 1,
        colors,
        [
          t("Mortgage.LineGraph.balance"),
          t("Mortgage.LineGraph.interestPayment"),
          t("Mortgage.LineGraph.fundPayment"),
        ],
        gradientColors
      )
    );

    if (myValues[0].principal && myValues[0].period) {
      let myTotalMortgage = mortgageData.totalPayment.toFixed(0);
      let myTotalPayment = (
        myTotalMortgage - mortgageData.totalPrincipal
      ).toFixed(0);
      let myTotalPrincipal = mortgageData.totalPrincipal;

      setFirstMonthPayment(mortgageData.firstMonthPayment.toFixed(0));
      setMaximumPayment(mortgageData.maxPayment.toFixed(0));
      setTotalMortgage(mortgageData.totalPayment.toFixed(0));
      setTotalPayment(
        (myTotalMortgage - mortgageData.totalPrincipal).toFixed(0)
      );
      setRatioPayment(
        (myTotalMortgage / mortgageData.totalPrincipal).toFixed(2)
      );
      setData(creatingData(myValues));
      setFundRefund(mortgageData.totalRefund.toFixed(2));

      setLineChartData(
        creatingLine(
          creatingData(myValues),
          myMaxPeriod + 1,
          colors,
          [
            t("Mortgage.LineGraph.balance"),
            t("Mortgage.LineGraph.interestPayment"),
            t("Mortgage.LineGraph.fundPayment"),
          ],
          gradientColors
        )
      );
      setDataDonut(
        dataDoughnut(
          ["יתרת", "תשלום"],
          myTotalPayment,
          myTotalPrincipal,
          isDarkTheme
        )
      );
    } else {
      setDataDonut(dataDoughnut(["יתרת", "תשלום"], 0, 0, isDarkTheme));
      setFirstMonthPayment(0);
      setMaximumPayment(0);
      setTotalMortgage(0);
      setTotalPayment(0);
      setRatioPayment(0);
      setFundRefund(0);
    }
  };
  const handleReceivedData = async (data) => {
    const myValues = [];
    const { paths, principles, rates, inflations, periods, purpose } = data;
    if (paths && paths.length > 0) {
      for (let index in paths) {
        myValues.push({
          key: uuidv4(),
          path: paths[index].trim() || t("Mortgage.paths.options.selectPath"),
          principal: principles[index],
          rate: rates[index] || 0,
          inflation: inflations[index] || 0,
          period: periods[index] || 0,
        });
      }
    } else {
      myValues.push({
        key: uuidv4(),
        path: t("Mortgage.paths.options.selectPath"),
        principal: 0,
        rate: 0,
        inflation: 0,
        period: 0,
      });
    }
    setValues(await myValues);
    setMortgagePurpose(purpose || " ");
    calculateMortgage(myValues);
  };

  async function getMortgageData() {
    setIsLoading(true);
    const myMortgages = await doFetch(
      `/reports/dashboard/page/loans${
        isOpenAccess.access ? "" : "?id=" + propertyId.id
      }`
    );

    if (myMortgages) {
      checkResponse(myMortgages);
    }
  }

  useEffect(() => {
    if (isDemo) {
      const myValues = {
        inflations: [],
        paths: [],
        periods: [],
        principles: [],
        purpose: " ",
        rates: [],
      };
      checkResponse(myValues);
    }
    if (propertyId && !isDemo) {
      try {
        getMortgageData();
      } catch (e) {
        console.error("Failed to fetch: ", e);
        setFetchError(true);
        throw e;
      }
    }
  }, [propertyId]);

  const handleAddPath = () => {
    setValues([
      ...values,
      {
        key: uuidv4(),
        path: t("Mortgage.paths.options.selectPath"),
        principal: 0,
        rate: 0,
        inflation: 0,
        period: 0,
      },
    ]);
  };

  const checkResponse = (response, callbackSuccess, callbackFailed) => {
    if (response["paths"]) {
      if (callbackSuccess) callbackSuccess();
      handleReceivedData(response);
    } else if (callbackFailed) {
      callbackFailed();
    }
    setIsLoading(false);
  };

  const postMortgage = async (mortgage) => {
    const response = await doFetch(
      `/reports/dashboard/page/loans/update${
        isOpenAccess.access ? "" : "?id=" + propertyId.id
      }`,
      {
        method: "POST",
        body: JSON.stringify(mortgage),
      }
    );
    return response;
  };

  const handleReset = async () => {
    setIsLoading(true);
    const myValues = {
      paths: [],
      principles: [],
      rates: [],
      inflations: [],
      periods: [],
      purpose: " ",
    };
    try {
      let response = myValues;
      if (!isDemo) response = await postMortgage(myValues);
      checkResponse(response, handleSuccess, handleFailed);
      setErrorsPrincipal([]);
    } catch (e) {
      console.error("Failed to update: ", e);
      setUpdateFailed(true);
      throw e;
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const valuesToSave = {
      paths: [],
      principles: [],
      rates: [],
      inflations: [],
      periods: [],
      purpose: mortgagePurpose,
    };
    const myValues = [...values];

    for (let value of myValues) {
      valuesToSave.paths.push(value.path);
      valuesToSave.principles.push(value.principal);
      valuesToSave.rates.push(parseFloat(value.rate));
      valuesToSave.inflations.push(parseFloat(value.inflation));
      valuesToSave.periods.push(value.period);
    }

    if (isDemo) {
      checkResponse(valuesToSave, handleSuccess, handleFailed);
    } else if (valuesToSave.paths.length >= 1 && valuesToSave.purpose) {
      try {
        const response = await postMortgage(valuesToSave);
        checkResponse(response, handleSuccess, handleFailed);
        setErrorsPrincipal([]);
      } catch (e) {
        console.error("Failed to update: ", e);
        setUpdateFailed(true);
        throw e;
      }
    } else {
      setSaveError(true);
      setIsLoading(false);
    }
  };

  return (
    <Grid
      container
      spacing={SPACING_FACTOR}
      style={{ backgroundColor: "#e1edf7", margin: "0", width: "revert" }}
    >
      <Grid item xs={12}>
        <TilesContainer style={{ height: "100%", border: "none" }} relative>
          <Typography variant={"h5"}>{t("Mortgage.title")}</Typography>
        </TilesContainer>
      </Grid>
      <Grid container item xs={12} md={9}>
        <Grid container item xs={12}>
          <InputMortgageDetails
            cv={cv}
            errorsPrincipal={errorsPrincipal}
            handleAddPath={handleAddPath}
            MAX_MORTGAGE={MAX_MORTGAGE}
            rawData={rawData}
            mortgagePurpose={mortgagePurpose}
            setMortgagePurpose={setMortgagePurpose}
            setErrorsPrincipal={setErrorsPrincipal}
            setTotalPrincipal={setTotalPrincipal}
            setValues={setValues}
            totalPrincipal={totalPrincipal}
            values={values}
            handleReset={handleReset}
            handleSave={handleSave}
            isLoading={isLoading}
          />
        </Grid>
        <MortgageResultRow
          firstMonthPayment={firstMonthPayment}
          fundRefund={fundRefund}
          maximumPayment={maximumPayment}
          ratioPayment={ratioPayment}
          totalMortgage={totalMortgage}
        />
        <Grid
          container
          item
          xs={12}
          alignItems={"stretch"}
          style={{ paddingTop: theme.spacing(3) }}
        >
          <TilesContainer
            style={{
              width: "100%",
            }}
            relative
          >
            {isDemo && <DemoExample />}
            <Grid container>
              {lineChartData ? (
                <>
                  <TitleGrid
                    title={t("Mortgage.LineGraph.title")}
                    tooltipText={t("Mortgage.LineGraph.tooltip")}
                  />
                  <Grid item xs={12}>
                    <MortgageLineChart
                      lineChartData={lineChartData}
                      pallet={pallet}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <LoadingCharts
                    variant={"h5"}
                    name={`${t("Mortgage.LineGraph.title")}`}
                  />
                </Grid>
              )}
            </Grid>
          </TilesContainer>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={3}>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <TilesContainer
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            topBorder
          >
            <AskFundBox isData={!!rawData} />
          </TilesContainer>
        </Grid>
        <Grid
          container
          alignItems={"stretch"}
          item
          xs={12}
          style={{ marginBottom: 24 }}
        >
          <TilesContainer
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            relative
          >
            {isDemo && <DemoExample />}
            <BarLeverageRate rawData={rawData} lineChartData={lineChartData} />
          </TilesContainer>
        </Grid>
        <Grid
          container
          alignItems={"stretch"}
          item
          xs={12}
          style={isMobileScreen ? { marginBottom: 41 } : {}}
        >
          <TilesContainer
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            relative
          >
            {" "}
            {isDemo && <DemoExample />}
            <MortgageDonutChart data={dataDonut} />
          </TilesContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MortgageCalculatorChart;
