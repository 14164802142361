import React, { useEffect, useState } from "react";
import { doFetch } from "common-components";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { LoadingButtons } from "../../components/layout/Layout";
import { useIsIncludes } from "common-components";

const useStyles = makeStyles({
  root: {
    width: "90%",
    "& div": {
      height: 32,
    },
    "& div label": {
      transform: "translate(0, -10px) scale(1)",
    },
  },
  inputRoot: {
    marginTop: "0px !important",
  },
});

const LinkProject = ({ control }) => {
  const [options, setOptions] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchFolders(setOptions, setError);
  }, []);

  return (
    <Controller
      control={control}
      name="project"
      render={({ field: { onChange, value } }) => {
        return (
          <ProjectAutocomplete
            options={options}
            value={value}
            onChange={onChange}
          />
        );
      }}
    />
  );
};
export default LinkProject;
//TODO create export component to reuse it

export function fetchFolders(setOptions, setError) {
  doFetch(`/reports/search/premium/folders`)
    .then((res) => {
      const keys = Object.keys(res.projects);
      const options = [];
      for (const key of keys) {
        options.push({
          title: key,
          project: key,
          building: "",
        });
        res.projects[key].forEach((value) => {
          options.push({
            title: `${key} (${value})`,
            project: key,
            building: value,
          });
        });
      }

      setOptions(options);
    })
    .catch((err) => setError(err));
}

export const ProjectAutocomplete = ({
  options,
  value,
  onChange,
  loading,
  defaultValue,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      id="project"
      name="project"
      options={options}
      getOptionLabel={(option) => option.title}
      classes={classes}
      autoComplete
      defaultValue={defaultValue ? defaultValue : null}
      noOptionsText="לא נמצאו תוצאות"
      placeholder={"לא נמצאו תוצאות"}
      includeInputInList
      value={value ? value : null}
      onChange={(e, data) => onChange(data)}
      renderInput={(params) => (
        <TextField
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <LoadingButtons color="inherit" size={"2px"} zIndex={9999} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          {...params}
          label="פרוייקט (בניין)"
          variant="standard"
          style={{ width: "100%" }}
        />
      )}
    />
  );
};
