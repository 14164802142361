import React, { useContext, useEffect, useState } from "react";
import StackBarChart from "../charts/StackBarChart";
import { hexToRgbA } from "../services/helpers";
import { darkStackBarColors, stackBarColors } from "../services/colors";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { labelData } from "../components/dashboard-components/tama/TamaLineChart";
import { SpinnerInsideCharts } from "../components/layout/Layout";
import { useTranslation } from "react-i18next";

function normaliseData(data: DataStackBarChartInterface, isDarkTheme: boolean) {
  return {
    labels: data["x-axis"].categories,
    datasets: data.series.map((item, index) => {
      return {
        label: item.name,
        backgroundColor: isDarkTheme
          ? darkStackBarColors[index]
          : stackBarColors[index],
        borderColor: isDarkTheme
          ? darkStackBarColors[index]
          : stackBarColors[index],
        borderWidth: 0,
        hoverBackgroundColor: hexToRgbA(
          isDarkTheme ? darkStackBarColors[index] : stackBarColors[index],
          0.7
        ),
        hoverBorderColor: hexToRgbA(
          isDarkTheme ? darkStackBarColors[index] : stackBarColors[index],
          0.7
        ),
        data: item.data,
      };
    }),
  };
}

export interface DataStackBarChartInterface {
  series: Series[];
  "x-axis": XAxis;
}

export interface Series {
  data: number[];
  name: string;
}

export interface XAxis {
  categories: number[];
  labels: string[];
}
export interface StackBarInterface {
  labels: number[];
  datasets: {
    label: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    hoverBackgroundColor: string;
    hoverBorderColor: string;
    data: number[];
  }[];
}
export interface LegendInterface {
  color: string;
  label: string;
}
const DataStackBarChart: React.FC<{ rawData: DataStackBarChartInterface }> = ({
  rawData,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [data, setData] = useState<StackBarInterface | undefined>(undefined);
  const [legend, setLegend] = useState<LegendInterface[] | undefined>(
    undefined
  );
  const { t } = useTranslation();
  useEffect(() => {
    setData(normaliseData(rawData, isDarkTheme));
    setLegend(
      labelData(
        t,
        rawData,
        isDarkTheme,
        stackBarColors,
        darkStackBarColors,
        "series"
      )
    );
  }, [rawData]);
  if (data && legend) {
    return <StackBarChart data={data} legend={legend} />;
  } else {
    return <SpinnerInsideCharts />;
  }
};
export default DataStackBarChart;
