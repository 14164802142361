import { priceFormatter } from "../helpers/formatting";
import Cookies from "js-cookie";
import { useDirection } from "../../context/DirectionContext";

const SAVE_PERC = 15;

export const PRICING = {
  il: { monthly: 149, yearly: 139 },
  br: { monthly: 59, yearly: 49 },
  us: { monthly: 69.99, yearly: 59.99 },
};

export const SINGLE_PLANS = (t, country = "il") => {
  return [
    {
      name: "monthly",
      title: t("pricing.monthly"),
      perMonth: t("pricing.perMonth"),
      price: PRICING[country].monthly,
    },
    {
      name: "yearly",
      title: t("pricing.yearly"),
      perYear: t("pricing.perMonth"),

      save: t("pricing.save"),
      price: PRICING[country].yearly,
      saveAmount: Math.ceil(
        (PRICING[country].monthly - PRICING[country].yearly) * 12
      ),
      savePercentage: 14,
    },
  ];
};

export const packagePlan = (t) => {
  // TODO: Rom - make price dynmaicly
  return [
    {
      name: "1pack",
      price: 49,
      perProperty: t("pricing.perProperty"),
      numApartments: 0,
      title: t("pricing.apartment"),
    },
    {
      name: "3pack",
      price: 39,
      perProperty: t("pricing.perProperty"),
      numApartments: 3,
      title: t("pricing.apartments"),
    },
    {
      name: "5pack",
      price: 29,
      perProperty: t("pricing.perProperty"),
      numApartments: 5,
      title: t("pricing.apartments"),
    },
    {
      name: "10pack",
      price: 25,
      perProperty: t("pricing.perProperty"),
      numApartments: 10,
      title: t("pricing.apartments"),
    },
  ];
};
