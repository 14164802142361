import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { addingComma, removeComma } from "../../services/helpers";
import Hidden from "@material-ui/core/Hidden";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import { useIsMobileScreen } from "common-components";
import { useIsIncludes } from "common-components";
import {
  darkMainPropColor,
  mainPropColor,
  darkBoxes,
  darkText,
  lightBoxes,
} from "./../../services/colors";
import { XS_PLUS_FONT } from "../../services/fonts";
import { useTranslation } from "react-i18next";

const MortgagePath = ({
  values,
  setValues,
  cv,
  MAX_MORTGAGE,
  totalPrincipal,
  setTotalPrincipal,
  errorsPrincipal,
  setErrorsPrincipal,
  isLoading,
}) => {
  const [accumulatedPrincipal, setAccumulatedPrincipal] = useState(0);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const calculateTotalPrincipal = (myValues, index) => {
    const myTotalPrincipal = myValues.reduce(
      (acc, curVal) => Number(curVal.principal) + acc,
      0
    );
    const myAccumulatedPrincipal = myValues.reduce(
      (acc, curVal, curIndex) =>
        curIndex !== index ? Number(curVal.principal) + acc : acc,
      0
    );

    setTotalPrincipal(myTotalPrincipal);
    setAccumulatedPrincipal(myAccumulatedPrincipal);
  };

  const handleDeletePath = (mortgage) => {
    let myNewPaths = [...values];
    myNewPaths = myNewPaths.filter((m) => m.key !== mortgage.key);
    const index = myNewPaths.length - 1;
    setValues(myNewPaths);
    calculateTotalPrincipal(myNewPaths, index);
  };

  const handleDecimals = (number) => {
    let value = "0";
    const decimal = String(number).indexOf(".");
    if (decimal > 0) {
      value = number.slice(0, decimal + 2);
    } else value = number;
    return value;
  };

  const setMyErrorsPrincipal = (index, event) => {
    let myErrorsPrincipal = [...errorsPrincipal];
    myErrorsPrincipal[index] = event.currentTarget;
    setErrorsPrincipal(myErrorsPrincipal);
    event.target.value = MAX_MORTGAGE - Number(accumulatedPrincipal);
  };

  const handleChange = (prop, index) => (event) => {
    const myValues = [...values];
    if (MAX_MORTGAGE <= 0) setMyErrorsPrincipal(index, event);
    if (prop === "period") {
      if (isNaN(Number(event.target.value))) return;
      if (!Number.isInteger(Number(event.target.value)))
        event.target.value = myValues[index][prop];
      if (Number(event.target.value) > 30) event.target.value = 30;
      if (Number(event.target.value) < 0) event.target.value = 0;

      myValues[index] = {
        ...myValues[index],
        [prop]: Number(event.target.value),
      };
      setValues(myValues);
    } else if (prop === "principal") {
      if (isNaN(removeComma(event.target.value))) return;
      if (
        Number(accumulatedPrincipal) + removeComma(event.target.value) >
        MAX_MORTGAGE
      ) {
        setMyErrorsPrincipal(index, event);
      }
      if (removeComma(event.target.value) <= 0) event.target.value = 0;
      myValues[index] = {
        ...myValues[index],
        [prop]: removeComma(event.target.value),
      };
      setValues(myValues);
      calculateTotalPrincipal(myValues, index);
    } else if (prop === "path") {
      if (MAX_MORTGAGE <= 0) {
        myValues[index]["inflation"] = 0.0;
        event.target.value = " ";
      } else if (
        // TODO Change to LTR
        String(event.target.value).includes(
          t("Mortgage.paths.options.variableRate")
        ) ||
        String(event.target.value).includes(
          t("Mortgage.paths.options.prime")
        ) ||
        event.target.value === " "
      ) {
        myValues[index]["inflation"] = 0.0;
      } else {
        myValues[index]["inflation"] = 1.2;
      }
      myValues[index] = {
        ...myValues[index],
        [prop]: String(event.target.value),
      };
      setValues(myValues);
    } else if (prop === "rate" || prop === "inflation") {
      if (event.target.value === ".") event.target.value = "0.";
      if (isNaN(event.target.value)) event.target.value = myValues[index][prop];
      if (Number(event.target.value) > 100) event.target.value = 100;
      if (Number(event.target.value) < 0 || !event.target.value)
        event.target.value = 0;
      const value = handleDecimals(event.target.value);
      myValues[index] = {
        ...myValues[index],
        [prop]: value || 0,
      };
      setValues(myValues);
    }
  };
  const { t } = useTranslation();
  const isSmallScreen = useIsMobileScreen();
  const handleCloseError = (index) => {
    setErrorsPrincipal([]);
  };
  const classesLab = useStylesAddProp({ isDarkTheme, font: 12 });

  return (
    <>
      {values.map((mortgage, index) => {
        return (
          <Grid
            item
            xs={12}
            container
            key={`mortgage${mortgage.key}`}
            spacing={2}
            alignItems={"flex-end"}
            justify={"space-between"}
            style={{
              backgroundColor:
                index % 2 === 0
                  ? "transparent"
                  : isDarkTheme
                  ? "#2B4468"
                  : "#F5F5F5",
              margin: 0,
            }}
          >
            <Grid
              container
              item
              xs={12}
              md={4}
              lg={2}
              style={{ alignItems: "center" }}
            >
              <Grid item md={10} lg={11}>
                <InputLabel
                  className={classesLab.inputLabelSearch}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {`${t("Mortgage.paths.path")} ${index + 1}`}*
                </InputLabel>
              </Grid>
              {isSmallScreen && values.length > 1 && (
                <Grid md={2} lg={1}>
                  <IconButton disabled={isLoading}>
                    <DeleteIcon
                      style={{
                        color: isLoading
                          ? "primary"
                          : isDarkTheme
                          ? darkMainPropColor
                          : mainPropColor,
                        cursor: isLoading ? "default" : "pointer",
                      }}
                      onClick={() => handleDeletePath(mortgage)}
                    />
                  </IconButton>
                </Grid>
              )}

              <Select
                id="standard-number"
                type="number"
                style={{ width: "100%" }}
                value={mortgage.path}
                onChange={handleChange("path", index)}
                variant={"outlined"}
                disabled={isLoading}
              >
                <MenuItem
                  value={t("Mortgage.paths.options.selectPath")}
                  disabled
                >
                  {t("Mortgage.paths.options.selectPath")}
                </MenuItem>
                <MenuItem value={t("Mortgage.paths.options.prime")}>
                  {t("Mortgage.paths.options.prime")}
                </MenuItem>
                <MenuItem value={t("Mortgage.paths.options.fixedRate")}>
                  {t("Mortgage.paths.options.fixedRate")}
                </MenuItem>
                <MenuItem value={t("Mortgage.paths.options.variableRate")}>
                  {t("Mortgage.paths.options.variableRate")}
                </MenuItem>
                <MenuItem value={t("Mortgage.paths.options.attachedRate")}>
                  {t("Mortgage.paths.options.attachedRate")}
                </MenuItem>
                <MenuItem value={t("Mortgage.paths.options.variableAttached")}>
                  {t("Mortgage.paths.options.variableAttached")}
                </MenuItem>
              </Select>
            </Grid>

            <Grid item xs={6} sm={4} md={4} lg={2}>
              <Popover
                id={`${mortgage.key}-popover`}
                open={Boolean(errorsPrincipal[index])}
                anchorEl={errorsPrincipal[index]}
                onClose={(event) => handleCloseError(index)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Typography
                  style={{
                    color: "red",
                    fontSize: XS_PLUS_FONT,
                    padding: "10px",
                  }}
                >
                  {MAX_MORTGAGE == 0
                    ? `${t("Mortgage.paths.typeError")}`
                    : `${t("Mortgage.paths.amountError")}`}
                </Typography>
              </Popover>

              <InputLabel
                className={classesLab.inputLabelSearch}
                style={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                {t("Mortgage.paths.loanAmount")}
              </InputLabel>

              <TextField
                variant={"outlined"}
                autoComplete="off"
                disabled={isLoading}
                style={{
                  width: "100%",
                }}
                onFocus={(event) => {
                  event.target.select();
                }}
                onChange={handleChange("principal", index)}
                value={addingComma(mortgage.principal)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"start"}>
                      <Typography
                        variant={"p"}
                        stlye={{
                          color: isDarkTheme
                            ? `${darkText} !important`
                            : "black !important",
                        }}
                      >
                        ₪
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  min: "0",
                  max:
                    values.length <= 1
                      ? MAX_MORTGAGE
                      : MAX_MORTGAGE - totalPrincipal,
                  step: "1",
                  inputMode: "numeric",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={1}>
              <InputLabel
                className={classesLab.inputLabelSearch}
                style={{ whiteSpace: "nowrap" }}
              >
                {t("years")}
              </InputLabel>
              <TextField
                variant={"outlined"}
                autoComplete="off"
                disabled={isLoading}
                style={{
                  width: "100%",
                }}
                onFocus={(event) => {
                  event.target.select();
                }}
                onChange={handleChange("period", index)}
                value={String(mortgage.period)}
                placeholder={"(1-30) בחירת מספר שנים"}
                error={mortgage.period > 31 || mortgage.period < 0}
                inputProps={{
                  min: "0",
                  max: "30",
                  step: "1",
                  inputMode: "numeric",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={1}>
              <InputLabel
                className={classesLab.inputLabelSearch}
                style={{ whiteSpace: "nowrap" }}
              >
                {t("Mortgage.paths.investRate")}
              </InputLabel>

              <TextField
                value={mortgage.rate}
                variant={"outlined"}
                disabled={isLoading}
                autoComplete="off"
                style={{ width: "100%" }}
                placeholder={0}
                onFocus={(event) => {
                  event.target.select();
                }}
                onChange={handleChange("rate", index)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position={"start"}
                      style={{
                        marginLeft: 0,
                        padding: 0,
                      }}
                    >
                      <Typography
                        variant={"p"}
                        style={{
                          color: isDarkTheme
                            ? `${darkText} !important`
                            : "black !important",
                        }}
                      >
                        %
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  min: "0",
                  max: "100",
                  inputMode: "decimal",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={values.length > 1 ? 1 : 2}>
              <InputLabel
                className={classesLab.inputLabelSearch}
                style={{ whiteSpace: "nowrap" }}
              >
                {t("Mortgage.paths.attachment")}
              </InputLabel>

              <div
                key={
                  mortgage.path.includes("לא צמודה") ||
                  mortgage.path.includes("פריים") ||
                  mortgage.path === " "
                    ? 0
                    : 1.2
                }
              >
                <TextField
                  value={mortgage.inflation}
                  autoComplete="off"
                  variant={"outlined"}
                  style={{ width: "100%" }}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  onChange={handleChange("inflation", index)}
                  defaultValue={mortgage.path === " " ? 0 : 1.2}
                  disabled={mortgage.path === " "}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position={"start"}
                        style={{ marginLeft: 0 }}
                      >
                        <Typography
                          variant={"p"}
                          stlye={{
                            color: isDarkTheme
                              ? `"" !important`
                              : "black !important",
                          }}
                        >
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: "0",
                    max: "100",
                    inputMode: "decimal",
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              style={{
                paddingRight: isSmallScreen ? "" : values.length > 1 ? 20 : "",
              }}
            >
              <InputLabel
                className={classesLab.inputLabelSearch}
                style={{ whiteSpace: "nowrap" }}
              >
                {t("Mortgage.paths.mortgageRate")}
              </InputLabel>

              <TextField
                variant={"standard"}
                style={{
                  width: "100%",
                  backgroundColor: isDarkTheme ? darkBoxes : lightBoxes,
                }}
                disabled={true}
                value={
                  MAX_MORTGAGE && mortgage.principal > 0
                    ? ((mortgage.principal / MAX_MORTGAGE) * 100).toFixed(2)
                    : 0
                }
                InputProps={{
                  classes: { input: classesLab.filledInput },
                  startAdornment: (
                    <InputAdornment
                      position={"start"}
                      style={{ paddingRight: 14 }}
                    >
                      %
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  inputMode: "decimal",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <InputLabel
                className={classesLab.inputLabelSearch}
                style={{ whiteSpace: "nowrap" }}
              >
                {t("Mortgage.paths.loanRatio")}
              </InputLabel>

              <TextField
                variant={"standard"}
                style={{
                  width: "100%",
                  backgroundColor: isDarkTheme ? darkBoxes : lightBoxes,
                }}
                disabled={true}
                value={
                  mortgage.principal
                    ? ((mortgage.principal / cv) * 100).toFixed(2)
                    : 0
                }
                inputProps={{ borderBottom: "none", inputMode: "decimal" }}
                InputProps={{
                  classes: { input: classesLab.filledInput },
                  startAdornment: (
                    <InputAdornment
                      position={"start"}
                      style={{ paddingRight: 14 }}
                    >
                      %
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Hidden only="xs">
              <Grid item xs={1} container alignItems={"flex-end"}>
                {!isSmallScreen && values.length > 1 && (
                  <IconButton disabled={isLoading}>
                    <DeleteIcon
                      style={{
                        color: isLoading
                          ? isDarkTheme
                            ? "#384057"
                            : "#6C6C6C"
                          : isDarkTheme
                          ? darkMainPropColor
                          : mainPropColor,
                        cursor: isLoading ? "default" : "pointer",
                      }}
                      onClick={() => handleDeletePath(mortgage)}
                    />
                  </IconButton>
                )}
              </Grid>
            </Hidden>
          </Grid>
        );
      })}
    </>
  );
};
export default MortgagePath;
