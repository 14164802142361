import Grid from "@material-ui/core/Grid";
import { ReactComponent as AddCircleOutlineIcon } from "../../../terporaryImgs/plus.svg";
import Typography from "@material-ui/core/Typography";
import { darkMainPropColor } from "../../../services/colors";
import IconButton from "@material-ui/core/IconButton";
import { useEffect, useState } from "react";
import ProjectsList from "./ProjectsList";
import BuildingsList from "./BuildingsList";
import { useLazyDataApi } from "common-components";
import { v4 as uuidv4 } from "uuid";
import { useNotistack } from "common-components";
import { LoadingCharts } from "../../../components/layout/Layout";
import { useTranslation } from "react-i18next";

const ProjectsComponent = ({ folders, setFolders }) => {
  const [{ data, error, isLoading }, lazyFetch] = useLazyDataApi();
  const [projects, setProject] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    lazyFetch("/reports/search/premium/folders");
  }, []);

  useEffect(() => {
    if (data) {
      setProject(
        Object.entries(data.projects).map((item) => {
          return {
            label: item[0],
            saved: true,
            id: uuidv4(),
            buildings: item[1].map((building) => {
              return { label: building, saved: true, id: uuidv4() };
            }),
          };
        })
      );
    }
  }, [data]);

  useNotistack(t("updateFailedMsg"), error, undefined, "error");

  return (
    <Grid container spacing={1}>
      {isLoading ? (
        <Grid item xs={12}>
          <LoadingCharts padding={"0"} />
        </Grid>
      ) : folders.display === "projects" ? (
        <ProjectsList
          folders={folders}
          projects={projects}
          setFolders={setFolders}
          setProject={setProject}
        />
      ) : (
        <BuildingsList
          projects={projects}
          setProject={setProject}
          folders={folders}
          setFolders={setFolders}
        />
      )}
    </Grid>
  );
};
export default ProjectsComponent;

export const AddFolderButton = ({ handleAdd, text }) => {
  return (
    <Grid
      item
      xs={2}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <IconButton onClick={handleAdd}>
          <AddCircleOutlineIcon style={{ width: "2.5em", height: "2.5em" }} />
        </IconButton>
      </div>

      <Typography align={"center"} style={{ color: darkMainPropColor }}>
        {text}
      </Typography>
    </Grid>
  );
};
