import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  darkBackground,
  darkMainPropColor,
  mainPropColor,
} from "../../services/colors";
import { PricingCard } from "./PricingCard";
import { useIsMobileScreen } from "common-components";
import { useHistory, useLocation } from "react-router-dom";
import { OpenAccessContext } from "../../context/OpenAccessContext.tsx";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { PaymentProcessContext } from "../../context/PaymentProcessContext.tsx";
import SelectPropDetails from "../add-prop/SelectPropDetails";
import { propertyNotPurchased } from "../payment/Payment";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesPurchasePlan } from "../../styledComponent/PurchasePlanStyled";
import { BackButton } from "common-components";
import {
  propertyIL,
  PurchasePropertyContext,
} from "../../context/PurchasePropertyContext";
import CloseButton from "../../components/CloseButton";
import Typography from "@material-ui/core/Typography";
import { IMAGES_LINK } from "common-components";
import { hasValueInStorage } from "../../services/helpers";
import MessageNoProp from "../../components/dashboard-components/global-summary/MessageNoProp";
import { Routes, RoutesUS } from "../../services/routes";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";
import useContextProps from "../../hooks/useContextProps";

const PLAN_ADVANCED = "advanced";
const PLAN_PREMIUM = "premium";

const PRICING_PLANS = (t) => {
  return [
    {
      price: 69,
      properties: 1,
      days: 90,
      planId: 1,
      title: "basic",
      text: `${t("PurchasePlan.singleReportBasic")}`,
      packageName: "1pack",
    },
    {
      price: 39,
      properties: 3,
      days: 90,
      planId: 2,
      saving: "34%",
      title: "advanced",
      text: `${t("PurchasePlan.singleReportAdvanced")}`,
      packageName: "3pack",
    },
    {
      price: 29,
      properties: 5,
      // TODO : ROM - v.	Also please mark the 5 properties option as the “optimal” program
      days: 90,
      planId: 3,
      saving: "40%",
      title: "premium",
      text: `${t("PurchasePlan.singleReportPremium")}`,
      packageName: "5pack",
    },
    {
      price: 25,
      properties: 10,
      days: 90,
      planId: 4,
      saving: "47%",
      title: "supreme",
      text: `${t("PurchasePlan.singleReportSupreme")}`,
      packageName: "10pack",
    },
  ];
};

const PRICING_PLANS_SUBSCRIPTION = (t) => {
  return [
    // {
    //   price: 249,
    //   properties: 50,
    //   months: 12,
    //   planId: 5,
    //   saving: "52%",
    //   title: "basic",
    //   text: `${t("PurchasePlan.yearlyBasic")}`,
    // },
    {
      price: 249,
      properties: 200,
      months: 12,
      planId: 6,
      saving: "53%",
      title: "advanced",
      text: `${t("PurchasePlan.yearlyAdvanced")}`,
    },
    // {
    //   price: 799,
    //   properties: 200,
    //   months: 12,
    //   planId: 7,
    //   saving: "54%",
    //   title: "premium",
    //   text: `${t("PurchasePlan.yearlyPremium")}`,
    // },
  ];
};
function PurchasePlan() {
  const queryParams = new URLSearchParams(window.location.search);

  const context = useContextProps();
  const {
    direction,
    property,
    setProperty,
    isDarkTheme,
    isOpenAccess,
  } = context;
  const { openDialog, closeDialog } = useDialogManager();
  const [paymentProcess, setPaymentProcess] = useContext(PaymentProcessContext);
  const [selectedPlanOnTime, setSelectedPlanOnTime] = useState(PLAN_PREMIUM);
  const [selectedPlanSubscription, setSelectedPlanSubscription] = useState(
    PLAN_ADVANCED
  );
  const { t } = useTranslation();
  const history = useHistory();
  let location = useLocation();
  const locationHome = {
    pathname: location.pathname,
  };
  const locationDemo = {
    pathname: Routes.demo,
  };
  const isMobileScreen = useIsMobileScreen();

  const handleClosePricing = () => {
    history.push(isOpenAccess[0] ? locationDemo : locationHome);
    setProperty(propertyIL);
    setPaymentProcess(false);
    if (hasValueInStorage(localStorage.getItem("noProp"))) {
      openDialog(MessageNoProp, {
        DialogProps: { fullScreen: false, scroll: "paper" },
      });
    } else {
      closeDialog();
      queryParams.delete("address");
      queryParams.delete("place_id");
    }
  };

  const backButton = useCallback(() => {
    openDialog(SelectPropDetails, {
      DialogProps: { maxWidth: "md" },
    });
  }, []);
  useEffect(() => {
    if (property.lat && property.long) {
      propertyNotPurchased(direction.api, property);
    }
  }, []);
  const classes = useStylesPurchasePlan();

  return (
    <Grid
      container
      justify={"center"}
      alignItems={"center"}
      style={{
        padding: isMobileScreen ? "30px 0 0 0" : "0 10px 15px",
        overflow: isMobileScreen ? "" : "hidden",
        backgroundColor: isDarkTheme ? darkBackground : "none",
        borderRadius: "var(--border-radius)",
      }}
    >
      <Grid item xs={12}>
        <div
          className={classes.divSpaceBetween100}
          style={{ padding: "15px 0" }}
        >
          {/* <BackButton callBack={backButton} context={context} /> */}

          <CloseButton callback={handleClosePricing} padding={"0"} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant={"h5"}
          align={"center"}
          style={{
            fontWeight: 400,
            color: isDarkTheme ? darkMainPropColor : mainPropColor,
          }}
        >
          {t("PurchasePlan.priceList")}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={3}
        style={{ padding: "12px 0px 20px" }}
        justify={"center"}
      >
        <PricingCard
          selectedPlan={
            isDarkTheme ? selectedPlanSubscription : selectedPlanOnTime
          }
          setSelectedPlan={
            isDarkTheme ? setSelectedPlanSubscription : setSelectedPlanOnTime
          }
          options={
            isDarkTheme ? PRICING_PLANS_SUBSCRIPTION(t) : PRICING_PLANS(t)
          }
          title={
            isDarkTheme
              ? `${t("PurchasePlan.yearSubscription")}`
              : `${t("PurchasePlan.singleReport")}`
          }
          icon={
            isDarkTheme && !isOpenAccess[0]
              ? `${IMAGES_LINK}/dashboard/buildingPricing/houseMediumOutlineWhite.svg`
              : `${IMAGES_LINK}/dashboard/buildingPricing/houseMediumMulticolor.svg`
          }
        />
      </Grid>
    </Grid>
  );
}

export default PurchasePlan;
