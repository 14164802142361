import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableImpactsHeader from "./TableImpactsHeader";
import TableHeadPart from "./TableHeadPart";
import TablePaginationFooter from "./TablePaginationFooter";
import { Column, RowTable } from "../../data/DataTableChart";
import TableBody from "@material-ui/core/TableBody";
import TableRowsPart from "./TableRowsPart";
import TableExtra from "./TableExtra";
import { useIsMobileScreen } from "common-components";
import { useIsLargeScreen } from "common-components";
import { randomInt } from "../../components/dashboard-components/global-summary/RowBoxCurrent";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";

export const useStylesTable = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    borderRadius: "var(--border-radius)",
    fontFamily: "Open Sans Hebrew, sans-serif",
  },
  tooltip: {
    padding: 0,
    backgroundColor: "#fff",
    opacity: "1 !important",
    boxShadow: "0 3px 15px 2px hsla(0,0%,53.3%,.3)",
    border: "none !important",
    transition: "all 0.5 ease !important",
    zIndex: 2500,
    pointerEvents: "none",
    borderRadius: "var(--border-radius)",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  svg: {
    width: 17,
    height: 17,
    paddingRight: 5,
  },
});

export function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T | undefined
) {
  if (!orderBy) {
    return 0; // no sort when no orderBy
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = "asc" | "desc";

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key | undefined
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean }
) => number {
  return order === "desc" && orderBy === "impact"
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : order === "asc" && orderBy === "impact"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface TableChartProps {
  rows: RowTable[];
  columns: Column[];
  filterTypes: string[];
}

export const TableChart = ({ rows, columns, filterTypes }: TableChartProps) => {
  const [open, setOpen] = useState<undefined | number>(undefined);
  const [itemsRows, setItemsRows] = useState<RowTable[]>(rows);
  const classes = useStylesTable();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof RowTable | undefined>(undefined);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [filterByEvent, setFilterByEvent] = useState<[] | string[]>([]);
  const [isPositiveImpacts, setIsPositiveImpacts] = useState(false); //TODO check what is this
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const count = Math.ceil(itemsRows.length / rowsPerPage);
  const isBigScreen = useIsLargeScreen();
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const { t } = useTranslation();

  useNotistack(
    `${t("updateSucceedMsg")}`,
    updateSuccess,
    setUpdateSuccess,
    "success"
  );
  useNotistack(
    `${t("updateFailedMsg")}`,
    updateFailed,
    setUpdateFailed,
    "error"
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RowTable
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof RowTable) => (
    event: React.MouseEvent<unknown>
  ) => {
    handleRequestSort(event, property);
    setPage(1);
  };

  useEffect(() => {
    if (isPositiveImpacts && filterByEvent.length === 0) {
      // @ts-ignore
      setItemsRows(rows.filter((item) => item.impact > 0));
    } else if (isPositiveImpacts && filterByEvent.length > 0) {
      setItemsRows(
        rows.filter(
          // @ts-ignore
          (item) => item.impact > 0 && filterByEvent.includes(item.event.trim())
        )
      );
    } else if (!isPositiveImpacts && filterByEvent.length > 0) {
      setItemsRows(
        // @ts-ignore
        rows.filter((item) => filterByEvent.includes(item.event.trim()))
      );
    } else if (filterByEvent.length === 0) {
      setItemsRows(rows);
    }
    setPage(1);
  }, [isPositiveImpacts, filterByEvent]);

  return (
    <Grid container>
      <TableImpactsHeader
        filterTypes={filterTypes}
        filterDropdown={filterDropdown}
        filterByEvent={filterByEvent}
        setFilterByEvent={setFilterByEvent}
        setFilterDropdown={setFilterDropdown}
      />
      {itemsRows && (
        <TableContainer className={classes.container}>
          <Table aria-label="sticky table">
            <TableHeadPart
              classes={classes}
              columns={columns}
              createSortHandler={createSortHandler}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {/*@ts-ignore*/}
              {stableSort(itemsRows, getComparator(order, orderBy))
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <React.Fragment key={`${row.index} ${randomInt()}`}>
                      <TableRowsPart
                        open={open}
                        setOpen={setOpen}
                        key={labelId}
                        columns={columns}
                        isExtraSmallScreen={isExtraSmallScreen}
                        //@ts-ignore
                        row={row}
                        index={index}
                        isBigScreen={isBigScreen}
                      />
                      {/*//@ts-ignore*/}
                      <TableExtra open={open} row={row} />
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {count > 1 && (
        <TablePaginationFooter
          classes={classes}
          count={count}
          setPage={setPage}
          page={page}
        />
      )}
    </Grid>
  );
};
