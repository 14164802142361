import React, { createContext, useContext, useState } from "react";
import { LoadScript } from "@react-google-maps/api";
import { GOOGLE_KEY } from "common-components";
import { DirectionContext } from "./DirectionContext";

export const PurchasePropertyContext = createContext<PurchasePropertyIL | {}>(
  {}
);
export interface PurchasePropertyIL {
  parking: "without" | "single" | "serial" | "double";
  floor: number;
  num_floors: number;
  lat: number;
  long: number;
  elevator: 0 | 1;
  storage: 0 | 1;
  bomb_shelter: 0 | 1;
  condition: 1 | 2 | 3 | 4 | 5 | undefined;
  house_num: string;
  num_rooms: number;
  street: string;
  year_built: number;
  sqm: number;
  sqm_balcony_roof: number;
  sqm_garden: number;
  sqm_balcony: number;
  type:
    | "דירה"
    | "דירת גג"
    | "דירת גן"
    | "בית פרטי"
    | "דו משפחתי"
    | "קרקע"
    | "Apartment"
    | "Penthouse"
    | "Ground Apartment"
    | "House"
    | "Town House"
    | "Land"
    | undefined;
  area: string;
  repurchase_id: string;
  gush: string;
  halka: string;
  building_rights_sqm: number;
  building_rights_level: "no" | "standard" | "above" | "luxury";
  building_rights_approval: number;
  place_id: string;
}
export const propertyIL: PurchasePropertyIL = {
  parking: "without",
  floor: 0,
  num_floors: 0,
  lat: 0,
  long: 0,
  elevator: 0,
  storage: 0,
  bomb_shelter: 0,
  condition: 3,
  house_num: "",
  num_rooms: 0,
  street: "",
  year_built: 0,
  sqm: 0,
  sqm_balcony_roof: 0,
  sqm_garden: 0,
  sqm_balcony: 0,
  type: undefined,
  area: "",
  repurchase_id: "",
  gush: "",
  halka: "",
  building_rights_sqm: 0,
  building_rights_level: "no",
  building_rights_approval: 0,
  place_id: "",
};
export const GOOGLE_LIBRARIES = ["places"];
export const PurchasePropertyContextProvider: React.FC = ({ children }) => {
  const [property, setProperty] = useState<PurchasePropertyIL>(propertyIL);
  const [direction] = useContext(DirectionContext);

  return (
    <PurchasePropertyContext.Provider value={[property, setProperty]}>
      <LoadScript
        googleMapsApiKey={String(GOOGLE_KEY)}
        // onLoad={() => <div></div>}
        //@ts-ignore
        libraries={GOOGLE_LIBRARIES}
        language={direction.isRtl ? "he" : "en"}
      >
        {children}
      </LoadScript>
    </PurchasePropertyContext.Provider>
  );
};
