import { useContext, useState } from "react";
import Typography from "@material-ui/core/Typography";
import LinkIcon from "@material-ui/icons/Link";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { useNotistack } from "common-components";
import { makeStyles } from "@material-ui/core/styles";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { greyAxis, mainPropColor } from "../../../services/colors";
import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles({
  root: {
    color: "#151326",
    border: "none",
    backgroundColor: "transparent",
    width: "100%",
    fontSize: "1rem",
  },
});

const SharedLink = ({ link }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { closeDialog } = useDialogManager();
  const share = link;
  const [copySuccess, setCopySuccess] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(share);
    setCopySuccess(true);
  };

  const handleClosePopup = () => {
    closeDialog();
  };

  const handleSelect = (event) => {
    event.target.select();
  };

  useNotistack(t("clipboard"), copySuccess, setCopySuccess, "success");

  return (
    <Grid
      container
      style={{
        padding: 30,
        borderRadius: "var(--border-radius)",
        maxWidth: 600,
      }}
    >
      <Grid container alignItems="center" item xs={12}>
        <Grid container alignItems="center" item xs={11}>
          <Typography variant={"h6"} style={{ padding: "10px 0" }}>
            {t("Share.getLink")}
          </Typography>
          <LinkIcon
            style={{
              paddingRight: 10,
              color: !isDarkTheme ? mainPropColor : "",
            }}
          />
        </Grid>
        <Grid container justify="flex-end" item xs={1}>
          <FontAwesomeIcon
            icon={faTimes}
            color={greyAxis}
            style={{
              cursor: "pointer",
              padding: 0,
            }}
            onClick={handleClosePopup}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            backgroundColor: isDarkTheme ? "#a09eb9" : "#e5e8f3",
            padding: 15,
            borderRadius: "var(--border-radius)",
          }}
        >
          <input
            type="text"
            value={share}
            className={classes.root}
            onFocus={handleSelect}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ padding: "10px 0" }}
        justify={"flex-end"}
        alignItems={"center"}
      >
        <Grid item>
          <Button
            variant={"contained"}
            color={isDarkTheme ? "secondary" : "primary"}
            onClick={handleCopyLink}
          >
            {t("Share.copy")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SharedLink;
