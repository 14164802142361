import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { darkBorderColor, darkText, lightBoxes } from "../services/colors";
import { XS_PLUS_FONT } from "../services/fonts";

export const LightTooltip = withStyles((theme) => ({
  arrow: (props) => ({
    color: props.isDarkTheme ? darkBorderColor : lightBoxes,
  }),
  tooltip: (props) => ({
    padding: 10,
    backgroundColor: props.isDarkTheme ? darkBorderColor : lightBoxes,
    color: props.isDarkTheme ? darkText : "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: XS_PLUS_FONT,
  }),
}))((props) => <Tooltip {...props} />);
