import ReactTooltip from "react-tooltip";
import React, { useCallback, useContext, useRef, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { darkBackground, darkText } from "../services/colors";

const useStyles = makeStyles({
	root: {
		padding: "0 !important",
		backgroundColor: "#fff",
		opacity: "1 !important",
		boxShadow: "0 3px 15px 2px hsla(0,0%,53.3%,.3) !important",
		border: "none !important",
		transition: "all 0.5 ease !important",
		zIndex: 2500,
		pointerEvents: "none !important",
		borderRadius: "var(--border-radius) !important",
	},
});

export function useCustomTooltip(chartRef, setCurrentIndex, setDatasetIndex) {
	const tooltipRef = useRef();
	const [position, setPosition] = useState({
		top: 0,
		left: 0,
	});
	const customTooltip = useCallback(
		tooltipModel => {
			if (tooltipModel.opacity === 0) {
				if (tooltipRef.current) {
					ReactTooltip.hide(tooltipRef.current);
				}
				return;
			}

			const chart = chartRef.current;
			if (!chart) {
				return;
			}

			const position = chart.chartInstance.canvas.getBoundingClientRect();
			const left = position.left + tooltipModel.caretX;
			const top = position.top + tooltipModel.caretY;
			if (tooltipModel.dataPoints && setCurrentIndex) {
				const indexCurrent = tooltipModel.dataPoints[0].index;
				setCurrentIndex(indexCurrent);
			}
			if (tooltipModel.dataPoints && setDatasetIndex) {
				const indexDataset = tooltipModel.dataPoints[0].datasetIndex;
				const indexCurrent = tooltipModel.dataPoints[0].index;
				setDatasetIndex([indexCurrent, indexDataset]);
			}
			setPosition({ top, left });
			ReactTooltip.show(tooltipRef.current);
		},
		[chartRef, setCurrentIndex]
	);

	return { customTooltip, tooltipRef, position };
}

export function CustomTooltip({ children, top, left, tooltipRef, id, place }) {
	const classes = useStyles();
	const [isDarkTheme] = useContext(DarkThemeContext);
	return (
		<>
			<div
				style={{
					position: "fixed",
					top: top,
					left: left,
				}}
				data-for={id}
				data-tip
				data-iscapture="true"
				data-scroll-hide="true"
				ref={tooltipRef}
			/>
			<ReactTooltip
				id={id}
				place={place}
				effect="solid"
				event="mouseover mouseenter"
				eventOff="mouseleave mouseout scroll mousewheel blur"
				textColor={isDarkTheme ? darkText : "#222"}
				backgroundColor={isDarkTheme ? darkBackground : "#fff"}
				className={classes.root}>
				{children}
			</ReactTooltip>
		</>
	);
}
