import React, { useContext, useEffect, useState } from "react";
import TilesContainer from "../../components/TilesContainer";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Typography } from "@material-ui/core";
import MortgagePath from "./MortgagePath";
import { addingComma } from "../../services/helpers";
import { useStylesForecast } from "../../styledComponent/ForecastStyled";
import { useIsMobileScreen } from "common-components";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { ResultComponent } from "./MortgageResultRow";
import { useStylesFontMortgage } from "./MortgageCalculatorChart";
import DemoExample from "../../components/DemoExample";
import { useIsIncludes } from "common-components";
import SubmitAndClearButtons from "../../components/SubmitAndClearButtons";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import { XS_PLUS_FONT } from "../../services/fonts";
import { useTranslation } from "react-i18next";
import TitleGrid from "../../components/TitleGrid";

const InputMortgageDetails = ({
  values,
  handleAddPath,
  totalPrincipal,
  cv,
  rawData,
  mortgagePurpose,
  setMortgagePurpose,
  setValues,
  setTotalPrincipal,
  errorsPrincipal,
  setErrorsPrincipal,
  handleReset,
  handleSave,
  MAX_MORTGAGE,
  isLoading,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classesForecast = useStylesForecast();
  const classes = useStylesFontMortgage({ isDarkTheme });
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const isSmallScreen = useIsMobileScreen();
  const isDemo = useIsIncludes("demo");
  const classesLab = useStylesAddProp({ isDarkTheme, font: 12 });
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(null);

  useEffect(() => {
    if (
      MAX_MORTGAGE > 0 &&
      totalPrincipal > 0 &&
      MAX_MORTGAGE - totalPrincipal < 0
    ) {
      const myValues = [...values];
      const lastIndex = myValues.length - 1;
      myValues[lastIndex]["principal"] =
        myValues[lastIndex]["principal"] + (MAX_MORTGAGE - totalPrincipal);
      setValues(myValues);
    }
  }, [mortgagePurpose, MAX_MORTGAGE, totalPrincipal]);

  useEffect(() => {
    const entries = Object.entries(values[0]);
    const disabled = [...entries].filter(
      (item) => item[1] === 0 && item[0] !== "inflation"
    );
    setDisabled(disabled.length !== 0);
  }, [values]);

  const handleMortgagePurpose = (event) => {
    const myMortgagePurpose = event.target.value;
    setMortgagePurpose(myMortgagePurpose);
  };

  return (
    <TilesContainer style={{ width: "100%" }} topBorder relative>
      {isDemo && <DemoExample />}
      <Grid
        container
        spacing={2}
        direction={"row"}
        justify={"flex-start"}
        alignItems={"stretch"}
      >
        <TitleGrid
          title={t("Mortgage.summary.title")}
          tooltipText={t("Mortgage.summary.tooltip")}
        />
        <Grid container item xs={12} spacing={isExtraSmallScreen ? 1 : 0}>
          <Grid item xs={12} sm={6} md={3}>
            <ResultComponent
              noBorder={isExtraSmallScreen}
              value={`${addingComma(MAX_MORTGAGE)} ₪`}
              classes={classes}
              classesForecast={classesForecast}
              id={`${t("Mortgage.summary.loanCeiling")}`}
              tooltip={`${t("Mortgage.summary.tooltipLoanCeiling")}`}
              borderRadius={"var(--br-right)"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ResultComponent
              noBorder={isSmallScreen}
              value={`${addingComma(totalPrincipal)} ₪`}
              classes={classes}
              classesForecast={classesForecast}
              id={`${t("Mortgage.summary.totalLoan")}`}
              tooltip={`${t("Mortgage.summary.tooltipTotalLoan")}`}
              borderRadius={0}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ResultComponent
              noBorder={isExtraSmallScreen}
              value={
                (MAX_MORTGAGE > 0 && totalPrincipal > 0
                  ? ((totalPrincipal / MAX_MORTGAGE) * 100).toFixed(2)
                  : 0) + "%"
              }
              classes={classes}
              classesForecast={classesForecast}
              id={`${t("Mortgage.summary.loanUsage")}`}
              tooltip={`${t("Mortgage.summary.tooltipLoanUsage")}`}
              borderRadius={0}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ResultComponent
              noBorder
              value={
                (totalPrincipal
                  ? ((totalPrincipal / cv) * 100).toFixed(2)
                  : 0) + "%"
              }
              classes={classes}
              classesForecast={classesForecast}
              id={`${t("Mortgage.summary.loanValueRatio")}`}
              tooltip={`${t("Mortgage.summary.tooltipLoanValueRatio")}`}
              borderRadius={"var(--br-left)"}
            />
          </Grid>
        </Grid>

        {rawData && (
          <>
            <Grid container style={{ padding: 16 }}>
              <TitleGrid title={t("Mortgage.paths.title")} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              lg={2}
              style={{ alignItems: "center" }}
            >
              <Grid item md={10} lg={11}>
                <div style={{ padding: 8 }}>
                  <InputLabel
                    className={classesLab.inputLabelSearch}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    *{t("Mortgage.paths.type")}
                  </InputLabel>
                  <Select
                    id="standard-number"
                    type="number"
                    style={{ width: "100%" }}
                    value={mortgagePurpose}
                    onChange={handleMortgagePurpose}
                    variant={"outlined"}
                    disabled={isLoading}
                  >
                    <MenuItem disabled value={" "}>
                      {t("Mortgage.paths.options.select")}
                    </MenuItem>
                    <MenuItem
                      value={t("Mortgage.paths.options.firstApartment")}
                    >
                      {t("Mortgage.paths.options.firstApartment")}
                    </MenuItem>
                    <MenuItem
                      value={t("Mortgage.paths.options.housingImprovement")}
                    >
                      {t("Mortgage.paths.options.housingImprovement")}
                    </MenuItem>
                    <MenuItem
                      value={t("Mortgage.paths.options.anotherApartment")}
                    >
                      {t("Mortgage.paths.options.anotherApartment")}
                    </MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item md={2} lg={1}>
                {errorsPrincipal.length > 0 && MAX_MORTGAGE <= 0 && (
                  <Typography
                    style={{
                      color: "red",
                      fontSize: XS_PLUS_FONT,
                    }}
                  >
                    {" "}
                    {t("Mortgage.paths.required")}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <MortgagePath
              setValues={setValues}
              values={values}
              MAX_MORTGAGE={MAX_MORTGAGE}
              cv={cv}
              totalPrincipal={totalPrincipal}
              setTotalPrincipal={setTotalPrincipal}
              errorsPrincipal={errorsPrincipal}
              setErrorsPrincipal={setErrorsPrincipal}
              isLoading={isLoading}
            />
          </>
        )}

        <Grid item xs={12}>
          <IconButton
            onClick={handleAddPath}
            disabled={
              totalPrincipal >= MAX_MORTGAGE || values.length >= 6 || isLoading
            }
            disableRipple
            disableFocusRipple
            color="primary"
            style={{
              cursor:
                totalPrincipal >= MAX_MORTGAGE || values.length >= 6
                  ? "not-allowed"
                  : "pointer",
              pointerEvents: "auto",
              borderRadius: 0,
              backgroundColor: "transparent",
            }}
          >
            <AddCircleOutlineIcon style={{ margin: "5px" }} />
            <Typography>{t("Mortgage.paths.addPath")}</Typography>
          </IconButton>
        </Grid>

        <Grid container item xs={12} justify={"space-between"}>
          <SubmitAndClearButtons
            isLoading={isLoading}
            onClearBtn={handleReset}
            submitTitle={`${t("calculate")}`}
            disabled={disabled}
            onClickBtn={handleSave}
          />
        </Grid>
      </Grid>
    </TilesContainer>
  );
};
export default InputMortgageDetails;
