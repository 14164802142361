import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { greyPropColor, mainPropColor } from "../../global-il/services/colors";
import { LG_FONT, LG_PLUS_FONT, MD_PLUS_FONT } from "../../global-il/services/fonts";
import { makeStyles } from "@material-ui/styles";
import "../assets/styles/index.css";
import { PropdoButton } from "../PropdoButton";
import { useIsMobileScreen } from "../../global-il/hooks/useIsMobileScreen.ts";

const useStyles = makeStyles({
  subtitle: (props) => ({
    fontSize: props.isMobileScreen ? MD_PLUS_FONT : LG_FONT
  })
})



export const WhyPropdo = () => {
  const isMobileScreen = useIsMobileScreen()
  const props = {isMobileScreen}
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className="padding"
      style={{ borderBottom: `1px solid ${greyPropColor}` }}
    >
      <Typography
        style={{
          fontSize: LG_PLUS_FONT,
          fontWeight: 600,
          padding: 12,
          textDecoration: "underline",
          textDecorationColor: "pink",
          textUnderlineOffset: 6,
        }}
      >
        {t("about.title")}
      </Typography>
      <Typography
        style={{
          textAlign: "center",
          padding: 12,
          color: greyPropColor,
        }}
        className={classes.subtitle}
      >
        {t("about.subtitle")}
      </Typography>
      <Grid
        container
        item
        xs={5}
        alignItems="center"
        justifyContent="center"
        style={{ padding: 12 }}
      >
        <a href="#contact">
          <PropdoButton
            text={t("buttons.contact")}
            style={{
              border: `1px solid ${mainPropColor}`,
              backgroundColor: "white",
              color: mainPropColor,
              margin: "15px"
            }}
          />
        </a>
        <a href="#solutions">
          <PropdoButton text={t("buttons.solutions")} />
        </a>
      </Grid>
    </Grid>
  );
};
