import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Doughnut } from "react-chartjs-2";
import { CustomTooltip, useCustomTooltip } from "../CustomTooltip";
import { addingComma } from "../../services/helpers";
import { CustomLegend } from "../CustomLegend.tsx";
import { fakeData } from "./BarLeverageRate";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import {
  darkBackground,
  darkText,
  darkThemeSliderRail,
  greyAxis,
} from "../../services/colors";
import { useStylesForecast } from "../../styledComponent/ForecastStyled";
import { ANIMATION_DURATION } from "../../services/consts";
import { TooltipInfoIcon } from "common-components";
import { useIsMobileScreen } from "common-components";
import Typography from "@material-ui/core/Typography";
import { FONT } from "../../services/fonts";
import { disableScrolling, enableScrolling } from "./MortgageLineChart";
import { datasetKeyProvider } from "../../components/dashboard-components/tama/LineChart";
import { useTranslation } from "react-i18next";

const legend = (data, t) => {
  return data.datasets[0].backgroundColor.map((item, index) => {
    return {
      color: item,
      label:
        index === 0
          ? t("Mortgage.doughnutGraph.interest")
          : t("Mortgage.doughnutGraph.fund"),
    };
  });
};

const MortgageDonutChart = ({ data }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [legendChart, setLegendChart] = useState(undefined);
  const chartRef = useRef();
  const [index, setCurrentIndex] = useState();
  const isSmallScreen = useIsMobileScreen();
  const { t } = useTranslation();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );

  const totalVal = useMemo(() => {
    return data.datasets[0].data.reduce(
      (accumulator, currentValue) => Number(accumulator) + Number(currentValue)
    );
  }, [data]);
  const percentageDonut = useCallback(
    (value) => {
      return value === 0
        ? null
        : parseFloat(((value / totalVal) * 100).toFixed(0)).toString() + "%";
    },
    [totalVal, data]
  );
  const isData = data.datasets[0].data.filter((item) => item !== undefined);
  const tooltip = {
    enabled: false,
    rtl: true,
    custom: customTooltip,
  };
  const noTooltip = { enabled: false };

  useEffect(() => {
    if (data) {
      setLegendChart(legend(data, t));
    }
  }, [data]);

  const isMobileScreen = useIsMobileScreen();

  const chartJsOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      animation: {
        duration: ANIMATION_DURATION,
      },
      legend: {
        display: false,
      },
      plugins: {
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
        datalabels: {
          color: "#FFF",
          formatter: percentageDonut,
          font: {
            size: isMobileScreen ? 12 : FONT,
            family: "Open Sans Hebrew, sans-serif",
          },
          anchor: "center",
          textAlign: "center",
        },
      },
      tooltips: isData.length === 0 || isData[0] === 0 ? noTooltip : tooltip,
    };
  }, [isData, isMobileScreen, isDarkTheme]);
  const classesForecast = useStylesForecast();

  return (
    <>
      <div
        className={classesForecast.divTooltipBoxStyle}
        style={{ padding: 0, marginBottom: 10 }}
      >
        <TooltipInfoIcon
          isDarkTheme={isDarkTheme}
          tooltipText={t("Mortgage.doughnutGraph.tooltip")}
        />

        <Typography
          variant={"h5"}
          style={{ paddingBottom: isSmallScreen ? 24 : "" }}
        >
          {t("Mortgage.doughnutGraph.title")}
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {legendChart && (
          <CustomLegend legend={legendChart} padding={"0 0 17px 0"} />
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: 250,
        }}
        onTouchStart={() => {
          disableScrolling();
        }}
        onTouchEnd={() => {
          enableScrolling();
        }}
      >
        <Doughnut
          ref={chartRef}
          data={
            isData.length === 0 || isData[0] === 0
              ? fakeData("No data", isDarkTheme)
              : data
          }
          datasetKeyProvider={datasetKeyProvider}
          legend={{
            display: false,
          }}
          options={chartJsOptions}
        />

        <CustomTooltip
          chartRef={chartRef}
          tooltipRef={tooltipRef}
          left={position.left}
          top={position.top}
          id="donut-mort"
          place="top"
        >
          <TooltipInner
            data={data}
            currentIndex={index}
            total={totalVal}
            legend={legendChart}
            isDarkTheme={isDarkTheme}
          />
        </CustomTooltip>
      </div>
    </>
  );
};
function TooltipInner({ data, currentIndex, total, legend, isDarkTheme }) {
  const { t } = useTranslation();
  if (data && currentIndex !== undefined && total !== undefined) {
    const currentValue = data.datasets[0].data[currentIndex];
    const percentage =
      parseFloat(((currentValue / total) * 100).toFixed(0)).toString() + "%";
    return (
      <div style={{ borderRadius: "var(--border-radius)" }}>
        <div
          style={{
            backgroundColor: isDarkTheme ? darkThemeSliderRail : "#f1f4fd",
            width: "100%",
            borderRadius: "var(--br-top)",
            padding: 8,
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            style={{
              color: isDarkTheme ? darkText : "black",
              textAlign: "end",
            }}
          >
            {data.labels[currentIndex] ===
              t("Mortgage.doughnutGraph.balance") &&
              t("Mortgage.doughnutGraph.interestRepayments")}
            {data.labels[currentIndex] !==
              t("Mortgage.doughnutGraph.balance") &&
              t("Mortgage.doughnutGraph.fundRepayments")}
          </Typography>
        </div>
        <div
          style={{
            backgroundColor: isDarkTheme ? darkBackground : "white",
            width: "100%",
            borderRadius: "var(--br-bottom)",
            padding: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "reverse",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ color: isDarkTheme ? darkText : greyAxis }}>
              {t("Mortgage.doughnutGraph.about")}
            </Typography>

            <Typography
              style={{ color: data.datasets[0].backgroundColor[currentIndex] }}
            >
              {percentage}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "reverse",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ color: isDarkTheme ? darkText : greyAxis }}>
              <bdi>{t("Mortgage.doughnutGraph.totalPayments")}</bdi>
              {legend[currentIndex].label}
            </Typography>

            <Typography
              style={{ color: data.datasets[0].backgroundColor[currentIndex] }}
            >
              {`${addingComma(Number(currentValue))} ₪`}
            </Typography>
          </div>
        </div>
      </div>
    );
  } else return null;
}
export default MortgageDonutChart;
