import React from "react";
import { IMAGES_LINK, LANDING_PAGE_LINK } from "common-components";
import { useStylesTopBar } from "../../styledComponent/TopBarStyled";

const Logo: React.FC = () => {
  const classes = useStylesTopBar();
  return (
    <div className={classes.divContainerLogo}>
      <img
        src={`${IMAGES_LINK}/dashboard/logos/Logo.svg`}
        alt="LogoSmall"
        onClick={() => {
          window.location.replace(LANDING_PAGE_LINK);
        }}
      />
    </div>
  );
};
export default Logo;
