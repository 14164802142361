import {
  darkText,
  darkMainPropColor,
  greyAxis,
  mainPropColor,
  darkBackground,
  lightBoxes,
  darkElements,
} from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";
import { LG_FONT, MD_FONT } from "../services/fonts";

export const useStylesSelectPropDetails = makeStyles({
  propertiesRoot: (props) => ({
    height: "100%",
    "& p": {
      textAlign: "left",
    },

    "& svg:hover": {
      fill: props.isDarkTheme ? darkMainPropColor : mainPropColor,
      stroke: props.isDarkTheme ? darkMainPropColor : mainPropColor,
      cursor: "pointer",
    },
    "& svg:hover + p": {
      color: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    },
  }),
  paragraphAddProp: (props) => ({
    color: props.isDarkTheme ? darkText : greyAxis,
    fontSize: MD_FONT,
    whiteSpace: "nowrap",
    textAlign: "center",
  }),
  pTitlePlanType: (props) => ({
    color: props.isDarkTheme ? darkText : greyAxis,
    textAlign: "left",
    fontSize: LG_FONT,
    fontWeight: 600,
  }),

  divRatingBox: (props) => ({
    display: "flex",
    alignItems: "center",
    "& label": {
      color: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    },
    "& .MuiRating-iconEmpty": {
      color: props.isDarkTheme ? darkText : "rgba(0, 0, 0, 0.26)",
    },
  }),
  divColumn: { display: "flex", alignItems: "center", flexDirection: "column" },
  pHeadingPlanType: (props) => ({
    color: props.isDarkTheme ? darkText : greyAxis,
    whiteSpace: "nowrap",
    fontWeight: 500,
    textAlign: "center",
  }),
  pTotalPlanType: (props) => ({
    color: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    fontSize: LG_FONT,
    textAlign: "center",
    paddingTop: 7,
    fontWeight: 600,
  }),
  selectContainer: (props) => ({
    backgroundColor: props.isDarkTheme ? darkBackground : lightBoxes,
    borderTopLeftRadius: "var(--border-radius)",
    borderBottomLeftRadius: "var(--border-radius)",
  }),
  selectContainerDetails: (props) => ({
    backgroundColor: props.isDarkTheme ? darkElements : "white",
    padding: "0 50px",
    borderTopRightRadius: "var(--border-radius)",
    borderBottomRightRadius: "var(--border-radius)",
  }),
  closeContainer: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});
