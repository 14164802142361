import { Grid } from "@material-ui/core";
import DemoExample from "../../DemoExample";
import SliderBox from "./SliderBox";
import DataLineChart from "../../../data/DataLineChart";
import { LoadingCharts } from "../../layout/Layout";
import React, { useContext, useEffect, useState } from "react";
import { useIsMobileScreen } from "common-components";
import { useIsIncludes } from "common-components";
import MapContainer from "../../map/MapContainer";
import SmallCardChange from "../../SmallCardChange";
import TilesContainer from "../../TilesContainer";
import { useLazyDataApi } from "common-components";
import { OpenAccessContext } from "../../../context/OpenAccessContext";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { useNotistack } from "common-components";
import { NOT_FOUND_MSG, SHARE_NOT_FOUND_MSG } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";
import { Helmet } from "react-helmet";
import { Login } from "common-components/src/global/components/login/Login";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";
import { useDialogManager } from "common-components/src/global-il/hooks/useDialogManager";

const Forecast = ({ SPACING_FACTOR, setSummaryPrice, toggleBRCall }) => {
  const [direction] = useContext(DirectionContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [propertyId, setIsProperty] = useContext(PropertyIdContext);
  const [{ data, error: lazyError }, lazyFetch] = useLazyDataApi();
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const isSmallScreen = useIsMobileScreen();
  const isDemo = useIsIncludes("demo");
  const [rawData, setRawData] = useState(undefined);
  const { t } = useTranslation();
  const [authorization] = useContext(AuthorizationContext);
  const { openDialog } = useDialogManager();

  // useNotistack(
  //   `${t("retrievalFailedMsg")}`,
  //   [NOT_FOUND_MSG, SHARE_NOT_FOUND_MSG].includes(lazyError)
  //     ? false
  //     : lazyError,
  //   undefined,
  //   "error"
  // );

  useEffect(() => {
    if (typeof propertyId === "object") {
      lazyFetch(
        `/reports/dashboard/page/summary?id=${
          isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
        }&lang=${direction.isRtl ? "he" : "en"}`
      );
    }
  }, [propertyId, toggleBRCall]);

  useEffect(() => {
    if (data) {
      setIsProperty(true);
      setRawData(data);
    }
  }, [data]);

  useEffect(() => {
    if (!authorization) {
      openDialog(Login, { DialogProps: { maxWidth: "xs" } });
    }
  }, [authorization]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("MetaData.dashboardTitle")}</title>
        <description>{t("MetaData.dashboardDescription")}</description>
      </Helmet>
      <Grid
        container
        spacing={SPACING_FACTOR}
        alignItems={"stretch"}
        justify={"center"}
        style={{
          paddingTop: 24,
          backgroundColor: "#e1edf7",
          margin: "0",
          width: "revert",
        }}
      >
        <Grid container item xs={12} md={4}>
          <Grid item xs={12}>
            <TilesContainer style={{ height: "100%" }} relative topBorder>
              {isDemo && (
                <DemoExample
                  ribbonLeft={isExtraSmallScreen ? "0px" : undefined}
                />
              )}
              <SliderBox
                isData={!!rawData}
                rawData={rawData ? rawData : undefined}
                setRawData={setRawData}
                setSummaryPrice={setSummaryPrice}
              />
            </TilesContainer>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={8}>
          <Grid item xs={12}>
            <TilesContainer style={{ height: "100%" }} relative>
              {isDemo && (
                <DemoExample
                  width={isExtraSmallScreen ? 100 : 125}
                  height={isExtraSmallScreen ? 100 : 125}
                />
              )}

              {rawData && rawData.fv_graph ? (
                <DataLineChart lineData={rawData.fv_graph} />
              ) : (
                <LoadingCharts
                  name={`${t("LineChart.title")}`}
                  height={isExtraSmallScreen ? "24%" : "70%"}
                  variant={"h5"}
                />
              )}
            </TilesContainer>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <MapContainer rawData={rawData} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            position: "relative",
            paddingBottom: isSmallScreen ? 24 : "",
          }}
        >
          <SmallCardChange data={rawData?.impact_point} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default Forecast;
