import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useDialogManager } from "../../hooks/useDialogManager";
import { LoadingGif } from "../loading/LoadingGif";
import { useNotistack } from "../../hooks/useNotistack";
import { PropdoButton } from "../buttons/PropdoButton";
import { FONT_TEXT, FONT_TITLE } from "../../../comps/services/constants/fonts";

const useStyles = makeStyles({
  input: {
    padding: "12.5px 14px",
  },
  label: {
    transform: "translate(14px,15.5px) scale(1)",
  },
});

export const ResetPassword = ({ setisResetPassword }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { closeDialog, openDialog } = useDialogManager();
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ defaultValues: { ...userDetails } });

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const data = await Auth.forgotPassword(values.email);
      setUserDetails({
        ...userDetails,
        email: data.CodeDeliveryDetails.Destination,
      });
      setMsg({ variant: "success", text: t("errors.successfulReset") });
      setTimeout(() => {
        setisResetPassword(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isSubmitSuccessful) return;
    reset({ email: "" });
  }, [isSubmitSuccessful, reset]);

  useNotistack(msg.text, msg, setMsg, msg.variant);

  return isLoading ? (
    <LoadingGif />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="email"
          type={"email"}
          label={t("login.email")}
          variant="outlined"
          inputProps={{ className: classes.input }}
          InputLabelProps={{ className: classes.label }}
          fullWidth
          {...register("email", { required: true })}
        />
        {errors.email && (
          <span style={{ color: "red", fontSize: FONT_TEXT, marginTop: 8 }}>
            {t("errors.must")}
          </span>
        )}
      </Grid>
      <Grid item xs={12}>
        <PropdoButton
          text={t("login.reset")}
          style={{
            width: "100%",
            border: "none",
            padding: "8px 14px",
            fontSize: FONT_TITLE,
          }}
          handleClick={handleSubmit(onSubmit)}
        />
      </Grid>
    </Grid>
  );
};
