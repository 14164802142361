import { makeStyles } from "@material-ui/core/styles";
import { XS_PLUS_FONT } from "../services/fonts";

export const useStylesReport = makeStyles({
  icon: { width: 20, height: 25 },
  paragraphDetails: {
    textAlign: "center",
    whiteSpace: "nowrap",
    padding: "0 5px",
    fontSize: XS_PLUS_FONT,
  },
  divContainerDetail: { display: "flex", alignItems: "center" },

  divContainerDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 10,
    alignItems: "center",
  },

  gridContainerDetails: {
    padding: "5px 0",
  },
});
