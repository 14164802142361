import React, { useContext, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useStylesForecast } from "../../styledComponent/ForecastStyled";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { doFetch, REPORTS_LINK } from "common-components";
import { PropertyIdContext } from "../../context/PropertyIdContext";
import { OpenAccessContext } from "../../context/OpenAccessContext";
import { useHistory } from "react-router-dom";
import { PaymentProcessContext } from "../../context/PaymentProcessContext";
import { useStylesUserLogin } from "../../styledComponent/UserLoginStyled";
import {
  propertyIL,
  PurchasePropertyContext,
} from "../../context/PurchasePropertyContext";
import { UserDetailsContext } from "../../context/UserDetailsContext";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import Typography from "@material-ui/core/Typography";
import { LG_FONT } from "../../services/fonts";
import { detectPage } from "../../components/top-bar/TopBarRoutes";
import { Routes } from "../../services/routes";
import { IMAGES_LINK } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  btn: {
    marginTop: 15,
    marginRight: 10,
  },
});

const MessagePropAdded = ({ title }) => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [, setProperty] = useContext(PurchasePropertyContext);
  const { closeDialog } = useDialogManager();
  const classes2 = useStylesUserLogin({ isDarkTheme });
  const [, setPaymentProcess] = useContext(PaymentProcessContext);
  const [, setUserDetails] = useContext(UserDetailsContext);
  const [isOpenAccess, setOpenAccess] = useContext(OpenAccessContext);
  let history = useHistory();
  const [, setPropertyId] = useContext(PropertyIdContext);
  const locationDashboard = {
    pathname: Routes.dashboardSummary,
  };
  const { t } = useTranslation();
  const classes = useStyles();
  const queryParams = new URLSearchParams(window.location.search);
  const address = queryParams.get("address");
  const googlePlaceId = queryParams.get("place_id");

  useEffect(() => {
    doFetch(
      `/user/icon?country=${direction?.country}&region=${direction?.region}&url=${window.location.href}`
    ).then((res) => {
      setUserDetails(res);
    });
  }, []);

  useEffect(() => {
    window.location.href = REPORTS_LINK;
  }, []);

  const classesForecast = useStylesForecast({ isDarkTheme });

  const handlePropForecast = () => {
    setPropertyId({ id: "-1" });
    if (isOpenAccess.access || isOpenAccess.page === "/user") {
      setOpenAccess({
        access: false,
        page: "",
        path: "property",
      });
    }
    setProperty(propertyIL);
    setPaymentProcess(false);
    closeDialog();
    history.push(locationDashboard);
    detectPage();
    queryParams.delete("address");
    queryParams.delete("place_id");
  };

  return (
    <div className={classes2.divRootContainer}>
      <div className={classesForecast.divPopupContainerIcon}>
        <img
          src={`${IMAGES_LINK}/dashboard/elements/house.svg`}
          alt="added"
          style={{ width: 70, height: 70 }}
        />
      </div>
      <div
        style={{
          padding: title === t("PaymentForm.paymentSuccess") ? "20px 40px" : 20,
        }}
      >
        <Typography
          align={"center"}
          style={{ margin: "30px 0 5px 0", fontSize: LG_FONT }}
        >
          {title ? title : `${t("MessagePropAdded.text")}`}
        </Typography>
        {title !== t("PaymentForm.paymentSuccess") && (
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handlePropForecast}
          >
            {t("MessagePropAdded.viewReport")}
          </Button>
        )}

        <Button
          variant="outlined"
          color="primary"
          style={{
            width: title === t("PaymentForm.paymentSuccess") ? "100%" : "",
            marginTop: 15,
            padding: "4px 16px",
          }}
          onClick={() => {
            closeDialog();
            queryParams.delete("address");
            queryParams.delete("place_id");
          }}
        >
          {t("MessagePropAdded.close")}
        </Button>
      </div>
    </div>
  );
};
export default MessagePropAdded;
