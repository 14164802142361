import { Grid } from "@material-ui/core";
import TilesContainer from "../../TilesContainer";
import RowBoxCurrent from "./RowBoxCurrent";
import PriceForecast from "./PriceForecast";
import React, { useContext, useEffect, useState } from "react";
import { LoadingCharts, SPACING_FACTOR } from "../../layout/Layout";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { useLazyDataApi } from "common-components";
import { OpenAccessContext } from "../../../context/OpenAccessContext";
import PropertyDetails from "./PropertyDetails";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { useHistory } from "react-router-dom";
import { AddressListingsContext } from "../../../context/AddressListingsContext";
import DemoExample from "../../DemoExample";
import { useIsIncludes } from "common-components";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import MessageNoProp from "./MessageNoProp";
import { AuthorizationContext } from "../../../context/AuthorizationContext";
import { Routes } from "../../../services/routes";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import {
  LANDING_PAGE_LINK,
  NOT_FOUND_MSG,
  SHARE_EXPIRED_MSG,
  SHARE_NOT_FOUND_MSG,
} from "common-components";
import PremiumGlobalSummary from "./PremiumGlobalSummary";
import CopyLinkVerification from "./CopyLinkVerification";
import MessageWrongProp from "../../../dialogs/dialog-components/MessageWrongProp";
import { hasValueInStorage } from "../../../services/helpers";
import { DirectionContext } from "../../../context/DirectionContext";
import { propTypeConverter } from "../../../dialogs/add-prop/SelectPropDetails";
import { PurchasePropertyContext } from "../../../context/PurchasePropertyContext";
import { UserDetailsContext } from "../../../context/UserDetailsContext";
import PurchasePlan from "../../../dialogs/pricing/PurchasePlan";

export async function refreshToken() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (err) throw err;
    });
  } catch (e) {
    console.log(e);
  }
}

const SummaryPrice = ({ rawData, setRawData, month, setMonth }) => {
  const [direction] = useContext(DirectionContext);
  const [authorization] = useContext(AuthorizationContext);
  const { openDialog, closeDialog } = useDialogManager();
  const [isDarkTheme, setDarkTheme] = useContext(DarkThemeContext);
  const [noProp, setNoProp] = useState(false);
  const [userDetails] = useContext(UserDetailsContext);
  const [{ data, error }, lazyFetch] = useLazyDataApi();
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [, setListingsAddress] = useContext(AddressListingsContext);
  const [propertyId, setPropertyId] = useContext(PropertyIdContext);
  const isDemo = useIsIncludes("demo");

  const isShare = useIsIncludes("share");
  const [fetchError, setFetchError] = useState({
    error: false,
    text: "",
    severity: "",
  });
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const address = queryParams.get("address");
  const googlePlaceId = queryParams.get("place_id");
  let history = useHistory();
  const [property, setProperty] = useContext(PurchasePropertyContext);

  useEffect(() => {
    if (typeof propertyId === "object") {
      lazyFetch(
        `/reports/dashboard/overview?id=${
          isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
        }&lang=${direction.isRtl ? "he" : "en"}`
      );
    }
  }, [propertyId]);

  useEffect(() => {
    if (error) {
      setNoProp(false);
      if (error === "Invalid Property ID") {
        history.replace({ pathname: Routes.dashboard });
        setPropertyId({ id: "-1" });
      } else if (userDetails?.balance > 0 && address && googlePlaceId) {
        setNoProp(true);
      } else if (userDetails?.balance === 0) {
        openDialog(PurchasePlan, {
          DialogProps: {
            maxWidth: "md",
          },
        });
      } else if (
        [SHARE_EXPIRED_MSG, SHARE_NOT_FOUND_MSG].includes(error) &&
        !property
      ) {
        openDialog(MessageWrongProp, {
          DialogProps: { fullScreen: false, scroll: "paper" },
          DialogContentProps: {
            text:
              error === SHARE_NOT_FOUND_MSG
                ? "נכס משותף לא נמצא"
                : "פג תוקף הקישור לנכס משותף. אנא צור קישור חדש לצפייה בנכס.",
            btnText: "חזור לדף הבית",
            callBack: () => window.location.replace(LANDING_PAGE_LINK),
          },
        });
      } else {
        // setFetchError({
        //   error: true,
        //   text: `${t("retrievalFailedMsg")}`,
        //   severity: "error",
        // });
      }
    }
  }, [error, userDetails]);

  useEffect(() => {
    if (noProp && authorization && !isDemo) {
      localStorage.setItem("noProp", "true");
      openDialog(MessageNoProp, {
        DialogProps: { fullScreen: false, scroll: "paper" },
      });
    } else if (!noProp && authorization) {
      closeDialog();
    }
  }, [noProp]);

  useEffect(() => {
    if (data) {
      if (noProp) setNoProp(false);
      const propType = propTypeConverter(data.property_details.details[0]);
      const details = [...data.property_details.details];
      details.splice(0, 1, propType);
      const newData = {
        ...data,
        property_details: {
          ...data.property_details,
          details: [...details],
        },
      };
      setRawData(newData);
      if (isOpenAccess.page === "/id") {
        setListingsAddress(data.property_details.address);
      } else {
        setListingsAddress(undefined);
      }
      setMonth(data?.property_details.date.split("-")[2]);
    }
  }, [data]);

  useEffect(() => {
    const isShareId = history.location.search.includes("id=S");
    if (
      isShare &&
      isShareId &&
      !error &&
      !hasValueInStorage(localStorage.getItem("share_recorded"))
    ) {
      // openDialog(CopyLinkVerification, {
      //   DialogProps: { fullScreen: false },
      //   DialogContentProps: { propertyId },
      // });
      localStorage.setItem("share_recorded", "true");
    }

    if (history.location.search.includes("id=SS")) {
      setDarkTheme(false);
    } else if (history.location.search.includes("id=SP")) {
      setDarkTheme(true);
    }
  }, [error]);

  useNotistack(
    fetchError.text,
    fetchError.error,
    undefined,
    fetchError.severity
  );

  return (
    <Grid
      container
      spacing={SPACING_FACTOR}
      alignItems={"stretch"}
      justify={"center"}
      style={{ padding: "12px 0" }}
    >
      <GlobalSummaryDetails
        isDemo={isDemo}
        data={rawData}
        setRawData={setRawData}
        isDarkTheme={isDarkTheme}
      />

      <GlobalSummaryPrice data={rawData} month={month} />
      {isDarkTheme && <PremiumGlobalSummary data={rawData} />}
    </Grid>
  );
};
export default SummaryPrice;

export const GlobalSummaryDetails = ({
  isDemo,
  isDarkTheme,
  data,
  setRawData,
}) => {
  return (
    <Grid container item xs={12} md={4}>
      <Grid item xs={12} style={{ paddingBottom: 24 }}>
        <TilesContainer padding={"16px 32px"} topBorder relative>
          {isDemo ? (
            <DemoExample height={"100%"} />
          ) : isDarkTheme && data?.premium.sold ? (
            <DemoExample height={"100%"} isSold={data?.premium.sold} />
          ) : null}
          {data ? (
            <PropertyDetails details={data} setRawData={setRawData} />
          ) : (
            <LoadingCharts name={""} height={"auto"} minHeight={"0"} />
          )}
        </TilesContainer>
      </Grid>
      <Grid item xs={12}>
        <TilesContainer
          padding={"16px 32px"}
          style={{ height: "100%" }}
          relative
        >
          {isDemo && <DemoExample height={"100%"} />}
          <RowBoxCurrent data={data?.overview.cv} />
        </TilesContainer>
      </Grid>
    </Grid>
  );
};

export const GlobalSummaryPrice = ({ data, month }) => {
  return (
    <Grid container item xs={12} md={8}>
      <Grid item xs={12}>
        <PriceForecast lineData={data?.overview.fv} month={month} />
      </Grid>
    </Grid>
  );
};
