// @ts-ignore

import { API_SERVER_URL } from "../../global/api/api_links";
import { AuthGetIdToken } from "../../global/services/helpers/auth";

/**
 * Empty for now. If we'll need our own props we will add them here.
 */
export interface UseFetchOptions extends RequestInit {}

/**
 * Wrapper around browser's fetch API.
 * Automatically manages access and refresh token under the hood.
 *
 * @param uri - uri to send request to.
 * @param options
 */
export function doFetch(uri: string, options?: UseFetchOptions): Promise<any> {
  console.debug(`Fetching ${uri}`);
  if (!options) {
    options = {};
  }
  if (!uri.startsWith("/")) {
    uri = "/" + uri; // prepend +leading slash in case it isn't there.
  }
  async function _doFetch(): Promise<any> {
    try {
      const response = await fetch(`${API_SERVER_URL}${uri}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await AuthGetIdToken()}`,
        },
        ...options,
      });
      const data = await response.json();
      return data;
    } catch (err) {
      //log the error and re-throw for further handling.
      console.error("Failed to fetch: ", err);
      throw err;
    }
  }

  return _doFetch();
}

/**
 * Refresh access token using the refresh token.
 */
