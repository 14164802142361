import {
  color4,
  darkBackground,
  darkThemeSliderRail,
  darkTurquoise,
} from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStylesForecast = makeStyles({
  divChartsHeader: {
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
    alignItems: "baseline",
  },

  divTooltipBoxStyle: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "baseline",
    justifyContent: "space-between",
    padding: "16px 16px 0 16px",
  },

  paperCalcChartBoxes: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  divWrapProgressBar: (props) => ({
    width: "100%",
    borderRadius: "var(--border-radius)",
    height: 13,
    overflow: "hidden",
    backgroundColor: props.isDarkTheme ? darkThemeSliderRail : "#ebebeb",
  }),

  divProgressBar: (props) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#ecf0f3",
    backgroundColor: props.isDarkTheme ? darkTurquoise : color4,
    textAlign: "center",
    whiteSpace: "nowrap",
    transition: "width 0.6s ease",
    boxShadow: "none",
    height: 13,
    borderRadius: "var(--border-radius)",
    "&:hover": {
      opacity: 0.7,
    },
  }),
  divPopupContainerIcon: (props) => ({
    position: "absolute",
    backgroundColor: props.isDarkTheme ? darkBackground : "#ffffff",
    borderRadius: "50%",
    width: 80,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "-36px",
  }),

  containerCta: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 15,
  },
});
