/**

 * Custom Hook to get access to DialogManager actions like openDialog and closeDialog.

 */

import { useContext } from "react";
import { DialogContext } from "../context/DialogContext";

export function useDialogManager() {
  return useContext(DialogContext);
}
