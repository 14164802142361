import { Grid } from "@material-ui/core";
import TilesContainer from "../../TilesContainer";
import Note from "../../Note";
import { LoadingCharts } from "../../layout/Layout";
import AskingPrice from "../../AskingPrice";
import PriceSold from "../../../outside-components/reports/cells/PriceSold";
import React from "react";

const PremiumGlobalSummary = ({ data }) => {
  return (
    <>
      <Grid item xs={12} md={data?.premium.sold ? 6 : 9}>
        <TilesContainer topBorder>
          {data && data.premium ? (
            <Note note={data.premium.note} />
          ) : (
            <LoadingCharts name={""} height={"auto"} minHeight={"0"} />
          )}
        </TilesContainer>
      </Grid>
      <Grid item xs={12} md={3}>
        <TilesContainer topBorder>
          {data && data.premium ? (
            <Grid container align="center">
              <AskingPrice
                price={data.premium.asking_price}
                fullWidth={!data.premium.sold}
              />
            </Grid>
          ) : (
            <LoadingCharts name={""} height={"auto"} minHeight={"0"} />
          )}
        </TilesContainer>
      </Grid>
      {data?.premium.sold ? (
        <Grid item xs={12} md={3}>
          <TilesContainer topBorder>
            {data && data.premium ? (
              <Grid container align="center">
                <PriceSold row={data.premium} isDashboard={true} />
              </Grid>
            ) : (
              <LoadingCharts name={""} height={"auto"} minHeight={"0"} />
            )}
          </TilesContainer>
        </Grid>
      ) : null}
    </>
  );
};
export default PremiumGlobalSummary;
