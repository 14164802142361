import React, { useContext, useEffect } from "react";
import { negativeRedColor } from "../../services/colors";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { PaymentProcessContext } from "../../context/PaymentProcessContext";
import { useStylesUserLogin } from "../../styledComponent/UserLoginStyled";
import { useStylesForecast } from "../../styledComponent/ForecastStyled";
import Button from "@material-ui/core/Button";
import { doFetch } from "common-components";
import {
  propertyIL,
  PurchasePropertyContext,
} from "../../context/PurchasePropertyContext";
import { UserDetailsContext } from "../../context/UserDetailsContext";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import Typography from "@material-ui/core/Typography";
import { LG_FONT } from "../../services/fonts";
import { IMAGES_LINK } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

const MessageWrongProp = ({ text, btnText, callBack }) => {
  const [direction] = useContext(DirectionContext);
  const [, setProperty] = useContext(PurchasePropertyContext);
  const [, setUserDetails] = useContext(UserDetailsContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes2 = useStylesUserLogin({ isDarkTheme });
  const { closeDialog } = useDialogManager();
  const [, setPaymentProcess] = useContext(PaymentProcessContext);
  const classesForecast = useStylesForecast({ isDarkTheme });
  const { t } = useTranslation();
  const handleClose = () => {
    closeDialog();
    setPaymentProcess(false);
  };

  useEffect(() => {
    setProperty(propertyIL);
    doFetch(
      `/user/icon?country=${direction?.country}&region=${direction?.region}&url=${window.location.href}`
    ).then((res) => {
      setUserDetails(res);
    });
  }, []);
  return (
    <div className={classes2.divRootContainer}>
      <div className={classesForecast.divPopupContainerIcon}>
        <img
          src={`${IMAGES_LINK}/dashboard/popups-icons/error.svg`}
          alt="Error"
          style={{ width: 70, height: 70 }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 315,
          padding: 20,
        }}
      >
        <Typography
          align={"center"}
          style={{
            padding: "22px 5px 20px ",
            fontSize: LG_FONT,
          }}
        >
          {text ? text : `${t("MessageWrongProp.text")}`}
        </Typography>
        <Button
          variant={"outlined"}
          style={{
            color: negativeRedColor,
            borderColor: negativeRedColor,
          }}
          onClick={callBack ? callBack : handleClose}
        >
          {btnText ? btnText : t("MessageWrongProp.close")}
        </Button>
      </div>
    </div>
  );
};
export default MessageWrongProp;
