import React, { createContext, useState } from "react";

export interface Tama {
  type: number;
  has_approval: 0 | 1;
  num_years: number;
  sqm: number;
  sqm_balcony: number;
  num_rooms: number;
  floor: number;
  num_floors: number;
  storage: 0 | 1;
  parking: 0 | 1;
}

export const TamaContext = createContext<any>({});
export const TamaContextProvider: React.FC = ({ children }) => {
  const [tama, setTama] = useState<Tama>({
    type: 0,
    has_approval: 0,
    num_years: -999,
    sqm: 0,
    sqm_balcony: 0,
    num_rooms: 0,
    floor: -999,
    num_floors: -999,
    storage: 0,
    parking: 0,
  });

  return (
    <TamaContext.Provider value={[tama, setTama]}>
      {children}
    </TamaContext.Provider>
  );
};
