import React, { useContext } from "react";
import { doFetch } from "common-components";
import { DataAddressesContext } from "../../../context/DataAddressesContext";
import EditCell from "./EditCell";

const TableAskingNickName = ({ row, value }) => {
  const [addresses, setAddresses] = useContext(DataAddressesContext);

  const handleSaveNickName = (
    setIsSaved,
    setLoading,
    setIsChangeNickName,
    nickName
  ) => {
    setLoading(true);
    doFetch(`/reports/premium/property/update?id=${row.id}`, {
      method: "POST",
      body: JSON.stringify({ nickname: nickName }),
    }).then((data) => {
      setLoading(false);
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
        setIsChangeNickName(false);
      }, 1000);
    });
    row.nickname = nickName;
    const newAddresses = { ...addresses.active };
    newAddresses.properties[row.index].premium.nickname = nickName;

    setAddresses((prevState) => ({ ...prevState, active: newAddresses }));
  };

  return <EditCell value={value} handleSaveValue={handleSaveNickName} />;
};
export default TableAskingNickName;
