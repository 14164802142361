import React, { useContext, useState } from "react";
import { Collapse } from "@material-ui/core";
import { useStylesRowBox } from "../../../styledComponent/RowBoxStyled";
import IconButton from "@material-ui/core/IconButton";
import { LoadingButtons } from "../../../components/layout/Layout";
import {
  darkMainPropColor,
  darkThemePositiveGreen,
  mainPropColor,
  positiveGreenColor,
} from "../../../services/colors";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import CreateIcon from "@material-ui/icons/Create";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  icon: (props) => ({
    color: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    width: 18,
    height: 18,
  }),
});

const EditCell = ({ value, handleSaveValue, mask, justDigits }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [inputValue, setInputValue] = useState(value);
  const [showInputField, setShowInputField] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChangeValue, setIsChangeValue] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const onChangeValue = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  const handleClickBtn = () => {
    setIsChangeValue(true);

    if (isChangeValue) {
      if (value !== inputValue) {
        if (justDigits) {
          const isNumbers = /^\d+$/.test(inputValue);
          if (!isNumbers) {
            setError(true);
            return;
          }
          handleSaveValue(setIsSaved, setLoading, setIsChangeValue, inputValue);
        } else {
          handleSaveValue(setIsSaved, setLoading, setIsChangeValue, inputValue);
        }
      } else {
        setIsChangeValue(false);
      }
      setShowInputField(false);
    } else {
      setShowInputField(true);
    }
  };

  const classes = useStylesRowBox();
  const classIcon = useStyles({ isDarkTheme });

  useNotistack(t("UserManagement.reports.errMsg"), error, setError, "error");

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Collapse
        in={showInputField}
        timeout={{ appear: 0, enter: 300, exit: 0 }}
        style={!showInputField ? { width: 0 } : undefined}
      >
        <input
          className={classes.inputAskingPrice}
          type="text"
          onChange={onChangeValue}
          value={inputValue}
        />
      </Collapse>
      <Typography noWrap style={{ fontSize: "0.875rem" }}>
        {!showInputField &&
          `${inputValue.length === 0 ? "--" : mask ? mask : inputValue}`}
      </Typography>
      <IconButton
        style={{ marginRight: 10 }}
        onClick={handleClickBtn}
        disabled={isSaved}
      >
        {loading ? (
          <LoadingButtons
            color={isDarkTheme ? darkMainPropColor : mainPropColor}
            style={{ width: 18, height: 18 }}
          />
        ) : isSaved ? (
          <DoneAllIcon
            className={classIcon.icon}
            style={{
              color: isDarkTheme ? darkThemePositiveGreen : positiveGreenColor,
            }}
          />
        ) : isChangeValue ? (
          value === inputValue ? (
            <ClearIcon className={classIcon.icon} />
          ) : (
            <CheckIcon className={classIcon.icon} />
          )
        ) : (
          <CreateIcon className={classIcon.icon} />
        )}
      </IconButton>
    </div>
  );
};
export default EditCell;
