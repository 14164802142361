import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { greyPropColor, mainPropColor } from "../../global-il/services/colors";
import { MD_PLUS_FONT } from "../../global-il/services/fonts";
import { useTranslation } from "react-i18next";
import { useIsMobileScreen } from "../..";
import { Logo } from "../Logo";
const useStyles = makeStyles({
  label: (props) => ({
    color: props.isTop ? "#fff" : mainPropColor,
    fontSize: MD_PLUS_FONT,
    fontWeight: 500,
    cursor: "pointer",
    textAlign: "center",
  }),
  linkContainer: (props) => ({
    borderTop: props.isMobileScreen ? `1px solid ${greyPropColor}` : "none",
    // width: props.isMobileScreen ? "50%" : null,
    maxHeight: props.isMobileScreen ? 80 : null,
    flexDirection: props.isMobileScreen ? "column" : null,
    '&:hover': {
       backgroundColor: props.isMobileScreen ? "rgba(0,17,69,0.78)" : null,
      //  color: props.isMobileScreen ? "#fff" : null,

    },
  }),
  logoContainer: (props) => ({
      maxHeight: props.isMobileScreen ? 100 : null
  })
});

export const NavbarLinks = ({ isTop }) => {
  const isMobileScreen = useIsMobileScreen();
  const classes = useStyles({ isTop, isMobileScreen });

  const { t } = useTranslation();
  return (
    <>
      {isMobileScreen && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={isMobileScreen ? 12 : 3}
          className={classes.logoContainer}
          // style={{maxHeight: "20px"}}
        >
          <Logo isTop={false} style={{ height: 50, width: 100 }}  />
        </Grid>
      )}
      <Grid
        container
        justifyContent={"center"}
        alignItems="center"
        item
        xs={isMobileScreen ? 12 : 2}
        className={classes.linkContainer}
      >
        <a href="#about">
          <Typography className={classes.label}>{t("navbar.about")}</Typography>
        </a>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        alignItems="center"
        item
        xs={isMobileScreen ? 12 : 4}
        className={classes.linkContainer}
      >
        <a href="#search-property">
          <Typography className={classes.label}>
            {t("navbar.claimReport")}
          </Typography>
        </a>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        alignItems="center"
        item
        xs={isMobileScreen ? 12 : 2}
        className={classes.linkContainer}
      >
        <a href="#testimonials">
          <Typography className={classes.label}>
            {t("navbar.testimonials")}
          </Typography>
        </a>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        alignItems="center"
        item
        xs={isMobileScreen ? 12 : 2}
        className={classes.linkContainer}
      >
        <a href="#contact">
          <Typography className={classes.label}>
            {t("navbar.contact")}
          </Typography>
        </a>
      </Grid>
    </>
  );
};
