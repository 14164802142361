import React, { useContext } from "react";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { darkMainPropColor, mainPropColor } from "../../services/colors";
import { useStylesTopBar } from "../../styledComponent/TopBarStyled";
import { useStylesReport } from "../../styledComponent/ReportItemStyled";
import { SelectedDetails } from "../../dialogs/payment/PropAddedPayment";
import { propDetailsList } from "../../services/consts";
import { useIsIncludes } from "common-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LightTooltip } from "../../outside-components/reports/TableReportsBody";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export const PropDetails: React.FC<{ details: (string | number)[] }> = ({
  details,
}) => {
  const { t } = useTranslation();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStylesTopBar();
  const classesIcon = useStylesReport();
  const isListings = useIsIncludes("listings");
  return (
    <div className={classes.divDetailsListContainer}>
      {details &&
        propDetailsList(
          details,
          isDarkTheme,
          classesIcon.icon,
          t,
          isListings
        ).map((item, index) => {
          return item.item === undefined ? null : (
            <SelectedDetails
              key={index}
              label={item.label}
              item={item.item}
              Icon={item.Icon}
              text={item.text}
            />
          );
        })}
    </div>
  );
};

const TopBarDetails: React.FC<{ details: (string | number)[] }> = ({
  details,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const { t } = useTranslation();
  const [isDarkTheme] = useContext(DarkThemeContext);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <LightTooltip
          isDarkTheme={isDarkTheme}
          title={<PropDetails details={details} />}
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={handleTooltipOpen}
          >
            <Typography>{`${t("TopBarDetails.showInfo")} ${
              String(details[0]).includes("דיר") ||
              String(details[0]).includes("apart")
                ? `${t("TopBarDetails.apartment")}`
                : `${t("TopBarDetails.property")}`
            }`}</Typography>

            <ExpandMoreIcon
              style={{
                color: isDarkTheme ? darkMainPropColor : mainPropColor,
                marginRight: "5px",
              }}
            />
          </div>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
};
export default TopBarDetails;
