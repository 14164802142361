import React, { createContext, useContext, useEffect, useState } from "react";
// import { IPricingPlans } from "../interfaces/subscription";
// import {
//   monthly,
//   packagePlan,
//   yearly,
// } from "../services/constants/pricingPlan";
import { useTranslation } from "react-i18next";
import { IPlan } from "../interfaces/subscription";
import { packagePlan, SINGLE_PLANS } from "../services/constants/pricingPlan";
import { useDirection } from "./DirectionContext";
import { Iconfig } from "../interfaces/countryConfig";

export const PaymentContext = createContext<any>({});

export const PaymentProvider: React.FC<Iconfig> = ({ children, config } : Iconfig) => {
  const { t } = useTranslation();
  const { direction } = useDirection();

  const [plans, setPlans] = useState<IPlan[]>([
    ...SINGLE_PLANS(t, config.country),
    ...packagePlan(t),
  ]);
  const [currPlan, setCurrPlan] = useState<object | null>(null);

  const setPlanByName = (name: string) => {
    const plan = plans.filter((item) => item.name === name)[0];

    setCurrPlan(plan);
  };

  return (
    <PaymentContext.Provider
      value={{
        plans,
        setPlans,
        currPlan,
        setCurrPlan,
        setPlanByName,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = () => useContext(PaymentContext);
