import React, { createContext, useContext, useEffect, useState } from "react";
import { OpenAccessContext } from "./OpenAccessContext";
import { AuthorizationContext } from "./AuthorizationContext";
import { useIsIncludes } from "common-components";

export const PropertyIdContext = createContext<any>({});

export const PropertyIdContextProvider: React.FC = ({ children }) => {
  const [authorization] = useContext(AuthorizationContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [propertyId, setPropertyId] = useState<{ id: string } | undefined>(
    undefined
  );
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const isShare = useIsIncludes("share");

  const [isProperty, setIsProperty] = useState(false);

  useEffect(() => {
    if (authorization) {
      if (isOpenAccess.page === "/listings") {
        setPropertyId(undefined);
      } else {
        setPropertyId({ id: id ? id : "-1" });
      }
    } else if (!authorization && isOpenAccess.page === "/id" && id) {
      setPropertyId({ id: id });
    } else if (isOpenAccess.page === "demo") {
      setPropertyId({ id: "-1" });
    }

    if (isShare && id) {
      setPropertyId({ id });
    }
  }, [isOpenAccess, authorization]);

  return (
    <PropertyIdContext.Provider
      value={[propertyId, setPropertyId, isProperty, setIsProperty]}
    >
      {children}
    </PropertyIdContext.Provider>
  );
};
