import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Controller, useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { FormCheckbox } from "../../../dialogs/add-prop/CheckboxPropDetails";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useIsMobileScreen } from "common-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { OpenAccessContext } from "../../../context/OpenAccessContext.tsx";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { darkElements } from "../../../services/colors";
import { Collapse, InputLabel } from "@material-ui/core";
import { useStylesTama } from "../../../styledComponent/TamaStyled";
import { useIsIncludes } from "common-components";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { tamaInputsOne, tamaInputsTwo } from "../../../services/consts";
import { useStylesAddProp } from "../../../styledComponent/AddPropStyled";
import ParkingInput from "../../../dialogs/add-prop/ParkingInput";
import Typography from "@material-ui/core/Typography";
import { XS_FONT, XS_PLUS_FONT } from "../../../services/fonts";
import { randomInt } from "../global-summary/RowBoxCurrent";
import { useLazyDataApi } from "common-components";
import SubmitAndClearButtons from "../../SubmitAndClearButtons";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import TitleGrid from "../../TitleGrid";
import { DirectionContext } from "../../../context/DirectionContext";

const useStylesImprovement = makeStyles({
  pharWeight: {
    fontSize: XS_PLUS_FONT,
    whiteSpace: "nowrap",
    fontWeight: 600,
  },
  phar: {
    fontSize: XS_FONT,
    whiteSpace: "nowrap",
    paddingBottom: 10,
  },
  select: (props) => ({
    backgroundColor: props.isDarkTheme ? darkElements : "#fff",
    fontSize: XS_PLUS_FONT,
    width: "18ch",
    padding: 11,
  }),
});
function findStage(num_years, has_approval) {
  if (num_years === 3) {
    return 1;
  } else if (num_years === 4 && has_approval) {
    return 1;
  } else if (num_years === 4 && !has_approval) {
    return 0;
  } else if (num_years >= 5 && num_years <= 8) {
    return 0;
  } else if (num_years === 2) {
    return 2;
  } else if (num_years === 1) {
    return 3;
  } else if (num_years === 0 && has_approval) {
    return 1;
  } else if (num_years === 0 && !has_approval) {
    return 0;
  }
}

export function callFetchData(
  api,
  lazyFetch,
  isOpenAccess,
  propertyId,
  dataUpdated
) {
  lazyFetch(
    `/reports/dashboard/page/tama/update?id=${
      isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
    }`,
    {
      method: "POST",
      body: JSON.stringify({
        type: dataUpdated.type_tama,
        approval: Number(dataUpdated.has_approval),
        num_years: dataUpdated.num_years,
        sqm: dataUpdated.shetach,
        sqm_balcony: dataUpdated.sqm_balcony,
        num_rooms: dataUpdated.num_rooms,
        floor: dataUpdated.floor,
        num_floors: dataUpdated.mispar_komot,
        storage: dataUpdated.storage,
        parking: dataUpdated.parking,
      }),
    }
  );
}

const PropertyImprovement = ({
  data,
  propDetails,
  setSummaryPrice,
  setStage,
  stage,
  setRawData,
}) => {
  const [direction] = useContext(DirectionContext);
  const [
    { data: lazyData, error: lazyError, isLoading },
    lazyFetch,
  ] = useLazyDataApi();
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const [propertyId] = useContext(PropertyIdContext);
  const [tama, setTama] = useState({
    type_tama: data[0],
    has_approval: !!data[1],
    num_years: data[2],
    sqm: data[3] ? data[3] : propDetails.sqm,
    sqm_balcony: data[4],
    num_rooms: data[5] ? data[5] : propDetails.num_rooms,
    floor: data[6],
    totalFloors: data[7],
    storage: !!data[8],
    parking: data[9] ? data[9] : "without",
  });

  const [isOpenAccess] = useContext(OpenAccessContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    tama: tama,
  });
  const [error, setError] = useState(false);
  const [isOpenTama, setIsOpenTama] = useState(!isExtraSmallScreen);

  const classes = useStylesImprovement({ isDarkTheme });

  useEffect(() => {
    reset({
      type_tama: data[0],
      has_approval: !!data[1],
      num_years: data[2] ? data[2] : 0,
      sqm: data[3] ? data[3] : "",
      sqm_balcony: data[4] ? data[4] : "",
      num_rooms: data[5] ? data[5] : "",
      floor: data[6] ? data[6] : "",
      totalFloors: data[7] ? data[7] : "",
      storage: !!data[8],
      parking: data[9] ? data[9] : "without",
    });
    setValue("has_approval", Number(!!data[1]));
    setValue("storage", !!data[8]);
  }, [data]);

  useEffect(() => {
    setStage(findStage(tama.num_years, tama.has_approval));
  }, [tama]);

  const onSubmit = (value) => {
    if (type_tama === 0) {
      setError(true);
    } else {
      const tamaUpdated = {
        ...tama,
        type_tama: type_tama,
        has_approval: value.has_approval,
        num_years: value.num_years,
        stage: stage + 1,
        shetach: Number(value.sqm),
        sqm_balcony: value.sqm_balcony ? Number(value.sqm_balcony) : data[4],
        num_rooms:
          value.num_rooms.length > 0
            ? Number(value.num_rooms)
            : Number(propDetails.num_rooms),
        floor: value.floor ? Number(value.floor) : data[6],
        mispar_komot: value.totalFloors ? Number(value.totalFloors) : data[7],
        storage: Number(value.storage),
        parking: value.parking,
      };
      setTama(tamaUpdated);

      if (
        (type_tama === 1 &&
          Number(value.sqm) + Number(value.sqm_balcony) <= 100) ||
        ((type_tama === 2 || type_tama === 3) &&
          Number(value.sqm) - propDetails.sqm + Number(value.sqm_balcony) < 51)
      ) {
        callFetchData(
          direction.api,
          lazyFetch,
          isOpenAccess,
          propertyId,
          tamaUpdated
        );
      } else {
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (lazyData) {
      setSummaryPrice((prevState) => ({
        ...prevState,
        summary: lazyData.summary,
      }));
      setRawData(lazyData);
    }
  }, [lazyData]);

  const onClearBtn = () => {
    setError(false);
    const tamaCleared = {
      ...tama,
      type_tama: 0,
      has_approval: 0,
      num_years: 0,
      shetach: 0,
      sqm_balcony: 0,
      num_rooms: 0,
      floor: 0,
      mispar_komot: 0,
      storage: 0,
      parking: "without",
      stage: 1,
    };
    setTama(tamaCleared);
    callFetchData(
      direction.api,
      lazyFetch,
      isOpenAccess,
      propertyId,
      tamaCleared
    );
    reset({
      type_tama: 0,
      has_approval: 0,
      num_years: 0,
      shetach: 0,
      sqm_balcony: 0,
      num_rooms: 0,
      floor: 0,
      mispar_komot: 0,
      storage: 0,
      parking: "without",
    });
    setStage(0);
  };

  const handleClickAway = () => {
    setError(false);
  };
  const type_tama = watch("type_tama");
  const has_approval = watch("has_approval");
  const storage = watch("storage");
  const props = { isDarkTheme };
  const classesSelect = useStylesTama(props);
  const isListings = useIsIncludes("listings");
  const classesLab = useStylesAddProp({ isDarkTheme });
  const { t } = useTranslation();
  const extraSmallScreen = useIsMobileScreen(370);

  useNotistack(`${t("updateSucceedMsg")}`, lazyData, undefined, "success");
  useNotistack(`${t("updateFailedMsg")}`, lazyError, undefined, "error");

  return (
    <Grid container>
      <TitleGrid title={t("Tama.titleForm")} />

      {isOpenTama && (
        <div style={{ display: isExtraSmallScreen ? "block" : "none" }}>
          <Button
            disabled={isListings}
            color="primary"
            onClick={() => setIsOpenTama(!isOpenTama)}
          >
            {t("Renovations.button")}
            <FontAwesomeIcon
              style={{ margin: "0 10px" }}
              icon={faChevronDown}
            />
          </Button>
        </div>
      )}
      <Collapse
        in={isExtraSmallScreen ? !isOpenTama : isOpenTama}
        timeout="auto"
        unmountOnExit
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            style={{ padding: "10px 0" }}
            alignItems={"stretch"}
            onClick={handleClickAway}
          >
            <Grid item xs={12}>
              <InputLabel
                className={classesLab.inputLabelSearch}
                style={{ whiteSpace: "nowrap" }}
              >
                {t("Tama.dropdownTitle")}
              </InputLabel>
              <Controller
                control={control}
                name="type_tama"
                id="type_tama"
                defaultValue={tama.type_tama}
                render={({ field: { onChange, value } }) => (
                  <Select
                    defaultValue={tama.type_tama}
                    value={value}
                    id="type_tama"
                    name="type_tama"
                    labelId="type_tama"
                    disabled={isLoading || isListings}
                    variant="outlined"
                    style={{
                      width: extraSmallScreen ? "88%" : "100%",
                      maxWidth: 200,
                    }}
                    onChange={onChange}
                  >
                    <MenuItem
                      className={classesSelect.select}
                      value={0}
                      disabled
                    >
                      &mdash;&mdash;
                    </MenuItem>
                    <MenuItem className={classesSelect.select} value={1}>
                      {t("Tama.tama1")}
                    </MenuItem>
                    <MenuItem className={classesSelect.select} value={2}>
                      {t("Tama.tama2")}
                    </MenuItem>
                    <MenuItem className={classesSelect.select} value={3}>
                      {t("Tama.tama3")}
                    </MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.pharWeight}>
                {t("Tama.status")}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <FormCheckbox
                name="has_approval"
                label={t("Tama.permit")}
                control={control}
                onClick={() => {
                  setValue("num_years", 0);
                  setValue("has_aproval", !has_approval);
                }}
                checked={has_approval}
                disabled={isLoading || isListings}
              />

              <Typography
                className={classes.phar}
                style={{ padding: isExtraSmallScreen ? "0 4px" : "0 15px" }}
              >
                {t("Tama.occupancy")}
              </Typography>

              <Controller
                control={control}
                name="num_years"
                defaultValue={tama.num_years}
                disabled={isLoading || isListings}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="num_years"
                    name="num_years"
                    labelId="num_years"
                    variant="outlined"
                    disabled={isLoading || isListings}
                    defaultValue={tama.num_years}
                    value={value}
                    style={{ height: "35px" }}
                    onChange={onChange}
                  >
                    {listApproval(has_approval).map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item === 0 ? t("Tama.dontKnow") : item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.pharWeight}>
                {type_tama === 2 || type_tama === 3
                  ? t("Tama.apartmentData")
                  : t("Tama.extension")}
              </Typography>
            </Grid>
            {tamaInputsOne(t, type_tama, propDetails.sqm).map((item, index) => {
              return (
                <Grid item xs={12} sm={6} key={index}>
                  <InputLabel
                    className={classesLab.inputLabelSearch}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {item.placeholder}
                  </InputLabel>
                  <TextField
                    id={item.id}
                    name={item.name}
                    autoComplete="off"
                    error={errors[item.id] !== undefined}
                    placeholder={item.placeholder}
                    variant="outlined"
                    disabled={isLoading || isListings}
                    size="small"
                    style={{ width: extraSmallScreen ? "" : "100%" }}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    {...register(item.name, item.register)}
                    inputProps={{
                      inputMode: item.inputMode,
                    }}
                  />
                  {item.errors.map((itemError, index) => {
                    if (errors[item.name]?.type === itemError.type) {
                      return (
                        <Typography
                          key={randomInt() + index}
                          className={classes.phar}
                          style={{ color: "red" }}
                        >
                          {itemError.text}
                        </Typography>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Grid>
              );
            })}
            {(type_tama === 2 || type_tama === 3) &&
              tamaInputsTwo(t, type_tama, propDetails.num_rooms, watch).map(
                (item, index) => {
                  return (
                    <Grid item xs={12} sm={6} key={index}>
                      <InputLabel
                        className={classesLab.inputLabelSearch}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {item.placeholder}
                      </InputLabel>
                      <TextField
                        id={item.id}
                        name={item.name}
                        autoComplete="off"
                        error={errors[item.id] !== undefined}
                        placeholder={item.placeholder}
                        variant="outlined"
                        disabled={isLoading || isListings}
                        size="small"
                        style={{ width: extraSmallScreen ? "" : "100%" }}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        InputProps={{
                          className: classes.textFiled,
                          inputProps: {
                            inputMode: item.inputMode,
                          },
                        }}
                        {...register(item.name, item.register)}
                      />
                      {item.errors.map((itemError, index) => {
                        if (errors[item.name]?.type === itemError.type) {
                          return (
                            <Typography
                              key={randomInt() + index}
                              className={classes.phar}
                              style={{ color: "red", fontSize: XS_FONT }}
                            >
                              {itemError.text}
                            </Typography>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Grid>
                  );
                }
              )}

            <Grid
              container
              alignItems={"flex-end"}
              item
              xs={12}
              sm={6}
              style={{ paddingRight: 10 }}
            >
              <ParkingInput
                control={control}
                register={register}
                classesLabel={classesLab.inputLabelSearch}
                inputProps={classes.select}
                width={extraSmallScreen ? "88%" : "100%"}
                name={"parking"}
                defaultValue={tama.parking}
                disabled={isLoading || isListings}
              />
            </Grid>
            <Grid
              container
              alignItems={"flex-end"}
              item
              xs={12}
              sm={6}
              md={2}
              style={{ paddingRight: 10 }}
            >
              <FormCheckbox
                name="storage"
                label={t("Tama.storage")}
                control={control}
                onClick={() => {
                  setValue("storage", !storage);
                }}
                checked={storage}
                disabled={isLoading || isListings}
              />
            </Grid>
          </Grid>

          <Typography
            className={classes.phar}
            style={{ color: "red", height: XS_FONT }}
          >
            {error && type_tama === 1 ? (
              t("Tama.rule100")
            ) : error && (type_tama === 2 || type_tama === 3) ? (
              <bdi>
                {t("Tama.totalArea")} {propDetails.sqm + 50} - {propDetails.sqm}{" "}
                {t("meter")}{" "}
              </bdi>
            ) : error && type_tama === 0 ? (
              t("Tama.select")
            ) : (
              ""
            )}
          </Typography>

          <Grid container>
            <SubmitAndClearButtons
              disabled={isLoading || isListings}
              isLoading={isLoading}
              onClearBtn={onClearBtn}
              submitTitle={t("Tama.submit")}
              onClickBtn={handleSubmit(onSubmit)}
            />
          </Grid>
        </form>
      </Collapse>
      {!isOpenTama && (
        <div style={{ display: isExtraSmallScreen ? "block" : "none" }}>
          <Button color="primary" onClick={() => setIsOpenTama(!isOpenTama)}>
            {t("Tama.minimizeWindow")}
            <FontAwesomeIcon style={{ margin: "0 10px" }} icon={faChevronUp} />
          </Button>
        </div>
      )}
    </Grid>
  );
};
export default PropertyImprovement;

const listApproval = (has_approval) => {
  return has_approval ? [1, 2, 3, 4, 0] : [4, 5, 6, 7, 8, 0];
};
