import React, { useContext, useState } from "react";
import { doFetch } from "common-components";
import { PropertyIdContext } from "../context/PropertyIdContext";
import { TooltipInfoIcon } from "common-components";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { darkMainPropColor } from "../services/colors";
import { useNotistack } from "common-components";
import { useIsIncludes } from "common-components";
import CheckIcon from "@material-ui/icons/Check";
import CreateIcon from "@material-ui/icons/Create";
import { LG_FONT } from "../services/fonts";
import { LoadingButtons } from "./layout/Layout";
import { useTranslation } from "react-i18next";
import { DarkThemeContext } from "../context/DarkThemeContext";

const Note: React.FC<{ note: string }> = ({ note }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [propertyId] = useContext(PropertyIdContext);
  const [textArea, setTextArea] = useState(note.length === 0 ? "--" : note);
  const [loading, setLoading] = useState(false);
  const [noteSaved, setNoteSaved] = useState(false);
  const [error, setError] = useState({ error: false, text: "" });
  const isShare = useIsIncludes("share");
  const [isChangeNote, setIsChangeNote] = useState(false);

  const { t } = useTranslation();

  useNotistack(t("updateSucceedMsg"), noteSaved, setNoteSaved, "success");
  useNotistack(t("updateFailedMsg"), error.error, setError, "error");

  const handleChange = (ev: any) => {
    const newNote = ev.target.value;
    setTextArea(newNote);
  };

  const onSaveNote = () => {
    if (propertyId && textArea.length > 0) {
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get("id") ? queryParams.get("id") : "-1";
      setLoading(true);
      doFetch(`/reports/premium/property/update?id=${id}`, {
        method: "POST",
        body: JSON.stringify({ note: textArea }),
      })
        .then((data) => {
          if (data.message.toLowerCase() === "Premium Updated".toLowerCase()) {
            setNoteSaved(true);
          } else {
            setError({ error: true, text: t("Note.saveError") });
          }

          setTimeout(() => {
            setIsChangeNote(false);
            setLoading(false);
          }, 300);
        })
        .catch((error) => {
          setError({ error: true, text: t("Note.saveError") });
          setLoading(false);
        });
    } else {
      setError({ error: true, text: t("Note.textError") });
    }
  };

  const handleClickBtn = () => {
    setIsChangeNote(true);
    if (isChangeNote) {
      onSaveNote();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} justify={"space-between"}>
        <Grid item>
          <Typography variant={"h5"}>{t("Note.title")}</Typography>
        </Grid>
        <Grid container justify="flex-end" item xs={2}>
          <TooltipInfoIcon
            tooltipText={t("Note.tooltip")}
            isDarkTheme={isDarkTheme}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginRight: 0 }}>
        <Grid container item xs={12} justify={"space-between"}>
          <Grid item xs={10} container alignItems="center">
            <Typography style={{ fontSize: LG_FONT, width: "100%" }}>
              {isChangeNote ? (
                <input
                  type="text"
                  onChange={handleChange}
                  value={textArea}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    borderBottom: `1px solid ${darkMainPropColor}`,
                    color: "white",
                    fontSize: LG_FONT,
                    width: "100%",
                  }}
                />
              ) : (
                textArea
              )}
            </Typography>
          </Grid>
          {!isShare && (
            <Grid container justify="flex-end" item xs={2}>
              <IconButton onClick={handleClickBtn} style={{ padding: 5 }}>
                {loading ? (
                  <LoadingButtons color={darkMainPropColor} size={"1x"} />
                ) : isChangeNote ? (
                  <CheckIcon
                    style={{
                      color: darkMainPropColor,
                      width: 22,
                      height: 22,
                    }}
                  />
                ) : (
                  <CreateIcon
                    style={{
                      color: darkMainPropColor,
                      width: 22,
                      height: 22,
                    }}
                  />
                )}
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Note;
