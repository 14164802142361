import React from "react";
import { ClassNameMap } from "@material-ui/styles/withStyles";
import { Order } from "./TableChart";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { TableSortLabel } from "@material-ui/core";
import { Column, RowTable } from "../../data/DataTableChart";

interface TableHeadPartProps {
  columns: Column[];
  orderBy: keyof RowTable | undefined;
  createSortHandler: (
    property: keyof RowTable
  ) => (event: React.MouseEvent<unknown>) => void;
  order: Order;
  classes: ClassNameMap;
}

const TableHeadPart: React.FC<TableHeadPartProps> = ({
  columns,
  orderBy,
  createSortHandler,
  order,
  classes,
}) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => {
          if (!column.hidden) {
            return (
              <TableCell
                key={column.id}
                style={{
                  fontWeight: 700,
                  fontFamily: "Open Sans Hebrew, sans-serif",
                  maxWidth: column.id === "eventName" ? 300 : "",
                  whiteSpace: "nowrap",
                }}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.notSortable ? (
                  <>{column.label}</>
                ) : (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};
export default TableHeadPart;
