import {
  Grid,
  Checkbox,
  TextField,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { useDialogManager } from "../../hooks/useDialogManager";
import { LoadingGif } from "../loading/LoadingGif";
import { useNotistack } from "../../hooks/useNotistack";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import { isAuthSignedin } from "../../services/helpers/auth";
import { PropdoButton } from "../buttons/PropdoButton";
import { LANDING_PAGE_LINK, PDF_LINK } from "../../../global/api/api_links";
import {
  FONT_TEXT,
  FONT_SUBTITLE,
  FONT_TITLE,
} from "../../../comps/services/constants/fonts";
import { useDirection } from "../../context/DirectionContext";
import ReadMore from "../readMore/ReadMore";
import { PricingPlan } from "../subscription/pricingPlans/PricingPlan";

const useStyles = makeStyles({
  input: {
    padding: "12.5px 14px",
  },
  label: {
    transform: "translate(14px,15.5px) scale(1)",
  },
});

const useCheckboxStyles = makeStyles({
  label: {
    fontSize: FONT_SUBTITLE,
  },
  root: {
    marginRight: 0,
  },
});

export const Register = ({ setIsRegister, config }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const checkboxClasses = useCheckboxStyles();
  const { direction } = useDirection();
  //Term link
  // const partOne = t("login.terms").slice(0, 16);
  // const partLink = t("login.terms").slice(16, 36);
  // const partTwo = t("login.terms").slice(36, 68);

  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    phone_number: "",
    username: "",
    password: "",
    terms: false,
    marketing: false,
  });
  const [authorization, setAuthorization] = useContext(AuthorizationContext);
  const { closeDialog, openDialog } = useDialogManager();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ defaultValues: { ...userDetails } });

  const onSubmit = async (data) => {
    try {
      const { user } = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          name: data.username,
          phone_number: direction.countryCode + data.phone_number,
          "custom:acc_type": "standard",
          "custom:marketing": `${Number(data.marketing)}`,
        },
      });
      setUserDetails({ ...data });
      setAuthorization(await isAuthSignedin());
      setMsg({ variant: "success", text: t("errors.successRegister") });
    } catch (err) {
      console.log(`err`, err.toString());
      if (
        err
          .toString()
          .includes("InvalidParameterException: Invalid email address format.")
      ) {
        setMsg({
          variant: "error",
          text: t("errors.invalidEmailAdressFormat"),
        });
      } else if (
        err
          .toString()
          .includes("InvalidParameterException: Invalid phone number format.")
      ) {
        setMsg({
          variant: "error",
          text: t("errors.invalidPhoneNumberFormat"),
        });
      } else if (
        err
          .toString()
          .includes(
            "UsernameExistsException: An account with the given email already exists."
          )
      ) {
        setMsg({ variant: "error", text: t("errors.userAlreadyExists") });
      } else if (
        err
          .toString()
          .includes(
            "InvalidPasswordException: Password did not conform with policy: Password not long enough"
          )
      ) {
        setMsg({ variant: "error", text: t("errors.passwordNotLong") });
      }
      setAuthorization(await isAuthSignedin());
    } finally {
      const user = await Auth.signIn(data.email, data.password);
      setAuthorization(await isAuthSignedin());
    }
  };

  const handleCheckbox = (ev) => {
    const isChecked = ev.target.checked;
    const value = ev.target.name;
    const updatedUserDetails = {};
    for (let item in userDetails) {
      if (item === value) updatedUserDetails[item] = isChecked;
      else updatedUserDetails[item] = userDetails[item];
    }
    setUserDetails({ ...updatedUserDetails });
  };

  useEffect(() => {
    if (!isSubmitSuccessful) return;
    reset({
      email: "",
      phone_number: "",
      password: "",
      terms: false,
      marketing: false,
    });
  }, [isSubmitSuccessful, reset]);

  useNotistack(msg.text, msg, setMsg, msg.variant);

  return isLoading ? (
    <LoadingGif />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="username"
          label={t("login.username")}
          type={"username"}
          variant="outlined"
          inputProps={{ className: classes.input }}
          InputLabelProps={{ className: classes.label }}
          fullWidth
          {...register("username", { required: true })}
        />
        {errors.username && (
          <span style={{ color: "red", fontSize: FONT_TEXT, marginTop: 8 }}>
            {t("errors.must")}
          </span>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="email"
          label={t("login.email")}
          type={"email"}
          variant="outlined"
          inputProps={{ className: classes.input }}
          InputLabelProps={{ className: classes.label }}
          fullWidth
          {...register("email", { required: true })}
        />
        {errors.email && (
          <span style={{ color: "red", fontSize: FONT_TEXT, marginTop: 8 }}>
            {t("errors.must")}
          </span>
        )}
      </Grid>
      <Grid container justifyContent="space-between" item xs={12}>
        <Grid item xs={8}>
          <TextField
            id="phone_number"
            label={t("login.phoneNumber")}
            variant="outlined"
            inputProps={{ className: classes.input }}
            InputLabelProps={{ className: classes.label }}
            fullWidth
            {...register("phone_number", { required: true })}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled
            id="area-code"
            label={t("login.areaCode")}
            variant="outlined"
            defaultValue={direction.countryCode}
            inputProps={{ className: classes.input }}
            InputLabelProps={{ className: classes.label }}
            fullWidth
          />
        </Grid>
        {errors.phone_number && (
          <span style={{ color: "red", fontSize: FONT_TEXT, marginTop: 8 }}>
            {t("errors.must")}
          </span>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="password"
          label={t("login.password")}
          type={"password"}
          variant="outlined"
          inputProps={{ className: classes.input }}
          InputLabelProps={{ className: classes.label }}
          fullWidth
          {...register("password", { required: true })}
        />
        {errors.password && (
          <span style={{ color: "red", fontSize: FONT_TEXT, marginTop: 8 }}>
            {t("errors.must")}
          </span>
        )}
      </Grid>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name={"terms"}
              required
              checked={userDetails.terms}
              onChange={handleCheckbox}
            />
          }
          {...register("terms", { required: true })}
          label={
            <div>
              <span>{t("login.terms1")}</span>
              {direction.country === "br" ? (
                <a
                  href={PDF_LINK + "/terms-and-conditions-br.pdf"}
                  download="termos e condições"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("login.terms2")}
                </a>
              ) : (
                <a
                  style={{ textDecoration: "Underline" }}
                  target="_blank"
                  href={PDF_LINK + "/listings-terms-and-conditions.pdf"}
                  rel="noreferrer"
                >
                  {t("login.terms2")}
                </a>
              )}
              <span>{t("login.terms3")}</span>
            </div>
          }
          classes={checkboxClasses}
        />

        <FormControlLabel
          control={
            <Checkbox
              name={"marketing"}
              checked={userDetails.marketing}
              onChange={handleCheckbox}
            />
          }
          {...register("marketing")}
          label={<ReadMore>{t("login.marketing")}</ReadMore>}
          classes={checkboxClasses}
        />
      </FormGroup>
      <Grid item xs={12}>
        <PropdoButton
          text={t("login.register")}
          style={{
            width: "100%",
            border: "none",
            padding: "8px 14px",
            fontSize: FONT_TITLE,
          }}
          handleClick={handleSubmit(onSubmit)}
        />
      </Grid>
    </Grid>
  );
};
