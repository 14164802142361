import { RTL, NotistackProvider } from "common-components";
import { AuthorizationContextProvider } from "../../context/AuthorizationContext";
import { DirectionContextProvider } from "../../context/DirectionContext";
import { OpenAccessContextProvider } from "../../context/OpenAccessContext";
import { UserDetailsContextProvider } from "../../context/UserDetailsContext";
import { DarkThemeContextProvider } from "../../context/DarkThemeContext";
import ThemeProviderComponent from "../ThemeProviderComponent";
import { PropertyIdContextProvider } from "../../context/PropertyIdContext";
import { DataAddressesContextProvider } from "../../context/DataAddressesContext";
import { PaymentProcessContextProvider } from "../../context/PaymentProcessContext";
import { TamaContextProvider } from "../../context/TamaContext";
import { PurchasePropertyContextProvider } from "../../context/PurchasePropertyContext";
import { DisplayProvider } from "../../context/DisplayContext";
import { PropertyProvider } from "../../context/PropertyContext";
import { MapboxProvider } from "../../context/MapboxContext";
import { AddressListingsContextProvider } from "../../context/AddressListingsContext";
import { DisabledTabsContextProvider } from "../../context/DisabledTabsContext";
import { ToggleSliderContextProvider } from "../../context/ToggleSliderContext";
import { DialogProvider } from "common-components/src/global/context/DialogContext";
import { DirectionProvider } from "common-components/src/global/context/DirectionContext";
import { AuthorizationProvider } from "common-components/src/global/context/AuthorizationContext";

const DIRECTION = {
  isRtl: true,
  country: "il",
  region: "",
  lang: "he",
  countryCode: "+972",
};

const ContextProviders = ({ children }) => {
  return (
    <AuthorizationProvider>
      <AuthorizationContextProvider>
        <DirectionContextProvider>
          <DirectionProvider config={DIRECTION}>
            <OpenAccessContextProvider>
              <DarkThemeContextProvider>
                <UserDetailsContextProvider>
                  <RTL>
                    <ThemeProviderComponent>
                      <NotistackProvider>
                        <PropertyIdContextProvider>
                          <DataAddressesContextProvider>
                            <PaymentProcessContextProvider>
                              <TamaContextProvider>
                                <PurchasePropertyContextProvider>
                                  <DisplayProvider>
                                    <PropertyProvider>
                                      <MapboxProvider>
                                        <AddressListingsContextProvider>
                                          <DisabledTabsContextProvider>
                                            <ToggleSliderContextProvider>
                                              <DialogProvider>
                                                {children}
                                              </DialogProvider>
                                            </ToggleSliderContextProvider>
                                          </DisabledTabsContextProvider>
                                        </AddressListingsContextProvider>
                                      </MapboxProvider>
                                    </PropertyProvider>
                                  </DisplayProvider>
                                </PurchasePropertyContextProvider>
                              </TamaContextProvider>
                            </PaymentProcessContextProvider>
                          </DataAddressesContextProvider>
                        </PropertyIdContextProvider>
                      </NotistackProvider>
                    </ThemeProviderComponent>
                  </RTL>
                </UserDetailsContextProvider>
              </DarkThemeContextProvider>
            </OpenAccessContextProvider>
          </DirectionProvider>
        </DirectionContextProvider>
      </AuthorizationContextProvider>
    </AuthorizationProvider>
  );
};
export default ContextProviders;
