import React, { useContext } from "react";
import { Grid, InputLabel, TextField } from "@material-ui/core";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { greyAxis } from "../../services/colors";
import { DirectionContext } from "../../context/DirectionContext";

const UserManagementInput = ({
  title,
  name,
  type,
  value,
  disabled,
  register,
  registerOptions,
  onChange,
  errors,
  errorsMsgs,
  variant,
  placeholder,
  InputProps,
  toolTip,
  xs,
  ...rest
}) => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme, width: "100%" };
  const classes = useStylesAddProp(props);
  return (
    <Grid
      container
      item
      xs={12}
      alignItems={"center"}
      justify={"flex-start"}
      spacing={1}
    >
      <Grid container item xs={"auto"} sm={xs[0]} justify={"flex-start"}>
        <InputLabel
          className={classes.inputLabelSearch}
          style={{ whiteSpace: "nowrap" }}
        >
          {title} {toolTip}
        </InputLabel>
      </Grid>
      {name === "phone_number" && !direction.isRtl && (
        <Grid item xs={4} sm={2} style={{ direction: "ltr" }}>
          <CssSignTextFieldDisabled
            disabled
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={"+972"}
          />
        </Grid>
      )}
      <Grid
        container
        item
        xs={name === "phone_number" ? xs[1] : "auto"}
        sm={xs[1]}
        justify={"flex-start"}
        dir={name === "phone_number" ? "ltr" : "rtl"}
      >
        <TextField
          id={name}
          name={name}
          {...register(name, registerOptions)}
          disabled={disabled}
          type={type}
          value={value}
          onChange={(event) => onChange(event)}
          error={errors !== undefined}
          placeholder={placeholder}
          variant={variant}
          size="small"
          style={{ width: "100%" }}
          onFocus={(event) => {
            event.target.select();
          }}
          InputProps={InputProps}
          {...rest}
        />

        {errors?.type === "required" && (
          <Typography className={classes.paragraphErrorAddProp}>
            {errorsMsgs.required}
          </Typography>
        )}
        {errors?.type === "maxLength" && (
          <Typography className={classes.paragraphErrorAddProp}>
            {errorsMsgs.maxLength}
          </Typography>
        )}
        {errors?.type === "pattern" && (
          <Typography className={classes.paragraphErrorAddProp}>
            {errorsMsgs.pattern}
          </Typography>
        )}
        {errors?.type === "minLength" && (
          <Typography className={classes.paragraphErrorAddProp}>
            {errorsMsgs.minLength}
          </Typography>
        )}
        {errors?.type === "validate" && (
          <Typography className={classes.paragraphErrorAddProp}>
            {errorsMsgs.notEqual}
          </Typography>
        )}
      </Grid>
      {name === "phone_number" && direction.isRtl && (
        <Grid item xs={4} sm={2} style={{ direction: "ltr" }}>
          <CssSignTextFieldDisabled
            disabled
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={"+972"}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default UserManagementInput;

export const CssSignTextFieldDisabled = withStyles({
  root: {
    color: greyAxis,
    "& .MuiOutlinedInput-root": {
      "& input": { textAlign: "left" },
      backgroundColor: "white",
      color: greyAxis,
      "& fieldset": {
        borderColor: greyAxis,
      },
      "&.Mui-focused fieldset": {
        borderColor: greyAxis,
      },
    },
  },
})(TextField);
