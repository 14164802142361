import React, { useContext } from "react";
import { useStylesTopBar } from "../../styledComponent/TopBarStyled";
import { useIsMobileScreen } from "common-components";
import { UserDetailsContext } from "../../context/UserDetailsContext";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { LightTooltip } from "../../outside-components/reports/TableReportsBody";
import { darkText } from "../../services/colors";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

function BalanceValue({ userDetails }: any) {
  const isSmallScreen = useIsMobileScreen();
  const props = { isSmallScreen: isSmallScreen };
  const { t } = useTranslation();
  const classes = useStylesTopBar(props);
  return (
    <div className={classes.divBalanceCenter}>
      <Typography> {t("Tabs.balance")}:</Typography>
      <Typography style={{ padding: "0 5px" }}>
        {userDetails.balance}
      </Typography>
    </div>
  );
}

const Balance: React.FC<{ isMediumScreen?: boolean }> = ({
  isMediumScreen,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [userDetails] = useContext(UserDetailsContext);
  const isSmallScreen = useIsMobileScreen();
  const props = { isSmallScreen: isSmallScreen };
  const classes = useStylesTopBar(props);

  return (
    <div className={classes.divBalanceContainer}>
      {userDetails && !isMediumScreen && (
        <BalanceValue userDetails={userDetails} />
      )}
      {userDetails && isMediumScreen && (
        <LightTooltip
          isDarkTheme={isDarkTheme}
          title={<BalanceValue userDetails={userDetails} />}
          arrow
          enterTouchDelay={50}
        >
          <AccountBalanceWalletIcon
            style={{ color: isDarkTheme ? darkText : "" }}
          />
        </LightTooltip>
      )}
    </div>
  );
};
export default Balance;
