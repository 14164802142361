import { useTranslation } from "react-i18next";
import { usePayment } from "../../../context/PaymentContext";
import { SINGLE_PLANS } from "../../../services/constants/pricingPlan";
import { useIsLargeScreen } from "../../../hooks/useIsLargeScreen";
import { SinglePlanItem } from "./SinglePlanItem";
import { useDirection } from "../../../context/DirectionContext";

export const SinglePlanList = () => {
  const { t } = useTranslation();
  const { setPlanByName, currPlan } = usePayment();
  const isDesktopScreen = useIsLargeScreen("md");
  const { direction } = useDirection();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = (event.target as HTMLInputElement).value;
    if (currPlan?.name === name) {
      setPlanByName(null);
    } else setPlanByName(name);
  };

  return (
    <>
      {SINGLE_PLANS(t, direction.country).map((item, idx) => {
        return (
          <SinglePlanItem handleChange={handleChange} plan={item} key={idx} />
        );
      })}
    </>
  );
};
