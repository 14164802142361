import { Grid, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  FONT_TEXT,
  FONT_TEXT_SECONDARY,
  FONT_TITLE_LG,
} from "../../../services/constants/fonts";
import { PropdoButton } from "../../buttons/PropdoButton";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { IMAGES_LINK } from "../../../api/api_links";
import { borderRadius, negative, white } from "../../../services/colors";

const errMessage = (msgs: any, c: any) => {
  let message = msgs ? msgs.find((x: any) => x === c) : "";

  if (message) {
    return message;
  }
  return "";
};

const ErrorMsg = (props: any) => {
  const err = props.msg;

  if (!err || err === "") {
    return <></>;
  }
  const errorMessage = "error_" + err;
  return (
    <div
      className="cross-validation-error-message alert alert-danger"
      style={{
        color: negative,
        position: "relative",
        marginBottom: 12,
        fontSize: FONT_TEXT_SECONDARY,
        fontWeight: 600,
      }}
    >
      <span>{props.t(`pricing.${errorMessage}`)}</span>
    </div>
  );
};

const useStyles = makeStyles({
  formControl: {
    height: "80px",
  },
});

export const PurchaseForm: React.FC<{
  handleSubmit: any;
  msg: any;
  handleLocalInputs: any;
}> = ({ handleSubmit, msg, handleLocalInputs }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const responseMsgs = msg;

  return (
    <form
      id={"payment_form"}
      style={{ marginTop: 15, maxWidth: 450 }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={5}>
            <label htmlFor="fullName">{t("pricing.fullName")}</label>
            <input
              id="fullName"
              placeholder={t("pricing.fullName")}
              className={classes.formControl}
              style={{
                height: "auto",
                width: "100%",
                padding: "16px 13px",
                borderRadius: 8,
                border: "1px solid #F2F3F4",
                marginTop: 15,
              }}
              type={"text"}
              onChange={(ev) => handleLocalInputs(ev.target.value, "fullName")}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <label htmlFor="full_name">{t("pricing.numberId")}</label>
            <input
              id="numberId"
              placeholder={t("pricing.numberId")}
              className={classes.formControl}
              style={{
                height: "auto",
                width: "100%",
                padding: "16px 13px",
                borderRadius: 8,
                border: "1px solid #F2F3F4",
                marginTop: 15,
              }}
              type={"text"}
              onChange={(ev) => handleLocalInputs(ev.target.value, "numberId")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="credit_card_num" style={{ marginBottom: 15 }}>
            {t("pricing.creditCard")}
          </label>
          <div id="credit_card_num" className={classes.formControl} />
          <div id="errors_for_number" className="error_message"></div>
          <ErrorMsg
            msg={errMessage(responseMsgs, "credit_card_number")}
            t={t}
          ></ErrorMsg>
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={5}>
            <label htmlFor="expiry" style={{ marginBottom: 15 }}>
              {t("pricing.expiry")}
            </label>
            <div className={classes.formControl} id="expiry"></div>
            <div id="errors_for_expiry" className="error_message"></div>
            <ErrorMsg msg={errMessage(responseMsgs, "expiry")} t={t}></ErrorMsg>
          </Grid>
          <Grid item xs={5}>
            <label htmlFor="cvv" style={{ marginBottom: 15 }}>
              CVV
            </label>
            <div className={classes.formControl} id="cvv"></div>
            <div id="errors_for_cvv" className="error_message"></div>
            <ErrorMsg msg={errMessage(responseMsgs, "cvv")} t={t}></ErrorMsg>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 15 }}>
        {/* @ts-ignore */}
        <Button
          type="submit"
          style={{
            width: "100%",
            fontSize: FONT_TITLE_LG,
            backgroundColor: "#001145",
            color: "#fff",
            border: "2px solid #fff",
            borderRadius: "8px",
            textTransform: "capitalize",
          }}
        >
          {t("pricing.buy")}
        </Button>
      </Grid>
      <Grid container item xs={6} style={{ margin: "20px auto 0" }}>
        {[1, 2, 3].map((item) => {
          return (
            <Grid container item justifyContent="center" xs={4}>
              <img
                src={IMAGES_LINK + `/homepage/secure${item}.png`}
                alt={"secure"}
                style={{ width: "100%", height: 30, objectFit: "contain" }}
              />
            </Grid>
          );
        })}
      </Grid>
    </form>
  );
};
