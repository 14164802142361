import { Auth } from "aws-amplify";
import awsmobile from "../../../aws-exports";
import { DOMAIN } from "../../api/api_links";

////////////////////////////////////////////////////////////////////////////////////////
// AMPLIFY AUTH

export function AuthConfigure(subDomain) {
  awsmobile.oauth.domain = `auth.${process.env.REACT_APP_BASE_URL}`;
  awsmobile.oauth.redirectSignIn = awsSignRedirect(subDomain);
  awsmobile.oauth.redirectSignOut = awsSignRedirect(subDomain);
  Auth.configure(awsmobile);
  Auth.configure({
    cookieStorage: {
      domain: DOMAIN,
      secure: false,
      path: "/",
      expires: 30,
    },
  });
}

export async function AuthGetIdToken() {
  try {
    const data = await Auth.currentSession();
    const idToken = data.getIdToken();
    const jwt = idToken.getJwtToken();
    return jwt;
  } catch {
    return null;
  }
}

export async function isAuthSignedin() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

export async function AuthSignOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export function awsSignRedirect(subDomain) {
  if (window.location.host.includes("localhost"))
    return `http://localhost:3000/`;
  if (subDomain)
    return `https://${subDomain}.${process.env.REACT_APP_BASE_URL}`;
  else return `https://${process.env.REACT_APP_BASE_URL}`;
}

export function awsSignoutRedirect() {
  if (window.location.host.includes("localhost"))
    return `http://localhost:3000/`;
  return `https://${process.env.REACT_APP_BASE_URL}`;
}
