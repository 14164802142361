import { Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useIsIncludes } from "common-components";
import { OpenAccessContext } from "../../../context/OpenAccessContext";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { useLazyDataApi } from "common-components";
import TamaToggleForecast from "../tama/TamaToggleForecast";
import TilesContainer from "../../TilesContainer";
import DemoExample from "../../DemoExample";
import { LoadingCharts, SPACING_FACTOR } from "../../layout/Layout";
import TamaLineChart from "../tama/TamaLineChart";
import RenovationsDetails from "./RenovationsDetails";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";
import { Helmet } from "react-helmet";

const RenovationsComponent = ({ month }) => {
  const [direction] = useContext(DirectionContext);
  const isDemo = useIsIncludes("demo");
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [propertyId] = useContext(PropertyIdContext);
  const [{ data, error }, lazyFetch] = useLazyDataApi();
  const [rawData, setRawData] = useState(undefined);
  const { t } = useTranslation();

  useNotistack(`${t("retrievalFailedMsg")}`, error, undefined, "error");

  useEffect(() => {
    if (typeof propertyId === "object") {
      lazyFetch(
        `/reports/dashboard/page/renovations?id=${
          isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
        }`
      );
    }
  }, [propertyId]);

  useEffect(() => {
    if (data) {
      setRawData(data);
    }
  }, [data]);

  return (
    <div>
      <Grid
        container
        spacing={SPACING_FACTOR}
        style={{ backgroundColor: "#e1edf7", margin: "0", width: "revert" }}
      >
        <Helmet>
          <title>{t("MetaData.renovationsTitle")}</title>
          <description>{t("MetaData.renovationsDescription")}</description>
        </Helmet>
        <Grid item xs={12}>
          <TilesContainer style={{ height: "100%", border: "none" }} relative>
            <Typography variant={"h5"}>{t("Renovations.title")}</Typography>
          </TilesContainer>
        </Grid>
        <Grid item xs={12}>
          <TamaToggleForecast
            lineData={rawData?.renovations_graph}
            name={t("Renovations.forecastTitle")}
            toggleSlider
            month={month}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <TilesContainer relative topBorder>
            {isDemo && <DemoExample />}
            {rawData ? (
              <RenovationsDetails
                renovations_details={rawData.renovations_details}
                setRawData={setRawData}
              />
            ) : (
              <LoadingCharts variant={"h5"} name={t("Renovations.formTitle")} />
            )}
          </TilesContainer>
        </Grid>
        <Grid item xs={12} sm={7} md={8}>
          {rawData ? (
            <TamaLineChart
              lineData={rawData.renovations_graph}
              title={t("Renovations.lineChart")}
            />
          ) : (
            <TilesContainer relative>
              <LoadingCharts variant={"h5"} name={t("Renovations.lineChart")} />
            </TilesContainer>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default RenovationsComponent;
