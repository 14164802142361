import React, { useContext } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useIsLargeScreen } from "common-components";
import { useStylesUserLogin } from "../../styledComponent/UserLoginStyled";
import { darkMainPropColor, mainPropColor } from "../../services/colors";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import Typography from "@material-ui/core/Typography";

const GrayCheckbox = withStyles({
  root: (props) => ({
    color: "#d1d7e2",
    backgroundColor: props.isDarkTheme ? "" : "#FFF",
    padding: 5,
  }),
  checked: (props) => ({
    color: props.isDarkTheme
      ? `${darkMainPropColor} !important`
      : `${mainPropColor} !important`,
  }),
})((props) => (
  <Checkbox color="default" {...props.register(props.name)} {...props} />
));

const UserManagementCheckbox = ({
  name,
  register,
  disabled,
  checked,
  inputREf,
  label,
  errors,
  errorsMsg,
  onChange,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const isLargeScreen = useIsLargeScreen();
  const classes = useStylesUserLogin({ isDarkTheme });

  return (
    <div>
      <FormControlLabel
        control={
          <GrayCheckbox
            isDarkTheme={isDarkTheme}
            defaultValue={true}
            id={name}
            name={name}
            register={register}
            disableRipple
            size={isLargeScreen ? "medium" : "small"}
            disabled={disabled}
            checked={checked}
            inputRef={inputREf}
            onChange={(event) => onChange(event)}
          />
        }
        label={label}
      />
      {errors?.type === "required" && (
        <div style={{ marginRight: 24 }}>
          <Typography
            style={{ textAlign: "right" }}
            className={classes.errorsStyle}
          >
            {errorsMsg.required}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default UserManagementCheckbox;
