import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { blue_1, orange_1, white } from "../../services/colors";
import { LoginForm } from "./LoginForm";
import { SocialLogin } from "./SocialLogin";
import { useState, useLayoutEffect, useEffect, useContext } from "react";
import { Register } from "./Register";
import { useIsLargeScreen } from "../../hooks/useIsLargeScreen";
import { ResetPassword } from "./ResetPassword";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { PropdoButton } from "../buttons/PropdoButton";
import { FONT_TEXT } from "../../../comps/services/constants/fonts";
import { useDirection } from "../../context/DirectionContext";
import { useDialogManager } from "../../hooks/useDialogManager";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import { COMPS_LINK, IMAGES_LINK } from "../../api/api_links";
import { useIsIncludes } from "../../hooks/useIsIncludes";
import { useIsBaseRoute } from "../../hooks/useIsBaseRoute";
import { ForgotPassword } from "./ForgotPassword";

const useStyles = makeStyles({
  with: (props) => ({
    "&::before,&::after": {
      display: "inline-block",
      content: `""`,
      borderTop: `1px solid ${blue_1}`,
      width: props.isDesktopScreen ? "90px" : "69px",
      margin: "0 10px",
      transform: "translateY(-2px)",
    },
  }),
});

export const Login = ({ config }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const codeParams = queryParams.get("reset-password-code");
  const emailParams = queryParams.get("email");

  const isDesktopScreen = useIsLargeScreen("md");
  const props = { useIsLargeScreen };
  const { t } = useTranslation();
  const classes = useStyles(props);
  const [isRegister, setIsRegister] = useState(false);
  const [isResetPassword, setisResetPassword] = useState(false);
  const [isRtl, setIsRtl] = useState(true);
  const { direction } = useDirection();
  const { closeDialog, openDialog } = useDialogManager();
  const [authorization] = useContext(AuthorizationContext);
  const isComps = useIsBaseRoute("comps");
  const isHomepage = useIsBaseRoute("homepage");

  const rtlTheme = createTheme({ direction: "rtl" });
  const ltrTheme = createTheme({ direction: "ltr" });

  useLayoutEffect(() => {
    document.body.setAttribute("dir", isRtl);
  }, [isRtl]);

  const handleSwitch = (value) => {
    const isRegister = value === "register";
    setIsRegister(isRegister);
  };

  useEffect(() => {
    if (authorization) {
      closeDialog();
    }
  }, [authorization]);

  useEffect(() => {
    if (codeParams && emailParams && !authorization) {
      openDialog(ForgotPassword, {
        DialogProps: { maxWidth: "md" },
        DialogContentProps: { codeParams, emailParams },
      });
    }
  }, []);

  return (
    <Grid
      container
      style={{
        padding: isDesktopScreen ? "64px" : "24px",
        position: "relative",
        fontSize: FONT_TEXT,
      }}
      justifyContent="center"
      direction="column"
    >
      {isHomepage && (
        <Grid container item xs={12} justifyContent="flex-end">
          <img
            src={IMAGES_LINK + "/homepage/exit.svg"}
            alt={"exit"}
            onClick={() => closeDialog()}
            style={{ cursor: "pointer" }}
          />
        </Grid>
      )}
      {!isResetPassword && (
        <Grid
          container
          item
          xs={12}
          style={{ marginTop: 14, paddingBottom: 30, fontSize: FONT_TEXT }}
          justifyContent="space-between"
        >
          <PropdoButton
            text={t(`login.${"login"}`)}
            style={{
              width: "40%",
              border: "none",
              padding: "8px 14px",
              fontSize: FONT_TEXT,
              backgroundColor: !isRegister ? blue_1 : white,
              color: !isRegister ? white : blue_1,
            }}
            handleClick={() => handleSwitch("login")}
          />

          <PropdoButton
            text={t(`login.${"registration"}`)}
            style={{
              width: "40%",
              border: "none",
              padding: "8px 14px",
              fontSize: FONT_TEXT,
              backgroundColor: isRegister ? blue_1 : white,
              color: isRegister ? white : blue_1,
              textTransform: "none",
            }}
            handleClick={() => handleSwitch("register")}
          />
        </Grid>
      )}

      {/* 
      {!isRegister && (
        <CloseIcon 
          style={{
            position: "absolute",
            right: 15,
            top: 15,
            cursor: "pointer",
          }}
          onClick={() => closeDialog()}
        />
      )} */}
      {/* empty */}
      <Grid item xs={12}>
        {isResetPassword && (
          <Typography
            style={{
              textAlign: "right",
              marginBottom: 14,
              fontSize: FONT_TEXT,
            }}
          >
            {t(`login.resetPassword`)}
          </Typography>
        )}
      </Grid>

      <ThemeProvider theme={direction.country === "il" ? rtlTheme : ltrTheme}>
        {!isRegister ? (
          !isResetPassword ? (
            <LoginForm config={config} />
          ) : (
            <ResetPassword setisResetPassword={setisResetPassword} />
          )
        ) : isRegister ? (
          <Register setIsRegister={setIsRegister} config={config} />
        ) : (
          <LoginForm />
        )}
      </ThemeProvider>

      {!isRegister && (
        <Grid container item xs={12} style={{ marginTop: 14 }}>
          <Typography style={{ fontSize: FONT_TEXT }}>
            {/* {t(`login.${!isPassword ? "notRegistered" : "registered"}`)} */}
          </Typography>
          <Typography
            style={{
              color: blue_1,
              cursor: "pointer",
              fontSize: FONT_TEXT,
              color: isResetPassword ? blue_1 : orange_1,
            }}
            onClick={() => setisResetPassword(!isResetPassword)}
          >
            {t(
              `login.${
                !isResetPassword
                  ? !isRegister
                    ? "forgotPassword"
                    : "submit"
                  : isRegister
                  ? "registration"
                  : "login"
              }`
            )}
          </Typography>
        </Grid>
      )}

      <Grid
        container
        alignItems="center"
        item
        xs={12}
        style={{ marginTop: 28, justifyContent: "center", color: blue_1 }}
      >
        {!isResetPassword && (
          <Typography className={classes.with}>{t("login.orWith")}</Typography>
        )}
      </Grid>
      {!isResetPassword && <SocialLogin />}
    </Grid>
  );
};
