import React, { useContext, useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useIsMobileScreen } from "common-components";
import {
  darkMainPropColor,
  greyAxis,
  mainPropColor,
} from "../../services/colors";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DataAddressesContext } from "../../context/DataAddressesContext";
import { fetchAcInacProp } from "./TableRepost";
import Typography from "@material-ui/core/Typography";
import { LG_FONT } from "../../services/fonts";
import { LoadingButtons } from "../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

const ReportsTableFilter = ({
  searchWord,
  setSearchWord,
  active,
  page,
  sortBy,
  order,
  type,
  setType,
  setPage,
  project,
  building,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const [direction] = useContext(DirectionContext);
  const props = {
    isDarkTheme,
    width: "100%",
    height: "36px",
    isExtraSmallScreen,
    maxHeight: "400px",
  };
  const classes = useStylesAddProp(props);
  const [addresses, setAddresses] = useContext(DataAddressesContext);
  const { t } = useTranslation();

  const handleFilter = (word, isEnter) => {
    setSearchWord(word);
    if (word.length === 0 || word.length >= 2 || isEnter) {
      sendFetch(page, type, sortBy, order, word);
    }
  };

  const clearSearch = () => {
    setSearchWord("");
    fetchAcInacProp(
      direction,
      active,
      1,
      "",
      type,
      sortBy,
      order === "asc",
      setAddresses,
      addresses,
      setPage,
      setLoading,
      project,
      building,
      isDarkTheme
    );
  };

  const sendFetch = (page, type, sortBy, order, word) => {
    setTimeout(async () => {
      fetchAcInacProp(
        direction,
        active,
        page,
        word,
        type,
        sortBy,
        order === "asc",
        setAddresses,
        addresses,
        setPage,
        setLoading,
        project,
        building,
        isDarkTheme
      );
    }, 700);
  };

  return (
    <Grid container alignItems={"center"} justify={"space-between"} spacing={3}>
      <Grid container item xs={8} sm={6} md={4} lg={3}>
        <Grid item xs={10}>
          <div className={classes.divReportsFilterContainer}>
            <div>
              <TextField
                className={classes.textFiledAddPropWidth100}
                variant="outlined"
                placeholder={t("Reports.searchProperty")}
                value={searchWord}
                style={isExtraSmallScreen ? { width: "100%" } : {}}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    handleFilter(event.target.value, true);
                  }
                }}
                onChange={(event) => {
                  handleFilter(event.target.value);
                }}
                onFocus={(event) => {
                  event.target.select();
                }}
                InputProps={{
                  inputProps: {
                    inputMode: "search",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} color={greyAxis} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        icon={faTimes}
                        size={"1x"}
                        color={greyAxis}
                        style={{ cursor: "pointer", width: LG_FONT }}
                        onClick={clearSearch}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography style={{ height: 13, color: "#8b8b8b" }}>
                {searchWord.length < 2 &&
                  searchWord.length > 0 &&
                  ` 
                    ${t("Reports.searchPropertyMsg1")}  ${
                    2 - searchWord.length
                  }  ${t("Reports.searchPropertyMsg2")}
                  `}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid container item xs={2} justify={"center"} alignItems={"center"}>
          {loading && (
            <LoadingButtons
              color={isDarkTheme ? darkMainPropColor : mainPropColor}
            />
          )}
        </Grid>
      </Grid>

      {/*<Grid item xs={4}>*/}
      {/*  <div*/}
      {/*    className={classes.divReportsFilterContainer}*/}
      {/*    style={{ justifyContent: "flex-end" }}*/}
      {/*  >*/}
      {/*    {type.length > 0 && (*/}
      {/*      <Collapse*/}
      {/*        in={type.length > 0}*/}
      {/*        timeout="auto"*/}
      {/*        unmountOnExit*/}
      {/*        style={{ marginLeft: 8 }}*/}
      {/*      >*/}
      {/*        {type.map((item, index) => {*/}
      {/*          return (*/}
      {/*            <Chip*/}
      {/*              label={item}*/}
      {/*              onDelete={(ev) => handleDelete(ev, item)}*/}
      {/*              color="primary"*/}
      {/*              size="small"*/}
      {/*              style={{*/}
      {/*                fontSize: 12,*/}
      {/*                margin: 2,*/}
      {/*                borderRadius: "var(--border-radius)",*/}
      {/*              }}*/}
      {/*            />*/}
      {/*          );*/}
      {/*        })}*/}
      {/*      </Collapse>*/}
      {/*    )}*/}
      {/*    <ClickAwayListener onClickAway={() => setFilterDropdown(false)}>*/}
      {/*      <IconButton*/}
      {/*        aria-label="filter list"*/}
      {/*        onClick={() => setFilterDropdown(!filterDropdown)}*/}
      {/*      >*/}
      {/*        <FilterListIcon style={{ fontSize: "1.6rem" }} />*/}
      {/*        <Collapse*/}
      {/*          in={filterDropdown}*/}
      {/*          timeout="auto"*/}
      {/*          unmountOnExit*/}
      {/*          style={{ position: "relative", right: "-75px", top: 10 }}*/}
      {/*        >*/}
      {/*          <ul className={classesBarChart.uLDropdownSmall}>*/}
      {/*            {filterTypes.map((item, index) => {*/}
      {/*              return (*/}
      {/*                <li*/}
      {/*                  className={classesBarChart.lIDropdownSmall}*/}
      {/*                  key={index}*/}
      {/*                  onClick={() => handleTypeFilter(item)}*/}
      {/*                >*/}
      {/*                  {item}*/}
      {/*                </li>*/}
      {/*              );*/}
      {/*            })}*/}
      {/*          </ul>*/}
      {/*        </Collapse>*/}
      {/*      </IconButton>*/}
      {/*    </ClickAwayListener>*/}
      {/*  </div>*/}
      {/*</Grid>*/}
    </Grid>
  );
};
export default ReportsTableFilter;
