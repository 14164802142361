import React, { useContext, useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Collapse } from "@material-ui/core";
import { darkText, darkThemePositiveGreen } from "../../../services/colors";
import { doFetch } from "common-components";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { DataAddressesContext } from "../../../context/DataAddressesContext";
import { makeStyles } from "@material-ui/core/styles";
import { XS_FONT } from "../../../services/fonts";
import { IMAGES_LINK } from "common-components";
import { DirectionContext } from "../../../context/DirectionContext";

const useStyles = makeStyles({
  icon: { color: darkThemePositiveGreen, width: 18, height: 18 },
});
const TableNote = ({ value, row }) => {
  const [direction] = useContext(DirectionContext);
  const [addresses, setAddresses] = useContext(DataAddressesContext);
  const [isOpenNote, setIsOpenNote] = useState(false);
  const [saved, setSaved] = useState(false);
  const [textArea, setTextArea] = useState(value);
  const handleClickOpen = () => {
    setIsOpenNote(!isOpenNote);
  };
  const handleClickSave = () => {
    doFetch(`/reports/premium/property/update?id=${row.id}`, {
      method: "POST",
      body: JSON.stringify({ note: textArea }),
    }).then((data) => {
      if (data.message === "Premium Updated") {
        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 1500);
        setTimeout(() => {
          setIsOpenNote(false);
        }, 3000);
      }
    });
    row.note = textArea;
    const newAddresses = { ...addresses.active };
    newAddresses.properties[row.index].premium.note = textArea;
    setAddresses((prevState) => ({ ...prevState, active: newAddresses }));
  };
  useEffect(() => {
    if (!isOpenNote) {
      setTextArea(value);
    }
  }, [isOpenNote]);
  const classIcon = useStyles();
  return (
    <div
      style={{ display: "flex", alignItems: "center", position: "relative" }}
    >
      <IconButton onClick={handleClickOpen}>
        <img
          src={`${IMAGES_LINK}/dashboard/reports/notepad.svg`}
          alt="Note"
          style={{ width: 18, height: 18, fontSize: XS_FONT }}
        />
      </IconButton>

      <Collapse
        in={isOpenNote}
        timeout="auto"
        unmountOnExit
        style={{
          position: "absolute",
          left: 80,
          backgroundColor: darkText,
          height: 70,
          width: 280,
          borderRadius: 10,
        }}
      >
        <ClickAwayListener onClickAway={() => setIsOpenNote(false)}>
          <div
            style={{
              backgroundColor: darkText,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              borderRadius: 10,
              height: 70,
            }}
          >
            <textarea
              value={textArea}
              onChange={(e) => setTextArea(e.target.value)}
              style={{
                backgroundColor: darkText,
                border: "none",
                width: 225,
                borderRadius: 10,
                padding: 5,
                zIndex: 1,
              }}
            />
            <IconButton onClick={handleClickSave} disabled={saved}>
              {saved ? (
                <DoneAllIcon className={classIcon.icon} />
              ) : (
                <CheckIcon className={classIcon.icon} />
              )}
            </IconButton>
          </div>
        </ClickAwayListener>
      </Collapse>
    </div>
  );
};
export default TableNote;
