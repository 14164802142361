import React, { useContext, useEffect, useState } from "react";
import {
  color2,
  color3,
  color4,
  darkBlue,
  darkMainPropColor,
  darkTurquoise,
} from "../services/colors";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { SpinnerInsideCharts } from "../components/layout/Layout";
import LineChart from "../components/dashboard-components/tama/LineChart";
import { LANDING_PAGE_LINK } from "common-components";
import useNameLabels from "../hooks/useNameLabels.js";
// @ts-ignore
import { useTranslation } from "react-i18next";

export function roundUp(num: number) {
  const res = Math.ceil(num * 100) / 100000000;
  return res.toFixed(2);
}
export function finColor(isDarkTheme: boolean, index: number) {
  return index === 0 && isDarkTheme
    ? darkBlue
    : index === 0 && !isDarkTheme
    ? color3
    : index === 1 && isDarkTheme
    ? darkTurquoise
    : index === 1 && !isDarkTheme
    ? color4
    : index === 2 && isDarkTheme
    ? darkMainPropColor
    : color2;
}
export function normaliseDataLine(
  t: any,
  data: DataLineChartProps,
  isDarkTheme: boolean
) {
  return {
    labels: data["x-axis"],
    datasets: data.series.map((item, index) => {
      return {
        label: t(`LineChart.labels.${item.name}`),
        fill: false,
        lineTension: 0.5,
        backgroundColor: finColor(isDarkTheme, index),
        borderColor: finColor(isDarkTheme, index),
        borderCapStyle: "round",
        borderDash: index === 1 ? [] : [12, 6],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: finColor(isDarkTheme, index),
        pointBackgroundColor: finColor(isDarkTheme, index),
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: finColor(isDarkTheme, index),
        pointHoverBorderColor: finColor(isDarkTheme, index),
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: item.data.map((num) => roundUp(num)),
      };
    }),
  };
}
export interface DataLineChartProps {
  date: string;
  series: Series[];
  "x-axis": number[];
}

export interface Series {
  data: number[];
  name: string;
}

export interface LineChartInterface {
  labels: number[];
  datasets: Dataset[];
}

export interface Dataset {
  label: string;
  fill: boolean;
  lineTension: number;
  backgroundColor: string;
  borderColor: string;
  borderCapStyle: string;
  borderDash: number[];
  borderDashOffset: number;
  borderJoinStyle: string;
  pointBorderColor: string;
  pointBackgroundColor: string;
  pointBorderWidth: number;
  pointHoverRadius: number;
  pointHoverBackgroundColor: string;
  pointHoverBorderColor: string;
  pointHoverBorderWidth: number;
  pointRadius: number;
  pointHitRadius: number;
  data: string[];
}

const DataLineChart: React.FC<{ lineData: DataLineChartProps }> = ({
  lineData,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [data, setData] = useState<LineChartInterface | undefined>(undefined);
  const [tooltipDate, setTooltipDate] = useState<string[] | undefined>(
    undefined
  );
  const { t } = useTranslation();
  const month = Number(lineData.date.split(" ")[0].split("-")[1]);
  const labelNames = useNameLabels(lineData["x-axis"], month);

  useEffect(() => {
    if (!labelNames) return;
    setTooltipDate(labelNames);
  }, [labelNames]);

  useEffect(() => {
    const normalisedData = normaliseDataLine(t, lineData, isDarkTheme);
    setData(normalisedData);
  }, [lineData, setData]);

  if (data && tooltipDate) {
    return (
      <LineChart
        interactive
        tooltipInfo={
          <p>
            {t("LineChart.tooltip")}{" "}
            <a
              href={`${LANDING_PAGE_LINK}/terms`}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "underline",
                fontWeight: 600,
              }}
            >
              {" "}
              {t("LineChart.tooltipLink")}
            </a>
            .
          </p>
        }
        data={data}
        tooltipDate={tooltipDate}
        title={t("LineChart.title")}
      />
    );
  } else {
    return <SpinnerInsideCharts />;
  }
};

export default DataLineChart;
