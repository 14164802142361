import { AddFolderButton } from "./ProjectsComponent";
import Grid from "@material-ui/core/Grid";
import { darkMainPropColor } from "../../../services/colors";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import IconButton from "@material-ui/core/IconButton";
import Folder from "./Folder";
import { ReactComponent as FolderOpenIcon } from "../../../terporaryImgs/openFolder.svg";
import Typography from "@material-ui/core/Typography";
import { doFetch } from "common-components";
import { v4 as uuidv4 } from "uuid";
import { useContext, useState } from "react";
import { useNotistack } from "common-components";
import {
  FOLDERS_CANNOT_REMOVED_MSG,
  FOLDERS_REMOVED_MSG,
} from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";

const BuildingsList = ({ folders, setFolders, projects, setProject }) => {
  const [error, setError] = useState(false);
  const [direction] = useContext(DirectionContext);
  const { t } = useTranslation();

  const currentProject = projects[folders.indexProject];

  const handleAddBuilding = () => {
    setTimeout(() => {
      const notSavedFolders = currentProject.buildings.filter(
        (item) => !item.saved
      );

      if (notSavedFolders.length === 0) {
        const newProjects = [...projects];
        newProjects[folders.indexProject].buildings = [
          ...newProjects[folders.indexProject].buildings,
          { label: t("PremiumProjects.construction"), saved: false },
        ];

        setProject(newProjects);
      }
    }, 500);
  };

  const handleSaveBuilding = (label, index, setLoading) => {
    setLoading(true);
    doFetch(`/reports/premium/folder/add`, {
      method: "POST",
      body: JSON.stringify({
        type: "building",
        project: projects[folders.indexProject].label,
        building: label,
      }),
    })
      .then(() => {
        const newProjects = [...projects];
        newProjects[folders.indexProject].buildings[index] = {
          label,
          saved: true,
          id: uuidv4(),
        };
        setProject(newProjects);
        setLoading(false);
      })
      .catch((error) => setError(true));
  };

  const handleExit = () => {
    if (folders.indexBuilding !== undefined) {
      setFolders((prevState) => ({
        ...prevState,
        building: "",
        indexBuilding: undefined,
      }));
    } else {
      setFolders((prevState) => ({
        ...prevState,
        display: "projects",
        indexProject: undefined,
        project: "",
        building: "",
      }));
    }

    handleClickAway();
  };

  const handleOpenFolder = (index) => {
    setFolders((prevState) => ({
      ...prevState,
      building: projects[folders.indexProject].buildings[index].label,
      indexBuilding: index,
    }));
  };

  const handleDelete = (setDropdownDelete, index, label) => {
    setDropdownDelete(false);
    doFetch(`/reports/premium/folder/remove`, {
      method: "POST",
      body: JSON.stringify({
        type: "building",
        project: projects[folders.indexProject].label,
        building: projects[folders.indexProject].buildings[index].label,
      }),
    })
      .then((data) => {
        if (data.message === FOLDERS_REMOVED_MSG) {
          setDropdownDelete(false);
          const newProjects = [...projects];
          const newBuildings = [...newProjects[folders.indexProject].buildings];
          newBuildings.splice(index, 1);

          newProjects[folders.indexProject] = {
            buildings: newBuildings,
            saved: newProjects[folders.indexProject].saved,
            label: newProjects[folders.indexProject].label,
          };
          setProject(newProjects);
        } else if (data.message === FOLDERS_CANNOT_REMOVED_MSG) {
          setError({ text: t("PremiumProjects.errMsg") });
        }
      })
      .catch((error) => setError({ text: t("updateFailedMsg") }));
  };

  const handleClickAway = () => {
    const newProjects = [...projects];
    const newBuildings = [
      ...newProjects[folders.indexProject].buildings.filter(
        (item) => item.saved
      ),
    ];
    newProjects[folders.indexProject] = {
      buildings: newBuildings,
      saved: newProjects[folders.indexProject].saved,
      label: newProjects[folders.indexProject].label,
    };
    setProject(newProjects);
  };

  useNotistack(error.text, error, setError, "error");

  return (
    <>
      <Grid xs={12}>
        <IconButton onClick={handleExit}>
          <ArrowForwardIcon
            style={{
              color: darkMainPropColor,
              transform: `rotate(${direction.isRtl ? "0" : "180"}deg)`,
            }}
          />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <div style={{ width: "fit-content" }}>
          <FolderOpenIcon
            style={{
              width: "6em",
              height: "6em",
            }}
          />
          <Typography align={"center"}>
            {folders.indexBuilding !== undefined
              ? currentProject.buildings[folders.indexBuilding].label
              : currentProject.label}
          </Typography>
        </div>
      </Grid>
      {folders.indexBuilding === undefined &&
        currentProject.buildings.map((building, index) => {
          return (
            <Folder
              handleClickAway={handleClickAway}
              key={building.id}
              index={index}
              item={building}
              handleOpenFolder={handleOpenFolder}
              handleDelete={handleDelete}
              handleSave={handleSaveBuilding}
            />
          );
        })}
      {folders.indexBuilding === undefined && (
        <AddFolderButton
          text={t("PremiumProjects.addBuilding")}
          handleAdd={handleAddBuilding}
        />
      )}
    </>
  );
};
export default BuildingsList;
