import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { blue_1, opacityBlue } from "../../services/colors";

const useStyles = makeStyles({
  default: {
    backgroundColor: blue_1,
    color: "#fff",
    border: "2px solid #fff",
    borderRadius: "8px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: opacityBlue,
    },
  },
});

interface PropdoButtonProps {
  text?: string;
  style?: { [x: string]: string | Number };
  handleClick?: () => void;
  icon?: React.ReactNode;
}

export const PropdoButton: React.FC<PropdoButtonProps> = ({
  text,
  style,
  handleClick,
  icon,
}) => {
  const classes = useStyles();

  return (
    <Button
      style={style}
      onClick={handleClick}
      endIcon={icon}
      className={classes.default}
    >
      {text}
      {/* {icon && <img src={icon} style={{ width: "inherit" }} />} */}
    </Button>
  );
};
