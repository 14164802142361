import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { XS_PLUS_FONT } from "../services/fonts";
import { darkMainPropColor, mainPropColor } from "../services/colors";

const useStyles = makeStyles({
  pharPricingBack: (props) => ({
    color: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    fontSize: XS_PLUS_FONT,
    margin: "0 10px",
    fontWeight: 800,
    cursor: "pointer",
  }),
});

const BackButton: React.FC<{
  callBack: () => void;
  whiteColor: boolean;
  context: object;
}> = ({ callBack, whiteColor = false, context }) => {
  const props = { isDarkTheme: context?.isDarkTheme };
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Typography
      className={classes.pharPricingBack}
      style={whiteColor ? { color: "white" } : {}}
      onClick={() => callBack()}
    >
      <FontAwesomeIcon
        icon={context.direction.isRtl ? faArrowRight : faArrowLeft}
        style={{ padding: "0 10px", width: 35 }}
      />

      {t("backButton")}
    </Typography>
  );
};
export default BackButton;
