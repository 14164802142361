export const FONT_TEXT_SECONDARY = "10px"; //MOBILE + DESKTOP (SECONDARY TEXT)
export const FONT_TEXT = "12px"; //MOBILE (TEXT)
export const FONT_SUBTITLE = "14px"; //MOBILE (SUBTITLES)
export const FONT_TITLE = "16px"; //MOBILE (TITLES) + DESKTOP (TEXT)
export const FONT_TITLE_LG = "18px"; //DESKTOP (SUBTITLES)
export const FONT_TITLE_XL = "22px"; //DESKTOP (TITLES)
export const FONT_TITLE_XXL = "30px"; //DESKTOP (EXTRA TITLES)
export const FONT_HEADER_LG = "38px"; //DESKTOP (HEADER TITLE)

//TODO Artem  Delete fonts in comps
