import React, { useContext, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  darkBackground,
  darkMainPropColor,
  darkText,
  negativeRedColor,
  positiveGreenColor,
} from "../../../services/colors";
import { doFetch } from "common-components";
import { DataAddressesContext } from "../../../context/DataAddressesContext";
import { addingComma } from "../../../services/helpers";
import { makeStyles } from "@material-ui/core/styles";
import { LG_PLUS_FONT, XS_PLUS_FONT } from "../../../services/fonts";
import { DirectionContext } from "../../../context/DirectionContext";

const useStylesForm = makeStyles({
  root: (props) => ({
    borderRadius: "var(--border-radius)",
    backgroundColor: props.isSold ? negativeRedColor : positiveGreenColor,
    maxWidth: 80,
    "& .MuiInputBase-root": {
      "&::before": { content: "none" },
      "& .MuiSelect-icon": {},
      "&::after": { content: "none" },
    },
  }),
});

const useStylesSelect = makeStyles({
  root: (props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
});

const useStyles = makeStyles({
  popup: (props) => ({
    height: "100%",
    width: "200px",
  }),
  inputAskingPrice: {
    width: "100%",
    backgroundColor: darkText,
    border: "none",
    color: darkBackground,
    padding: 4,
    borderRadius: "var(--border-radius)",
  },
});

const UpdateStatus = ({
  soldPrice,
  setSoldPrice,
  handleSavePrice,
  loading,
  handleCancel,
}) => {
  const classes = useStyles();

  const onChangePrice = (event) => setSoldPrice(event.target.value);

  return (
    <Grid container className={classes.popup} spacing={1}>
      <Grid container justify="center" alignItems="center" item xs={12}>
        <Typography style={{ fontSize: 12 }}>
          על מנת לעדכן סטטוס, אנא הזן את המחיר בו הנכס נמכר
        </Typography>
      </Grid>
      <Grid container justify="space-between" alignItems="center" item xs={12}>
        <input
          className={classes.inputAskingPrice}
          type="number"
          onChange={onChangePrice}
          value={soldPrice}
        />
      </Grid>
      <Grid container alignItems="center" justify="space-between" item xs={12}>
        <Grid item xs={5}>
          <Button
            onClick={handleCancel}
            style={{ width: "100%", backgroundColor: "rgb(153 177 211)" }}
          >
            בטל
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            onClick={handleSavePrice}
            style={{ width: "100%", backgroundColor: darkMainPropColor }}
          >
            אישור
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PriceSold = ({ row, isDashboard }) => {
  const [direction] = useContext(DirectionContext);
  const [isSold, setIsSold] = useState(row.sold);
  const [soldPrice, setSoldPrice] = useState(row.sold_price);
  const [loading, setLoading] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [addresses, setAddresses] = useContext(DataAddressesContext);

  const handleSavePrice = () => {
    setLoading(true);
    setIsSold(1);
    doFetch(`/reports/premium/property/update?id=${row.id}`, {
      method: "POST",
      body: JSON.stringify({
        sold: 1,
        sold_price: Number(soldPrice),
      }),
    }).then((data) => {
      setLoading(false);
    });
    const newAddresses = { ...addresses.active };
    newAddresses.properties[row.index].premium.sold_price = +soldPrice;
    newAddresses.properties[row.index].premium.sold = 1;
    setAddresses((prevState) => ({ ...prevState, active: newAddresses }));
  };

  const handleChange = (event) => {
    setIsToggle(event.target.value);
  };

  const handleCancel = () => {
    setIsToggle(false);
  };

  const classesForm = useStylesForm({ isSold });
  const classesSelect = useStylesSelect({ isSold });

  // TODO : RTL
  return isDashboard ? (
    <Grid container item xs={12}>
      {isSold ? (
        <>
          <Grid container justify={"flex-start"} item xs={12}>
            <Typography variant={"h5"}>מחיר מכירה</Typography>
          </Grid>
          <Grid container justify={"flex-start"} item xs={12}>
            <Typography style={{ fontSize: LG_PLUS_FONT }}>
              {!isSold ? "-- ₪" : `${addingComma(soldPrice)} ₪`}
            </Typography>
          </Grid>
        </>
      ) : null}
    </Grid>
  ) : !isSold ? (
    <>
      {!isToggle ? (
        <FormControl fullWidth classes={classesForm}>
          <Select
            id="demo-simple-select"
            value={isSold}
            label="סטטוס"
            onChange={handleChange}
            classes={classesSelect}
          >
            <MenuItem value={0}>למכירה</MenuItem>
            <MenuItem value={1}>נמכר</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <UpdateStatus
          soldPrice={soldPrice}
          setSoldPrice={setSoldPrice}
          handleSavePrice={handleSavePrice}
          loading={loading}
          handleCancel={handleCancel}
        />
      )}
    </>
  ) : (
    <Typography style={{ fontSize: XS_PLUS_FONT, width: 80 }}>{`${addingComma(
      soldPrice
    )} ₪`}</Typography>
  );
};
export default PriceSold;
