import React, { useContext, useEffect, useState } from "react";
import { Grid, useTheme } from "@material-ui/core";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import { doFetch } from "common-components";
import UserManagementDetails from "./UserManagementDetails";
import UserManagementPremiumDetails from "./UserManagementPremiumDetails";
import UserManagementSubscriptions from "./UserManagementSubscriptions";
import { useStylesLayout } from "../../styledComponent/LayoutStyled";
import { SPACING_FACTOR } from "../layout/Layout";
import { PropertyIdContext } from "../../context/PropertyIdContext";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import { useIsMobileScreen } from "common-components";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

const UserManagementLayOut = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    phone_number: "",
    marketing: 0,
    accept_tc: true,
    is_premium: "",
    subscriptions: [],
  });
  const [userPremiumData, setUserPremiumData] = useState({
    company: "",
    contact_name: "",
    contact_phone: "",
    logo_stored: "",
  });
  const [direction] = useContext(DirectionContext);
  const [authorization] = useContext(AuthorizationContext);
  const [propertyId] = useContext(PropertyIdContext);
  const [userSubscriptions, setUserSubscriptions] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [errorFileFormat, setErrorFileFormat] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [imageFile, setImageFile] = useState(undefined);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme, width: "100%" };
  const classes = useStylesAddProp(props);
  const isSmallScreen = useIsMobileScreen();
  const { t } = useTranslation();

  useNotistack(
    `${t("updateSucceedMsg")}`,
    updateSuccess,
    setUpdateSuccess,
    "success"
  );
  useNotistack(
    `${t("updateFailedMsg")}`,
    updateFailed,
    setUpdateFailed,
    "error"
  );
  useNotistack(
    `${t("UserManagement.companyInfo.logoSizeError")}`,
    errorSize,
    setErrorSize,
    "error"
  );
  useNotistack(
    `${t("UserManagement.companyInfo.logoError")}`,
    errorFileFormat,
    setErrorFileFormat,
    "error"
  );

  useEffect(() => {
    if (propertyId && authorization) {
      async function getUserData() {
        const data = await doFetch(`/user/details?region=${direction.region}`);
        if (data.errorMessage) {
          setUpdateFailed(true);
        } else {
          const {
            name,
            email,
            phone_number,
            marketing,
            accept_tc,
            is_premium,
            subscriptions,
            user_premium,
          } = data;

          setUserData({
            name,
            email,
            phone_number: phone_number.toString().slice(4),
            marketing: 1,
            accept_tc,
            is_premium,
          });
          setUserSubscriptions(
            subscriptions.length === 0 ? false : subscriptions
          );
          if (is_premium) setUserPremiumData(user_premium);
          if ("user_premium" in data && data.user_premium.logo_stored)
            setImageFile(1);
        }
      }
      try {
        getUserData();
      } catch (e) {
        console.error("Failed to fetch: ", e);
        throw e;
      }
    }
  }, [propertyId, authorization]);

  const theme = useTheme();
  const classesLA = useStylesLayout({
    spacing: theme.spacing(SPACING_FACTOR),
    isDarkTheme: isDarkTheme,
  });

  return (
    <div
      className={classesLA.divContent}
      style={{ minHeight: "calc(100% - 168px)" }}
    >
      <div className={classesLA.divWidth}>
        <Grid
          container
          style={{ paddingTop: 12 }}
          spacing={3}
          alignItems={
            !userSubscriptions ? "stretch" : isDarkTheme ? "" : "flex-start"
          }
        >
          <Grid
            container
            item
            xs={12}
            md={6}
            spacing={isDarkTheme && !isSmallScreen ? 3 : 0}
            style={{ marginRight: "0px" }}
          >
            <Grid
              item
              container
              xs={12}
              style={isDarkTheme && isSmallScreen ? { paddingBottom: 24 } : {}}
            >
              {/* Personal info form */}
              <UserManagementDetails
                userData={userData}
                setUserData={setUserData}
                setUpdateSuccess={setUpdateSuccess}
                setUpdateFailed={setUpdateFailed}
                classes={classes}
              />
            </Grid>
          </Grid>
          {/* User Subscriptions */}
          <Grid item container xs={12} md={6}>
            <UserManagementSubscriptions
              userData={userData}
              subscriptions={userSubscriptions}
              classes={classes}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UserManagementLayOut;
