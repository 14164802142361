import React, { useContext, useEffect, useState } from "react";
import { darkElements, darkMainPropColor } from "../../../services/colors";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox, CheckboxProps } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import RowBoxPrice from "./RowBoxPrice";
import { LANDING_PAGE_LINK } from "common-components";
import Button from "@material-ui/core/Button";
import { doFetch } from "common-components";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { LoadingButtons } from "../../layout/Layout";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import LinkIcon from "@material-ui/icons/Link";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import SharedLink from "./SharedLink";
import { useNotistack } from "common-components";
import { useIsIncludes } from "common-components";
import { useTranslation } from "react-i18next";

export function randomInt() {
  return Math.floor(Math.random() * 3000000);
}
export interface RowBoxCurrentData {
  amount: number;
  year: number;
}
export interface RowBoxCurrentProps {
  data: RowBoxCurrentData | undefined;
  date: string | undefined;
}
export const BlackThemeCheckbox = withStyles({
  root: {
    color: darkMainPropColor,
    backgroundColor: darkElements,
    padding: 5,
    "&$checked": {
      color: darkMainPropColor,
      backgroundColor: darkElements,
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const RowBoxCurrent: React.FC<RowBoxCurrentProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container justify={"space-between"} style={{ height: "100%" }}>
      <RowBoxPrice
        title={t("RowBoxCurrent.title")} //TODO*check LTR
        price={data?.amount}
        tooltipText={
          <p>
            {t("RowBoxCurrent.tooltip")}{" "}
            <a
              href={`${LANDING_PAGE_LINK}/terms`}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "underline",
                fontWeight: 600,
              }}
            >
              {" "}
              {t("RowBoxCurrent.tooltipLink")}
            </a>
            .
          </p>
        }
        interactive
      />
    </Grid>
  );
};

export default RowBoxCurrent;
