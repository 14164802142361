import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { XS_PLUS_FONT } from "../../services/fonts";

interface HiddenShownTextProps {
  value: any;
  isExtraSmallScreen: boolean;
  isBigScreen: boolean;
}

const HiddenShownText: React.FC<HiddenShownTextProps> = ({
  value,
  isExtraSmallScreen,
  isBigScreen,
}) => {
  const [textHidden, setTextHidden] = useState(true);

  return (
    <Typography
      style={
        textHidden
          ? {
              display: "block",
              width: isExtraSmallScreen ? 100 : isBigScreen ? 300 : 300,
              overflow: "hidden",
              fontSize: XS_PLUS_FONT,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }
          : {
              display: "block",
              width: isExtraSmallScreen ? 100 : isBigScreen ? 300 : 300,
              overflow: "hidden",
              fontSize: XS_PLUS_FONT,
            }
      }
      onClick={() => setTextHidden(!textHidden)}
    >
      {value}
    </Typography>
  );
};
export default HiddenShownText;
