import TilesContainer from "../../TilesContainer";
import Grid from "@material-ui/core/Grid";
import { ToggleSlider } from "common-components";
import React, { useContext, useEffect, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { TableCell } from "@material-ui/core";
import { addingComma } from "../../../services/helpers";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  greyAxis,
  negativeRedColor,
  positiveGreenColor,
} from "../../../services/colors";
import { MD_FONT } from "../../../services/fonts";
import DemoExample from "../../DemoExample";
import { useIsIncludes } from "common-components";
import { useIsMobileScreen } from "common-components";
import { useTranslation } from "react-i18next";
import TitleGrid from "../../TitleGrid";
import { DarkThemeContext } from "../../../context/DarkThemeContext";

function addIcon(value) {
  if (value > 0) {
    return (
      <ExpandLessIcon
        style={{ color: positiveGreenColor, width: 25, height: 25 }}
      />
    );
  } else if (value < 0) {
    return (
      <ExpandMoreIcon
        style={{ color: negativeRedColor, width: 25, height: 25 }}
      />
    );
  } else {
    return <RemoveIcon style={{ color: greyAxis, width: 25, height: 25 }} />;
  }
}

function checkToggleList(t, stage) {
  if (stage === 1) {
    return [
      { value: 0, text: t("Tama.steps.preliminary") },
      { value: 7, text: t("Tama.steps.permit") },
      { value: 9, text: t("Tama.steps.start") },
      { value: 10, text: t("Tama.steps.execution") },
      { value: 15, text: t("Tama.steps.population") },
    ];
  } else if (stage === 2) {
    return [
      { value: "disabled", text: t("Tama.steps.preliminary") },
      { value: 0, text: t("Tama.steps.permit") },
      { value: 2, text: t("Tama.steps.start") },
      { value: 3, text: t("Tama.steps.execution") },
      { value: 8, text: t("Tama.steps.population") },
    ];
  } else if (stage === 3) {
    return [
      { value: "disabled", text: t("Tama.steps.preliminary") },
      { value: "disabled", text: t("Tama.steps.permit") },
      { value: 0, text: t("Tama.steps.start") },
      { value: 1, text: t("Tama.steps.execution") },
      { value: 6, text: t("Tama.steps.population") },
    ];
  } else if (stage === 4) {
    return [
      { value: "disabled", text: t("Tama.steps.preliminary") },
      { value: "disabled", text: t("Tama.steps.permit") },
      { value: "disabled", text: t("Tama.steps.start") },
      { value: 0, text: t("Tama.steps.execution") },
      { value: 5, text: t("Tama.steps.population") },
    ];
  } else if (stage === 5) {
    return [
      { value: "disabled", text: t("Tama.steps.preliminary") },
      { value: "disabled", text: t("Tama.steps.permit") },
      { value: "disabled", text: t("Tama.steps.start") },
      { value: "disabled", text: t("Tama.steps.execution") },
      { value: 0, text: t("Tama.steps.population") },
    ];
  } else {
    return [
      { value: "disabled", text: t("Tama.steps.preliminary") },
      { value: "disabled", text: t("Tama.steps.permit") },
      { value: "disabled", text: t("Tama.steps.start") },
      { value: "disabled", text: t("Tama.steps.execution") },
      { value: "disabled", text: t("Tama.steps.population") },
    ];
  }
}

const ContributionTama = ({ lineData, stage }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [value, setValue] = useState(0);
  const [afterTama, setAfterTama] = useState({
    price: 0,
    percentage: 0,
  });
  const [beforeTama, setBeforeTama] = useState({
    price: 0,
    percentage: 0,
  });
  const isDemo = useIsIncludes("demo");
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const { t } = useTranslation();

  useEffect(() => {
    if (lineData) {
      setBeforeTama({
        price: lineData.series[0].data[value] - lineData.series[0].data[0],
        percentage:
          (lineData.series[0].data[value] * 100) / lineData.series[0].data[0] -
          100,
      });
      setAfterTama({
        price: lineData.series[1]
          ? lineData.series[1].data[value] - lineData.series[1].data[0]
          : 0,
        percentage: lineData.series[1]
          ? (lineData.series[1].data[value] * 100) /
              lineData.series[1].data[0] -
            100
          : 0,
      });
    }
  }, [lineData, value]);

  return (
    <TilesContainer topBorder relative padding={32}>
      {isDemo && (
        <DemoExample ribbonLeft={isExtraSmallScreen ? "0px" : undefined} />
      )}
      <Grid container>
        <TitleGrid title={t("Tama.titleTable")} />

        <Grid item xs={12} style={{ paddingTop: 15 }}>
          <TableContainer>
            <ToggleSlider
              radius
              value={value}
              setValue={setValue}
              list={checkToggleList(t, stage + 1)}
              noWrap
              isDarkTheme={isDarkTheme}
            />
            <Table style={{ minWidth: 850 }}>
              <TableBody>
                <ContributionRow
                  name={t("Tama.rowImprovement")}
                  Icon={addIcon(afterTama.price)}
                  percentage={afterTama.percentage}
                  price={afterTama.price}
                />
                <ContributionRow
                  name={t("Tama.rowIncrease")}
                  Icon={addIcon(beforeTama.price + afterTama.price)}
                  percentage={beforeTama.percentage}
                  price={beforeTama.price}
                />
                <ContributionRow
                  name={t("Tama.rowAddition")}
                  Icon={addIcon(beforeTama.price)}
                  percentage={beforeTama.percentage + afterTama.percentage}
                  price={beforeTama.price + afterTama.price}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </TilesContainer>
  );
};
export default ContributionTama;
export const ContributionRow = ({ name, price, percentage, Icon }) => {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell style={{ fontSize: MD_FONT, minWidth: 150 }}>{name}</TableCell>
      <TableCell
        style={{ fontSize: MD_FONT, whiteSpace: "nowrap" }}
        component={"bdi"}
      >
        {price ? `₪  ${addingComma(price)}` : `₪  --`}
      </TableCell>
      <TableCell
        style={{ fontSize: MD_FONT, whiteSpace: "nowrap" }}
        component={"bdi"}
      >
        {percentage ? `${percentage.toFixed(2)}%` : "--%"}
      </TableCell>
      <TableCell style={{ fontSize: MD_FONT }}>{Icon}</TableCell>
    </TableRow>
  );
};
