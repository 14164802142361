import { makeStyles } from "@material-ui/core/styles";

export const useStylesLineChart = makeStyles({
  buttonRadioContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 26,
    height: 26,
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
  },
  divRadioInside: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    border: "none",
  },
  divRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  divLineChart: (props) => ({
    minHeight: props.isSmallScreen ? 250 : 400,
    maxHeight: props.isSmallScreen ? 250 : 400,
  }),
});
