import React, { useContext } from "react";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { Button } from "@material-ui/core";
import { useStylesForecast } from "../../styledComponent/ForecastStyled";
import { useStylesUserLogin } from "../../styledComponent/UserLoginStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { IMAGES_LINK } from "common-components";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { negativeRedColor } from "../../services/colors";

const MessageAskMashkanta = ({ text, error }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { closeDialog } = useDialogManager();
  const classes2 = useStylesUserLogin({ isDarkTheme });
  const classesForecast = useStylesForecast({ isDarkTheme });

  const { t } = useTranslation();

  return (
    <div
      className={classes2.divRootContainer}
      style={{
        position: "relative",
        padding: "45px 20px 20px 20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className={classesForecast.divPopupContainerIcon}
        style={{
          top: "-36px",
        }}
      >
        <img
          src={
            error
              ? `${IMAGES_LINK}/dashboard/popups-icons/error.svg`
              : `${IMAGES_LINK}/dashboard/elements/house.svg`
          }
          alt="added"
          style={{ width: 70, height: 70 }}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <Typography variant={"h6"}>{text}</Typography>

        <Button
          variant={error ? "outlined" : "contained"}
          color={"primary"}
          style={{
            marginTop: 15,
          }}
          onClick={() => {
            closeDialog();
          }}
        >
          {t("backButton")}
        </Button>
      </div>
    </div>
  );
};

export default MessageAskMashkanta;
