import { Grid, Typography, TextField, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { HOMEPAGE_LINK, IMAGES_LINK } from "../../api/api_links";
import { Auth } from "aws-amplify";
import { useDialogManager } from "../../hooks/useDialogManager";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PropdoButton } from "../buttons/PropdoButton";
import {
  FONT_TEXT,
  FONT_TITLE,
  FONT_TITLE_LG,
} from "../../services/constants/fonts";
import { blue_1, borderRadius, negative } from "../../services/colors";
import { useNotistack } from "../../hooks/useNotistack";
import { Login } from "./Login";
import { LoadingGif } from "../loading/LoadingGif";
import { useHistory } from "react-router-dom";

type Props = {
  codeParams: string;
  emailParams: string;
};

type FormValues = {
  password: string;
};

type Message = {
  variant: string;
  text: string;
};
export const ForgotPassword: React.FC<Props> = ({
  emailParams,
  codeParams,
}) => {
  let history = useHistory();
  const { closeDialog, openDialog } = useDialogManager();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Partial<Message>>();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const handleClose = () => {
    closeDialog();
    history.push("/");
  };

  const onSubmit: SubmitHandler<FormValues> = async (values: any) => {
    try {
      setIsLoading(true);
      await Auth.forgotPasswordSubmit(emailParams, codeParams, values.password);
      setMessage({ variant: "success", text: t("errors.successfulReset") });
      closeDialog();
      openDialog(Login);
    } catch (err: any) {
      if (err.toString().includes("LimitExceededException")) {
        setMessage({
          variant: "error",
          text: t("errors.LimitExceededException"),
        });
      } else if (err.toString().includes("NotAuthorizedException")) {
        setMessage({
          variant: "error",
          text: t("errors.notAuthorizedException"),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useNotistack(message?.text, message, setMessage, message?.variant);

  return (
    <Grid container style={{ padding: "24px 48px" }}>
      <Grid item xs={12}>
        <img
          src={IMAGES_LINK + "/homepage/exit.svg"}
          alt={"exit"}
          onClick={handleClose}
          style={{ cursor: "pointer" }}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Typography
          style={{ fontSize: FONT_TITLE_LG, color: blue_1, fontWeight: "bold" }}
        >
          {t("login.forgotPasswordTitle")}
        </Typography>
      </Grid>
      <form
        noValidate
        style={{ width: "100%", marginTop: 10 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          fullWidth
          id="password"
          type="password"
          {...register("password", {
            required: true,
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
          })}
          placeholder={t("login.password")}
          variant="outlined"
          size="small"
        />
        {errors.password?.type === "required" && (
          <Typography
            style={{
              color: negative,
              fontSize: FONT_TEXT,
              paddingTop: "6px",
            }}
          >
            {t("errors.must")}
          </Typography>
        )}
        {errors.password?.type === "pattern" && (
          <Typography
            style={{
              color: negative,
              fontSize: FONT_TEXT,
              paddingTop: "6px",
            }}
          >
            {t("errors.pattern")}
          </Typography>
        )}
        <Grid item xs={12}>
          {isLoading && <LoadingGif width={100} />}
        </Grid>
        <Button
          type="submit"
          style={{
            marginTop: 10,
            width: "100%",
            backgroundColor: blue_1,
            color: "white",
            borderRadius: borderRadius,
          }}
        >
          {t("buttons.submit")}
        </Button>
      </form>
    </Grid>
  );
};
