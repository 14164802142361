import { darkThemeSliderRail } from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStylesTama = makeStyles({
	select: props => ({
		"&.Mui-selected": {
			backgroundColor: props.isDarkTheme ? `${darkThemeSliderRail} !important` : "inherit",
		},
		"&.Mui-selected:hover": {
			backgroundColor: props.isDarkTheme ? `#2b44684a !important` : "inherit",
		},
		".MuiListItem-button:hover": {
			backgroundColor: props.isDarkTheme ? `#2b44684a !important` : "inherit",
		},
		"&.MuiListItem-button:hover": {
			backgroundColor: props.isDarkTheme ? `#2b44684a !important` : "inherit",
		},
	}),
});
