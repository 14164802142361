import React, { useContext } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import Check from "@material-ui/icons/Check";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { darkMainPropColor, mainPropColor } from "../../../services/colors";
import clsx from "clsx";
import { StepConnectorProps } from "@material-ui/core/StepConnector";
import { XS_PLUS_FONT } from "../../../services/fonts";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(t: any) {
  return [
    t("Tama.steps.preliminary"),
    t("Tama.steps.permit"),
    t("Tama.steps.start"),
    t("Tama.steps.execution"),
    t("Tama.steps.population"),
  ];
}
interface Props {
  isDarkTheme?: boolean;
}
const Connector = withStyles({
  alternativeLabel: {
    top: 10,
  },
  active: (props: Props) => ({
    "& $line": {
      backgroundColor: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    },
  }),
  completed: (props: Props) => ({
    "& span": {
      backgroundColor: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    },
  }),
  line: (props: Props) => ({
    height: "100%",
    width: 2,
    border: 0,
    backgroundColor: props.isDarkTheme ? "#9e9e9e" : "#eaeaf0",
  }),
})((props: StepConnectorProps) => <StepConnector {...props} />);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: (props: Props) => ({
    backgroundColor: props.isDarkTheme
      ? `${darkMainPropColor} !important`
      : `${mainPropColor} !important`,
  }),
  circleCompleted: (props: Props) => ({
    width: 30,
    height: 30,
    borderRadius: "50%",
    backgroundColor: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  circle: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    backgroundColor: "#9e9e9e",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      color: "white",
      fontSize: XS_PLUS_FONT,
    },
  },
  completed: {
    color: "#fff",
    zIndex: 1,
    fontSize: XS_PLUS_FONT,
  },
});
function QontoStepIcon(props: StepIconProps) {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useQontoStepIconStyles({ isDarkTheme });
  const { active, completed, icon } = props;

  return (
    <div>
      {completed ? (
        <div className={classes.circleCompleted}>
          <Check className={classes.completed} />
        </div>
      ) : (
        <div
          className={clsx(classes.circle, {
            [classes.active]: active,
          })}
        >
          <Typography>{icon}</Typography>
        </div>
      )}
    </div>
  );
}

const TamaStepper: React.FC<{ activeStep: number }> = ({ activeStep }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const steps = getSteps(t);

  return (
    <div className={classes.root}>
      <Stepper
        style={{ height: "100%", padding: "0 24px" }}
        orientation="vertical"
        activeStep={activeStep}
        connector={<Connector isDarkTheme={isDarkTheme} />}
      >
        {steps.map((label) => (
          <Step key={label} style={{ padding: "10px 0" }}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
export default TamaStepper;
