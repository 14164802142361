import React, { useContext, useEffect, useState } from "react";
import RadarChart from "../charts/RadarChart";
import { darkMainPropColor, greyAxis, mainPropColor } from "../services/colors";
import { LegendInterface } from "./DataStackBarChart";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { PointStyle } from "chart.js";
import { labelData } from "../components/dashboard-components/tama/TamaLineChart";
import { SpinnerInsideCharts } from "../components/layout/Layout";
import { IMAGES_LINK } from "common-components";
import { useTranslation } from "react-i18next";
import { use } from "i18next";

const collator = new Intl.Collator("he");

const chartPoint = new Image();
chartPoint.src = `${IMAGES_LINK}/dashboard/elements/location-pin-scatter.svg`;

const chartPointDark = new Image();
chartPointDark.src = `${IMAGES_LINK}/dashboard/elements/location-pin-scatter-dark.svg`;

const pointStyles: PointStyle[] = [
  "cross",
  "triangle",
  "star",
  "circle",
  "rect",
  "line",
  "rectRot",
];
interface datasets {
  label?: string;
  lineTension?: number;
  backgroundColor?: string;
  borderColor?: string;
  pointBackgroundColor?: string;
  pointBorderColor: string;
  pointRadius?: number;
  pointHoverRadius?: number;
  pointHitRadius?: number;
  pointHoverBackgroundColor?: string;
  pointHoverBorderColor?: string;
  distance: string[];
  places: number[];
  data?: (number | null)[];
  pointStyle?: HTMLImageElement | PointStyle;
}

export function normaliseDataRadar(
  data: AllProperties,
  myProp: MyProperty,
  isDarkTheme: boolean,
  t: Function
) {
  const res: {
    labels: string[];
    datasets: datasets[];
  } = {
    labels: [
      `${t("RadarChart.north")}`,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `${t("RadarChart.east")}`,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `${t("RadarChart.south")}`,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `${t("RadarChart.west")}`,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    datasets: data.series
      .sort((a, b) => collator.compare(a.label, b.label))
      .map((item, index) => {
        const places: number[] = [];
        item.data.forEach((item, index) => {
          if (item !== 0) places.push(index);
        });
        return {
          label: item.label,
          lineTension: 0.5,
          backgroundColor: "rgba(0, 0, 0, 0)",
          borderColor: "rgba(0, 0, 0, 0)",
          pointStyle: pointStyles[index],
          pointBackgroundColor: isDarkTheme ? darkMainPropColor : mainPropColor,
          pointBorderColor: isDarkTheme ? darkMainPropColor : mainPropColor,
          pointRadius: 4,
          pointHoverRadius: 5,
          pointHitRadius: 10,
          pointHoverBackgroundColor: isDarkTheme
            ? darkMainPropColor
            : mainPropColor,
          pointHoverBorderColor: isDarkTheme
            ? darkMainPropColor
            : mainPropColor,
          distance: item.distance,
          places: places,
          data: item.data.map((item) => (item === 0 ? null : item)),
        };
      }),
  };

  const myPropObj = {
    label: "My center ",
    backgroundColor: greyAxis,
    borderColor: greyAxis,
    pointBackgroundColor: greyAxis,
    pointBorderColor: greyAxis,
    pointHoverBackgroundColor: greyAxis,
    pointHoverBorderColor: greyAxis,
    data: myProp.series,
    pointStyle: isDarkTheme ? chartPointDark : chartPoint,
    places: [],
    distance: [""],
  };
  res.datasets.unshift(myPropObj);
  return res;
}
export interface DataRadarChartInterface {
  all: AllProperties;
  property: MyProperty;
}

export interface AllProperties {
  series: Series[];
}

export interface Series {
  data: number[];
  distance: string[];
  label: string;
}

export interface MyProperty {
  series: number[];
}
export interface RadarChartInterface {
  labels: string[];
  datasets: datasets[];
}
const DataRadarChart: React.FC<{ rawData: DataRadarChartInterface }> = ({
  rawData,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [data, setData] = useState<RadarChartInterface | undefined>(undefined);
  const [legend, setLegend] = useState<LegendInterface[] | undefined>(
    undefined
  );
  const { t } = useTranslation();
  useEffect(() => {
    setData(normaliseDataRadar(rawData.all, rawData.property, isDarkTheme, t));
    setLegend(
      labelData(
        false,
        rawData.all,
        isDarkTheme,
        mainPropColor,
        darkMainPropColor,
        "series"
      )
    );
  }, [rawData]);

  if (data && legend) {
    return <RadarChart data={data} legend={legend} />;
  } else {
    return <SpinnerInsideCharts />;
  }
};
export default DataRadarChart;
