import { Grid, Typography } from "@material-ui/core";
import { IMAGES_LINK } from "../../api/api_links";
import { useTranslation } from "react-i18next";
import { FONT_TEXT } from "../../../comps/services/constants/fonts";

export const Terms = () => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column" item xs={12} style={{ marginTop: 5 }}>
      <Grid container alignItems="center">
        <img
          src={IMAGES_LINK + "/map/verified.svg"}
          style={{ marginLeft: 5 }}
          alt={"verified"}
        />
        <Typography style={{ fontSize: FONT_TEXT, paddingLeft: "5px" }}>
          {t("pricing.charge")}
        </Typography>
      </Grid>
      <Grid container alignItems="center">
        <img
          src={IMAGES_LINK + "/map/verified.svg"}
          style={{ marginLeft: 5 }}
          alt={"verified"}
        />
      <Typography style={{ fontSize: FONT_TEXT, paddingLeft: "5px" }}>
          {t("pricing.freeCancelation")}
        </Typography>
      </Grid>
    </Grid>
  );
};
