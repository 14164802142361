import { darkBackground } from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStylesLayout = makeStyles({
  divContent: (props) => ({
    paddingTop: 12,
    backgroundColor: props.isDarkTheme ? darkBackground : "white",
    display: "inline-block",
    width: `calc(100% - ${props.spacing * 2}px)`,
    padding: "0 24px",
    position: "relative",
  }),
  divWidth: { height: "100%", margin: "0 auto", maxWidth: 1380 },
});
