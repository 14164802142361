import Grid from "@material-ui/core/Grid";
import React, { useContext, useEffect, useState } from "react";
import { addingComma } from "../../../services/helpers";
import { ToggleSlider } from "common-components";
import TilesContainer from "../../TilesContainer";
import { useIsMobileScreen } from "common-components";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { DirectionContext } from "../../../context/DirectionContext";
import {
  borderColor,
  darkBorderColor,
  darkBoxes,
  darkText,
  lightBoxes,
} from "../../../services/colors";
import Typography from "@material-ui/core/Typography";
import { LG_PLUS_FONT } from "../../../services/fonts";
import DemoExample from "../../DemoExample";
import { useIsIncludes } from "common-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TitleGrid from "../../TitleGrid";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { LANDING_PAGE_LINK } from "common-components";
import { ToggleSliderContext } from "../../../context/ToggleSliderContext";

const date = new Date();

export function findingPriceIndex(value, month) {
  if (value === 25) {
    return 25 * 2;
  }
  if (value === 0) {
    return 0;
  } else if (month <= 6) {
    return value * 2 + 1;
  } else {
    return value * 2;
  }
}

const xs = 12;
const sm = 6;

export const useStylesCardBorder = makeStyles({
  border: (props) => ({
    position: "relative",
    "&:after": {
      content: "''",
      backgroundColor: props.isDarkTheme ? darkBorderColor : borderColor,
      position: "absolute",
      top: "calc((100% - 65%)/2)",
      right: 0,
      height: "65%",
      width: 1,
    },
  }),
});

const PriceForecast = ({ lineData, month }) => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [value, setValue] = useContext(ToggleSliderContext);
  const currentYear = date.getFullYear();
  const [year, setYear] = useState(currentYear + 1);
  const [gro, setGro] = useState(undefined);
  const [carg, setCarg] = useState(undefined);
  const [increase, setIncrease] = useState(undefined);
  const isSmallScreen = useIsMobileScreen();
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const isDemo = useIsIncludes("demo");
  const theme = useTheme();
  const betweenMobTab = useMediaQuery(theme.breakpoints.between(960, 1015));
  const classes = useStylesCardBorder({ isDarkTheme });

  const { t } = useTranslation();

  useEffect(() => {
    setYear(value ? currentYear + value : currentYear + value + 1);
  }, [value]);
  findingPriceIndex();

  useEffect(() => {
    if (lineData) {
      const fv =
        lineData[findingPriceIndex(value ? value : value + 1, Number(month))];
      setGro(((fv - lineData[0]) / lineData[0]) * 100);
      setCarg((Math.pow(fv / lineData[0], 1 / (year - currentYear)) - 1) * 100);
      setIncrease(fv - lineData[0]);
    }
  }, [year, lineData]);

  return (
    <Grid container item xs={12} style={{ height: "100%" }}>
      <TilesContainer
        padding={"16px 32px"}
        style={{ minWidth: "200px" }}
        topBorder
        relative
      >
        {isDemo && <DemoExample />}
        <Grid
          container
          justify={"space-between"}
          style={{
            height: "100%",
          }}
          spacing={isExtraSmallScreen ? 1 : 0}
        >
          <TitleGrid
            title={t("PriceForecast.title")}
            tooltipText={
              <p>
                {t("PriceForecast.tooltip")}{" "}
                <a
                  href={`${LANDING_PAGE_LINK}/terms`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "underline",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  {t("PriceForecast.tooltipLink")}
                </a>
                .
              </p>
            }
            interactive
          />

          <Grid
            item
            xs={12}
            style={{
              padding: "8px 0",
            }}
          >
            <ToggleSlider
              radius
              widthContainer={230}
              widthRoot={46}
              value={value}
              setValue={setValue}
              font={direction.isRtl ? false : "0.7rem"}
              list={[
                { value: 0, text: `${t("year")}` },
                { value: 3, text: `3 ${t("years")}` },
                { value: 5, text: `5 ${t("years")}` },
                { value: 10, text: `10 ${t("years")}` },
                { value: 25, text: `25 ${t("years")}` },
              ]}
              isDarkTheme={isDarkTheme}
            />
          </Grid>

          {dataCards(
            t,
            year,
            month,
            lineData,
            gro,
            increase,
            value,
            carg,
            isExtraSmallScreen,
            isSmallScreen
          ).map((item) => {
            return (
              <Grid
                item
                xs={xs}
                sm={sm}
                key={item.name}
                className={clsx({
                  [classes.border]: !item.noBorder,
                })}
                style={{
                  backgroundColor: isDarkTheme ? darkBoxes : lightBoxes,
                  marginTop: isExtraSmallScreen ? 10 : 0,
                }}
              >
                <Card
                  padding={betweenMobTab ? "16px 10px" : 16}
                  value={item.value}
                  name={item.name}
                />
              </Grid>
            );
          })}
        </Grid>
      </TilesContainer>
    </Grid>
  );
};
export default PriceForecast;

export function Card({ name, value, padding }) {
  const [isDarkTheme] = useContext(DarkThemeContext);
  return (
    <Grid
      container
      justify={"space-between"}
      item
      xs={12}
      style={{
        padding: padding ? padding : 16,
        height: "100%",
      }}
    >
      <Grid item xs={12}>
        <Typography
          style={{
            color: isDarkTheme ? darkText : "",
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <bdi style={{ fontSize: LG_PLUS_FONT }}>{value}</bdi>
      </Grid>
    </Grid>
  );
}

function dataCards(
  t,
  year,
  month,
  lineData,
  gro,
  increase,
  value,
  carg,
  isExtraSmallScreen
) {
  return [
    {
      value:
        month && lineData ? (
          `₪ ${addingComma(
            lineData[
              findingPriceIndex(value ? value : value + 1, Number(month))
            ]
          )}`
        ) : (
          <bdi>₪ &mdash; &mdash;</bdi>
        ),
      name: `${t("PriceForecast.cardsForecast.1")}${year}`,
      noBorder: isExtraSmallScreen,
    },
    {
      value: lineData ? (
        gro >= 0 ? (
          `${gro.toFixed(2)}%`
        ) : (
          <bdi> &mdash; &mdash;% </bdi>
        )
      ) : (
        <bdi> &mdash; &mdash;% </bdi>
      ),
      name: t("PriceForecast.cardsForecast.2"),
      noBorder: true,
    },
    {
      value: lineData ? (
        `₪ ${addingComma(increase)}`
      ) : (
        <bdi>₪ &mdash; &mdash;</bdi>
      ),
      name: t("PriceForecast.cardsForecast.3"),
      noBorder: isExtraSmallScreen,
    },
    {
      value: lineData ? (
        carg >= 0 ? (
          `${carg.toFixed(2)}%`
        ) : (
          <bdi> &mdash; &mdash;% </bdi>
        )
      ) : (
        <bdi> &mdash; &mdash;% </bdi>
      ),
      name: t("PriceForecast.cardsForecast.4"),
      noBorder: true,
    },
  ];
}
