import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { mainPropColor } from "../global-il/services/colors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  default: {
    backgroundColor: mainPropColor,
    color: "#fff",
    border: "2px solid #fff",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "none",
    },
  },
});

export const PropdoButton = ({
  text,
  style,
  handleClick,
  isDisabled,
  icon,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      style={style}
      onClick={handleClick}
      className={classes.default}
      disabled={isDisabled}
    >
      {text} {icon}
    </Button>
  );
};
