import { useForm } from "react-hook-form";
import { doFetch } from "common-components";
import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextareaAutosize } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  darkElements,
  darkText,
  darkThemeNegativeRed,
  greyAxis,
  negativeRedColor,
  positiveGreenColor,
} from "../../services/colors";
import CloseButton from "../../components/CloseButton";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { useIsMobileScreen } from "common-components";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { MD_FONT, XS_FONT } from "../../services/fonts";
import Typography from "@material-ui/core/Typography";
import { LoadingButtons } from "../../components/layout/Layout";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PropertyIdContext } from "../../context/PropertyIdContext";
import { DirectionContext } from "../../context/DirectionContext";

const useStyles = makeStyles({
  errorsStyle: (props) => ({
    color: props.isDarkTheme ? darkThemeNegativeRed : negativeRedColor,
    maxWidth: 200,
    fontSize: XS_FONT,
    textAlign: "left",
  }),
  textareaContact: (props) => ({
    padding: "8.5px 14px",
    borderRadius: "var(--border-radius)",
    backgroundColor: props.isDarkTheme ? darkElements : "#ffffff",
    fontSize: MD_FONT,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: props.isDarkTheme ? darkText : "",
    display: "block",
    width: "-webkit-fill-available",
    boxShadow: "none",
  }),
});

const defaultValues = {
  name: "",
  phone_number: "",
  email: "",
  message: "",
};

const ContactForm = () => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { closeDialog } = useDialogManager();
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const isSmallScreen = useIsMobileScreen();
  const classes = useStyles({ isDarkTheme });
  let location = useLocation();
  const { t } = useTranslation();

  const onSubmit = (values) => {
    setLoading(true);
    doFetch(`/user/request?region=${direction.region}`, {
      method: "POST",
      body: JSON.stringify({
        request_type: location.pathname.substring(1),
        subject: "contact",
        message: values.message,
      }),
    }).then((data) => {
      setLoading(false);
      setIsSubscribed(true);
      setValue("message", "");
      setTimeout(() => {
        closeDialog();
      }, 4500);
    });
  };
  const onClose = () => {
    closeDialog();
  };
  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{
        maxWidth: 700,
        padding: isSmallScreen ? 16 : 24,
        margin: "0 auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          container
          xs={12}
          justify={"space-between"}
          style={{ borderBottom: "1px solid rgba(208, 209, 210, 0.5)" }}
        >
          <Typography
            variant={"h5"}
            style={{
              padding: "15px 20px",
              color: isDarkTheme ? darkText : greyAxis,
            }}
          >
            {t("ContactUs.title")}
          </Typography>
          <CloseButton callback={onClose} padding={"15px 15px 15px 0"} />
        </Grid>
        <Grid item xs={12} style={{ height: 10, padding: 2 }} />

        <Grid item xs={12} style={{ position: "relative" }}>
          <TextareaAutosize
            id="message"
            name="message"
            error={errors["message"] !== undefined}
            placeholder={t("ContactUs.message")}
            className={classes.textareaContact}
            maxLength={500}
            minLength={2}
            style={{
              fontSize: MD_FONT,
              height: 130,
              resize: "none",
            }}
            {...register("message", {
              required: "Name is required",
              maxLength: 500,
              minLength: 2,
            })}
          />
          {errors.message?.type === "required" && (
            <Typography className={classes.errorsStyle}>
              {t("ContactUs.requiredMsg")}
            </Typography>
          )}
          {errors.message?.type === "maxLength" && (
            <Typography className={classes.errorsStyle}>
              {t("ContactUs.maxLengthMsg")}
            </Typography>
          )}
          {errors.message?.type === "minLength" && (
            <Typography className={classes.errorsStyle}>
              {" "}
              {t("ContactUs.minLengthMsg")}
            </Typography>
          )}
        </Grid>
        <Grid container item xs={12} justify={"center"}>
          <Button
            type={"submit"}
            variant={"contained"}
            color={"primary"}
            style={{
              width: "30%",
              backgroundColor: isSubscribed ? positiveGreenColor : "",
            }}
          >
            {isSubscribed
              ? `${t("ContactUs.wasSend")}`
              : `${t("ContactUs.send")}`}

            {loading && (
              <LoadingButtons color={"#fff"} style={{ padding: "0 5px" }} />
            )}
            {isSubscribed && <DoneAllIcon />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default ContactForm;
