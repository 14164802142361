import React, { useContext, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { TooltipInfoIcon } from "common-components";
import TilesContainer from "../TilesContainer";
import { greyAxis } from "../../services/colors";
import UserManagementInput from "./UserManagementInput";
import UserManagementButtons from "./UserManagementButtons";
import { useIsMobileScreen } from "common-components";
import { LoadingCharts } from "../layout/Layout";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import CloseButton from "../CloseButton";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { useNotistack } from "common-components";
import { DarkThemeContext } from "../../context/DarkThemeContext";

const UserManagementPassword = ({ userData, classes }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { closeDialog } = useDialogManager();
  const [userPassword, setUserPassword] = useState({
    newPassword: "",
    oldPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [watchShowPassword, setWatchShowPassword] = useState({
    showNewPassword: false,
    showOldPassword: false,
  });
  const [passwordResponse, setPasswordResponse] = useState(false);
  const isSmallScreen = useIsMobileScreen();

  const toggleShowPassword = (event) => {
    let myWatchShowPassword = { ...watchShowPassword };
    myWatchShowPassword[event.target.name] = event.target.checked;
    setWatchShowPassword(myWatchShowPassword);
  };
  const { t } = useTranslation();
  const handleChangePassword = (event) => {
    const myUserPassword = { ...userPassword };
    myUserPassword[event.target.name] = event.target.value;
    setUserPassword(myUserPassword);
  };

  const onSubmitPassword = async () => {
    setLoading(true);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          userPassword.oldPassword,
          userPassword.newPassword
        );
      })
      .then((data) => {
        setPasswordResponse({
          text: t("UserManagement.password.responseSuccess"),
          variant: "success",
        });
        closeDialog();
      })
      .catch((err) => {
        if (err.toString().includes("NotAuthorizedException")) {
          setPasswordResponse({
            text: t("UserManagement.password.responseNotAuthorized"),
            variant: "error",
          });
        } else if (err.toString().includes("LimitExceededException")) {
          setPasswordResponse({
            text: t("UserManagement.password.responseLimitExceeded"),
            variant: "error",
          });
        }
        closeDialog();
      });

    setLoading(false);
  };

  useNotistack(
    passwordResponse.text,
    passwordResponse,
    setPasswordResponse,
    passwordResponse.variant
  );

  const handleClose = () => {
    closeDialog();
  };
  return (
    <TilesContainer
      style={{
        width: "100%",
        margin: "0px auto",
        border: "none",
      }}
    >
      {!userData && <LoadingCharts name={""} size={"3x"} />}
      {userData && userData.name && (
        <form
          noValidate
          onSubmit={handleSubmit(onSubmitPassword)}
          style={{
            margin: "0px auto",
            textAlign: "center",
            height: "calc(100% - 24px)",
          }}
        >
          <Grid
            container
            justify={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing={3}
          >
            <Grid
              container
              item
              xs={12}
              justify={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant={"h5"} gutterBottom>
                  {t("UserManagement.password.changePassword")}
                </Typography>
              </Grid>
              <Grid item>
                <CloseButton callback={handleClose} padding={"0"} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <UserManagementInput
                xs={[3, 9]}
                title={`${t("UserManagement.password.oldPassword")}`}
                name={"oldPassword"}
                registerOptions={{
                  required: "Password is required",
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                }}
                register={register}
                type={watchShowPassword.showOldPassword ? "text" : "password"}
                value={userPassword.oldPassword}
                disabled={false}
                onChange={(event) => handleChangePassword(event)}
                errors={errors["oldPassword"]}
                errorsMsgs={{
                  notEqual: `${t(
                    "UserManagement.password.passwordsNoMatchError"
                  )}`,
                }}
                variant="outlined"
                placeholder={`${t("UserManagement.password.enterNewPassword")}`}
                InputProps={{
                  className: classes.inputTextField,
                  endAdornment: (
                    <InputAdornment position="end">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="showOldPassword"
                            defaultChecked={!!watchShowPassword.showOldPassword}
                            {...register("showOldPassword")}
                            color="primary"
                            size={"medium"}
                            onChange={(event) => toggleShowPassword(event)}
                            icon={
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                color={greyAxis}
                                size={"xs"}
                              />
                            }
                            disableRipple
                            checkedIcon={
                              <FontAwesomeIcon
                                icon={faEye}
                                color={greyAxis}
                                size={"xs"}
                              />
                            }
                          />
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <UserManagementInput
                xs={[3, 9]}
                title={`${t("UserManagement.password.newPassword")}`}
                name={"newPassword"}
                register={register}
                registerOptions={{
                  required: "Password is required",
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                }}
                type={watchShowPassword.showNewPassword ? "text" : "password"}
                value={userPassword.newPassword}
                disabled={false}
                onChange={(event) => handleChangePassword(event)}
                errors={errors["newPassword"]}
                errorsMsgs={{
                  require: "נדרש",
                  pattern: `${t("UserManagement.password.passwordTooltip")}`,
                }}
                variant="outlined"
                placeholder={`${t("UserManagement.password.enterNewPassword")}`}
                InputProps={{
                  className: classes.inputTextField,
                  endAdornment: (
                    <InputAdornment position="end">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="showNewPassword"
                            defaultChecked={!!watchShowPassword.showNewPassword}
                            {...register("showNewPassword")}
                            color="primary"
                            size={"medium"}
                            onChange={(event) => toggleShowPassword(event)}
                            icon={
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                color={greyAxis}
                                size={"xs"}
                              />
                            }
                            disableRipple
                            checkedIcon={
                              <FontAwesomeIcon
                                icon={faEye}
                                color={greyAxis}
                                size={"xs"}
                              />
                            }
                          />
                        }
                      />
                    </InputAdornment>
                  ),
                }}
                toolTip={
                  <TooltipInfoIcon
                    tooltipText={
                      isSmallScreen ? (
                        <>{t("UserManagement.password.passwordTooltip")}</>
                      ) : (
                        <>{t("UserManagement.password.passwordTooltip")}</>
                      )
                    }
                    question
                    isDarkTheme={isDarkTheme}
                  />
                }
              />
            </Grid>

            <UserManagementButtons
              title={`${t("UserManagement.password.save")}`}
              loading={loading}
            />
          </Grid>
        </form>
      )}
    </TilesContainer>
  );
};

export default UserManagementPassword;
