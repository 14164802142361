import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import propdoLogo from "./logo.svg";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import { useIsMobileScreen } from "../..";
import { Grid, Typography } from "@material-ui/core";
import { greyPropColor } from "../../global-il/services/colors";

const useStyles = makeStyles({
  footer: {
    background: "#001145",
    width: "100%",
    height: "310px",
    // zIndex: 1302,
    // position: "relative",
  },
  mainContainer: {
    position: "absolute",
  },
  logo: {
    width: "40px",
    marginBottom: "120px",
  },
  mobileLogo: {
    paddingRight: "200px",
    paddingTop: "30px",
    position: "absolute",
  },
  link: {
    color: "white",
    fontSize: "0.75rem",
    // fontWeight: "bold",
  },
  gridItem: {
    margin: "3em",
  },
  title: {
    color: "#32D3D3",
  },
  icon: {
    height: "2em",
    width: "2em",
    color: "#fff",
  },
  mobileLink: {
    color: "white",
    fontSize: "1.4rem",
  },
  gridMobileItem: {
    margin: "1.5em",
  },
  //   subtitle: {
  //     color: "#fff",
  //   },
});

export const Footer = () => {
  const classes = useStyles();
  const isMobileScreen = useIsMobileScreen();
  const { t } = useTranslation();

  const footer = [
    {
      title: t("footer.contact.title"),
      subtitles: [
        t("footer.contact.learnMore"),
        t("footer.contact.propdoPhoneNumber"),
        t("footer.contact.propdoEmail"),
      ],
    },
    {
      title: t("footer.offices.title"),
      subtitles: [
        t("footer.offices.nyc"),
        t("footer.offices.br"),
        t("footer.offices.il"),
      ],
    },
    {
      title: t("footer.product.title"),
      subtitles: [
        t("footer.product.prices"),
        t("footer.product.Propdo"),
        t("footer.product.report"),
      ],
    },
    {
      title: t("footer.more.title"),
      subtitles: [t("footer.more.privacyPolicy"), t("footer.more.terms")],
    },
  ];

  return (
    <footer className={classes.footer}>
      <Grid container justifyContent="center" className={classes.mainContainer}>
        {isMobileScreen ? (
          <>
            <img alt="logo" src={propdoLogo} className={classes.mobileLogo} />

            <Grid
              container
              item
              align="center"
              className={classes.gridMobileItem}
            >
              <Grid container spacing={2} direction="column">
                <Grid item className={classes.mobileLink}>
                  Propdo
                </Grid>
                <Grid item className={classes.mobileLink}>
                  Prices
                </Grid>
                <Grid item className={classes.mobileLink}>
                  Report
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                borderBottom: `1px solid ${greyPropColor}`,
                width: "70%",
              }}
            />
            <Grid
              container
              item
              align="center"
              direction="column"
              style={{ marginTop: "20px" }}
            >
                <Grid item className={classes.link}>
                  New York, 353 Third Avenue, #338, New York, NY 10010, US
                </Grid>
                <Grid item className={classes.link}>
                  Hachilzon 6, Diamond Exhange District, Ramat Gan, Israel
                </Grid>
                <Grid item className={classes.link}>
                  Avenida Rebouças 1585, São Paulo, 05401-250, BR
                </Grid>

            </Grid>

            <Grid
              container
              item
              justifyContent="center"
              style={{ marginTop: "20px" }}
            >
              <Grid
                item
                component={"a"}
                href="https://www.facebook.com/PropdoIL/"
              >
                <FacebookIcon className={classes.icon} />
              </Grid>
              <Grid
                item
                component={"a"}
                href="https://www.linkedin.com/company/propdo/"
              >
                <LinkedInIcon className={classes.icon} />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <img alt="logo" src={propdoLogo} className={classes.logo} />

            <Grid item lg={2} className={classes.gridItem}>
              <Grid container direction="column" spacing={2}>
                <Grid item className={classes.title}>
                  Contact
                </Grid>
                <Grid item className={classes.link}>
                  Want to learn more?
                </Grid>
                <Grid item className={classes.link}>
                  Call +1 (646) 662-2467
                </Grid>
                <Grid item className={classes.link}>
                  Contact@propdo.com
                </Grid>
                <Grid container item spacing={1}>
                  <Grid
                    item
                    component={"a"}
                    href="https://www.facebook.com/PropdoIL/"
                  >
                    <FacebookIcon className={classes.icon} />
                  </Grid>
                  <Grid item component={"a"} href="https://www.linkedin.com">
                    <LinkedInIcon className={classes.icon} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={2} className={classes.gridItem} style={{ width: "230px" }}>
              <Grid container direction="column" spacing={2}>
                <Grid item className={classes.title}>
                  Our Offices
                </Grid>
                <Grid item className={classes.link}>
                  New York, 353 Third Avenue, #338, New York, NY 10010, US
                </Grid>
                <Grid item className={classes.link}>
                  Hachilzon 6, Diamond Exhange District, Ramat Gan, Israel
                </Grid>
                <Grid item className={classes.link}>
                  Avenida Rebouças 1585, São Paulo, 05401-250, BR
                </Grid>
              </Grid>
            </Grid>

            <Grid item  lg={2} className={classes.gridItem}>
              <Grid container direction="column" spacing={2}>
                <Grid item className={classes.title}>
                  The Product
                </Grid>
                <Grid item className={classes.link}>
                  Prices
                </Grid>
                <Grid item className={classes.link}>
                  Propdo
                </Grid>
                <Grid item className={classes.link}>
                  report
                </Grid>
              </Grid>
            </Grid>

            <Grid item  lg={2} className={classes.gridItem}>
              <Grid container direction="column" spacing={2}>
                <Grid item className={classes.title}>
                  More
                </Grid>
                <Grid item className={classes.link}>
                  Privacy Policy
                </Grid>
                <Grid item className={classes.link}>
                  Terms of Use
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </footer>
  );
};

//TODO with map function
{
  /* <Grid container>
<Grid item container xs={12}>
  {footer.map((item, idx) => {
    return (
      <Grid item container xs={3} key={idx}>
        <Grid item xs={12}>
          <Typography style={{ color: "#32D3D3" }}>
            {item.title}
          </Typography>
          <Grid item xs={12}>
            <Typography style={{ color: "#fff" }}>
              {item.subtitles}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  })}
</Grid>
</Grid>  */
}
