import { Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { LoadingCharts, SPACING_FACTOR } from "../layout/Layout";
import TilesContainer from "../TilesContainer";
import DemoExample from "../DemoExample";
import MonthlyRentBox, {
  AverangeRentInfoBox,
  ConclusionsRentBox,
} from "../MonthlyRent";
import DataStackBarChart from "../../data/DataStackBarChart";
import { useIsIncludes } from "common-components";
import { PropertyIdContext } from "../../context/PropertyIdContext";
import { OpenAccessContext } from "../../context/OpenAccessContext";
import { doFetch } from "common-components";
import { useNotistack } from "common-components";
import {
  disableScrolling,
  enableScrolling,
} from "../../charts/mortgage/MortgageLineChart";
import { useLazyDataApi } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";
import { Helmet } from "react-helmet";

const Rent = () => {
  const [direction] = useContext(DirectionContext);
  const [{ data, error: lazyError }, lazyFetch] = useLazyDataApi();
  const isDemo = useIsIncludes("demo");
  const [propertyId] = useContext(PropertyIdContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [rentData, setRentData] = useState({});
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useNotistack(
    `${t("updateSucceedMsg")}`,
    updateSuccess,
    setUpdateSuccess,
    "success"
  );
  useNotistack(
    `${t("updateFailedMsg")}`,
    updateFailed,
    setUpdateFailed,
    "error"
  );

  useEffect(() => {
    if (typeof propertyId === "object") {
      lazyFetch(
        `/reports/dashboard/page/rent?id=${
          isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
        }`
      );
    }
  }, [propertyId]);

  useEffect(() => {
    if (data) {
      setRentData(data);
    }
  }, [data]);

  const handleUpdate = async (rent) => {
    setLoading(true);
    try {
      const response = await doFetch(
        `/reports/dashboard/page/rent/update?id=${
          isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
        }`,
        {
          method: "POST",
          body: JSON.stringify({ expected_rent: rent }),
        }
      );
      let myRentData = { ...rentData };
      myRentData = {
        ...myRentData,
        ROI: response.ROI,
        expected_rent: response.expected_rent,
      };
      setRentData(myRentData);
      setUpdateSuccess(true);
    } catch (e) {
      setUpdateFailed(true);
    }
    setLoading(false);
  };

  const handleReset = async (rent) => {
    await handleUpdate(rent);
  };

  const handleSetRent = async (rent) => {
    await handleUpdate(Number(rent));
  };

  return (
    <Grid
      container
      spacing={SPACING_FACTOR}
      style={{ backgroundColor: "#e1edf7", margin: "0", width: "revert" }}
    >
      <Helmet>
        <title>{t("MetaData.rentTitle")}</title>
        <description>{t("MetaData.rentDescription")}</description>
      </Helmet>
      <Grid item xs={12}>
        <TilesContainer style={{ height: "100%", border: "none" }} relative>
          <Typography variant={"h5"}>{t("Rent.title")}</Typography>
        </TilesContainer>
      </Grid>
      <Grid item xs={12}>
        {rentData ? (
          <AverangeRentInfoBox data={rentData} />
        ) : (
          <div style={{ height: "100%", position: "relative" }}>
            <LoadingCharts
              variant={"h5"}
              name={`${t("Rent.AverageRent")}`}
              height={"5%"}
              minHeight={"0px"}
              padding={0}
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {rentData?.cv ? (
          <MonthlyRentBox
            CV={rentData.cv}
            currentRent={rentData.expected_rent}
            handleReset={(rent) => handleReset(rent)}
            handleSetRent={(rent) => handleSetRent(rent)}
            loading={loading}
            setLoading={setLoading}
          />
        ) : (
          <TilesContainer
            style={{ height: "100%", position: "relative" }}
            topBorder
          >
            <LoadingCharts
              variant={"h5"}
              height={"auto"}
              minHeight={"0px"}
              padding={0}
              name={`${t("Rent.MonthlyRent.actualRent")}`}
            />
          </TilesContainer>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {rentData ? (
          <ConclusionsRentBox data={rentData} />
        ) : (
          <LoadingCharts variant={"h5"} name={" "} />
        )}
      </Grid>
      <Grid item xs={12}>
        <TilesContainer relative>
          {isDemo && <DemoExample />}
          {rentData?.ROI ? (
            <div
              onTouchStart={() => {
                disableScrolling();
              }}
              onTouchEnd={() => {
                enableScrolling();
              }}
            >
              <DataStackBarChart rawData={rentData.ROI} />
            </div>
          ) : (
            <LoadingCharts variant={"h5"} name={t("StackBarChart.title")} />
          )}
        </TilesContainer>
      </Grid>
    </Grid>
  );
};
export default Rent;
