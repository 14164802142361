import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles({
  text: {
    display: "inline",
    width: "100%",
  },
  readOrHide: {
    color: "rgb(192,192,192)",
    cursor: "pointer",
  },
});

const ReadMore = ({ children }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = (ev) => {
    setIsReadMore(!isReadMore);
    ev.preventDefault()
  };

  return (
    <p className={classes.text}>
      {isReadMore ? text.slice(0, 50) : text}
      <span onClick={toggleReadMore} className={classes.readOrHide}>
        {isReadMore ? t("buttons.readMore") : t("buttons.showLess")}
      </span>
    </p>
  );
};

export default ReadMore;
