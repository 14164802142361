import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PackagePlan } from "./PackagePlan";
import { PropdoButton } from "../../buttons/PropdoButton";
import {
  FONT_TEXT,
  FONT_TITLE_LG,
  FONT_TITLE_XL,
} from "../../../../comps/services/constants/fonts";
import { blue_1 } from "../../../services/colors";
import {
  COMPS_LINK,
  HOMEPAGE_LINK,
  IMAGES_LINK,
  LANDING_PAGE_LINK,
  REPORTS_LINK,
} from "../../../api/api_links";
import { useDialogManager } from "../../../hooks/useDialogManager";
import { Purchase } from "../purchase/Purchase";
import { useIsLargeScreen } from "../../../hooks/useIsLargeScreen";
import { usePayment } from "../../../context/PaymentContext";
import { apiFetch } from "../../../api/apiFetch";
import { FramePurchase } from "../purchase/FramePurchase";
import { AuthGetIdToken } from "../../../services/helpers/auth";
import { useIsIncludes } from "../../../hooks/useIsIncludes";
import { SinglePlanList } from "./SinglePlanList";
import { useDirection } from "../../../context/DirectionContext";
import { useHistory } from "react-router-dom";
import { PricingDropdown } from "./PricingDropdown";
import { PRODUCTS } from "../../../../homepage/services/constants/productSearch";
import { useState } from "react";
import { Product } from "../../../interfaces/subscription";
import { useIsExactRoute } from "../../../hooks/useIsExactRoute";
import { useIsBaseRoute } from "../../../hooks/useIsBaseRoute";

export const PricingPlan: React.FC<{ projectName: string; title?: string }> = ({
  projectName,
  title = "title",
}) => {
  let history = useHistory();
  const { t } = useTranslation();
  const { openDialog, closeDialog } = useDialogManager();
  const isDesktopScreen = useIsLargeScreen("md");
  const { currPlan } = usePayment();
  const { direction } = useDirection();
  const isComps = useIsBaseRoute("comps");
  const isHomepage = useIsBaseRoute("homepage");
  //
  const [product, setProduct] = useState<string>("reports");
  const products = ["reports", "comps"];

  const handleContinue = () => {
    if (!currPlan) return;
    openDialog(Purchase, {
      DialogProps: { maxWidth: "sm" },
      DialogContentProps: {
        projectName,
      },
    });
  };

  const handleClose = () => {
    closeDialog();
  };

  return (
    <Grid container style={{ padding: !isDesktopScreen ? "10px" : "48px" }}>
      {!isComps && (
        <Grid container item xs={12} justifyContent="flex-end">
          <img
            src={IMAGES_LINK + "/homepage/exit.svg"}
            alt={"exit"}
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Grid>
      )}
      <Grid item style={{ marginBottom: 15 }}>
        <Typography
          style={{ fontSize: FONT_TITLE_XL, color: blue_1, fontWeight: 600 }}
        >
          {t(`pricing.${title}`)}
        </Typography>
        {title === "Trial Expired" && (
          <Typography
            style={{ fontSize: FONT_TEXT, color: blue_1, fontWeight: 600 }}
          >
            {t(`pricing.${title}`)}
          </Typography>
        )}
      </Grid>
      {isHomepage && (
        <PricingDropdown
          products={products}
          product={product}
          setProduct={setProduct}
        />
      )}
      <Grid container>
        <Grid container item xs={12}>
          {isHomepage && product === "comps" ? (
            <SinglePlanList />
          ) : isComps ? (
            <SinglePlanList />
          ) : (
            <PackagePlan />
          )}
        </Grid>
        {(title !== "Trial Expired" || product === "reports") && (
          <Grid container item xs={12} style={{ marginTop: 15 }}>
            <Typography style={{ fontWeight: 600 }}>
              {t("pricing.freePeriodTime")}
            </Typography>
            <Grid
              container
              direction="column"
              item
              xs={12}
              style={{ marginTop: 5 }}
            >
              <Grid container alignItems="center">
                <img
                  src={IMAGES_LINK + "/map/verified.svg"}
                  style={{ marginLeft: 5 }}
                  alt={"verified"}
                />
                <Typography style={{ fontSize: FONT_TEXT, paddingLeft: 5 }}>
                  {t("pricing.charge")}
                </Typography>
              </Grid>
              <Grid container alignItems="center">
                <img
                  src={IMAGES_LINK + "/map/verified.svg"}
                  style={{ marginLeft: 5 }}
                  alt={"verified"}
                />
                <Typography style={{ fontSize: FONT_TEXT, paddingLeft: "5px" }}>
                  {t("pricing.freeCancelation")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12} style={{ marginTop: 15 }}>
          {/* @ts-ignore */}
          <PropdoButton
            text={t("pricing.continue")}
            style={{ width: "100%", fontSize: FONT_TITLE_LG }}
            handleClick={handleContinue}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
