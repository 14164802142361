import React, { useContext, useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputAdornment from "@material-ui/core/InputAdornment";
import DialogOpenerAddProp from "../dialogs/DialogOpenerAddProp";
import { PaymentProcessContext } from "../context/PaymentProcessContext.tsx";
import { OpenAccessContext } from "../context/OpenAccessContext.tsx";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { darkText, greyAxis } from "../services/colors";
import { useStylesSearchPlaces } from "../styledComponent/SearchPlacesStyled";
import { useStylesTopBar } from "../styledComponent/TopBarStyled";
import { TextField } from "@material-ui/core";
import {
  propertyIL,
  PurchasePropertyContext,
} from "../context/PurchasePropertyContext";
import SelectPropDetails from "../dialogs/add-prop/SelectPropDetails";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { MD_FONT } from "../services/fonts";
import { LoadingButtons } from "./layout/Layout";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../context/DirectionContext";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";
import { UserDetailsContext } from "../context/UserDetailsContext";
import PurchasePlan from "../dialogs/pricing/PurchasePlan";
import { Login } from "common-components/src/global/components/login/Login";
import { PropertyIdContext } from "../context/PropertyIdContext";

// options to pass to places autocomplete.
const searchOptions = (isRtl) => {
  return {
    componentRestrictions: { country: ["il"] }, //restricts search to Israel only isRtl ? ["il"] : ["us"]
    types: ["address"], // search only for addresses
  };
};

export function checkSearchAddress(
  placeId,
  setLoading,
  setAddressInput,
  setPaymentProcess,
  setErrorAddress,
  setProperty,
  isRtl
) {
  if (placeId) {
    setLoading(true);
    geocodeByPlaceId(placeId)
      .then((results) => {
        // if (isRtl) {

        const res = {
          address: results[0].formatted_address,
          country: undefined,
          locality: undefined,
          route: undefined,
          street_number: undefined,
          place_id: results[0].place_id,
        };
        results[0].address_components.forEach((item) => {
          if (item.types[0] === "street_number")
            res.street_number = Number(item.long_name);
          else if (item.types[0] === "route") res.route = item.long_name;
          else if (item.types[0] === "locality") res.locality = item.long_name;
          else if (item.types[0] === "country") res.country = item.long_name;
        });
        if (res.country === undefined) res.country = "ישראל";
        if (res.street_number && res.route && res.locality && res.country) {
          setAddressInput(res);
          setProperty((prevState) => ({
            ...prevState,
            house_num: res.street_number.toString(),
            street: res.route,
            area: res.locality,
            place_id: res.place_id,
          }));
          return getLatLng(results[0]);
        } else {
          return false;
        }
      })
      .then((latLng) => {
        if (latLng) {
          setPaymentProcess(true);
          setLoading(false);
          setProperty((prevState) => ({
            ...prevState,
            lat: latLng.lat,
            long: latLng.lng,
          }));
        } else {
          if (setErrorAddress) setErrorAddress(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (setErrorAddress) setErrorAddress(true);
        setLoading(false);
      });
  }
}

export const addressSearch = {
  address: "",
  country: undefined,
  locality: undefined,
  route: undefined,
  street_number: undefined,
};
const SearchPlaces = ({ margin, maxWidth, width, place, btnColor }) => {
  const { openDialog } = useDialogManager();
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [addressInput, setAddressInput] = useState(addressSearch);
  const [errorAddress, setErrorAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useContext(PurchasePropertyContext);
  const [paymentProcess, setPaymentProcess] = useContext(PaymentProcessContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [direction] = useContext(DirectionContext);
  const { t } = useTranslation();
  const [authorization] = useContext(AuthorizationContext);
  const [userDetails] = useContext(UserDetailsContext);
  const queryParams = new URLSearchParams(window.location.search);
  const address = queryParams.get("address");
  const googlePlaceId = queryParams.get("place_id");
  const [isProperty] = useContext(PropertyIdContext);

  useEffect(() => {
    if (address && googlePlaceId && authorization) {
      if (userDetails?.balance > 0) {
        handleSelect(address, googlePlaceId);
      } else {
        openDialog(PurchasePlan, {
          DialogProps: {
            maxWidth: "md",
          },
          DialogContentProps: { popup: true },
        });
      }

      if (!authorization) {
        openDialog(PurchasePlan, {
          DialogProps: {
            maxWidth: "md",
          },
          DialogContentProps: { popup: true },
        });
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (property.lat && property.long) {
      onSubmit();
    }
  }, [property]);

  useNotistack(`${t("insertAddress")}`, errorAddress, setErrorAddress, "error");

  const handleSelect = (address, placeId) => {
    setAddressInput((prevState) => ({ ...prevState, address: address }));
    checkSearchAddress(
      placeId,
      setLoading,
      setAddressInput,
      setPaymentProcess,
      false,
      setProperty,
      direction.isRtl
    );
  };
  const onSubmit = () => {
    if (property.lat && property.long) {
      openDialog(SelectPropDetails, { DialogProps: { maxWidth: "md" } });
      setAddressInput(addressSearch);
    } else {
      setErrorAddress(true);
    }
  };

  useEffect(() => {
    if (isOpenAccess.access) {
      setPaymentProcess(false);
      setAddressInput(addressSearch);
    }
  }, [isOpenAccess]);
  const props = { isDarkTheme: isDarkTheme, place: place };
  const classes = useStylesTopBar(props);

  return (
    <div
      className={classes.divFlexCenter}
      style={{
        marginRight: margin,
        width: width,
        maxWidth: maxWidth ? maxWidth : "",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={8} md={10}>
          <SearchAutocomplete
            addressInput={addressInput}
            handleChange={handleSelect}
            handleSelect={handleSelect}
            place={place}
            setAddressInput={setAddressInput}
            setErrorAddress={setErrorAddress}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <Button
            variant={"contained"}
            color={btnColor ? btnColor : "secondary"}
            onClick={onSubmit}
            style={{ height: "100%", width: "100%" }}
          >
            {loading ? (
              <LoadingButtons style={{ padding: "3px 0" }} color={"inherit"} />
            ) : (
              `${t("SearchBar.buttonGo")}`
            )}
          </Button>
        </Grid>
      </Grid>
      <DialogOpenerAddProp paymentProcess={paymentProcess} />
    </div>
  );
};
export function SearchAutocomplete({
  addressInput,
  handleChange,
  handleSelect,
  setAddressInput,
  setErrorAddress,
  place,
}) {
  const [, setProperty] = useContext(PurchasePropertyContext);
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme: isDarkTheme, bottom: place };
  const classes = useStylesSearchPlaces(props);
  const { t } = useTranslation();

  return (
    <PlacesAutocomplete
      value={addressInput.address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions(direction.isRtl)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
          <TextField
            className={classes.customTextFiled}
            color="secondary"
            fullWidth
            value={addressInput}
            variant="outlined"
            size="small"
            {...getInputProps({
              placeholder: `${t("SearchBar.placeHolderAddress")}`,
            })}
            InputProps={{
              inputProps: {
                inputMode: "search",
              },
              endAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    icon={faTimes}
                    size={"1x"}
                    color={isDarkTheme ? darkText : greyAxis}
                    style={{ cursor: "pointer", width: MD_FONT }}
                    onClick={() => {
                      setAddressInput(addressSearch);
                      setErrorAddress(false);
                      setProperty(propertyIL);
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <ul className={classes.ulDropdownSearch}>
            {suggestions.map((suggestion) => {
              return (
                <li
                  className={classes.liDropdownSearch}
                  {...getSuggestionItemProps(suggestion)}
                  key={suggestion.placeId}
                >
                  {suggestion.description}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
export default SearchPlaces;
