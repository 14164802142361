import { Grid, Typography } from "@material-ui/core";
import { PropdoButton } from "../../buttons/PropdoButton";
import { useTranslation } from "react-i18next";
import {
  FONT_TITLE_LG,
  FONT_TITLE_XL,
} from "../../../../comps/services/constants/fonts";
import { blue_1, borderRadius, grey_1 } from "../../../services/colors";
import { Terms } from "../Terms";
import { PurchaseForm } from "./PurchaseForm";
import { usePayment } from "../../../context/PaymentContext";
import React, { useEffect, useState } from "react";
import { SuccessfulPurchase } from "./SuccessfulPurchase";
import { PurchaseResult } from "./PurchaseResult";
import { IMAGES_LINK } from "../../../api/api_links";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useDirection } from "../../../context/DirectionContext";
import { useDialogManager } from "../../../hooks/useDialogManager";
import { PricingPlan } from "../pricingPlans/PricingPlan";
import { apiFetch } from "../../../api/apiFetch";
import { TERMINAL_NAME } from "../../../services/constants/env";
import { SinglePlanItem } from "../pricingPlans/SinglePlanItem";
import { useIsBaseRoute } from "../../../hooks/useIsBaseRoute";
import CurrencyFormat from "react-currency-format";
import { IPlan } from "../../../interfaces/subscription";
import { LoadingGif } from "../../loading/LoadingGif";

export const Purchase: React.FC<{
  src?: string;
  projectName: string;
  currentPlan?: IPlan;
}> = ({ src, projectName, currentPlan }) => {
  const { t } = useTranslation();
  const { currPlan } = usePayment();
  const { direction } = useDirection();
  const { openDialog, closeDialog } = useDialogManager();
  const isComps = useIsBaseRoute("comps");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const project = projectName ? projectName.split("-")[0] : "";
  const plan = isComps ? "subscription" : "package";

  const [localInputs, setLocalInputs] = useState<{
    fullName: string;
    numberId: string;
  }>({
    fullName: "",
    numberId: "",
  });

  const handleBack = () => {
    openDialog(PricingPlan, {
      DialogProps: { maxWidth: "sm" },
      DialogContentProps: { projectName },
    });
  };

  const handleExit = () => {
    closeDialog();
  };

  const handleLocalInputs = (value: string, key: string) => {
    setLocalInputs({ ...localInputs, [key]: value });
  };

  // #### TRANZILA ####
  const [fields, setFields] = useState<any>(null);
  const [state, setState] = useState({
    imgSrc: "cardExample",
    msgs: [],
    success: false,
    resMessage: "",
  });
  const { resMessage, imgSrc, success, msgs } = state;

  useEffect(() => {
    if (!fields) {
      tzlHostedFields();
    }
  }, []);

  // useEffect(() => {
  //   if (success) {
  //     window.location.reload();
  //   }
  // }, [success]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    chargeCcData(event);
  };

  const chargeCcData = (e: any) => {
    // TODO: change it to be dynmic
    setIsLoading(true);
    fields.charge(
      {
        terminal_name: TERMINAL_NAME,
        amount: "1.00",
        currency_code: direction.isRtl ? "ILS" : "US",
        response_language: "hebrew",
        tokenize: true,
        tran_mode: "V",
      },
      (error: any, response: any) => {
        if (response) {
          parseResponse(response);
          const {
            token,
            expiry_month,
            expiry_year,
          } = response.transaction_response;

          apiFetch(
            `/${project}/purchase/${plan}?country=${direction?.country}&region=${direction?.region}`,
            {
              method: "POST",
              body: JSON.stringify({
                type: currPlan ? currPlan.name : currentPlan?.name,
                token,
                month: expiry_month,
                year: expiry_year,
                name: localInputs.fullName,
                id: localInputs.numberId,
              }),
            }
          ).then((data) => {
            if (
              data.message === "Package Purchased" ||
              data.message === "Subscription Purchased"
            ) {
              setIsLoading(false);
              // @ts-ignore
              setState({ success: true });
            } else {
              setIsLoading(false);

              // @ts-ignore
              setState({ success: false });
            }
          });
        }
        if (error) {
          setIsLoading(false);
          if (error.messages !== null) {
            handleError(error);
          }
        }
      }
    );
  };

  const handleError = (err: any) => {
    // @ts-ignore
    const tmpArray = [];
    err.messages.forEach((message: any) => {
      tmpArray.push(message.param);
    });
    // @ts-ignore
    setState({ msgs: tmpArray, success: false });
  };

  const parseResponse = (response: any) => {
    if (response.errors != null) {
      // @ts-ignore
      let tmpArray = [];
      response.errors.forEach((message: any) => {
        tmpArray.push(message.message);
      });
      // @ts-ignore
      const str = tmpArray.join();
      // @ts-ignore
      setState({ resMessage: str, success: false });
    } else {
      if (response.transaction_response.success === false) {
        // @ts-ignore
        setState({
          resMessage: response.transaction_response.error,
          // success: true,
        });
      } else {
        // @ts-ignore
        // setState({ success: true });
      }
    }
  };

  const onCardNumberChanged = (result: any) => {
    if (result && result.cardType !== "unknown") {
      // @ts-ignore
      setState({ imgSrc: result.cardType });
    }
  };

  const validityChange = (result: any) => {
    const tmpArray = state.msgs.slice();
    if (result && result.field) {
      tmpArray.splice(result.field.index, 1);
    }
    // @ts-ignore
    setState({ msgs: tmpArray });
  };

  const tzlHostedFields = () => {
    const tzlFields = window.TzlaHostedFields.create({
      sandbox: false,

      fields: {
        credit_card_number: {
          selector: "#credit_card_num",
          placeholder: "xxxx xxxx xxxx xxxx",
          tabindex: 1,
        },
        cvv: {
          selector: "#cvv",
          placeholder: t("pricing.cvv"),
          tabindex: 4,
        },
        expiry: {
          selector: "#expiry",
          placeholder: "MM/YYYY",
          tabindex: 3,
          version: "1",
        },
      },
      styles: {
        input: {
          height: "auto",
          width: "100%",
          padding: "16px 13px",
          "border-radius": borderRadius + "px",
          border: `1px solid ${grey_1}`,
          "margin-top": "15px",
        },
        select: {
          height: "auto",
          width: "auto",
        },
      },
    });
    // tzlFields.onEvent("cardTypeChange", onCardNumberChanged);
    // tzlFields.onEvent("blur", validityChange);

    setFields(tzlFields);
  };

  // #### TRANZILA ####

  return (
    <Grid style={{ padding: "48px" }}>
      {success ? (
        <PurchaseResult msg={resMessage} success={success} />
      ) : (
        <>
          <Grid item xs={12} style={{ marginBottom: 15 }}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
                position: "absolute",
                top: 15,
                right: direction.isRtl && 45,
              }}
              onClick={isComps ? handleBack : handleExit}
            >
              {isComps ? (
                direction.isRtl ? (
                  <ArrowForwardIcon />
                ) : (
                  <ArrowBackIcon />
                )
              ) : (
                <img src={IMAGES_LINK + "/homepage/exit.svg"} alt={"exit"} />
              )}
            </Grid>
            <Typography
              style={{
                fontSize: FONT_TITLE_XL,
                color: blue_1,
                fontWeight: 600,
                textAlign: direction.isRtl && "right",
              }}
            >
              {t("pricing.orderSummary")}
            </Typography>
          </Grid>
          {isComps ? (
            <SinglePlanItem plan={currPlan} />
          ) : (
            <Grid container item xs={12}>
              <Typography style={{ marginLeft: 5 }}>
                {t("pricing.amount")}
              </Typography>
              <CurrencyFormat
                value={currentPlan?.price}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" ₪"}
              />
            </Grid>
          )}
          {isComps && <Terms />}
          <PurchaseForm
            handleSubmit={handleSubmit}
            msg={msgs}
            handleLocalInputs={handleLocalInputs}
          />
          {isLoading && <LoadingGif />}
        </>
      )}
    </Grid>
  );
};
