import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { mainPropColor } from "../global-il/services/colors";
import { LG_PLUS_FONT } from "../global-il/services/fonts";
import { PropdoButton } from "./PropdoButton";
import { useTranslation } from "react-i18next";
import { useIsMobileScreen } from "../global-il/hooks/useIsMobileScreen.ts";

const useStyles = makeStyles({
  root: {
    backgroundColor: mainPropColor,
    opacity: 0.99,
    height: "70vh",
  },
  img: {
    position: "absolute",
    top: 0,
    height: "70%",
    width: "100%",
    objectFit: "cover",
    opacity: 0.3,
    zIndex: -2,
  },
  title: (props) => ({
    fontSize: props.isMobileScreen ? LG_PLUS_FONT : "2.5rem",
    fontWeight: 600,
  }),
});

export const Hero = ({ src, btn }) => {
  const isMobileScreen = useIsMobileScreen();
  const props = { isMobileScreen };
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent="center"
      className={classes.root}
    >
      <img src={src} className={classes.img} />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        item
        xs={6}
        style={{ color: "#fff", textAlign: "center", maxWidth: 780 }}
        spacing={10}
      >
        <Grid item xs={12}>
          <Typography className={classes.title}>{t("hero.title")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <a href="#search-property">
            <PropdoButton
              text={t("buttons.claimReport")}
              style={{ width: 200 }}
            />
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};
