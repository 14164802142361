import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CustomTooltip, useCustomTooltip } from "../CustomTooltip";
import { Line } from "react-chartjs-2";
import { addingComma } from "../../services/helpers";
import {
  darkBackground,
  darkMortgageColors,
  darkText,
  darkThemeGridLines,
  darkThemeSliderRail,
  greyAxis,
  mortgageColors,
} from "../../services/colors";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { ANIMATION_DURATION } from "../../services/consts";
import { FONT } from "../../services/fonts";
import Typography from "@material-ui/core/Typography";
import ClickableLegend from "../../components/dashboard-components/comparative/ClickableLegend";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";
import { datasetKeyProvider } from "../../components/dashboard-components/tama/LineChart";

export function yAxisCallbackMillionsIL(value) {
  let unit = "מ'";
  if (value <= 1) return value;
  else return `${Number(value) / 1000000} ${unit}`;
}
export function yAxisCallbackMillionsUS(value) {
  let unit = " M ";
  if (value <= 1) return value;
  else return `${Number(value) / 1000000}${unit}`;
}
function toggleDataFunction(data, show) {
  return {
    labels: data.labels,
    datasets: data.datasets.filter((item, index) => {
      return item.label === show[index].label && show[index].show;
    }),
  };
}

export const disableScrolling = () => {
  var x = window.scrollX;
  var y = window.scrollY;
  window.onscroll = function () {
    window.scrollTo(x, y);
  };
};

export const enableScrolling = () => {
  window.onscroll = function () {};
};

const MortgageLineChart = ({ lineChartData }) => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const chartRef = useRef();
  const [index, setCurrentIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );

  const { t } = useTranslation();

  const [toggleData, setToggleData] = useState(lineChartData);
  const [show, setShow] = useState(
    lineChartData.datasets.map((item, index) => {
      return {
        index: index,
        label: item.label,
        show: true,
      };
    })
  );
  const [clickableLegend, setClickableLegend] = useState(
    lineChartData.datasets.map((item) => {
      return {
        color: item.borderColor,
        label: item.label,
      };
    })
  );

  const toggleShow = (label) => {
    const newArr = [...show];
    show.forEach((item, index) => {
      if (item.label === label) {
        newArr[index].show = !newArr[index].show;
      }
    });
    setShow(newArr);
  };
  useEffect(() => {
    setClickableLegend(
      lineChartData.datasets.map((item) => {
        return {
          color: item.borderColor,
          label:
            item.label === t("Mortgage.LineGraph.balance")
              ? t("Mortgage.LineGraph.balance")
              : item.label,
        };
      })
    );

    setShow(
      lineChartData.datasets.map((item, index) => {
        return {
          index: index,
          label: item.label,
          show: true,
        };
      })
    );
  }, [lineChartData]);

  useEffect(() => {
    setToggleData(toggleDataFunction(lineChartData, show));
  }, [show]);

  const chartJsOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
        },
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
      },
      animation: {
        duration: ANIMATION_DURATION,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
        rtl: true,
        custom: customTooltip,
      },

      scales: {
        yAxes: [
          {
            id: "dataBalance",
            type: "linear",
            position: "left",
            ticks: {
              display: !(
                (toggleData.datasets.length === 2 &&
                  toggleData.datasets[0].label !==
                    t("Mortgage.LineGraph.balance") &&
                  toggleData.datasets[1].label !==
                    t("Mortgage.LineGraph.balance")) ||
                (toggleData.datasets.length === 1 &&
                  toggleData.datasets[0].label !==
                    t("Mortgage.LineGraph.balance"))
              ),
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: FONT,
              fontFamily: "Open Sans Hebrew, sans-serif",
              callback: direction.isRtl
                ? yAxisCallbackMillionsIL
                : yAxisCallbackMillionsUS,
            },
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
          },
          {
            id: "mixedData",
            stacked: true,
            type: "linear",
            position: "right",
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              display: !(
                toggleData.datasets.length === 1 &&
                toggleData.datasets[0].label === t("Mortgage.LineGraph.balance")
              ),
              beginAtZero: false,
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: FONT,
              fontFamily: "Open Sans, sans-serif",
              callback: direction.isRtl
                ? yAxisCallbackMillionsIL
                : yAxisCallbackMillionsUS,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              display: true,
              fontSize: FONT,
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontFamily: "Open Sans, sans-serif",
            },
          },
        ],
      },
    };
  }, [toggleData, isDarkTheme, direction]);

  return (
    <>
      <div>
        <ClickableLegend
          show={show}
          toggleShow={toggleShow}
          legend={clickableLegend}
        />
      </div>
      <div
        style={{
          minHeight: 300,
        }}
        onTouchStart={() => {
          disableScrolling();
        }}
        onTouchEnd={() => {
          enableScrolling();
        }}
      >
        <Line
          ref={chartRef}
          data={toggleData}
          datasetKeyProvider={datasetKeyProvider}
          options={chartJsOptions}
          id="myGraph"
        />
      </div>
      <CustomTooltip
        chartRef={chartRef}
        tooltipRef={tooltipRef}
        left={position.left}
        top={position.top}
        id="tooltip-mortgage-lines"
        place="top"
      >
        <TooltipInner
          data={lineChartData}
          currentIndex={index}
          isDarkTheme={isDarkTheme}
        />
      </CustomTooltip>
    </>
  );
};
function TooltipInner({ data, currentIndex, isDarkTheme }) {
  const { t } = useTranslation();
  if (data && currentIndex >= 0) {
    return (
      <div style={{ borderRadius: "var(--border-radius)" }}>
        <div
          style={{
            backgroundColor: isDarkTheme ? darkThemeSliderRail : "#f1f4fd",
            maxWidth: 200,
            borderRadius: "var(--br-top)",
            padding: 8,
          }}
        >
          <Typography
            style={{
              color: isDarkTheme ? darkText : "black",
              textAlign: "start",
            }}
          >
            {t("Mortgage.LineGraph.year") + data.labels[currentIndex]}
          </Typography>
        </div>
        <div
          style={{
            backgroundColor: isDarkTheme ? darkBackground : "white",
            borderRadius: "var(--br-bottom)",
            padding: 8,
          }}
        >
          {data.datasets.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                <Typography
                  style={{
                    color: isDarkTheme
                      ? darkMortgageColors[index]
                      : mortgageColors[index],
                    opacity: 1,
                  }}
                >
                  {item.label === t("Mortgage.LineGraph.fundPayment") &&
                    t("Mortgage.LineGraph.fundPayment")}
                  {item.label === t("Mortgage.LineGraph.interestPayment") &&
                    t("Mortgage.LineGraph.interestPayment")}
                  {item.label === t("Mortgage.LineGraph.balance") &&
                    t("Mortgage.LineGraph.balance")}
                </Typography>

                <Typography
                  style={{ color: isDarkTheme ? darkText : greyAxis }}
                >
                  {currentIndex === 0 &&
                  item.label !== t("Mortgage.LineGraph.balance") ? (
                    <>0</>
                  ) : (
                    <>{`${addingComma(item.data[currentIndex])} ${t(
                      "currency"
                    )} `}</>
                  )}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else return null;
}
export default MortgageLineChart;
