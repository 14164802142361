import { SnackbarProvider } from "notistack";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import { negativeRedColor, positiveGreenColor } from "../services/colors";
import { createRef } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export const CloseSnackButton = ({ snackKey, notistackRef }) => {
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <HighlightOffIcon
      onClick={onClickDismiss(snackKey)}
      style={{ color: "white", cursor: "pointer" }}
    />
  );
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    "& div": {
      justifyContent: "space-between",
      "& .SnackbarItem-action": {
        marginRight: "5px !important",
      },
    },
  },
  variantSuccess: {
    backgroundColor: positiveGreenColor,
  },
  variantError: {
    backgroundColor: negativeRedColor,
  },
  // its also accessible next variants: contentRoot, variantInfo, variantWarning
}));

const NotistackProvider = ({ children }) => {
  const classes = useStyles();
  const notistackRef = createRef();
  return (
    <SnackbarProvider
      classes={classes}
      ref={notistackRef}
      maxSnack={1}
      TransitionComponent={Fade}
      // action={(key) => (
      //   <CloseSnackButton notistackRef={notistackRef} snackKey={key} />
      // )}
    >
      {children}
    </SnackbarProvider>
  );
};
export default NotistackProvider;
