import Button from "@material-ui/core/Button";
import { yellow } from "../../../services/colors";
import React, { useContext, useEffect, useState } from "react";
import { PurchasePropertyContext } from "../../../context/PurchasePropertyContext";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import SelectPropDetails from "../../../dialogs/add-prop/SelectPropDetails";
import { XS_PLUS_FONT } from "../../../services/fonts";
import { useTranslation } from "react-i18next";

const RepurchaseProp = ({ data }) => {
  const [, setProperty] = useContext(PurchasePropertyContext);
  const { openDialog } = useDialogManager();
  const [gush, setGush] = useState("");
  const [halka, setHalka] = useState("");
  const [area, setArea] = useState("");
  const [house_num, setHouseNum] = useState("");
  const [street, setStreet] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.address.includes(`${t("gush")}`)) {
      const gush_halka = data.address.split(" ");
      setGush(gush_halka[1]);
      setHalka(gush_halka[3]);
    } else if (data) {
      const address = data.address.split(", ");
      setArea(address[1]);
      let street = "";
      address[0].split(" ").forEach((str, idx) => {
        if (idx === address[0].split(" ").length - 1 && /^\d+$/.test(str)) {
          setHouseNum(str);
        } else setStreet((street += str + " "));
      });
    } else return;
  }, [data]);

  const handleClick = () => {
    setProperty({
      lat: data.coords[0],
      long: data.coords[1],
      house_num,
      street,
      area,
      gush,
      halka,
      type: data.property_details[0],
      condition: data.property_details[1],
      sqm: data.property_details[2],
      year_built: data.property_details[3],
      floor: data.property_details[4],
      num_floors: data.property_details[5],
      num_rooms: data.property_details[6],
      elevator: data.property_details[7] || 0,
      parking: data.property_details[8],
      storage: data.property_details[9] || 0,
      bomb_shelter: data.property_details[10] || 0,
      sqm_balcony: data.property_details[11],
      sqm_balcony_roof: data.property_details[12],
      sqm_garden: data.property_details[13],
      building_rights_sqm: data.property_details[14],
      building_rights_level: data.property_details[15],
      building_rights_approval: data.property_details[16] || 0,
      repurchase_id: data.id.toString(),
    });
    openDialog(SelectPropDetails, { DialogProps: { maxWidth: "md" } });
  };
  return (
    <Button
      style={{
        fontSize: XS_PLUS_FONT,
        backgroundColor: yellow,
        color: "white",
      }}
      onClick={handleClick}
      variant={"contained"}
    >
      {t("Reports.buy")}
    </Button>
  );
};
export default RepurchaseProp;
