import { createContext, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { setCookies } from "common-components/src/global/services/helpers/general";

const DirectionContext = createContext();

export const DirectionProvider = ({ children, config }) => {
  const { t } = useTranslation();

  const [direction, setDirection] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    setDirection({ ...config });
    setCookies(
      "propdo_locale",
      `${config.country}${
        config.region ? `_${config.region}` : config.region
      }/${config.lang}`
    );
  }, []);

  useEffect(() => {
    if (!direction) return;
    i18n.changeLanguage(config.lang);
    document.body.style.direction = direction.isRtl ? "rtl" : "ltr";
  }, [direction]);

  return (
    <DirectionContext.Provider value={{ direction, setDirection }}>
      {children}
    </DirectionContext.Provider>
  );
};

export const useDirection = () => useContext(DirectionContext);
