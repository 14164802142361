import React, { useContext } from "react";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import {
  darkThemeNegativeRed,
  darkThemePositiveGreen,
  negativeRedColor,
  positiveGreenColor,
} from "../../services/colors";
import { LightTooltip } from "../../outside-components/reports/TableReportsBody";
import { useStylesImpactPoints } from "../../styledComponent/ImpactPointsStyled";
import { useTranslation } from "react-i18next";

const RenderColorRectangle: React.FC<{
  index: number;
  isDarkTheme: boolean;
}> = ({ index, isDarkTheme }) => {
  const classes = useStylesImpactPoints();
  const color =
    index > 0 && isDarkTheme
      ? darkThemePositiveGreen
      : index > 0 && !isDarkTheme
      ? positiveGreenColor
      : index < 0 && isDarkTheme
      ? darkThemeNegativeRed
      : negativeRedColor;

  return (
    <div
      className={classes.grayRectangle}
      style={{
        backgroundColor: color,
      }}
    />
  );
};
const GrayRectangle = () => {
  const classes = useStylesImpactPoints();
  return <div className={classes.grayRectangle} />;
};
const Influence: React.FC<{ index: number }> = ({ index }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStylesImpactPoints();
  const { t } = useTranslation();
  const idAndText =
    index === 0
      ? `${t("PosConChart.insignificant")}`
      : index > 0 && index <= 5
      ? `${t("PosConChart.slightlyPositive")}`
      : index > 5 && index <= 7
      ? `${t("PosConChart.extremelyPositive")}`
      : index < 0 && index >= -5
      ? `${t("PosConChart.slightlyNegative")}`
      : `${t("PosConChart.extremelyNegative")}`;

  return (
    <LightTooltip
      isDarkTheme={isDarkTheme}
      title={idAndText}
      arrow
      enterTouchDelay={50}
    >
      <div className={classes.containerRectangle}>
        {index === 0 && (
          <div className={classes.rectangle}>
            <GrayRectangle />
            <GrayRectangle />
            <GrayRectangle />
          </div>
        )}

        {((index > 0 && index <= 2) || (index < 0 && index >= -2)) && (
          <div className={classes.rectangle}>
            <RenderColorRectangle index={index} isDarkTheme={isDarkTheme} />
            <GrayRectangle />
            <GrayRectangle />
          </div>
        )}
        {((index > 2 && index <= 5) || (index < -2 && index >= -5)) && (
          <div className={classes.rectangle}>
            <RenderColorRectangle index={index} isDarkTheme={isDarkTheme} />
            <RenderColorRectangle index={index} isDarkTheme={isDarkTheme} />
            <GrayRectangle />
          </div>
        )}
        {((index > 5 && index <= 7) || (index < -5 && index >= -7)) && (
          <div className={classes.rectangle}>
            <RenderColorRectangle index={index} isDarkTheme={isDarkTheme} />
            <RenderColorRectangle index={index} isDarkTheme={isDarkTheme} />
            <RenderColorRectangle index={index} isDarkTheme={isDarkTheme} />
          </div>
        )}
      </div>
    </LightTooltip>
  );
};
export default Influence;
