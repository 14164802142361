import React, { useContext } from "react";
import { useStylesTopBar } from "../../styledComponent/TopBarStyled";
import { onLogout } from "./hamburger/HamburgerMenu";
import { useTranslation } from "react-i18next";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";
import { AuthSignOut } from "common-components/src/global/services/helpers/auth";

const Logout: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStylesTopBar();
  const [, setAuthorization] = useContext(AuthorizationContext);

  const handleLogout = async () => {
    AuthSignOut();
    setAuthorization(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <button className={classes.buttonAvatarDropdown} onClick={handleLogout}>
      {t("Tabs.logout")}
    </button>
  );
};
export default Logout;
