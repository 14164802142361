import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function useNameLabels(xAxis, month) {
  const [labels, setLabels] = useState([...xAxis]);
  const { t } = useTranslation();

  useEffect(() => {
    const labelNames = labels.map((label, index) => {
      if (index % 2 === 0) {
        const currMonthName = `${t(`months.${month}`)}`;

        return `${currMonthName} ${Math.floor(label)}`;
      } else {
        const currMonthName = `${t(`months.${((month + 6) % 12) + 1}`)}`;
        return `${currMonthName} ${Math.floor(label)}`;
      }
    });
    setLabels(labelNames);
  }, []);

  return labels;
}
