import {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { doFetch, UseFetchOptions } from "../api/doFetch";

/**
 * Internal.
 * Type of dispatch action for reducer.
 */
enum DataFetcherAction {
  FETCH_INIT,
  FETCH_SUCCESS,
  FETCH_FAILURE,
}

/**
 * Internal.
 * Dispatch action fields.
 */
interface DataFetcherActionType<T> {
  type: DataFetcherAction;
  payload?: T;
  error?: any;
}

/**
 * Internal.
 * Reducer implementation.
 * Handles dispatched actions.
 * @param state current state
 * @param action dispatched action
 */
function dataFetchReducer<T>(
  state: UseDataState<T>,
  action: DataFetcherActionType<T>
) {
  switch (action.type) {
    case DataFetcherAction.FETCH_INIT:
      return { ...state, isLoading: true, error: undefined };
    case DataFetcherAction.FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        data: action.payload,
      };
    }
    case DataFetcherAction.FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default:
      throw new Error();
  }
}

export interface UseDataState<T> {
  isLoading: boolean;
  error?: any;
  data?: T;
}

export type UseDataReturnType<T> = [
  state: UseDataState<T>,
  reFetch: () => void
];

/**
 * Hook to work against API.
 *
 * Heavily inspired by: https://www.robinwieruch.de/react-hooks-fetch-data
 *
 * Returns Tuple of *UseDataState* and *reFetch* callback.
 *
 * UseDataState contains *{isLoading, error, data}*  fields.
 *
 * *reFetch* callback can be used to re-fetch data for the same request.
 *
 * @example  Use of the hook
 * const [reFetchCount, setReFetchCount] = useState(0);
 * const [{isLoading, data, error}, reFetch] = useDataApi("/il/dashboard/get-property");
 * if(!data && isLoading) {
 *     return <Loading/>
 * }
 * const onClick = () => {
 * 	reFetch();
 * 	setReFetchCount(prev => prev + 1)
 * }
 *
 * return (<div>
 *     <p>{JSON.stringify(data)}</p>
 *     {error && <p>{error}</p>}
 *     <p>Re-Fetch count : {reFetchCount}</p>
 *     <button onClick={onClick}>Re-fetch</button>
 * </div>)
 *
 * @param uri request uri
 * @param initialData - *optional*  initial state of data
 */
export function useDataApi<T>(
  uri: string,
  initialData?: T
): UseDataReturnType<T> {
  const [reFetchTrigger, triggerSetter] = useState(false);

  /**
   * Idea is trigger some state change to trigger re-fetch
   * so we have state with boolean flat that will change it's value
   * each time reFetch is called
   */
  const reFetch = useCallback(() => {
    triggerSetter((prev) => !prev);
  }, [triggerSetter]);

  const [state, dispatch] = useReducer<
    Reducer<UseDataState<T>, DataFetcherActionType<T>>
  >(dataFetchReducer, {
    isLoading: false,
    error: undefined,
    data: initialData as T | undefined,
  });

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      dispatch({ type: DataFetcherAction.FETCH_INIT });
      try {
        const result = await doFetch(uri, {
          method: "GET",
        });
        if (result.message) {
          throw result.message;
        }
        if (!didCancel) {
          dispatch({ type: DataFetcherAction.FETCH_SUCCESS, payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: DataFetcherAction.FETCH_FAILURE, error });
        }
      }
    };
    fetchData();
    return () => {
      didCancel = true; //set cancel flag to false.
    };
  }, [uri, reFetchTrigger]); //reFetchTrigger required in deps! do not remove!

  return [state, reFetch];
}

export type UseLazyDataReturnType<T> = [
  state: UseDataState<T>,
  fetch: (uri: string) => void
];

/**
 * Hook to work against API in a lazy way.
 *
 * When called no actual fetch is popups-icons. You are getting lazyFetch() callback in a result, to control fetch trigger.
 *
 * @example   Use of the Hook
 * const [{isLoading, error, data}, lazyFetch] = useLazyDataApi()
 * const onClick = () => {
 *     lazyFetch("/il/dashboard/get-property")
 * }
 * return (<div>
 *     <button onClick={onClick}> Fetch data </button>
 *     {!data && loading && <Loading/>}
 *     {data && JSON.stringify(data)}
 * </div>)
 *
 * @param initialData - *optional*  initial state of data
 */
export function useLazyDataApi<T>(initialData?: T) {
  const [state, dispatch] = useReducer<
    Reducer<UseDataState<T>, DataFetcherActionType<T>>
  >(dataFetchReducer, {
    isLoading: false,
    error: undefined,
    data: initialData as T | undefined,
  });

  const cancelRef = useRef(false);
  useEffect(() => {
    return () => {
      cancelRef.current = true;
    };
  }, []);

  let lazyFetch = useMemo(() => {
    return async (uri: string, options: UseFetchOptions = {}) => {
      dispatch({ type: DataFetcherAction.FETCH_INIT });
      try {
        const result = await doFetch(uri, {
          method: "GET",
          ...options,
        });
        if (result.message) {
          throw result.message;
        }
        if (!cancelRef.current) {
          dispatch({ type: DataFetcherAction.FETCH_SUCCESS, payload: result });
        }
      } catch (error) {
        if (!cancelRef.current) {
          dispatch({ type: DataFetcherAction.FETCH_FAILURE, error });
        }
      }
    };
  }, []);

  return [(state as unknown) as T, lazyFetch];
}
