import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.tsx";
import * as serviceWorker from "./serviceWorker";
import { Accessibility } from "accessibility/src/main";
import "./config18/i18n";
import { AuthConfigure } from "common-components/src/global/services/helpers/auth";

AuthConfigure("app");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
  window.addEventListener(
    "load",
    function () {
      var labels = {
        resetTitle: "איפוס",
        closeTitle: "סגירה",
        menuTitle: "תפריט נגישות",
        increaseText: "הגדלת טקסט",
        decreaseText: "הקטנת טקסט",
        increaseTextSpacing: "הגדל את ריווח הטקסט",
        decreaseTextSpacing: "להקטין את ריווח הטקסט",
        invertColors: "ניגודיות הפוכה",
        grayHues: "גווני אפור",
        underlineLinks: "הדגשת קישורים",
        bigCursor: "סמן גדול",
        readingGuide: "מדריך קריאה",
        textToSpeech: "טקסט לדיבור",
      };
      var options = {
        icon: {
          img: ["accessible"],
          position: {
            top: { size: 200, units: "px" },
            left: { size: 0, units: "px" },
            type: "fixed",
          },
          dimensions: {
            width: { size: 30, units: "px" },
            height: { size: 30, units: "px" },
          },
        },
        labels: labels,
      };
      options.textToSpeechLang = "he-IL";
      options.speechToTextLang = "he-IL";
      options.modules = {
        increaseText: true,
        decreaseText: true,
        invertColors: true,
        increaseTextSpacing: false,
        decreaseTextSpacing: false,
        grayHues: true,
        underlineLinks: false,
        bigCursor: true,
        readingGuide: true,
        textToSpeech: false,
        speechToText: false,
      };
      new Accessibility(options);
    },
    false
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
