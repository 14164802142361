import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { doFetch } from "common-components";
import { useIsIncludes } from "common-components";
import { DirectionContext } from "./DirectionContext";
const PropertyContext = createContext();

export const PropertyProvider = ({ children }) => {
  const [direction] = useContext(DirectionContext);
  const [loading, setLoading] = useState(true);
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const [properties, setProperties] = useState(null);
  const [queryProperties, setQueryProperties] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [areas, setAreas] = useState(null);
  const [page, setPage] = useState(1);
  const [filterBy, setFilterBy] = useState({
    district: [],
    area: [],
    price: { min: 0, max: 20000000 },
  });
  const [cities, setCities] = useState(null);
  const [districts, setDistricts] = useState(null);
  const [divisions, setDivisions] = useState(null);
  const paramsQuery = new URLSearchParams(location.search);
  const id = paramsQuery.get("id");
  const isListings = useIsIncludes("listings") && !id;

  useEffect(() => {
    if (isListings) {
      const areaParams = params.get("area");
      const districtParams = params.get("district");
      const min = +params.get("minPrice");
      const max = +params.get("maxPrice");

      if (
        typeof areaParams !== "object" ||
        typeof districtParams !== "object" ||
        min !== 0 ||
        max !== 0
      ) {
        const area = areaParams.length === 0 ? [] : areaParams.split(",");
        const district =
          districtParams.length === 0 ? [] : districtParams.split(",");

        const filter = {
          district,
          area,
          price: { min, max },
        };
        setFilterBy(filter);
        loadProperties(filter);
      } else {
        loadProperties(filterBy);
      }
    }
  }, [isListings]);

  const loadProperties = async (filter) => {
    setLoading(true);
    try {
      const {
        properties,
        areas,
        query_properties,
        total_pages,
        districts,
      } = await doFetch(`/reports/search/listings`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          page: page,
          district: filter.district,
          area: filter.area,
          min_price: filter.price.min,
          max_price: filter.price.max,
        }),
      });
      const divisions = districts.map(
        (district) => district.properties.district
      );
      const cities = _getCitiesInDistrict(filter, areas);
      setProperties(properties);
      setAreas(areas);
      setQueryProperties(query_properties);
      setTotalPages(total_pages);
      setCities(cities);
      setDivisions(divisions);
      setDistricts(districts);
      setLoading(false);
    } catch (err) {
      console.log(`err`, err);
    }
  };

  const getRequestedPage = async (pageValue) => {
    setLoading(true);
    try {
      const { page, properties } = await doFetch(
        `/reports/search/listings`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            page: pageValue,
            district: filterBy.district,
            area: filterBy.area,
            min_price: filterBy.price.min,
            max_price: filterBy.price.max,
          }),
        }
      );

      setProperties(properties);
      setPage(page);
      setLoading(false);
    } catch (err) {
      console.log(`err`, err);
    }
  };

  const _getCitiesInDistrict = (filter, areas) => {
    if (filter.district.length === 0) {
      const cities = areas.map((area) => area.properties.area);
      return cities;
    } else {
      const citiesToReturn = [];
      const cities = filter.district.map((district) => {
        const area = areas.filter(
          (area) => area.properties.district === district
        );
        return area;
      });
      cities.forEach((cities) =>
        cities.forEach((city) => citiesToReturn.push(city.properties.area))
      );
      return citiesToReturn;
    }
  };

  const getDistrictByName = ([districtName]) => {
    return districts.find(
      (district) => districtName === district.properties.district
    );
  };

  const getYeshuvByName = ([yeshuvName]) => {
    return areas.find((area) => yeshuvName === area.properties.area);
  };
  return (
    <PropertyContext.Provider
      value={{
        properties,
        queryProperties,
        cities,
        page,
        districts,
        divisions,
        filterBy,
        getYeshuvByName,
        getDistrictByName,
        setFilterBy,
        totalPages,
        getRequestedPage,
        areas,
        loadProperties,
        loading,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

export const useProperty = () => useContext(PropertyContext);
