import React, { useContext } from "react";
import {
  darkThemePositiveGreen,
  positiveGreenColor,
} from "../../services/colors";
import { UserDetailsContext } from "../../context/UserDetailsContext";
import { useStylesSelectPropDetails } from "../../styledComponent/SelectPropDetailsStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { addingComma } from "../../services/helpers";
import { LG_FONT, MD_FONT, XS_FONT } from "../../services/fonts";
import Typography from "@material-ui/core/Typography";
import { IMAGES_LINK } from "common-components";
import { useTranslation } from "react-i18next";

export default function Price({ id, listingProperty }) {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [userDetails] = useContext(UserDetailsContext);
  const { t } = useTranslation();
  const price = !id
    ? 189
    : userDetails && userDetails.is_premium
    ? Number(sessionStorage.getItem("price")) +
      Number(sessionStorage.getItem("price") * 0.17)
    : Number(sessionStorage.getItem("price"));

  const classes = useStylesSelectPropDetails({ isDarkTheme });

  return (
    <div style={{ paddingTop: 20 }}>
      <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Typography className={classes.pTotalPlanType}>
          {userDetails && userDetails.is_premium ? (
            <bdi> {t("totalVat")}: </bdi>
          ) : (
            <bdi>&thinsp;{t("total")}: </bdi>
          )}
          {addingComma(price.toFixed(0))} ₪
        </Typography>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={`${IMAGES_LINK}/dashboard/moneyBack/security.svg`}
          alt="Secured"
          style={{ width: LG_FONT, height: MD_FONT, paddingLeft: 5 }}
        />
        <Typography
          style={{
            fontSize: XS_FONT,
            color: isDarkTheme ? darkThemePositiveGreen : positiveGreenColor,
            fontWeight: 600,
          }}
        >
          {t("moneyBackGuarantee")}
        </Typography>
      </div>
    </div>
  );
}
