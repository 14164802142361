import { makeStyles } from "@material-ui/core/styles";
import {
  darkBackground,
  darkThemeNegativeRed,
  greyAxis,
  negativeRedColor,
} from "../services/colors";
import { MD_PLUS_FONT, SM_FONT, XS_PLUS_FONT } from "../services/fonts";

export const useStylesUserLogin = makeStyles({
  root: {
    backgroundColor: "#f1f4fd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 40,
    height: "100vh",
  },
  paragraphBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingLeft: 20,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  paragraph: {
    color: greyAxis,
    whiteSpace: "nowrap",
  },
  paragraphForgotPass: {
    color: greyAxis,
    whiteSpace: "nowrap",
    transition: "all 0.4 ease",
    "&:hover": {
      cursor: "pointer",
      color: "#3f51b5",
      textDecoration: "underline",
    },
  },
  gridContainer: {
    marginBottom: 25,
  },

  inputs: { fontSize: MD_PLUS_FONT },
  errorsStyle: (props) => ({
    color: props.isDarkTheme ? darkThemeNegativeRed : negativeRedColor,
    maxWidth: 200,
    fontSize: XS_PLUS_FONT,
  }),
  closeBtn: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-between",
  },
  divRootContainer: (props) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: greyAxis,
    position: "relative",
    borderRadius: "var(--border-radius)",
    backgroundColor: props.isDarkTheme ? darkBackground : "white",
  }),
});
