import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { useStylesSelectPropDetails } from "../../styledComponent/SelectPropDetailsStyled";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { LG_FONT } from "../../services/fonts";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const SelectPropRating = ({
  ratingValue,
  setRatingValue,
  setRatingHover,
  ratingHover,
  labels,
  sizeRating,
  disabled,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme: isDarkTheme };
  const classes = useStylesSelectPropDetails(props);
  const { t } = useTranslation();

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography style={{ paddingTop: 0, fontSize: LG_FONT }}>
          {t("selectPropertyCondition")}:
        </Typography>
      </Grid>
      <Grid container item xs={12} alignItems={"center"}>
        <div className={classes.divRatingBox}>
          <Rating
            name="condition"
            value={ratingValue}
            precision={1}
            size={sizeRating}
            onChange={(event, newValue) => {
              setRatingValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setRatingHover(newHover);
            }}
            disabled={disabled}
          />
          {ratingValue != null && (
            <Box ml={3}>
              <Typography className={classes.paragraphAddProp}>
                {labels[ratingHover !== -1 ? ratingHover : ratingValue]}
              </Typography>
            </Box>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default SelectPropRating;
