import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IMAGES_LINK } from "..";
import { greyPropColor, mainPropColor } from "../global-il/services/colors";
import { LG_PLUS_FONT, XS_PLUS_FONT } from "../global-il/services/fonts";
import "./assets/styles/index.css";
import { useIsMobileScreen } from "../global-il/hooks/useIsMobileScreen.ts";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  cardContainer: (props) => ({
    marginBottom: props.isMobileScreen ? "25px" : null,
    maxWidth: props.isMobileScreen ? 220 : 200
    // marginBottom: "100px"
  })
})


export const Solutions = () => {
  const isMobileScreen = useIsMobileScreen()
  const props = {isMobileScreen}
  const classes = useStyles(props);
  const { t } = useTranslation();

  //   TODO : MAKE EXTERNAL FILE TO GET THIS DATA FROM
  const solutions = [
    {
      title: t("solutions.inidividuals"),
      subtitles: [
        t("solutions.instantPrice"),
        t("solutions.actionableInsights"),
        t("solutions.futurePrice"),
        t("solutions.currentFuture"),
      ],
    },
    {
      title: t("solutions.agents"),
      subtitles: [
        t("solutions.accuratePrice"),
        t("solutions.comparePrice"),
        t("solutions.futurePrice"),
        t("solutions.listingManagement"),
      ],
    },
    {
      title: t("solutions.devlopers"),
      subtitles: [
        t("solutions.accuracy"),
        t("solutions.instantPrice"),
        t("solutions.annualRental"),
        t("solutions.currentFuture"),
        t("solutions.managePortfolio"),
      ],
    },
    {
      title: t("solutions.managingAgents"),
      subtitles: [
        t("solutions.accuracy"),
        t("solutions.instantPrice"),
        t("solutions.annualRental"),
        t("solutions.currentFuture"),
        t("solutions.currentFuture"),
      ],
    },
  ];

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className="main-container padding"
      id="solutions"
    >
      <Typography
        style={{
          fontSize: LG_PLUS_FONT,
          fontWeight: 600,
          padding: 12,
          textDecoration: "underline",
          textDecorationColor: "pink",
          textUnderlineOffset: 6,
        }}
      >
        {t("solutions.title")}
      </Typography>
      <Grid  container justifyContent={isMobileScreen ? "center" : "space-between"}  style={{ padding: 12 }}>
        {solutions.map((item, idx) => {
          return (
            <Grid
              container
              item
              alignContent="flex-start"
              xs={12} 
              key={idx}
              className={classes.cardContainer}

              style={{
                borderRadius: "var(--border-radius)",
                // border: `1px solid ${greyPropColor}`,
                boxShadow: `${greyPropColor} 5px 5px 11px -7px`,
                // maxWidth: 200,
              }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                item
                xs={12}
                style={{
                  height: 40,
                  backgroundColor: mainPropColor,
                  color: "#fff",
                  borderTopLeftRadius: "var(--border-radius)",
                  borderTopRightRadius: "var(--border-radius)",
                  margin: 2,
                }}
              >
                <Typography style={{ fontWeight: 600 }}>
                  {item.title}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                style={{ padding: 12, height: "85%" }}
              >
                {item.subtitles.map((subtitle, index) => {
                  return (
                    <Grid
                      container
                      item
                      xs={12}
                      key={index}
                      justifyContent="space-between"
                    >
                      <Grid item xs={1}>
                        <img
                          src={
                            IMAGES_LINK + "/landing-page/solutions/check.svg"
                          }
                          style={{ width: "100%", height: 10 }}
                        />
                      </Grid>
                      <Grid container item xs={10}>
                        <Typography
                          style={{
                            fontSize: XS_PLUS_FONT,
                            color: greyPropColor,
                          }}
                        >
                          {subtitle}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
