import { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { mainPropColor, yellow } from "../../../../services/colors";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import Payment from "../../../../dialogs/payment/Payment";
import { isLoggedIn } from "../../../../services/helpers";
import { PurchasePropertyContext } from "../../../../context/PurchasePropertyContext";
import { Routes } from "../../../../services/routes";
import UserLoginIframe from "../../../../dialogs/login-singup/UserLoginIframe";
import { Login } from "common-components/src/global/components/login/Login";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";

export const handleBuyListings = (openDialog, property, authorization) => {
  if (authorization) {
    openDialog(Payment, { DialogContentProps: { listingProperty: property } });
  } else {
    openDialog(Login, { DialogProps: { maxWidth: "xs" } });
  }
};

export default function BackTilePreview({ property, classes }) {
  const { openDialog } = useDialogManager();
  const [, setProperty] = useContext(PurchasePropertyContext);
  const [authorization] = useContext(AuthorizationContext);

  const handleBuy = () => {
    setProperty(property);
    handleBuyListings(openDialog, property, authorization);
  };

  return (
    <Grid
      container
      alignItems={"center"}
      justify={"space-around"}
      className={classes.backTile}
    >
      <Grid container item xs={12} justify={"center"}>
        <FontAwesomeIcon icon={faHome} size={"3x"} />
      </Grid>
      <Grid container item xs={12} justify={"center"}>
        <Typography align={"center"} style={{ fontSize: 18 }}>
          רכש דו"ח פרופדו לנכס שבמודעה
        </Typography>
      </Grid>
      <Grid container item xs={12} justify={"center"} spacing={1}>
        <Grid item xs={12} justify={"center"}>
          <Button
            variant={"outlined"}
            color={"secondary"}
            style={{
              fontSize: 13,
              width: "100%",
              backgroundColor: yellow,
            }}
            onClick={handleBuy}
          >
            לרכישה
          </Button>
        </Grid>
        <Grid item xs={12} justify={"center"}>
          <Button
            variant={"contained"}
            color={"secondary"}
            style={{ fontSize: 13, width: "100%" }}
          >
            <Link
              target="_blank"
              to={`${Routes.listingsSummary}?id=${property.id}`}
              style={{ fontSize: 13, width: "100%", color: mainPropColor }}
            >
              לפרטים
            </Link>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
