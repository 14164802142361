import { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  addressSearch,
  checkSearchAddress,
  SearchAutocomplete,
} from "../../components/SearchPlaces";
import { useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import {
  checkAddress,
  isMaxRatingFunction,
  labelsRating,
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_GARDEN,
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_PENTHOUSE,
  PROPERTY_TYPE_PRIVATE_HOUSE,
  PROPERTY_TYPE_TOWN_HOUSE,
} from "../../dialogs/add-prop/SelectPropDetails";
import { InputLabel } from "@material-ui/core";
import { purchaseProperty } from "../../dialogs/payment/Payment";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { ConditionStars } from "common-components";
import { useIsMobileScreen } from "common-components";
import { doFetch } from "common-components";
import { propertyIL } from "../../context/PurchasePropertyContext";
import PropDetailsIL from "../../dialogs/add-prop/PropDetailsIL";
import { isLoggedIn } from "../../services/helpers";
import { ButtonSwitchSearch } from "common-components";
import CloseButton from "../../components/CloseButton";
import Typography from "@material-ui/core/Typography";
import { MD_PLUS_FONT } from "../../services/fonts";
import ReportsGushHalka from "./ReportsGushHalka";
import { LoadingButtons } from "../../components/layout/Layout";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import useContextProps from "../../hooks/useContextProps";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";

const ReportsAddProp = () => {
  const context = useContextProps();
  const { property, setProperty } = context;
  const [authorization] = useContext(AuthorizationContext);
  const { closeDialog } = useDialogManager();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    property,
  });

  const [addressInput, setAddressInput] = useState({
    address: checkAddress(property),
    area: property.area,
    house_num: property.house_num,
    street: property.street,
  });
  const [propTypeVal, setPropTypeVal] = useState(
    property.type ? property.type : PROPERTY_TYPE_APARTMENT()
  );
  const [errorAddress, setErrorAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSnackbar, setIsSnackbar] = useState(false);
  const { openDialog } = useDialogManager();

  const [isAddressSwitch, setIsAddressSwitch] = useState(
    !property.gush && !property.halka
  );
  const [ratingValue, setRatingValue] = useState(
    property.condition ? property.condition : 3
  );
  const [isMaxRating, setIsMaxRating] = useState(false);
  const { t } = useTranslation();

  useNotistack(
    `${t("insertAddress")}`,
    isSnackbar && isAddressSwitch,
    setIsSnackbar,
    "error"
  );
  useNotistack(`${t("noBlockPlot")}`, errorAddress, setErrorAddress, "error");

  const handleOpenSnackbar = () => {
    if (property.lat === 0 && property.long) {
      setIsSnackbar(true);
    } else {
      return null;
    }
  };

  const handleSelect = (address, placeId) => {
    setAddressInput((prevState) => ({ ...prevState, address: address }));
    checkSearchAddress(
      placeId,
      setLoading,
      setAddressInput,
      context.setPaymentProcess,
      false,
      setProperty
    );
  };
  const onSubmit = (values) => {
    setLoading(true);
    const newData = { ...property };
    newData["type"] = propTypeVal;
    for (const [key, value] of Object.entries(property)) {
      if (values[key]) {
        newData[key] = Number(values[key]);
      }
    }

    newData.parking = values.parking;
    newData.building_rights_level =
      values.building_rights_level === "no"
        ? "standard"
        : values.building_rights_level;

    setProperty(newData);
    if (authorization) {
      if (isAddressSwitch) {
        if (newData.lat && newData.long) {
          purchaseProperty(
            context.direction.api,
            newData,
            openDialog,
            t,
            setProperty
          );
        }
      } else {
        doFetch(
          `/${context.direction.api}/search/gush-halka?halka=${values.halka}&gush=${values.gush}`
        ).then((data) => {
          if (data.msg) {
            setErrorAddress(true);
            setTimeout(() => {
              setErrorAddress(false);
            }, 4000);
          } else {
            newData.lat = data.lat;
            newData.long = data.long;
            purchaseProperty(
              context.direction.api,
              newData,
              openDialog,
              t,
              setProperty
            );
          }
        });
      }
    }
    setAddressInput(addressSearch);
  };
  const props = { isDarkTheme: context.isDarkTheme, width: "100%" };
  const classes = useStylesAddProp(props);
  const isExtraSmallScreen = useIsMobileScreen("xs");
  let constructionYear = watch("year_built");

  useEffect(() => {
    isMaxRatingFunction(
      constructionYear,
      setProperty,
      setRatingValue,
      setIsMaxRating
    );
  }, [constructionYear]);

  const handleClose = () => {
    closeDialog();
    setProperty(propertyIL);
  };

  const handleRating = (value) => {
    setRatingValue(value);
    setProperty((prevState) => ({ ...prevState, condition: value }));
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
    >
      <div style={{ width: "100%", textAlign: "end" }}>
        <CloseButton callback={handleClose} height={15} />
      </div>

      <Grid
        container
        spacing={1}
        alignItems={"flex-start"}
        style={{ padding: "0 20px 20px 20px" }}
      >
        <Grid item xs={12} style={{ paddingRight: 8 }}>
          <Typography style={{ fontSize: MD_PLUS_FONT }}>
            {t("ReportsAddProp.title")}
          </Typography>
        </Grid>
        <PropDetailsIL
          xsInput={6}
          smInput={4}
          mdInput={3}
          lgInput={4}
          errors={errors}
          register={register}
          classesLabel={classes.inputLabelSearch}
          classesInput={classes.inputTextField}
          classesError={classes.paragraphErrorAddProp}
          propTypeVal={propTypeVal}
          watch={watch}
          control={control}
          setValue={setValue}
        >
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={4}
            direction={"row"}
            alignItems={"flex-end"}
            spacing={1}
          >
            <Grid item xs={2}>
              <ButtonSwitchSearch
                isAddressSwitch={isAddressSwitch}
                setIsAddressSwitch={setIsAddressSwitch}
                context={context}
              />
            </Grid>

            <Grid
              container
              item
              xs={10}
              style={{ paddingBottom: 10 }}
              spacing={1}
            >
              {isAddressSwitch ? (
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabelSearch}>
                    {t("ReportsAddProp.address")} *
                  </InputLabel>
                  <SearchAutocomplete
                    addressInput={addressInput}
                    handleChange={handleSelect}
                    handleSelect={handleSelect}
                    place={""}
                    setAddressInput={setAddressInput}
                    setErrorAddress={setErrorAddress}
                  />
                </Grid>
              ) : (
                <ReportsGushHalka
                  classes={classes}
                  errors={errors}
                  register={register}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <InputLabel className={classes.inputLabelSearch}>
              {t("ReportsAddProp.propertyType")} *
            </InputLabel>
            <Select
              id="propTypeVal"
              name="propTypeVal"
              labelId="propTypeVal"
              variant="outlined"
              defaultValue={PROPERTY_TYPE_APARTMENT()}
              style={{
                height: "35px",
                width: "100%",
              }}
            >
              <MenuItem value={0} disabled>
                {t("ReportsAddProp.propertyType")}
              </MenuItem>
              <MenuItem
                value={PROPERTY_TYPE_APARTMENT()}
                onClick={() => setPropTypeVal(PROPERTY_TYPE_APARTMENT())}
              >
                {PROPERTY_TYPE_APARTMENT()}
              </MenuItem>
              <MenuItem
                value={PROPERTY_TYPE_PENTHOUSE()}
                onClick={() => setPropTypeVal(PROPERTY_TYPE_PENTHOUSE())}
              >
                {PROPERTY_TYPE_PENTHOUSE()}
              </MenuItem>
              <MenuItem
                value={PROPERTY_TYPE_GARDEN()}
                onClick={() => setPropTypeVal(PROPERTY_TYPE_GARDEN())}
              >
                {PROPERTY_TYPE_GARDEN()}
              </MenuItem>
              <MenuItem
                value={PROPERTY_TYPE_PRIVATE_HOUSE()}
                onClick={() => setPropTypeVal(PROPERTY_TYPE_PRIVATE_HOUSE())}
              >
                {PROPERTY_TYPE_PRIVATE_HOUSE()}
              </MenuItem>
              <MenuItem
                value={PROPERTY_TYPE_TOWN_HOUSE()}
                onClick={() => setPropTypeVal(PROPERTY_TYPE_TOWN_HOUSE())}
              >
                {PROPERTY_TYPE_TOWN_HOUSE()}
              </MenuItem>
              <MenuItem
                value={PROPERTY_TYPE_LAND()}
                onClick={() => setPropTypeVal(PROPERTY_TYPE_LAND())}
              >
                {PROPERTY_TYPE_LAND()}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <InputLabel className={classes.inputLabelSearch}>
              {t("Const.propertyCondition")}
            </InputLabel>
            <Select
              className={classes.selectAddProp}
              variant="outlined"
              name={"condition"}
              style={{
                height: "35px",
                width: "100%",
              }}
              value={ratingValue}
              disabled={isMaxRating}
            >
              <MenuItem value={0} disabled>
                {t("Const.propertyCondition")}
              </MenuItem>
              <MenuItem value={1} onClick={() => handleRating(1)}>
                <div style={{ padding: "0 5px" }}>
                  <ConditionStars amount={1} context={context} />
                </div>

                {labelsRating()[1]}
              </MenuItem>
              <MenuItem value={2} onClick={() => handleRating(2)}>
                <div style={{ padding: "0 5px" }}>
                  <ConditionStars amount={2} context={context} />
                </div>

                {labelsRating()[2]}
              </MenuItem>
              <MenuItem value={3} onClick={() => handleRating(3)}>
                <div style={{ padding: "0 5px" }}>
                  <ConditionStars amount={3} context={context} />
                </div>

                {labelsRating()[3]}
              </MenuItem>
              <MenuItem value={4} onClick={() => handleRating(4)}>
                <div style={{ padding: "0 5px" }}>
                  <ConditionStars amount={4} context={context} />
                </div>
                {labelsRating()[4]}
              </MenuItem>
              <MenuItem value={5} onClick={() => handleRating(5)}>
                <div style={{ padding: "0 5px" }}>
                  <ConditionStars amount={5} context={context} />
                </div>

                {labelsRating()[5]}
              </MenuItem>
            </Select>
          </Grid>
        </PropDetailsIL>

        <Grid
          container
          alignItems={"center"}
          justify={"center"}
          item
          xs={12}
          style={{ marginTop: 20 }}
        >
          <div
            style={{ width: isExtraSmallScreen ? "100%" : "" }}
            onClick={() => {
              if (property.lat === 0 && property.long) {
                handleOpenSnackbar();
              }
            }}
          >
            <Button
              type="submit"
              variant={"contained"}
              color={"primary"}
              disabled={
                (property.lat === 0 &&
                  property.long === 0 &&
                  isAddressSwitch) ||
                loading
              }
              style={{
                width: isExtraSmallScreen ? "100%" : "",
                marginTop: 16,
                padding: "8px 60px",
              }}
            >
              {t("continue")}
              {loading && (
                <LoadingButtons
                  style={{ width: 15, height: 15, marginRight: 5 }}
                />
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
export default ReportsAddProp;
