import { useStylesPurchasePlan } from "../../styledComponent/PurchasePlanStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const SliderSwitch = ({ setIsAddressSwitch, isAddressSwitch }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme };
  const classes = useStylesPurchasePlan(props);
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 10,
      }}
    >
      <span
        className={classes.switch}
        onClick={() => setIsAddressSwitch(!isAddressSwitch)}
        test="test"
      >
        <input
          type="checkbox"
          id="switcher"
          beforeText={t("gushHalka")}
          afterText={t("address")}
          checked={!isAddressSwitch}
        />
        <label htmlFor="switcher" />
      </span>
    </div>
  );
};
export default SliderSwitch;
