import { darkElements, mainPropColor } from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStylesFooterSearch = makeStyles({
  divSearchBox: (props) => ({
    position: "fixed",
    backgroundColor: props.isDarkTheme ? darkElements : mainPropColor,
    width: "100%",
    height: 80,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      "0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14),  0px -1px 10px 0px rgba(0, 0, 0, 0.12)",
    transition: "ease 0.3s",
  }),
  divBalanceCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
});
