import {
  borderColor,
  darkElements,
  darkMainPropColor,
  mainPropColor,
} from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";
import { LG_FONT, XS_PLUS_FONT } from "../services/fonts";

export const useStylesPurchasePlan = makeStyles({
  pharPricingBack: (props) => ({
    color: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    fontSize: XS_PLUS_FONT,
    margin: "0 10px",
    fontWeight: 800,
    cursor: "pointer",
  }),
  divSpaceBetween100: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divCenter100: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  labelSwitch: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
    cursor: "pointer",
    transition: "all 0.5s",
  },
  divCenter100Relative: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 10,
  },
  spanSwitchContainer: (props) => ({
    display: "inline-block",
    position: "relative",
    borderRadius: "50em",
    border: props.isDarkTheme
      ? `1.5px solid ${darkMainPropColor}`
      : `1.5px solid ${mainPropColor}`,
    padding: 2,
    width: 175,
    height: 26,
  }),
  spanSwitchOnOff: (props) => ({
    position: "absolute",
    top: 1,
    right: 2,
    height: 28,
    width: 78,
    backgroundColor: props.isDarkTheme ? darkMainPropColor : mainPropColor,
    borderRadius: "50em",
    transition: "transform 0.5s",
  }),

  paragraphPrice: {
    display: "inline-block",
    verticalAlign: "top",
  },
  paragraphPriceForProp: {
    display: "inline-block",
    verticalAlign: "bottom",
    fontSize: LG_FONT,
    fontWeight: 500,
    transition: "all 0.2s",
  },
  gridPriceCardContainer: (props) => ({
    backgroundColor: props.isDarkTheme ? darkElements : "white",
    borderRadius: "var(--border-radius)",
    width: "100%",
    transition: "all 0.5s",
    border: `1px solid ${borderColor}`,
    zIndex: 1,
    height: 400,
  }),

  //  switch
  switch: (props) => ({
    position: "relative",
    width: 200,
    height: 36,
    borderRadius: 25,

    "& input": {
      appearance: "none",
      width: 200,
      height: 36,
      borderRadius: 25,
      border: `2px solid ${
        props.isDarkTheme ? darkMainPropColor : mainPropColor
      }`,
      outline: "none",
      transition: "0.25s",
    },

    "& input::before, & input::after": {
      zIndex: 2,
      position: "absolute",
      top: 18,
      transform: "translateY(-50%)",
      fontWeight: "bolder",
    },

    "& input::before": {
      content: "attr(beforeText)",
      right: 20,
    },
    "& input::after": {
      content: "attr(afterText)",
      left: 20,
    },
    "& input:checked": {
      border: `2px solid ${
        props.isDarkTheme ? darkMainPropColor : mainPropColor
      }`,
    },
    "& input:checked::after": {
      color: props.isDarkTheme ? darkMainPropColor : mainPropColor,
      transition: "color 0.5s",
      cursor: "pointer",
    },
    "& input:checked::before": {
      color: "white",
      transition: "color 0.5s",
    },

    "& label": {
      zIndex: 1,
      position: "absolute",
      top: 10,
      bottom: 4,
      borderRadius: 20,
    },

    "& input:checked+label": {
      left: 105,
      right: 4,
      height: 30,
      top: "3px",
      background: props.isDarkTheme ? darkMainPropColor : mainPropColor,
      transition: "left 0.4s 0.2s, right 0.5s, background 0.35s",
    },

    "& input:not(:checked)": {
      border: `2px solid ${
        props.isDarkTheme ? darkMainPropColor : mainPropColor
      }`,
      transition: "background 0.4s",
    },

    "& input:not(:checked)::before": {
      color: props.isDarkTheme ? darkMainPropColor : mainPropColor,
      transition: "color 0.5s",
      cursor: "pointer",
    },

    "& input:not(:checked)::after": {
      color: "#fff",
      transition: "color 0.5s",
    },

    "& input:not(:checked)+label": {
      left: 4,
      right: 120,
      height: 30,
      top: "3px",
      background: props.isDarkTheme ? darkMainPropColor : mainPropColor,
      transition: "left 0.5s, right 0.4s 0.2s",
    },
  }),
});
