import React, { useContext } from "react";
import { LoadingCharts } from "./layout/Layout";
import { Grid } from "@material-ui/core";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { useStylesForecast } from "../styledComponent/ForecastStyled";
import DemoExample from "./DemoExample";
import { useIsIncludes } from "common-components";
import TilesContainer from "./TilesContainer";
import { useIsMobileScreen } from "common-components";
import Typography from "@material-ui/core/Typography";
import { XS_PLUS_FONT } from "../services/fonts";
import { useTranslation } from "react-i18next";
import TitleGrid from "./TitleGrid";

interface DegreeOfInfluenceProp {
  data: { ratio_big: number; ratio_small: null } | undefined;
}
interface ProgressBarProps {
  width: number | string;
}
const DegreeOfInfluenceCard: React.FC<DegreeOfInfluenceProp> = ({ data }) => {
  const isDemo = useIsIncludes("demo");
  const isSmallScreen = useIsMobileScreen();
  const { t } = useTranslation();
  if (data) {
    return (
      <TilesContainer relative>
        <Grid
          container
          style={{ height: "100%", paddingBottom: isSmallScreen ? 15 : "" }}
        >
          {isDemo && <DemoExample />}
          <TitleGrid
            title={t("DegreeOfInfluenceCard.title")}
            tooltipText={t("DegreeOfInfluenceCard.tooltip")}
          />
          {dataInfluence(data, t).map((item) => {
            return (
              <Grid
                container
                item
                spacing={2}
                alignItems={"center"}
                key={item.title}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{ fontSize: XS_PLUS_FONT, fontWeight: 600 }}
                  >
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <ProgressBar width={item.data} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </TilesContainer>
    );
  } else {
    return (
      <TilesContainer style={{ height: "100%" }}>
        <LoadingCharts
          variant={"h5"}
          name={t("DegreeOfInfluenceCard.title")}
          height={"5%"}
          minHeight={"0px"}
          padding={0}
        />
      </TilesContainer>
    );
  }
};
export const ProgressBar: React.FC<ProgressBarProps> = ({ width }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme: isDarkTheme };
  const classesForecast = useStylesForecast(props);
  return (
    <div className={classesForecast.divWrapProgressBar}>
      <div
        className={classesForecast.divProgressBar}
        style={{ width: width }}
      />
    </div>
  );
};
export default DegreeOfInfluenceCard;

function dataInfluence(data: any, t: any) {
  return [
    {
      title: t("DegreeOfInfluenceCard.strongInfluence"),
      data: `${data["ratio_big"]}%`,
    },
    {
      title: t("DegreeOfInfluenceCard.lightInfluence"),
      data: `${data["ratio_small"]}%`,
    },
  ];
}
