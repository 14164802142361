import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckboxPropDetails from './CheckboxPropDetails';
import React, { useContext, useEffect, useState } from 'react';
import { useIsMobileScreen } from 'common-components';
import { PurchasePropertyContext } from '../../context/PurchasePropertyContext';
import {
  checkboxIlAll,
  selectPropDetailsILAll,
  selectPropDetailsILApartment_Penthouse,
  selectPropDetailsILExpanded,
  selectPropDetailsILGarden,
  selectPropDetailsILNotPrivateHouse,
  selectPropDetailsILPenthouse,
  selectPropDetailsILPrivate_TownHouse,
  selectPropDetailsLand,
} from '../../services/consts';
import InputProp from './InputProp';
import {
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_GARDEN,
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_PENTHOUSE,
  PROPERTY_TYPE_PRIVATE_HOUSE,
  PROPERTY_TYPE_TOWN_HOUSE,
} from './SelectPropDetails';
import { DarkThemeContext } from '../../context/DarkThemeContext';
import { makeStyles } from '@material-ui/core/styles';
import { darkElements } from '../../services/colors';
import {
  Collapse,
  InputLabel,
  Link,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { XS_PLUS_FONT } from '../../services/fonts';
import { useTranslation } from 'react-i18next';
import LinkProject from './LinkProject';
//

const useStyles = makeStyles({
  select: (props) => ({
    backgroundColor: props.isDarkTheme ? darkElements : '#fff',
    width: '18ch',
    padding: 11,
  }),
});

const PropDetailsIL = ({
  classesLabel,
  classesInput,
  classesError,
  register,
  errors,
  propTypeVal,
  watch,
  control,
  setValue,
  children,
}) => {
  const [property] = useContext(PurchasePropertyContext);
  const isMobileScreen = useIsMobileScreen();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classesSelect = useStyles({ isDarkTheme });
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [isElevator, setIsElevator] = useState(false);
  const [isMamad, setIsMamad] = useState(false);
  const watchYearBuilt = Number(watch('year_built'));
  const watchNumFloors = Number(watch('num_floors'));
  const handleCollapse = () => setIsAdvanced(!isAdvanced);
  const { t } = useTranslation();

  useEffect(() => {
    if (watchYearBuilt >= 1992) {
      setIsMamad(true);
    } else if (watchYearBuilt <= 1992) {
      setIsMamad(false);
    }
    if (watchYearBuilt >= 1974 && watchNumFloors >= 4) {
      setIsElevator(true);
    } else if (watchYearBuilt <= 1974 || watchNumFloors < 4) {
      setIsElevator(false);
    }
  }, [watchYearBuilt, watchNumFloors]);

  useEffect(() => {
    if (
      propTypeVal === PROPERTY_TYPE_LAND() ||
      propTypeVal === PROPERTY_TYPE_PRIVATE_HOUSE() ||
      propTypeVal === PROPERTY_TYPE_TOWN_HOUSE()
    )
      setIsAdvanced(true);
    else setIsAdvanced(false);
  }, [propTypeVal]);

  useEffect(() => {
    setValue('elevator', property.elevator || 0);
    setValue('parking', property.parking || 0);
    setValue('storage', property.storage || 0);
    setValue('bomb_shelter', property.bomb_shelter || 0);
    setValue(
      'building_rights_level',
      property.building_rights_level === 'no'
        ? 'standard'
        : property.building_rights_level
    );
    setValue(
      'building_rights_approval',
      property.building_rights_approval || 0
    );
  }, []);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        style={
          isMobileScreen
            ? { margin: 0, paddingLeft: 0 }
            : {
                height: propTypeVal === PROPERTY_TYPE_LAND ? 305 : 180,
                paddingTop: 8,
              }
        }
      >
        {children}
        {propTypeVal === PROPERTY_TYPE_LAND() &&
          selectPropDetailsLand(watch, t).map((item, index) => {
            return (
              <InputProp
                xsInput={!isMobileScreen ? 3 : 6}
                register={register}
                errors={errors}
                classesError={classesError}
                classesInput={classesInput}
                classesLabel={classesLabel}
                item={item}
                property={property}
              />
            );
          })}
        {propTypeVal === PROPERTY_TYPE_LAND() && (
          <>
            <Grid
              item
              xs={!isMobileScreen ? 3 : 6}
              style={{ paddingRight: 10 }}
            >
              <InputLabel
                className={classesLabel}
                style={{ whiteSpace: 'nowrap' }}
              >
                {t('PropDetails.buildingRightsLevel')}
              </InputLabel>
              <Controller
                control={control}
                name='building_rights_level'
                id='building_rights_level'
                defaultValue={'no'}
                render={({ field: { onChange, value } }) => (
                  <Select
                    variant='outlined'
                    id='building_rights_level'
                    name='building_rights_level'
                    labelId='building_rights_level'
                    defaultValue={'no'}
                    value={value}
                    onChange={onChange}
                    style={{
                      width: '100%',
                    }}
                    inputProps={{ className: classesSelect.select }}
                  >
                    <MenuItem value={'no'}>&mdash; &mdash;</MenuItem>
                    <MenuItem value={'standard'}>
                      {t('PropDetails.buildingRightsLevelDropdown.standard')}
                    </MenuItem>
                    <MenuItem value={'above'}>
                      {t('PropDetails.buildingRightsLevelDropdown.above')}
                    </MenuItem>
                    <MenuItem value={'luxury'}>
                      {t('PropDetails.buildingRightsLevelDropdown.luxury')}
                    </MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Typography style={{ fontSize: XS_PLUS_FONT, paddingRight: 8 }}>
                {t('PropDetails.buildingRightsApproval')}
              </Typography>
            </Grid>
            <Grid item xs={12} container alignItems={'center'}>
              <CheckboxPropDetails
                control={control}
                setValue={setValue}
                name={'building_rights_approval'}
                label={t('PropDetails.buildingRightsApproval')}
                checked={property.building_rights_approval}
              />
            </Grid>
            <Grid item xs={!isMobileScreen ? 3 : 6}>
              <InputLabel
                className={classesLabel}
                style={{ whiteSpace: 'nowrap' }}
              >
                {t('PropDetails.maturationPeriod')}
              </InputLabel>
              <Controller
                control={control}
                name='maturation_period'
                id='maturation_period'
                defaultValue={0}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id='maturation_period'
                    name='maturation_period'
                    labelId='maturation_period'
                    variant='outlined'
                    style={{
                      width: '100%',
                      fontSize: '13px',
                    }}
                    inputProps={{ className: classesSelect.select }}
                    placeholder={0}
                    defaultValue={0}
                    onChange={onChange}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                )}
              />
            </Grid>
          </>
        )}
        {propTypeVal !== PROPERTY_TYPE_LAND() &&
          selectPropDetailsILAll(watch, propTypeVal, t).map((item, index) => {
            return (
              <InputProp
                key={index}
                xsInput={!isMobileScreen ? 3 : 6}
                register={register}
                errors={errors}
                classesError={classesError}
                classesInput={classesInput}
                classesLabel={classesLabel}
                item={item}
                property={property}
              />
            );
          })}
        {propTypeVal !== PROPERTY_TYPE_LAND() && (
          <Grid item xs={!isMobileScreen ? 3 : 6}>
            <InputLabel
              className={classesLabel}
              style={{ whiteSpace: 'nowrap' }}
            >
              {t('PropDetails.parking')}
            </InputLabel>
            <Controller
              control={control}
              name='parking'
              id='parking'
              defaultValue={'without'}
              render={({ field: { onChange, value } }) => (
                <Select
                  variant='outlined'
                  id='parking'
                  name='parking'
                  defaultValue={'without'}
                  style={{
                    width: '100%',
                  }}
                  value={value}
                  inputProps={{ className: classesSelect.select }}
                  placeholder={t('PropDetails.parking')}
                  onChange={onChange}
                >
                  <MenuItem value={'without'}>
                    {t('PropDetails.parkingDropdown.without')}
                  </MenuItem>
                  <MenuItem value={'single'}>
                    {t('PropDetails.parkingDropdown.singleParking')}
                  </MenuItem>
                  <MenuItem value={'serial'}>
                    {t('PropDetails.parkingDropdown.serialParking')}
                  </MenuItem>
                  <MenuItem value={'double'}>
                    {t('PropDetails.parkingDropdown.doubleParking')}
                  </MenuItem>
                </Select>
              )}
            />
          </Grid>
        )}
        {
          /*Floor input relevant only for apartment and penthouse */
          propTypeVal !== PROPERTY_TYPE_LAND() &&
            [PROPERTY_TYPE_APARTMENT(), PROPERTY_TYPE_PENTHOUSE()].includes(
              propTypeVal
            ) && (
              <>
                {selectPropDetailsILApartment_Penthouse(watch, t).map(
                  (item, index) => {
                    return (
                      <InputProp
                        xsInput={!isMobileScreen ? 3 : 6}
                        register={register}
                        errors={errors}
                        classesError={classesError}
                        classesInput={classesInput}
                        classesLabel={classesLabel}
                        item={item}
                        property={property}
                      />
                    );
                  }
                )}
              </>
            )
        }
        {propTypeVal !== PROPERTY_TYPE_PRIVATE_HOUSE() &&
          propTypeVal !== PROPERTY_TYPE_LAND() && (
            <>
              {selectPropDetailsILNotPrivateHouse(t).map((item, index) => {
                return (
                  <InputProp
                    xsInput={!isMobileScreen ? 3 : 6}
                    register={register}
                    errors={errors}
                    classesError={classesError}
                    classesInput={classesInput}
                    classesLabel={classesLabel}
                    item={item}
                    property={property}
                  />
                );
              })}
            </>
          )}
        {
          /*Yard area relevant only for private house and townhouse */
          propTypeVal !== PROPERTY_TYPE_LAND() &&
            [
              PROPERTY_TYPE_PRIVATE_HOUSE(),
              PROPERTY_TYPE_TOWN_HOUSE(),
            ].includes(propTypeVal) && (
              <>
                {selectPropDetailsILPrivate_TownHouse(t).map((item, index) => {
                  return (
                    <InputProp
                      xsInput={!isMobileScreen ? 3 : 6}
                      register={register}
                      errors={errors}
                      classesError={classesError}
                      classesInput={classesInput}
                      classesLabel={classesLabel}
                      item={item}
                      property={property}
                    />
                  );
                })}
              </>
            )
        }
        {
          /*Yard roof area only relevant for penthouse*/
          propTypeVal !== PROPERTY_TYPE_LAND() &&
            propTypeVal === PROPERTY_TYPE_PENTHOUSE() && (
              <>
                {selectPropDetailsILPenthouse(t).map((item, index) => {
                  return (
                    <InputProp
                      xsInput={!isMobileScreen ? 3 : 6}
                      register={register}
                      errors={errors}
                      classesError={classesError}
                      classesInput={classesInput}
                      classesLabel={classesLabel}
                      item={item}
                      property={property}
                    />
                  );
                })}
              </>
            )
        }
        {
          /*Yard roof area only relevant for garden*/
          propTypeVal === PROPERTY_TYPE_GARDEN() && (
            <>
              {selectPropDetailsILGarden(t).map((item, index) => {
                return (
                  <InputProp
                    xsInput={!isMobileScreen ? 3 : 6}
                    register={register}
                    errors={errors}
                    classesError={classesError}
                    classesInput={classesInput}
                    classesLabel={classesLabel}
                    item={item}
                    property={property}
                  />
                );
              })}
            </>
          )
        }
      </Grid>
      {propTypeVal !== PROPERTY_TYPE_LAND && (
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: XS_PLUS_FONT, paddingRight: 8, paddingTop: 20 }}
          >
            {t('PropDetails.fillDataMsg')}
          </Typography>
        </Grid>
      )}

      <Grid
        container
        alignItems={'center'}
        justify={
          propTypeVal === PROPERTY_TYPE_TOWN_HOUSE()
            ? 'flex-start'
            : 'space-around'
        }
        style={
          isMobileScreen ? { padding: 12 } : { paddingTop: 0, paddingBottom: 0 }
        }
      >
        {propTypeVal !== PROPERTY_TYPE_LAND() ? (
          checkboxIlAll(t).map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={4}
                key={index}
                container
                justify={'flex-start'}
              >
                <CheckboxPropDetails
                  control={control}
                  setValue={setValue}
                  name={item.name}
                  label={item.label}
                  checked={property[item.name]}
                  isMamad={isMamad}
                />
              </Grid>
            );
          })
        ) : (
          <div style={{ height: 38 }}></div>
        )}
        {
          /* Elevator relevant only for apartment and penthouse  */
          propTypeVal !== PROPERTY_TYPE_LAND() &&
            [
              PROPERTY_TYPE_APARTMENT(),
              PROPERTY_TYPE_PENTHOUSE(),
              PROPERTY_TYPE_GARDEN(),
            ].includes(propTypeVal) && (
              <Grid item xs={12} sm={4} container justify={'flex-start'}>
                <CheckboxPropDetails
                  control={control}
                  setValue={setValue}
                  name='elevator'
                  label={t('PropDetails.elevator')}
                  checked={property.elevator}
                  isElevator={isElevator}
                />
              </Grid>
            )
        }
      </Grid>
      {isDarkTheme && (
        <Grid item xs={6} style={{ paddingTop: 30 }}>
          <LinkProject control={control} />
        </Grid>
      )}

      {propTypeVal !== PROPERTY_TYPE_LAND() ? (
        <Grid
          container
          item
          xs={12}
          spacing={1}
          style={{ paddingTop: 0, paddingBottom: 0, height: 100 }}
        >
          {isAdvanced ? (
            <Grid item xs={12} container>
              <Link
                onClick={handleCollapse}
                component='button'
                variant='body1'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: 4,
                }}
              >
                {t('PropDetails.closeBuildingRights')}
                <ExpandLessIcon />
              </Link>
            </Grid>
          ) : (
            <Link
              onClick={handleCollapse}
              component='button'
              variant='body1'
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: 4,
              }}
            >
              {t('PropDetails.extendBuildingRights')}
              <ExpandMoreIcon />
            </Link>
          )}
          <Grid item xs={!isMobileScreen ? 3 : 6}>
            <Collapse in={isAdvanced} timeout={'auto'} unmountOnExit>
              <InputLabel
                className={classesLabel}
                style={{ whiteSpace: 'nowrap' }}
              >
                {t('PropDetails.buildingRightsLevel')}
              </InputLabel>
              <Controller
                control={control}
                name='building_rights_level'
                id='building_rights_level'
                defaultValue={'no'}
                render={({ field: { onChange, value } }) => (
                  <Select
                    variant={'outlined'}
                    id={'building_rights_level'}
                    name={'building_rights_level'}
                    value={value}
                    defaultValue={'no'}
                    style={{
                      width: '100%',
                    }}
                    inputProps={{ className: classesSelect.select }}
                    onChange={onChange}
                  >
                    <MenuItem value={'no'}>&mdash; &mdash;</MenuItem>
                    <MenuItem value={'standard'}>
                      {t('PropDetails.buildingRightsLevelDropdown.standard')}
                    </MenuItem>
                    <MenuItem value={'above'}>
                      {t('PropDetails.buildingRightsLevelDropdown.above')}
                    </MenuItem>
                    <MenuItem value={'luxury'}>
                      {t('PropDetails.buildingRightsLevelDropdown.luxury')}
                    </MenuItem>
                  </Select>
                )}
              />
            </Collapse>
          </Grid>
          <Grid item xs={!isMobileScreen ? 3 : 6}>
            <Collapse in={isAdvanced} timeout={'auto'} unmountOnExit>
              {isAdvanced &&
                selectPropDetailsILExpanded(t).map((item, index) => {
                  return (
                    <InputProp
                      xsInput={12}
                      register={register}
                      errors={errors}
                      classesError={classesError}
                      classesInput={classesInput}
                      classesLabel={classesLabel}
                      item={item}
                      property={property}
                    />
                  );
                })}
            </Collapse>
          </Grid>
          <Grid
            item
            xs={!isMobileScreen ? 3 : 4}
            container
            alignItems={'flex-end'}
          >
            <Collapse in={isAdvanced} timeout={'auto'} unmountOnExit>
              <CheckboxPropDetails
                control={control}
                setValue={setValue}
                name={'building_rights_approval'}
                label={t('PropDetails.buildingRightsApproval')}
                checked={property.building_rights_approval}
              />
            </Collapse>
          </Grid>
        </Grid>
      ) : (
        <div style={{ height: 13.84 }}></div>
      )}
    </>
  );
};
export default PropDetailsIL;
