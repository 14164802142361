import { Grid, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({});

export const getLabels = (t) => {
  return {
    1: t("propertyForm.propertyCondition.1"),
    2: t("propertyForm.propertyCondition.2"),
    3: t("propertyForm.propertyCondition.3"),
    4: t("propertyForm.propertyCondition.4"),
    5: t("propertyForm.propertyCondition.5"),
  };
};

export const PropertyCondition = ({
  value,
  size,
  setConditionValue,
  SetConditionHover,
  disabled,
  conditionHover,
}) => {
  const { t } = useTranslation();

  const labels = getLabels(t);

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item xs={12} sm={2}>
        <Typography style={{ color: "white" }}>
          {t("propertyForm.propertyCondition.title")}
        </Typography>
      </Grid>
      <Grid container item xs={12} sm={10} alignItems={"center"}>
        <Grid item xs={4} sm={2}>
          <Rating
            name="condition"
            value={value}
            precision={1}
            size={size}
            onChange={(event, newValue) => {
              setConditionValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              SetConditionHover(newHover);
            }}
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={8} sm={8}>
          {value != null && (
            <Box ml={3}>
              <Typography style={{ color: "white" }}>
                {labels[conditionHover !== -1 ? conditionHover : value]}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
