import React, { useContext, useState } from "react";
import { Button } from "@material-ui/core";
import { doFetch } from "common-components";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import MessageAskMashkanta from "./../dialogs/dialog-components/MessageAskMashkanta";
import { OpenAccessContext } from "../context/OpenAccessContext";
import { PropertyIdContext } from "../context/PropertyIdContext";
import Typography from "@material-ui/core/Typography";
import { LG_FONT } from "../services/fonts";
import { LoadingButtons } from "./layout/Layout";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../context/DirectionContext";
import { t } from "i18next";

const AskMashkantaBox = ({ isData }) => {
  const [direction] = useContext(DirectionContext);
  const [propertyId] = useContext(PropertyIdContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [loading, setLoading] = useState(false);
  const { openDialog } = useDialogManager();
  const { t } = useTranslation();

  const handleSendRequest = async () => {
    try {
      setLoading(true);
      const result = await doFetch(
        `/reports/dashboard/request?id=${propertyId.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            request_type: "loan",
            subject: "appraisal",
            message: "",
          }),
        }
      );
      let text;
      let error = false;
      if (result.message.toLowerCase() === "request recieved") {
        text = (
          <>
            <Typography align={"center"} style={{ fontSize: LG_FONT }}>
              תודה שפנית לצוות המומחים שלנו.
            </Typography>
            <Typography align={"center"} style={{ fontSize: LG_FONT }}>
              ניצור עמך קשר במהלך 48 שעות העבודה הקרובות.
            </Typography>
          </>
        );
      } else {
        text = "הייתה תקלה בשליחת בקשתך. נא נסה שוב.";
        error = true;
      }
      handleResultDialog(openDialog, text, error);
      setLoading(false);
    } catch (err) {
      console.error(err);
      const text = t("errMsg");
      handleResultDialog(openDialog, text, true);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "middle",
        }}
      >
        <Typography variant={"h5"} align={"center"} style={{ width: "80%" }}>
          {t("Mortgage.appraiser.title")}
        </Typography>
      </div>
      <div
        style={{
          width: "80%",
          margin: "20px auto 0",
        }}
      >
        <Button
          variant={"contained"}
          disabled={!isData || isOpenAccess.access}
          color="primary"
          onClick={handleSendRequest}
          style={{
            width: "100%",
          }}
        >
          {loading ? <LoadingButtons /> : `${t("Mortgage.appraiser.button")}`}
        </Button>
      </div>
    </div>
  );
};

export default AskMashkantaBox;
function handleResultDialog(openDialog, text, error) {
  return openDialog(MessageAskMashkanta, {
    DialogProps: {
      fullScreen: false,
      scroll: "paper",
    },
    DialogContentProps: {
      text,
      error,
    },
  });
}
