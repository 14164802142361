import { AddFolderButton } from "./ProjectsComponent";
import Folder from "./Folder";
import Grid from "@material-ui/core/Grid";
import { doFetch } from "common-components";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useNotistack } from "common-components";
import {
  FOLDERS_CANNOT_REMOVED_MSG,
  FOLDERS_REMOVED_MSG,
} from "common-components";
import { useTranslation } from "react-i18next";

const ProjectsList = ({ folders, setFolders, projects, setProject }) => {
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const handleOpenFolder = (index) => {
    setFolders((prevState) => ({
      ...prevState,
      display: "buildings",
      indexProject: index,
      project: projects[index].label,
    }));
  };
  const handleAddProject = () => {
    setTimeout(() => {
      const notSavedFolders = projects.filter((item) => !item.saved);
      if (notSavedFolders.length === 0) {
        setProject((prevState) => [
          ...prevState,
          {
            label: t("PremiumProjects.project"),
            saved: false,
            buildings: [],
            id: uuidv4(),
          },
        ]);
      }
    }, 500);
  };

  const handleSaveProject = (label, index, setLoading) => {
    setLoading(true);
    doFetch(`/reports/premium/folder/add`, {
      method: "POST",
      body: JSON.stringify({
        type: "project",
        project: label,
        building: "",
      }),
    })
      .then(() => {
        const newProjects = [...projects];
        newProjects[index] = {
          label,
          saved: true,
          buildings: [],
          id: uuidv4(),
        };
        setProject(newProjects);
        setLoading(false);
      })
      .catch((error) => setError(true));
  };

  const handleDelete = (setDropdownDelete, index) => {
    setDropdownDelete(false);
    doFetch(`/reports/premium/folder/remove`, {
      method: "POST",
      body: JSON.stringify({
        type: "project",
        project: projects[index].label,
        building: "",
      }),
    })
      .then((data) => {
        if (data.message === FOLDERS_REMOVED_MSG) {
          const newProjects = [...projects];
          newProjects.splice(index, 1);
          setProject(newProjects);
        } else if (data.message === FOLDERS_CANNOT_REMOVED_MSG) {
          setError({ text: t("PremiumProjects.errMsg") });
        }
      })
      .catch((error) => setError({ text: t("updateFailedMsg") }));
  };

  const handleClickAway = () => {
    const newProjects = [...projects.filter((item) => item.saved)];
    setProject(newProjects);
  };

  useNotistack(error.text, error, setError, "error");

  return (
    <>
      <Grid xs={12}>
        <div style={{ height: "46.5px" }} />
      </Grid>
      {projects.map((item, index) => {
        return (
          <Folder
            handleClickAway={handleClickAway}
            key={item.id}
            index={index}
            item={item}
            handleOpenFolder={handleOpenFolder}
            handleDelete={handleDelete}
            handleSave={handleSaveProject}
          />
        );
      })}
      <AddFolderButton
        text={t("PremiumProjects.addProject")}
        handleAdd={handleAddProject}
      />
    </>
  );
};
export default ProjectsList;
