import React, { createContext, useState } from "react";

export const AddressListingsContext = createContext({});

export const AddressListingsContextProvider = ({ children }) => {
  const [listingsAddress, setListingsAddress] = useState(undefined);

  return (
    <AddressListingsContext.Provider
      value={[listingsAddress, setListingsAddress]}
    >
      {children}
    </AddressListingsContext.Provider>
  );
};
