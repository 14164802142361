import React, { useContext, useEffect, useState } from "react";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import LineChart from "./LineChart";
import TilesContainer from "../../TilesContainer";
import { LoadingCharts } from "../../layout/Layout";
import DemoExample from "../../DemoExample";
import { useIsIncludes } from "common-components";
import { useIsMobileScreen } from "common-components";
import {
  color2,
  darkGrey,
  darkLineColor,
  darkMainPropColor,
  lineColor,
  mainPropColor,
} from "../../../services/colors";
import { roundUp } from "../../../data/DataLineChart";
import useNameLabels from "../../../hooks/useNameLabels";
import { useTranslation } from "react-i18next";

export function labelData(t, data, isDarkTheme, colors, colorsDark, name) {
  const res = data[name]
    .map((item, index) => {
      if (
        (typeof item === "object" && item.data.length > 0) ||
        typeof item === "string"
      ) {
        return {
          color:
            t &&
            t(`labels.${item.name}`) === t("labels.property") &&
            !isDarkTheme
              ? color2
              : t &&
                t(`labels.${item.name}`) === t("labels.property") &&
                isDarkTheme
              ? darkMainPropColor
              : t &&
                t(`labels.${item.name}`) === t("labels.all") &&
                !isDarkTheme
              ? mainPropColor
              : t && t(`labels.${item.name}`) === t("labels.all") && isDarkTheme
              ? darkGrey
              : isDarkTheme
              ? colorsDark[index]
              : colors[index],
          label: item.name
            ? t(`labels.${item.name}`)
            : item.label
            ? t
              ? t(`labels.${item.label}`)
              : item.label
            : item,
        };
      }
    })
    .filter((item) => item !== undefined);

  return res;
}
function normaliseDataLine(t, data, isDarkTheme) {
  return {
    labels: data["x-axis"],
    datasets: data.series.map((item, index) => {
      return {
        label: t(`LineChart.labels.${item.name}`),
        fill: false,
        lineTension: 0.5,
        backgroundColor: isDarkTheme ? darkLineColor[index] : lineColor[index],
        borderColor: isDarkTheme ? darkLineColor[index] : lineColor[index],
        borderCapStyle: "round",
        borderDash: index === 1 ? [] : [12, 6],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: isDarkTheme ? darkLineColor[index] : lineColor[index],
        pointBackgroundColor: isDarkTheme
          ? darkLineColor[index]
          : lineColor[index],
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: isDarkTheme
          ? darkLineColor[index]
          : lineColor[index],
        pointHoverBorderColor: isDarkTheme
          ? darkLineColor[index]
          : lineColor[index],
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: item.data.map((num) => roundUp(num)),
      };
    }),
  };
}

const TamaLineChart = ({ lineData, title }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [data, setData] = useState(undefined);
  const [tooltipDate, setTooltipDate] = useState(undefined);
  const { t } = useTranslation();
  const isDemo = useIsIncludes("demo");
  const isExtraSmallScreen = useIsMobileScreen("xs");

  const month = Number(lineData.date.split(" ")[0].split("-")[1]);
  const labelNames = useNameLabels(lineData ? lineData["x-axis"] : [], month);

  useEffect(() => {
    if (!labelNames) return;
    setTooltipDate(labelNames);
  }, [labelNames]);

  useEffect(() => {
    if (lineData) {
      const normalisedData = normaliseDataLine(t, lineData, isDarkTheme);
      setData(normalisedData);
    }
  }, [lineData, setData]);

  return (
    <TilesContainer relative>
      {isDemo && (
        <DemoExample ribbonLeft={isExtraSmallScreen ? "0px" : undefined} />
      )}
      {data && tooltipDate ? (
        <LineChart data={data} tooltipDate={tooltipDate} title={title} />
      ) : (
        <LoadingCharts
          variant={"h5"}
          name={title}
          height={"calc(100% - 70px)"}
        />
      )}
    </TilesContainer>
  );
};
export default TamaLineChart;
