import React, { useContext } from "react";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import Button from "@material-ui/core/Button";
import { XS_PLUS_FONT } from "../../../services/fonts";
import { darkMainPropColor, mainPropColor } from "../../../services/colors";
import { Link } from "react-router-dom";
import { Routes } from "../../../services/routes";
import CallMade from "@material-ui/icons/CallMade";
import { useTranslation } from "react-i18next";

const TableButtonViewProp = ({ row }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();

  return (
    <Button
      style={{
        fontSize: XS_PLUS_FONT,
        color: isDarkTheme ? darkMainPropColor : mainPropColor,
      }}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      <Link
        target="_blank"
        to={`${Routes.dashboardSummary}?id=${[row.id]}`}
        style={{ color: "inherit" }}
      >
        <CallMade
          style={{
            color: isDarkTheme ? darkMainPropColor : mainPropColor,
            width: 15,
            height: 15,
          }}
        />
        {t("Reports.view")}
      </Link>
    </Button>
  );
};
export default TableButtonViewProp;
