import React, { useContext, useEffect, useState } from "react";
import BarChart, { BarChartInterface } from "../charts/BarChart";
import { hexToRgbA } from "../services/helpers";
import {
  darkElements,
  darkFiveColors,
  darkMainPropColor,
  fiveColors,
  mainPropColor,
} from "../services/colors";
import { LegendInterface } from "./DataStackBarChart";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { labelData } from "../components/dashboard-components/tama/TamaLineChart";
import { SpinnerInsideCharts } from "../components/layout/Layout";
import { useTranslation } from "react-i18next";

const barColors = (color: string, opacity: number) => {
  return [
    hexToRgbA(color, opacity),
    hexToRgbA(color, opacity),
    hexToRgbA(color, opacity),
    hexToRgbA(color, opacity),
    hexToRgbA(color, opacity),
  ];
};

export function normaliseData(
  data: DataBarCharInterface,
  isDarkTheme: boolean
) {
  return {
    labels: data.labels,
    datasets: [
      {
        data: [...data.series],
        backgroundColor: isDarkTheme
          ? barColors(darkMainPropColor, 1)
          : barColors(mainPropColor, 1),
        hoverBackgroundColor: isDarkTheme
          ? barColors(darkMainPropColor, 0.7)
          : barColors(mainPropColor, 0.7),
        hoverBorderColor: isDarkTheme
          ? barColors(darkMainPropColor, 0.7)
          : barColors(mainPropColor, 0.7),
        borderColor: isDarkTheme ? darkElements : "#fff",
        hoverBorderWidth: 0,
      },
    ],
  };
}

export interface DataBarCharInterface {
  labels: string[];
  series: number[];
}
const DataBarChar: React.FC<{ rawData: DataBarCharInterface }> = ({
  rawData,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [data, setData] = useState<BarChartInterface | undefined>(undefined);
  const [legend, setLegend] = useState<LegendInterface[] | undefined>(
    undefined
  );
  const { t } = useTranslation();
  useEffect(() => {
    setData(normaliseData(rawData, isDarkTheme));
    setLegend(
      labelData(t, rawData, isDarkTheme, fiveColors, darkFiveColors, "labels")
    );
  }, [rawData]);
  if (data && legend) {
    return <BarChart data={data} legend={legend} />;
  } else {
    return <SpinnerInsideCharts />;
  }
};
export default DataBarChar;
