import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { mainPropColor } from "../services/colors";
import { LoginForm } from "./LoginForm";
import { SocialLogin } from "./SocialLogin";
import CloseIcon from "@material-ui/icons/Close";
import { useDialogManager } from "../hooks/useDialogManager";
import { useState, useLayoutEffect } from "react";
import { Register } from "./Register";
import { useIsIncludes } from "../hooks/useIsIncludes";
import { LANDING_PAGE_LINK } from "../api/api_links";
import { useIsLargeScreen } from "../hooks/useIsLargeScreen";
import { ResetPassword } from "./ResetPassword";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const useStyles = makeStyles({
  with: (props) => ({
    "&::before,&::after": {
      display: "inline-block",
      content: `""`,
      borderTop: `1px solid ${mainPropColor}`,
      width: props.isDesktopScreen ? "90px" : "69px",
      margin: "0 10px",
      transform: "translateY(-2px)",
    },
  }),
});

export const Login = () => {
  const isDesktopScreen = useIsLargeScreen("md");
  const props = { useIsLargeScreen };
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { closeDialog } = useDialogManager();
  const [isRegister, setIsRegister] = useState(false);
  const [isResetPassword, setisResetPassword] = useState(false);
  const isLandingPage = useIsIncludes(LANDING_PAGE_LINK);
  const [isRtl, setIsRtl] = useState(true);

  const rtlTheme = createTheme({ direction: "rtl" });

  useLayoutEffect(() => {
    document.body.setAttribute("dir", isRtl);
  }, [isRtl]);

  return (
    <Grid
      container
      style={{
        padding: isDesktopScreen ? "64px" : "24px",
        position: "relative",
      }}
      justifyContent="center"
      direction="column"
    >
      {isLandingPage && (
        <CloseIcon
          style={{
            position: "absolute",
            right: 15,
            top: 15,
            cursor: "pointer",
          }}
          onClick={() => closeDialog()}
        />
      )}
      <Grid item xs={12}>
        <Typography
          style={{ textAlign: "right", marginBottom: 14, fontSize: 19 }}
        >
          {t(
            `login.${
              !isRegister
                ? !isResetPassword
                  ? "login"
                  : "resetPassword"
                : isRegister
                ? "registration"
                : "login"
            }`
          )}
        </Typography>
      </Grid>
      <ThemeProvider theme={rtlTheme}>
        {!isRegister ? (
          !isResetPassword ? (
            <LoginForm />
          ) : (
            <ResetPassword setisResetPassword={setisResetPassword} />
          )
        ) : isRegister ? (
          <Register setIsRegister={setIsRegister} />
        ) : (
          <LoginForm />
        )}
      </ThemeProvider>

      {!isResetPassword ? (
        <Grid container item xs={12} style={{ marginTop: 14 }}>
          <Typography style={{ fontSize: 14 }}>
            {t(`login.${!isRegister ? "notRegistered" : "registered"}`)}
          </Typography>
          <Typography
            style={{
              color: mainPropColor,
              marginRight: 5,
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: 14,
            }}
            onClick={() => setIsRegister(!isRegister)}
          >
            {t(`login.${!isRegister ? "signupHere" : "submit"}`)}
          </Typography>
        </Grid>
      ) : null}

      {!isRegister ? (
        <Grid container item xs={12} style={{ marginTop: 14 }}>
          <Typography style={{ fontSize: 14 }}>
            {/* {t(`login.${!isPassword ? "notRegistered" : "registered"}`)} */}
          </Typography>
          <Typography
            style={{
              color: mainPropColor,
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: 14,
            }}
            onClick={() => setisResetPassword(!isResetPassword)}
          >
            {t(
              `login.${
                !isResetPassword
                  ? !isRegister
                    ? "forgotPassword"
                    : "submit"
                  : isRegister
                  ? "registration"
                  : "login"
              }`
            )}
          </Typography>
        </Grid>
      ) : null}

      <Grid
        container
        alignItems="center"
        item
        xs={12}
        style={{ marginTop: 28, justifyContent: "center" }}
      >
        {!isResetPassword ? (
          <Typography className={classes.with}>{t("login.orWith")}</Typography>
        ) : null}
      </Grid>
      {!isResetPassword ? <SocialLogin /> : null}
    </Grid>
  );
};
