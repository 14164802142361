import React, { useContext, useEffect, useState } from "react";
import PosConChart from "../charts/PosConChart";
import { hexToRgbA } from "../services/helpers";
import {
  color5,
  darkMainPropColor,
  darkPosConBar,
  mainPropColor,
  posConBar,
} from "../services/colors";
import { LegendInterface } from "./DataStackBarChart";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { labelData } from "../components/dashboard-components/tama/TamaLineChart";
import { SpinnerInsideCharts } from "../components/layout/Layout";
import { useTranslation } from "react-i18next";

function normaliseData(data: DataPosConChartInterface, isDarkTheme: boolean) {
  return {
    labels: data["x-axis"].categories,
    datasets: data.series.map((item, index) => {
      return {
        label: item.name,
        backgroundColor:
          index === 0 && isDarkTheme
            ? darkMainPropColor
            : index === 0
            ? mainPropColor
            : color5,
        borderColor: index === 0 ? mainPropColor : color5,
        borderWidth: 0,
        hoverBackgroundColor:
          index === 0
            ? hexToRgbA(isDarkTheme ? darkMainPropColor : mainPropColor, 0.7)
            : hexToRgbA(color5, 0.7),
        hoverBorderColor:
          index === 0
            ? hexToRgbA(isDarkTheme ? darkMainPropColor : mainPropColor, 0.7)
            : hexToRgbA(color5, 0.7),
        data: item.data,
      };
    }),
  };
}

function tooltipData(data: {
  categories: string[];
  label_names: string[];
  labels: number[];
}) {
  return data["label_names"].map((item, index) => {
    return {
      label: data.labels[index],
      name: item,
    };
  });
}

export interface DataPosConChartInterface {
  series: Series[];
  "x-axis": XAxis;
}

export interface Series {
  data: number[];
  name: string;
}

export interface XAxis {
  categories: string[];
  label_names: string[];
  labels: number[];
}
export interface PosConChartInterface {
  labels: string[];
  datasets: Dataset[];
}

export interface Dataset {
  label: string;
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  hoverBackgroundColor: string;
  hoverBorderColor: string;
  data: number[];
}
const DataPosConChart: React.FC<{ rawData: DataPosConChartInterface }> = ({
  rawData,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [data, setData] = useState<
    | {
        labels: string[];
        datasets: {
          label: string;
          backgroundColor: string;
          borderColor: string;
          borderWidth: number;
          hoverBackgroundColor: string;
          hoverBorderColor: string;
          data: number[];
        }[];
      }
    | undefined
  >(undefined);
  const [legend, setLegend] = useState<LegendInterface[] | undefined>(
    undefined
  );
  const [dataTooltip, setDataTooltip] = useState<
    { label: number; name: string }[] | undefined
  >(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    setData(normaliseData(rawData, isDarkTheme));
    setLegend(
      labelData(t, rawData, isDarkTheme, posConBar, darkPosConBar, "series")
    );
    setDataTooltip(tooltipData(rawData["x-axis"]));
  }, [rawData]);
  if (data && legend && dataTooltip) {
    return (
      <PosConChart data={data} legend={legend} dataTooltip={dataTooltip} />
    );
  } else {
    return <SpinnerInsideCharts />;
  }
};
export default DataPosConChart;
