import { Button } from "@material-ui/core";
import { LoadingButtons } from "../../../components/layout/Layout";
import { darkMainPropColor, mainPropColor } from "../../../services/colors";
import ShareIcon from "@material-ui/icons/Share";
import React, { useContext, useState } from "react";
import { doFetch } from "common-components";
import SharedLink from "../../../components/dashboard-components/global-summary/SharedLink";
import { REPORTS_LINK } from "common-components";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { useNotistack } from "common-components";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { useTranslation } from "react-i18next";

const ShareButton = ({ id, color }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { openDialog } = useDialogManager();
  const [loading, setLoading] = useState(false);
  const [errorShare, setErrorShare] = useState(false);
  const { t } = useTranslation();

  const shareColor = color
    ? color
    : isDarkTheme
    ? darkMainPropColor
    : mainPropColor;

  const handleShare = () => {
    setLoading(true);
    doFetch(`/reports/share?id=${id}`)
      .then((res) => {
        if (res.message) {
          setErrorShare(true);
        } else {
          setLoading(false);
          openDialog(SharedLink, {
            DialogProps: {
              fullScreen: false,
              scroll: "paper",
            },
            DialogContentProps: {
              link: `${
                window.location.host.includes("localhost")
                  ? "http://localhost:3000"
                  : REPORTS_LINK
              }/il/report/summary/share/?id=${res.id}`,
            },
          });
        }
      })
      .catch((e) => setErrorShare(true));
  };

  useNotistack(t("updateFailedMsg"), errorShare, setErrorShare, "error");

  return (
    <Button
      variant={"text"}
      color={"primary"}
      onClick={handleShare}
      style={{
        color: shareColor,
      }}
    >
      {t("Share.share")}

      {loading ? (
        <LoadingButtons size={"1x"} style={{ padding: 5 }} color={shareColor} />
      ) : (
        <ShareIcon style={{ paddingRight: 5 }} />
      )}
    </Button>
  );
};
export default ShareButton;
