import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import {
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_GARDEN,
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_PENTHOUSE,
  PROPERTY_TYPE_PRIVATE_HOUSE,
  PROPERTY_TYPE_TOWN_HOUSE,
  PropertyTypeRadio,
} from "./SelectPropDetails";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import clsx from "clsx";
import { propTypeEnName } from "../payment/PropAddedPayment";
import Typography from "@material-ui/core/Typography";
import { LG_FONT } from "../../services/fonts";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

const SelectPropPropType = ({
  handlePropTypeValChange,
  propTypeVal,
  sizeIcon,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [direction] = useContext(DirectionContext);
  const props = { isDarkTheme };
  const classes = useStylesAddProp(props);
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        direction={"column"}
        item
        xs={6}
        className={clsx({
          [classes.checkedStyle]: propTypeVal === PROPERTY_TYPE_APARTMENT(),
          [classes.notCheckedStyle]: propTypeVal !== PROPERTY_TYPE_APARTMENT(),
        })}
        style={{ cursor: "pointer" }}
        onClick={() => handlePropTypeValChange(PROPERTY_TYPE_APARTMENT())}
      >
        <PropertyTypeRadio
          label={PROPERTY_TYPE_APARTMENT()}
          Icon={`selectProp/${
            isDarkTheme ? "darkTheme" : "lightTheme"
          }/${propTypeEnName(PROPERTY_TYPE_APARTMENT())}.svg`}
          size={sizeIcon}
        />
      </Grid>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        direction={"column"}
        item
        xs={6}
        className={clsx({
          [classes.checkedStyle]: propTypeVal === PROPERTY_TYPE_GARDEN(),
          [classes.notCheckedStyle]: propTypeVal !== PROPERTY_TYPE_GARDEN(),
        })}
        style={{ cursor: "pointer" }}
        onClick={() => handlePropTypeValChange(PROPERTY_TYPE_GARDEN())}
      >
        <PropertyTypeRadio
          label={PROPERTY_TYPE_GARDEN()}
          Icon={`selectProp/${
            isDarkTheme ? "darkTheme" : "lightTheme"
          }/${propTypeEnName(PROPERTY_TYPE_GARDEN())}.svg`}
          size={sizeIcon}
        />
      </Grid>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        direction={"column"}
        item
        xs={6}
        className={clsx({
          [classes.checkedStyle]: propTypeVal === PROPERTY_TYPE_PENTHOUSE(),
          [classes.notCheckedStyle]: propTypeVal !== PROPERTY_TYPE_PENTHOUSE(),
        })}
        style={{ cursor: "pointer" }}
        onClick={() => handlePropTypeValChange(PROPERTY_TYPE_PENTHOUSE())}
      >
        <PropertyTypeRadio
          label={PROPERTY_TYPE_PENTHOUSE()}
          Icon={`selectProp/${
            isDarkTheme ? "darkTheme" : "lightTheme"
          }/${propTypeEnName(PROPERTY_TYPE_PENTHOUSE())}.svg`}
          size={sizeIcon}
        />
      </Grid>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        direction={"column"}
        item
        xs={6}
        className={clsx({
          [classes.checkedStyle]: propTypeVal === PROPERTY_TYPE_TOWN_HOUSE(),
          [classes.notCheckedStyle]: propTypeVal !== PROPERTY_TYPE_TOWN_HOUSE(),
        })}
        style={{ cursor: "pointer" }}
        onClick={() => handlePropTypeValChange(PROPERTY_TYPE_TOWN_HOUSE())}
      >
        <PropertyTypeRadio
          label={PROPERTY_TYPE_TOWN_HOUSE()}
          Icon={`selectProp/${
            isDarkTheme ? "darkTheme" : "lightTheme"
          }/${propTypeEnName(PROPERTY_TYPE_TOWN_HOUSE())}.svg`}
          size={sizeIcon}
        />
      </Grid>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        direction={"column"}
        item
        xs={6}
        className={clsx({
          [classes.checkedStyle]: propTypeVal === PROPERTY_TYPE_PRIVATE_HOUSE(),
          [classes.notCheckedStyle]:
            propTypeVal !== PROPERTY_TYPE_PRIVATE_HOUSE(),
        })}
        style={{ cursor: "pointer" }}
        onClick={() => handlePropTypeValChange(PROPERTY_TYPE_PRIVATE_HOUSE())}
      >
        <PropertyTypeRadio
          label={PROPERTY_TYPE_PRIVATE_HOUSE()}
          Icon={`selectProp/${
            isDarkTheme ? "darkTheme" : "lightTheme"
          }/${propTypeEnName(PROPERTY_TYPE_PRIVATE_HOUSE())}.svg`}
          size={sizeIcon}
        />
      </Grid>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        direction={"column"}
        item
        xs={6}
        className={clsx({
          [classes.checkedStyle]: propTypeVal === PROPERTY_TYPE_LAND(),
          [classes.notCheckedStyle]: propTypeVal !== PROPERTY_TYPE_LAND(),
        })}
        style={{ cursor: "pointer" }}
        onClick={() => handlePropTypeValChange(PROPERTY_TYPE_LAND())}
      >
        <PropertyTypeRadio
          label={PROPERTY_TYPE_LAND()}
          Icon={`selectProp/${
            isDarkTheme ? "darkTheme" : "lightTheme"
          }/${propTypeEnName(PROPERTY_TYPE_LAND())}.svg`}
          size={sizeIcon}
        />
      </Grid>
    </>
  );
};
export default SelectPropPropType;
