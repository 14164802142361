import React, { useCallback, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SpinnerLoading } from "../../components/dashboard-components/forecast/SliderBox";
import PurchasePlan from "../pricing/PurchasePlan";
import MessageWrongProp from "../dialog-components/MessageWrongProp";
import MessagePropAdded from "../dialog-components/MessagePropAdded";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { PaymentProcessContext } from "../../context/PaymentProcessContext.tsx";
import { useIsMobileScreen } from "common-components";
import { makeStyles } from "@material-ui/core/styles";
import PlanTypePayment from "./PlanTypePayment";
import PropAddedPayment from "./PropAddedPayment";
import PaymentForm from "./PaymentForm";
import Grid from "@material-ui/core/Grid";
import { BackButton } from "common-components";
import { useStylesPurchasePlan } from "../../styledComponent/PurchasePlanStyled";
import { doFetch } from "common-components";
import { useStylesUserLogin } from "../../styledComponent/UserLoginStyled";
import {
  propertyIL,
  PurchasePropertyContext,
} from "../../context/PurchasePropertyContext";
import CloseButton from "../../components/CloseButton";
import {
  borderColor,
  darkBackground,
  darkBorderColor,
  darkElements,
  lightBoxes,
} from "../../services/colors";
import Price from "./Price";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { clearSessionStorage, hasValueInStorage } from "../../services/helpers";
import MessageNoProp from "../../components/dashboard-components/global-summary/MessageNoProp";
import { DirectionContext } from "../../context/DirectionContext";
import { t } from "i18next";
import useContextProps from "../../hooks/useContextProps";

export const date = new Date();

export const defaultValues = {
  month: "01",
  num_payments: "1",
  year: date.getFullYear().toString(),
};
export const useBreakpointsFont = makeStyles((theme) => ({
  spacing: {
    [theme.breakpoints.down("lg")]: {
      marginBottom: 4,
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: 5,
    },
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginBottom: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginBottom: 17,
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginBottom: 15,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      marginBottom: 19,
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: 20,
    },
  },
}));

const Payment = ({ margin, noBack, listingProperty }) => {
  const context = useContextProps();
  const {
    direction,
    isDarkTheme,
    property,
    setProperty,
    setPaymentProcess,
  } = context;
  const { openDialog, closeDialog } = useDialogManager();
  const [loading, setLoading] = useState(false);
  const classes = useStylesUserLogin({ isDarkTheme });
  let location = useLocation();
  const isMobileScreen = useIsMobileScreen();
  const history = useHistory();

  const handleClosePayment = () => {
    if (property.lat && property.long) {
      propertyNotPurchased(direction.api, property);
    }
    history.push({ pathname: location.pathname, search: location.search });
    setProperty(propertyIL);
    setPaymentProcess(false);
    if (hasValueInStorage(localStorage.getItem("noProp"))) {
      openDialog(MessageNoProp, {
        DialogProps: { fullScreen: false, scroll: "paper" },
      });
    } else {
      closeDialog();
    }
  };

  const backButton = useCallback(() => {
    sessionStorage.removeItem("planId");
    sessionStorage.removeItem("price");
    sessionStorage.removeItem("period");
    sessionStorage.removeItem("amount");
    openDialog(PurchasePlan, {
      DialogProps: {
        maxWidth: "md",
      },
    });
  }, []);
  const classesPurch = useStylesPurchasePlan();
  const id = location.hash.length > 0;

  return (
    <div
      style={{
        position: "relative",
        marginTop: isMobileScreen ? margin : 0,
        padding: 0,
      }}
    >
      <div
        className={classesPurch.divSpaceBetween100}
        style={{
          justifyContent: noBack ? "flex-end" : "",
          position: "absolute",
        }}
      >
        {id ? <BackButton callBack={backButton} context={context} /> : <div />}
        <CloseButton padding={"15px"} callback={handleClosePayment} />
      </div>
      <Grid
        container
        alignItems={isMobileScreen ? "center" : "flex-start"}
        style={{
          backgroundColor: isDarkTheme ? darkElements : "",
          opacity: loading ? 0.3 : 1,
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{
            backgroundColor: isDarkTheme ? darkBackground : lightBoxes,
            padding: "32px 37px 6px",
            order: isMobileScreen ? 1 : null,
          }}
        >
          <PaymentForm
            setLoading={setLoading}
            classes={classes}
            loading={loading}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            backgroundColor: isDarkTheme ? darkElements : "",
            padding: isMobileScreen ? "30px 0" : "90px 37px 0",
          }}
        >
          <div
            style={{
              border: isMobileScreen
                ? "none"
                : `1px solid ${isDarkTheme ? darkBorderColor : borderColor}`,
              padding: isMobileScreen ? "16px 36px 0" : "16px 25px",
            }}
          >
            <Grid item xs={12}>
              <PlanTypePayment id={id} />
            </Grid>
            <Grid item xs={12}>
              <PropAddedPayment id={id} listingProperty={listingProperty} />
            </Grid>
            <Grid item xs={12}>
              <Price id={id} />
            </Grid>
          </div>
        </Grid>
      </Grid>

      {loading && <SpinnerLoading />}
    </div>
  );
};

const queryParams = new URLSearchParams(window.location.search);

export function purchaseProperty(
  api,
  property,
  openDialog,
  t,
  setProperty,
  history
) {
  doFetch(`/reports/purchase/property`, {
    method: "POST",
    body: JSON.stringify(property),
  })
    .then((data) => {
      if (data.message === "Property Added") {
        if (hasValueInStorage(localStorage.getItem("noProp"))) {
          localStorage.removeItem("noProp");
        }
        openDialog(MessagePropAdded, {
          DialogProps: { fullScreen: false, scroll: "paper" },
        });
        setProperty(propertyIL);
      } else if (data.message === "No Valid Subscriptions") {
        openDialog(PurchasePlan, {
          DialogProps: {
            maxWidth: "md",
          },
        });
      } else if (data.message === "Error Adding Property") {
        openDialog(MessageWrongProp, {
          DialogProps: { fullScreen: false, scroll: "paper" },
          DialogContentProps: { text: t("24HoursErrMsg") },
        });
      } else {
        openDialog(MessageWrongProp, {
          DialogProps: { fullScreen: false, scroll: "paper" },
          DialogContentProps: { text: t("contactUsErrMsg") },
        });
      }
    })
    .catch(() => {
      openDialog(MessageWrongProp, {
        DialogProps: { fullScreen: false, scroll: "paper" },
      });
    })
    .finally(() => {
      queryParams.delete("address");
      queryParams.delete("place_id");
      console.log("window.locationsad :>> ", window.location);
    });
}
export default Payment;
export function propertyNotPurchased(api, property) {
  const propNotPurchased = { ...property };
  delete propNotPurchased.repurchase_id;
  doFetch(`/${api}/crm/property/not-purchased`, {
    method: "POST",
    body: JSON.stringify(propNotPurchased),
  }).catch((error) => console.error(error));
}
