import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useDialogManager } from "../../hooks/useDialogManager";
import { LoadingGif } from "../loading/LoadingGif";
import { useNotistack } from "../../hooks/useNotistack";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import { isAuthSignedin } from "../../services/helpers/auth";
import { PropdoButton } from "../buttons/PropdoButton";
import {
  FONT_TEXT,
  FONT_SUBTITLE,
} from "../../../comps/services/constants/fonts";
import { PricingPlan } from "../subscription/pricingPlans/PricingPlan";

const useStyles = makeStyles({
  root: {
    "& .MuiFormLabel-root": {
      color: "red", // or black
    },
  },
  input: {
    padding: "12.5px 14px",
  },
  label: {
    transform: "translate(14px,15.5px) scale(1)",
  },
});

export const LoginForm = ({ config }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [authorization, setAuthorization] = useContext(AuthorizationContext);
  const { closeDialog, openDialog } = useDialogManager();
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ defaultValues: { email: "", password: "" } });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const user = await Auth.signIn(data.email, data.password);
      setUserDetails({ ...data });
      setAuthorization(await isAuthSignedin());
    } catch (err) {
      if (err.toString().includes("NotAuthorizedException")) {
        setMsg({ variant: "error", text: t("errors.notAuthorizedException") });
      } else if (err.toString().includes("UserNotConfirmedException")) {
        setMsg({
          variant: "error",
          text: t("errors.userNotConfirmedException"),
        });
      } else if (err.toString().includes("UserNotFoundException")) {
        setMsg({ variant: "error", text: t("errors.userNotFoundException") });
      } else if (
        err.toString().includes("Password reset required for the user")
      ) {
        setMsg({ variant: "error", text: t("errors.passwordResetRequired") });
      }
      setAuthorization(await isAuthSignedin());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isSubmitSuccessful) return;
    reset({ email: "", password: "" });
  }, [isSubmitSuccessful, reset]);

  useNotistack(msg.text, msg, setMsg, msg.variant);

  return isLoading ? (
    <LoadingGif />
  ) : (
    <Grid container spacing={2} direction="column">
      {/* TODO: add username validation */}
      <Grid item xs={12}>
        <TextField
          id="email"
          type={"email"}
          label={t("login.email")}
          variant="outlined"
          inputProps={{ className: classes.input }}
          InputLabelProps={{ className: classes.label }}
          fullWidth
          {...register("email", { required: true })}
        />
        {errors.email && (
          <span style={{ color: "red", fontSize: FONT_TEXT, marginTop: 8 }}>
            {t("errors.must")}
          </span>
        )}
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="password"
          label={t("login.password")}
          type={"password"}
          variant="outlined"
          inputProps={{ className: classes.input }}
          InputLabelProps={{ className: classes.label }}
          fullWidth
          {...register("password", { required: true })}
        />
        {errors.password && (
          <span style={{ color: "red", fontSize: FONT_TEXT, marginTop: 8 }}>
            {t("errors.must")}
          </span>
        )}
      </Grid>
      <Grid item xs={12}>
        <PropdoButton
          text={t("login.submit")}
          style={{
            width: "100%",
            border: "none",
            padding: "8px 14px",
            fontSize: FONT_SUBTITLE,
          }}
          handleClick={handleSubmit(onSubmit)}
        />
      </Grid>
    </Grid>
  );
};
