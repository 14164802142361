import { useStylesLineChart } from "../../../styledComponent/LineChartStyled";
import { hexToRgbA } from "../../../services/helpers";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
});

const ClickableLegend = ({ legend, toggleShow, show }) => {
  const classes = useStyles();
  const classes2 = useStylesLineChart();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      {legend.map((item, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingBottom: 5,
            }}
            key={index}
          >
            <div
              className={classes2.divRow}
              onClick={() => {
                if (item.label !== `${t("yourProperty")}`) {
                  toggleShow(item.label);
                }
              }}
            >
              <button
                className={classes2.buttonRadioContainer}
                style={{
                  backgroundColor: hexToRgbA(item.color, 0.3),
                  cursor: item.label === t("labels.property") ? "default" : "",
                }}
              >
                <div
                  className={classes2.divRadioInside}
                  style={{
                    backgroundColor: hexToRgbA(item.color, 1),
                    display: show[index].show ? "block" : "none",
                  }}
                />
              </button>
            </div>
            <Typography style={{ margin: "0 10px" }}>{item.label}</Typography>
          </div>
        );
      })}
    </div>
  );
};
export default ClickableLegend;
