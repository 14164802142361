import React, { useContext, useMemo } from "react";
// @ts-ignore
import { Chart, Doughnut } from "react-chartjs-2";
import { addingComma, hexToRgbA } from "../services/helpers";
import { LoadingCharts } from "./layout/Layout";
import Grid from "@material-ui/core/Grid";
import * as chartjs from "chart.js";
import { DarkThemeContext } from "../context/DarkThemeContext";
import {
  darkElements,
  darkText,
  darkThemeNegativeRed,
  darkThemePositiveGreen,
  darkThemeSliderRail,
  greyAxis,
  negativeRedColor,
  positiveGreenColor,
} from "../services/colors";
import { useStylesTitles } from "../styledComponent/TilesStyled";
import { ANIMATION_DURATION } from "../services/consts";
import DemoExample from "./DemoExample";
import { useIsIncludes } from "common-components";
import TilesContainer from "./TilesContainer";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useTranslation } from "react-i18next";
import TitleGrid from "./TitleGrid";

Chart.pluginService.register({
  // @ts-ignore
  beforeDraw: function (chart) {
    if (chart.config.options.elements.center) {
      // Get ctx from string
      let ctx = chart.chart.ctx;
      // Get options from the center object in options
      let centerConfig = chart.config.options.elements.center;
      let fontStyle = centerConfig.fontStyle || "Open Sans, sans-serif";
      let fontSize = centerConfig.fontSize;
      let txt = centerConfig.text;
      let color = centerConfig.color || "#000";
      let maxFontSize = centerConfig.maxFontSize || 75;
      let sidePadding = centerConfig.sidePadding || 20;
      let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      // Start with a base font of 30px
      ctx.font = fontSize + " " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      let stringWidth = ctx.measureText(txt).width;
      let elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      let widthRatio = elementWidth / stringWidth;
      let newFontSize = Math.floor(30 * widthRatio);
      let elementHeight = chart.innerRadius * 2;

      // Pick a new font size so it will not be larger than the height of label.
      let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      let minFontSize = centerConfig.minFontSize;
      let lineHeight = centerConfig.lineHeight || 25;
      let wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      let centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      let words = txt.split(" ");
      let line = "";
      let lines = [];

      // Break words up into multiple lines if necessary
      for (let n = 0; n < words.length; n++) {
        let testLine = line + words[n] + " ";
        let metrics = ctx.measureText(testLine);
        let testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = words[n] + " ";
        } else {
          line = testLine;
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;

      for (let n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY);
    }
  },
});

//@ts-ignore
function getGradient(ctx, c1, c2, impact) {
  let colors = [];

  //@ts-ignore
  let gradientOne = ctx.createLinearGradient(0, 0, 0, 250);
  //@ts-ignore
  let gradientTwo = ctx.createLinearGradient(0, 0, 0, 100);
  //@ts-ignore
  gradientOne.addColorStop(0, c1);
  //@ts-ignore
  gradientOne.addColorStop(1, impact > 0 ? "#48a487" : "#e75463");
  //@ts-ignore
  gradientTwo.addColorStop(0, c2);
  //@ts-ignore
  gradientTwo.addColorStop(1, c2);

  colors = [gradientOne, gradientTwo];

  //@ts-ignore
  return colors;
}

function creatingData(impact: number, isDarkTheme: boolean) {
  const dataArr = impact > 0 ? [impact, 100 - impact] : [impact, 100 + impact];
  const mainPropColor =
    impact > 0 && isDarkTheme
      ? darkThemePositiveGreen
      : impact > 0 && !isDarkTheme
      ? positiveGreenColor
      : isDarkTheme
      ? darkThemeNegativeRed
      : negativeRedColor;
  const color2 = isDarkTheme ? darkThemeSliderRail : "rgba(0, 0, 0, 0.08)";

  const mainPropColorHover =
    impact > 0 && isDarkTheme
      ? hexToRgbA(darkThemePositiveGreen, 0.7)
      : impact > 0 && !isDarkTheme
      ? hexToRgbA(positiveGreenColor, 0.7)
      : isDarkTheme
      ? hexToRgbA(darkThemeNegativeRed, 0.7)
      : hexToRgbA(negativeRedColor, 0.7);

  return {
    labels: ["1", "2"],
    datasets: [
      {
        data: dataArr,
        //@ts-ignore
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          return getGradient(ctx, mainPropColor, color2, impact);
        },
        //@ts-ignore
        hoverBackgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          return getGradient(ctx, mainPropColorHover, color2, impact);
        },
        borderWidth: 2,
        hoverBorderColor: isDarkTheme ? darkElements : "#ffffff",
        hoverBorderWidth: 2,
        borderColor: isDarkTheme ? darkElements : "#fff",
      },
    ],
  };
}

interface SmallCardChangeProps {
  PD_SQM: number;
  PD_units: number;
  change_SQM: number;
  change_units: number;
  distance: string;
  impact: number;
  status: string;
  type: string;
}

const SmallCardChange: React.FC<{ data: SmallCardChangeProps | undefined }> = ({
  data,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();
  // @ts-ignore
  const chartJsOptions = useMemo<chartjs.ChartOptions>(() => {
    if (data) {
      return {
        elements: {
          center: {
            text: `${
              data.impact > 0
                ? `${t("SmallCardChange.chart.positiveInfluence")}`
                : `${t("SmallCardChange.chart.negativeInfluence")}`
            }`,
            color: isDarkTheme ? darkText : greyAxis, // Default is #000000
            fontStyle: "Open Sans Hebrew, sans-serif",
            fontSize: "30px",
            sidePadding: 20, // Default is 20 (as a percentage)
            minFontSize: 15, // Default is 20 (in px), set to false and text will not wrap.
            lineHeight: 20, // Default is 25 (in px), used for when text wraps
          },
        },
        legend: {
          display: false,
        },
        rotation: Math.PI / 2,
        maintainAspectRatio: false,
        animation: {
          duration: ANIMATION_DURATION,
        },
        plugins: {
          datalabels: false,
          labels: false,
        },
        cutoutPercentage: 90,
        tooltips: {
          enabled: false,
          rtl: true,
        },
      };
    } else {
      return {};
    }
  }, [data, isDarkTheme]);
  // @ts-ignore
  const chartJsData = useMemo<chartjs.ChartData>(() => {
    if (data) {
      return creatingData(data.impact, isDarkTheme);
    } else return {};
  }, [data, isDarkTheme]);
  const classes = useStylesTitles();
  const isDemo = useIsIncludes("demo");

  // rounded end of dataset using this code as basis https://jsfiddle.net/alonas/kx6rjy07/
  let roundedEnd = {
    // @ts-ignore
    afterUpdate: function (chart) {
      let a = chart.config.data.datasets.length - 1;
      for (let i in chart.config.data.datasets) {
        for (
          let j = chart.config.data.datasets[i].data.length - 1;
          j >= 0;
          --j
        ) {
          if (Number(j) == chart.config.data.datasets[i].data.length - 1)
            continue;
          let arc = chart.getDatasetMeta(i).data[j];
          arc.round = {
            x: (chart.chartArea.left + chart.chartArea.right) / 2,
            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
            radius:
              chart.innerRadius +
              chart.radiusLength / 2 +
              a * chart.radiusLength,
            thickness: (chart.radiusLength / 2 - 1) * 2.5,
            backgroundColor: arc._model.backgroundColor,
          };
        }
        a--;
      }
    },
    // @ts-ignore
    afterDraw: function (chart) {
      let ctx = chart.chart.ctx;
      for (let i in chart.config.data.datasets) {
        for (
          let j = chart.config.data.datasets[i].data.length - 1;
          j >= 0;
          --j
        ) {
          if (Number(j) == chart.config.data.datasets[i].data.length - 1)
            continue;
          let arc = chart.getDatasetMeta(i).data[j];
          let startAngle = Math.PI / 2 - arc._view.startAngle;
          let endAngle = Math.PI / 2 - arc._view.endAngle;

          ctx.save();
          ctx.translate(arc.round.x, arc.round.y);
          ctx.fillStyle = arc.round.backgroundColor;
          ctx.beginPath();
          //ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
          ctx.arc(
            arc.round.radius * Math.sin(endAngle),
            arc.round.radius * Math.cos(endAngle),
            arc.round.thickness,
            0,
            2 * Math.PI
          );
          ctx.closePath();
          ctx.fill();
          ctx.restore();
        }
      }
    },
  };

  if (data) {
    // @ts-ignore
    return (
      <TilesContainer relative>
        {isDemo && <DemoExample />}
        <Grid container>
          <TitleGrid
            title={t("SmallCardChange.title")}
            tooltipText={t("SmallCardChange.tooltip")}
          />

          <Grid
            container
            item
            xs={12}
            style={{ padding: "15px 0" }}
            justify={"flex-start"}
            alignItems={"center"}
          >
            <Grid item xs={12} style={{ paddingBottom: 24 }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {dataImpact(data, t).map((item) => {
                      return (
                        <TableRow key={item.cell}>
                          <TableCell
                            className={classes.tableCell}
                            style={{ paddingRight: 0 }}
                          >
                            {item.cell}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.data}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Doughnut
                data={chartJsData}
                options={chartJsOptions}
                plugins={[roundedEnd]}
              />
            </Grid>
          </Grid>
        </Grid>
      </TilesContainer>
    );
  } else {
    return (
      <TilesContainer style={{ height: "100%" }}>
        <LoadingCharts
          name={t("SmallCardChange.title")}
          variant={"h5"}
          height={"5%"}
          minHeight={"0px"}
          padding={"70px 50px 0 50px"}
        />
      </TilesContainer>
    );
  }
};
export default SmallCardChange;

function dataImpact(data: any, t: any) {
  return [
    { cell: t("SmallCardChange.type"), data: data.type },
    { cell: t("SmallCardChange.distance"), data: data.distance },
    {
      cell: t("SmallCardChange.changeMeters"),
      data: data.change_SQM === 0 ? "--" : addingComma(data.change_SQM),
    },
    {
      cell: t("SmallCardChange.changeUnits"),
      data: data.change_units === 0 ? "--" : addingComma(data.change_units),
    },
    {
      cell: t("SmallCardChange.changeMeters%"),
      data: data.PD_SQM === 0 ? "--" : data.PD_SQM + "%",
    },
    {
      cell: t("SmallCardChange.changeUnits%"),
      data: data.PD_units === 0 ? "--" : data.PD_units + "%",
    },
    { cell: t("SmallCardChange.status"), data: data.status },
  ];
}
