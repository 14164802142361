import React, { useContext } from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { IMAGES_LINK } from "common-components";
import { LightTooltip } from "./LightTooltip";

interface TooltipInfoIcon {
  width?: string | number;
  padding?: string | number;
  tooltipText?: string;
  question?: boolean;
  interactive?: boolean;
  isDarkTheme?: boolean;
}
const TooltipInfoIcon: React.FC<TooltipInfoIcon> = ({
  width,
  padding,
  tooltipText,
  question,
  interactive,
  isDarkTheme,
}) => {
  return (
    <LightTooltip
      isDarkTheme={isDarkTheme}
      title={tooltipText}
      arrow
      enterTouchDelay={50}
      interactive={interactive}
    >
      {question ? (
        <HelpOutlineIcon
          style={{ padding: "0 5px", cursor: "pointer", color: "#b5b5b5" }}
        />
      ) : (
        <img
          src={`${IMAGES_LINK}/dashboard/elements${
            isDarkTheme ? "/infoDark" : "/infoLight"
          }.svg`}
          alt="info"
          style={{
            cursor: "pointer",
            width: width ? width : "16px",
            padding: padding ? padding : 0,
            zIndex: 10,
            margin: 4,
          }}
        />
      )}
    </LightTooltip>
  );
};
export default TooltipInfoIcon;
