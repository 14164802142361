import React, { createContext, useEffect, useState } from "react";
import { isAuthSignedin } from "../services/helpers/auth";

export const AuthorizationContext = createContext<any>({});

export const AuthorizationProvider: React.FC = ({ children }) => {
  const [authorization, setAuthorization] = useState(false);

  useEffect(() => {
    (async () => {
      setAuthorization(await isAuthSignedin());
    })();
  }, []);

  return (
    <AuthorizationContext.Provider value={[authorization, setAuthorization]}>
      {children}
    </AuthorizationContext.Provider>
  );
};
