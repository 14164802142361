import React, { useContext, useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { darkBorderColor, darkText, lightBoxes } from "../../services/colors";
import TableAskingPrice from "./cells/TableAskingPrice";
import TableAskingNickName from "./cells/TableAskingNickName";
import TableNote from "./cells/TableNote";
import { addingComma } from "../../services/helpers";
import RepurchaseProp from "./cells/RepurchaseProp";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PROPERTY_TYPE_PRIVATE_HOUSE } from "../../dialogs/add-prop/SelectPropDetails";
import { XS_PLUS_FONT } from "../../services/fonts";
import PriceSold from "./cells/PriceSold";
import ShareButton from "./cells/ShareButton";
import TableButtonViewProp from "./cells/TableButtonViewProp";
import TableOpenIcon from "./cells/TableOpenIcon";
import ApartmentNumber from "./cells/ApartmentNumber";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";
import Typography from "@material-ui/core/Typography";

export const LightTooltip = withStyles((theme) => ({
  arrow: (props) => ({
    color: props.isDarkTheme ? darkBorderColor : lightBoxes,
  }),
  tooltip: (props) => ({
    padding: 10,
    backgroundColor: props.isDarkTheme ? darkBorderColor : lightBoxes,
    color: props.isDarkTheme ? darkText : "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: XS_PLUS_FONT,
  }),
}))((props) => <Tooltip {...props} />);

const TableReportsBody = ({
  columns,
  row,
  open,
  indexRow,
  setOpen,
  addresses,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [isSold, setIsSold] = useState(0);

  useEffect(() => {
    setIsSold(row.sold);
  }, [row]);
  const { t } = useTranslation();

  return (
    <TableRow
      hover
      tabIndex={-1}
      style={{
        transition: "all 0.5 ease",
      }}
    >
      {columns.map((column, index) => {
        let value = row[column.id];
        if (!column.hidden && !column.premium) {
          return (
            <TableCell
              style={{
                fontFamily: "Open Sans Hebrew, sans-serif",
                maxWidth: column.id === "open" ? 10 : "",
              }}
              key={`${column.id}`}
            >
              {column.id === "id" && column.active ? (
                <TableButtonViewProp row={row} />
              ) : column.id === "id" && !column.active ? (
                <RepurchaseProp data={addresses.properties[indexRow]} />
              ) : column.id === "share" ? (
                <ShareButton id={row.id} />
              ) : column.id === "area" ? (
                ` ${value} מ"ר `
              ) : column.id === "open" ? (
                <TableOpenIcon row={row} setOpen={setOpen} open={open} />
              ) : column.id === "floor" ? (
                `${
                  value === 0 && row.propType === PROPERTY_TYPE_PRIVATE_HOUSE()
                    ? "NA"
                    : value === ""
                    ? "ללא"
                    : value
                }`
              ) : column.id === "apartment" && column.active ? (
                <ApartmentNumber row={row} value={value} />
              ) : column.id === "type" ? (
                `${t(`PropertyTypes.${value}`)}`
              ) : (
                `${value}`
              )}
            </TableCell>
          );
        } else if (!column.hidden && column.premium && isDarkTheme) {
          return (
            <TableCell
              style={{
                fontFamily: "Open Sans Hebrew, sans-serif",
                maxWidth: column.id === "open" ? 10 : "",
              }}
              key={`${column.id}`}
            >
              {column.id === "price" ? (
                <TableAskingPrice value={value} row={row} />
              ) : column.id === "nickname" ? (
                <TableAskingNickName row={row} value={value} />
              ) : column.id === "note" ? (
                <TableNote value={value} row={row} />
              ) : column.id === "cv" ? (
               <Typography noWrap style={{fontSize:XS_PLUS_FONT}}>{`${addingComma(value)} ₪`}</Typography>
              ) : column.id === "sold" ? (
                <PriceSold value={value} row={row} />
              ) : (
                `${value}`
              )}
            </TableCell>
          );
        } else {
          return null;
        }
      })}
    </TableRow>
  );
};
export default TableReportsBody;
