import React, { useContext, useMemo, useRef, useState } from "react";
import { Radar } from "react-chartjs-2";
import { CustomTooltip, useCustomTooltip } from "./CustomTooltip";
import { CustomLegend } from "./CustomLegend";
import { RadarChartInterface } from "../data/DataRadarChart";
import { DarkThemeContext } from "../context/DarkThemeContext";
import {
  darkBackground,
  darkText,
  darkThemeGridLines,
  greyAxis,
} from "../services/colors";
import { ANIMATION_DURATION } from "../services/consts";
import Typography from "@material-ui/core/Typography";
import { FONT } from "../services/fonts";
import {
  disableScrolling,
  enableScrolling,
} from "./mortgage/MortgageLineChart";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { IMAGES_LINK } from "common-components";
import TitleGrid from "../components/TitleGrid";

const chartPoint = new Image();
chartPoint.src = `${IMAGES_LINK}/dashboard/elements/location-pin-scatter.svg`;

const RadarChart: React.FC<{
  data: RadarChartInterface;
  legend: { color: string; label: string }[];
}> = ({ data, legend }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const chartRef = useRef<any>();
  const [, setCurrentIndex] = useState();
  const [datasetIndex, setDatasetIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex,
    setDatasetIndex
  );

  const chartJsOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scale: {
        angleLines: {
          display: false,
        },
        gridLines: {
          circular: true,
          color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          display: false,
        },
        pointLabels: {
          fontColor: isDarkTheme ? darkText : greyAxis,
          fontSize: FONT,
          fontFamily: "Open Sans Hebrew, sans-serif",
        },
      },

      plugins: {
        datalabels: {
          display: false,
        },
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
      },
      animation: {
        duration: ANIMATION_DURATION,
      },
      tooltips: {
        enabled: false,
        rtl: true,
        custom: customTooltip,
      },
    };
  }, [isDarkTheme]);

  const { t } = useTranslation();
  return (
    <Grid container>
      <TitleGrid
        title={t("RadarChart.title")}
        tooltipText={t("RadarChart.tooltip")}
      />

      <Grid item xs={12}>
        <CustomLegend legend={legend} useShapes />
        <div
          style={{
            width: "100%",
            minHeight: 300,
          }}
          onTouchStart={() => {
            disableScrolling();
          }}
          onTouchEnd={() => {
            enableScrolling();
          }}
        >
          <Radar ref={chartRef} data={data} options={chartJsOptions} />
        </div>
        <CustomTooltip
          tooltipRef={tooltipRef}
          left={position.left}
          top={position.top}
          id="radar"
          place="top"
        >
          <TooltipInner
            datasetIndex={datasetIndex}
            data={data}
            isDarkTheme={isDarkTheme}
          />
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};
const TooltipInner: React.FC<{
  data: RadarChartInterface;
  datasetIndex: undefined | number[];
  isDarkTheme: boolean;
}> = ({ datasetIndex, data, isDarkTheme }) => {
  let indexDistance = -1;
  const { t } = useTranslation();
  if (datasetIndex && data) {
    if (datasetIndex[0] === 0 && datasetIndex[1] === 0) indexDistance = 9999;
    else
      indexDistance = data.datasets[datasetIndex[1]].places.indexOf(
        datasetIndex[0]
      );
  }

  if (datasetIndex && data && indexDistance >= 0) {
    return (
      <div
        style={{
          maxWidth: 200,
          padding: 0,
          backgroundColor: isDarkTheme ? darkBackground : "#fff",
          opacity: "1 !important",
          boxShadow: "0 3px 15px 2px hsla(0,0%,53.3%,.3)",
          border: "none !important",
          transition: "all 0.5 ease !important",
          zIndex: 2500,
          pointerEvents: "none",
          borderRadius: "var(--border-radius)",
        }}
      >
        <div
          style={{
            padding: 15,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography style={{ color: isDarkTheme ? darkText : greyAxis }}>
            {indexDistance === 9999 ? (
              <bdi>{t("RadarChart.myProperty")}</bdi>
            ) : (
              <>
                {data.datasets[datasetIndex[1]].label + " "}
                <bdi>{t("RadarChart.distance")}</bdi>
                <br />
                <bdi>
                  {data.datasets[datasetIndex[1]].distance[indexDistance]}
                </bdi>
                <bdi style={{ color: "transparent", visibility: "hidden" }}>
                  i
                </bdi>
                <bdi>{t("RadarChart.fromProperty")}</bdi>.
              </>
            )}
          </Typography>
        </div>
      </div>
    );
  } else return null;
};
export default RadarChart;
