import React, { useContext } from "react";
import { useIsMobileScreen } from "common-components";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesSelectPropDetails } from "../../styledComponent/SelectPropDetailsStyled";
import { darkMainPropColor, mainPropColor } from "../../services/colors";
import { UserDetailsContext } from "../../context/UserDetailsContext";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import PurchasePlan from "../pricing/PurchasePlan";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const PlanTypePayment = ({ id }) => {
  const [userDetails] = useContext(UserDetailsContext);
  const isMobileScreen = useIsMobileScreen();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStylesSelectPropDetails({ isDarkTheme });
  const { t } = useTranslation();

  const { closeDialog, openDialog } = useDialogManager();

  const handleEditPlan = () => {
    closeDialog();
    setTimeout(
      openDialog(PurchasePlan, {
        DialogContentProps: { popup: true },
        DialogProps: {
          maxWidth: "md",
        },
      }),
      200
    );
  };

  return (
    <>
      <Typography className={classes.pTitlePlanType}>
        {t("PlanTypePayment.title")}
      </Typography>
      <div>
        <div
          style={
            isMobileScreen
              ? { paddingBottom: 10 }
              : { padding: "0px 0px 10px 10px" }
          }
        >
          <div
            className={id && classes.divColumn}
            style={{
              display: !id && "flex",
              alignItems: !id && "flex-start",
              flexDirection: !id && "column",
            }}
          >
            {userDetails && userDetails.is_premium ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  paddingBottom: 13,
                  paddingRight: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography className={classes.pHeadingPlanType}>
                    <bdi>{t("PlanTypePayment.subscriptionInfoMsg1")}</bdi>
                    &thinsp;{sessionStorage.getItem("amount")}&thinsp;
                    <bdi>{t("PlanTypePayment.subscriptionInfoMsg2")}</bdi>
                  </Typography>
                  <Typography className={classes.pHeadingPlanType}>
                    {t("PlanTypePayment.subscriptionInfoMsg3")}
                  </Typography>
                </div>
                <EditOutlinedIcon
                  style={{
                    cursor: "pointer",
                    color: isDarkTheme ? darkMainPropColor : mainPropColor,
                    marginLeft: 24,
                  }}
                  onClick={handleEditPlan}
                />
              </div>
            ) : sessionStorage.getItem("amount") === "1" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  paddingBottom: 13,
                  paddingRight: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    className={classes.pHeadingPlanType}
                    style={{ paddingRight: 5 }}
                  >
                    {t("PlanTypePayment.onTimeMsg1")}
                  </Typography>
                  <Typography
                    className={classes.pHeadingPlanType}
                    style={{ paddingRight: 5 }}
                  >
                    {t("PlanTypePayment.onTimeMsg2")}
                  </Typography>
                </div>
                <EditOutlinedIcon
                  style={{
                    cursor: "pointer",
                    color: isDarkTheme ? darkMainPropColor : mainPropColor,
                    marginLeft: 24,
                  }}
                  onClick={handleEditPlan}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  paddingBottom: 13,
                  paddingRight: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography className={classes.pHeadingPlanType}>
                    <bdi>{t("PlanTypePayment.onTimeWithPropertiesMsg1")}</bdi>
                    &thinsp;{sessionStorage.getItem("amount")}&thinsp;
                    <bdi>{t("PlanTypePayment.onTimeWithPropertiesMsg2")} </bdi>
                  </Typography>
                  <Typography
                    className={classes.pHeadingPlanType}
                    style={{ paddingRight: 5 }}
                  >
                    {t("PlanTypePayment.onTimeWithPropertiesMsg3")}
                  </Typography>
                </div>
                <EditOutlinedIcon
                  style={{
                    cursor: "pointer",
                    color: mainPropColor,
                    marginLeft: 24,
                  }}
                  onClick={handleEditPlan}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PlanTypePayment;
