import Grid from "@material-ui/core/Grid";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useStylesTama } from "../../../styledComponent/TamaStyled";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import Typography from "@material-ui/core/Typography";
import {
  renovationDropdownDetains,
  renovationDropdownOptions,
  renovationMonth,
} from "../../../services/consts";
import { useLazyDataApi } from "common-components";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { OpenAccessContext } from "../../../context/OpenAccessContext";
import { InputLabel } from "@material-ui/core";
import { useStylesAddProp } from "../../../styledComponent/AddPropStyled";
import SubmitAndClearButtons from "../../SubmitAndClearButtons";
import { XS_FONT } from "../../../services/fonts";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import TitleGrid from "../../TitleGrid";
import { DirectionContext } from "../../../context/DirectionContext";

function callFetch(api, lazyFetch, isOpenAccess, propertyId, values) {
  lazyFetch(
    `/reports/dashboard/page/renovations/update?id=${
      isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
    }`,
    {
      method: "POST",
      body: JSON.stringify({
        num_months: values
          ? Number(values.num_months) === 0
            ? ""
            : Number(values.num_months)
          : "",
        renovations_levels: values
          ? [
              values.kitchen,
              values.livingRoom,
              values.showerToilet,
              values.otherRooms,
            ]
          : ["without", "without", "without", "without"],
      }),
    }
  );
}

const RenovationsDetails = ({ renovations_details, setRawData }) => {
  const [direction] = useContext(DirectionContext);
  const { t } = useTranslation();
  const [propertyId] = useContext(PropertyIdContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [errorType, setErrorType] = useState(undefined);
  const [
    { data: lazyData, error: lazyError, isLoading },
    lazyFetch,
  ] = useLazyDataApi();
  const defaultValues = useMemo(() => {
    return {
      kitchen: renovations_details.levels[0],
      livingRoom: renovations_details.levels[1],
      showerToilet: renovations_details.levels[2],
      otherRooms: renovations_details.levels[3],
      num_months: !renovations_details.num_months
        ? 0
        : renovations_details.num_months,
    };
  }, [renovations_details]);
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues,
  });

  const props = { isDarkTheme };
  const classesSelect = useStylesTama(props);
  const numMonths = watch("num_months");
  const kitchen = watch("kitchen");
  const livingRoom = watch("livingRoom");
  const showerToilet = watch("showerToilet");
  const otherRooms = watch("otherRooms");

  const onSubmit = (values) => {
    setErrorType(undefined);
    if (
      kitchen === "without" &&
      livingRoom === "without" &&
      showerToilet === "without" &&
      otherRooms === "without"
    ) {
      setErrorType("renovationType");
      return;
    }
    if (!numMonths) {
      setErrorType("numMonths");
      return;
    }
    callFetch(direction.api, lazyFetch, isOpenAccess, propertyId, values);
  };

  const onClearBtn = () => {
    setErrorType(undefined);
    callFetch(direction.api, lazyFetch, isOpenAccess, propertyId);

    reset({
      kitchen: "without",
      livingRoom: "without",
      showerToilet: "without",
      otherRooms: "without",
      num_months: 0,
    });
  };

  useEffect(() => {
    if (lazyData) {
      setRawData(lazyData);
    }
  }, [lazyData]);

  const handleChange = () => {
    setErrorType(undefined);
  };

  useNotistack(`${t("updateSucceedMsg")}`, lazyData, undefined, "success");
  useNotistack(`${t("updateFailedMsg")}`, lazyError, undefined, "error");

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: "100%" }}
    >
      <Grid container spacing={3} style={{ height: "100%" }}>
        <TitleGrid title={t("Renovations.formTitle")} />

        {renovationDropdownDetains(t).map((item, index) => {
          const months = item.name === "num_months";
          return (
            <Grid item xs={6}>
              <RenovationDropdowns
                key={index}
                name={item.name}
                title={item.title}
                defaultValue={defaultValues[item.name]}
                control={control}
                classes={classesSelect.select}
                dropdown={
                  months ? renovationMonth : renovationDropdownOptions(t)
                }
                handleChange={() => handleChange()}
                disabled={isLoading}
              />
            </Grid>
          );
        })}

        <Typography
          style={{
            color: "red",
            fontSize: XS_FONT,
            height: XS_FONT,
            whiteSpace: "nowrap",
            paddingBottom: 10,
          }}
        >
          {errorType === "renovationType"
            ? `${t("Renovations.renovationTypeError")}`
            : errorType === "numMonths"
            ? `${t("Renovations.numMonthsError")}`
            : ""}
        </Typography>

        <Grid container item xs={12} style={{ alignSelf: "flex-end" }}>
          <SubmitAndClearButtons
            isLoading={isLoading}
            onClearBtn={onClearBtn}
            submitTitle={t("Renovations.button")}
            disabled={isLoading}
            onClickBtn={handleSubmit(onSubmit)}
          />
        </Grid>
      </Grid>
    </form>
  );
};
export default RenovationsDetails;

const RenovationDropdowns = ({
  control,
  name,
  title,
  classes,
  defaultValue,
  dropdown,
  handleChange,
  disabled,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classesLab = useStylesAddProp({ isDarkTheme });
  return (
    <>
      <InputLabel
        style={{ whiteSpace: "nowrap" }}
        className={classesLab.inputLabelSearch}
      >
        {title}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        id={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <Select
            defaultValue={defaultValue}
            value={value}
            id={name}
            name={name}
            labelId={name}
            variant="outlined"
            disabled={disabled}
            style={{
              width: "100%",
            }}
            onChange={(value) => {
              onChange(value);
              handleChange();
            }}
          >
            {dropdown.map((item) => {
              return (
                <MenuItem className={classes} value={item.value}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </>
  );
};
