import React, { useContext, useState } from "react";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { useStylesUserLogin } from "../../../styledComponent/UserLoginStyled";
import { useStylesForecast } from "../../../styledComponent/ForecastStyled";
import Typography from "@material-ui/core/Typography";
import TopBarSearch from "../../top-bar/TopBarSearch";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { OpenAccessContext } from "../../../context/OpenAccessContext";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { IMAGES_LINK, LANDING_PAGE_LINK } from "common-components";
import { Routes, RoutesUS } from "../../../services/routes";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";
import { useIsIncludes } from "common-components";

const MessageNoProp = () => {
  const [direction] = useContext(DirectionContext);
  const [, setOpenAccess] = useContext(OpenAccessContext);
  const [, setPropertyId] = useContext(PropertyIdContext);
  const [isAddressSwitch, setIsAddressSwitch] = useState(true);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes2 = useStylesUserLogin({ isDarkTheme });
  const classesForecast = useStylesForecast({ isDarkTheme });
  const history = useHistory();
  const { t } = useTranslation();
  const isShare = useIsIncludes("share");
  const isShareId = history.location.search.includes("S");

  const handleGoToDemo = () => {
    setTimeout(() => {
      history.push(direction.isRtl ? Routes.demoSummary : RoutesUS.demoSummary);
      window.scrollTo(0, 0);
      setOpenAccess({
        access: true,
        path: "property/demo",
        page: "demo",
      });
      setPropertyId({ id: "-1" });
    }, 700);
  };

  const handleGoToHomepage = () => {
    window.location.replace(LANDING_PAGE_LINK);
  };

  return (
    <div
      className={classes2.divRootContainer}
      style={{ maxWidth: 400, padding: "20px 35px" }}
    >
      <div className={classesForecast.divPopupContainerIcon}>
        <img
          src={`${IMAGES_LINK}/dashboard/elements/home.svg`}
          alt="added"
          style={{ width: 55, height: 55 }}
        />
      </div>
      <div>
        <Typography
          variant={"h5"}
          align={"center"}
          style={{ margin: "30px 0 5px 0" }}
        >
          {isShare && isShareId ? t("linkBroken") : t("noPropertyDisplay")}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <TopBarSearch
          popup
          btnColor={"primary"}
          isAddressSwitch={isAddressSwitch}
          setIsAddressSwitch={setIsAddressSwitch}
        />
      </div>
      <div
        style={{
          paddingTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Button color={"primary"} onClick={handleGoToDemo}>
          {`${t("Tabs.takeMeToDemo")}` + " >> "}
        </Button>

        <Button color={"primary"} onClick={handleGoToHomepage}>
          {`${t("Tabs.homePage")}` + " >> "}
        </Button>
      </div>
    </div>
  );
};
export default MessageNoProp;
