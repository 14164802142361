import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import customerImg from "./customer.png";
import prts from "./prts.png";
import { LG_PLUS_FONT } from "../../global-il/services/fonts";
import { useTranslation } from "react-i18next";
import { useIsMobileScreen } from "../../global-il/hooks/useIsMobileScreen.ts";

const useStyles = makeStyles({
  cardContainer: (props) => ({
    maxWidth: props.isMobileScreen ? 250 : 400,
    maxHeight: 260,
    marginTop: "100px",
    borderTopWidth: 10,
    borderTop: "solid",
    borderColor: "#1B3791",
  }),
  image: {
    width: 120,
    height: 120,
    borderRadius: "50%",
    transform: "TranslateY(-88%)",
    position: "absolute",
    boxShadow: "0 0 0 4px #1B3791",
  },
  container: {
    padding: "40px 0",
    borderBottom: "1px solid rgba(0,0,0,0.07)",
  },
});

const customers = [
  {
    img: customerImg,
    name: "Dov",
    title: "Data Team Lead",
    text: `Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.`,
  },
  {
    img: customerImg,
    name: "David",
    title: "CEO, Co-founder",
    text:
      "Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.",
  },
  {
    img: customerImg,
    name: "Josh",
    title: "Product Manager",
    text:
      "Lobortis leo pretium facilisis amet nisl at nec. Scelerisque risus tortor donec ipsum consequat semper consequat adipiscing ultrices.",
  },
];

export const Testimonials = () => {
  const { t } = useTranslation();
  const isMobileScreen = useIsMobileScreen();
  const props = { isMobileScreen };
  const classes = useStyles(props);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      // className="main-container padding"
      style={{ padding: "65px 0px" }}
      id="testimonials"
    >
      <Typography
        style={{
          fontSize: LG_PLUS_FONT,
          fontWeight: 600,
          padding: 12,
          textDecoration: "underline",
          textDecorationColor: "pink",
          textUnderlineOffset: 6,
        }}
      >
        {t("testimonial.title")}
      </Typography>
      <Grid container justifyContent="center">
        {customers.map((item, idx) => {
          return (
            <Grid
              container
              item
              xs={12}
              md={4}
              key={idx}
              align={"center"}
              justifyContent={"center"}
              // style={{ paddingBottom: "40px" }}
            >
              <Card className={classes.cardContainer}>
                <Grid item xs={10} className={classes.container}>
                  <Grid container item justifyContent="center">
                    <img src={item.img} alt="" className={classes.image} />
                  </Grid>
                  <CardContent
                    style={{ marginTop: "10px" }}
                    className={classes.cardContent}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="center"
                      style={{ fontSize: "20px", color: "#32D3D3" }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      gutterBottom
                      component="h2"
                      align="center"
                      style={{ fontSize: "12px" }}
                    >
                      {item.title}
                    </Typography>
                    <Grid
                      style={{
                        backgroundImage: `url(${prts})`,
                        height: "20px",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    <Typography
                      align="center"
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        fontSize: "14px",
                        // marginTop: "20px"
                      }}
                    >
                      {item.text}
                    </Typography>
                    <Grid
                      style={{
                        backgroundImage: `url(${prts})`,
                        height: "12px",
                        backgroundRepeat: "no-repeat",
                        // marginTop: "10px",
                        marginLeft: "270px",
                      }}
                    ></Grid>
                  </CardContent>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
