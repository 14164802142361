import React, { useContext } from "react";
import { doFetch } from "common-components";
import { DataAddressesContext } from "../../../context/DataAddressesContext";
import { addingComma } from "../../../services/helpers";
import EditCell from "./EditCell";

const TableAskingPrice = ({ row, value }) => {
  const [addresses, setAddresses] = useContext(DataAddressesContext);
  const handleSavePrice = (
    setIsSaved,
    setLoading,
    setIsChangePrice,
    askingPrice
  ) => {
    setLoading(true);
    doFetch(`/reports/premium/property/update?id=${row.id}`, {
      method: "POST",
      body: JSON.stringify({
        asking_price: Number(askingPrice),
      }),
    }).then((data) => {
      setLoading(false);
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
        setIsChangePrice(false);
      }, 1500);
    });
    row.price = askingPrice;
    const newAddresses = { ...addresses.active };
    newAddresses.properties[row.index].premium.asking_price = askingPrice;
    setAddresses((prevState) => ({ ...prevState, active: newAddresses }));
  };

  return (
    <EditCell
      value={value}
      handleSaveValue={handleSavePrice}
      mask={`${addingComma(value)} ₪`}
      justDigits
    />
  );
};
export default TableAskingPrice;
