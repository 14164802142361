import Grid from "@material-ui/core/Grid";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ToggleSlider } from "common-components";
import { labelData } from "../tama/TamaLineChart";
import { darkFiveColors, fiveColors } from "../../../services/colors";
import { CustomLegend } from "../../../charts/CustomLegend";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { hexToRgbA } from "../../../services/helpers";
import { Pie } from "react-chartjs-2";
import { ANIMATION_DURATION } from "../../../services/consts";
import { FONT } from "../../../services/fonts";
import { useCustomTooltip } from "../../../charts/CustomTooltip";
import { useIsMobileScreen } from "common-components";
import {
  disableScrolling,
  enableScrolling,
} from "../../../charts/mortgage/MortgageLineChart";
import { useTranslation } from "react-i18next";
import ChartTooltip from "../../../charts/chartTooltip/ChartTooltip";
import TitleGrid from "../../TitleGrid";

function normaliseData(data, comparison, year, isDarkTheme) {
  return {
    labels: [...data[comparison][year].labels],
    datasets: [
      {
        label: [...data[comparison][year].labels],
        backgroundColor: isDarkTheme ? darkFiveColors : fiveColors,
        borderColor: isDarkTheme ? darkFiveColors : fiveColors,
        borderWidth: 0,
        hoverBackgroundColor: isDarkTheme
          ? darkFiveColors.map((color) => hexToRgbA(color, 0.7))
          : fiveColors.map((color) => hexToRgbA(color, 0.7)),
        hoverBorderColor: isDarkTheme
          ? darkFiveColors.map((color) => hexToRgbA(color, 0.7))
          : fiveColors.map((color) => hexToRgbA(color, 0.7)),
        data: [...data[comparison][year].series],
      },
    ],
  };
}

const ComparisonsPie = ({ data }) => {
  const keys = Object.keys(data.israel);
  const disabledSurr = useMemo(() => {
    return (
      data.surroundings[keys[0]].series.length === 0 &&
      data.surroundings[keys[1]].series.length === 0 &&
      data.surroundings[keys[2]].series.length === 0
    );
  }, [data]);

  const [isDarkTheme] = useContext(DarkThemeContext);
  // TODO check that the correct country or area is use in this useState
  const [comparison, setComparison] = useState("israel");
  const { t } = useTranslation();
  const [year, setYear] = useState(keys[0]);
  const [pieData, setPieData] = useState(
    normaliseData(data, comparison, year, isDarkTheme)
  );

  const chartRef = useRef();
  const [index, setCurrentIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );
  const isMobileScreen = useIsMobileScreen();

  useEffect(() => {
    setPieData(normaliseData(data, comparison, year, isDarkTheme));
  }, [year, comparison]);

  const list = keys.map((item, index) => {
    return {
      value:
        data[comparison][keys[index]].series.length === 0 ? "disabled" : item,
      text: `${t("yearContraction")} ${item}`,
    };
  });

  const totalVal = useMemo(() => {
    //only 1 dataset exist for this chart
    //calculate sum:
    return pieData.datasets[0].data.reduce(
      (accumulator, currentValue) => accumulator + currentValue
    );
  }, [pieData]);

  const percentageDonut = useMemo(() => {
    return function (value) {
      return parseFloat(((value / totalVal) * 100).toFixed(0)).toString() + "%";
    };
  }, []);

  const chartJsOptions = useMemo(() => {
    return {
      // maintainAspectRatio: false,
      animation: {
        duration: ANIMATION_DURATION,
      },
      legend: {
        display: false,
      },
      plugins: {
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
        datalabels: {
          color: "#FFF",
          formatter: percentageDonut,
          font: {
            size: isMobileScreen ? 12 : FONT,
            family: "Open Sans Hebrew, sans-serif",
          },
          anchor: "center",
          textAlign: "center",
        },
      },
      tooltips: {
        enabled: false,
        custom: customTooltip,
      },
    };
  }, [isMobileScreen]);

  const legends = useMemo(() => {
    return labelData(
      t,
      data[comparison][year],
      isDarkTheme,
      fiveColors,
      darkFiveColors,
      "labels"
    ).map((item) => {
      return {
        color: item.color,
        label: /^\d+$/.test(item.label)
          ? item.label + `${t("roomsContraction")}`
          : item.label,
      };
    });
  }, []);

  return (
    <Grid item xs={12} container>
      <TitleGrid
        title={t("ComparisonsPie.title")}
        tooltipText={t("ComparisonsPie.tooltip")}
      />

      <Grid container item xs={12} justify={"space-between"} spacing={2}>
        <Grid item>
          <ToggleSlider radius value={year} setValue={setYear} list={list} />
        </Grid>
        <Grid item>
          <ToggleSlider
            radius
            value={comparison}
            setValue={setComparison}
            isDarkTheme={isDarkTheme}
            list={[
              {
                value: `${t("countryValue")}`,
                text: `${t("countryText")}`,
              },
              {
                value: disabledSurr ? "disabled" : "surroundings",
                text: `${t("surroundingsText")}`,
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomLegend legend={legends} />
      </Grid>
      <Grid item xs={12}>
        <div
          onTouchStart={() => {
            disableScrolling();
          }}
          onTouchEnd={() => {
            enableScrolling();
          }}
        >
          <Pie ref={chartRef} data={pieData} options={chartJsOptions} />
        </div>
        <ChartTooltip
          data={pieData}
          title={{ title: t("ComparisonsPie.totalTransactions") }}
          position={position}
          id={"pieComparative"}
          index={index}
          tooltipRef={tooltipRef}
          tooltipPercent={[
            t("ComparisonsPie.about"),
            t("ComparisonsPie.rooms"),
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default ComparisonsPie;
