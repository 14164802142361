import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const PricingDropdown: React.FC<{
  products: string[];
  product: string;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
}> = ({ products, product, setProduct }) => {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProduct(event.target.value);
  };

  return (
    <Grid container item xs={12}>
      <TextField
        id="outlined-select-product"
        select
        value={product}
        onChange={handleChange}
        variant="standard"
      >
        {products.map((option, idx) => (
          <MenuItem key={idx} value={option}>
            {t(`productSearch.${option}.title`)}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};
