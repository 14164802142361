import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { IMAGES_LINK, REPORTS_EXAMPLE } from "../../../global/api/api_links";
import { blue_1, orange_2, white } from "../../../global/services/colors";
import { FOOTER } from "../../services/constants/footer";
import {
  FONT_SUBTITLE,
  FONT_TITLE,
} from "../../../global/services/constants/fonts";
import { SendEmail } from "../Subscription/Subscription";
import { useIsLargeScreen } from "../../../global/hooks/useIsLargeScreen";
import { useDialogManager } from "../../../global/hooks/useDialogManager";
import { Login } from "../../../global/components/login/Login";
import { useContext } from "react";
import { AuthorizationContext } from "../../../global/context/AuthorizationContext";
import { HashLink } from "react-router-hash-link";
import { PricingPlan } from "../../../global/components/subscription/pricingPlans/PricingPlan";
import { useDirection } from "../../../global/context/DirectionContext";
import { ContactForm } from "../../../global/components/contactForm/ContactForm";
import { FONT_TEXT } from "../../../comps/services/constants/fonts";
import PropdoTermsAndConditions from "../LegelDocuments/PropdoTermsAndConditions";
import { PropdoPrivacyPolicy } from "../LegelDocuments/PropdoPrivacyPolicy";

export const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isDesktopScreen = useIsLargeScreen("md");
  const logo = IMAGES_LINK + `/homepage/propdo-logo-white-sm.svg`;
  const facebookIcon = IMAGES_LINK + `/homepage/facebook-icon.svg`;
  const linkedinIcon = IMAGES_LINK + `/homepage/linkedin-icon.svg`;
  const { openDialog } = useDialogManager();
  const [authorization, setAuthorization] = useContext(AuthorizationContext);
  const { direction } = useDirection();

  const handleLogin = () => {
    openDialog(Login, { DialogProps: { maxWidth: "xs" } });
  };

  const handleContact = () => {
    openDialog(ContactForm, { DialogProps: { maxWidth: "xs" } });
  };

  const handlePricing = () => {
    openDialog(PricingPlan, { DialogProps: { maxWidth: "xs" } });
  };

  const handleTerms = () => {
    openDialog(PropdoTermsAndConditions, { DialogProps: { maxWidth: "xs" } });
  };

  const handlePrivacy = () => {
    openDialog(PropdoPrivacyPolicy, { DialogProps: { maxWidth: "xs" } });
  };

  return (
    <Grid
      container
      item
      sm
      alignItems="center"
      style={{ justifyContent: !isDesktopScreen ? "center" : "" }}
      className={classes.container}
    >
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={9}
        style={{ paddingBottom: "20px" }}
        alignItems="baseline"
      >
        <Grid item xs={6} md={2} sm={6} style={{ paddingBottom: "20px" }}>
          <Grid item className={classes.img}>
            <HashLink to="#top">
              <img src={logo} />
            </HashLink>
          </Grid>
          <Typography className={classes.subtitle}>
            {t("footer.address")}
          </Typography>
          <Typography className={classes.subtitle}>
            {t("footer.tel")}
          </Typography>
          <Grid item className={classes.socialIcons}>
            <Grid
              item
              className={classes.facebookIcon}
              component={"a"}
              href="https://www.facebook.com/PropdoIL/"
            >
              <img src={facebookIcon} />
            </Grid>
            <Grid
              item
              component={"a"}
              href="https://www.linkedin.com/company/propdo/mycompany/"
            >
              <img src={linkedinIcon} />
            </Grid>
          </Grid>
        </Grid>

        {FOOTER(t, direction.country).map((item, idx) => {
          return (
            <Grid
              key={idx}
              container
              item
              xs={6}
              sm={6}
              md={3}
              direction="column"
              justifyContent="center"
            >
              <Typography className={classes.title}>{item.title}</Typography>

              {item.subtitle1.subtitle === t("footer.subtitle1") ? (
                <></>
              ) : (
                // <HashLink
                //   smooth
                //   to={item.subtitle1.route}
                //   className={classes.subtitle}
                // >
                //   {item.subtitle1.subtitle}
                // </HashLink>
                <>
                  {item.subtitle1.subtitle === t("footer.subtitle8") ? (
                    <Grid
                      onClick={handleTerms}
                      style={{
                        display: authorization && "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography className={classes.subtitle}>
                        {item.subtitle1.subtitle}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item component={"a"} href={item.subtitle1.route}>
                      <Typography className={classes.subtitle}>
                        {item.subtitle1.subtitle}
                      </Typography>
                    </Grid>
                  )}
                </>
              )}

              {item.subtitle2.subtitle === t("footer.subtitle6") ? (
                <HashLink smooth to={item.subtitle2.route}></HashLink>
              ) : item.subtitle2.subtitle === t("footer.subtitle6") ? (
                <HashLink smooth to={item.subtitle2.route}>
                  {item.subtitle2.subtitle}
                </HashLink>
              ) : item.subtitle2.subtitle === t("footer.subtitle2") ? (
                <>
                  <Grid
                    onClick={handleContact}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography className={classes.subtitle}>
                      {item.subtitle2.subtitle}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  {item.subtitle2.subtitle === t("footer.subtitle7") ? (
                    <Grid
                      onClick={handlePrivacy}
                      style={{
                        display: authorization && "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography className={classes.subtitle}>
                        {item.subtitle2.subtitle}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item component={"a"} href={item.subtitle2.route}>
                      <Typography className={classes.subtitle}>
                        {item.subtitle2.subtitle}
                      </Typography>
                      {item.subtitle4?.subtitle === t("footer.subtitle11") && (
                        <a
                          href={REPORTS_EXAMPLE}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: white,
                            fontSize: FONT_TEXT,
                            lineHeight: 1.5,
                            marginRight: 15,
                          }}
                        >
                          {t("footer.subtitle11")}
                        </a>
                      )}
                    </Grid>
                  )}
                </>
              )}

              {item.subtitle3.subtitle === t("footer.subtitle9") ? (
                <Grid
                  onClick={handleLogin}
                  style={{
                    display: authorization && "none",
                    cursor: "pointer",
                  }}
                >
                  <Typography className={classes.subtitle}>
                    {item.subtitle3.subtitle}
                  </Typography>
                </Grid>
              ) : (
                item.subtitle3.subtitle === t("footer.subtitle6") && (
                  <a href={item.subtitle3.route} className={classes.subtitle}>
                    {item.subtitle3.subtitle}
                  </a>
                )
              )}
            </Grid>
          );
        })}
      </Grid>
      <Grid container item xs={12} sm={12} md={3} direction="column">
        <SendEmail />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "30px 40px 30px 40px",
    margin: "0 auto",
    backgroundColor: blue_1,
  },
  img: {
    paddingTop: "20px",
    paddingBottom: "15px",
  },
  title: {
    color: white,
    fontSize: FONT_TITLE,
    fontWeight: 600,
    paddingBottom: "10px",
  },
  subtitle: {
    color: white,
    fontSize: FONT_SUBTITLE,
    lineHeight: "2.5",
  },
  icon: {
    color: white,
  },
  socialIcons: {
    paddingTop: "10px",
  },
  facebookIcon: {
    paddingLeft: "10px",
  },
});
