import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CityImpactsCard from "../CityImpactsCard";
import DemoExample from "../DemoExample";
import DataTableChart from "../../data/DataTableChart";
import { LoadingCharts } from "../layout/Layout";
import { useIsIncludes } from "common-components";
import DegreeOfInfluenceCard from "../DegreeOfInfluenceCard";
import Segmentation from "./Segmentation";
import TilesContainer from "../TilesContainer";
import { OpenAccessContext } from "../../context/OpenAccessContext";
import { PropertyIdContext } from "../../context/PropertyIdContext";
import { useLazyDataApi } from "common-components";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";
import { Helmet } from "react-helmet";

const TableDashboard = ({ SPACING_FACTOR }) => {
  const [direction] = useContext(DirectionContext);
  const [{ data, error }, lazyFetch] = useLazyDataApi();
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [propertyId] = useContext(PropertyIdContext);
  const [rawData, setRawData] = useState(undefined);
  const isDemo = useIsIncludes("demo");
  const { t } = useTranslation();

  useNotistack(`${t("insertAddress")}`, error, undefined, "error");

  useEffect(() => {
    if (typeof propertyId === "object") {
      lazyFetch(
        `/reports/dashboard/page/impact-events?id=${
          isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
        }&lang=${direction.isRtl ? "he" : "en"}`
      );
    }
  }, [propertyId]);

  useEffect(() => {
    if (data) {
      setRawData(data);
    }
  }, [data]);

  return (
    <Grid
      container
      spacing={SPACING_FACTOR}
      alignItems={"stretch"}
      style={{
        paddingTop: 24,
        backgroundColor: "#e1edf7",
        margin: "0",
        width: "revert",
      }}
    >
      <Helmet>
        <title>{t("MetaData.impactTitle")}</title>
        <description>{t("MetaData.impactDescription")}</description>
      </Helmet>
      <Grid name="table" container item>
        <Grid item xs={12}>
          <TilesContainer topBorder relative>
            {/* {isDemo && <DemoExample />} */}
            {rawData ? (
              <DataTableChart rawData={rawData.table} />
            ) : (
              <LoadingCharts
                variant={"h5"}
                name={`${t("ImpactTable.title")}`}
              />
            )}
          </TilesContainer>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <DegreeOfInfluenceCard data={rawData?.ratio_plans} />
      </Grid>

      <Grid item xs={12} md={8}>
        <CityImpactsCard data={rawData?.surrounding_area_plans} />
      </Grid>

      <Segmentation SPACING_FACTOR={SPACING_FACTOR} rawData={rawData} />
    </Grid>
  );
};
export default TableDashboard;
