import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { withStyles } from "@material-ui/core/styles";
import {
  borderColor,
  darkBorderColor,
  darkBoxes,
  darkElements,
  darkMainPropColor,
  lightBoxes,
  mainPropColor,
} from "../services/colors";
import { XS_PLUS_FONT } from "../services/fonts";

const useStyles = makeStyles({
  container: (props) => ({
    width: props.widthContainer ? props.widthContainer : "",
    margin: 0,
    flexDirection: "row",
    backgroundColor: props.isDarkTheme ? darkBoxes : lightBoxes,
    border: `1px solid ${props.isDarkTheme ? darkBorderColor : borderColor}`,
    borderRadius: props.radius ? 24 : "var(--border-radius)",
    height: 40,
  }),
});
const StyledToggleButton = withStyles({
  root: (props) => ({
    width: props.widthRoot ? props.widthRoot : "",
    fontSize: props.font ? props.font : XS_PLUS_FONT,
    color: "rgb(163, 162, 162)",
    backgroundColor: "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "transparent",
    borderRadius: props.radius ? 24 : "var(--border-radius)",
    padding: "6px 12px",
    whiteSpace: props.noWrap ? "nowrap" : "normal",
    "&.MuiToggleButton-root.Mui-selected": {
      background: props.isDarkTheme ? darkElements : "rgb(255, 255, 255)",
      color: props.isDarkTheme ? darkMainPropColor : mainPropColor,
      border: `1px solid ${
        props.isDarkTheme ? darkMainPropColor : mainPropColor
      }`,
      borderRadius: props.radius ? 24 : "var(--border-radius)",
      padding: "6px 12px",
    },
  }),
})((props) => <ToggleButton {...props} />);

const ToggleSlider = ({
  value,
  setValue,
  list,
  noWrap,
  widthContainer,
  widthRoot,
  font,
  radius,
  isDarkTheme,
}) => {
  const classes = useStyles({ isDarkTheme, widthContainer, radius });

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setValue(newAlignment);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
      className={classes.container}
    >
      {list.map((item, index) => {
        return (
          <StyledToggleButton
            radius={radius}
            widthContainer={widthContainer}
            widthRoot={widthRoot}
            font={font}
            isDarkTheme={isDarkTheme}
            noWrap={noWrap}
            key={index}
            value={item.value}
            disabled={item.value === "disabled"}
          >
            {item.text}
          </StyledToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
export default ToggleSlider;
