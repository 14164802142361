import React, { useMemo, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { CustomLegend, CustomLegendProp } from "./CustomLegend";
import { useCustomTooltip } from "./CustomTooltip";
import { makeStyles } from "@material-ui/core/styles";
import * as chartjs from "chart.js";
import { ANIMATION_DURATION } from "../services/consts";
import { FONT } from "../services/fonts";
import { useIsMobileScreen } from "common-components";
import {
  disableScrolling,
  enableScrolling,
} from "./mortgage/MortgageLineChart";
import { useTranslation } from "react-i18next";
import ChartTooltip from "./chartTooltip/ChartTooltip";
import Grid from "@material-ui/core/Grid";
import { datasetKeyProvider } from "../components/dashboard-components/tama/LineChart";
import TitleGrid from "../components/TitleGrid";

export const PaperStyle = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
});

export interface ChartjsData {
  labels: string[];
  datasets: DatasetDonutChart[];
}

export interface DatasetDonutChart {
  data: number[];
  backgroundColor: string[];
  hoverBackgroundColor: string[];
  hoverBorderColor: string[];
  hoverBorderWidth: number;
}

export interface DonutChartProps {
  data: ChartjsData;
  legend: CustomLegendProp[];
}

const DonutChart: React.FC<DonutChartProps> = ({ data, legend }) => {
  const chartRef = useRef<any>();
  const [index, setCurrentIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );

  const totalVal = useMemo(() => {
    //only 1 dataset exist for this chart
    //calculate sum:
    return data.datasets[0].data.reduce(
      (accumulator, currentValue) => accumulator + currentValue
    );
  }, [data]);

  const percentageDonut = useMemo(() => {
    return function (value: any) {
      const num = parseFloat(((value / totalVal) * 100).toFixed(0));
      if (num < 3) return "";
      else return num.toString() + "%";
    };
  }, []);
  const isMobileScreen = useIsMobileScreen();
  // @ts-ignore
  const chartJsOptions = useMemo<chartjs.ChartOptions>(() => {
    return {
      maintainAspectRatio: false,
      animation: {
        duration: ANIMATION_DURATION,
      },
      legend: {
        display: false,
      },
      plugins: {
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
        datalabels: {
          color: "#FFF",
          formatter: percentageDonut,
          font: {
            size: isMobileScreen ? 12 : FONT,
            family: "Open Sans Hebrew, sans-serif",
          },
          anchor: "center",
          textAlign: "center",
        },
      },
      tooltips: {
        enabled: false,
        custom: customTooltip,
      },
    };
  }, [isMobileScreen]);
  const { t } = useTranslation();

  return (
    <Grid container>
      <TitleGrid
        title={t("DonutChart.title")}
        tooltipText={t("DonutChart.tooltip")}
      />

      <Grid item xs={12}>
        <CustomLegend legend={legend} />
        <div
          style={{ minHeight: 300 }}
          onTouchStart={() => {
            disableScrolling();
          }}
          onTouchEnd={() => {
            enableScrolling();
          }}
        >
          <Doughnut
            ref={chartRef}
            data={data}
            datasetKeyProvider={datasetKeyProvider}
            options={chartJsOptions}
          />
        </div>
        {/*@ts-ignore*/}
        <ChartTooltip
          data={data}
          title={{ title: "" }}
          position={position}
          id={"donutImpact"}
          index={index}
          tooltipRef={tooltipRef}
          tooltipPercent={[t("BarChart.about"), t("BarChart.totalEvents")]}
          tooltipEvent
          total={totalVal}
        />
      </Grid>
    </Grid>
  );
};

export default DonutChart;
