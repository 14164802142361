import { makeStyles } from "@material-ui/core/styles";
import { LG_PLUS_FONT } from "../services/fonts";
import { darkBackground, darkText } from "../services/colors";

export const useStylesRowBox = makeStyles({
  marginPoint: {
    margin: "1% 0 2%",
    display: "flex",
    justifyContent: "flex-start",
  },

  divTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "space-between",
  },

  divNumbers: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: LG_PLUS_FONT,
    margin: "1% 0 2%",
    alignItems: "center",
  },
  inputAskingPrice: {
    maxWidth: 70,
    backgroundColor: darkText,
    border: "none",
    color: darkBackground,
    padding: 4,
    borderRadius: 10,
  },
});
