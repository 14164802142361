import { DOMAIN } from "../../api/api_links";
import Cookies from "js-cookie";

export function setCookies(name, value) {
  Cookies.set(name, value, {
    domain: DOMAIN,
  });
}

export const viewCookies = () => {
  var pairs = document.cookie.split(";");
  var cookies = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
  }
  return cookies;
};

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
};
