import { Grid } from "@material-ui/core";
import { ProductSearch } from "../productSearch/ProductSearch";
import { BuildingImg } from "../header/BuildingImg";
import { Title } from "../header/Title";
import { makeStyles } from "@material-ui/styles";
import { useSearch } from "../../../global/context/SearchContext";
import { useEffect } from "react";
import { useIsLargeScreen } from "../../../global/hooks/useIsLargeScreen";
import { PropdoButton } from "../../../global/components/buttons/PropdoButton";
import { useTranslation } from "react-i18next";
import {
  blue_1,
  borderRadius,
  orange_1,
  white,
} from "../../../global/services/colors";
import { useHistory } from "react-router-dom";
import { ContactForm } from "../../../global/components/contactForm/ContactForm";
import { useDialogManager } from "../../../global/hooks/useDialogManager";
import { Button } from "@material-ui/core";
// filter: props.hasFocus ? "blur(4px)" : "transparent",
const useStyles = makeStyles({
  mainContainer: (props: { isDesktopScreen: boolean }) => ({
    minHeight: props.isDesktopScreen ? "80vh" : "85vh",
  }),
  blur: (props: { hasFocus: boolean }) => ({
    filter: props.hasFocus && "blur(10px)",
  }),
  button: {
    width: "100%",
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderRadius: 8,
    padding: "8px 12px",
    backgroundColor: orange_1,
    color: blue_1,
    border: `1px solid ${blue_1}`,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: blue_1,
      color: white,
    },
  },
});

export const Hero: React.FC<{
  config: { DEFAULT_SEARCH: { centroid: object } };
}> = ({ config }) => {
  const { hasFocus } = useSearch();
  const isDesktopScreen = useIsLargeScreen("md");
  const classes = useStyles({ hasFocus, isDesktopScreen });
  const { t } = useTranslation();
  const { openDialog } = useDialogManager();

  useEffect(() => {
    if (hasFocus) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [hasFocus]);

  const handleBuyApartment = () => {
    openDialog(ContactForm, { DialogProps: { maxWidth: "xs" } });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      id={"main"}
      className={classes.mainContainer}
    >
      <Grid container spacing={4} direction="column" item xs={12} md={7}>
        <Grid className={classes.blur}>
          <Title />
        </Grid>
        <Grid container item xs={12} md={4}>
          <Button className={classes.button}>
            <a href="https://seller.propdo.ai/" rel="nofollow">
              {t("buttons.sellApartment")}
            </a>
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button className={classes.button} onClick={handleBuyApartment}>
            {t("buttons.buyApartment")}
          </Button>
        </Grid>
        <Grid container item xs={12} md={4}>
          <Button className={classes.button}>
            <a href="https://www.propdort.com/" rel="nofollow">
              {t("buttons.professionals")}
            </a>
          </Button>
        </Grid>
      </Grid>
      {isDesktopScreen && (
        <Grid item xs={5} className={classes.blur}>
          <BuildingImg />
        </Grid>
      )}
    </Grid>
  );
};
