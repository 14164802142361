import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { PurchasePropertyContext } from "../../context/PurchasePropertyContext";
import { MD_FONT } from "../../services/fonts";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const ReportsGushHalka = ({ classes, errors, register }) => {
  const [property] = useContext(PurchasePropertyContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classesLabel = useStylesAddProp({ isDarkTheme });
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={6} style={{ fontSize: MD_FONT }}>
        <TextField
          id="gush"
          name="gush"
          error={errors["gush"] !== undefined}
          placeholder={t("SearchBar.placeHolderGush")}
          variant="outlined"
          type="number"
          size="small"
          style={{ width: "100%", height: "36px !important" }}
          defaultValue={property.gush}
          InputProps={{
            className: classesLabel.inputGushHalka,
            inputProps: {
              inputMode: "numeric",
            },
          }}
          onFocus={(event) => {
            event.target.select();
          }}
          {...register("gush", {
            required: "required",
            pattern: /^[0-9]*$/,
          })}
        />
        {errors.gush?.type === "required" && (
          <Typography className={classesLabel.errorsStyle}>
            {t("SearchBar.gushRequiredMsg")}
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="halka"
          name="halka"
          error={errors["halka"] !== undefined}
          placeholder={t("SearchBar.placeHolderHalka")}
          variant="outlined"
          type="number"
          size="small"
          style={{ width: "100%" }}
          defaultValue={property.halka}
          onFocus={(event) => {
            event.target.select();
          }}
          InputProps={{
            className: classesLabel.inputGushHalka,
            inputProps: {
              inputMode: "numeric",
            },
          }}
          {...register("halka", {
            required: "required",
            pattern: /^[0-9]*$/,
          })}
        />
        {errors.halka?.type === "required" && (
          <Typography className={classesLabel.errorsStyle}>
            {t("SearchBar.halkaRequiredMsg")}
          </Typography>
        )}
      </Grid>
    </>
  );
};
export default ReportsGushHalka;
