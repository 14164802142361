import { BrowserRouter as Router, useHistory } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import { OpenAccessContext } from "../../context/OpenAccessContext";
import { DetectPageDashboard, Routes, RoutesUS } from "../../services/routes";
import { MD_FONT } from "../../services/fonts";
import { useIsIncludes } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";
import { COMPS_LINK } from "common-components/src/global/api/api_links";

const HEIGHT_HEADER = 62;

const AntTabs = withStyles({
  root: {
    height: HEIGHT_HEADER,
    minHeight: `${HEIGHT_HEADER}px !important`,
    "&.MuiTabs-flexContainer": {
      height: HEIGHT_HEADER,
    },
  },
  indicator: {
    backgroundColor: "white",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    minWidth: 110,
    height: HEIGHT_HEADER,
    margin: "0 2px",
    color: "white",
    borderRadius: "var(--br-top)",
    transition: "all 0.3s",
    textTransform: "none",
    "&.MuiTabs-flexContainer": {
      height: HEIGHT_HEADER,
    },
    "&.MuiTab-root": {
      minHeight: `${HEIGHT_HEADER}px !important`,
      fontSize: MD_FONT,
    },
    "&:hover": {
      borderBottom: "1px solid #fff",
      opacity: 1,
    },
    "&$selected": {
      color: "white",
      fontWeight: 600,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export function detectPage() {
  const page = window.location.pathname;
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const pageNoSlash = page.replace(/[\/\\]/g, "");

  if (DetectPageDashboard.includes(pageNoSlash) || id) {
    return 0;
  } else if (
    pageNoSlash === Routes.reports.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.reports.replace(/[\/\\]/g, "")
  ) {
    return 1;
    // } else if (pageNoSlash === Routes.listings.replace(/[\/\\]/g, "")) {
    //   return 3;   TODO to be added in the Future
  } else if (
    pageNoSlash === Routes.user.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.user.replace(/[\/\\]/g, "")
  ) {
    return 2;
  }
}

const TopBarRoutes = () => {
  const [direction] = useContext(DirectionContext);
  let history = useHistory();
  const [value, setValue] = useState(detectPage());
  const [isOpenAccess, setOpenAccess] = useContext(OpenAccessContext);
  const isShare = useIsIncludes("share");
  const isDemo = useIsIncludes("demo");
  const isShareId = history.location.search.includes("S");

  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(detectPage());
  }, [detectPage()]);

  const handleSwitch = (pathname, newValue, openAccess, property, page) => {
    history.push({
      pathname:
        direction.region === "il" ? Routes[pathname] : RoutesUS[pathname],
    });
    setValue(newValue);
    setOpenAccess({
      access: openAccess,
      path: `property${property}`,
      page: page,
    });
  };

  return (
    <Router>
      <div
        style={{
          margin: "0 auto",
          maxWidth: 1380,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: HEIGHT_HEADER,
        }}
      >
        <AntTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <AntTab
            style={{ cursor: isShare ? "default" : "" }}
            label={
              isOpenAccess.page === "/id"
                ? `${t("Tabs.reportBuy")}`
                : isOpenAccess.page === "demo"
                ? `${t("Tabs.reportDemo")}`
                : `${t("Tabs.dashboard")}`
            }
            onClick={() => {
              if (!isOpenAccess.access) {
                handleSwitch("dashboard", 0, false, "", "");
              }
            }}
          />

          {!isShare && !isShareId && !isDemo && (
            <AntTab
              label={`${t("Tabs.reports")}`}
              onClick={() => handleSwitch("reports", 1, false, "", "")}
            />
          )}
          {!isShare && !isShareId && !isDemo && (
            <AntTab
              label={`${t("Tabs.profile")}`}
              onClick={() => handleSwitch("user", 2, false, "", "/user")}
            />
          )}
          {/* <AntTab
            style={{ cursor: isShare ? "default" : "" }}
            label={t("Tabs.comps")}
            onClick={() => {
              window.open(COMPS_LINK);
            }}
          /> */}
        </AntTabs>
      </div>
    </Router>
  );
};
export default TopBarRoutes;
