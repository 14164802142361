import { makeStyles } from "@material-ui/core/styles";
import { mainPropColor } from "../services/colors";

export const useStylesErrorPage = makeStyles({
  divError: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    zIndex: 9999,
    top: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  headerError: {
    width: "100%",
    padding: "32px 0",
  },
  divTextBox: (props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& p": {
      fontSize: props.isMobileScreen ? 22 : 28,
      fontWeight: "bold",
    },
  }),
  logoBoxError: {
    display: "flex",
    justifyContent: "end",
  },
  textWrapError: {
    padding: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: mainPropColor,
    "& p": {
      textAlign: "center",
    },
  },
});
