import React from "react";
import { IPlan } from "../../../interfaces/subscription";
import { Grid, Typography, Radio } from "@material-ui/core";
import {
  borderRadius,
  grey_2,
  orange_1,
  white,
} from "../../../services/colors";
import { usePayment } from "../../../context/PaymentContext";
import {
  FONT_TEXT,
  FONT_TEXT_SECONDARY,
} from "../../../services/constants/fonts";
import { useTranslation } from "react-i18next";
import { useIsLargeScreen } from "../../../hooks/useIsLargeScreen";
import { useDirection } from "../../../context/DirectionContext";
import { numFormatter } from "../../../services/helpers/formatting";

type Props = {
  plan: IPlan;
  handleChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

export const SinglePlanItem: React.FC<Props> = ({ plan, handleChange }) => {
  const { currPlan } = usePayment();
  const { t } = useTranslation();
  const isDesktopScreen = useIsLargeScreen("md");
  const { direction } = useDirection();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      style={{
        border:
          currPlan?.name === plan.name
            ? `1px solid ${orange_1}`
            : `1px solid ${grey_2}`,
        padding: handleChange ? 4 : 12,
        marginTop: 15,
        marginBottom: !handleChange ? 15 : "",
        borderRadius: borderRadius,
      }}
    >
      <Grid container alignItems="center" item xs={8}>
        {handleChange && (
          <Radio
            style={{
              color: orange_1,
            }}
            checked={currPlan?.name === plan.name}
            onChange={handleChange}
            value={plan.name}
            inputProps={{ "aria-label": "monthly" }}
          />
        )}
        <Typography style={{ fontWeight: 600, marginLeft: 15 }}>
          {plan.title}
        </Typography>
        <Typography
          style={{
            fontSize: !isDesktopScreen ? FONT_TEXT_SECONDARY : "",
            paddingLeft: 5,
          }}
        >
          {numFormatter(plan.price, direction.country)}
          {" + " + t("global.vat")}
          {plan.perMonth && plan.perMonth}
        </Typography>
        <Typography
          style={{
            marginLeft: 5,
            fontSize: !isDesktopScreen ? FONT_TEXT_SECONDARY : "",
          }}
        >
          {plan.perYear}
        </Typography>
      </Grid>
      {plan.perYear && (
        <Grid
          container
          item
          xs={4}
          justifyContent="space-around"
          style={{
            backgroundColor: orange_1,
            padding: !isDesktopScreen ? 10 : 6,
            color: white,
            borderRadius: borderRadius,
            right: "2.5rem",
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: !isDesktopScreen ? FONT_TEXT : "",
            }}
          >
            {plan.save}
          </Typography>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: !isDesktopScreen ? FONT_TEXT : "",
            }}
          >
            {numFormatter(plan.saveAmount, direction.country)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
