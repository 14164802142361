import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Collapse, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Cookies from "js-cookie";
import { doFetch } from "../api/doFetch";
import { DOMAIN } from "../api/api_links";
import { ReactComponent as FlagIsrael } from "../icons/israel.svg";
import { ReactComponent as FlagUSA } from "../icons/united-states.svg";
import { useNotistack } from "../hooks/useNotistack";

import {
  darkThemePositiveGreen,
  mainPropColor,
  positiveGreenColor,
} from "../services/colors";
import { LoadingButtons } from "../loading/LoadingButtons";
import { FONT, MD_PLUS_FONT } from "../services/fonts";

const useStyles = makeStyles({
  container: (props) => ({
    padding: props.isMobile ? "5px 20px" : props.isFooter ? "" : "8px 10px",
    cursor: "pointer",

    justifyContent: props.isLP && props.isMobile ? "center" : "flex-start",
  }),
  text: (props) => ({
    color: props.isMobile
      ? "#000 !important"
      : props.isLP
      ? "#8e929b !important"
      : "#fff !important",
    fontSize: props.isFooter || props.isLP ? MD_PLUS_FONT : FONT,
    fontWeight: 400,
  }),
  flag: (props) => ({
    width: 20,
    height: 20,
    marginLeft: "15px",
    display: "flex",
    alignItems: "center",
    padding: props.isMobile && "0px !important",
  }),
});

const LanguageButton = ({
  isMobile = false,
  isFooter = false,
  isLP = false,
  context,
}) => {
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles({
    isDarkTheme: context.isDarkTheme,
    isMobile,
    isFooter,
    isLP,
  });

  const handleLanguageChange = (isRtl) => {
    if (!context.authorization.clientId) {
      console.log("context.authorization", context.authorization);
      setResponse({
        variant: "success",
        title: t("Tabs.langSuccessText"),
      });
      context.setDirection((prevState) => ({
        ...prevState,
        isRtl,
      }));
      Cookies.set("lang", isRtl ? "he" : "en", { domain: DOMAIN });
    }
    setLoading(true);
    doFetch(`/user/details/update`, {
      method: "POST",
      body: JSON.stringify({
        lang: isRtl ? "he" : "en",
      }),
    })
      .then((data) => {
        if (data.message === "User Updated") {
          setResponse({
            variant: "success",
            title: t("Tabs.langSuccessText"),
          });

          context.setDirection((prevState) => ({
            ...prevState,
            isRtl,
          }));
          context.setUserDetails((prevState) => ({
            ...prevState,
            lang: isRtl ? "he" : "en",
          }));
          Cookies.set("lang", isRtl ? "he" : "en", { domain: DOMAIN });
        } else if (data.errorMessage) {
          setResponse({
            variant: "error",
            title: t("Tabs.langErrorText"),
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setResponse({
          variant: "error",
          title: t("Tabs.langErrorText"),
        });
      });
  };
  useNotistack(response.title, response, setResponse, response.variant);

  return (
    <>
      <Grid
        container
        alignItems="center"
        onClick={() => setOpen((prevState) => !prevState)}
        className={classes.container}
      >
        <Typography className={classes.text}>
          {context.direction.isRtl ? t("Tabs.hebrew") : t("Tabs.english")}
        </Typography>
        <Typography className={classes.flag}>
          {context.direction.isRtl ? <FlagIsrael /> : <FlagUSA />}
        </Typography>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid
          container
          direction={isMobile || isFooter ? "column" : "row"}
          alignItems="center"
          style={{ maxWidth: (isMobile && !isLP) || isFooter ? 200 : "none" }}
          justifyContent={isMobile && isLP ? "center" : "flex-start"}
        >
          {context.direction.isRtl ? (
            <Button
              disabled={!context.direction.isRtl}
              className={classes.text}
              onClick={() => handleLanguageChange(false)}
            >
              {t("Tabs.english")} <FlagUSA className={classes.flag} />
              {context.direction.isRtl && loading && (
                <LoadingButtons
                  size={"1x"}
                  style={{ padding: 5 }}
                  color={isMobile ? "white" : mainPropColor}
                />
              )}
            </Button>
          ) : (
            <Button
              disabled={context.direction.isRtl}
              className={classes.text}
              onClick={() => handleLanguageChange(true)}
            >
              {t("Tabs.hebrew")} <FlagIsrael className={classes.flag} />
              {!context.direction.isRtl && loading && (
                <LoadingButtons
                  size={"1x"}
                  style={{ padding: 5 }}
                  color={isMobile ? "white" : mainPropColor}
                />
              )}
            </Button>
          )}
        </Grid>
      </Collapse>
    </>
  );
};
export default LanguageButton;
