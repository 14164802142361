import { Grid, Typography } from "@material-ui/core";
import DemoExample from "../../DemoExample";
import PropertyImprovement from "./PropertyImprovement";
import { LoadingCharts, SPACING_FACTOR } from "../../layout/Layout";
import React, { useContext, useEffect, useState } from "react";
import { useIsIncludes } from "common-components";
import { useIsMobileScreen } from "common-components";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import TilesContainer from "../../TilesContainer";
import { OpenAccessContext } from "../../../context/OpenAccessContext";
import { useLazyDataApi } from "common-components";
import TamaStepper from "./TamaStepper";
import TamaLineChart from "./TamaLineChart";
import TamaToggleForecast from "./TamaToggleForecast";
import ContributionTama from "./ContributionTama";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";
import { Helmet } from "react-helmet";

const TamaComponent = ({ setSummaryPrice, toggleBRCall, month }) => {
  const [direction] = useContext(DirectionContext);
  const [stage, setStage] = useState(0);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [propertyId] = useContext(PropertyIdContext);
  const [{ data, error: lazyError }, lazyFetch] = useLazyDataApi();
  const [rawData, setRawData] = useState(undefined);
  const isListings = useIsIncludes("listings");
  const isDemo = useIsIncludes("demo");
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const isSmallScreen = useIsMobileScreen();
  const { t } = useTranslation();

  useNotistack(`${t("retrievalFailedMsg")}`, lazyError, undefined, "error");

  useEffect(() => {
    if (typeof propertyId === "object") {
      lazyFetch(
        `/reports/dashboard/page/tama?id=${
          isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
        }`
      );
    }
  }, [propertyId, toggleBRCall]);

  useEffect(() => {
    if (data) {
      setRawData(data);
    }
  }, [data]);

  return (
    <Grid
      container
      spacing={SPACING_FACTOR}
      style={{ backgroundColor: "#e1edf7", margin: "0", width: "revert" }}
    >
      <Helmet>
        <title>{t("MetaData.rentTitle")}</title>
        <description>{t("MetaData.rentDescription")}</description>
      </Helmet>
      <Grid item xs={12}>
        <TilesContainer style={{ height: "100%", border: "none" }} relative>
          <Typography variant={"h5"}>{t("Tama.title")}</Typography>
        </TilesContainer>
      </Grid>
      <Grid item xs={12}>
        <TamaToggleForecast
          lineData={rawData?.tama_graph}
          toggleSlider
          name={t("Tama.name")}
          month={month}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TilesContainer style={{ height: "100%" }} relative topBorder>
          {isDemo && (
            <DemoExample ribbonLeft={isExtraSmallScreen ? "0px" : undefined} />
          )}
          {rawData && isListings ? (
            <PropertyImprovement
              data={rawData?.tama_details}
              propDetails={rawData?.property_details}
              setStage={setStage}
              stage={stage}
              setRawData={setRawData}
            />
          ) : rawData?.tama_details ? (
            <PropertyImprovement
              setSummaryPrice={setSummaryPrice}
              data={rawData?.tama_details}
              propDetails={rawData?.property_details}
              setStage={setStage}
              setRawData={setRawData}
              stage={stage}
            />
          ) : (
            <LoadingCharts variant={"h5"} name={t("Tama.titleForm")} />
          )}
        </TilesContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        {rawData ? (
          <TamaLineChart
            lineData={rawData?.tama_graph}
            title={t("Tama.titleGraph")}
            tooltip={
              "הגרף מציג אומדן לתוספת במחיר הנכס בכל תקופה בגין פרויקט התחדשות עירונית. על מנת לצפות בפער יש להזין את פרטי הפרויקט בצידו הימיני של הגרף."
            }
          />
        ) : (
          <LoadingCharts variant={"h5"} name={t("Tama.titleGraph")} />
        )}
      </Grid>
      {!isSmallScreen && (
        <Grid item xs={2}>
          <TilesContainer padding={16} relative>
            {isDemo && <DemoExample width={110} />}
            <TamaStepper activeStep={stage} />
          </TilesContainer>
        </Grid>
      )}

      <Grid item xs={12} style={{ paddingBottom: isSmallScreen ? 24 : "" }}>
        <ContributionTama lineData={rawData?.tama_graph} stage={stage} />
      </Grid>
    </Grid>
  );
};
export default TamaComponent;
