import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {
  color4,
  color5,
  darkBlue,
  darkOrange,
  darkPurple,
  darkText,
  darkMainPropColor,
  darkThemeSliderRail,
  greyAxis,
  yellow,
} from "../../services/colors";
import { Collapse, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { RowTable } from "../../data/DataTableChart";
import { Bar } from "react-chartjs-2";
import { addingComma } from "../../services/helpers";
import { XS_PLUS_FONT } from "../../services/fonts";
import { useTranslation } from "react-i18next";

interface TableExtraProps {
  open: undefined | number;
  row: RowTable;
}
const TableExtra = ({ open, row }: TableExtraProps) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();

  const chartJsOptions = {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      datalabels: {
        color: isDarkTheme ? "#fff" : "#000",
        formatter: function (value: any) {
          return value === 0 ? "" : addingComma(value);
        },
        font: {
          size: 12,
          family: "Open Sans Hebrew, sans-serif",
        },
        anchor: "center",
        textAlign: "center",
        align: "center",
        clamp: true,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      rtl: true,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            fontColor: isDarkTheme ? darkText : greyAxis,
            fontSize: 13,
            fontFamily: "Open Sans Hebrew, sans-serif",
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            drawOnChartArea: false,
            drawBorder: false,
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <TableRow style={{ width: "100%" }}>
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
          width: "100%",
          backgroundColor: isDarkTheme ? darkThemeSliderRail : "whitesmoke",
        }}
        colSpan={isDarkTheme ? 8 : 6}
      >
        <Collapse in={open === row.index} timeout="auto" unmountOnExit>
          <div
            style={{
              minHeight: 50,
              maxHeight: 250,
              maxWidth: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              flexDirection: "row",
              padding: 15,
            }}
          >
            {Object.keys(row.extra).length === 0 && (
              <Typography variant="h6" gutterBottom component="div">
                {t("ImpactTable.NoData")}
              </Typography>
            )}

            {row.extra.sqm && row.extra.sqm.length > 0 && (
              <div>
                <Typography
                  align={"center"}
                  style={{ fontWeight: 600, fontSize: XS_PLUS_FONT }}
                >
                  <bdi>&thinsp; {t("ImpactTable.change")}&thinsp;</bdi>
                  {typeof row.extra.PD_SQM === "number" ? (
                    <bdi>{row.extra.PD_SQM}%</bdi>
                  ) : (
                    "--"
                  )}
                </Typography>
                <div>
                  <Bar
                    //@ts-ignore
                    options={chartJsOptions}
                    data={{
                      labels: [
                        `${t("ImpactTable.SqmBefore")}`,
                        `${t("ImpactTable.SqmAfter")}`,
                      ],
                      datasets: [
                        {
                          data: [row.extra.sqm[0], row.extra.sqm[1]],
                          backgroundColor: [
                            isDarkTheme ? darkOrange : color5,
                            isDarkTheme ? darkBlue : yellow,
                          ],
                          borderColor: [
                            isDarkTheme ? darkOrange : color5,
                            isDarkTheme ? darkBlue : yellow,
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            )}

            {row.extra.units && row.extra.units.length > 0 && (
              <div>
                <Typography
                  align={"center"}
                  style={{ fontWeight: 600, fontSize: XS_PLUS_FONT }}
                >
                  <bdi>&thinsp; {t("ImpactTable.change")}&thinsp; </bdi>
                  {typeof row.extra.PD_units === "number" ? (
                    <bdi>{row.extra.PD_units}%</bdi>
                  ) : (
                    "--"
                  )}
                </Typography>

                <div>
                  <Bar
                    //@ts-ignore
                    options={chartJsOptions}
                    data={{
                      labels: [
                        `${t("ImpactTable.unitsBefore")}`,
                        `${t("ImpactTable.unitsAfter")}`,
                      ],
                      datasets: [
                        {
                          data: [row.extra.units[0], row.extra.units[1]],
                          backgroundColor: [
                            isDarkTheme ? darkMainPropColor : darkText,
                            isDarkTheme ? darkPurple : color4,
                          ],
                          borderColor: [
                            isDarkTheme ? darkMainPropColor : darkText,
                            isDarkTheme ? darkPurple : color4,
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
export default TableExtra;
