import { useContext, useEffect, useState } from "react";
import { AuthorizationContext } from "../context/AuthorizationContext";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { DirectionContext } from "../context/DirectionContext";
import { DisabledTabsContext } from "../context/DisabledTabsContext";
import { OpenAccessContext } from "../context/OpenAccessContext";
import { PropertyIdContext } from "../context/PropertyIdContext";
import { PurchasePropertyContext } from "../context/PurchasePropertyContext";
import { UserDetailsContext } from "../context/UserDetailsContext";

export default function useContextProps() {
  const [isDarkTheme, setDarkTheme] = useContext(DarkThemeContext);
  const [userDetails, setUserDetails] = useContext(UserDetailsContext);
  const [direction, setDirection] = useContext(DirectionContext);
  const [authorization, setAuthorization] = useContext(AuthorizationContext);
  const [isOpenAccess, setOpenAccess] = useContext(OpenAccessContext);
  const [propertyId] = useContext(PropertyIdContext);
  //   @ts-ignore
  const [property, setProperty] = useContext(PurchasePropertyContext);
  //   @ts-ignore
  const [disabledTabs] = useContext(DisabledTabsContext);

  return {
    isDarkTheme,
    setDarkTheme,
    userDetails,
    setUserDetails,
    direction,
    setDirection,
    authorization,
    setAuthorization,
    isOpenAccess,
    setOpenAccess,
    disabledTabs,
    propertyId,
    property,
    setProperty,
  };
}
