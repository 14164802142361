import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IMAGES_LINK } from "../..";
import { greyPropColor } from "../../global-il/services/colors";
import { LG_FONT, MD_PLUS_FONT } from "../../global-il/services/fonts";
import { useIsMobileScreen } from "../../global-il/hooks/useIsMobileScreen.ts";
import { makeStyles } from "@material-ui/styles";

import "../assets/styles/index.css";

const useStyles = makeStyles({
  cardContainer: (props) => ({
    marginBottom: props.isMobileScreen ? "10px" : null,

  })
})

export const Benefits = () => {
  const { t } = useTranslation();

  //   TODO : MAKE EXTERNAL FILE TO GET THIS DATA FROM

  const benefits = [
    {
      icon: `${IMAGES_LINK}/landing-page/about/accurate.svg`,
      text: t("about.icons.accurate"),
    },
    {
      icon: `${IMAGES_LINK}/landing-page/about/timing.svg`,
      text: t("about.icons.timing"),
    },
    {
      icon: `${IMAGES_LINK}/landing-page/about/fees.svg`,
      text: t("about.icons.fees"),
    },
  ];

  const isMobileScreen = useIsMobileScreen()
  const props = {isMobileScreen}
  const classes = useStyles(props);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className="padding"
      style={{ borderBottom: `1px solid ${greyPropColor}` }}
    >
      {benefits.map((item, idx) => {
        return (
          <Grid
            container
            item
            xs={12}
            md={3}
            alignItems="flex-start"
            justifyContent="center"
            key={idx}
            spacing={1}
            style={{ height: 200 }}
            className={classes.cardContainer}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              item
              xs={12}
            >
              <img src={item.icon} style={{ width: "100%", height: 100 }} />
            </Grid>
            <Grid
              container
              justifyContent="center"
              item
              xs={12}
              style={{ height: 70 }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  color: greyPropColor,
                }}
              >
                {item.text}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
