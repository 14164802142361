import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import TilesContainer from "../TilesContainer";
import Mapbox from "../Mapbox";
import { useIsMobileScreen } from "common-components";
import { LoadingCharts } from "../layout/Layout";

const MapContainer = ({ rawData }: any) => {
  const [clickPanTo, setClickPanTo] = useState(false);
  const isSmallScreen = useIsMobileScreen();
  return (
    <Grid
      id="map-container"
      item
      style={{
        flexGrow: 1,
        height: isSmallScreen ? 200 : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TilesContainer
        padding={"0px"}
        style={{ flexGrow: 1, display: "flex" }}
        relative
      >
        {rawData ? (
          <>
            <div
              onClick={() => setClickPanTo(!clickPanTo)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 26,
                height: 26,
                position: "absolute",
                bottom: 80,
                right: 11,
                backgroundColor: "rgb(249,249,249)",
                zIndex: 1,
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
                borderRadius: 2,
                cursor: "pointer",
              }}
            >
              <MyLocationIcon style={{ color: "black" }} />
            </div>

            <Mapbox
              zoomNumb={7}
              properties={rawData?.map?.properties}
              clickPanTo={clickPanTo}
              geojson={rawData?.map}
            />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <LoadingCharts
              name={""}
              height={"auto"}
              minHeight={"0"}
              outsideHeight={"auto"}
            />
          </div>
        )}
      </TilesContainer>
    </Grid>
  );
};
export default MapContainer;
