import { Grid, Typography } from "@material-ui/core";
import imgExampleOne from "./example1.png";
import imgExampleTwo from "./example2.png";
import { useTranslation } from "react-i18next";
import { XS_PLUS_FONT, LG_PLUS_FONT } from "../../global-il/services/fonts";
import { useIsMobileScreen } from "../../global-il/hooks/useIsMobileScreen.ts";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    imgContainer: (props) => ({
        padding: props.isMobileScreen ? 20 : null,
        width: props.isMobileScreen ? "30px" : null

    }),
    image: (props) => ({
            width: props.isMobileScreen ? "200px" : null,
            height: props.isMobileScreen ? "50px" : null
    })
})

const marketingList = [
  {
    icon: imgExampleOne,
  },
  {
    icon: imgExampleTwo,
  },
];

const Carousel = () => {
  const { t } = useTranslation();
  const isMobileScreen = useIsMobileScreen();
  const props = { isMobileScreen };
  const classes = useStyles(props);

  return (
    <Grid container justifyContent="center" className="main-container padding">
      <Typography
        style={{
          fontSize: LG_PLUS_FONT,
          fontWeight: 600,
          padding: 12,
          textDecoration: "underline",
          textDecorationColor: "pink",
          textUnderlineOffset: 6,
        }}
      >
        {t("media.title")}
      </Typography>
      <Grid container item xs={12} justifyContent="space-between">
        {marketingList.map((item, idx) => {
          return (
            <Grid container item xs={12} md={3} key={idx}>
              <Grid item xs={12} className={classes.imgContainer}>
                <Typography align={"center"}>
                  <img src={item.icon} alt="icon" className={classes.image}/>
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Carousel;
