import {
  Grid,
  Drawer,
  Box,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
  useTheme,
  AppBar,
  Typography,
  ListItemIcon,
  ClickAwayListener,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useRef, useState } from "react";
import { useIsMobileScreen } from "../..";
import { Logo } from "../Logo";
import { NavbarLinks } from "./NavbarLinks";
import "../assets/styles/index.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";


//NOT WORKING
const useStyles = makeStyles({
  root: (props) => ({
    height: 85,
    backgroundColor: props.isTop ? "transparent" : "white",
    position: "fixed",
    zIndex: 1,
    boxShadow: props.isTop
      ? "none"
      : "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px",
    zIndex: 3,
    transition: "all 0.3s",
  }),
  wrapper: (props) => ({
    padding: props.isMobileScreen ? "0 24px" : "0 64px",
  }),
  icon: {
    height: "2em",
    width: "2em",
    color: "#001145",
  },
});

export const Navbar = () => {
  //NOOT WORKING
  const [isTop, setIsTop] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const isMobileScreen = useIsMobileScreen();
  const props = { isTop, isMobileScreen };
  const classes = useStyles(props);

  useEffect(() => {
    function scrollFunction() {
      if (
        document.body.scrollTop > 30 ||
        document.documentElement.scrollTop > 30
      ) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    }
    window.addEventListener("scroll", scrollFunction);

    return () => {
      // console.log('Component unmounting code')
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  // console.log("isOpen", isOpen);

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current?.contains(event.target)) 
      setIsOpen(false);
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  let menuRef = useRef();

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <Grid
          container
          alignItems="center"
          // justifyContent="space-between"
          className={classes.wrapper}
        >
          <Grid item xs={3}>
            <Logo isTop={isTop} style={{ height: 50, width: 100 }} />
          </Grid>
          {isMobileScreen ? (
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              item
              xs={9}
              style={{
                color: window.scrollY > 30 ? "black" : "white",
                cursor: "pointer",
              }}
              ref={menuRef}
            >
              <MenuIcon onClick={() => setIsOpen(!isOpen)} />
              <Drawer
                anchor={"right"}
                // variant="permanent"
                // variant="persistent"
                // variant="temporary"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                style={{ width: "80%" }}
              >
                <NavbarLinks
                  isTop={false}
                />

                <Grid container item spacing={3} justifyContent="center" style={{marginTop: "380px"}}>
          
                  <Grid
                    item
                    component={"a"}
                    href="https://www.facebook.com/PropdoIL/"
                  >
                    <FacebookIcon className={classes.icon} />
                  </Grid>
                  <Grid
                    item
                    component={"a"}
                    href="https://www.linkedin.com/company/propdo/"
                  >
                    <LinkedInIcon className={classes.icon} />
                  </Grid>
                </Grid>
              </Drawer>
            </Grid>
          ) : (
            <Grid
              container
              alignItems="center"
              justifyContent="space-around"
              item
              xs={7}

            >
              <NavbarLinks isTop={isTop} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
