import ReactMapboxGl, {
  GeoJSONLayer,
  Marker,
  ZoomControl,
} from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useContext, useEffect, useState } from "react";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { OpenAccessContext } from "../context/OpenAccessContext";
import { darkMainPropColor, mainPropColor } from "../services/colors";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import { isLoggedIn } from "../services/helpers";
import { useIsIncludes } from "common-components";
import { useStylesSpinner } from "./usermanagement/UserManagementDetails";
import { LoadingButtons } from "./layout/Layout";
import { IMAGES_LINK, MAPBOX_KEY } from "common-components";
import { DirectionContext } from "../context/DirectionContext";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.setRTLTextPlugin(
  "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
  null,
  true // Lazy load the plugin
);

const Map = ReactMapboxGl({
  accessToken: MAPBOX_KEY,
  attributionControl: false,
});

export default function Mapbox({ zoomNumb, properties, clickPanTo, geojson }) {
  const [authorization] = useContext(AuthorizationContext);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(zoomNumb);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [direction] = useContext(DirectionContext);
  const isListings = useIsIncludes("listings");
  const style = isDarkTheme
    ? direction.isRtl
      ? "mapbox://styles/propdo/cks4jcgdk61ew18t3yyr0w90i"
      : "mapbox://styles/propdo/ckvyzztpp620j14nmmph6yirt"
    : direction.isRtl
    ? "mapbox://styles/propdo/cksyk3q6z6fk818o7mys3uhg1"
    : "mapbox://styles/propdo/ckvyzz3t87d7c14pl0cv6v0mw";

  const mapFlyTo = (map) => {
    map.flyTo({
      animate: true,
      center: center,
      zoom: isOpenAccess.access ? zoomNumb : 11,
      duration: 5500,
      essential: true,
    });
  };

  useEffect(() => {
    if (properties && !isListings) {
      const res = [properties.coord[1], properties.coord[0]];
      setCenter(res);
      if (map) mapFlyTo(map);
    } else if (isOpenAccess.access) {
      const res = [34.77302504523412, 32.0635585563946];
      setCenter(res);
    } else if (!isOpenAccess.access && !properties) {
      setCenter(undefined);
    }
  }, [map, properties, isOpenAccess]);

  useEffect(() => {
    if (map && clickPanTo) {
      mapFlyTo(map);
    }
  }, [clickPanTo]);
  const classesSpinner = useStylesSpinner();
  return (
    <>
      {!properties && !isOpenAccess.access && (
        <div className={classesSpinner.spinnerStyle}>
          <LoadingButtons
            size={"2x"}
            color={isDarkTheme ? darkMainPropColor : mainPropColor}
            style={{ zIndex: 9999 }}
          />
        </div>
      )}
      {center && (
        <Map
          center={center}
          zoom={[zoom]}
          style={style}
          containerStyle={{
            position: "absolute",
            height: "100%",
            width: "100%",
            borderRadius: "var(--border-radius)",
          }}
          onStyleLoad={(map) => setMap(map)}
        >
          <GeoJSONLayer
            data={geojson}
            fillPaint={{
              "fill-color": mainPropColor,
              "fill-opacity": 0.2,
            }}
            linePaint={{
              "line-color": "#ef3043",
              "line-width": 1,
            }}
          />
          {!isOpenAccess.access && authorization && (
            <Marker
              coordinates={center}
              anchor="bottom"
              onClick={() => mapFlyTo(map)}
              style={{ cursor: "pointer" }}
            >
              <img
                src={
                  isDarkTheme
                    ? `${IMAGES_LINK}/dashboard/elements/location-pin-dark-theme.svg`
                    : `${IMAGES_LINK}/dashboard/elements/location-pin.svg`
                }
              />
            </Marker>
          )}
          <ZoomControl position="bottom-right" />
        </Map>
      )}
    </>
  );
}
