import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { darkFiveColors } from "../services/colors";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAsterisk,
  faCircle,
  faMinus,
  faPlay,
  faPlus,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import Typography from "@material-ui/core/Typography";
import { XS_PLUS_FONT } from "../services/fonts";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    padding: "17px 0px",
  },
});

const iconStyles: any = [
  faPlus,
  faPlay,
  faAsterisk,
  faCircle,
  faSquare,
  faMinus,
  faSquare,
];

const impactItemTypes = (t: any) => {
  return [
    `${t("ImpactTypes.specialHousing")}`,
    `${t("ImpactTypes.publicBuildings")}`,
    `${t("ImpactTypes.residence")}`,
    `${t("ImpactTypes.trade")}`,
    `${t("ImpactTypes.transportation")}`,
    `${t("ImpactTypes.tourism")}`,
    `${t("ImpactTypes.employment")}`,
  ];
};

export interface CustomLegendProp {
  color: string;
  label: string;
}
interface Legend {
  legend: CustomLegendProp[];
  useShapes?: boolean;
  padding?: string;
}
export const Collator = new Intl.Collator("he");
export const CustomLegend: React.FC<Legend> = ({
  legend,
  useShapes = false,
  padding,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root} style={{ padding: padding ? padding : "" }}>
      {legend.map((item, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            key={index}
          >
            {useShapes ? (
              <FontAwesomeIcon
                icon={iconStyles[index] as IconProp}
                transform={index === 1 ? { rotate: -90 } : { rotate: 0 }}
                style={{
                  color: isDarkTheme ? darkFiveColors[0] : item.color,
                  fontSize: XS_PLUS_FONT,
                }}
              />
            ) : (
              <div
                style={{
                  borderRadius: "50%",
                  backgroundColor: item.color,
                  width: 12,
                  height: 12,
                }}
              />
            )}
            <Typography style={{ margin: "0 10px" }}>{item.label}</Typography>
          </div>
        );
      })}
    </div>
  );
};
