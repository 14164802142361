import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { useCustomTooltip } from "../../../charts/CustomTooltip";
import {
  darkText,
  darkThemeGridLines,
  greyAxis,
} from "../../../services/colors";
import { Line } from "react-chartjs-2";
import { FONT } from "../../../services/fonts";
import ClickableLegend from "../comparative/ClickableLegend";
import Grid from "@material-ui/core/Grid";
import {
  disableScrolling,
  enableScrolling,
} from "../../../charts/mortgage/MortgageLineChart";
import ChartTooltip from "../../../charts/chartTooltip/ChartTooltip";
import { DirectionContext } from "../../../context/DirectionContext";
import TitleGrid from "../../TitleGrid";
import { useTranslation } from "react-i18next";
import { useIsMobileScreen } from "common-components";

export function yAxisCallbackIL(value) {
  let unit = "מ'";
  return `${value} ${unit}`;
}
export function yAxisCallbackUS(value) {
  let unit = " M ";
  return `${value}${unit}`;
}
export function yAxisCallbackMetersIL(value) {
  let unit = 'מ"ר';
  return `${value} ${unit}`;
}
export function yAxisCallbackMetersUS(value) {
  let unit = " sqm ";
  return `${value}${unit}`;
}
export function xAxisCallback(value, extraSmallScreen) {
  if (extraSmallScreen) {
    return value % 15 === 0 ? value : null;
  } else {
    return value % 5 === 0 ? value : null;
  }
}
export function datasetKeyProvider() {
  return Math.random();
}

function toggleDataFunction(data, show) {
  return {
    labels: data.labels,
    datasets: data.datasets.filter((item, index) => {
      return item.label === show[index].label && show[index].show;
    }),
  };
}

const LineChart = ({ data, tooltipDate, title, tooltipInfo, interactive }) => {
  const [direction] = useContext(DirectionContext);
  const { t } = useTranslation();
  const [toggleData, setToggleData] = useState(data);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const chartRef = useRef();
  const [index, setCurrentIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );

  const isMobileScreen = useIsMobileScreen();
  const extraSmallScreen = useIsMobileScreen(370);

  const [show, setShow] = useState(
    data.datasets.map((item, index) => {
      return {
        index: index,
        label: item.label,
        show: true,
      };
    })
  );
  const [clickableLegend, setClickableLegend] = useState(
    data.datasets.map((item) => {
      return {
        color: item.backgroundColor,
        label: item.label,
      };
    })
  );

  const toggleShow = (label) => {
    const newArr = [...show];
    show.forEach((item, index) => {
      if (item.label === label) {
        newArr[index].show = !newArr[index].show;
      }
    });
    setShow(newArr);
  };

  useEffect(() => {
    setClickableLegend(
      data.datasets.map((item) => {
        return {
          color: item.backgroundColor,
          label: item.label,
        };
      })
    );

    setShow(
      data.datasets.map((item, index) => {
        return {
          index: index,
          label: item.label,
          show: true,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    setToggleData(toggleDataFunction(data, show));
  }, [show]);

  const chartJsOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      animation: {
        duration: 6000,
      },
      plugins: {
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
        datalabels: {
          display: false,
        },
      },
      tooltips: {
        enabled: false,
        custom: customTooltip,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },

            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: isMobileScreen ? 10 : FONT,
              fontFamily: "Open Sans Hebrew, sans-serif",
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              callback: (value) => xAxisCallback(value, extraSmallScreen),
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              stepSize: 0.2,
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: isMobileScreen ? 10 : FONT,
              fontFamily: "Open Sans, sans-serif",
              callback: direction.isRtl ? yAxisCallbackIL : yAxisCallbackUS,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
  }, [isDarkTheme, direction, extraSmallScreen]);
  return (
    <Grid container style={{ height: "100%" }}>
      <TitleGrid
        interactive={interactive}
        title={title}
        tooltipText={tooltipInfo}
      />

      <Grid item xs={12}>
        <ClickableLegend
          show={show}
          toggleShow={toggleShow}
          legend={clickableLegend}
        />
      </Grid>

      <Grid item xs={12} style={{ minHeight: 300 }}>
        <div
          onTouchStart={() => {
            disableScrolling();
          }}
          onTouchEnd={() => {
            enableScrolling();
          }}
          style={{ minHeight: 300 }}
        >
          <Line
            ref={chartRef}
            data={toggleData}
            datasetKeyProvider={datasetKeyProvider}
            options={chartJsOptions}
          />
        </div>
      </Grid>
      <ChartTooltip
        data={data}
        title={{
          title: "",
          year: false,
        }}
        tooltipDate={tooltipDate}
        position={position}
        id={"tooltip-renovation"}
        index={index}
        tooltipRef={tooltipRef}
        dataToMap={data.datasets}
        sign={t("LineChart.sign")}
      />
    </Grid>
  );
};
export default LineChart;
