import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { Button, Collapse, Grid, MenuItem, Select } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useIsMobileScreen } from "common-components";
import { OpenAccessContext } from "../../../context/OpenAccessContext.tsx";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import {
  darkGrey,
  darkMainPropColor,
  darkText,
  darkThemeNegativeRed,
  darkThemePositiveGreen,
  darkThemeSliderRail,
  greyAxis,
  mainPropColor,
  negativeRedColor,
  positiveGreenColor,
} from "../../../services/colors";
import { TooltipInfoIcon } from "common-components";
import { useIsIncludes } from "common-components";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import Typography from "@material-ui/core/Typography";
import { FONT, XS_PLUS_FONT } from "../../../services/fonts";
import { useLazyDataApi } from "common-components";
import SubmitAndClearButtons from "../../SubmitAndClearButtons";
import { LoadingButtons } from "../../layout/Layout";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";
import TitleGrid from "../../TitleGrid";

const useStyles = makeStyles({
  secTitle: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  icon: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "end",
    alignItems: "center",
  },

  itemContainer: {
    paddingBottom: 15,
  },
  btnBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const useBreakpoints = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      margin: "0 20px 20px 20px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 20,
    },
  },
  gridMargin: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginBottom: "0%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginBottom: "10%",
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginBottom: "10%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      marginBottom: "10%",
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: "10%",
    },
  },
}));
const MIN = -45;
const MAX = 45;
const marksView = [
  {
    value: MIN,
  },
  {
    value: 0,
  },
  {
    value: MAX,
  },
];
const marksDesign = [
  {
    value: MIN,
  },
  {
    value: 0,
  },
  {
    value: MAX,
  },
];
const marksDivision = [
  {
    value: MIN,
  },
  {
    value: 0,
  },
  {
    value: MAX,
  },
];
const marksNuisances = [
  {
    value: MIN,
  },
  {
    value: 0,
  },
  {
    value: MAX,
  },
];
function updateSlider(
  api,
  lazyFetch,
  slidersUpdated,
  propertyId,
  isOpenAccess,
  beachLine
) {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id") ? queryParams.get("id") : "-1";
  lazyFetch(
    `/reports/dashboard/page/summary/update/slider?id=${
      isOpenAccess.page === "demo" ? isOpenAccess.page : id
    }`,
    {
      method: "POST",
      body: JSON.stringify({ slider: slidersUpdated, beach_line: beachLine }),
    }
  );
}

const useStylesText = makeStyles({
  text: (props) => ({
    fontSize: props.isMobileScreen ? 12 : FONT,
    overflowWrap: "anywhere",
  }),
});

const SliderBox = ({ isData, rawData, setRawData, setSummaryPrice }) => {
  const [direction] = useContext(DirectionContext);
  const [
    { data: lazyData, error: lazyError, isLoading },
    lazyFetch,
  ] = useLazyDataApi();
  const [beachLine, setBeachLine] = useState(0);
  const [valView, setValView] = useState(0);
  const [design, setDesign] = useState(0);
  const [division, setDivision] = useState(0);
  const [nuisances, setNuisances] = useState(0);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const [openSlidersSmall, setOpenSlidersSmall] = useState(!isExtraSmallScreen);
  const [propertyId] = useContext(PropertyIdContext);
  const classes = useStyles();
  const breakpoints = useBreakpoints();
  const { t } = useTranslation();

  useNotistack(`${t("updateSucceedMsg")}`, lazyData, undefined, "success");
  useNotistack(`${t("updateFailedMsg")}`, lazyError, undefined, "error");

  useEffect(() => {
    if (rawData) {
      setValView(rawData.subjective[0].slider[0] * 15);
      setDesign(rawData.subjective[0].slider[1] * 15);
      setDivision(rawData.subjective[0].slider[2] * 15);
      setNuisances(rawData.subjective[0].slider[3] * 15);
      setBeachLine(rawData.subjective[0].beach_line);
    }
  }, [rawData]);

  const handleApply = () => {
    const slidersUpdated = [
      valView / 15,
      design / 15,
      division / 15,
      nuisances / 15,
    ];
    updateSlider(
      direction.api,
      lazyFetch,
      slidersUpdated,
      propertyId,
      isOpenAccess,
      beachLine
    );
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (lazyData) {
      if (lazyData.id || isOpenAccess.access) {
        const newData = { ...rawData };
        for (const [key, value] of Object.entries(rawData)) {
          if (lazyData[key]) {
            newData[key] = lazyData[key];
          }
        }
        setSummaryPrice((prevState) => ({
          ...prevState,
          overview: lazyData.overview,
        }));
        setRawData(newData);
      } else {
        console.error("Update failed");
      }
    }
  }, [lazyData]);

  const onClearBtn = () => {
    const slidersCleared = [0, 0, 0, 0];
    updateSlider(
      direction.api,
      lazyFetch,
      slidersCleared,
      propertyId,
      isOpenAccess,
      beachLine
    );

    setValView(0);
    setDesign(0);
    setDivision(0);
    setNuisances(0);
  };
  const isListings = useIsIncludes("listings");

  return (
    <Grid container style={{ opacity: isLoading ? 0.3 : 1, marginBottom: 10 }}>
      <TitleGrid
        title={t("SliderBox.title")}
        tooltipText={t("SliderBox.tooltip")}
      />

      {isExtraSmallScreen && openSlidersSmall && (
        <div style={{ marginBottom: -8 }}>
          <Button
            disabled={isListings}
            color="primary"
            onClick={() => setOpenSlidersSmall(!openSlidersSmall)}
          >
            {openSlidersSmall
              ? `${t("SliderBox.openWindow")}`
              : `${t("SliderBox.closeWindow")}`}
            <FontAwesomeIcon
              style={{ margin: "0 10px" }}
              icon={openSlidersSmall ? faChevronDown : faChevronUp}
            />
          </Button>
        </div>
      )}
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Collapse
          in={isExtraSmallScreen ? !openSlidersSmall : openSlidersSmall}
          timeout="auto"
          unmountOnExit
        >
          <Sliders
            isData={isData}
            classes={classes}
            breakpoints={breakpoints}
            design={design}
            division={division}
            handleApply={handleApply}
            loading={isLoading}
            nuisances={nuisances}
            setDesign={setDesign}
            setDivision={setDivision}
            setNuisances={setNuisances}
            setValView={setValView}
            valView={valView}
            isOpenAccess={isOpenAccess}
            onClearBtn={onClearBtn}
            beachLine={beachLine}
            setBeachLine={setBeachLine}
          />
        </Collapse>
      </Grid>
      {isExtraSmallScreen && !openSlidersSmall && (
        <div style={{ marginBottom: -8 }}>
          <Button
            disabled={isListings}
            color="primary"
            onClick={() => setOpenSlidersSmall(!openSlidersSmall)}
          >
            {openSlidersSmall
              ? `${t("SliderBox.openWindow")}`
              : `${t("SliderBox.closeWindow")}`}
            <FontAwesomeIcon
              style={{ margin: "0 10px" }}
              icon={openSlidersSmall ? faChevronDown : faChevronUp}
            />
          </Button>
        </div>
      )}
      {isLoading && <SpinnerLoading />}
    </Grid>
  );
};
export function SpinnerLoading({ color }) {
  const [isDarkTheme] = useContext(DarkThemeContext);
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        top: 0,
        height: "100%",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <LoadingButtons
        size={"3x"}
        color={color ? color : isDarkTheme ? darkMainPropColor : mainPropColor}
      />
    </div>
  );
}
function Sliders({
  className,
  loading,
  breakpoints,
  classes,
  setValView,
  valView,
  setDesign,
  design,
  setDivision,
  division,
  setNuisances,
  nuisances,
  isData,
  handleApply,
  onClearBtn,
  beachLine,
  setBeachLine,
}) {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const isListings = useIsIncludes("listings");
  const { t } = useTranslation();
  const [direction] = useContext(DirectionContext);
  const handleChange = (event) => {
    setBeachLine(Number(event.target.value));
  };

  const isMobileScreen = useIsMobileScreen();
  const classesText = useStylesText({ isMobileScreen });
  return (
    <>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        className={breakpoints.gridMargin}
      >
        <Grid item xs={5} xl={3}>
          <div className={classes.secTitle}>
            <TooltipInfoIcon
              tooltipText={t("SliderBox.view.tooltip")}
              isDarkTheme={isDarkTheme}
            />

            <Typography className={classesText.text}>
              {t("SliderBox.view.title")}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={7} xl={9}>
          <FancySlider
            marks={marksView}
            setVal={setValView}
            val={valView}
            disabled={!isData || isListings}
          />
          <SliderLabels
            label1={t("SliderBox.view.badView")}
            label2={t("SliderBox.view.goodView")}
          />
        </Grid>
        {valView === 45 && (
          <>
            <Grid item xs={4} xl={3} />
            <Grid item xs={8} xl={9}>
              <Select
                variant="outlined"
                id="beach_line"
                name="beach_line"
                defaultValue={beachLine}
                disabled={!isData || isListings}
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
              >
                <MenuItem value={0}>{t("SliderBox.view.viewVal0")}</MenuItem>
                <MenuItem value={1}>{t("SliderBox.view.viewVal1")}</MenuItem>
                <MenuItem value={2}>{t("SliderBox.view.viewVal2")}</MenuItem>
                <MenuItem value={3}>{t("SliderBox.view.viewVal3")}</MenuItem>
                <MenuItem value={4}>{t("SliderBox.view.viewVal4")}</MenuItem>
                <MenuItem value={5}>{t("SliderBox.view.viewVal5")}</MenuItem>
              </Select>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        className={breakpoints.gridMargin}
      >
        <Grid item xs={5} xl={3}>
          <div className={classes.secTitle}>
            <TooltipInfoIcon
              tooltipText={t("SliderBox.interiorCondition.tooltip")}
            />

            <Typography className={classesText.text}>
              {t("SliderBox.interiorCondition.title")}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={7} xl={9}>
          <FancySlider
            marks={marksDesign}
            setVal={setDesign}
            val={design}
            disabled={!isData || isListings}
          />
          <SliderLabels
            label1={t("SliderBox.interiorCondition.badCondition")}
            label2={t("SliderBox.interiorCondition.goodCondition")}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        className={breakpoints.gridMargin}
      >
        <Grid item xs={5} xl={3}>
          <div className={classes.secTitle}>
            <TooltipInfoIcon tooltipText={t("SliderBox.division.tooltip")} />

            <Typography className={classesText.text}>
              {t("SliderBox.division.title")}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={7} xl={9}>
          <FancySlider
            marks={marksDivision}
            setVal={setDivision}
            val={division}
            disabled={!isData || isListings}
          />
          <SliderLabels
            label1={t("SliderBox.division.problematicDivision")}
            label2={t("SliderBox.division.optimalDivision")}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        className={breakpoints.gridMargin}
      >
        <Grid item xs={5} xl={3}>
          <div className={classes.secTitle}>
            <TooltipInfoIcon tooltipText={t("SliderBox.nuisances.tooltip")} />

            <Typography className={classesText.text}>
              {t("SliderBox.nuisances.title")}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={7} xl={9}>
          <FancySlider
            marks={marksNuisances}
            setVal={setNuisances}
            val={nuisances}
            disabled={!isData || isListings}
          />
          <SliderLabels
            label1={t("SliderBox.nuisances.loudNuisances")}
            label2={t("SliderBox.nuisances.noNuisances")}
          />
        </Grid>
      </Grid>
      <Grid container>
        <SubmitAndClearButtons
          onClearBtn={onClearBtn}
          submitTitle={`${t("SliderBox.openWindow")}`}
          disabled={!isData || isListings}
          onClickBtn={handleApply}
        />
      </Grid>
    </>
  );
}
function SliderLabels({ label1, label2 }) {
  const [isDarkTheme] = useContext(DarkThemeContext);

  return (
    <div
      style={{
        position: "relative",
        top: -7,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        style={{
          color: isDarkTheme ? darkText : greyAxis,
          fontSize: XS_PLUS_FONT,
        }}
      >
        {label1}
      </Typography>
      <Typography
        style={{
          color: isDarkTheme ? darkText : greyAxis,
          fontSize: XS_PLUS_FONT,
        }}
      >
        {label2}
      </Typography>
    </div>
  );
}
function FancySlider({ marks, setVal, val, disabled }) {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);

  const sliderDirection = useMemo(() => {
    return direction.isRtl ? "right" : "left";
  }, [direction]);

  const PrettoSlider = useMemo(() => {
    return withStyles({
      root: {
        color: "rgb(219, 219, 219)",
        height: 2,
        padding: "15px 0",
        width: "100%",
      },
      thumb: {
        borderRadius: "50%",
        transition: "all 250ms cubic-bezier(.27,.01,.38,1.06)",
        backgroundColor: isDarkTheme ? darkText : "white",
        border: isDarkTheme
          ? `1px solid ${darkGrey}`
          : `1px solid ${mainPropColor}`,
        height: 20,
        width: 20,
        marginTop: -12,
        marginLeft: -13,
        top: 19,
        "&:focus, &:hover, &:active": {
          boxShadow: isDarkTheme ? "none" : "#ccc 0 2px 3px 1px",
        },
        "& .bar": {
          height: 9,
          width: 1,
          backgroundColor: mainPropColor,
          marginLeft: 1,
          marginRight: 1,
        },
      },
      valueLabel: {
        left: "calc(-50% + 12px)",
        top: -22,
        "& *": {
          background: "transparent",
          color: "#44476a",
        },
      },

      rail: {
        height: 3,
        opacity: 1,
        borderRadius: "var(--border-radius)",
        backgroundColor: isDarkTheme
          ? darkThemeSliderRail
          : "rgb(219, 219, 219)",
      },
      mark: {
        backgroundColor: "#bfbfbf",
        height: 8,
        width: 1,
        marginTop: -3,
      },
      markActive: {
        opacity: 1,
        backgroundColor: "currentColor",
      },
      markLabel: {
        top: "26px !important",
        color: greyAxis,
        left: 800,
      },
      disabled: {
        "& .MuiSlider-thumb": {
          height: 20,
          width: 20,
          marginTop: -12,
          marginLeft: -13,
          top: 19,
          backgroundColor: darkGrey,
          border: `1px solid ${darkGrey}`,
        },
      },
    })(Slider);
  }, [isDarkTheme]);
  const handleUpdate = useCallback(
    (event, value) => {
      if (value !== val) {
        setVal(value);
      }
    },
    [val]
  );

  const ref = useRef();
  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    const sliderSpan = ref.current;
    const trackSpan = sliderSpan.querySelector(".MuiSlider-track");
    trackSpan.style.backgroundColor =
      val < 0 && isDarkTheme
        ? darkThemeNegativeRed
        : val < 0 && !isDarkTheme
        ? negativeRedColor
        : isDarkTheme
        ? darkThemePositiveGreen
        : positiveGreenColor;

    trackSpan.style[sliderDirection] =
      val === -45 ? 0 : val < 0 ? `${50 - Math.abs(val)}%` : "50%";

    trackSpan.style.width = Math.abs(val) === 45 ? "50%" : `${Math.abs(val)}%`;
    trackSpan.style.border = isDarkTheme ? "none" : ".0625rem solid #d1d9e6";
    trackSpan.style.height = "3px";
    trackSpan.style.opacity = 1;
    trackSpan.style.borderRadius = "var(--border-radius)";
    trackSpan.style.borderBottomRightRadius =
      val < 0 ? 0 : "var(--border-radius)";
    trackSpan.style.borderBottomLeftRadius =
      val > 0 ? 0 : "var(--border-radius)";
    trackSpan.style.overflow = "hidden";
    trackSpan.style.transition = "all 250ms cubic-bezier(.27,.01,.38,1.06)";
  });

  return (
    <PrettoSlider
      ref={ref}
      valueLabelDisplay="off"
      aria-label="ios slider"
      value={val}
      step={15}
      marks={marks}
      onChange={handleUpdate}
      min={MIN}
      max={MAX}
      disabled={disabled}
    />
  );
}

export default SliderBox;
