import React, { useState } from "react";
import { Button, IconButton } from "@material-ui/core/";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { blue_1, white } from "../../services/colors";
import {
  FONT_SUBTITLE,
  FONT_TEXT,
  FONT_TITLE,
} from "../../services/constants/fonts";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// @ts-ignore
import { HashLink } from "react-router-hash-link";
import { useIsIncludes } from "../../hooks/useIsIncludes";
import { COMPS_LINK } from "../../api/api_links";
import { useIsBaseRoute } from "../../hooks/useIsBaseRoute";
import { IProduct } from "../../../homepage/interfaces/products";
import { makeStyles } from "@material-ui/styles";

type Style = {
  color: string;
  fontSize: string;
};

interface Dropdown {
  btnText: string;
  style: Style;
  items: string[] | IProduct[];
  callback?: Function;
  isRoute?: boolean;
}

const useStyles = makeStyles({
  text: {
    fontSize: FONT_SUBTITLE,
    textAlign: "right",
  },
});

export const PropdoDropdown: React.FC<Dropdown> = ({
  btnText,
  style,
  items,
  isRoute,
  callback,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [arrowDirection, setArrowDirection] = useState(true);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setArrowDirection(!arrowDirection);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setArrowDirection(!arrowDirection);
  };

  return (
    <>
      <IconButton
        style={{
          color: style.color,
          marginRight: 5,
          // backgroundColor: "black",
          fontSize: FONT_TITLE,
          padding: 0,
        }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Typography
          style={{
            fontSize: style.fontSize,
            fontFamily: "Propdo Light",
          }}
        >
          {btnText}
        </Typography>
        {arrowDirection ? (
          <KeyboardArrowDownIcon
            style={{
              color: style.color,
              // color: "red",
              height: 17,
            }}
          />
        ) : (
          <KeyboardArrowUpIcon
            style={{
              color: style.color,
              height: 17,
            }}
          />
        )}
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        // @ts-ignore
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        {items.map((item: any, idx: number) => {
          return (
            <MenuItem key={idx} onClick={handleClose}>
              {item.label ? (
                <>
                  {isRoute ? (
                    <Typography
                      className={classes.text}
                      onClick={item.callback}
                    >
                      {item.title}
                    </Typography>
                  ) : (
                    <HashLink smooth to={item.route}>
                      <Typography className={classes.text}>
                        {item.title}
                      </Typography>
                    </HashLink>
                  )}
                </>
              ) : (
                <Typography className={classes.text} onClick={item.callback}>
                  {item.title}
                </Typography>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
