import React, { useContext, useEffect, useState } from "react";
import { TableChart } from "../charts/table/TableChart";
import { useTranslation } from "react-i18next";
import { DarkThemeContext } from "../context/DarkThemeContext";

const headCells = (isDarkTheme: boolean, t: any): Column[] => {
  return [
    { id: "open", numeric: false, label: "", notSortable: true },
    { id: "event", numeric: false, label: `${t("ImpactTable.event")}` },
    { id: "eventName", numeric: false, label: `${t("ImpactTable.eventName")}` },
    { id: "status", numeric: false, label: `${t("ImpactTable.status")}` },
    { id: "distance", numeric: false, label: `${t("ImpactTable.distance")}` },
    { id: "impact", numeric: true, label: `${t("ImpactTable.impact")}` },
    { id: "distanceNumber", numeric: true, label: "", hidden: true },
    { id: "extra", numeric: false, label: "", hidden: true },
    { id: "index", numeric: false, label: "", hidden: true },
  ];
};

export interface Column {
  id: keyof RowTable;
  /**
   * If true table will be sorted in numeric order
   */
  numeric: boolean;
  label: string;
  notSortable?: boolean;
  hidden?: boolean;
}
interface Data {
  filter_types: string[];
  rows: RowClass[];
  rows_selected?: number[];
}

interface RowClass {
  extra: Extra;
  row: Array<number | string>;
}

export interface Extra {
  PD_SQM?: number | string;
  PD_units?: number | string;
  sqm?: number[];
  units?: number[];
}
interface DataTableChart {
  rawData: Data;
}
export interface RowTable {
  open: string;
  event: string;
  eventName: string;
  status: string;
  distance: string;
  impact: number;
  distanceNumber: number;
  extra: Extra;
  index: number;
}
const DataTableChart = ({ rawData }: DataTableChart) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [rows, setRows] = useState<[] | RowTable[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (rawData) {
      setRows([]);
      rawData.rows.forEach((item: any, index: number) => {
        setRows((prevState) => [
          ...prevState,
          {
            open: "",
            event: item.row[0],
            eventName: item.row[1],
            status: item.row[2],
            distance: item.row[5],
            impact: item.row[4],
            distanceNumber: item.row[3],
            extra: item.extra,
            index: index,
          },
        ]);
      });
    }
  }, []);

  return rows.length > 0 ? (
    <TableChart
      rows={rows}
      columns={headCells(isDarkTheme, t)}
      filterTypes={rawData.filter_types}
    />
  ) : (
    <></>
  );
};
export default DataTableChart;
