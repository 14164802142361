import { Grid } from "@material-ui/core";
import { ToggleSlider } from "common-components";
import { CustomLegend } from "../../../charts/CustomLegend";
import { Bar } from "react-chartjs-2";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  barComparisons,
  darkBarComparisons,
  darkText,
  darkThemeGridLines,
  greyAxis,
} from "../../../services/colors";
import { ANIMATION_DURATION } from "../../../services/consts";
import { FONT } from "../../../services/fonts";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { hexToRgbA } from "../../../services/helpers";
import { labelData } from "../tama/TamaLineChart";
import { useCustomTooltip } from "../../../charts/CustomTooltip";
import { useIsMobileScreen } from "common-components";
import ChartTooltip from "../../../charts/chartTooltip/ChartTooltip";
import {
  disableScrolling,
  enableScrolling,
} from "../../../charts/mortgage/MortgageLineChart";
import { useTranslation } from "react-i18next";
import TitleGrid from "../../TitleGrid";

export function addPercentage(value) {
  return value + "%";
}

const groupedBarData = (t, data, year, isDarkTheme) => {
  return {
    labels: data.labels.map((item) => t(`labels.${item}`)),
    datasets: data.data[year].series.map((item, index) => {
      return {
        data: [...item.data],
        label: t(`labels.${item.name}`),
        backgroundColor: isDarkTheme
          ? darkBarComparisons[index]
          : barComparisons[index],
        hoverBackgroundColor: isDarkTheme
          ? hexToRgbA(darkBarComparisons[index], 0.7)
          : hexToRgbA(barComparisons[index], 0.7),
      };
    }),
  };
};

const ComparativeGraph = ({ data }) => {
  const { t } = useTranslation();
  const keys = Object.keys(data.data);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [year, setYear] = useState(keys[0]);
  const [barData, setBarData] = useState(
    groupedBarData(t, data, year, isDarkTheme)
  );

  const chartRef = useRef();
  const [index, setCurrentIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );
  const isMobileScreen = useIsMobileScreen();

  function colorFactory(context) {
    const index = context.dataIndex;
    const value = context.dataset.data[index];
    return value <= 10 ? (isDarkTheme ? darkText : "#000") : "#ffff";
  }

  function anchorFactory(context) {
    const index = context.dataIndex;
    const value = context.dataset.data[index];
    return value <= 10 ? "end" : "center";
  }

  function alignmnetactory(context) {
    const index = context.dataIndex;
    const value = context.dataset.data[index];
    return value <= 10 ? "top" : "center";
  }

  useEffect(() => {
    setBarData(groupedBarData(t, data, year, isDarkTheme));
  }, [year]);

  const chartJsOptionsDonut = useMemo(() => {
    return {
      maintainAspectRatio: false,
      barValueSpacing: 20,
      animation: {
        duration: ANIMATION_DURATION,
      },
      legend: {
        display: false,
      },
      plugins: {
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
        datalabels: {
          color: colorFactory,
          formatter: addPercentage,
          font: {
            size: isMobileScreen ? 9 : FONT,
            family: "Open Sans Hebrew, sans-serif",
          },
          anchor: anchorFactory,
          align: alignmnetactory,
          textAlign: "center",
          clamp: true,
        },
      },
      tooltips: {
        enabled: false,
        rtl: true,
        custom: customTooltip,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: FONT,
              fontFamily: "Open Sans Hebrew, sans-serif",
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              drawBorder: false,
              display: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              display: false,
            },
          },
        ],
      },
    };
  }, [isMobileScreen]);

  const list = keys.map((item, index) => {
    return { value: item, text: `${t("yearContraction")} ${item}` };
  });

  return (
    <Grid item xs={12} container spacing={2}>
      <TitleGrid title={t("ComparativeGraph.title")} />

      <Grid container item xs={12} justify={"space-between"} spacing={2}>
        <Grid item>
          <ToggleSlider
            radius
            value={year}
            setValue={setYear}
            list={list}
            isDarkTheme={isDarkTheme}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomLegend
            legend={labelData(
              t,
              data.data[year],
              isDarkTheme,
              barComparisons,
              darkBarComparisons,
              "series"
            )}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ minHeight: 300 }}
        onTouchStart={() => {
          disableScrolling();
        }}
        onTouchEnd={() => {
          enableScrolling();
        }}
      >
        <Bar ref={chartRef} data={barData} options={chartJsOptionsDonut} />
        <ChartTooltip
          data={barData}
          title={{ title: t("ComparativeGraph.totalTransactions") }}
          position={position}
          id={"barComparative"}
          index={index}
          tooltipRef={tooltipRef}
          dataToMap={barData.datasets}
          sign={"%"}
          signPosition={"right"}
        />
      </Grid>
    </Grid>
  );
};
export default ComparativeGraph;
