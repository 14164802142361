import Grid from "@material-ui/core/Grid";
import { darkBackground, darkText } from "../services/colors";
import Button from "@material-ui/core/Button";
import React, { useContext } from "react";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { LoadingButtons } from "./layout/Layout";
import { useTranslation } from "react-i18next";

const SubmitAndClearButtons = ({
  isLoading,
  onClearBtn,
  submitTitle,
  disabled,
  onClickBtn,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="text"
          type={onClearBtn ? "button" : "submit"}
          onClick={onClearBtn ? onClearBtn : ""}
          disabled={disabled ? disabled : isLoading}
          disableFocusRipple
          disableRipple
          style={{
            padding: "6px 0",
            justifyContent: "flex-start",
            color: isDarkTheme ? darkText : "rgba(0, 0, 0, 0.3)",
            fontWeight: 600,
            backgroundColor: isDarkTheme ? darkBackground : "#fff",
          }}
        >
          {isLoading ? (
            <LoadingButtons style={{ padding: "3px 0" }} color={"inherit"} />
          ) : (
            `${t("clear")}`
          )}
        </Button>
      </Grid>
      <Grid item xs={9}>
        <Button
          type={onClickBtn ? "button" : "submit"}
          variant={"contained"}
          color="primary"
          onClick={onClickBtn ? onClickBtn : ""}
          disabled={disabled ? disabled : isLoading}
          style={{
            width: "100%",
          }}
        >
          {isLoading ? (
            <LoadingButtons style={{ padding: "3px 0" }} />
          ) : (
            submitTitle
          )}
        </Button>
      </Grid>
    </>
  );
};
export default SubmitAndClearButtons;
