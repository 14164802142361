import React, { createContext, useState } from "react";

export const PaymentProcessContext = createContext<any>({});
export const PaymentProcessContextProvider: React.FC = ({ children }) => {
	const [paymentProcess, setPaymentProcess] = useState<boolean>(false);

	return (
		<PaymentProcessContext.Provider value={[paymentProcess, setPaymentProcess]}>
			{children}
		</PaymentProcessContext.Provider>
	);
};
