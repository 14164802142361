import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import TopbarComponent from "./components/top-bar/TopbarComponent";
import Footer from "./components/Footer";
import ContextProviders from "./components/providers/ContextProviders";
import SwitchRoutes from "./components/routes/SwitchRoutes";
import { clearSessionStorage } from "./services/helpers";

const App: React.FC = () => {
  useEffect(() => {
    function handler() {
      clearSessionStorage();
    }
    window.addEventListener("beforeunload", handler);
    return () => window.removeEventListener("beforeunload", handler);
  }, []);
  window.scrollTo(0, 0);

  return (
    <div style={{ height: "100%" }}>
      <Router>
        <ContextProviders>
          <TopbarComponent />
          <Switch>
            <SwitchRoutes />
          </Switch>
          <Footer />
        </ContextProviders>
      </Router>
    </div>
  );
};

export default App;
