import { InputLabel } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { randomInt } from "../../components/dashboard-components/global-summary/RowBoxCurrent";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    "& input::placeholder": {
      fontSize: 14,
    },
  },
});

const InputProp = ({
  xsInput,
  smInput,
  mdInput,
  lgInput,
  classesLabel,
  classesInput,
  classesError,
  register,
  item,
  errors,
  property,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={xsInput} sm={smInput} md={mdInput} lg={lgInput}>
      <InputLabel className={classesLabel} style={{ whiteSpace: "nowrap" }}>
        {item.label}
      </InputLabel>
      <TextField
        id={item.name}
        name={item.name}
        autoComplete="off"
        error={errors[`${item.name}`] !== undefined}
        placeholder={item.placeholder}
        variant="outlined"
        size="small"
        style={{ width: "100%" }}
        defaultValue={
          property[item.name] && property[item.name] !== 0
            ? property[item.name]
            : ""
        }
        InputProps={{
          className: `${classesInput} ${classes.root}`,
          inputProps: {
            inputMode: item.inputMode,
          },
        }}
        onFocus={(event) => {
          event.target.select();
        }}
        {...register(item.name, item.register)}
      />
      {item.errors.map((itemError, index) => {
        if (errors[item.name]?.type === itemError.type) {
          return (
            <Typography key={randomInt() + index} className={classesError}>
              {itemError.text}
            </Typography>
          );
        } else {
          return null;
        }
      })}
    </Grid>
  );
};
export default InputProp;
