import { useContext, useState } from "react";
import { Grid, Input, InputAdornment, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useNotistack } from "../../../global/hooks/useNotistack";
import { useIsLargeScreen } from "../../../global/hooks/useIsLargeScreen";
import { useForm } from "react-hook-form";
import { PropdoButton } from "../../../global/components/buttons/PropdoButton";
import {
  borderRadius,
  grey_3,
  grey_4,
  grey_5,
  negative,
  white,
} from "../../../global/services/colors";
import {
  FONT_SUBTITLE,
  FONT_TEXT_SECONDARY,
  FONT_TITLE,
} from "../../../global/services/constants/fonts";
import { useDirection } from "../../../global/context/DirectionContext";
import { apiFetch } from "../../../global/api/apiFetch";

export const SendEmail: React.FC<{}> = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isDesktopScreen = useIsLargeScreen("md");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [userDetails, setUserDetails] = useState({ email: "" });
  const { direction } = useDirection();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  useNotistack(
    t("footerEmail.joinSuccess"),
    isSubscribed,
    setIsSubscribed,
    "success"
  );

  const onSubmit = (value) => {
    apiFetch(
      `/user/subscribe?country=${direction?.country}&region=${direction?.region}`,
      {
        method: "POST",
        body: JSON.stringify({ email: value.email }),
      }
    ).then((data) => {
      setIsSubscribed(true);
      setValue("email", "");
      setTimeout(() => {
        setIsSubscribed(false);
      }, 4500);
    });
  };

  return (
    <>
      <Grid container>
        <Typography className={classes.titleEmail}>
          {t("footer.title4")}
        </Typography>
        <Grid
          item
          xs={8}
          md={8}
          style={{
            backgroundColor: white,
            borderRadius: borderRadius,
            padding: "5px 12px",
            border: `1px solid ${grey_3}`,
          }}
        >
          <Grid container alignItems="center" item xs={11} md={11}>
            <Input
              placeholder={t("footer.email")}
              id="email"
              type={"text"}
              disableUnderline={true}
              style={{
                border: "none",
                backgroundColor: "transparent",
                height: "100%",
                width: "100%",
                fontSize: FONT_SUBTITLE,
              }}
              endAdornment={
                <InputAdornment
                  children={false}
                  position="end"
                  style={{ marginLeft: 0, cursor: "pointer" }}
                ></InputAdornment>
              }
              {...register("email", {
                required: "Email is required",
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
          </Grid>
        </Grid>
        <PropdoButton
          text={t("footerEmail.subscribe")}
          style={{
            width: "54px",
            backgroundColor: grey_4,
            color: grey_5,
            marginRight: "10px",
          }}
          icon={null}
          handleClick={handleSubmit(onSubmit)}
        />
        <Grid item xs={12} md={12}>
          {errors.email?.type === "required" && (
            <p className={classes.errorMsg}>{t("footerEmail.requiredMsg")}</p>
          )}
          {errors.email?.type === "pattern" && (
            <p className={classes.errorMsg}>{t("footerEmail.patternMsg")}</p>
          )}
        </Grid>
        <Grid container item xs={12}>
          <Typography className={classes.subtitle}>
            {t("footer.subtitle10")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  errorMsg: {
    color: negative,
    fontWeight: 600,
  },
  titleEmail: {
    color: white,
    fontSize: FONT_TITLE,
    fontWeight: 600,
    paddingBottom: "10px",
  },
  subtitle: {
    color: white,
    fontSize: FONT_TEXT_SECONDARY,
    lineHeight: "2",
    marginTop: 10,
  },
});
