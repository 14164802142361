import Cookies from "js-cookie";
import { DOMAIN } from "../api/api_links";
import { Auth } from "aws-amplify";
import awsmobile from "../../aws-exports";

export function hexToRgbA(hex, opacity) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      `,${opacity})`
    );
  }
  console.log(hex, " HEX Error");
  return "";
}
export function isLoggedIn() {
  return hasValueInStorage(getIdToken());
}

export function isFederationLogin() {
  const clientId = Cookies.get("clientId", {
    domain: DOMAIN,
  });

  const userLogin = localStorage.getItem(
    `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`
  );

  return (
    userLogin &&
    (userLogin.startsWith("facebook") || userLogin.startsWith("google"))
  );
}
export function hasValueInStorage(item) {
  return item && item !== "null" && item !== "undefined" && item !== "0";
}

/**
 * Takes number and formats it to UK format (Splitting each 3 digits with comma)
 * Ex: 123456789 -> "123,456,789"
 */
export function addingComma(number) {
  if (number === undefined || !number || number.toString().length <= 3)
    return number;
  const noComma = number.toString();

  const iter = Math.floor(noComma.length / 3);
  let returnString = "";
  let endIndex = noComma.length;
  let startIndex = noComma.length - 3;
  for (let i = 1; i <= iter; i++) {
    if (i === 1) {
      returnString = returnString.replace(
        /^/,
        noComma.slice(startIndex, endIndex)
      );
    } else {
      returnString = returnString.replace(
        /^/,
        noComma.slice(startIndex, endIndex) + ","
      );
    }

    endIndex = endIndex - 3;
    startIndex = startIndex - 3;
  }
  if (noComma.length % 3 !== 0) {
    returnString = returnString.replace(
      /^/,
      noComma.slice(0, noComma.length % 3) + ","
    );
  }
  return returnString;
}

export function removeComma(string) {
  const noComa = string.replaceAll(",", "");
  return Number(noComa);
}

export function clearSessionStorage() {
  sessionStorage.clear();
}

export async function refreshToken() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (err) throw err;
      console.log("currentSession.refreshToken", currentSession.refreshToken);
    });
  } catch (e) {
    console.log(e);
  }
}

export function randomInt() {
  return Math.floor(Math.random() * 3000000);
}

export function clearAuthCookies() {
  Cookies.remove("IdToken", { domain: DOMAIN });
  Cookies.remove("RefreshToken", { domain: DOMAIN });
}

export function setCookies(name, value) {
  Cookies.set(name, value, {
    domain: DOMAIN,
  });
}

export function getIdToken() {
  const username = Cookies.get("username", {
    domain: DOMAIN,
  });
  const clientId = Cookies.get("clientId", {
    domain: DOMAIN,
  });

  return localStorage.getItem(
    `CognitoIdentityServiceProvider.${clientId}.${username}.idToken`
  );
}

export function setAuthCookies(item) {
  setCookies(
    "IdToken",
    item.pool.storage[
      `CognitoIdentityServiceProvider.${item.pool.clientId}.${item.username}.idToken`
    ]
  );
  setCookies(
    "RefreshToken",
    item.pool.storage[
      `CognitoIdentityServiceProvider.${item.pool.clientId}.${item.username}.refreshToken`
    ]
  );
}
