import Grid from "@material-ui/core/Grid";
import React, { useContext, useEffect, useState } from "react";
import TopBarDetails from "../../top-bar/TopBarDetails";
import Typography from "@material-ui/core/Typography";
import { LG_PLUS_FONT, MD_PLUS_FONT, SM_FONT } from "../../../services/fonts";
import { darkMainPropColor } from "../../../services/colors";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CreateIcon from "@material-ui/icons/Create";
import { doFetch } from "common-components";
import { useNotistack } from "common-components";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { useIsMobileScreen } from "common-components";
import { useIsIncludes } from "common-components";
import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { LoadingButtons } from "../../layout/Layout";
import { fetchFolders } from "../../../dialogs/add-prop/LinkProject";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import {
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_GARDEN,
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_PENTHOUSE,
} from "../../../dialogs/add-prop/SelectPropDetails";
import { hebrewMonth } from "./RowBoxCurrent";
import FabShareButton from "../../top-bar/FabShareButton";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  root: {
    width: "90%",
    "& div": {
      height: 32,
    },
    "& div label": {
      transform: "translate(0, -10px) scale(1)",
    },
  },
  inputRoot: {
    marginTop: "0px !important",
  },
  icon: {
    color: darkMainPropColor,
    width: 22,
    height: 22,
  },
});

const PropertyDetails = ({ details, setRawData }) => {
  const [direction] = useContext(DirectionContext);
  const { t } = useTranslation();

  const [propertyId] = useContext(PropertyIdContext);
  const isMobileScreen = useIsMobileScreen();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [isChangeProject, setIsChangeProject] = useState(false);
  const [isCloseBtn, setIsCloseBtn] = useState(false);
  const [updateProject, setUpdateProject] = useState({
    title:
      details.premium?.project && details.premium?.building
        ? `${details.premium?.project} (${details.premium?.building})`
        : t("PropertyDetails.projectName"),
    project: details.premium?.project,
    building: details.premium?.building,
  });
  const [options, setOptions] = useState(null);

  const [isSaved, setIsSaved] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState(null);

  const [data, setData] = useState(null);

  const isShare = useIsIncludes("share");
  const isDemo = useIsIncludes("demo");

  useNotistack(t("updateSucceedMsg"), isSaved, setIsSaved, "success");
  useNotistack(t("updateFailedMsg"), error, setError, "error");

  useEffect(() => {
    setData(details);
  }, [details]);

  useEffect(() => {
    if (!data) return;
    setDate(data.property_details.date.split("-"));
    setFormattedAddress(data.property_details.address);
    setUpdateProject({
      title:
        data.premium?.project && data.premium?.building
          ? `${data.premium?.project} (${data.premium?.building})`
          : t("PropertyDetails.projectName"),
      project: data.premium?.project,
      building: data.premium?.building,
    });
  }, [data]);

  useEffect(() => {
    if (!isShare && !isDemo && isDarkTheme) {
      fetchFolders(setOptions, setError);
    }
  }, [isDemo, isDarkTheme]);

  useEffect(() => {
    if (!data) return;
    if (
      (data.property_details.details[0] === PROPERTY_TYPE_APARTMENT ||
        data.property_details.details[0] === PROPERTY_TYPE_PENTHOUSE ||
        data.property_details.details[0] === PROPERTY_TYPE_GARDEN) &&
      data.property_details.apartment
    ) {
      const apartment = `דירה ${data.property_details.apartment},`;
      const address = data.property_details.address.split(",");
      const formatAddress = [
        "רח'",
        ...address.slice(0, 1),
        apartment,
        ...address.slice(1, address.length),
      ];
      setFormattedAddress(formatAddress.join(" "));
    }
  }, [data, formattedAddress]);

  const handleChange = (ev, value) => {
    setIsCloseBtn(false);
    setIsChangeProject(true);
    setUpdateProject(value);
  };

  const handleSave = () => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id") ? queryParams.get("id") : "-1";
    doFetch(`/reports/premium/property/update?id=${id}`, {
      method: "POST",
      body: JSON.stringify({
        ...details.premium,
        project: updateProject.project,
        building: updateProject.building,
      }),
    }).then((data) => {
      if (data.message.toLowerCase() === "Premium Updated".toLowerCase()) {
        setIsSaved(true);
        setTimeout(() => {
          setIsChangeProject(false);
          setIsCloseBtn(false);
        }, 300);

        setRawData({
          ...details,
          premium: {
            ...details.premium,
            project: updateProject.project,
            building: updateProject.building,
          },
        });
      } else {
        setError(true);
      }
      setLoading(false);
    });
  };

  const handleClickBtn = () => {
    setIsCloseBtn(true);
    if (isChangeProject) {
      handleSave();
    }
  };

  const [date, setDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState([]);

  useEffect(() => {
    if (!date) return;
    const month = +date[1];
    const currMonth = `${t(`months.${month}`)}`;
    setFormattedDate([date[0], currMonth, parseInt(date[2])]);
  }, [date]);

  const classes = useStyles();

  return (
    data && (
      <Grid container>
        <Grid item xs={12}>
          <Typography style={{ paddingBottom: 10, fontSize: LG_PLUS_FONT }}>
            {t("PropertyDetails.ReportDate")}
            {direction.isRtl && " " + formattedDate[2] + " "}
            <bdi>
              {direction.isRtl && "ב"}
              {formattedDate[1]}
            </bdi>
            {" " + formattedDate[0] + " "}
          </Typography>
        </Grid>

        {details.premium && (
          <Grid
            container
            alignItems="center"
            item
            xs={12}
            style={{ paddingBottom: 10, position: "relative" }}
          >
            <Grid item xs={10}>
              {isChangeProject || isCloseBtn ? (
                <Autocomplete
                  id="project"
                  options={options}
                  getOptionLabel={(option) => option.title}
                  classes={classes}
                  defaultValue={updateProject ? updateProject.title : ""}
                  autoComplete
                  noOptionsText={t("noResults")}
                  includeInputInList
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      {...params}
                      label={t("PropertyDetails.projectLabel")}
                      variant="standard"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              ) : (
                <Typography style={{ fontSize: MD_PLUS_FONT }}>
                  {updateProject.title}
                </Typography>
              )}
            </Grid>
            <Grid container item xs={2} justify={"flex-end"}>
              {!isShare && (
                <IconButton
                  style={{
                    padding: 0,
                  }}
                  onClick={handleClickBtn}
                >
                  {loading ? (
                    <LoadingButtons
                      color={darkMainPropColor}
                      size={"1x"}
                      zIndex={9999}
                    />
                  ) : isCloseBtn ? (
                    <ClearIcon className={classes.icon} />
                  ) : isChangeProject ? (
                    <CheckIcon className={classes.icon} />
                  ) : (
                    <CreateIcon className={classes.icon} />
                  )}
                </IconButton>
              )}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} style={{ paddingBottom: 3 }}>
          <Typography
            style={{
              fontSize: !isDarkTheme || isDemo ? LG_PLUS_FONT : MD_PLUS_FONT,
            }}
          >
            {data.property_details.gush_halka
              ? data.property_details.gush_halka
              : formattedAddress}
          </Typography>
        </Grid>
        <Grid container item xs={12} style={{ paddingBottom: 10 }}>
          <Grid container item xs={12} style={{ paddingBottom: 3 }}>
            <Typography>{`${t(
              `PropertyTypes.${data.property_details.details[0]}`
            )}`}</Typography>{" "}
            |
            {data.property_details.details[0] !== PROPERTY_TYPE_LAND ? (
              <Typography
                style={{
                  marginRight: 3,
                  fontSize: isMobileScreen && SM_FONT,
                }}
              >
                {data.property_details.details[6]} {t("PropertyDetails.Rooms")}{" "}
                |
              </Typography>
            ) : (
              <Typography
                style={{
                  marginRight: 3,
                  fontSize: isMobileScreen && SM_FONT,
                }}
              >
                {" "}
                {t("PropertyDetails.Sqm")} |
              </Typography>
            )}
            {data.property_details.details[0] !== PROPERTY_TYPE_LAND ? (
              <Typography
                style={{
                  marginRight: 3,
                  fontSize: isMobileScreen && SM_FONT,
                }}
              >
                {data.property_details.details[2]} {t("PropertyDetails.Sqm")}|
              </Typography>
            ) : (
              <Typography
                style={{
                  marginRight: 3,
                  fontSize: isMobileScreen && SM_FONT,
                }}
              >
                {" "}
                {t("PropertyDetails.BuildingRights")} |
              </Typography>
            )}
            {data.property_details.details[0] === PROPERTY_TYPE_APARTMENT && (
              <Typography
                style={{
                  marginRight: 3,
                  fontSize: isMobileScreen && SM_FONT,
                }}
              >
                {t("PropertyDetails.Floor")} {data.property_details.details[4]}{" "}
                `$
                {t("PropertyDetails.From")} {data.property_details.details[5]}
              </Typography>
            )}
            {data.property_details.details[0] === PROPERTY_TYPE_LAND && (
              <Typography
                style={{
                  marginRight: 3,
                  fontSize: isMobileScreen && SM_FONT,
                }}
              >
                {t("PropertyDetails.BuildingLevel")} |
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            {details && data.property_details.details && (
              <TopBarDetails details={data.property_details.details} />
            )}
          </Grid>
          {!isShare && <FabShareButton />}
        </Grid>
      </Grid>
    )
  );
};
export default PropertyDetails;
