import { ButtonSwitchSearch } from "common-components";
import SearchPlaces from "../SearchPlaces";
import SearchGushHalka from "./SearchGushHalka";
import { useIsMobileScreen } from "common-components";
import useContextProps from "../../hooks/useContextProps";

const TopBarSearch = ({
  popup,
  isAddressSwitch,
  setIsAddressSwitch,
  btnColor,
}) => {
  const context = useContextProps();
  const isSmallScreen = useIsMobileScreen();

  if (
    (!isSmallScreen || popup) &&
    (!context.isOpenAccess.access || context.isOpenAccess.page === "demo") &&
    context.isOpenAccess.page !== "/user"
  ) {
    return (
      <>
        <ButtonSwitchSearch
          isAddressSwitch={isAddressSwitch}
          setIsAddressSwitch={setIsAddressSwitch}
          context={context}
        />
        <div style={{ width: "100%" }}>
          {isAddressSwitch ? (
            <SearchPlaces
              margin={0}
              width={"100%"}
              maxWidth={""}
              place={""}
              btnColor={
                context.isDarkTheme ? "primary" : btnColor ? btnColor : false
              }
            />
          ) : (
            <SearchGushHalka
              width={"100%"}
              place={"top"}
              openSelectProp
              btnColor={btnColor}
            />
          )}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
export default TopBarSearch;
