import React, { useContext } from "react";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import IconButton from "@material-ui/core/IconButton";
import { PROPERTY_TYPE_LAND } from "../../../dialogs/add-prop/SelectPropDetails";
import { darkText } from "../../../services/colors";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { LightTooltip } from "../TableReportsBody";
import { useTranslation } from "react-i18next";

const TableOpenIcon = ({ setOpen, row, open }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();
  return (
    <LightTooltip
      title={t("details")}
      arrow
      isDarkTheme={isDarkTheme}
      enterTouchDelay={50}
    >
      <IconButton
        size="small"
        style={
          row.type === PROPERTY_TYPE_LAND
            ? {}
            : { color: isDarkTheme ? darkText : "rgba(0, 0, 0, 0.54)" }
        }
        onClick={() => {
          if (open.includes(row.id)) {
            const newOpen = open.filter((item) => item !== row.id);
            setOpen(newOpen);
          } else {
            setOpen((state) => [...state, row.id]);
          }
        }}
      >
        {open.includes(row.id) ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </IconButton>
    </LightTooltip>
  );
};
export default TableOpenIcon;
