import { Grid } from "@material-ui/core";
import DemoExample from "../DemoExample";
import DataRadarChart from "../../data/DataRadarChart";
import { LoadingCharts } from "../layout/Layout";
import DataBarChar from "../../data/DataBarChar";
import DataDonutChart from "../../data/DataDonutChart";
import DataPosConChart from "../../data/DataPosConChart";
import React from "react";
import { useIsIncludes } from "common-components";
import TilesContainer from "../TilesContainer";
import { useIsMobileScreen } from "common-components";
import { useTranslation } from "react-i18next";

const Segmentation = ({ SPACING_FACTOR, rawData }) => {
  const isDemo = useIsIncludes("demo");
  const isSmallScreen = useIsMobileScreen();
  const { t } = useTranslation();
  return (
    <Grid
      name="segmentation"
      container
      item
      spacing={SPACING_FACTOR}
      style={{ paddingBottom: isSmallScreen ? 24 : "" }}
    >
      <Grid item xs={12} sm={12} md={6}>
        <TilesContainer style={{ height: "100%" }} relative>
          {isDemo && <DemoExample />}
          {rawData ? (
            <DataPosConChart rawData={rawData.pos_neg_chart} />
          ) : (
            <LoadingCharts variant={"h5"} name={t("PosConChart.title")} />
          )}
        </TilesContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TilesContainer style={{ height: "100%" }} relative>
          {isDemo && <DemoExample />}
          {rawData ? (
            <DataBarChar rawData={rawData.pie_chart} />
          ) : (
            <LoadingCharts variant={"h5"} name={t("BarChart.title")} />
          )}
        </TilesContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TilesContainer style={{ height: "100%" }} relative>
          {isDemo && <DemoExample />}
          {rawData ? (
            <DataDonutChart dataProp={rawData.donut_chart} />
          ) : (
            <LoadingCharts variant={"h5"} name={t("DonutChart.title")} />
          )}
        </TilesContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TilesContainer style={{ height: "100%" }} relative>
          {isDemo && <DemoExample />}
          {rawData ? (
            <DataRadarChart rawData={rawData.radar_chart} />
          ) : (
            <LoadingCharts variant={"h5"} name={t("RadarChart.title")} />
          )}
        </TilesContainer>
      </Grid>
    </Grid>
  );
};
export default Segmentation;
