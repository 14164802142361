import React, { useContext, useState } from "react";
import { Button } from "@material-ui/core";
import { useIsMobileScreen } from "common-components";
import { OpenAccessContext } from "../../context/OpenAccessContext";
import Logo from "./Logo";
import Balance from "./Balance";
import AvatarTopBar from "./AvatarTopBar";
import { useStylesTopBar } from "../../styledComponent/TopBarStyled";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { useIsIncludes } from "common-components";
import { handleBuyListings } from "../listings/Property/Tiles/BackTilePreview";
import TopBarRoutes from "./TopBarRoutes";
import HamburgerMenu from "./hamburger/HamburgerMenu";
import TopBarSearch from "./TopBarSearch";
import { DarkThemeContext } from "../../context/DarkThemeContext";

const TopBar: React.FC = () => {
  const { openDialog } = useDialogManager();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [isAddressSwitch, setIsAddressSwitch] = useState(true);
  const isSmallScreen = useIsMobileScreen();
  const isListings = useIsIncludes("listings");
  const props = { isDarkTheme, isListings, isSmallScreen };
  const classes = useStylesTopBar(props);
  const isMediumScreen = useIsMobileScreen("md");

  return (
    <div className={classes.topBar}>
      <Logo />

      <div className={classes.divTopBar}>
        {!isSmallScreen && <TopBarRoutes />}

        <TopBarSearch
          popup={false}
          btnColor={false}
          setIsAddressSwitch={setIsAddressSwitch}
          isAddressSwitch={isAddressSwitch}
        />

        <div
          className={classes.divFlexCenter}
          style={{
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {!isOpenAccess.access && !isSmallScreen && (
            <Balance isMediumScreen={isMediumScreen} />
          )}

          {isOpenAccess.page === "/id" && (
            <Button
              variant={"contained"}
              color={"secondary"}
              style={{ marginLeft: isSmallScreen ? 10 : 35 }}
              className={classes.listingsBtn}
              onClick={() => handleBuyListings(openDialog)}
            >
              לחשיפת הכתובת
            </Button>
          )}
        </div>
      </div>
      {isSmallScreen ? <HamburgerMenu /> : <AvatarTopBar />}
    </div>
  );
};

export default TopBar;
