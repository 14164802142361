import React, { useContext, useEffect, useRef, useState } from "react";
import { AppBar } from "@material-ui/core";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import MessagePurchaseProperty from "../../dialogs/dialog-components/MessagePurchaseProperty";
import { useIsIncludes } from "common-components";
import { useHistory, useLocation } from "react-router-dom";
import Forecast from "../dashboard-components/forecast/Forecast";
import TableDashboard from "../dashboard-components/TableDashboard";
import Comparative from "../dashboard-components/comparative/Comparative";
import MortgageDashboard from "../dashboard-components/MortgageDashboard";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TamaComponent from "../dashboard-components/tama/TamaComponent";
import { useStylesLayout } from "../../styledComponent/LayoutStyled";
import SummaryPrice from "../dashboard-components/global-summary/SummaryPrice";
import Rent from "../dashboard-components/Rent";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import {
  borderColor,
  color3,
  darkBlue,
  darkBorderColor,
} from "../../services/colors";
import { useScroolTotop } from "../../hooks/useScrollToTop";
import {
  PROPERTY_TYPE_LAND,
  propTypeConverter,
} from "../../dialogs/add-prop/SelectPropDetails";
import { Routes } from "../../services/routes";
import { useIsMobileScreen } from "common-components";
import { PropertyIdContext } from "../../context/PropertyIdContext";
import { MD_FONT, XS_PLUS_FONT } from "../../services/fonts";
import { scroller } from "react-scroll";
import { detectVal, handleSwitch } from "./Layout";
import RenovationsComponent from "../dashboard-components/renovations/RenovationsComponent";
import BuildingRightsComponent from "../dashboard-components/buildingRights/BuildingRightsComponent";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";
import { DisabledTabsContext } from "../../context/DisabledTabsContext";
import useContextProps from "../../hooks/useContextProps";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}
export const AntTabs = withStyles({
  root: {
    "&.MuiTabs-flexContainer": {},
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);

const AntTab = withStyles({
  root: (props) => ({
    textTransform: "none",
    borderBottom: `1px solid ${
      props.isDarkTheme ? darkBorderColor : borderColor
    }`,
    borderRadius: "var(--br-top)",
    transition: "all 0.3s",
    "&.MuiTab-root": {
      fontSize: MD_FONT,
    },
    "&:hover": {
      backgroundColor: props.isDarkTheme ? "#282735" : "#f7f7f7",
    },
    "&.MuiTab-textColorPrimary.Mui-selected": {
      border: `1px solid ${props.isDarkTheme ? darkBorderColor : borderColor}`,
      borderBottom: "none",
      backgroundColor: "#e1edf7",
    },
  }),
})((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles({
  headerTab: {
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "default",
    },
  },
  header: {
    position: "relative",
    right: 0,
    zIndex: 998,
    boxShadow: "none",
    maxWidth: 1380,
    margin: "0 auto",
  },
  content: {
    height: "100%",
    maxWidth: 1380,
    margin: "0 auto",
  },
  ribbon: (props) => ({
    position: "relative",
    "&:after": {
      content: "attr(ribbonText)",
      textTransform: "capitalize",
      position: "absolute",
      transform: "rotate(35deg) translate3d(0,0,0)",
      color: "white",
      fontSize: XS_PLUS_FONT,
      height: 15,
      width: 87,
      backgroundColor: props.isDarkTheme ? darkBlue : color3,
      left: props.isMobileScreen ? 28 : 94,
      top: props.isMobileScreen ? 6 : 8,
      paddingBottom: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
});
function scrollTo() {
  scroller.scrollTo("scrollToTabs", {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: -70,
  });
}

export const handleSwitchTab = (
  pathname,
  newValue,
  propertyId,
  history,
  setValue,
  setIsOpenMenu
) => {
  const queryParams = new URLSearchParams(window.location.search);
  const urlId = queryParams.get("id");
  const id = urlId ? urlId : "-1";

  history.push({
    pathname: pathname,
    search: `?id=${id}`,
  });

  setValue(newValue);
  if (setIsOpenMenu) {
    setIsOpenMenu(false);
  }
  scrollTo();
};

const ContentAreaLayout = ({ SPACING_FACTOR }) => {
  const [direction] = useContext(DirectionContext);
  const [propertyId, setPropertyId] = useContext(PropertyIdContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [disabledTabs, setDisabledTabs] = useContext(DisabledTabsContext);
  const [toggleBRCall, setToggleBRCall] = useState(false);
  const [summaryPrice, setSummaryPrice] = useState(undefined);
  const [month, setMonth] = useState(null);
  const [value, setValue] = useState(detectVal());
  const { openDialog } = useDialogManager();
  const history = useHistory();
  let historyRef = useRef();
  historyRef.current = history;
  const isListings = useIsIncludes("listings");
  const isDemo = useIsIncludes("demo");
  const isShare = useIsIncludes("share");
  const context = useContextProps();

  const isMobileScreen = useIsMobileScreen();
  const isLeptopScreen = useIsMobileScreen(1000);
  const { t } = useTranslation();
  let timeoutID;

  const queryParams = new URLSearchParams(window.location.search);
  const urlId = queryParams.get("id");
  const propId = urlId ? urlId : "-1";

  useScroolTotop();

  useEffect(() => {
    if (isListings) {
      timeoutID = setTimeout(() => {
        openDialog(MessagePurchaseProperty, {
          DialogProps: { fullScreen: false, scroll: "paper" },
        });
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  const classesLA = useStylesLayout();
  const handleChange = (event, newValue) => {
    setPropertyId({ id: propId });
    setValue(newValue);
  };

  const classes = useStyles({ isDarkTheme, isMobileScreen });

  let location = useLocation();

  useEffect(() => {
    setValue(detectVal());
  }, [location]);

  useEffect(() => {
    if (!summaryPrice) return;
    if (
      summaryPrice.property_details.details[0] ===
        propTypeConverter(PROPERTY_TYPE_LAND()) ||
      Number(summaryPrice?.property_details.details[3]) > 2000
    ) {
      setDisabledTabs([Routes.dashboardTama, Routes.dashboardRent]);
    }
  }, [summaryPrice]);

  return (
    <div id="right" className={classesLA.divWidth}>
      <div style={{ height: "100%" }}>
        <SummaryPrice
          rawData={summaryPrice}
          setRawData={setSummaryPrice}
          month={month}
          setMonth={setMonth}
        />
        <div style={{ paddingTop: 3 }}>
          <AppBar
            className={classes.header}
            color={isDarkTheme ? "" : "secondary"}
            name="scrollToTabs"
          >
            <AntTabs
              value={value}
              isDarkTheme={isDarkTheme}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={isLeptopScreen ? "on" : "off"}
              TabScrollButtonProps={{ disabled: false }}
            >
              <AntTab
                label={`${t("Tabs.summary")}`}
                isDarkTheme={isDarkTheme}
                onClick={() =>
                  handleSwitchTab(
                    handleSwitch(direction.region, isDemo, isShare, "Summary"),
                    0,
                    propertyId,
                    history,
                    setValue
                  )
                }
              />
              <AntTab
                label={`${t("Tabs.impact")}`}
                isDarkTheme={isDarkTheme}
                onClick={() =>
                  handleSwitchTab(
                    handleSwitch(direction.region, isDemo, isShare, "Impact"),
                    1,
                    propertyId,
                    history,
                    setValue
                  )
                }
              />
              <AntTab
                label={`${t("Tabs.comparisons")}`}
                isDarkTheme={isDarkTheme}
                onClick={() =>
                  handleSwitchTab(
                    handleSwitch(
                      direction.region,
                      isDemo,
                      isShare,
                      "Comparisons"
                    ),
                    2,
                    propertyId,
                    history,
                    setValue
                  )
                }
              />
              <AntTab
                label={`${t("Tabs.loans")}`}
                isDarkTheme={isDarkTheme}
                onClick={() =>
                  handleSwitchTab(
                    handleSwitch(direction.region, isDemo, isShare, "Loans"),
                    3,
                    propertyId,
                    history,
                    setValue
                  )
                }
              />
              <AntTab
                label={`${t("Tabs.tama")}`}
                disabled={disabledTabs.includes(Routes.dashboardTama)}
                className={isMobileScreen ? "" : classes.ribbon}
                ribbonText={t("beta")}
                isDarkTheme={isDarkTheme}
                onClick={() =>
                  handleSwitchTab(
                    handleSwitch(direction.region, isDemo, isShare, "Tama"),
                    4,
                    propertyId,
                    history,
                    setValue
                  )
                }
                style={{ width: isMobileScreen && !direction.isRtl ? 90 : "" }}
              />
              )
              <AntTab
                label={`${t("Tabs.rent")}`}
                isDarkTheme={isDarkTheme}
                disabled={disabledTabs.includes(Routes.dashboardRent)}
                className={isMobileScreen ? "" : classes.ribbon}
                ribbonText={t("beta")}
                onClick={() =>
                  handleSwitchTab(
                    handleSwitch(direction.region, isDemo, isShare, "Rent"),
                    5,
                    propertyId,
                    history,
                    setValue
                  )
                }
                style={{ width: isMobileScreen ? 92 : "" }}
              />
              <AntTab
                label={`${t("Tabs.building-rights")}`}
                isDarkTheme={isDarkTheme}
                className={isMobileScreen ? "" : classes.ribbon}
                ribbonText={t("beta")}
                onClick={() =>
                  handleSwitchTab(
                    handleSwitch(
                      direction.region,
                      isDemo,
                      isShare,
                      "BuildingRights"
                    ),
                    6,
                    propertyId,
                    history,
                    setValue
                  )
                }
              />
              <AntTab
                label={`${t("Tabs.renovations")}`}
                isDarkTheme={isDarkTheme}
                className={isMobileScreen ? "" : classes.ribbon}
                ribbonText={t("beta")}
                onClick={() =>
                  handleSwitchTab(
                    handleSwitch(
                      direction.region,
                      isDemo,
                      isShare,
                      "Renovations"
                    ),
                    7,
                    propertyId,
                    history,
                    setValue
                  )
                }
                style={{ width: isMobileScreen ? 90 : "" }}
              />
              <AntTab
                label=""
                className={classes.headerTab}
                isDarkTheme={isDarkTheme}
                disabled
              />
              {!direction.region && (
                <AntTab
                  label=""
                  className={classes.headerTab}
                  isDarkTheme={isDarkTheme}
                  disabled
                />
              )}
            </AntTabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Forecast
              SPACING_FACTOR={SPACING_FACTOR}
              setSummaryPrice={setSummaryPrice}
              toggleBRCall={toggleBRCall}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableDashboard SPACING_FACTOR={SPACING_FACTOR} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Comparative SPACING_FACTOR={SPACING_FACTOR} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <MortgageDashboard rawData={summaryPrice?.overview} />
          </TabPanel>

          <TabPanel value={value} index={4}>
            <TamaComponent
              setSummaryPrice={setSummaryPrice}
              toggleBRCall={toggleBRCall}
              month={month}
            />
          </TabPanel>

          <TabPanel value={value} index={5}>
            <Rent />
          </TabPanel>

          <TabPanel value={value} index={6}>
            <BuildingRightsComponent
              setToggleBRCall={setToggleBRCall}
              setSummaryPrice={setSummaryPrice}
              month={month}
            />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <RenovationsComponent month={month} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};
export default ContentAreaLayout;
