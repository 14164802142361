import { InputLabel } from "@material-ui/core";
import { Controller } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import { useIsMobileScreen } from "common-components";
import { useIsIncludes } from "common-components";
import { useTranslation } from "react-i18next";

function isDisabledParking(currentVal, dropdownVal) {
  if (dropdownVal === "without" && currentVal !== "without") {
    return true;
  } else if (
    dropdownVal === "single" &&
    ["double", "serial"].includes(currentVal)
  ) {
    return true;
  }
}

const ParkingInput = ({
  control,
  register,
  classesLabel,
  width,
  inputProps,
  name,
  defaultValue,
  rules,
  fontSize,
  disabled,
}) => {
  const isMobileScreen = useIsMobileScreen();
  const isReports = useIsIncludes("reports");
  const isDisabled = isDisabledParking(defaultValue, "without");
  const { t } = useTranslation();

  return (
    <>
      <InputLabel className={classesLabel} style={{ whiteSpace: "nowrap" }}>
        {t("ParkingInput.title")}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        id={name}
        defaultValue={defaultValue}
        disabled={disabled}
        render={({ field: { onChange, value } }) => (
          <Select
            variant={"outlined"}
            id={name}
            name={name}
            value={value}
            disabled={disabled}
            defaultValue={defaultValue}
            onChange={onChange}
            style={{
              width: width
                ? width
                : isMobileScreen || isReports
                ? "100%"
                : "18ch",
              fontSize: fontSize,
            }}
            inputProps={{ className: inputProps }}
          >
            <MenuItem
              value={"without"}
              disabled={isDisabledParking(defaultValue, "without")}
            >
              {t("ParkingInput.without")}
            </MenuItem>
            <MenuItem
              value={"single"}
              disabled={isDisabledParking(defaultValue, "single")}
            >
              {t("ParkingInput.singleParking")}
            </MenuItem>
            <MenuItem value={"serial"}>
              {" "}
              {t("ParkingInput.serialParking")}
            </MenuItem>
            <MenuItem value={"double"}>
              {" "}
              {t("ParkingInput.doubleParking")}
            </MenuItem>
          </Select>
        )}
        rules={rules}
      />
    </>
  );
};
export default ParkingInput;
