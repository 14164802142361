import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface LoadingButtonsProps {
  size?: SizeProp;
  color?: string;
  style?: any;
}

export function LoadingButtons({
  size = "lg",
  color = "white",
  style,
}: LoadingButtonsProps) {
  return (
    <FontAwesomeIcon
      icon={faSpinner}
      spin
      size={size}
      color={color}
      style={style}
    />
  );
}
