import React, { useContext, useMemo } from "react";
import { DirectionContext } from "../context/DirectionContext.tsx";
import {
  createMuiTheme,
  ThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import { DarkThemeContext } from "../context/DarkThemeContext";
import {
  darkBackground,
  darkElements,
  darkMainPropColor,
  darkText,
  darkThemeNegativeRed,
  darkThemeSliderRail,
  mainPropColor,
  negativeRedColor,
  positiveGreenColor,
} from "../services/colors";
import { SM_FONT } from "../services/fonts";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ThemeProviderComponent = (props) => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);

  const themeb = useTheme();
  const isSmallScreen = useMediaQuery(themeb.breakpoints.between(0, 1281));

  const theme = useMemo(() => {
    return createMuiTheme({
      direction: direction.isRtl ? "rtl" : "ltr",
      typography: {
        fontFamily: "Open Sans Hebrew, sans-serif !important",
      },
      palette: {
        type: isDarkTheme ? "dark" : "light",
        primary: { main: isDarkTheme ? darkMainPropColor : mainPropColor },
        secondary: { main: isDarkTheme ? darkMainPropColor : "#fff" },
        default: { main: "#004cb8" },
        error: { main: `${negativeRedColor} !important` },
        success: { main: `${positiveGreenColor} !important` },
      },
      overrides: {
        MuiSelect: {
          select: {
            "&:focus": {
              backgroundColor: "none",
            },
          },
        },
        MuiPaper: {
          root: {
            backgroundColor: isDarkTheme ? darkBackground : "#fff",
            color: isDarkTheme ? darkText : "#000000",
          },
          rounded: {
            borderRadius: "var(--border-radius)",
          },
          elevation3: {
            boxShadow:
              "0px 3px 3px -2px rgba(0,0,0,0.20), 0px 6px 4px 0px rgba(0,0,0,0.20), 0px 1px 4px 0px rgba(0,0,0,0.30)",
          },
        },
        MuiTypography: {
          root: {
            color: isDarkTheme ? darkText : "",
          },
        },
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: isDarkTheme ? darkElements : mainPropColor,
            color: isDarkTheme ? darkText : "#fff",
          },
        },

        MuiButton: {
          containedSecondary: {
            color: isDarkTheme ? darkText : mainPropColor,
          },
          containedPrimary: {
            color: isDarkTheme ? darkText : "#fff",
          },
          root: {
            borderRadius: "var(--border-radius)",
            fontWeight: 600,
            fontSize: SM_FONT,
            padding: isSmallScreen ? "4px 16px" : "6px 16px",
            textTransform: "none",
          },
          contained: {
            "&:hover": {
              boxShadow: "none",
            },
            backgroundColor: isDarkTheme ? darkText : "#e0e0e0",
            color: isDarkTheme ? darkBackground : "rgba(0, 0, 0, 0.87)",
            boxShadow: "none",
          },
        },
        MuiTableCell: {
          head: {
            color: isDarkTheme ? darkText : "#000000",
          },
          body: {
            color: isDarkTheme ? darkText : "#000000",
          },
        },
        MuiInputBase: {
          root: {
            color: isDarkTheme ? darkText : "rgba(0, 0, 0, 0.87)",
            height: isSmallScreen ? 30 : 36,
          },
          "&$disabled": {
            height: isSmallScreen ? 30 : 36,
          },
        },
        MuiChip: {
          deleteIconSmall: {
            width: 20,
            height: 20,
          },
          sizeSmall: {
            height: 26,
          },
        },
        MuiOutlinedInput: {
          root: {
            borderRadius: "var(--border-radius)",
            backgroundColor: isDarkTheme ? darkElements : "white",
            height: isSmallScreen ? 30 : 36,
          },
          adornedEnd: {
            paddingRight: 0,
          },
          input: {
            "&:-webkit-autofill": {
              "-webkit-box-shadow": `0 0 0 1000px ${
                isDarkTheme ? darkElements : "#fff"
              } inset`,
              "-webkit-text-fill-color": "#fff",
            },
          },
        },
        MuiFormLabel: {
          root: {
            fontSize: SM_FONT,
          },
        },
        MuiAvatar: {
          colorDefault: {
            color: mainPropColor,
            fontWeight: 600,
          },
        },
        MuiSlider: {
          mark: {
            display: "none",
          },
          marked: {
            marginBottom: 0,
          },
        },
        MuiDialog: {
          paper: {
            margin: 7,
            overflowY: "scroll",
          },
        },
        MuiAutocomplete: {
          popper: {
            position: "fixed",
            top: 15,
          },
        },

        MuiFormControlLabel: {
          root: {
            marginRight: 0,
          },
        },
        MuiBadge: {
          colorError: {
            backgroundColor: isDarkTheme ? darkThemeNegativeRed : "inherit",
          },
        },
        MuiFilledInput: {
          root: {
            "&.Mui-disabled": {
              backgroundColor: "rgb(0 0 0 / 4%)",
            },
          },
        },
        MuiListItem: {
          root: {
            "&:hover": {
              backgroundColor: isDarkTheme ? darkThemeSliderRail : "inherit",
            },
          },
        },
      },
    });
  }, [direction, isDarkTheme, isSmallScreen]);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
export default ThemeProviderComponent;
//TODO check apes disabled on loading data
