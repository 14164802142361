import React from "react";
import { LoadingCharts } from "./layout/Layout";
import { Grid } from "@material-ui/core";
import { ProgressBar } from "./DegreeOfInfluenceCard";
import DemoExample from "./DemoExample";
import { useIsIncludes } from "common-components";
import TilesContainer from "./TilesContainer";
import Typography from "@material-ui/core/Typography";
import { XS_PLUS_FONT } from "../services/fonts";
import { useTranslation } from "react-i18next";
import TitleGrid from "./TitleGrid";

interface CityImpactsProps {
  data:
    | {
        amount: number[];
        max: number;
        name: string;
        type: string[];
      }
    | undefined;
}
const CityImpactsCard: React.FC<CityImpactsProps> = ({ data }) => {
  const isDemo = useIsIncludes("demo");
  const { t } = useTranslation();
  if (data) {
    return (
      <TilesContainer relative>
        {isDemo && <DemoExample />}
        <Grid container>
          <TitleGrid
            title={
              t("CityImpactsCard.title") +
              t(`CityImpactsCard.${data.name.trim()}`) +
              t("CityImpactsCard.area")
            }
            tooltipText={t("CityImpactsCard.tooltip")}
          />

          <Grid
            container
            style={{ width: "100%", paddingTop: 10 }}
            alignItems={"center"}
            justify={"space-between"}
            spacing={1}
            wrap={"wrap"}
          >
            {data.type.map((item, index) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  lg={6}
                  spacing={1}
                  alignItems={"center"}
                  justify={"flex-end"}
                  style={{ marginBottom: 15 }}
                  key={index}
                >
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        fontSize: XS_PLUS_FONT,
                        fontWeight: 600,
                        overflowWrap: "anywhere",
                      }}
                    >
                      {item}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <ProgressBar
                      width={`${((data.amount[index] * 100) / data.max).toFixed(
                        0
                      )}%`}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ fontSize: XS_PLUS_FONT }}>
                      {data.amount[index]}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </TilesContainer>
    );
  } else {
    return (
      <TilesContainer padding={0}>
        <LoadingCharts
          variant={"h5"}
          name={t("CityImpactsCard.titleLoading")}
          height={"5%"}
          minHeight={"0px"}
          padding={50}
        />
      </TilesContainer>
    );
  }
};
export default CityImpactsCard;
