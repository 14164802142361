import { makeStyles } from "@material-ui/core/styles";

export const useStylesImpactPoints = makeStyles({
  containerRectangle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    cursor: "pointer",
  },
  rectangle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  grayRectangle: {
    width: 45,
    height: 8,
    borderRadius: 2,
    backgroundColor: "#ebebeb",
    margin: "0 5px",
  },
});
