import React, { useContext, useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useCustomTooltip } from "./CustomTooltip";
import "chartjs-plugin-deferred";
import * as chartjs from "chart.js";
import { LegendInterface } from "../data/DataStackBarChart";
import { darkText, darkThemeGridLines, greyAxis } from "../services/colors";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { ANIMATION_DURATION } from "../services/consts";
import { FONT } from "../services/fonts";
import { useIsMobileScreen } from "common-components";
import {
  disableScrolling,
  enableScrolling,
} from "./mortgage/MortgageLineChart";
import { datasetKeyProvider } from "../components/dashboard-components/tama/LineChart";
import { useTranslation } from "react-i18next";
import ChartTooltip from "./chartTooltip/ChartTooltip";
import Grid from "@material-ui/core/Grid";
import TitleGrid from "../components/TitleGrid";

export interface BarChartInterface {
  labels: string[];
  datasets: Dataset[];
}

export interface Dataset {
  data: number[];
  backgroundColor: string[];
  hoverBackgroundColor: string[];
  hoverBorderColor: string[];
  hoverBorderWidth: number;
}
const BarChart: React.FC<{
  data: BarChartInterface;
  legend: LegendInterface[];
}> = ({ data, legend }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const chartRef = useRef<any>();
  const [index, setCurrentIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );

  const totalVal = useMemo(() => {
    //only 1 dataset exist for this chart
    //calculate sum:
    return data.datasets[0].data.reduce(
      (accumulator, currentValue) => accumulator + currentValue
    );
  }, [data]);

  const percentageBar = useMemo(() => {
    return function (value: number) {
      return parseFloat(((value / totalVal) * 100).toFixed(0)).toString() + "%";
    };
  }, []);
  const isMobileScreen = useIsMobileScreen();
  const { t } = useTranslation();
  const chartJsOptions = useMemo<chartjs.ChartOptions>(() => {
    return {
      maintainAspectRatio: false,
      animation: {
        duration: ANIMATION_DURATION,
      },
      plugins: {
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
        datalabels: {
          color: "#FFF",
          formatter: percentageBar,
          font: {
            size: isMobileScreen ? 12 : FONT,
            family: "Open Sans Hebrew, sans-serif",
          },
          anchor: "center",
          textAlign: "center",
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
        rtl: true,
        custom: customTooltip,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: isMobileScreen ? 10 : FONT,
              fontFamily: "Open Sans Hebrew, sans-serif",
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              drawBorder: false,
              display: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              display: false,
              fontSize: isMobileScreen ? 10 : FONT,
            },
          },
        ],
      },
    };
  }, [isMobileScreen]);

  return (
    <Grid container style={{ height: "100%" }}>
      <TitleGrid
        title={t("BarChart.title")}
        tooltipText={t("BarChart.tooltip")}
      />
      <Grid item xs={12}>
        <div
          style={{
            minHeight: 300,
          }}
          onTouchStart={() => {
            disableScrolling();
          }}
          onTouchEnd={() => {
            enableScrolling();
          }}
        >
          <Bar
            ref={chartRef}
            data={data}
            datasetKeyProvider={datasetKeyProvider}
            options={chartJsOptions}
          />
        </div>
        {/*@ts-ignore*/}
        <ChartTooltip
          data={data}
          title={{ title: "label" }}
          position={position}
          id={"barImpacts"}
          index={index}
          tooltipRef={tooltipRef}
          tooltipPercent={[t("BarChart.about"), t("BarChart.totalEvents")]}
          tooltipEvent
          total={totalVal}
        />
      </Grid>
    </Grid>
  );
};

export default BarChart;
