import Grid from "@material-ui/core/Grid";
import { ReactComponent as FolderIcon } from "../../../terporaryImgs/folder.svg";
import {
  darkMainPropColor,
  darkThemeNegativeRed,
} from "../../../services/colors";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useState } from "react";
import { MD_FONT } from "../../../services/fonts";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import { useStylesBarChart } from "../../../styledComponent/BarChartStyled";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { Collapse } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { LoadingButtons } from "../../../components/layout/Layout";

const useStyles = makeStyles({
  icon: {
    color: darkMainPropColor,
    cursor: "pointer",
    paddingLeft: 15,
  },
  input: {
    maxWidth: 100,
    backgroundColor: "transparent",
    border: "none",
    borderBottom: `1px solid ${darkMainPropColor}`,
    color: "white",
    fontSize: MD_FONT,
  },
  folder: {
    color: darkMainPropColor,
    cursor: "pointer",
    width: "8em",
    height: "8em",
  },
});

const Folder = ({
  handleClickAway,
  handleOpenFolder,
  item,
  index,
  handleDelete,
  handleSave,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [dropdownDelete, setDropdownDelete] = useState(false);
  const [label, setLabel] = useState(item.label);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const classesUl = useStylesBarChart({ isDarkTheme });

  return (
    <Grid container item xs={2}>
      <Grid item xs={12} container>
        <Grid container item xs={12} justify={"flex-end"}>
          <ClickAwayListener onClickAway={() => setDropdownDelete(false)}>
            <div>
              <IconButton
                onClick={() => {
                  setDropdownDelete(true);
                }}
              >
                <MoreHorizIcon style={{ color: darkMainPropColor }} />
              </IconButton>

              <Collapse
                in={dropdownDelete}
                timeout="auto"
                unmountOnExit
                style={{ position: "relative", right: "-90px" }}
              >
                <ul className={classesUl.uLDropdownSmall}>
                  <li
                    className={classesUl.lIDropdownSmall}
                    style={{ color: darkThemeNegativeRed, fontWeight: 600 }}
                    onClick={() =>
                      handleDelete(setDropdownDelete, index, label)
                    }
                  >
                    Delete
                  </li>
                </ul>
              </Collapse>
            </div>
          </ClickAwayListener>
        </Grid>

        <Grid item xs={12} container justify={"center"}>
          <FolderIcon
            onClick={() => handleOpenFolder(index)}
            className={classes.folder}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container justify={"center"}>
        {!item.saved ? (
          <ClickAwayListener onClickAway={() => handleClickAway()}>
            <div>
              <input
                type="text"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                className={classes.input}
              />
              {loading ? (
                <LoadingButtons color={darkMainPropColor} />
              ) : (
                <CheckIcon
                  onClick={() => handleSave(label, index, setLoading)}
                  className={classes.icon}
                />
              )}
            </div>
          </ClickAwayListener>
        ) : (
          <Typography>{label}</Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default Folder;
