import MortgageCalculatorChart from "../../charts/mortgage/MortgageCalculatorChart";
import { darkMortgageColors, mortgageColors } from "../../services/colors";
import React, { useContext } from "react";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const MortgageDashboard = ({ rawData }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("MetaData.loansTitle")}</title>
        <description>{t("MetaData.loansDescription")}</description>
      </Helmet>
      <MortgageCalculatorChart
        colors={isDarkTheme ? darkMortgageColors : mortgageColors}
        rawData={rawData ? rawData.fv : undefined}
        cv={rawData ? rawData.cv.amount : 0}
      />
    </>
  );
};
export default MortgageDashboard;
