import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DOMAIN } from "common-components";
//@ts-ignore
import Cookies from "js-cookie";

export const DirectionContext = createContext<any>({});

export const DirectionContextProvider: React.FC = ({ children }) => {
  const [direction, setDirection] = useState({
    isRtl:
      Cookies.get("lang", {
        domain: DOMAIN,
      }) !== "en",
    region: "il", // location.pathname.includes("/il/") ? "il" : "us",
    api: "il", //"us/nyc"
  });
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(direction.isRtl ? "he" : "en");
    document.body.dir = direction.isRtl ? "rtl" : "ltr";
  }, [direction]);

  return (
    <DirectionContext.Provider value={[direction, setDirection]}>
      {children}
    </DirectionContext.Provider>
  );
};
