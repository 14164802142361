import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { TableSortLabel } from "@material-ui/core";

const ReportsTableCell = ({
  createSortHandler,
  classes,
  column,
  orderBy,
  order,
}) => {
  return (
    <TableCell
      key={column.id}
      style={{
        fontWeight: 700,
        fontFamily: "Open Sans Hebrew, sans-serif",
        maxWidth: column.id === "open" ? 10 : "",
        whiteSpace: "nowrap",
      }}
      sortDirection={orderBy === column.id ? order : false}
    >
      {column.notSortable ? (
        `${column.label}`
      ) : (
        <TableSortLabel
          active={orderBy === column.id}
          direction={orderBy === column.id ? order : "asc"}
          onClick={createSortHandler(column.id)}
        >
          {column.label}
          {orderBy === column.id ? (
            <span className={classes.visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      )}
    </TableCell>
  );
};
export default ReportsTableCell;
