import { useEffect } from "react";

export function useScroolTotop() {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scroll(0, 0);
  }, []);
}
