import React, { useContext, useEffect, useState } from "react";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import { isLoggedIn } from "../../services/helpers";
import { makeStyles } from "@material-ui/core/styles";
import { LoadingButtons } from "../../components/layout/Layout";
import { mainPropColor } from "../../services/colors";
import { Routes } from "../../services/routes";
import Payment from "../payment/Payment";
import SelectPropDetails from "../add-prop/SelectPropDetails";
import { useLocation } from "react-router-dom";
import {
  propertyIL,
  PurchasePropertyContext,
} from "../../context/PurchasePropertyContext";
import Cookies from "js-cookie";
import { DOMAIN, LANDING_PAGE_LINK } from "common-components";
import CloseButton from "../../components/CloseButton";
import { useIsIncludes } from "common-components";
import { handleIframe } from "../pricing/PricingCard";

const useStyles = makeStyles({
  spinner: {
    height: 500,
    width: 425,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iframe: (props) => ({
    display: props.loading ? "none" : "block",
    border: "none",
    height: props.loading ? 0 : 500,
    width: props.loading ? 0 : 425,
  }),
  closeContainer: {
    position: "absolute",
    right: 28,
    top: 30,
  },
});

const SRCLogin = window.location.host.includes("localhost")
  ? "http://localhost:3001/il/?login=1"
  : `${LANDING_PAGE_LINK}/il/?login=1`;

const SRCSignUp = window.location.host.includes("localhost")
  ? "http://localhost:3001/il/?signUp=1"
  : `${LANDING_PAGE_LINK}/il/?signUp=1`;

const UserLoginIframe = ({ redirect, close, signUp }) => {
  const [property, setProperty] = useContext(PurchasePropertyContext);
  const [loading, setLoading] = useState(true);
  const [, setAuthorization] = useContext(AuthorizationContext);
  const { openDialog, closeDialog } = useDialogManager();
  const classes = useStyles({ loading });
  let location = useLocation();
  const isDemo = useIsIncludes("demo");

  useEffect(() => {
    localStorage.clear();

    if (window.addEventListener) {
      window.addEventListener(
        "message",
        (msg) => {
          if (msg && msg.data === "login success") {
            onUserLogin(
              setAuthorization,
              openDialog,
              closeDialog,
              location,
              property,
              redirect
            );
          }
        },
        false
      );
    }
  }, []);

  const handleOnLoad = () => {
    setLoading(false);
  };

  const handleClose = () => {
    setProperty(propertyIL);
    closeDialog();
  };

  return (
    <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
      {loading ? (
        <div className={classes.spinner}>
          <LoadingButtons color={mainPropColor} size={"3x"} />
        </div>
      ) : null}
      {!loading && (close || isDemo) && (
        <div className={classes.closeContainer}>
          <CloseButton callback={handleClose} padding={"0"} />
        </div>
      )}
      <iframe
        onLoad={handleOnLoad}
        loading="lazy"
        src={signUp ? SRCSignUp : SRCLogin}
        className={classes.iframe}
      />
    </div>
  );
};

export default UserLoginIframe;

function onUserLogin(
  setAuthorization,
  openDialog,
  closeDialog,
  location,
  property,
  redirect
) {
  setAuthorization(true);

  if (redirect) {
    window.location.replace(
      `${window.location.origin}${Routes.dashboardSummary}`
    );
  }

  setCognitoLocalStorage();

  if (location.hash.includes("#select")) {
    setTimeout(() => {
      handleIframe(openDialog, sessionStorage.getItem("planId"));
      // openDialog(Payment);
    }, 1000);
  } else if (property.lat !== 0 && property.long !== 0) {
    openDialog(SelectPropDetails, { DialogProps: { maxWidth: "md" } });
  } else {
    closeDialog();
  }
}

export function setCognitoLocalStorage() {
  const storage = Cookies.get();
  for (const [key, value] of Object.entries(storage)) {
    if (key.startsWith("Cognito") || key.startsWith("amplify")) {
      localStorage.setItem(key, value);
      Cookies.remove(key, { domain: DOMAIN });
    }
  }
}
