import React, { useContext, useEffect, useRef, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import { useStylesTable } from "../../charts/table/TableChart";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableReportsDetails from "./TableReportsDetails";
import TableReportsBody from "./TableReportsBody";
import ReportsTableFilter from "./ReportsTableFilter";
import TableBody from "@material-ui/core/TableBody";
import TablePaginationFooter from "../../charts/table/TablePaginationFooter";
import ReportsTableCell from "./ReportsTableCell";
import { doFetch } from "common-components";
import { DataAddressesContext } from "../../context/DataAddressesContext";
import Typography from "@material-ui/core/Typography";
import { IMAGES_LINK } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

export function fetchAcInacProp(
  direction,
  isActive,
  page,
  searchWord,
  type,
  property,
  isAsc,
  setAddresses,
  addresses,
  setPage,
  setLoading,
  project,
  building,
  isDarkTheme
) {
  if (setLoading) setLoading(true);
  doFetch(
    `/reports/search/properties?page=${page}&search=${searchWord}&active=${Number(
      isActive
    )}${!isDarkTheme ? "" : `&project=${project}&building=${building}`}&lang=${
      direction.isRtl ? "he" : "en"
    }`
  ).then((reportsRes) => {
    setPage(page);
    if (isActive) setAddresses({ ...addresses, active: reportsRes });
    else setAddresses({ ...addresses, inactive: reportsRes });

    if (setLoading) setLoading(false);
  });
}

const TableReport = ({
  rows,
  columns,
  addresses,
  active,
  project,
  building,
}) => {
  const [searchRows, setSearchRows] = useState([...rows]);
  const [orderBy, setOrderBy] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState([]);
  const [originalTotalPages, setOriginalTotalPages] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const classes = useStylesTable();
  const targetRef = useRef();
  const [sortBy, setSortBy] = useState("");
  const [type, setType] = useState([]);
  const [, setAddresses] = useContext(DataAddressesContext);
  const [direction] = useContext(DirectionContext);
  const { t } = useTranslation();

  useEffect(() => {
    setPage(addresses.page);
    setOpen([]);
  }, [addresses.page]);

  useEffect(() => {
    setSearchRows([...rows]);
  }, [rows]);

  useEffect(() => {
    setOriginalTotalPages(addresses.total);
  }, [addresses]);

  const handleRequestSort = async (event, property) => {
    if (property === "type") return;
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortBy(property);

    fetchAcInacProp(
      direction,
      active,
      1,
      searchWord,
      type,
      property,
      isAsc,
      setAddresses,
      addresses,
      setPage
    );
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const emptyRows =
    addresses.page === 1 ? 0 : active ? 10 - rows.length : 3 - rows.length;

  return (
    <>
      <ReportsTableFilter
        searchWord={searchWord}
        setSearchWord={setSearchWord}
        active={active}
        page={page}
        setPage={setPage}
        sortBy={sortBy}
        type={type}
        setType={setType}
        project={project}
        building={building}
      />

      {addresses.properties && addresses.properties.length !== 0 ? (
        <TableContainer className={classes.container} ref={targetRef}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  if (!column.hidden) {
                    return (
                      <ReportsTableCell
                        key={column.id}
                        column={column}
                        orderBy={orderBy}
                        order={order}
                        classes={classes}
                        createSortHandler={createSortHandler}
                      />
                    );
                  } else return null;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchRows.map((row, index) => {
                return (
                  <React.Fragment key={row.id}>
                    <TableReportsBody
                      indexRow={index}
                      open={open}
                      row={row}
                      columns={columns}
                      setOpen={setOpen}
                      addresses={addresses}
                    />
                    <TableReportsDetails
                      row={row}
                      open={open}
                      address={addresses.properties[index]}
                    />
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={`${IMAGES_LINK}/dashboard/elements/home.svg`}
            alt="home"
            style={{ width: 70, height: 70 }}
          />
          <Typography>{t("noResults")}.</Typography>
        </div>
      )}
      {addresses.total > 1 && (
        <TablePaginationFooter
          isTableChart
          classes={classes}
          count={addresses.total}
          setPage={setPage}
          page={page}
          active={active}
          searchWord={searchWord}
          sortBy={sortBy}
          type={type}
          project={project}
          building={building}
        />
      )}
    </>
  );
};
export default TableReport;
