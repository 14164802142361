import React, { useContext, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles, Toolbar } from "@material-ui/core";
import TopBar from "./TopBar";
import { useStylesTopBar } from "../../styledComponent/TopBarStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useIsMobileScreen } from "common-components";
import { OpenAccessContext } from "../../context/OpenAccessContext";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { isLoggedIn } from "../../services/helpers";
import { useIsIncludes } from "common-components";
import UserLoginIframe, {
  setCognitoLocalStorage,
} from "../../dialogs/login-singup/UserLoginIframe";
//@ts-ignore
import Cookies from "js-cookie";
import { Login } from "common-components/src/global/components/login/Login";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";

const useStyles = makeStyles((theme) => ({
  topBar: {
    top: 0,
    boxShadow: "none",
  },
}));

const TopbarComponent = () => {
  const { openDialog } = useDialogManager();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [authorization] = useContext(AuthorizationContext);
  const isReports = useIsIncludes("reports");
  const isSmallScreen = useIsMobileScreen();
  const classes = useStyles();
  const props = {
    isSmallScreen: isSmallScreen,
    location: isReports,
    isDarkTheme: isDarkTheme,
  };
  const classesTop = useStylesTopBar(props);

  useEffect(() => {
    if (!authorization && !isOpenAccess.access) {
      openDialog(Login, { DialogProps: { maxWidth: "xs" } });
    }
  }, [isOpenAccess]);

  const isResetPass = useIsIncludes("reset-password");
  const isError = useIsIncludes("error");
  const isDemo = useIsIncludes("demo");

  if ((!isResetPass && !isError) || isDemo) {
    return (
      <>
        <AppBar position="fixed" className={classes.topBar}>
          <div
            style={{
              margin: "0 auto",
              maxWidth: 1380,
              width: "calc(100% - 48px)",
            }}
          >
            <Toolbar className={classesTop.toolbar}>
              <TopBar />
            </Toolbar>
          </div>
        </AppBar>
        <Toolbar className={classesTop.toolbar} />
      </>
    );
  } else {
    return null;
  }
};
export default TopbarComponent;
