import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIsIncludes } from "common-components";

export const OpenAccessContext = createContext<any>({});
export const OpenAccessContextProvider: React.FC = ({ children }) => {
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const isListings = useIsIncludes("listings");

  const [isOpenAccess, setOpenAccess] = useState<{
    access: boolean;
    path: string;
    page: string;
  }>({
    access:
      location.pathname.includes("demo") ||
      location.pathname.includes("share") ||
      location.pathname.includes("listings"),
    path: `property${
      location.pathname.includes("demo")
        ? "demo"
        : location.pathname.includes("listings")
        ? "/listings"
        : ""
    }`,
    page: location.pathname.includes("demo")
      ? "demo"
      : location.pathname.includes("listings") && !id
      ? "/listings"
      : location.pathname.includes("listings") && id
      ? "/id"
      : location.pathname.includes("user")
      ? "/user"
      : location.pathname.includes("share")
      ? "share"
      : "",
  });

  useEffect(() => {
    if (isListings && id) {
      setOpenAccess({ access: true, path: "property/listings", page: "/id" });
    }
  }, [isListings, id]);

  return (
    <OpenAccessContext.Provider value={[isOpenAccess, setOpenAccess]}>
      {children}
    </OpenAccessContext.Provider>
  );
};
