import { darkMainPropColor, mainPropColor } from "../services/colors";
import Star from "@material-ui/icons/Star";
import { randomInt } from "../services/helpers";

const ConditionStars = ({ amount, context }) => {
  const iterArr = [];
  for (let i = 0; i < amount; i++) {
    iterArr.push(i);
  }
  return (
    <>
      {iterArr.map(() => {
        return (
          <Star
            key={randomInt()}
            style={{
              fill: context.isDarkTheme ? darkMainPropColor : mainPropColor,
              width: 15,
              height: 15,
            }}
          />
        );
      })}
    </>
  );
};
export default ConditionStars;
