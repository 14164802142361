import React, { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Controller, useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  date,
  defaultValues,
  purchaseProperty,
  useBreakpointsFont,
} from "./Payment";
import { useIsMobileScreen } from "common-components";
import { useHistory, useLocation } from "react-router-dom";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { useIsLargeScreen } from "common-components";
import { useStylesSelectPropDetails } from "../../styledComponent/SelectPropDetailsStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { doFetch } from "common-components";
import NumberFormat from "react-number-format";
import { PurchasePropertyContext } from "../../context/PurchasePropertyContext";
import Typography from "@material-ui/core/Typography";
import { LG_FONT, XL_FONT, XS_FONT, XS_PLUS_FONT } from "../../services/fonts";
import MessagePropAdded from "../dialog-components/MessagePropAdded";
import { IMAGES_LINK } from "common-components";
import { hasValueInStorage } from "../../services/helpers";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

const PaymentForm = ({ classes, loading, setLoading }) => {
  const [direction] = useContext(DirectionContext);
  const [property, setProperty] = useContext(PurchasePropertyContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [payments, setPayments] = useState(1);
  const { openDialog } = useDialogManager();
  const [errorText, setError] = useState(undefined);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    defaultValues,
  });
  let location = useLocation();
  const breakpoints = useBreakpointsFont();
  const params = new URLSearchParams(location.search);
  const id = params.get("selectId") | params.get("id") | property.id;
  const isLargeScreen = useIsLargeScreen();
  const history = useHistory();
  const props = { isDarkTheme: isDarkTheme };
  const classesSelPropDet = useStylesSelectPropDetails(props);
  const isMobileScreen = useIsMobileScreen();
  useEffect(() => {
    if (sessionStorage.getItem("planId") === "1") {
    } else if (
      sessionStorage.getItem("planId") === "2" ||
      sessionStorage.getItem("planId") === "3"
    ) {
      setPayments(3);
    } else {
      setPayments(12);
    }
  }, []);

  const onSubmit = (values) => {
    const expiration = values.expiration.split("/");
    setLoading(true);
    values["plan_id"] = sessionStorage.getItem("planId");
    const dataToSend = {
      id_num: values.id_num,
      cc: values.cc,
      cvv: values.cvv,
      month: expiration[0],
      year: expiration[1],
      name: values.name,
      num_payments: values.num_payments,
    };
    if (id) {
      dataToSend.listing_id = id;
    } else {
      dataToSend.plan_id = values.plan_id;
    }

    if (values) {
      doFetch(`/reports/purchase/package`, {
        method: "POST",
        body: JSON.stringify(dataToSend),
      }).then((data) => {
        if (data["Bank Error"]) {
          setLoading(false);
          setError(`${t("PaymentForm.bankError")}`);
        } else if (property.type && data.message === "Package Purchased") {
          history.push({ pathname: location.pathname });
          purchaseProperty(direction.api, property, openDialog, t, setProperty);
        } else if (data.message === "Package Purchased") {
          setLoading(false);
          openDialog(MessagePropAdded, {
            DialogContentProps: { title: `${t("PaymentForm.paymentSuccess")}` },
          });
        }
      });
    } else {
      setLoading(false);
      setError(t("PaymentForm.generalError"));
    }
  };
  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ opacity: loading ? 0.3 : 1 }}
      onClick={() => setError(undefined)}
    >
      <div style={isMobileScreen ? { marginTop: 10 } : {}}>
        <Grid
          container
          justify={"flex-start"}
          alignItems={"flex-start"}
          direction={"row"}
          className={breakpoints.spacing}
        >
          <Grid item xs={12} className={classes.title}>
            <Typography
              className={classesSelPropDet.paragraphAddProp}
              style={{ fontWeight: 600, fontSize: LG_FONT }}
            >
              {t("PaymentForm.addTCInfo")}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify={"flex-start"}
          alignItems={"flex-start"}
          direction={"row"}
          spacing={1}
          className={breakpoints.spacing}
        >
          <Grid item xs={4} className={classes.paragraphBox}>
            <Typography className={classesSelPropDet.paragraphAddProp}>
              {t("PaymentForm.name")}:
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <TextField
              id="name"
              name="name"
              autoComplete="off"
              error={errors["name"] !== undefined}
              placeholder={t("PaymentForm.name")}
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              InputProps={{ className: classes.inputs }}
              onFocus={(event) => {
                event.target.select();
              }}
              {...register("name", {
                required: "Name is required",
                maxLength: 60,
                minLength: 3,
                pattern: /^[a-zA-Z\u05D0-\u05EA ]*$/,
              })}
            />
            {errors.name?.type === "required" && (
              <Typography className={classes.errorsStyle}>
                {" "}
                {t("PaymentForm.requiredMsg")}
              </Typography>
            )}
            {errors.name?.type === "maxLength" && (
              <Typography className={classes.errorsStyle}>
                {t("PaymentForm.nameMaxLength")}
              </Typography>
            )}
            {errors.name?.type === "pattern" && (
              <Typography className={classes.errorsStyle}>
                {t("PaymentForm.namePattern")}
              </Typography>
            )}
            {errors.name?.type === "minLength" && (
              <Typography className={classes.errorsStyle}>
                {t("PaymentForm.nameMinLength")}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          justify={"flex-start"}
          alignItems={"flex-start"}
          direction={"row"}
          spacing={1}
          className={breakpoints.spacing}
        >
          <Grid item xs={4} className={classes.paragraphBox}>
            <Typography className={classesSelPropDet.paragraphAddProp}>
              {t("PaymentForm.TCNumber")}:
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <TextField
              id="cc"
              name="cc"
              autoComplete="off"
              error={errors["cc"] !== undefined}
              placeholder={t("PaymentForm.TCNumber")}
              variant="outlined"
              size="small"
              style={{ width: "100%", textAlign: "right" }}
              InputProps={{
                className: classes.inputs,
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              onFocus={(event) => {
                event.target.select();
              }}
              {...register("cc", {
                required: "Credit card is required",
                pattern: /^[0-9]{16}$/,
              })}
            />
            {errors.cc?.type === "required" && (
              <Typography className={classes.errorsStyle}>
                {t("PaymentForm.requiredMsg")}
              </Typography>
            )}
            {errors.cc?.type === "pattern" && (
              <Typography className={classes.errorsStyle}>
                {t("PaymentForm.TCPatternMsg")}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          justify={"flex-start"}
          alignItems={"baseline"}
          direction={"row"}
          spacing={1}
          className={breakpoints.spacing}
        >
          <Grid
            container
            item
            xs={4}
            direction={"column"}
            alignItems={"flex-start"}
          >
            <Grid
              container
              justify={"flex-start"}
              alignItems={"flex-start"}
              direction={"column"}
              spacing={1}
              className={breakpoints.spacing}
            >
              <Grid item xs={4} className={classes.paragraphBox}>
                <Typography className={classesSelPropDet.paragraphAddProp}>
                  {t("PaymentForm.expirationDate")}:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="expiration"
                  rules={{ required: true }}
                  error={errors["expiration"] !== undefined}
                  render={({ field: { onChange, value } }) => (
                    <NumberFormat
                      customInput={TextField}
                      error={errors["expiration"] !== undefined}
                      format="##/##"
                      placeholder="MM/YY"
                      mask={["M", "M", "Y", "Y"]}
                      id="expiration"
                      name="expiration"
                      labelId="expiration"
                      autoComplete="off"
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                      isAllowed={(values) => {
                        const year = values.formattedValue.substring(3);
                        const month = values.formattedValue.substring(
                          0,
                          values.formattedValue.length - 3
                        );
                        if (
                          !year.includes("Y") &&
                          Number(year) <
                            Number(date.getFullYear().toString().substring(2))
                        ) {
                          setError("לא תואם דפוס");
                        } else if (
                          !month.includes("M") &&
                          (Number(month) > 12 || month === "00")
                        ) {
                          setError("לא תואם דפוס");
                        } else if (typeof errorText === "string") {
                          setError(false);
                        }

                        return true;
                      }}
                      InputProps={{ className: classes.inputs }}
                      value={value}
                      onChange={onChange}
                      rules={{ required: true }}
                    />
                  )}
                />
                {errors.expiration?.type === "required" && (
                  <Typography className={classes.errorsStyle}>
                    {t("PaymentForm.requiredMsg")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            direction={"column"}
            alignItems={"flex-start"}
            spacing={1}
            className={breakpoints.spacing}
          >
            <Grid item xs={4} className={classes.paragraphBox}>
              <Typography className={classesSelPropDet.paragraphAddProp}>
                CVV:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="cvv"
                name="cvv"
                autoComplete="off"
                error={errors["cvv"] !== undefined}
                placeholder="CVV"
                variant="outlined"
                size="small"
                inputProps={{
                  inputMode: "numeric",
                }}
                style={{
                  width: "100%",
                }}
                onFocus={(event) => {
                  event.target.select();
                }}
                {...register("cvv", {
                  required: "Credit card is required",
                  pattern: /^[0-9]{3,4}$/,
                })}
              />
              {errors.cvv?.type === "required" && (
                <Typography className={classes.errorsStyle}>
                  {t("PaymentForm.requiredMsg")}
                </Typography>
              )}
              {errors.cvv?.type === "pattern" && (
                <Typography className={classes.errorsStyle}>
                  {t("PaymentForm.cvvPattern")}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            direction={"column"}
            alignItems={"flex-start"}
          >
            <Grid
              container
              justify={"flex-start"}
              alignItems={"flex-start"}
              direction={"column"}
              spacing={1}
              className={breakpoints.spacing}
              item
            >
              <Grid item xs={4} className={classes.paragraphBox}>
                <Typography className={classesSelPropDet.paragraphAddProp}>
                  {t("PaymentForm.payments")}:
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ width: "100%" }}>
                <Controller
                  control={control}
                  name="num_payments"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      defaultValue={"1"}
                      id="num_payments"
                      name="num_payments"
                      labelId="num_payments"
                      variant="outlined"
                      style={{
                        width: "100%",
                        height: isLargeScreen ? 38 : 30,
                        borderRadius: "var(--border-radius)",
                        textAlign: "right",
                      }}
                      onChange={onChange}
                    >
                      {Array(payments)
                        .fill(0)
                        .map((_, index) => (
                          <MenuItem key={index} value={(index + 1).toString()}>
                            {index + 1}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify={"flex-start"}
          alignItems={"flex-start"}
          direction={"column"}
          spacing={1}
          className={breakpoints.spacing}
        >
          <Grid item xs={4} className={classes.paragraphBox}>
            <Typography className={classesSelPropDet.paragraphAddProp}>
              {t("PaymentForm.id")}:
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <TextField
              id="id_num"
              name="id_num"
              autoComplete="off"
              error={errors["cc"] !== undefined}
              placeholder={t("PaymentForm.id")}
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              onFocus={(event) => {
                event.target.select();
              }}
              InputProps={{
                className: classes.inputs,
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              {...register("id_num", {
                required: "Credit card is required",
                pattern: /^[0-9]{9}$/,
              })}
            />
            {errors.id_num?.type === "required" && (
              <Typography className={classes.errorsStyle}>
                {" "}
                {t("PaymentForm.requiredMsg")}
              </Typography>
            )}
            {errors.id_num?.type === "pattern" && (
              <Typography className={classes.errorsStyle}>
                {t("PaymentForm.idPattern")}
              </Typography>
            )}
          </Grid>
        </Grid>
        {errorText && (
          <Typography className={classes.errorsStyle}>{errorText}</Typography>
        )}
      </div>
      <div
        style={{
          margin: 0,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            id="payment"
            style={{
              fontWeight: 400,
              fontSize: LG_FONT,
              width: "100%",
              padding: isLargeScreen ? "6px 16px" : "4px 16px",
              margin: "15px 0",
            }}
          >
            {t("PaymentForm.button")}
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 5,
          justifyContent: "center",
        }}
      >
        <Typography style={{ fontSize: XS_FONT }}>
          <img
            src={`${IMAGES_LINK}/dashboard/moneyBack/locked.svg`}
            alt="Locked"
            style={{
              width: XS_PLUS_FONT,
              height: XS_PLUS_FONT,
              padding: "0 5px",
            }}
          />
          {t("PaymentForm.securePayment")}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img
          src={`${IMAGES_LINK}/dashboard/moneyBack/${
            isDarkTheme ? "darkVisa" : "visa"
          }.svg`}
          alt="Visa"
          style={{ width: XL_FONT, height: XL_FONT }}
        />
        <img
          src={`${IMAGES_LINK}/dashboard/moneyBack/mastercard.svg`}
          alt="Mastercard"
          style={{ width: XL_FONT, height: XL_FONT, margin: "0 10px" }}
        />
        <img
          src={`${IMAGES_LINK}/dashboard/moneyBack/icon-isracard.svg`}
          alt="Isracard"
          style={{ width: XL_FONT, height: XL_FONT }}
        />
      </div>
    </form>
  );
};
export default PaymentForm;
