import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { darkBackground, darkText } from "../services/colors";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { useLocation } from "react-router-dom";
import { Routes } from "../services/routes";
import Typography from "@material-ui/core/Typography";
import { XS_PLUS_FONT } from "../services/fonts";
import { useIsMobileScreen } from "common-components";
import { useIsIncludes } from "common-components";
import { useTranslation } from "react-i18next";

const useStylesFooter = makeStyles({
  footer: (props) => ({
    padding: props.footer ? "26.5px 0 85px" : "26.5px 0",
    backgroundColor: props.isDarkTheme ? darkBackground : "",
  }),
  container: (props) => ({
    margin: "0 auto",
    maxWidth: 1380,
    borderTop: "1px solid rgb(219, 219, 219)",
    color: props.isDarkTheme ? darkText : "black",
  }),
});

const Footer = () => {
  const isDashboard = useIsIncludes(Routes.dashboard);
  let location = useLocation();
  const isSmallScreen = useIsMobileScreen();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();
  const classes = useStylesFooter({
    isDarkTheme,
    footer: isSmallScreen && isDashboard,
  });
  const date = new Date();
  if (
    location.pathname !== Routes.error &&
    location.pathname !== Routes.listings
  ) {
    return (
      <footer className={classes.footer}>
        <div className={classes.container}>
          <Typography style={{ fontSize: XS_PLUS_FONT, padding: "10px 24px" }}>
            {t("copyRight")}
            {date.getFullYear()}
          </Typography>
        </div>
      </footer>
    );
  } else {
    return <></>;
  }
};
export default Footer;
