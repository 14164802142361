import React, { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import {
  darkElements,
  darkText,
  mainPropColor,
  topBorder,
} from "../services/colors";
import { LightTooltip } from "../tooltips/LightTooltip";

interface ButtonSwitchSearchProps {
  setIsAddressSwitch: Dispatch<SetStateAction<boolean>>;
  isAddressSwitch: boolean;
  context: any;
}
const ButtonSwitchSearch: React.FC<ButtonSwitchSearchProps> = ({
  setIsAddressSwitch,
  isAddressSwitch,
  context,
}) => {
  const { t } = useTranslation();
  const handleClick = () => {
    setIsAddressSwitch(!isAddressSwitch);
  };

  return (
    <IconButton
      style={{
        color: "rgb(255 255 255 / 54%)",
        padding: 3,
        margin: 7,
        borderRadius: "var(--border-radius)",
        backgroundColor: context.isDarkTheme ? darkText : topBorder,
      }}
      onClick={handleClick}
    >
      <LightTooltip
        isDarkTheme={context.isDarkTheme}
        title={
          isAddressSwitch
            ? `${t("SearchBar.addGushHalka")}`
            : `${t("SearchBar.addAddress")}`
        }
        arrow
        enterTouchDelay={50}
      >
        <SwapVertIcon
          style={{
            color: context.isDarkTheme ? darkElements : mainPropColor,
            width: 30,
            height: 30,
          }}
        />
      </LightTooltip>
    </IconButton>
  );
};
export default ButtonSwitchSearch;
