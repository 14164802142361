import React, { useContext, useState } from "react";
import SearchPlaces from "./SearchPlaces";
import { useIsMobileScreen } from "common-components";
import { useStylesFooterSearch } from "../styledComponent/FooterSearchStyled";
import SearchGushHalka from "./top-bar/SearchGushHalka";
import { ButtonSwitchSearch } from "common-components";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import useContextProps from "../hooks/useContextProps";

const FooterSearch: React.FC = () => {
  const context = useContextProps();
  const [isAddressSwitch, setIsAddressSwitch] = useState(true);

  const isSmallScreen = useIsMobileScreen();
  const isExtraSmallScreen = useIsMobileScreen("xs");

  const props = { isDarkTheme: context.isDarkTheme, isSmallScreen };
  const { t } = useTranslation();
  const classes = useStylesFooterSearch(props);

  if (isSmallScreen) {
    return (
      <div className={classes.divSearchBox}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ButtonSwitchSearch
            isAddressSwitch={isAddressSwitch}
            setIsAddressSwitch={setIsAddressSwitch}
            context={context}
          />
          {isAddressSwitch ? (
            <SearchPlaces
              margin={0}
              width={isExtraSmallScreen ? "88%" : "80%"}
              place={"bottom"}
              maxWidth={""}
              btnColor={false}
            />
          ) : (
            <SearchGushHalka
              width={isExtraSmallScreen ? "95%" : "80%"}
              place={"bottom"}
              openSelectProp
            />
          )}
        </div>

        {context.userDetails && (
          <div className={classes.divBalanceCenter}>
            <Typography> {t("Tabs.balance")}:</Typography>
            <Typography style={{ padding: "0 5px" }}>
              {context.userDetails.balance}
            </Typography>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};
export default FooterSearch;
