import React, { createContext, useState } from "react";

export const DataAddressesContext = createContext<any>({});

export const DataAddressesContextProvider: React.FC = ({ children }) => {
  const [addresses, setAddresses] = useState({
    active: undefined,
    inactive: undefined,
  });

  return (
    <DataAddressesContext.Provider value={[addresses, setAddresses]}>
      {children}
    </DataAddressesContext.Provider>
  );
};
