import React, { useContext } from "react";
import { useStylesReport } from "../../styledComponent/ReportItemStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import {
  propertyIL,
  PurchasePropertyContext,
} from "../../context/PurchasePropertyContext";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import SelectPropDetails, {
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_GARDEN,
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_PENTHOUSE,
  PROPERTY_TYPE_PRIVATE_HOUSE,
  PROPERTY_TYPE_TOWN_HOUSE,
} from "../add-prop/SelectPropDetails";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";
import { darkMainPropColor, mainPropColor } from "../../services/colors";
import { useStylesSelectPropDetails } from "../../styledComponent/SelectPropDetailsStyled";
import Typography from "@material-ui/core/Typography";
import { AddressListingsContext } from "../../context/AddressListingsContext";
import { IMAGES_LINK } from "common-components";
import { useTranslation } from "react-i18next";

const PropAddedPayment = ({ id, listingProperty }) => {
  const [listingsAddress] = useContext(AddressListingsContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [property, setProperty] = useContext(PurchasePropertyContext);
  const { closeDialog, openDialog } = useDialogManager();
  const { t } = useTranslation();

  const handleEditAddress = () => {
    openDialog(SelectPropDetails, { DialogProps: { maxWidth: "md" } });
    closeDialog();
    setTimeout(
      openDialog(SelectPropDetails, {
        DialogProps: {
          maxWidth: "md",
        },
      }),
      200
    );
  };

  const handleRemove = () => {
    setProperty(propertyIL);
  };
  const classesText = useStylesSelectPropDetails({ isDarkTheme });
  const classes = useStylesSelectPropDetails({ isDarkTheme });

  return (
    <div
      style={{
        borderBottom: "1px solid rgba(208, 209, 210, 0.5)",
        height: 145,
      }}
    >
      <Typography className={classesText.pTitlePlanType}>
        {t("PropAddedPayment.address")}
      </Typography>
      {property.area || listingsAddress ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 10,
            paddingRight: 7,
          }}
        >
          {listingsAddress ? (
            <Typography
              className={classesText.pHeadingPlanType}
            >{`${listingsAddress[2]} ${listingsAddress[1]}, ${listingsAddress[0]}`}</Typography>
          ) : (
            <Typography
              className={classesText.pHeadingPlanType}
            >{`${property.street} ${property.house_num}, ${property.area}`}</Typography>
          )}

          {id && (
            <div>
              <EditOutlinedIcon
                style={{
                  cursor: "pointer",
                  color: isDarkTheme ? darkMainPropColor : mainPropColor,
                }}
                onClick={handleEditAddress}
              />
              <DeleteOutlineIcon
                style={{
                  cursor: "pointer",
                  color: isDarkTheme ? darkMainPropColor : mainPropColor,
                }}
                onClick={handleRemove}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 10,
          }}
        >
          <Typography className={classes.pHeadingPlanType}>
            {t("PropAddedPayment.noAddress")}
          </Typography>
          <AddIcon style={{ cursor: "pointer" }} onClick={handleEditAddress} />
        </div>
      )}
    </div>
  );
};

export function SelectedDetails({ item, label, Icon, text }) {
  const classes = useStylesReport();
  return (
    <>
      {item !== 0 && item && (
        <div
          className={classes.divContainerDetails}
          style={{ alignItems: "center" }}
        >
          <div
            className={classes.divContainerDetail}
            style={{ alignItems: "center" }}
          >
            {Icon}
            <Typography align={"center"} noWrap style={{ padding: "0 5px" }}>
              {label}
            </Typography>
          </div>
          <Typography align={"center"} noWrap style={{ padding: "0 5px" }}>
            {text ? text : item}
          </Typography>
        </div>
      )}
    </>
  );
}
export function propTypeEnName(propType) {
  if (propType === PROPERTY_TYPE_APARTMENT()) {
    return "Apartment";
  } else if (propType === PROPERTY_TYPE_GARDEN()) {
    return "Garden Apartment";
  } else if (propType === PROPERTY_TYPE_PENTHOUSE()) {
    return "Penthouse";
  } else if (propType === PROPERTY_TYPE_TOWN_HOUSE()) {
    return "Multi-Family Home";
  } else if (propType === PROPERTY_TYPE_PRIVATE_HOUSE()) {
    return "Single-Family Home";
  } else if (propType === PROPERTY_TYPE_LAND()) {
    return "Land";
  }
}
export function PropTypeIcon({ item, style, isDarkTheme }) {
  return (
    <img
      src={`${IMAGES_LINK}/dashboard/selectProp/${
        isDarkTheme ? "darkTheme" : "lightTheme"
      }/${item}.svg`}
      alt="Icon"
      style={style}
    />
  );
}
export default PropAddedPayment;
