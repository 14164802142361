import React, { useContext, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Controller } from "react-hook-form";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesSelectPropDetails } from "../../styledComponent/SelectPropDetailsStyled";
import Typography from "@material-ui/core/Typography";
import { IMAGES_LINK } from "common-components";

const CheckboxPropDetails = ({
  control,
  setValue,
  name,
  label,
  checked,
  isMamad,
  isElevator,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme: isDarkTheme };
  const classes = useStylesSelectPropDetails(props);

  useEffect(() => {
    if (name === "bomb_shelter") {
      if (isMamad) {
        setValue("bomb_shelter", true);
      } else setValue("bomb_shelter", false);
    }
    if (name === "elevator") {
      if (isElevator) {
        setValue("elevator", true);
      } else setValue("elevator", false);
    }
  }, [isMamad, isElevator]);

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          id={name}
          control={control}
          defaultChecked={checked ? checked : false}
          render={({ field: { value, ref, ...field } }) => (
            <Checkbox
              {...field}
              inputRef={ref}
              checked={!!value}
              color="primary"
              size={"medium"}
              disableRipple
              icon={
                <img
                  src={`${IMAGES_LINK}/dashboard/elements/rec.svg`}
                  alt="Circle"
                  style={{ width: 20, height: 20 }}
                />
              }
              checkedIcon={
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ width: 20, height: 20 }}
                />
              }
            />
          )}
        />
      }
      labelPlacement="end"
      label={
        <Typography className={classes.paragraphAddProp}>{label}</Typography>
      }
    />
  );
};

export function FormCheckbox({
  control,
  name,
  label,
  onClick,
  checked,
  disabled,
  square,
  labelStyle,
}) {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme: isDarkTheme };
  const classes = useStylesSelectPropDetails(props);
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          id={name}
          control={control}
          disabled={disabled}
          defaultChecked={!!checked}
          render={({ field: { onChange, value } }) => {
            return (
              <Checkbox
                {...props}
                name={name}
                id={name}
                disabled={disabled}
                checked={!!value}
                {...(square
                  ? {}
                  : {
                      icon: (
                        <img
                          src={`${IMAGES_LINK}/dashboard/elements/rec.svg`}
                          alt="Circle"
                          style={{ width: 15, height: 15 }}
                        />
                      ),
                    })}
                onClick={onClick}
                {...(square
                  ? {}
                  : {
                      checkedIcon: (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{ width: 15, height: 15 }}
                        />
                      ),
                    })}
                color="primary"
                size="medium"
                disableRipple
                onChange={onChange}
              />
            );
          }}
        />
      }
      labelPlacement="end"
      label={
        <Typography
          style={labelStyle ? labelStyle : {}}
          className={classes.paragraphAddProp}
        >
          {label}
        </Typography>
      }
    />
  );
}
export default CheckboxPropDetails;
