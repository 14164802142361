import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DataReportTable from "./DataReportTable";
import { DataAddressesContext } from "../../context/DataAddressesContext";
import { LoadingCharts, SPACING_FACTOR } from "../../components/layout/Layout";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesForecast } from "../../styledComponent/ForecastStyled";
import Button from "@material-ui/core/Button";
import { ScrollToTop } from "common-components";
import { useHistory } from "react-router-dom";
import { doFetch } from "common-components";
import { UserDetailsContext } from "../../context/UserDetailsContext";
import { OpenAccessContext } from "../../context/OpenAccessContext";
import TilesContainer from "../../components/TilesContainer";
import { useStylesLayout } from "../../styledComponent/LayoutStyled";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import SelectPropDetails from "../../dialogs/add-prop/SelectPropDetails";
import { useScroolTotop } from "../../hooks/useScrollToTop";
import Typography from "@material-ui/core/Typography";
import { Routes } from "../../services/routes";
import { useNotistack } from "common-components";
import ProjectsComponent from "./projects/ProjectsComponent";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

const Reports = () => {
  const [direction] = useContext(DirectionContext);
  const [folders, setFolders] = useState({
    display: "projects", // projects or buildings
    project: "",
    indexProject: undefined,
    building: "",
    indexBuilding: undefined,
  });
  const { openDialog } = useDialogManager();
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [addresses, setAddresses] = useContext(DataAddressesContext);
  const classesForecast = useStylesForecast();
  const [userDetails] = useContext(UserDetailsContext);
  const [updateFailed, setUpdateFailed] = useState(false);
  const { t } = useTranslation();

  useNotistack(`${t("retrievalFailedMsg")}`, updateFailed, undefined, "error");

  let history = useHistory();
  const locationError = {
    pathname: Routes.error,
  };
  const theme = useTheme();
  const classes = useStylesLayout({
    spacing: theme.spacing(SPACING_FACTOR),
    isDarkTheme: isDarkTheme,
  });

  useScroolTotop();

  useEffect(() => {
    if (userDetails && !isOpenAccess.access) {
      setAddresses({
        active: undefined,
        inactive: undefined,
      });
      setTimeout(() => {
        doFetch(
          `/reports/search/properties?page=1&active=1${
            isDarkTheme
              ? `&project=${folders.project}&building=${folders.building}`
              : ""
          }&lang=${direction.isRtl ? "he" : "en"}`
        )
          .then((dataActive) => {
            if (dataActive.msg) {
              setUpdateFailed(true);
              dataActive = {};
            }
            if (!isDarkTheme) {
              doFetch(
                `/reports/search/properties?page=1&active=0&lang=${
                  direction.isRtl ? "he" : "en"
                }`
              )
                .then((dataInactive) => {
                  if (dataInactive.msg) {
                    setUpdateFailed(true);
                    dataInactive = {};
                  }

                  setAddresses({ active: dataActive, inactive: dataInactive });
                })
                .catch((error) => {
                  history.replace(locationError);
                });
            } else {
              setAddresses((prevState: any) => ({
                ...prevState,
                active: dataActive,
              }));
            }
          })
          .catch((error) => history.replace(locationError));
      }, 1500);
    }
  }, [userDetails, folders]);

  const handleOpenAddProp = () => {
    openDialog(SelectPropDetails, {
      DialogProps: { maxWidth: "md" },
    });
  };

  return (
    <div
      style={{
        minHeight: "calc(100% - 168px)",
      }}
      className={classes.divContent}
    >
      <div className={classes.divWidth}>
        <ScrollToTop />

        <Grid container spacing={3} style={{ paddingTop: 12 }}>
          {isDarkTheme && (
            <Grid item xs={12}>
              <TilesContainer topBorder>
                <Typography variant={"h5"}>
                  {t("Reports.projectsList")}
                </Typography>
                <ProjectsComponent folders={folders} setFolders={setFolders} />
              </TilesContainer>
            </Grid>
          )}
          {!isDarkTheme && (
            <Grid item xs={12}>
              <TilesContainer topBorder>
                <div>
                  <div className={classesForecast.containerCta}>
                    <Typography variant={"h5"}>
                      {t("Reports.inactiveTitle")}
                    </Typography>
                    <Button
                      color={"primary"}
                      variant={"contained"}
                      onClick={handleOpenAddProp}
                    >
                      {t("Reports.buyProperty")}
                    </Button>
                  </div>
                  {addresses.inactive ? (
                    <DataReportTable
                      addresses={addresses.inactive}
                      active={false}
                    />
                  ) : (
                    <LoadingCharts name="" />
                  )}
                </div>
              </TilesContainer>
            </Grid>
          )}
          {isDarkTheme && folders.indexProject === undefined ? (
            <></>
          ) : (
            <Grid item xs={12}>
              <TilesContainer topBorder>
                <div>
                  <div className={classesForecast.containerCta}>
                    <Typography variant={"h5"}>
                      {t("Reports.activeTitle")}
                    </Typography>
                    <Button
                      color={"primary"}
                      variant={"contained"}
                      onClick={handleOpenAddProp}
                    >
                      {t("Reports.buyProperty")}
                    </Button>
                  </div>

                  {addresses.active ? (
                    <DataReportTable
                      addresses={addresses.active}
                      active={true}
                      building={folders.building}
                      project={folders.project}
                    />
                  ) : (
                    <LoadingCharts name="" />
                  )}
                </div>
              </TilesContainer>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
export default Reports;
