import React, { useContext, useEffect } from "react";
import { useStylesTopBar } from "../../styledComponent/TopBarStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import {
  PurchasePropertyContext,
  PurchasePropertyIL,
} from "../../context/PurchasePropertyContext";
import SelectPropDetails from "../../dialogs/add-prop/SelectPropDetails";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import { useLazyDataApi } from "common-components";
import { LoadingButtons } from "../layout/Layout";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

interface SearchGushHalkaProps {
  width: string;
  place: string;
  openSelectProp?: boolean;
  btnColor?: "primary";
}

const SearchGushHalka: React.FC<SearchGushHalkaProps> = ({
  width,
  place,
  openSelectProp,
  btnColor,
}) => {
  const [direction] = useContext(DirectionContext);
  const { openDialog } = useDialogManager();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [{ data: lazyData, error, isLoading }, lazyFetch] = useLazyDataApi<
    any
  >();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme: isDarkTheme, place: place };
  const classes = useStylesTopBar(props);
  //@ts-ignore
  const [property, setProperty] = useContext(PurchasePropertyContext);
  const { t } = useTranslation();

  const onSubmit = (values: any) => {
    lazyFetch(
      `/reports/search/gush-halka?halka=${values.halka}&gush=${values.gush}`
    );
  };

  useEffect(() => {
    if (lazyData) {
      const values = getValues();

      setProperty((prevState: Partial<PurchasePropertyIL>) => ({
        ...prevState,
        lat: lazyData.lat,
        long: lazyData.long,
        gush: Number(values.gush),
        halka: Number(values.halka),
      }));
      if (openSelectProp) {
        openDialog(SelectPropDetails, { DialogProps: { maxWidth: "md" } });
      }
    }
  }, [lazyData]);

  const classesLabel = useStylesAddProp({ isDarkTheme });
  const values = getValues();

  useNotistack(`${t("updateFailedMsg")}`, error, undefined, "error");

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className={classes.divFlexCenter}
      style={{ width: width }}
    >
      <Grid container spacing={1} wrap={"nowrap"}>
        <Grid item xs={4} md={5}>
          <TextField
            id="gush"
            error={errors["gush"] !== undefined}
            placeholder={t("SearchBar.placeHolderGush")}
            defaultValue={property.gush}
            variant="outlined"
            type="number"
            size="small"
            style={{ width: "100%" }}
            InputProps={{
              className: classesLabel.inputGushHalka,
              inputProps: {
                inputMode: "numeric",
              },
            }}
            onFocus={(event) => {
              event.target.select();
            }}
            {...register("gush", {
              required: "required",
              pattern: /^[0-9]*$/,
            })}
          />
        </Grid>

        <Grid item xs={4} md={5}>
          <TextField
            id="halka"
            error={errors["halka"] !== undefined}
            placeholder={t("SearchBar.placeHolderHalka")}
            defaultValue={property.halka}
            variant="outlined"
            type="number"
            size="small"
            style={{ width: "100%" }}
            InputProps={{
              className: classesLabel.inputGushHalka,
              inputProps: {
                inputMode: "numeric",
              },
            }}
            onFocus={(event) => {
              event.target.select();
            }}
            {...register("halka", {
              required: "required",
              pattern: /^[0-9]*$/,
            })}
          />
        </Grid>

        <Grid item xs={4} md={2}>
          <Button
            type={"submit"}
            variant={"contained"}
            color={isDarkTheme ? "primary" : btnColor ? btnColor : "secondary"}
            style={{ height: "100%", width: "100%" }}
          >
            {isLoading ? (
              <LoadingButtons color={"inherit"} style={{ padding: "3px 0" }} />
            ) : (
              `${
                openSelectProp
                  ? `${t("SearchBar.buttonGo")}`
                  : `${t("SearchBar.buttonSelect")}`
              }`
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default SearchGushHalka;
