export const mainPropColor = "#001145";
export const greyPropColor = "rgb(89,92,98)";

export const yellow = "#f7c324";
export const purple = "#AB99FF";
export const greyAxis = "#666";
export const negativeRedColor = "#a91e2c";
export const positiveGreenColor = "#18634b";
export const color2 = "#9dc3e6";
export const color3 = "#2e75b6";
export const color4 = "#1f4e79";
export const color5 = "#B0B9BF";

export const topBorder = "#c4d1ec";
export const borderColor = "#dbdbdb";
export const lightBoxes = "rgb(247, 247, 247)";
export const links = "#49a9f5";

export const fiveColors = [color2, color3, mainPropColor, color4, color5];
export const sixColors = [
  color2,
  color3,
  mainPropColor,
  color4,
  color5,
  borderColor,
];
export const fourColors = [mainPropColor, color4, color3, color5];
export const lineColor = [color3, color4];
export const posConBar = [mainPropColor, color5];
export const barComparisons = [mainPropColor, color5];
export const stackBarColors = [mainPropColor, color5];
export const mortgageColors = [color4, mainPropColor, color5];
export const mortgageDonutColors = [mainPropColor, color5];

//DARK THEME
export const darkMainPropColor = "#4286e6";
export const darkBackground = "#1e1d2b";
export const darkElements = "#292b3a";
export const darkText = "#e0e0e0";
export const darkBorderColor = "#62616f";
export const darkThemeGridLines = "#c7c8cb24";
export const darkThemeSliderRail = "#2b4468";
export const darkBoxes = "#42414d";
export const darkThemePositiveGreen = "#72b70a";
export const darkThemeNegativeRed = "#f32c0c";
export const darkPurple = "#a941f0";
export const darkOrange = "#f2b863";
export const darkBlue = "#5042E6";
export const darkYellow = "#f2e763";
export const darkGrey = "#c4c4c4";
export const darkTurquoise = "#42D8E6";

export const darkSevenColors = [
  darkOrange,
  darkMainPropColor,
  darkBlue,
  darkYellow,
  darkGrey,
  darkTurquoise,
  darkPurple,
];
export const darkFiveColors = [
  darkMainPropColor,
  darkTurquoise,
  darkBlue,
  darkGrey,
  darkPurple,
];
export const darkSixColors = [
  darkMainPropColor,
  darkTurquoise,
  darkBlue,
  darkGrey,
  darkPurple,
  darkOrange,
];
export const darkFourColors = [
  darkTurquoise,
  darkBlue,
  darkGrey,
  darkMainPropColor,
  darkPurple,
];
export const darkLineColor = [darkBlue, darkMainPropColor];
export const darkPosConBar = [darkMainPropColor, color5];
export const darkBarComparativeColors = [
  darkBlue,
  darkPurple,
  darkMainPropColor,
];
export const darkBarAllPropsColors = [darkMainPropColor, darkYellow];
export const darkStackBarColors = [darkMainPropColor, darkGrey];
// export const darkMortgageColors = [darkTurquoise, darkMainPropColor, darkBlue];
export const darkMortgageColors = [darkTurquoise, darkMainPropColor, darkGrey];
export const darkBarComparisons = [darkMainPropColor, darkBlue];
