import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locale/en.json";
import he from "../locale/he.json";

const CURRENT_LANG = "he";

i18n.use(initReactI18next).init({
	resources: {
		he: { common: he },
		en: { common: en },
	},
	fallbackLng: "he",
	lng: CURRENT_LANG,
	ns: ["common"],
	defaultNS: "common",
	fallbackNS: ["common"],
	interpolation: {
		escapeValue: false,
	},
	// debug only when not in production
	debug: process.env.NODE_ENV !== "production",
});

export default i18n;
