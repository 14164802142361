import React, { useContext, useState } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { useStylesTable } from "../../charts/table/TableChart";
import TilesContainer from "../TilesContainer";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import PurchasePlan from "../../dialogs/pricing/PurchasePlan";
import TablePaginationFooter from "../../charts/table/TablePaginationFooter";
import { LoadingCharts } from "../layout/Layout";
import { useIsMobileScreen } from "common-components";
import { useStylesUserPage } from "../../styledComponent/UserPageStyled";
import Typography from "@material-ui/core/Typography";
import { LG_PLUS_FONT } from "../../services/fonts";
import { useTranslation } from "react-i18next";
import { IMAGES_LINK } from "common-components";
import TitleGrid from "../TitleGrid";

const tableHeadTitles = (t) => {
  return [
    `${t("UserManagement.reports.type")}`,
    `${t("UserManagement.reports.boughtDate")}`,
    `${t("UserManagement.reports.expirationDate")}`,
    `${t("UserManagement.reports.daysLeft")}`,
    `${t("UserManagement.reports.numberOfReportsSubscription")}`,
    `${t("UserManagement.reports.reportsUsedSubscription")}`,
    `${t("UserManagement.reports.reportsLeftSubscription")}`,
  ];
};

const UserManagementSubscriptions = ({ userData, subscriptions }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const classes = useStylesTable();
  const { openDialog } = useDialogManager();
  const { t } = useTranslation();
  const emptyRows = subscriptions
    ? rowsPerPage -
      Math.min(rowsPerPage, subscriptions.length - (page - 1) * rowsPerPage)
    : 0;
  const isExtraSmallScreen = useIsMobileScreen("xs");

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const classesUser = useStylesUserPage({ isDarkTheme, isExtraSmallScreen });

  return (
    <TilesContainer
      topBorder
      style={{
        width: "100%",
      }}
    >
      {!userData.email && <LoadingCharts name={""} size={"3x"} />}

      {userData.email && (
        <Grid container style={{ height: "100%" }}>
          <TitleGrid title={t("UserManagement.reports.title")} />
          {!subscriptions && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography gutterBottom>
                {t("UserManagement.reports.noReports")}
              </Typography>
              <img
                src={`${IMAGES_LINK}/dashboard/elements/home.svg`}
                alt="added"
                style={{ width: 90, height: 90 }}
              />
            </Grid>
          )}
          {subscriptions && (
            <div style={{ width: "100%", marginBottom: 15 }}>
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classesUser.acquiredReportsContainer}
                >
                  <Typography className={classesUser.acquiredTitle}>
                    {t("UserManagement.reports.reportsBought")}
                  </Typography>

                  <Typography className={classesUser.acquiredValue}>
                    {subscriptions.reduce((total, currentValue) => {
                      return total + parseInt(currentValue.total_reports || 0);
                    }, 0)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classesUser.acquiredReportsContainer}
                >
                  <Typography noWrap>
                    {t("UserManagement.reports.reportsUsed")}
                  </Typography>

                  <Typography noWrap style={{ fontSize: LG_PLUS_FONT }}>
                    {subscriptions.reduce((total, currentValue) => {
                      return total + parseInt(currentValue.total_reports || 0);
                    }, 0) -
                      subscriptions.reduce((total, currentValue) => {
                        return total + parseInt(currentValue.balance || 0);
                      }, 0)}
                  </Typography>
                </Grid>
              </Grid>

              <TableContainer
                className={classes.container}
                style={{ padding: "12px 6px" }}
              >
                <Table style={{ padding: "12px" }}>
                  <TableHead>
                    <TableRow>
                      {tableHeadTitles(t).map((title) => {
                        return (
                          <TableCell
                            align="left"
                            key={title}
                            style={{ fontWeight: 600 }}
                          >
                            {title}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptions
                      .sort(function (a, b) {
                        return (
                          new Date(b.datetime_start) -
                          new Date(a.datetime_start)
                        );
                      })
                      .slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage
                      )
                      .map((subscription) => {
                        return (
                          <TableRow key={subscription.datetime_start}>
                            <TableCell align="left">
                              {subscription.total_reports > 10
                                ? `${t(
                                    "UserManagement.reports.yearlySubscription"
                                  )}`
                                : `${t("UserManagement.reports.oneUse")}`}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(subscription.datetime_start)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(subscription.datetime_end)}
                            </TableCell>
                            <TableCell align="left">
                              {new Date(subscription.datetime_end).getTime() >
                              new Date().getTime()
                                ? (
                                    (new Date(
                                      subscription.datetime_end
                                    ).getTime() -
                                      new Date().getTime()) /
                                    (1000 * 3600 * 24)
                                  ).toFixed(0)
                                : 0}
                            </TableCell>
                            <TableCell align="left">
                              {subscription.total_reports}
                            </TableCell>
                            <TableCell align="left">
                              {subscription.total_reports -
                                subscription.balance}
                            </TableCell>
                            <TableCell align="left">
                              {subscription.balance}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 67 * emptyRows }}>
                        <TableCell colSpan={7} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {Math.ceil(subscriptions.length / 5) > 1 && (
                <TablePaginationFooter
                  classes={classes}
                  count={Math.ceil(subscriptions.length / 5)}
                  setPage={setPage}
                  page={page}
                />
              )}
            </div>
          )}

          <Grid
            container
            item
            xs={12}
            justify={"center"}
            alignItems={"flex-end"}
          >
            <Button
              onClick={() =>
                openDialog(PurchasePlan, {
                  DialogProps: {
                    maxWidth: "md",
                  },
                })
              }
              variant={"contained"}
              color="primary"
            >
              {t("UserManagement.reports.buyNew")}
            </Button>
          </Grid>
        </Grid>
      )}
    </TilesContainer>
  );
};

export default UserManagementSubscriptions;
