import { Route } from "react-router-dom";

export const Routes = {
  dashboard: "/il/report",
  dashboardSummary: "/il/report/summary/",
  dashboardTama: "/il/report/tama/",
  dashboardImpact: "/il/report/impact/",
  dashboardComparisons: "/il/report/comparisons/",
  dashboardRent: "/il/report/rent/",
  dashboardLoans: "/il/report/loans/",
  dashboardBuildingRights: "/il/report/building-rights/",
  dashboardRenovations: "/il/report/renovations/",
  dashboardId: "/il/report/",
  successfulPurchase: "/il/payment/success",
  errorPurchase: "/il/payment/error",
  demoSummary: "/il/report/summary/demo/",
  demoTama: "/il/report/tama/demo/",
  demoImpact: "/il/report/impact/demo/",
  demoComparisons: "/il/report/comparisons/demo/",
  demoRent: "/il/report/rent/demo/",
  demoLoans: "/il/report/loans/demo/",
  demoBuildingRights: "/il/report/building-rights/demo/",
  demoRenovations: "/il/report/renovations/demo/",

  shareSummary: "/il/report/summary/share/",
  shareTama: "/il/report/tama/share/",
  shareImpact: "/il/report/impact/share/",
  shareComparisons: "/il/report/comparisons/share/",
  shareRent: "/il/report/rent/share/",
  shareLoans: "/il/report/loans/share/",
  shareBuildingRights: "/il/report/building-rights/share/",
  shareRenovations: "/il/report/renovations/share/",

  reports: "/il/reports",
  user: "/il/user/update",

  error: "/il/error",
};

export const RedirectRoutes = [
  Routes.dashboard,
  Routes.dashboardSummary,
  Routes.dashboardTama,
  Routes.dashboardImpact,
  Routes.dashboardComparisons,
  Routes.dashboardRent,
  Routes.dashboardLoans,
  Routes.dashboardBuildingRights,
  Routes.dashboardRenovations,
  Routes.dashboardId,
  Routes.demoSummary,
  Routes.demoTama,
  Routes.demoImpact,
  Routes.demoComparisons,
  Routes.demoRent,
  Routes.demoLoans,
  Routes.demoBuildingRights,
  Routes.demoRenovations,
  Routes.shareSummary,
  Routes.shareTama,
  Routes.shareImpact,
  Routes.shareComparisons,
  Routes.shareRent,
  Routes.shareLoans,
  Routes.shareBuildingRights,
  Routes.shareRenovations,
  Routes.reports,
  Routes.user,
  Routes.error,
  Routes.errorPurchase,
  Routes.successfulPurchase,
];

export const Dashboard = [
  Routes.dashboardSummary,
  Routes.dashboardTama,
  Routes.dashboardImpact,
  Routes.dashboardComparisons,
  Routes.dashboardRent,
  Routes.dashboardLoans,
  Routes.dashboardBuildingRights,
  Routes.dashboardRenovations,
  Routes.demoSummary,
  Routes.demoTama,
  Routes.demoImpact,
  Routes.demoComparisons,
  Routes.demoRent,
  Routes.demoLoans,
  Routes.demoBuildingRights,
  Routes.demoRenovations,
  Routes.shareSummary,
  Routes.shareTama,
  Routes.shareImpact,
  Routes.shareComparisons,
  Routes.shareRent,
  Routes.shareLoans,
  Routes.shareBuildingRights,
  Routes.shareRenovations,
];

export const RoutesUS = {
  dashboard: "/nyc/report",
  dashboardSummary: "/nyc/report/summary/",
  dashboardImpact: "/nyc/report/impact/",
  dashboardComparisons: "/nyc/report/comparisons/",
  dashboardRent: "/nyc/report/rent/",
  dashboardLoans: "/nyc/report/loans/",
  dashboardBuildingRights: "/nyc/report/building-rights/",
  dashboardRenovations: "/nyc/report/renovations/",
  dashboardNeighborhood: "/nyc/report/neighborhood/",
  dashboardId: "/nyc/report/",

  demoSummary: "/nyc/report/summary/demo/",
  demoImpact: "/nyc/report/impact/demo/",
  demoComparisons: "/nyc/report/comparisons/demo/",
  demoRent: "/nyc/report/rent/demo/",
  demoLoans: "/nyc/report/loans/demo/",
  demoBuildingRights: "/nyc/report/building-rights/demo/",
  demoRenovations: "/nyc/report/renovations/demo/",
  demoNeighborhood: "/nyc/report/neighborhood/demo/",

  shareSummary: "/nyc/report/summary/share/",
  shareTama: "/nyc/report/tama/share/",
  shareImpact: "/nyc/report/impact/share/",
  shareComparisons: "/nyc/report/comparisons/share/",
  shareRent: "/nyc/report/rent/share/",
  shareLoans: "/nyc/report/loans/share/",
  shareBuildingRights: "/nyc/report/building-rights/share/",
  shareRenovations: "/nyc/report/renovations/share/",

  reports: "/nyc/reports",
  user: "/nyc/user/update",

  error: "/nyc/error",
};

export const DashboardUS = [
  RoutesUS.dashboardSummary,
  RoutesUS.dashboardImpact,
  RoutesUS.dashboardComparisons,
  RoutesUS.dashboardRent,
  RoutesUS.dashboardLoans,
  RoutesUS.dashboardBuildingRights,
  RoutesUS.dashboardRenovations,
  RoutesUS.dashboardNeighborhood,
  RoutesUS.demoSummary,
  RoutesUS.demoImpact,
  RoutesUS.demoComparisons,
  RoutesUS.demoRent,
  RoutesUS.demoLoans,
  RoutesUS.demoBuildingRights,
  RoutesUS.demoRenovations,
  RoutesUS.demoNeighborhood,
  RoutesUS.shareSummary,
  RoutesUS.shareTama,
  RoutesUS.shareImpact,
  RoutesUS.shareComparisons,
  RoutesUS.shareRent,
  RoutesUS.shareLoans,
  RoutesUS.shareBuildingRights,
  RoutesUS.shareRenovations,
];

export const RedirectRoutesUS = [
  RoutesUS.dashboard,
  RoutesUS.dashboardSummary,
  RoutesUS.dashboardImpact,
  RoutesUS.dashboardComparisons,
  RoutesUS.dashboardRent,
  RoutesUS.dashboardLoans,
  RoutesUS.dashboardBuildingRights,
  RoutesUS.dashboardRenovations,
  RoutesUS.dashboardNeighborhood,
  RoutesUS.dashboardId,
  RoutesUS.demoSummary,
  RoutesUS.demoImpact,
  RoutesUS.demoComparisons,
  RoutesUS.demoRent,
  RoutesUS.demoLoans,
  RoutesUS.demoBuildingRights,
  RoutesUS.demoRenovations,
  RoutesUS.demoNeighborhood,
  RoutesUS.shareSummary,
  RoutesUS.shareTama,
  RoutesUS.shareImpact,
  RoutesUS.shareComparisons,
  RoutesUS.shareRent,
  RoutesUS.shareLoans,
  RoutesUS.shareBuildingRights,
  RoutesUS.shareRenovations,
  RoutesUS.reports,
  RoutesUS.user,
  RoutesUS.error,
];

export const DetectPageDashboard = [
  Routes.dashboardSummary.replace(/[\/\\]/g, ""),
  Routes.dashboardTama.replace(/[\/\\]/g, ""),
  Routes.dashboardImpact.replace(/[\/\\]/g, ""),
  Routes.dashboardComparisons.replace(/[\/\\]/g, ""),
  Routes.dashboardRent.replace(/[\/\\]/g, ""),
  Routes.dashboardLoans.replace(/[\/\\]/g, ""),
  Routes.dashboardBuildingRights.replace(/[\/\\]/g, ""),
  Routes.dashboardRenovations.replace(/[\/\\]/g, ""),
  Routes.demoSummary.replace(/[\/\\]/g, ""),
  Routes.demoTama.replace(/[\/\\]/g, ""),
  Routes.demoImpact.replace(/[\/\\]/g, ""),
  Routes.demoComparisons.replace(/[\/\\]/g, ""),
  Routes.demoRent.replace(/[\/\\]/g, ""),
  Routes.demoLoans.replace(/[\/\\]/g, ""),
  Routes.demoBuildingRights.replace(/[\/\\]/g, ""),
  Routes.demoRenovations.replace(/[\/\\]/g, ""),
  Routes.shareSummary.replace(/[\/\\]/g, ""),
  Routes.shareTama.replace(/[\/\\]/g, ""),
  Routes.shareImpact.replace(/[\/\\]/g, ""),
  Routes.shareComparisons.replace(/[\/\\]/g, ""),
  Routes.shareRent.replace(/[\/\\]/g, ""),
  Routes.shareLoans.replace(/[\/\\]/g, ""),
  Routes.shareBuildingRights.replace(/[\/\\]/g, ""),
  Routes.shareRenovations.replace(/[\/\\]/g, ""),
  RoutesUS.dashboardSummary.replace(/[\/\\]/g, ""),
  RoutesUS.dashboardImpact.replace(/[\/\\]/g, ""),
  RoutesUS.dashboardComparisons.replace(/[\/\\]/g, ""),
  RoutesUS.dashboardRent.replace(/[\/\\]/g, ""),
  RoutesUS.dashboardLoans.replace(/[\/\\]/g, ""),
  RoutesUS.dashboardBuildingRights.replace(/[\/\\]/g, ""),
  RoutesUS.dashboardRenovations.replace(/[\/\\]/g, ""),
  RoutesUS.dashboardNeighborhood.replace(/[\/\\]/g, ""),
  RoutesUS.demoSummary.replace(/[\/\\]/g, ""),
  RoutesUS.demoImpact.replace(/[\/\\]/g, ""),
  RoutesUS.demoComparisons.replace(/[\/\\]/g, ""),
  RoutesUS.demoRent.replace(/[\/\\]/g, ""),
  RoutesUS.demoLoans.replace(/[\/\\]/g, ""),
  RoutesUS.demoBuildingRights.replace(/[\/\\]/g, ""),
  RoutesUS.demoRenovations.replace(/[\/\\]/g, ""),
  RoutesUS.demoNeighborhood.replace(/[\/\\]/g, ""),
  RoutesUS.shareSummary.replace(/[\/\\]/g, ""),
  RoutesUS.shareTama.replace(/[\/\\]/g, ""),
  RoutesUS.shareImpact.replace(/[\/\\]/g, ""),
  RoutesUS.shareComparisons.replace(/[\/\\]/g, ""),
  RoutesUS.shareRent.replace(/[\/\\]/g, ""),
  RoutesUS.shareLoans.replace(/[\/\\]/g, ""),
  RoutesUS.shareBuildingRights.replace(/[\/\\]/g, ""),
  RoutesUS.shareRenovations.replace(/[\/\\]/g, ""),
];
