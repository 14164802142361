import { HOMEPAGE_LINK } from "../api/api_links";

export const useIsBaseRoute = (baseUrl: string) => {
  let url: string = baseUrl;
  const host = window.location.host;
  if (baseUrl === "homepage") {
    return "https://" + host === HOMEPAGE_LINK;
  }
  return host.includes(url);
};
