import React, { useContext, useEffect, useState } from "react";
import DonutChart, { ChartjsData } from "../charts/DonutChart";
import { hexToRgbA } from "../services/helpers";
import { darkElements, darkFiveColors, fiveColors } from "../services/colors";
import { LegendInterface } from "./DataStackBarChart";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { labelData } from "../components/dashboard-components/tama/TamaLineChart";
import { SpinnerInsideCharts } from "../components/layout/Layout";
import { useTranslation } from "react-i18next";

function normaliseData(data: DataDonut, isDarkTheme: boolean) {
  return {
    labels: data.labels,
    datasets: [
      {
        data: data.series,
        backgroundColor: isDarkTheme
          ? darkFiveColors.map((color) => hexToRgbA(color, 1))
          : fiveColors.map((color) => hexToRgbA(color, 1)),
        hoverBackgroundColor: isDarkTheme
          ? darkFiveColors.map((color) => hexToRgbA(color, 0.7))
          : fiveColors.map((color) => hexToRgbA(color, 0.7)),
        hoverBorderColor: isDarkTheme
          ? darkFiveColors.map((color) => hexToRgbA(color, 0.7))
          : fiveColors.map((color) => hexToRgbA(color, 0.7)),
        borderColor: isDarkTheme ? darkElements : "#fff",
        hoverBorderWidth: 0,
      },
    ],
  };
}

interface DataDonut {
  labels: string[];
  series: number[];
}
interface DataDonutChartProps {
  dataProp: DataDonut;
}
const DataDonutChart: React.FC<DataDonutChartProps> = ({ dataProp }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [data, setData] = useState<ChartjsData | undefined>(undefined);
  const [legend, setLegend] = useState<LegendInterface[] | undefined>(
    undefined
  );
  const { t } = useTranslation();
  useEffect(() => {
    setData(normaliseData(dataProp, isDarkTheme));
    setLegend(
      labelData(t, dataProp, isDarkTheme, fiveColors, darkFiveColors, "labels")
    );
  }, [dataProp]);

  if (data && legend) {
    return <DonutChart data={data} legend={legend} />;
  } else {
    return <SpinnerInsideCharts />;
  }
};
export default DataDonutChart;
