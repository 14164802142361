import React, { useContext, useMemo, useRef } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { useIsMobileScreen } from "common-components";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { darkText, greyAxis } from "../services/colors";
import { ANIMATION_DURATION } from "../services/consts";
import "common-components/src/global-il/charts/RoundedBars";
import { useIsIncludes } from "common-components";
import { FONT } from "../services/fonts";
import { datasetKeyProvider } from "../components/dashboard-components/tama/LineChart";

const BarOptionsAllProp = ({ data }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const chartRef = useRef();
  const isSmallScreen = useIsMobileScreen();
  const targetRef = useRef();
  const isListings = useIsIncludes("listings");

  const chartJsOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      animation: {
        duration: ANIMATION_DURATION,
      },
      plugins: {
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
        datalabels: {
          display: false,
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
        rtl: true,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              drawBorder: false,
              display: false,
            },
            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: FONT,
              fontFamily: "Open Sans Hebrew, sans-serif",
            },
          },
        ],
      },
    };
  }, []);

  return (
    <div
      ref={targetRef}
      style={{
        minHeight: isSmallScreen ? "" : 220,
        maxHeight: isListings ? 350 : 250,
      }}
    >
      <HorizontalBar
        ref={chartRef}
        data={data}
        datasetKeyProvider={datasetKeyProvider}
        options={chartJsOptions}
        id={"myGraph"}
      />
    </div>
  );
};

export default BarOptionsAllProp;
