import React, { createContext, useState } from "react";

export const ToggleSliderContext = createContext({});

export const ToggleSliderContextProvider = ({ children }) => {
  const [value, setValue] = useState(3);

  return (
    <ToggleSliderContext.Provider value={[value, setValue]}>
      {children}
    </ToggleSliderContext.Provider>
  );
};
