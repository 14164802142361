import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { purchaseProperty } from "../payment/Payment";
import { useIsMobileScreen } from "common-components";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesAddProp } from "../../styledComponent/AddPropStyled";
import { PurchasePropertyContext } from "../../context/PurchasePropertyContext";
import PropDetailsIL from "./PropDetailsIL";
import { useLazyDataApi } from "common-components";
import {
  PROPERTY_TYPE_APARTMENT,
  propTypeConverter,
} from "./SelectPropDetails";
import { LoadingButtons } from "../../components/layout/Layout";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

export function hasValue(val) {
  return val && val !== "";
}
const useStylesForm = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > *": {
      padding: theme.spacing(1),
    },
  },
}));

function continueWithSelectedDetails(
  api,
  setLoading,
  property,
  values,
  setProperty,
  openDialog,
  dataGushHalka,
  t
) {
  setLoading(true);
  const newData = { ...property };
  if (!newData.type) newData.type = propTypeConverter("דירה");
  else newData.type = propTypeConverter(property.type);
  for (const [key, value] of Object.entries(property)) {
    if (values[key]) {
      newData[key] = Number(values[key]);
    }
  }

  if (values.project) {
    newData.project_name = values.project.project;
    newData.building_name = values.project.building;
  }
  if (dataGushHalka) {
    newData.lat = dataGushHalka.lat;
    newData.long = dataGushHalka.long;
    newData.gush = values.gush;
    newData.halka = values.halka;
  }

  newData.building_rights_approval = values.building_rights_approval ? 1 : 0;
  newData.parking = values.parking || "";
  newData.building_rights_level =
    values.building_rights_level === "no"
      ? "standard"
      : values.building_rights_level;
  if (values.maturation_period)
    newData.maturation_period = values.maturation_period;

  setProperty(newData);

  purchaseProperty(api, newData, openDialog, t, setProperty);
}

const FormSelectPropDetails = ({
  propTypeVal,
  register,
  handleSubmit,
  errors,
  watch,
  control,
  setValue,
  isAddressSwitch,
  errorAddress,
  getValues,
}) => {
  const [direction] = useContext(DirectionContext);
  const [
    { data: lazyData, error: lazyError, isLoading },
    lazyFetch,
  ] = useLazyDataApi();
  const [property, setProperty] = useContext(PurchasePropertyContext);
  const { openDialog } = useDialogManager();
  const classForm = useStylesForm();
  const isMobileScreen = useIsMobileScreen();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (lazyData) {
      const values = getValues();

      continueWithSelectedDetails(
        direction.api,
        setLoading,
        property,
        values,
        setProperty,
        openDialog,
        lazyData,
        t
      );
    }
  }, [lazyData]);

  const onSubmit = (values) => {
    if (!isAddressSwitch) {
      lazyFetch(
        `/reports/search/gush-halka?halka=${values.halka}&gush=${values.gush}`
      );
    } else {
      continueWithSelectedDetails(
        direction.api,
        setLoading,
        property,
        values,
        setProperty,
        openDialog,
        undefined,
        t
      );
    }
  };
  const [isDarkTheme] = useContext(DarkThemeContext);

  const props = { isDarkTheme };
  const classes = useStylesAddProp(props);

  useNotistack(`${t("insertAddress")}`, errorAddress, undefined, "error");
  useNotistack(`${t("noBlockPlot")}`, lazyError, undefined, "error");

  return (
    <form
      noValidate
      className={isMobileScreen ? "" : classForm.root}
      onSubmit={handleSubmit(onSubmit)}
    >
      <PropDetailsIL
        lg={"auto"}
        classesLabel={classes.inputLabelSearch}
        classesInput={classes.inputs}
        classesError={classes.errorsStyle}
        errors={errors}
        register={register}
        propTypeVal={propTypeVal}
        watch={watch}
        control={control}
        setValue={setValue}
      />

      <Grid
        container
        item
        xs={12}
        alignItems={"center"}
        justify={"flex-end"}
        style={{ margin: isMobileScreen & 0 }}
      >
        <Button
          type="submit"
          variant={"contained"}
          color="primary"
          disabled={
            isLoading ||
            (!property.lat && !property.long && isAddressSwitch) ||
            loading
          }
          style={{
            width: "30%",
          }}
        >
          {isLoading || loading ? (
            <LoadingButtons color={"inherit"} style={{ padding: "3px 0" }} />
          ) : (
            `${t("PropDetails.buttonGo")}`
          )}
        </Button>
      </Grid>
    </form>
  );
};

export default FormSelectPropDetails;
