import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { MD_FONT } from "../services/fonts";
import { greyAxis } from "../services/colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface CloseButtonProps {
  callback: () => void;
  height?: number | string;
  padding?: number | string;
  whiteColor?: boolean;
}
const CloseButton: React.FC<CloseButtonProps> = ({
  callback,
  height,
  padding,
  whiteColor,
}) => {
  return (
    <FontAwesomeIcon
      icon={faTimes as IconProp}
      color={whiteColor ? "white" : greyAxis}
      style={{
        cursor: "pointer",
        width: 40,
        height: height ? height : MD_FONT,
        padding: padding ? padding : 15,
      }}
      onClick={() => {
        sessionStorage.removeItem("address");
        sessionStorage.removeItem("planId");
        sessionStorage.removeItem("price");
        sessionStorage.removeItem("period");
        sessionStorage.removeItem("amount");
        callback();
      }}
    />
  );
};
export default CloseButton;
