import React, { useContext, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Logout from "./Logout";
import Avatar from "@material-ui/core/Avatar";
import {
  darkBackground,
  darkElements,
  darkText,
  mainPropColor,
} from "../../services/colors";
import { useStylesTopBar } from "../../styledComponent/TopBarStyled";
import { isLoggedIn } from "../../services/helpers";
import { useIsIncludes, useIsMobileScreen } from "common-components";
import ContactForm from "../../dialogs/dialog-components/ContactForm";
import Typography from "@material-ui/core/Typography";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import UserLoginIframe from "../../dialogs/login-singup/UserLoginIframe";
import { LANDING_PAGE_LINK } from "common-components";
import { useTranslation } from "react-i18next";
import { LanguageButton } from "common-components";
import useContextProps from "../../hooks/useContextProps";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Login } from "common-components/src/global/components/login/Login";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";
import { DirectionContext } from "../../context/DirectionContext";
import { COMPS_LINK } from "common-components/src/global/api/api_links";

const AvatarTopBar = () => {
  const context = useContextProps();
  const [authorization] = useContext(AuthorizationContext);
  const { t } = useTranslation();
  const [dropdown, setDropdown] = useState(false);
  const { openDialog } = useDialogManager();
  const handleClickAway = () => {
    setDropdown(false);
  };
  const handleClick = () => {
    setDropdown((prev) => !prev);
  };

  const [direction] = useContext(DirectionContext);

  const props = { isDarkTheme: context.isDarkTheme, isRtl: direction.isRtl };
  const classes = useStylesTopBar(props);
  const isDemo = useIsIncludes("demo");

  const handleOpenForm = () => {
    openDialog(ContactForm);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.divHeaderActions}>
        <div className={classes.divStyleActions}>
          <div className={classes.divUserActions}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Avatar
                onClick={handleClick}
                style={{
                  backgroundColor: context.isDarkTheme ? darkText : "white",
                  color: context.isDarkTheme ? darkBackground : "01145",
                }}
              >
                {context.userDetails?.initials}
              </Avatar>

              <FontAwesomeIcon
                onClick={handleClick}
                icon={faChevronDown as IconProp}
                size={"lg"}
                style={{
                  paddingRight: context.direction.isRtl ? 15 : "",
                  paddingLeft: !context.direction.isRtl ? 15 : "",
                }}
              />

              {dropdown && (
                <ul
                  className={classes.uLDropdownAvatar}
                  style={{
                    backgroundColor: context.isDarkTheme
                      ? darkElements
                      : mainPropColor,
                  }}
                >
                  {isDemo && (
                    <li className={classes.lIDropdownAvatar}>
                      <button className={classes.buttonAvatarDropdown}>
                        <Typography
                          onClick={() => {
                            window.location.replace(LANDING_PAGE_LINK);
                          }}
                        >
                          {t("Tabs.demoExit")}
                        </Typography>
                      </button>
                    </li>
                  )}
                  <li className={classes.lIDropdownAvatar}>
                    <button className={classes.buttonAvatarDropdown}>
                      <a href={COMPS_LINK} target="_blank" rel="noreferrer">
                        {t("Tabs.comps")}
                      </a>
                    </button>
                  </li>
                  {authorization && (
                    <li className={classes.lIDropdownAvatar}>
                      <button className={classes.buttonAvatarDropdown}>
                        <Typography onClick={handleOpenForm}>
                          {t("Tabs.contactUs")}
                        </Typography>
                      </button>
                    </li>
                  )}
                  <li className={classes.lIDropdownAvatar}>
                    <button className={classes.buttonAvatarDropdown}>
                      <a href={LANDING_PAGE_LINK} rel="noreferrer">
                        {t("Tabs.homePage")}
                      </a>
                    </button>
                  </li>
                  <LanguageButton isMobile={false} context={context} />
                  {authorization ? (
                    <li className={classes.lIDropdownAvatar}>
                      <Logout />
                    </li>
                  ) : (
                    <>
                      <li className={classes.lIDropdownAvatar}>
                        <button className={classes.buttonAvatarDropdown}>
                          <Typography
                            onClick={() => {
                              openDialog(Login, {
                                DialogProps: { maxWidth: "xs" },
                              });
                            }}
                          >
                            {t("Tabs.login")}
                          </Typography>
                        </button>
                      </li>

                      {isDemo && (
                        <li className={classes.lIDropdownAvatar}>
                          <button className={classes.buttonAvatarDropdown}>
                            <Typography
                              onClick={() => {
                                openDialog(Login, {
                                  DialogProps: { maxWidth: "xs" },
                                });
                              }}
                            >
                              {t("Tabs.signUp")}
                            </Typography>
                          </button>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};
export default AvatarTopBar;
