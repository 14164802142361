import React, { createContext, useContext, useState } from "react";
import { OpenAccessContext } from "./OpenAccessContext";
import { DOMAIN } from "common-components";
//@ts-ignore
import Cookies from "js-cookie";

export const DarkThemeContext = createContext<any>({});
export const DarkThemeContextProvider: React.FC = ({ children }) => {
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [isDarkTheme, setDarkTheme] = useState<boolean>(
    Boolean(
      !isOpenAccess.access &&
        Cookies.get("username", {
          domain: DOMAIN,
        })
        ? Boolean(Number(localStorage.getItem("premium")))
        : false
    )
  );

  return (
    <DarkThemeContext.Provider value={[isDarkTheme, setDarkTheme]}>
      {children}
    </DarkThemeContext.Provider>
  );
};
