import { darkBackground, darkText, greyAxis } from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";
import { SM_FONT } from "../services/fonts";

export const useStylesBarChart = makeStyles({
  uLDropdownSmall: (props) => ({
    position: "absolute",
    top: props.top ? props.top : "inherit",
    background: props.isDarkTheme ? darkBackground : "rgb(255, 255, 255)",
    listStyle: "none",
    margin: 0,
    padding: "8px 0",
    width: 130,
    maxWidth: 300,
    maxHeight: props.maxHeight ? props.maxHeight : "200px",
    overflow: "auto",
    borderRadius: "var(--border-radius)",
    boxShadow: props.isDarkTheme
      ? "rgba(255, 255, 255, 0.1) 0px 2px 14px 0px"
      : "rgba(0, 0, 0, 0.1) 0px 2px 14px 0px",
    zIndex: 3000,
  }),
  lIDropdownSmall: (props) => ({
    fontSize: SM_FONT,
    display: "flex",
    color: props.isDarkTheme ? darkText : greyAxis,
    padding: "6px 12px",
    transition:
      "background 144ms ease-in-out 32ms, box-shadow 144ms ease-in 0ms",
    cursor: "pointer",
    textAlign: "start",
    "&:hover": {
      textDecoration: "none",
      background: props.isDarkTheme
        ? "rgba(255, 255, 255, 0.16)"
        : "rgba(0, 0, 0, 0.04)",
      color: props.isDarkTheme ? darkText : greyAxis,
      transition:
        "background 144ms ease-in-out 0s, box-shadow 144ms ease-in 32ms",
    },
  }),
});
