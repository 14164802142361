import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { doFetch } from "common-components";
import UserManagementInput from "./UserManagementInput";
import UserManagementButtons from "./UserManagementButtons";
import UserManagementCheckbox from "./UserManagementCheckBox";
import { makeStyles } from "@material-ui/core/styles";
import TilesContainer from "../TilesContainer";
import { LoadingCharts } from "../layout/Layout";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import UserManagementPassword from "./UserManagementPassword";
import Typography from "@material-ui/core/Typography";
import { XS_PLUS_FONT } from "../../services/fonts";
import { refreshToken } from "../dashboard-components/global-summary/SummaryPrice";
import { useTranslation } from "react-i18next";
import TitleGrid from "../TitleGrid";
import { isFederationLogin } from "../../services/helpers";

export const useStylesSpinner = makeStyles({
  spinnerStyle: {
    zIndex: -1,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const UserManagementDetails = ({
  userData,
  setUserData,
  setUpdateSuccess,
  setUpdateFailed,
  classes,
}) => {
  const { openDialog } = useDialogManager();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const handleChangeUser = (event) => {
    let myUserData = { ...userData };
    if (event.target.name === "marketing") {
      myUserData[event.target.name] = event.target.checked;
    } else myUserData[event.target.name] = event.target.value;
    setUserData(myUserData);
  };

  const checkResponse = (response) => {
    if (response.message === "User Updated") setUpdateSuccess(true);
    else setUpdateFailed(true);
    setLoading(false);
  };

  const onSubmitUser = async () => {
    setLoading(true);
    let myUserData = { ...userData };
    const response = await doFetch(`/user/details/update`, {
      method: "POST",
      body: JSON.stringify({
        phone_number: `+972${myUserData.phone_number}`,
        marketing: myUserData.marketing,
      }),
    });
    checkResponse(response);
    await refreshToken();
  };

  const handleChangePassword = () => {
    openDialog(UserManagementPassword, {
      DialogContentProps: {
        userData,
        setUpdateSuccess,
        setUpdateFailed,
        classes,
      },
      DialogProps: {
        scroll: "paper",
        disableBackdropClick: false,
        maxWidth: "sm",
      },
    });
  };

  return (
    <TilesContainer
      topBorder
      style={{
        width: "100%",
        margin: "0px auto",
      }}
    >
      {!userData.email && <LoadingCharts name={""} size={"3x"} />}
      {userData.email && (
        <Grid container>
          <TitleGrid title={t("UserManagement.personalInfo.title")} />

          <form
            noValidate
            onSubmit={handleSubmit(onSubmitUser)}
            style={{ margin: "0px auto", textAlign: "center" }}
          >
            <Grid
              container
              justify={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing={3}
            >
              <Grid container item xs={12}>
                <UserManagementInput
                  xs={[2, 10]}
                  title={`${t("UserManagement.personalInfo.name")} :`}
                  name={"name"}
                  register={register}
                  registerOptions={{}}
                  type={"text"}
                  value={userData.name}
                  disabled={true}
                  variant="filled"
                  InputProps={{
                    className: classes.inputTextField,
                    style: {
                      border: "1px mainPropColor solid",
                    },
                  }}
                  inputProps={{
                    style: { padding: "10.5px 14px" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <UserManagementInput
                  xs={[2, 10]}
                  title={`${t("UserManagement.personalInfo.email")} :`}
                  name={"email"}
                  register={register}
                  registerOptions={{}}
                  type={"text"}
                  value={userData.email}
                  disabled={true}
                  variant="filled"
                  InputProps={{
                    className: classes.inputTextField,
                    inputProps: {
                      inputMode: "email",
                    },
                    style: {
                      border: "1px mainPropColor solid",
                    },
                  }}
                  inputProps={{
                    style: { padding: "10.5px 14px" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <UserManagementInput
                  xs={[2, 8, 2]}
                  title={`${t("UserManagement.personalInfo.phone")} :`}
                  name={"phone_number"}
                  register={register}
                  registerOptions={{
                    required: "phone_number is required",
                    pattern: /^5\d([-]{0,1})\d{7}$/,
                  }}
                  value={userData.phone_number}
                  type={"text"}
                  disabled={false}
                  onChange={(event) => handleChangeUser(event)}
                  errors={errors["phone_number"]}
                  errorsMsgs={{
                    required: `${t(
                      "UserManagement.personalInfo.phoneRequired"
                    )}`,
                    pattern: `${t("UserManagement.personalInfo.phonePattern")}`,
                  }}
                  variant="outlined"
                  placeholder={t("UserManagement.personalInfo.phone")}
                  InputProps={{
                    className: classes.inputTextField,
                    inputProps: {
                      inputMode: "numeric",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} container justify={"flex-start"}>
                <UserManagementCheckbox
                  name="marketing"
                  disabled={false}
                  checked={userData.marketing}
                  label={
                    <Typography
                      align={"right"}
                      style={{ fontSize: XS_PLUS_FONT }}
                    >
                      {t("UserManagement.personalInfo.acceptMarketing")}
                    </Typography>
                  }
                  onChange={(event) => handleChangeUser(event)}
                  errors={{}}
                  errrorsMsg={{}}
                  register={register}
                />
              </Grid>
              {!isFederationLogin() && (
                <Grid item xs={12} container justify={"flex-start"}>
                  <Typography
                    align={"right"}
                    onClick={handleChangePassword}
                    style={{
                      fontSize: XS_PLUS_FONT,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("UserManagement.personalInfo.changePassword")}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                <UserManagementButtons
                  title={t("UserManagement.personalInfo.update")}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </TilesContainer>
  );
};

export default UserManagementDetails;
