import React, { useContext } from "react";
import { useTheme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ContentAreaLayout from "./ContentAreaLayout";
import FooterSearch from "../FooterSearch";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { darkMainPropColor, mainPropColor } from "../../services/colors";
import { useStylesLayout } from "../../styledComponent/LayoutStyled";
import { ScrollToTop } from "common-components";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { Routes, RoutesUS } from "../../services/routes";
import Typography from "@material-ui/core/Typography";
import { Variant as ThemeVariant } from "@material-ui/core/styles/createTypography";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const SPACING_FACTOR = 3;

interface LayoutProps {
  mapCurrent?: boolean;
}

export function detectVal() {
  const page = window.location.pathname;
  const pageNoSlash = page.replace(/[\/\\]/g, "");

  if (
    pageNoSlash === Routes.dashboardSummary.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.shareSummary.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.demoSummary.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.dashboardSummary.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.demoSummary.replace(/[\/\\]/g, "")
    // || pageNoSlash === Routes.listingsSummary.replace(/[\/\\]/g, "") TODO  to be added in the Future
  ) {
    return 0;
  } else if (
    pageNoSlash === Routes.dashboardImpact.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.shareImpact.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.demoImpact.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.dashboardImpact.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.demoImpact.replace(/[\/\\]/g, "")
    // || pageNoSlash === Routes.listingsImpact.replace(/[\/\\]/g, "") TODO  to be added in the Future
  ) {
    return 1;
  } else if (
    pageNoSlash === Routes.dashboardComparisons.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.shareComparisons.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.demoComparisons.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.dashboardComparisons.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.demoComparisons.replace(/[\/\\]/g, "")
    // || pageNoSlash === Routes.listingsComparisons.replace(/[\/\\]/g, "") TODO  to be added in the Future
  ) {
    return 2;
  } else if (
    pageNoSlash === Routes.dashboardLoans.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.shareLoans.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.demoLoans.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.dashboardLoans.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.demoLoans.replace(/[\/\\]/g, "")
    // || pageNoSlash === Routes.listingsLoans.replace(/[\/\\]/g, "") TODO  to be added in the Future
  ) {
    return 3;
  } else if (
    pageNoSlash === Routes.dashboardTama.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.shareTama.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.demoTama.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.dashboardNeighborhood.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.demoNeighborhood.replace(/[\/\\]/g, "")
  ) {
    return 4;
  } else if (
    pageNoSlash === Routes.dashboardRent.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.shareRent.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.demoRent.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.dashboardRent.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.demoRent.replace(/[\/\\]/g, "")
    // || pageNoSlash === Routes.listingsRent.replace(/[\/\\]/g, "") TODO  to be added in the Future
  ) {
    return 5;
  } else if (
    pageNoSlash === Routes.dashboardBuildingRights.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.shareBuildingRights.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.demoBuildingRights.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.dashboardBuildingRights.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.demoBuildingRights.replace(/[\/\\]/g, "")
    // || pageNoSlash === Routes.listingsBuildingRights.replace(/[\/\\]/g, "") TODO  to be added in the Future
  ) {
    return 6;
  } else if (
    pageNoSlash === Routes.dashboardRenovations.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.shareRenovations.replace(/[\/\\]/g, "") ||
    pageNoSlash === Routes.demoRenovations.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.dashboardRenovations.replace(/[\/\\]/g, "") ||
    pageNoSlash === RoutesUS.demoRenovations.replace(/[\/\\]/g, "")
    // ||    pageNoSlash === Routes.listingsRenovations.replace(/[\/\\]/g, "") TODO  to be added in the Future
  ) {
    return 7;
  } else {
    return undefined;
  }
}
export function handleSwitch(
  region: string,
  isDemo: boolean,
  isShare: boolean,
  name: string
) {
  return region === "il"
    ? // @ts-ignore
      Routes[`${isDemo ? "demo" : isShare ? "share" : "dashboard"}${name}`]
    : // @ts-ignore
      RoutesUS[`${isDemo ? "demo" : isShare ? "share" : "dashboard"}${name}`];
}

const Layout: React.FC<LayoutProps> = () => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const theme = useTheme();
  const mapProps = {
    isDarkTheme: isDarkTheme,
    spacing: theme.spacing(SPACING_FACTOR),
  };
  const classesMap = useStylesLayout(mapProps);

  return (
    <>
      <ScrollToTop />
      <div id="content" className={classesMap.divContent}>
        <ContentAreaLayout SPACING_FACTOR={SPACING_FACTOR} />
      </div>
      <FooterSearch />
    </>
  );
};
type Variant = ThemeVariant | "srOnly";
interface LoadingChartsProps {
  name: string;
  color?: string;
  height?: string | number;
  minHeight?: string | number;
  padding?: string | number;
  size?: SizeProp;
  outsideHeight?: string;
  variant?: Variant;
}

interface LoadingButtonsProps {
  size?: SizeProp;
  color?: string;
  style?: any;
}

export function LoadingButtons({
  size = "lg",
  color = "white",
  style,
}: LoadingButtonsProps) {
  return (
    <FontAwesomeIcon
      icon={faSpinner as IconProp}
      spin
      size={size}
      color={color}
      style={style}
    />
  );
}
export function SpinnerInsideCharts() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 30,
        }}
      >
        <LoadingButtons size={"4x"} color={mainPropColor} />
      </div>
    </div>
  );
}
export function LoadingCharts({
  name,
  color,
  height,
  minHeight,
  padding,
  size,
  outsideHeight,
  variant,
}: LoadingChartsProps) {
  const [isDarkTheme] = useContext(DarkThemeContext);

  return (
    <div
      style={{
        height: outsideHeight ? outsideHeight : "100%",
      }}
    >
      <div>
        <Typography variant={variant ? variant : "inherit"}>{name}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: height ? height : "70%",
          padding: padding ? padding : 30,
          minHeight: minHeight ? minHeight : 300,
        }}
      >
        <FontAwesomeIcon
          icon={faSpinner as IconProp}
          spin
          size={size ? size : "3x"}
          color={
            color ? color : isDarkTheme ? darkMainPropColor : mainPropColor
          }
        />
      </div>
    </div>
  );
}

export default Layout;
