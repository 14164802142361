import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  color2,
  darkFourColors,
  darkMainPropColor,
  darkText,
  darkThemeGridLines,
  fourColors,
  greyAxis,
} from "../../../services/colors";
import { FONT } from "../../../services/fonts";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { Line } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import { useCustomTooltip } from "../../../charts/CustomTooltip";
import ClickableLegend from "./ClickableLegend";
import { labelData } from "../tama/TamaLineChart";
import ChartTooltip from "../../../charts/chartTooltip/ChartTooltip";
import {
  disableScrolling,
  enableScrolling,
} from "../../../charts/mortgage/MortgageLineChart";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";
import {
  yAxisCallbackMetersIL,
  yAxisCallbackMetersUS,
} from "../tama/LineChart";
import TitleGrid from "../../TitleGrid";

function yAxisCallbackMeters(value) {
  let unit = 'מ"ר';
  return `${value} ${unit}`;
}

const dataRel = (data, show, isDarkTheme) => {
  return {
    labels: data.labels,
    datasets: show.map((item, index) => {
      return {
        label: item.show ? data.series[index].name : "",
        fill: false,
        data: item.show ? [...data.series[index].data] : [null],
        borderCapStyle: "round",
        lineTension: 0.5,
        borderDashOffset: 0,
        borderDash: data.series[index].name === "הנכס שלך" ? [25, 15] : [0, 0],
        backgroundColor:
          // TODO add english text
          data.series[index].name === "הנכס שלך" && !isDarkTheme
            ? color2
            : data.series[index].name === "הנכס שלך" && isDarkTheme
            ? darkMainPropColor
            : isDarkTheme
            ? darkFourColors[index]
            : fourColors[index],
        borderColor:
          data.series[index].name === "הנכס שלך" && !isDarkTheme
            ? color2
            : data.series[index].name === "הנכס שלך" && isDarkTheme
            ? darkMainPropColor
            : isDarkTheme
            ? darkFourColors[index]
            : fourColors[index],
        borderWidth: 2,
        pointBorderWidth: 1,
        pointHitRadius: 3,
        pointRadius: 2,
      };
    }),
  };
};

const ComparativeLine = ({ data }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(
    data.series.map((item, index) => {
      return {
        index: index,
        label: t(`labels.${item.name}`),
        show: true,
      };
    })
  );
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [direction] = useContext(DirectionContext);
  const chartRef = useRef();
  const [index, setCurrentIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );
  const [dataLine, setDataLine] = useState(dataRel(data, show, isDarkTheme));

  const chartJsOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      animation: {
        duration: 6000,
      },
      plugins: {
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
        datalabels: {
          display: false,
        },
      },

      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },

            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: FONT,
              fontFamily: "Open Sans Hebrew, sans-serif",
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: FONT,
              fontFamily: "Open Sans Hebrew, sans-serif",
              callback: direction.isRtl
                ? yAxisCallbackMetersIL
                : yAxisCallbackMetersUS,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
        custom: customTooltip,
      },
    };
  }, [isDarkTheme, direction]);

  const toggleShow = (label) => {
    if (label !== t("labels.property")) {
      const newArr = [...show];
      show.forEach((item, index) => {
        if (item.label === label) {
          newArr[index].show = !newArr[index].show;
        }
      });
      setShow(newArr);
    }
  };

  useEffect(() => {
    setDataLine(dataRel(data, show, isDarkTheme));
  }, [show]);

  return (
    <Grid item xs={12} container style={{ height: "100%" }}>
      <TitleGrid
        title={t("ComparativeLine.title")}
        tooltipText={t("ComparativeLine.tooltip")}
      />

      <Grid item xs={12}>
        <ClickableLegend
          show={show}
          toggleShow={toggleShow}
          legend={labelData(
            t,
            data,
            isDarkTheme,
            fourColors,
            darkFourColors,
            "series"
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div
          style={{ height: "100%" }}
          onTouchStart={() => {
            disableScrolling();
          }}
          onTouchEnd={() => {
            enableScrolling();
          }}
        >
          <Line ref={chartRef} data={dataLine} options={chartJsOptions} />
        </div>
        <ChartTooltip
          data={dataLine}
          title={{ title: t("ComparativeLine.totalTransactions"), year: true }}
          position={position}
          id={"tooltip-line-comparisons"}
          index={index}
          tooltipRef={tooltipRef}
          dataToMap={dataLine.datasets}
          sign={t("ComparativeLine.sqm")}
        />
      </Grid>
    </Grid>
  );
};
export default ComparativeLine;
