import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TooltipInfoIcon } from "common-components";
import React, { useContext } from "react";
import { DarkThemeContext } from "../context/DarkThemeContext";

interface TitleGrid {
  title: any;
  tooltipText?: any;
  interactive?: boolean;
}

const TitleGrid: React.FC<TitleGrid> = ({
  title,
  tooltipText,
  interactive,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  return (
    <Grid container item xs={12} justify={"space-between"}>
      <Grid item xs={11}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      {tooltipText && (
        <Grid
          container
          justify={"flex-end"}
          alignItems={"flex-start"}
          item
          xs={1}
          style={{ zIndex: 4 }}
        >
          <TooltipInfoIcon
            isDarkTheme={isDarkTheme}
            tooltipText={tooltipText}
            interactive={interactive}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default TitleGrid;
