import React, { Dispatch, SetStateAction, useContext } from "react";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { Chip, ClickAwayListener, Collapse } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useStylesBarChart } from "../../styledComponent/BarChartStyled";
import { TooltipInfoIcon } from "common-components";
import Typography from "@material-ui/core/Typography";
import { LG_PLUS_FONT, SM_FONT } from "../../services/fonts";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { DirectionContext } from "../../context/DirectionContext";

interface HeaderProps {
  filterDropdown: boolean;
  filterTypes: string[];
  handleClickAway?: () => void;
  filterByEvent: string[];
  setFilterDropdown: Dispatch<SetStateAction<boolean>>;
  setFilterByEvent: Dispatch<SetStateAction<string[]>>;
}

const TableImpactsHeader: React.FC<HeaderProps> = ({
  filterDropdown,
  filterTypes,
  setFilterDropdown,
  filterByEvent,
  setFilterByEvent,
}) => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();

  const handleDelete = (item: any) => {
    const newFilter = [...filterByEvent];
    newFilter.splice(newFilter.indexOf(item), 1);
    setFilterByEvent(newFilter);
  };
  const handleAddFilter = (item: any) => {
    if (!filterByEvent.includes(item)) {
      setFilterByEvent((prevState) => [...prevState, item]);
    }
  };
  const props = { isDarkTheme: isDarkTheme, maxHeight: "400px" };
  const classes = useStylesBarChart(props);

  return (
    <Grid container item xs={12} justify={"space-between"}>
      <Grid item xs={8}>
        <Typography variant={"h5"}>{t("ImpactTable.title")}</Typography>
      </Grid>
      <Grid item xs={4} container alignItems={"center"} justify={"flex-end"}>
        <Collapse
          in={filterByEvent.length > 0}
          timeout="auto"
          unmountOnExit
          style={{ marginLeft: 8 }}
        >
          {filterByEvent.map((item, index) => {
            return (
              <Chip
                label={item}
                onDelete={() => handleDelete(item)}
                color="primary"
                size="small"
                style={{
                  fontSize: SM_FONT,
                  margin: 2,
                  borderRadius: "var(--border-radius)",
                }}
              />
            );
          })}
        </Collapse>

        <ClickAwayListener onClickAway={() => setFilterDropdown(false)}>
          <IconButton
            aria-label="filter list"
            onClick={() => setFilterDropdown(!filterDropdown)}
          >
            <FilterListIcon style={{ fontSize: LG_PLUS_FONT }} />
            <Collapse
              in={filterDropdown}
              timeout="auto"
              unmountOnExit
              style={{
                position: "relative",
                right: direction.isRtl ? "-70px" : 70,
                top: 25,
              }}
            >
              <ul className={classes.uLDropdownSmall}>
                {filterTypes.map((item, index) => {
                  return (
                    <li
                      className={classes.lIDropdownSmall}
                      key={index}
                      onClick={() => handleAddFilter(item)}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </IconButton>
        </ClickAwayListener>
        <TooltipInfoIcon
          tooltipText={t("ImpactTable.tooltip")}
          isDarkTheme={isDarkTheme}
        />
      </Grid>
    </Grid>
  );
};
export default TableImpactsHeader;
