import React, { useContext, useEffect } from "react";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { PurchasePropertyContext } from "../context/PurchasePropertyContext";
import { OpenAccessContext } from "../context/OpenAccessContext";
import { isLoggedIn } from "../services/helpers";
import UserLoginIframe from "./login-singup/UserLoginIframe";
import { Login } from "common-components/src/global/components/login/Login";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";

const DialogOpenerAddProp = ({ paymentProcess }) => {
  const [property] = useContext(PurchasePropertyContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const { openDialog } = useDialogManager();
  const [authorization] = useContext(AuthorizationContext);

  useEffect(() => {
    if (paymentProcess && property.lat && property.long) {
      if (isOpenAccess.access && !authorization) {
        openDialog(Login, { DialogProps: { maxWidth: "xs" } });
      }
    }
  }, [paymentProcess, openDialog, property]);

  return <></>;
};
export default DialogOpenerAddProp;
