import React, { useContext } from "react";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Influence from "./Influence";
import HiddenShownText from "./HiddenShownText";
import { Column, RowTable } from "../../data/DataTableChart";
import IconButton from "@material-ui/core/IconButton";
import { darkText } from "../../services/colors";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useIsIncludes } from "common-components";

interface TableRowsPartProps {
  row: RowTable;
  index: number;
  columns: Column[];
  isBigScreen: boolean;
  isExtraSmallScreen: boolean;
  open: undefined | number;
  setOpen: (value: number | undefined) => void;
}

const TableRowsPart: React.FC<TableRowsPartProps> = ({
  row,
  index,
  columns,
  isBigScreen,
  isExtraSmallScreen,
  open,
  setOpen,
}) => {
  const isListings = useIsIncludes("listings");

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row["eventName"] + index}
    >
      {columns.map((column) => {
        let value = row[column.id];

        if (column.id === "distance") {
          value = row.distanceNumber;
        }
        if (!column.hidden) {
          return (
            <TableCell
              key={column.label}
              style={{
                fontFamily: "Open Sans Hebrew, sans-serif",
                maxWidth: column.id === "eventName" ? 300 : "",
                filter:
                  isListings && column.id === "eventName" ? "blur(2px)" : "",
              }}
            >
              {column.id === "impact" ? (
                <Influence index={value as number} />
              ) : column.id === "eventName" ? (
                <HiddenShownText
                  value={value}
                  isBigScreen={isBigScreen}
                  isExtraSmallScreen={isExtraSmallScreen}
                />
              ) : column.id === "open" ? (
                <TableOpenIcon row={row} setOpen={setOpen} open={open} />
              ) : (
                value
              )}
            </TableCell>
          );
        }
      })}
    </TableRow>
  );
};
interface TableOpenIconProps {
  open: number | undefined;
  row: RowTable;
  setOpen: (value: number | undefined) => void;
}
function TableOpenIcon({ setOpen, row, open }: TableOpenIconProps) {
  const [isDarkTheme] = useContext(DarkThemeContext);
  return (
    <IconButton
      size="small"
      disabled={Object.keys(row.extra).length === 0}
      style={{ color: isDarkTheme ? darkText : "rgba(0, 0, 0, 0.54)" }}
      onClick={() => {
        if (open === row.index) {
          setOpen(undefined);
        } else {
          setOpen(row.index);
        }
      }}
    >
      {open === row.index ? (
        <KeyboardArrowUpIcon />
      ) : (
        <KeyboardArrowDownIcon
          style={{
            color:
              Object.keys(row.extra).length === 0 && isDarkTheme
                ? "#42455e"
                : Object.keys(row.extra).length === 0
                ? "#e3e3e3"
                : "",
          }}
        />
      )}
    </IconButton>
  );
}
export default TableRowsPart;
