import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useIsMobileScreen } from "./useIsMobileScreen";

export function useNotistack(msg, event, setEvent, variant) {
  const { enqueueSnackbar } = useSnackbar();
  const isMobileScreen = useIsMobileScreen();

  useEffect(() => {
    if (event) {
      enqueueSnackbar(msg, {
        variant: variant,
        anchorOrigin: {
          vertical: isMobileScreen ? "top" : "bottom",
          horizontal: isMobileScreen ? "center" : "right",
        },
      });
    }
    if (!setEvent) return;
    setTimeout(() => setEvent(false), 3500);
  }, [event, msg]);
}
