import { Grid, Menu, MenuItem } from "@material-ui/core";
import { blue_1, white } from "../../services/colors";
import { Link } from "react-router-dom";
import { FONT_SUBTITLE, FONT_TEXT } from "../../services/constants/fonts";
import { PRODUCTS } from "../../../homepage/services/constants/productSearch";

import { useTranslation } from "react-i18next";
import { useSearch } from "../../context/SearchContext";
import { makeStyles } from "@material-ui/styles";
import { INavbarLink } from "../../interfaces/topbar";
import { PropdoDropdown } from "../dropdownMenu/PropdoDropdown";
import { useDialogManager } from "../../hooks/useDialogManager";
import { PricingPlan } from "../subscription/pricingPlans/PricingPlan";
// @ts-ignore
import { HashLink } from "react-router-hash-link";
import React from "react";
import { scrollToTop } from "../../services/helpers/general";
import { ContactForm } from "../contactForm/ContactForm";

type Props = {
  navbarLinks: (t: any) => INavbarLink[];
  isTopbarActive?: boolean;
};

type Style = {
  hasFocus: Boolean;
};

const useStyles = makeStyles({});

export const TopbarLinks: React.FC<Props> = ({
  navbarLinks,
  isTopbarActive,
}) => {
  const { t } = useTranslation();
  const { openDialog, closeDialog } = useDialogManager();
  const { hasFocus, onFocus } = useSearch();

  const handlePricing = () => {
    openDialog(PricingPlan, { DialogProps: { maxWidth: "sm" } });
  };
  const handleContact = () => {
    openDialog(ContactForm, { DialogProps: { maxWidth: "xs" } });
  };

  const handleLink = (e: React.MouseEvent<HTMLElement>, item: INavbarLink) => {
    if (item.route === "search") {
      scrollToTop();
      onFocus(e);
    } else if (item.route === "pricing") handlePricing();
    else if (item.route === "contact") handleContact();
  };

  return (
    <Grid container item xs={8} justifyContent="center">
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          width: "60%",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        {navbarLinks(t).map((item: INavbarLink, idx: number) => {
          return item.route !== "products" ? (
            <li
              key={idx}
              style={{
                fontSize: "18px",
                margin: "0 10px",
                color: isTopbarActive ? blue_1 : white,
                cursor: "pointer",
              }}
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                handleLink(e, item)
              }
            >
              {item.title}
            </li>
          ) : (
            <li key={idx} style={{ margin: "0 10px" }}>
              <PropdoDropdown
                style={{
                  color: isTopbarActive ? blue_1 : white,
                  fontSize: "18px",
                }}
                btnText={t("navLinks.products")}
                items={PRODUCTS(t)}
                isRoute={true}
              />
            </li>
          );
        })}
      </ul>
    </Grid>
  );
};
