import React, { useContext, useEffect, useState } from "react";
import TableReport from "./TableRepost";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { PROPERTY_TYPE_LAND } from "../../dialogs/add-prop/SelectPropDetails";
import { useTranslation } from "react-i18next";

const headCells = (active: boolean, isDarkTheme: boolean, t: any) => {
  return [
    { id: "id", numeric: false, label: "", active: active, notSortable: true },
    { id: "open", numeric: false, label: "", notSortable: true },
    {
      id: "nickname",
      numeric: false,
      label: t("Reports.nickname"),
      hidden: !isDarkTheme,
      premium: true,
      notSortable: true,
    },
    {
      id: "address",
      numeric: false,
      label: t("Reports.address"),
      notSortable: true,
    },
    {
      id: "apartment",
      numeric: false,
      label: t("PropertyTypes.Apartment"),
      notSortable: true,
      active: active,
    },
    {
      id: "purchase_date",
      numeric: false,
      label: t("Reports.purchaseDate"),
      notSortable: true,
    },
    {
      id: "expiry_date",
      numeric: false,
      label: `${t("Reports.expirationDate")}`,
      hidden: isDarkTheme,
      notSortable: true,
    },
    {
      id: "type",
      numeric: false,
      label: t("Reports.propertyType"),
      notSortable: true,
    },

    {
      id: "num_rooms",
      numeric: true,
      label: t("Reports.rooms"),
      notSortable: true,
    },
    {
      id: "floor",
      numeric: true,
      label: t("Reports.floor"),
      notSortable: true,
    },
    {
      id: "sqm",
      numeric: true,
      label: t("Reports.size"),
      notSortable: true,
    },
    {
      id: "cv",
      numeric: true,
      label: t("Reports.cv"),
      hidden: !isDarkTheme,
      premium: true,
      notSortable: true,
    },
    {
      id: "price",
      numeric: true,
      label: t("Reports.price"),
      hidden: !isDarkTheme,
      premium: true,
      notSortable: true,
    },
    {
      id: "sold",
      numeric: false,
      label: t("Reports.propStatus"),
      hidden: !isDarkTheme,
      premium: true,
      notSortable: true,
    },
    {
      id: "share",
      numeric: false,
      label: t("Share.share"),
      hidden: !active,
      notSortable: true,
    },
    {
      id: "note",
      numeric: false,
      label: t("Reports.note"),
      hidden: !isDarkTheme,
      premium: true,
      notSortable: true,
    },

    { id: "index", numeric: false, label: "", hidden: true, notSortable: true },
  ];
};
export interface Row {
  open: string;
  nickname: string;
  address: string;
  apartment: string;
  purchase_date: string;
  expiry_date: string;
  type: string;
  num_rooms: number;
  floor: number;
  sqm: number;
  cv: number;
  price: number;
  sold: number;
  sold_price: number;
  share: string;
  note: string;
  id: number;
  index: number;
}

const DataReportTable: React.FC<{
  addresses: any;
  active: boolean;
  project?: string;
  building?: string;
}> = ({ addresses, active, project, building }) => {
  const [rows, setRows] = useState<[] | Row[]>([]);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (addresses) {
      setRows([]);
      addresses.properties?.forEach((item: any, index: number) => {
        setRows((prevState) => [
          ...prevState,
          {
            id: item.id,
            open: "",
            nickname: item.premium?.nickname,
            address: item.address,
            apartment: item.apartment ? item.apartment : "--",
            purchase_date: item["purchase_date"],
            expiry_date: item["expiry_date"],
            type: item.property_details[0],
            num_rooms:
              item.property_details[0] === PROPERTY_TYPE_LAND()
                ? "--"
                : item.property_details[6],
            floor:
              item.property_details[0] === PROPERTY_TYPE_LAND()
                ? "--"
                : item.property_details[4],
            sqm:
              item.property_details[0] === PROPERTY_TYPE_LAND()
                ? item.property_details[1]
                : item.property_details[2],
            cv: item.premium?.cv,
            price: item.premium?.asking_price,
            sold: item.premium?.sold,
            sold_price: item.premium?.sold_price,
            share: "",
            note: item.premium?.note,
            index: index,
          },
        ]);
      });
    }
  }, [addresses]);

  return (
    <TableReport
      rows={rows}
      columns={headCells(active, isDarkTheme, t)}
      addresses={addresses}
      active={active}
      project={project}
      building={building}
    />
  );
};
export default DataReportTable;
