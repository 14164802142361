import Grid from "@material-ui/core/Grid";
import { addingComma } from "../../services/helpers";
import React, { useContext } from "react";
import { useStylesForecast } from "../../styledComponent/ForecastStyled";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesFontMortgage } from "./MortgageCalculatorChart";
import TilesContainer from "../../components/TilesContainer";
import { TooltipInfoIcon } from "common-components";
import { useIsMobileScreen } from "common-components";
import { darkBoxes, darkText, lightBoxes } from "../../services/colors";
import Typography from "@material-ui/core/Typography";
import DemoExample from "../../components/DemoExample";
import { useIsIncludes } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

const MortgageResultRow = ({
  totalMortgage,
  firstMonthPayment,
  fundRefund,
  ratioPayment,
  maximumPayment,
}) => {
  const isSmallScreen = useIsMobileScreen();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStylesFontMortgage({ isDarkTheme });
  const classesForecast = useStylesForecast({ isDarkTheme });
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const { t } = useTranslation();

  return (
    <Grid
      container
      item
      xs={12}
      alignItems={"stretch"}
      style={{ paddingTop: 24 }}
      spacing={isExtraSmallScreen ? 1 : 0}
    >
      <Grid item xs={12} sm={4} lg={3}>
        <ResultComponent
          noBorder={isExtraSmallScreen}
          value={
            totalMortgage && !isNaN(totalMortgage) ? (
              `${addingComma(totalMortgage)} ₪`
            ) : (
              <bdi>₪ &mdash; &mdash; </bdi>
            )
          }
          classes={classes}
          classesForecast={classesForecast}
          id={`${t("Mortgage.ResultRow.totalRefunds")}`}
          tooltip={`${t("Mortgage.ResultRow.tooltipTotalRefunds")}`}
          borderRadius={"var(--br-right)"}
        />
      </Grid>

      <Grid item xs={12} sm={4} lg={2}>
        <ResultComponent
          noBorder={isExtraSmallScreen}
          value={
            firstMonthPayment ? (
              `${addingComma(firstMonthPayment)} ₪`
            ) : (
              <bdi>₪ &mdash; &mdash; </bdi>
            )
          }
          classes={classes}
          classesForecast={classesForecast}
          id={`${t("Mortgage.ResultRow.firstRepayment")}`}
          tooltip={`${t("Mortgage.ResultRow.tooltipFirstRepayment")}`}
          borderRadius={0}
        />
      </Grid>

      <Grid item xs={12} sm={4} lg={3}>
        <ResultComponent
          value={
            maximumPayment ? (
              `${addingComma(maximumPayment)} ₪`
            ) : (
              <bdi>₪ &mdash; &mdash; </bdi>
            )
          }
          classes={classes}
          classesForecast={classesForecast}
          id={`${t("Mortgage.ResultRow.maxRepayment")}`}
          tooltip={`${t("Mortgage.ResultRow.tooltipMaxRepayment")}`}
          noBorder={isSmallScreen}
          borderRadius={0}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={2}>
        <ResultComponent
          noBorder={isExtraSmallScreen}
          value={
            ratioPayment && !isNaN(ratioPayment) ? (
              ratioPayment
            ) : (
              <bdi> &mdash; &mdash; </bdi>
            )
          }
          classes={classes}
          classesForecast={classesForecast}
          id={`${t("Mortgage.ResultRow.repaymentRatio")}`}
          tooltip={`${t("Mortgage.ResultRow.tooltipRepaymentRatio")}`}
          borderRadius={0}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={2}>
        <ResultComponent
          value={
            fundRefund && !isNaN(fundRefund) ? (
              `${fundRefund}%`
            ) : (
              <bdi> &mdash; &mdash;% </bdi>
            )
          }
          classes={classes}
          classesForecast={classesForecast}
          id={`${t("Mortgage.ResultRow.rateEquity")}`}
          tooltip={`${t("Mortgage.ResultRow.tooltipRateEquity")}`}
          noBorder
          borderRadius={"var(--br-left)"}
        />
      </Grid>
    </Grid>
  );
};
export default MortgageResultRow;
export function ResultComponent({
  classesForecast,
  classes,
  id,
  value,
  tooltip,
  noBorder,
  borderRadius,
  paddingTitle,
}) {
  const isMobileScreen = useIsMobileScreen("xs");
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [direction] = useContext(DirectionContext);

  return (
    <TilesContainer
      className={classesForecast.paperCalcChartBoxes}
      padding={16}
      style={{
        backgroundColor: isDarkTheme ? darkBoxes : lightBoxes,
        color: isDarkTheme ? darkText : "",
        border: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: borderRadius,
      }}
    >
      <div
        style={{
          borderLeft:
            noBorder || !direction.isRtl
              ? "none"
              : "1px solid rgb(219, 219, 219)",
          borderRight:
            noBorder || direction.isRtl
              ? "none"
              : "1px solid rgb(219, 219, 219)",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          className={classesForecast.divTooltipBoxStyle}
          style={{
            justifyContent: isMobileScreen ? "flex-end" : "space-between",
            position: "relative",
            padding: paddingTitle ? paddingTitle : "",
          }}
        >
          <TooltipInfoIcon tooltipText={tooltip} isDarkTheme={isDarkTheme} />

          <Typography>{id}</Typography>
        </div>
        <Typography className={classes.resultHeader}>{value}</Typography>
      </div>
    </TilesContainer>
  );
}
