import React, { createContext, useContext, useEffect, useState } from "react";

import { AuthorizationContext as AuthorizationContextGlobal } from "common-components/src/global/context/AuthorizationContext";

export const AuthorizationContext = createContext<any>({});

export const AuthorizationContextProvider: React.FC = ({ children }) => {
  const [authorization, setAuthorization] = useState(false);

  const context = useContext(AuthorizationContextGlobal);
  const authorizationGlobal = context[0];

  useEffect(() => {
    setAuthorization(authorizationGlobal)
  }, [authorizationGlobal]);

  return (
    <AuthorizationContext.Provider value={[authorization, setAuthorization]}>
      {children}
    </AuthorizationContext.Provider>
  );
};
