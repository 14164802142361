import { CURRENCY_CODE } from "../constants";
import { PRICING } from "../constants/pricingPlan";

export function hexToRgbA(hex, opacity) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      `,${opacity})`
    );
  }
  console.log(hex, " HEX Error");
  return "";
}

export const addressFormat = (address) => {
  if (!address) return null;
  const formatAddress = address.split(",")[0];
  return formatAddress;
};

export function numFormatter(num, country, isRegular) {
  if (isRegular) return num;
  const countryFormat = country + "-" + country.toUpperCase();
  const currency = getCurrencyByCountry(country);
  const numberFormat = new Intl.NumberFormat(countryFormat, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  }).format(num);
  return numberFormat;
}

export const priceFormatter = (country, packageType) => {
  return PRICING[country][packageType];
};

export const getCurrencyByCountry = (country) => {
  const currency = CURRENCY_CODE[country].code;
  return currency;
};
