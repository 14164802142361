import React, { useContext } from "react";
import { hexToRgbA } from "../services/helpers";
import BarOptionsAllProp from "../charts/BarOptionsAllProp";
import { Grid } from "@material-ui/core";
import { CustomLegend } from "../charts/CustomLegend";
import {
  color2,
  darkGrey,
  darkMainPropColor,
  mainPropColor,
} from "../services/colors";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { ToggleSlider } from "common-components";
import { useIsMobileScreen } from "common-components";
import { useTranslation } from "react-i18next";
import TitleGrid from "../components/TitleGrid";
import { labelData } from "../components/dashboard-components/tama/TamaLineChart";

export interface BarIsrael {
  israel?: { series: SeriesBar[] };
  surrounding?: { series: SeriesBar[] };
  labels: string[];
}

export interface Series {
  data: number;
  name: string;
}
export interface SeriesBar {
  data: number[];
  name: string;
}
export interface XAxisString {
  labels: string[];
}
interface DataBarOptionsChartProps {
  data: BarIsrael;
  comparison: "israel" | "surrounding";
  setComparison: (value: string) => void;
}

function normaliseData(
  t: any,
  data: { series: SeriesBar[] },
  labels: string[],
  isDarkTheme: boolean
) {
  return {
    labels: labels.map((item) => t(`labels.${item}`)),
    datasets: data.series.map((item, index) => {
      return {
        label: item.name,
        backgroundColor:
          t(`labels.${item.name}`) === t("labels.property") && isDarkTheme
            ? darkMainPropColor
            : t(`labels.${item.name}`) === t("labels.property") && !isDarkTheme
            ? color2
            : isDarkTheme
            ? darkGrey
            : mainPropColor,
        borderWidth: 0,
        hoverBackgroundColor:
          item.name === `"הנכס שלך"` && isDarkTheme
            ? hexToRgbA(darkMainPropColor, 0.7)
            : item.name === `"הנכס שלך"` && !isDarkTheme
            ? hexToRgbA(color2, 0.7)
            : isDarkTheme
            ? hexToRgbA(darkGrey, 0.7)
            : hexToRgbA(mainPropColor, 0.7),
        data: item.data,
      };
    }),
  };
}

const DataBarOptionsChart: React.FC<DataBarOptionsChartProps> = ({
  data,
  comparison,
  setComparison,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const isExtraMobileScreen = useIsMobileScreen("xs");
  const { t } = useTranslation();
  const legend = labelData(
    t,
    data[comparison],
    isDarkTheme,
    [color2, mainPropColor],
    [darkMainPropColor, darkGrey],
    "series"
  );

  return (
    <Grid container>
      <TitleGrid
        title={t("DataBarOptionsChart.title")}
        tooltipText={t("DataBarOptionsChart.tooltip")}
      />

      <Grid
        container
        item
        xs={12}
        justify={"space-between"}
        alignItems={isExtraMobileScreen ? "flex-start" : "center"}
        direction={isExtraMobileScreen ? "column-reverse" : "row"}
      >
        <Grid item>
          <CustomLegend legend={legend} />
        </Grid>
        <Grid item>
          {/*@ts-ignore*/}
          <ToggleSlider
            radius
            value={comparison}
            setValue={setComparison}
            list={[
              { value: "israel", text: t("labels.israel") },
              {
                value:
                  data.surrounding?.series.length === 0
                    ? "disabled"
                    : "surrounding",
                text: t("labels.surrounding"),
              },
            ]}
            isDarkTheme={isDarkTheme}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <BarOptionsAllProp
          //@ts-ignore
          data={normaliseData(t, data[comparison], data.labels, isDarkTheme)}
        />
      </Grid>
    </Grid>
  );
};
export default DataBarOptionsChart;
