import {
  borderColor,
  darkElements,
  darkText,
  darkThemeGridLines,
  darkThemeNegativeRed,
  mainPropColor,
  negativeRedColor,
} from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";
import { XS_FONT } from "../services/fonts";

export const useStylesAddProp = makeStyles({
  inputGushHalka: (props) => ({
    backgroundColor: props.isDarkTheme ? darkElements : "#fff",
    height: "38px !important",
  }),
  inputFotSunTerraceNone: {
    width: 0,
    height: 0,
    visibility: "hidden",
    transition: "width 0.4s ease, height 0.4s ease",
  },
  errorsStyle: (props) => ({
    color: `${
      props.isDarkTheme ? darkThemeNegativeRed : negativeRedColor
    } !important`,
    maxWidth: "150px !important",
    fontSize: `${XS_FONT} !important`,
    fontWeight: "400 !important",
    textAlign: "left !important",
  }),
  textFiledAddPropWidth100: (props) => ({
    width: props.width,
    "& .MuiInputBase-root": {
      backgroundColor: props.isDarkTheme ? darkElements : "#fff",
      width: props.width,
      height: props.height,
    },
  }),
  selectAddProp: {
    "& .MuiSelect-selectMenu": {
      display: "flex",
      alignItems: "center",
    },
  },
  paragraphErrorAddProp: {
    color: darkThemeNegativeRed,
    maxWidth: 150,
    fontWeight: 400,
    textAlign: "left",
    fontSize: XS_FONT,
  },
  inputLabelSearch: (props) => ({
    paddingRight: "15px !important",
    paddingBottom: "5px !important",
    paddingTop: "0 !important",
    color: props.isDarkTheme ? `${darkText} !important` : "black !important",
  }),
  inputTextField: {
    width: "100%",
    "& input": {
      textAlign: "left",
    },
  },

  filledInput: { paddingLeft: 14 },

  divColumn: { display: "flex", alignItems: "center", flexDirection: "column" },
  divReportsFilterContainer: (props) => ({
    width: "100%",
    display: "flex",
    alignItems: props.isExtraSmallScreen ? "flex-end" : "center",
    justifyContent: "space-between",
    flexDirection: props.isExtraSmallScreen ? "column" : "row",
  }),
  checkedStyle: (props) => ({
    boxShadow: `inset 0 0 0px 1.5px ${
      props.isDarkTheme ? darkText : mainPropColor
    }`,
    backgroundColor: props.isDarkTheme ? darkThemeGridLines : borderColor,
    transition: "all .65s",
  }),
  notCheckedStyle: (props) => ({
    transition: "all .15s",
    "&:hover": {
      backgroundColor: props.isDarkTheme ? darkThemeGridLines : borderColor,
    },
  }),
});
