import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { blue_1, white } from "../../services/colors";
import { useIsLargeScreen } from "../../hooks/useIsLargeScreen";
import { useIsMobileScreen } from "../../hooks/useIsMobileScreen";
import { FONT_TEXT, FONT_TITLE } from "../../services/constants/fonts";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: props.color === blue_1 ? white : blue_1,
    backgroundColor: props.color,
    width: props.isMobileScreen ? 30 : 40,
    height: props.isMobileScreen ? 30 : 40,
    marginRight: 5,
    justifyContent: "center",
    alignItems: "center",
    fontSize: props.isMobileScreen ? FONT_TEXT : FONT_TITLE,
  }),
}));

export default function LetterAvatars({ letters, color }) {
  const isMobileScreen = useIsMobileScreen();
  const classes = useStyles({ color, isMobileScreen });

  return <Avatar className={classes.root}>{letters}</Avatar>;
}
