import { makeStyles } from "@material-ui/core";

export const useLegelDocumentsStyles = makeStyles({
  closeButton: { 
    borderRadius: "8px", 
    padding: "2px", 
  },
  headerTitle: { 
    position: "fixed", 
    paddingRight: "12px", 
    zIndex: 2, 
  },
  modalTitle: {
    textAlign: "center",
    fontFamily: "Propdo Bold",
    fontSize: "1.625rem",
  },
  modalSubtitle: {
    textAlign: "center",
    fontFamily: "Propdo Bold",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    marginTop: "0.25rem",
    color: "rgb(255, 175, 11)",
  },
  modalText: {
    textAlign: "center",
    fontFamily: "Propdo Bold",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    marginTop: "0.25rem",
    color: "rgb(125, 133, 155)",
  },
  listItem: {
    marginBottom: "0.5rem",
    fontSize: "12px",
    lineHeight: 1.5,
    "& strong + strong": {
      fontFamily: "Propdo Bold",
    },
  },
  orderKey: {
    fontFamily: "Propdo Bold",
  },
  link: {
    color: "#69b1ff",
    textDecoration: "underline",
  },
  title: {
    fontFamily: "Propdo Bold",
    fontSize: "14px",
    lineHeight: "1.25rem",
    marginBottom: "0.5rem",
    marginTop: "1.5rem",
  },
  termsTitle: {
    fontFamily: "Propdo Bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    marginBottom: "0.5rem",
    marginTop: "1.5rem",
  },
  text: {
    paddingLeft: "0.5rem",
    fontSize: "14px",
  },
  nameAndUrllink: {
    textDecoration: "underline",
    color: "#69b1ff",
  },
  contactLink: {
    color: "#000000",
    transition: "color 0.3s ease-in-out",
    "&:hover": {
      color: "#69b1ff",
      textDecoration: "none",
    },
  },
  contactItem: {
    "& p": {
      fontSize: "10px",
      fontFamily: "Propdo Bold",
      margin: 0,
      lineHeight: 1.5,
    },
  },
});
