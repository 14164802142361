import React, { useContext } from "react";
import TableCell from "@material-ui/core/TableCell";
import { Collapse } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import { useStylesTable } from "../../charts/table/TableChart";
import { useStylesReport } from "../../styledComponent/ReportItemStyled";
import Grid from "@material-ui/core/Grid";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { darkThemeSliderRail } from "../../services/colors";
import { propDetailsList } from "../../services/consts";
import Typography from "@material-ui/core/Typography";
import { IMAGES_LINK } from "common-components";
import { useTranslation } from "react-i18next";

const TableReportsDetails = ({ open, row, address }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStylesTable();
  const { t } = useTranslation();

  return (
    <TableRow style={{ width: "100%" }}>
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
          width: "100%",
          backgroundColor: isDarkTheme ? darkThemeSliderRail : "whitesmoke",
        }}
        colSpan={isDarkTheme ? 18 : 12}
      >
        <Collapse in={open.includes(row.id)} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Grid container>
              {address &&
                propDetailsList(
                  address.property_details,
                  isDarkTheme,
                  classes.svg,
                  t
                ).map((item, index) => {
                  return (
                    <ReportsDetails
                      key={index}
                      label={item.label}
                      item={item.item}
                      Icon={item.Icon}
                      text={item.text}
                    />
                  );
                })}
            </Grid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
export function ReportsDetails({ item, label, Icon, text }) {
  const classesRep = useStylesReport();
  const { t } = useTranslation();
  if (
    label !== "סוג" &&
    label !== "סוג הנכס" &&
    label !== "קומה" &&
    label !== "שטח (מ''ר)" &&
    label !== "מספר חדרים"
  ) {
    return (
      <Grid
        container
        alignItems={"center"}
        justify={"flex-start"}
        item
        xs={2}
        className={classesRep.gridContainerDetails}
      >
        <div className={classesRep.divContainerDetail}>
          {Icon}
          <Typography className={classesRep.paragraphDetails}>
            {label} :
          </Typography>
        </div>
        <Typography className={classesRep.paragraphDetails}>
          {!item || item === 0 || item === ""
            ? `${t("Const.without")}`
            : text
            ? text
            : item}
        </Typography>
      </Grid>
    );
  } else {
    return null;
  }
}
export function PropDetailImg({ classes, isDarkTheme, name }) {
  return (
    <img
      src={
        isDarkTheme
          ? `${IMAGES_LINK}/dashboard/propDetails/darkTheme/${name}.svg`
          : `${IMAGES_LINK}/dashboard/propDetails/lightTheme/${name}.svg`
      }
      alt={name}
      className={classes}
    />
  );
}
export default TableReportsDetails;
