import { CustomTooltip } from "../CustomTooltip";
import React, { useContext } from "react";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import {
  darkBackground,
  darkText,
  darkThemeSliderRail,
  greyAxis,
} from "../../services/colors";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";

const useStyles = makeStyles({
  container: {
    borderRadius: "var(--border-radius)",
  },
  header: (props) => ({
    backgroundColor: props.isDarkTheme ? darkThemeSliderRail : "#f1f4fd",
    width: "100%",
    borderRadius: "var(--br-top)",
    padding: 8,
  }),
  content: (props) => ({
    display: props.tooltipDate ? "flex" : "",
    flexDirection: props.tooltipDate ? "column-reverse" : "",
    backgroundColor: props.isDarkTheme ? darkBackground : "white",
    width: "100%",
    borderRadius: "var(--br-bottom)",
    padding: 8,
  }),
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const ChartTooltip = ({
  tooltipRef,
  position,
  id,
  data,
  index,
  title,
  labels,
  dataToMap,
  sign,
  signPosition,
  tooltipDate,
  tooltipPercent,
  tooltipEvent,
  total,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);

  return (
    <CustomTooltip
      tooltipRef={tooltipRef}
      left={position.left}
      top={position.top}
      id={id}
      place="top"
    >
      <TooltipInner
        data={data}
        currentIndex={index}
        isDarkTheme={isDarkTheme}
        title={title}
        labels={labels}
        dataToMap={dataToMap}
        sign={sign}
        tooltipDate={tooltipDate}
        signPosition={signPosition}
        tooltipPercent={tooltipPercent}
        tooltipEvent={tooltipEvent}
        total={total}
      />
    </CustomTooltip>
  );
};
export default ChartTooltip;

const TooltipInner = ({
  data,
  currentIndex,
  isDarkTheme,
  title,
  labels,
  dataToMap,
  sign,
  signPosition,
  tooltipDate,
  tooltipPercent,
  tooltipEvent,
  total,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ isDarkTheme, tooltipDate });
  const [direction] = useContext(DirectionContext);
  if (tooltipEvent && data && currentIndex !== undefined) {
    const currentValue = data.datasets[0].data[currentIndex];
    const percentage =
      parseFloat(((currentValue / total) * 100).toFixed(0)).toString() + "%";

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography style={{ color: isDarkTheme ? darkText : "black" }}>
            {title.title === "label"
              ? data.labels[currentIndex]
              : `${t("DonutChart.effect")} ${data.labels[currentIndex]}`}
          </Typography>
        </div>
        <div className={classes.content}>
          {tooltipPercent.map((item, index) => {
            return (
              <div
                key={item}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{ color: isDarkTheme ? darkText : greyAxis }}
                >
                  {item}
                </Typography>

                <Typography
                  style={{
                    color: data.datasets[0].backgroundColor[currentIndex],
                  }}
                >
                  {index === 0 ? percentage : currentValue}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (data && currentIndex !== undefined) {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography
            style={{
              color: isDarkTheme ? darkText : "black",
              direction: "lrt",
            }}
          >
            {title.title}{" "}
            {title.year
              ? data.labels[currentIndex]
              : tooltipDate
              ? tooltipDate[currentIndex]
              : ""}
          </Typography>
        </div>
        {tooltipPercent ? (
          <div className={classes.content}>
            {tooltipPercent.map((item, index) => {
              return (
                <div
                  key={item}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{ color: isDarkTheme ? darkText : greyAxis }}
                  >
                    {item}
                  </Typography>

                  <Typography
                    style={{
                      color: data.datasets[0].backgroundColor[currentIndex],
                    }}
                  >
                    {index === 0
                      ? data.datasets[0].data[currentIndex] + "%"
                      : data.labels[currentIndex]}
                  </Typography>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.content}>
            {dataToMap.map((item, index) => {
              if (item.data.length > 0) {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography style={{ color: item.backgroundColor }}>
                      {labels ? labels[index] : t(item.label)}
                    </Typography>

                    <Typography
                      style={{
                        color: item.backgroundColor,
                        direction: !direction.isRtl ? "ltr" : "rtl",
                      }}
                    >
                      {item.data[currentIndex]}
                      {!direction.isRtl ? "" : " "}
                      {sign}
                    </Typography>
                  </div>
                );
              } else return null;
            })}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};
