export const white = "#FFF";

export const blue_1 = "#001145";

export const blue_2 = "#0D4C7D";

export const opacityBlue = "#001145b3";

export const opacityOrange = "rgba(255, 193, 94, 0.25)";

export const grey_1 = "#F2F3F4";

export const grey_2 = "#B5B5B5";

export const grey_3 = "#A7A7A7";

export const grey_4 = "#E7E7E7";

export const grey_5 = "#676767";

export const orange_1 = "#FEAD04";

export const orange_2 = "#FFC15E";

export const orange_3 = "#FFC15E";

export const orange_hover = "#FFF8EB";

export const orange_hover_1 = "#FFE1A2";

export const borderRadius = 8;

export const borderRadius_30 = 30;

export const borderRadius_50 = 50;

export const negative = "#a91e2c";

export const positive = "#18634b";

export const facebookBtn = "#0D4C7D";

export const googleBtn = "#FF5B5B";

export const googleBtn_hover = "#CF1D1D";

export const blue_3 = "#99A5C9";
