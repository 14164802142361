import React, { FC } from "react";
import { Grid, Typography, Radio } from "@material-ui/core";
import { packagePlan } from "../../../services/constants/pricingPlan";
import { useTranslation } from "react-i18next";
import {
  blue_1,
  borderRadius,
  grey_2,
  orange_1,
} from "../../../services/colors";
import {
  FONT_TEXT,
  FONT_TEXT_SECONDARY,
} from "../../../../comps/services/constants/fonts";
import { useIsLargeScreen } from "../../../hooks/useIsLargeScreen";
import { usePayment } from "../../../context/PaymentContext";

export const PackagePlan = () => {
  const { t } = useTranslation();
  const { setPlanByName, currPlan } = usePayment();
  const isDesktopScreen = useIsLargeScreen("md");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = (event.target as HTMLInputElement).value;

    if (currPlan?.name === name) {
      setPlanByName(null);
    } else setPlanByName(name);
  };

  return (
    <Grid
      container
      style={{
        border: `1px solid ${grey_2}`,
        padding: 6,
        marginTop: 15,
        borderRadius: borderRadius,
      }}
    >
      <Grid item>
        <Typography style={{ fontWeight: 600 }}>
          {t("pricing.package")}
        </Typography>
      </Grid>
      <Grid container>
        {packagePlan(t).map((item, idx) => {
          return (
            <Grid container alignItems="center">
              <Radio
                style={{
                  color: orange_1,
                }}
                checked={currPlan?.name === item.name}
                onChange={handleChange}
                value={item.name}
                inputProps={{ "aria-label": "monthly" }}
              />
              <Typography
                style={{
                  marginLeft: 5,
                  fontSize: !isDesktopScreen && FONT_TEXT,
                }}
              >
                {item.price}
                {t("global.currency")}
              </Typography>
              <Typography style={{ fontSize: !isDesktopScreen && FONT_TEXT }}>
                {item.perProperty} /
              </Typography>
              <Typography
                style={{
                  margin: "0 5px",
                  fontSize: !isDesktopScreen && FONT_TEXT,
                }}
              >
                {item.numApartments !== 0 && item.numApartments}
              </Typography>
              <Typography style={{ fontSize: !isDesktopScreen && FONT_TEXT }}>
                {item.title}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
