import Grid from "@material-ui/core/Grid";
import { Controller, useForm } from "react-hook-form";
import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useLazyDataApi } from "common-components";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { OpenAccessContext } from "../../../context/OpenAccessContext";
import { useStylesAddProp } from "../../../styledComponent/AddPropStyled";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { useIsIncludes } from "common-components";
import Typography from "@material-ui/core/Typography";
import SubmitAndClearButtons from "../../SubmitAndClearButtons";
import { useNotistack } from "common-components";
import { FormCheckbox } from "../../../dialogs/add-prop/CheckboxPropDetails";
import { useTranslation } from "react-i18next";
import TitleGrid from "../../TitleGrid";
import { DirectionContext } from "../../../context/DirectionContext";

function callFetch(api, lazyFetch, isOpenAccess, propertyId, values) {
  lazyFetch(
    `/reports/dashboard/page/building-rights/update?id=${
      isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
    }`,
    {
      method: "POST",
      body: JSON.stringify({
        building_rights_sqm: values ? Number(values.sqm) : 0,
        building_rights_level: values ? values.level : "standard",
        building_rights_approval: values ? Number(values.approval) : 0,
      }),
    }
  );
}

const BuildingRights = ({
  data,
  setRawData,
  rawData,
  setToggleBRCall,
  setSummaryPrice,
}) => {
  const [direction] = useContext(DirectionContext);
  const [errorSelect, setErrorSelect] = useState(false);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [propertyId] = useContext(PropertyIdContext);
  const [{ data: dataFetch, error, isLoading }, lazyFetch] = useLazyDataApi();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      level: data.level || "no",
      sqm: data.sqm,
      approval: data.approval,
    },
  });

  let level = watch("level");
  let approval = watch("approval");
  const isListings = useIsIncludes("listings");
  const props = { isDarkTheme };
  const classes = useStylesAddProp(props);

  useNotistack(`${t("updateSucceedMsg")}`, dataFetch, undefined, "success");
  useNotistack(`${t("updateFailedMsg")}`, error, undefined, "error");
  useNotistack(
    t("BuildingRights.constructionDate"),
    errorSelect,
    setErrorSelect,
    "error"
  );

  const onSubmit = (values) => {
    if (values.level === "no") {
      setErrorSelect(true);
    } else {
      callFetch(direction.api, lazyFetch, isOpenAccess, propertyId, values);
    }
  };
  useEffect(() => {
    if (dataFetch) {
      const newData = { ...rawData };
      for (const [key, value] of Object.entries(rawData)) {
        if (dataFetch[key]) {
          newData[key] = dataFetch[key];
        }
      }
      setRawData(newData);
      setToggleBRCall((prev) => !prev);
      setSummaryPrice((prevState) => ({
        ...prevState,
        summary: dataFetch.summary,
      }));
    }
  }, [dataFetch]);

  const classesLab = useStylesAddProp({ isDarkTheme });

  const onClearBtn = () => {
    callFetch(direction.api, lazyFetch, isOpenAccess, propertyId);
    reset({
      level: "no",
      sqm: 0,
      approval: 0,
    });
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: "100%" }}
    >
      <Grid
        container
        item
        xs={12}
        spacing={2}
        alignItems={"stretch"}
        style={{ height: "100%" }}
      >
        <TitleGrid title={t("BuildingRights.formTitle")} />

        <Grid item sm={12}>
          <InputLabel
            style={{ whiteSpace: "nowrap" }}
            className={classesLab.inputLabelSearch}
          >
            {t("BuildingRights.size")}
          </InputLabel>
          <TextField
            id={"sqm"}
            name={"sqm"}
            autoComplete="off"
            error={errors["sqm"] !== undefined}
            placeholder={t("BuildingRights.sizePlaceholder")}
            variant="outlined"
            disabled={isLoading || isListings}
            size="small"
            style={{ width: "100%" }}
            onFocus={(event) => {
              event.target.select();
            }}
            {...register("sqm", {
              max: {
                value: 1000,
                message: `${t("BuildingRights.sizeError")}`,
              },
            })}
            inputProps={{
              inputMode: "numeric",
            }}
          />
          {errors["sqm"] !== undefined && (
            <Typography className={classes.errorsStyle}>
              {errors["sqm"].message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            style={{ whiteSpace: "nowrap" }}
            className={classesLab.inputLabelSearch}
          >
            *{t("BuildingRights.dropdown.title")}
          </InputLabel>
          <Controller
            control={control}
            name="level"
            value={level.length > 0 ? level : "no"}
            render={({ field: { onChange, value } }) => (
              <Select
                id="level"
                name="level"
                labelId="level"
                variant="outlined"
                disabled={isLoading || isListings}
                value={level.length > 0 ? level : "no"}
                style={{
                  width: "100%",
                }}
                onChange={onChange}
              >
                <MenuItem value={"no"} disabled>
                  &mdash;&mdash;
                </MenuItem>
                <MenuItem value={"standard"}>
                  {t("BuildingRights.dropdown.standard")}
                </MenuItem>
                <MenuItem value={"above"}>
                  {t("BuildingRights.dropdown.above")}
                </MenuItem>
                <MenuItem value={"luxury"}>
                  {t("BuildingRights.dropdown.luxury")}
                </MenuItem>
              </Select>
            )}
          />
        </Grid>
        <Grid container item xs={12} alignItems={"flex-end"}>
          <FormCheckbox
            name="approval"
            label={t("BuildingRights.approved")}
            control={control}
            onClick={() => {
              setValue("approval", !approval);
            }}
            checked={approval}
            disabled={isLoading || isListings}
          />
        </Grid>
        <Grid container item xs={12} style={{ alignSelf: "flex-end" }}>
          <SubmitAndClearButtons
            disabled={isLoading || isListings}
            isLoading={isLoading}
            onClearBtn={onClearBtn}
            submitTitle={t("weightRights")}
            onClickBtn={handleSubmit(onSubmit)}
          />
        </Grid>
      </Grid>
    </form>
  );
};
export default BuildingRights;
