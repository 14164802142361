import { useContext } from "react";
import { Paper } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DarkThemeContext } from "../context/DarkThemeContext";
import {
  borderColor,
  darkBorderColor,
  darkThemeSliderRail,
  topBorder,
} from "../services/colors";

interface Props {
  topBorder?: boolean;
  padding?: number | string;
  isDarkTheme?: boolean;
}
const useStyles = makeStyles({
  styleTopBorder: (props: Props) => ({
    padding: props.padding ? props.padding : "32px 32px 16px",
    height: "100%",
    borderWidth: props.topBorder ? "6px 1px 1px" : 1,
    borderStyle: "solid",
    borderColor: props.topBorder
      ? props.isDarkTheme
        ? `${darkThemeSliderRail} ${darkBorderColor} ${darkBorderColor}`
        : `${topBorder} ${borderColor} ${borderColor}`
      : props.isDarkTheme
      ? darkBorderColor
      : borderColor,
    borderImage: "initial",
    boxSizing: "border-box",
  }),
});
interface TilesContainerProps {
  topBorder?: boolean;
  padding?: number | string;
  elevation?: number;
  style?: any;
  relative?: boolean;
}
const TilesContainer: React.FC<TilesContainerProps> = ({
  children,
  topBorder,
  padding,
  elevation = 0,
  style,
  relative,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStyles({ topBorder, padding, isDarkTheme });
  return (
    <Paper
      elevation={elevation}
      className={classes.styleTopBorder}
      style={relative ? { ...style, position: "relative" } : style}
    >
      {children}
    </Paper>
  );
};
export default TilesContainer;
