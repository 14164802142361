import { useStylesForecast } from "../../styledComponent/ForecastStyled";
import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { useStylesUserLogin } from "../../styledComponent/UserLoginStyled";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { handleBuyListings } from "../../components/listings/Property/Tiles/BackTilePreview";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import Typography from "@material-ui/core/Typography";
import { IMAGES_LINK } from "common-components";

const MessagePurchaseProperty = () => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { openDialog, closeDialog } = useDialogManager();
  const classes2 = useStylesUserLogin({ isDarkTheme });
  const classesForecast = useStylesForecast({ isDarkTheme });

  return (
    <div
      className={classes2.divRootContainer}
      style={{ maxWidth: 400, padding: "20px 35px" }}
    >
      <div className={classesForecast.divPopupContainerIcon}>
        <img
          src={`${IMAGES_LINK}/dashboard/listings/purchase.png`}
          alt="added"
          style={{ width: 55, height: 55 }}
        />
      </div>
      <div>
        <Typography
          variant={"h5"}
          align={"center"}
          style={{ margin: "30px 0 5px 0" }}
        >
          מעוניין לקבל בלעדיות על פרטי הנכס?
        </Typography>
        <Typography>
          עם חשיפת הדו"ח תקבל את כתובת הנכס ופרטי המפרסם של המודעה לצורך יצירת
          קשר. כמו כן, תקבל בלעדיות לפרטי הנכס למשך 7 ימים כך שכל משתמש אחר לא
          יוכל לחשוף את פרטי הנכס בזמן זה. בתום 7 הימים תוכל לחדש את הבלעדיות
          ולמנוע את החזרת פרטי הנכס למאגר הדירות המבטיחות המפורסם למשתמשים
          במערכת.
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: 15,
            }}
            onClick={() => handleBuyListings(openDialog)}
          >
            לחשיפת הכתובת
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{
              marginTop: 15,
            }}
            onClick={() => closeDialog()}
          >
            סגור
          </Button>
        </div>
      </div>
    </div>
  );
};
export default MessagePurchaseProperty;
