/**

 * Custom Hook to get access to DialogManager actions like openDialog and closeDialog.

 */

import { useContext } from "react";
import { DialogManagerContext } from "../providers/DialogManager";

export function useDialogManager() {
  return useContext(DialogManagerContext);
}
