import { Grid } from "@material-ui/core";
import DemoExample from "../../DemoExample";
import DataBarOptionsChart from "../../../data/DataBarOptionsChart";
import { LoadingCharts } from "../../layout/Layout";
import React, { useContext, useEffect, useState } from "react";
import { useIsIncludes } from "common-components";
import TilesContainer from "../../TilesContainer";
import { OpenAccessContext } from "../../../context/OpenAccessContext";
import { PropertyIdContext } from "../../../context/PropertyIdContext";
import { useLazyDataApi } from "common-components";
import { useIsMobileScreen } from "common-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { color3, darkBlue, darkPurple } from "../../../services/colors";
import ComparativeGraph from "./ComparativeGraph";
import ComparisonsPie from "./ComparisonsPie";
import ComparativeLine from "./ComparativeLine";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { XS_PLUS_FONT } from "../../../services/fonts";
import { useNotistack } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";
import ComparativePotential from "./ComparativePotential";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  ribbon: (props) => ({
    position: "relative",
    "&:after": {
      content: '"בהרצה"',
      position: "absolute",
      transform: "rotate(35deg) translate3d(0,0,0)",
      color: "white",
      fontSize: XS_PLUS_FONT,
      height: 15,
      width: 105,
      backgroundColor: props.isDarkTheme ? darkBlue : color3,
      right: "-35px",
      top: 5,
      paddingBottom: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
});

const Comparative = ({ SPACING_FACTOR }) => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const isDemo = useIsIncludes("demo");
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [propertyId] = useContext(PropertyIdContext);
  const [{ data, error }, lazyFetch] = useLazyDataApi();
  const [rawData, setRawData] = useState(undefined);
  const [comparison, setComparison] = useState("israel");
  const isSmallScreen = useIsMobileScreen();
  const [indexYourProp, setIndexYourProp] = useState(undefined);
  const { t } = useTranslation();

  useNotistack(`${t("retrievalFailedMsg")}`, error, undefined, "error");

  useEffect(() => {
    if (typeof propertyId === "object") {
      lazyFetch(
        `/reports/dashboard/page/comparisons?id=${
          isOpenAccess.page === "demo" ? isOpenAccess.page : propertyId.id
        }&lang=${direction.isRtl ? "he" : "en"}`
      );
    }
  }, [propertyId]);

  useEffect(() => {
    if (data) {
      setRawData(data);
    }
  }, [data]);

  useEffect(() => {
    if (rawData) {
      data.comparisons_future_growth[comparison].series[1].data.map(
        (item, index) => {
          if (item !== 0) return setIndexYourProp(index);
        }
      );
    }
  }, [rawData, comparison]);

  return (
    <Grid
      name="comparative"
      container
      spacing={SPACING_FACTOR}
      style={{
        paddingTop: 24,
        backgroundColor: "#e1edf7",
        margin: "0",
        width: "revert",
      }}
    >
      <Helmet>
        <title>{t("MetaData.comparisionsTitle")}</title>
        <description>{t("MetaData.comparisionsDescription")}</description>
      </Helmet>
      <Grid item xs={12}>
        <TilesContainer topBorder relative>
          {isDemo && <DemoExample />}
          {rawData ? (
            <DataBarOptionsChart
              data={rawData.comparisons_future_growth}
              comparison={comparison}
              setComparison={setComparison}
            />
          ) : (
            <LoadingCharts
              variant={"h5"}
              name={t("DataBarOptionsChart.title")}
            />
          )}
        </TilesContainer>
      </Grid>

      <Grid item xs={12}>
        <ComparativePotential
          indexYourProp={indexYourProp}
          comparison={comparison}
          data={rawData?.comparisons_future_growth}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TilesContainer topBorder relative>
          {isDemo && <DemoExample />}
          {rawData ? (
            <ComparisonsPie data={rawData.comparisons_num_rooms} />
          ) : (
            <LoadingCharts variant={"h5"} name={t("ComparisonsPie.title")} />
          )}
        </TilesContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <TilesContainer topBorder relative>
          {isDemo && <DemoExample />}
          {rawData ? (
            <ComparativeLine data={rawData.comparisons_sqm} />
          ) : (
            <LoadingCharts variant={"h5"} name={t("ComparativeLine.title")} />
          )}
        </TilesContainer>
      </Grid>

      <Grid item xs={12} style={{ paddingBottom: isSmallScreen ? 24 : "" }}>
        <TilesContainer topBorder relative>
          {isDemo && <DemoExample />}
          {rawData ? (
            <ComparativeGraph data={rawData.comparisons_year_built} />
          ) : (
            <LoadingCharts variant={"h5"} name={t("ComparativeGraph.title")} />
          )}
        </TilesContainer>
      </Grid>
    </Grid>
  );
};
export default Comparative;
