import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { IMAGES_LINK } from "../../../api/api_links";
import { useTranslation } from "react-i18next";
import { useDialogManager } from "../../../hooks/useDialogManager";
import { useIsBaseRoute } from "../../../hooks/useIsBaseRoute";

export const PurchaseResult: React.FC<{ msg: any; success: Boolean }> = ({
  msg,
  success,
}) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialogManager();
  const isComps = useIsBaseRoute("comps");

  const handleClose = () => {
    closeDialog();
    window.location.reload();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <img
          src={IMAGES_LINK + "/map/exit.svg"}
          alt={"exit"}
          onClick={handleClose}
          style={{ cursor: "pointer" }}
        />
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <img
          src={IMAGES_LINK + `/map/${success ? "success" : "error"}.svg`}
          alt={"response"}
        />
        <Grid item xs={12}>
          <Typography
            style={{ textAlign: "center", marginTop: 15, fontWeight: 600 }}
          >
            {success
              ? t("pricing.successfulPayment")
              : t("pricing.purchaseFailed")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
