import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import {
  color4,
  darkBackground,
  darkBlue,
  darkText,
  darkThemeSliderRail,
  greyAxis,
} from "../../services/colors";
import { hexToRgbA } from "../../services/helpers";
import { date } from "../../dialogs/payment/Payment";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { ANIMATION_DURATION } from "../../services/consts";
import Typography from "@material-ui/core/Typography";
import { FONT } from "../../services/fonts";
import { CustomTooltip, useCustomTooltip } from "../CustomTooltip";
import { useIsMobileScreen } from "common-components";
import { disableScrolling, enableScrolling } from "./MortgageLineChart";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { datasetKeyProvider } from "../../components/dashboard-components/tama/LineChart";
import TitleGrid from "../../components/TitleGrid";
import { DirectionContext } from "../../context/DirectionContext";

export function percentageFormatFactory(value) {
  return value.toFixed(1) + "%";
}

export function fakeData(label, isDarkTheme) {
  return {
    labels: [label],
    datasets: [
      {
        data: [100],
        backgroundColor: isDarkTheme ? [darkThemeSliderRail] : ["#e5e5e5"],
        hoverBackgroundColor: isDarkTheme ? [darkThemeSliderRail] : ["#e5e5e5"],
        borderWidth: 0,
      },
    ],
  };
}

const BarLeverageRate = ({ lineChartData, rawData }) => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [data, setData] = useState({});
  const chartRef = useRef();
  const [index, setCurrentIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );

  function colorFactory(context) {
    if (context.dataset.data.length > 1) {
      const index = context.dataIndex;
      const value = context.dataset.data[index];
      const maxValue = Math.max(...context.dataset.data);
      const limitValue = maxValue / context.dataset.data.length;

      return value <= limitValue
        ? "transparent"
        : isDarkTheme
        ? darkText
        : "#fff";
    } else {
      return isDarkTheme ? darkText : "#fff";
    }
  }

  function aligmentFactory(context) {
    if (context.dataset.data.length > 1) {
      const index = context.dataIndex;
      const value = context.dataset.data[index];
      const maxValue = Math.max(...context.dataset.data);
      const limitValue = maxValue / context.dataset.data.length;

      return value <= limitValue ? "end" : "center";
    } else {
      return "center";
    }
  }

  useEffect(() => {
    if (lineChartData) {
      if (lineChartData.datasets.length === 0) {
        setData(fakeData("", isDarkTheme));
      } else if (lineChartData.datasets[0].data && rawData) {
        setData(
          createData(
            lineChartData.datasets[0].data,
            rawData,
            lineChartData.labels,
            isDarkTheme
          )
        );
      }
    }
  }, [lineChartData, rawData]);

  const isMobileScreen = useIsMobileScreen();
  const { t } = useTranslation();
  const chartJsOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      animation: {
        duration: ANIMATION_DURATION,
      },
      plugins: {
        datalabels: {
          color: colorFactory,
          formatter: percentageFormatFactory,
          font: {
            size: isMobileScreen ? 12 : FONT,
            family: "Open Sans Hebrew, sans-serif",
          },
          anchor: aligmentFactory,
          textAlign: aligmentFactory,
          clamp: true,
        },

        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
        rtl: true,
        custom: customTooltip,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
            },
            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: FONT,
            },
          },
        ],
      },
    };
  }, [isMobileScreen]);

  return (
    <Grid container>
      <TitleGrid
        title={t("Mortgage.BarLeverage.leverageRate")}
        tooltipText={t("Mortgage.BarLeverage.toolTip")}
      />
      <Grid item xs={12}>
        <div
          style={{
            minHeight: 250,
          }}
          onTouchStart={() => {
            disableScrolling();
          }}
          onTouchEnd={() => {
            enableScrolling();
          }}
        >
          <HorizontalBar
            id="myGraph"
            ref={chartRef}
            data={data}
            datasetKeyProvider={datasetKeyProvider}
            options={chartJsOptions}
          />
          {data && data.labels && data.labels[0] !== "" && (
            <CustomTooltip
              chartRef={chartRef}
              tooltipRef={tooltipRef}
              left={position.left}
              top={position.top}
              id="horizontal-bar-mortgage"
              place="top"
            >
              <TooltipInner
                data={data}
                currentIndex={index}
                isDarkTheme={isDarkTheme}
              />
            </CustomTooltip>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
function TooltipInner({ data, currentIndex, isDarkTheme }) {
  const { t } = useTranslation();
  if (data && currentIndex !== undefined) {
    return (
      <div style={{ borderRadius: "var(--border-radius)" }}>
        <div
          style={{
            backgroundColor: isDarkTheme ? darkThemeSliderRail : "#f1f4fd",
            width: "100%",
            maxWidth: 200,
            borderRadius: "var(--br-top)",
            padding: 8,
          }}
        >
          <Typography style={{ color: isDarkTheme ? darkText : "black" }}>
            {t("Mortgage.BarLeverage.leverageRate")}
          </Typography>
        </div>
        <div
          style={{
            backgroundColor: isDarkTheme ? darkBackground : "white",
            width: "100%",
            borderRadius: "var(--br-bottom)",
            padding: 8,
          }}
        >
          {data.datasets[0].data.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{ color: isDarkTheme ? darkText : greyAxis }}
                >
                  {t("Mortgage.LineGraph.year")} {data.labels[index]}
                </Typography>

                <Typography
                  style={{
                    color: isDarkTheme ? darkText : greyAxis,
                  }}
                >
                  {item.toFixed(1)}%
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else return null;
}

function dataSeries(arrBalance, arrRealistic, years) {
  const res = [];
  if (arrBalance && arrRealistic) {
    years.forEach((item) => {
      const year = Number(item - date.getFullYear());
      res.push((arrBalance[year] / arrRealistic[year]) * 100);
    });
  }

  return res;
}
function createData(arrBalance, arrRealistic, years, isDarkTheme) {
  const currentYear = date.getFullYear();
  const arrYears = years.filter(
    (year) =>
      (Number(year) - currentYear) % 5 === 0 &&
      Number(year) - currentYear !== 0 &&
      Number(year) - currentYear !== 30
  );
  arrYears.reverse();
  return {
    labels: arrYears,
    datasets: [
      {
        backgroundColor: isDarkTheme ? darkBlue : color4,
        borderColor: isDarkTheme ? darkBlue : color4,
        borderWidth: 0,
        hoverBackgroundColor: hexToRgbA(isDarkTheme ? darkBlue : color4, 1),
        hoverBorderColor: hexToRgbA(isDarkTheme ? darkBlue : color4, 1),
        data: dataSeries(arrBalance, arrRealistic, arrYears),
      },
    ],
  };
}
export default BarLeverageRate;
