import TilesContainer from "../../TilesContainer";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { ToggleSlider } from "common-components";
import { addingComma } from "../../../services/helpers";
import {
  Card,
  findingPriceIndex,
  useStylesCardBorder,
} from "../global-summary/PriceForecast";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { useIsMobileScreen } from "common-components";
import DemoExample from "../../DemoExample";
import { useIsIncludes } from "common-components";
import clsx from "clsx";
import { darkBoxes, lightBoxes } from "../../../services/colors";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../../context/DirectionContext";
import { ToggleSliderContext } from "../../../context/ToggleSliderContext";

const TamaToggleForecast = ({ lineData, toggleSlider, name, month }) => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const [year, setYear] = useState(currentYear + 1);
  const [value, setValue] = useContext(ToggleSliderContext);
  const [index, setIndex] = useState(null);
  const [gro, setGro] = useState(undefined);
  const [difference, setDifference] = useState(undefined);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const isExtraSmallScreen = useIsMobileScreen("xs");
  const isSmallScreen = useIsMobileScreen();
  const isDemo = useIsIncludes("demo");
  const classes = useStylesCardBorder({ isDarkTheme });
  const { t } = useTranslation();
  const [direction] = useContext(DirectionContext);
  useEffect(() => {
    setYear(currentYear + value);
  }, [value]);

  useEffect(() => {
    if (lineData) {
      setIndex(
        findingPriceIndex(
          value,
          Number(lineData["x-axis"][0] % 1 === 0 ? 12 : 5)
        )
      );
    }
  }, [value, lineData]);

  useEffect(() => {
    if (lineData) {
      setDifference(
        lineData.series[1]
          ? lineData.series[1].data[index] - lineData.series[0].data[index]
          : undefined
      );

      setGro(
        lineData.series[1]
          ? ((lineData.series[1].data[index] - lineData.series[0].data[index]) /
              lineData.series[0].data[index]) *
              100
          : undefined
      );
    }
  }, [index, lineData]);

  return (
    <TilesContainer topBorder={!!toggleSlider} padding={"32px 16px"} relative>
      {isDemo && <DemoExample />}
      <Grid
        container
        item
        xs={12}
        alignItems={"stretch"}
        justify={"center"}
        spacing={isExtraSmallScreen ? 1 : 0}
      >
        <Grid
          container
          item
          xs={12}
          justify={"space-between"}
          style={{ paddingBottom: 12 }}
        >
          {toggleSlider && (
            <Grid
              item
              xs={12}
              style={{ marginBottom: 20, color: isDarkTheme ? "black" : "" }}
            >
              <ToggleSlider
                radius
                value={value}
                setValue={setValue}
                widthContainer={250}
                widthRoot={50}
                font={direction.isRtl ? false : "0.7rem"}
                list={[
                  { value: 0, text: `${t("today")}` },
                  { value: 3, text: `3 ${t("years")}` },
                  { value: 5, text: `5 ${t("years")}` },
                  { value: 10, text: `10 ${t("years")}` },
                  { value: 25, text: `25 ${t("years")}` },
                ]}
                isDarkTheme={isDarkTheme}
              />
            </Grid>
          )}
        </Grid>
        {dataCards(
          t,
          lineData,
          index,
          year,
          name,
          difference,
          gro,
          month,
          value,
          isExtraSmallScreen,
          isSmallScreen
        ).map((item) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={item.name}
              className={clsx({
                [classes.border]: !item.noBorder,
              })}
              style={{
                backgroundColor: isDarkTheme ? darkBoxes : lightBoxes,
                marginTop: isExtraSmallScreen ? 10 : 0,
              }}
            >
              <Card value={item.value} name={item.name} />
            </Grid>
          );
        })}
      </Grid>
    </TilesContainer>
  );
};
export default TamaToggleForecast;

function dataCards(
  t,
  lineData,
  index,
  year,
  name,
  difference,
  gro,
  month,
  value,
  isExtraSmallScreen,
  isSmallScreen
) {
  return [
    {
      value:
        lineData && month ? (
          `₪ ${addingComma(
            lineData.series[0].data[findingPriceIndex(value, Number(month))]
          )}`
        ) : (
          <bdi>₪ &mdash; &mdash; </bdi>
        ),
      name: `${
        year === new Date().getFullYear()
          ? t("Tama.estimate")
          : t("Tama.forecast")
      } ${t("Tama.propdo")} ${
        name === t("Tama.name") ? t("Tama.excludingProject") : t("Tama.without")
      } ${name === t("Tama.name") ? "" : name} ${
        year === new Date().getFullYear() ? `` : `${t("Tama.forYear")} ${year}`
      }`,
      noBorder: isExtraSmallScreen,
    },
    {
      value:
        lineData && lineData.series[1] ? (
          `₪ ${addingComma(lineData.series[1].data[index])}`
        ) : (
          <bdi>₪ &mdash; &mdash; </bdi>
        ),
      name: `${t("Tama.forecast")} ${t("Tama.propdo")} ${
        name === t("Tama.name")
          ? t("Tama.includingProject")
          : t("Tama.including")
      } ${name === t("Tama.name") ? "" : name} ${
        year === new Date().getFullYear() ? `` : `${t("Tama.forYear")} ${year}`
      }`,
      noBorder: isExtraSmallScreen || isSmallScreen,
    },
    {
      value:
        lineData && difference ? (
          `₪ ${addingComma(difference)}`
        ) : (
          <bdi>₪ &mdash; &mdash; </bdi>
        ),
      name:
        name === t("Tama.name")
          ? t("Tama.predictedImprovement")
          : ` ${t("Tama.supplement")} ${name}`,
      noBorder: isExtraSmallScreen,
    },
    {
      value: lineData ? (
        gro >= 0 ? (
          `${gro.toFixed(2)}%`
        ) : (
          <bdi> &mdash; &mdash;% </bdi>
        )
      ) : (
        <bdi> &mdash; &mdash;% </bdi>
      ),
      name: t("Tama.rateAddition"),
      noBorder: true,
    },
  ];
}
