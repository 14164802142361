import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { HorizontalBar } from "react-chartjs-2";
import { CustomLegend } from "./CustomLegend";
import { CustomTooltip, useCustomTooltip } from "./CustomTooltip";
import { PosConChartInterface } from "../data/DataPosConChart";
import { DarkThemeContext } from "../context/DarkThemeContext";
import {
  darkBackground,
  darkText,
  darkThemeGridLines,
  greyAxis,
} from "../services/colors";
import { ANIMATION_DURATION } from "../services/consts";
import Typography from "@material-ui/core/Typography";
import { FONT } from "../services/fonts";
import {
  disableScrolling,
  enableScrolling,
} from "./mortgage/MortgageLineChart";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { datasetKeyProvider } from "../components/dashboard-components/tama/LineChart";
import TitleGrid from "../components/TitleGrid";
import { useIsMobileScreen } from "common-components";

const PosConChart: React.FC<{
  data: PosConChartInterface;
  legend: { color: string; label: string }[];
  dataTooltip: { label: number; name: string }[];
}> = ({ data, legend, dataTooltip }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const chartRef = useRef<any>();
  const [index, setCurrentIndex] = useState();
  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );
  const isMobileScreen = useIsMobileScreen();

  const xAxisCallback = useCallback(
    (value, item, ets) => {
      let first = dataTooltip[0].name;
      // first = first.slice(0, first.length - 5);
      let last = dataTooltip[dataTooltip.length - 1].name;
      // last = last.slice(0, last.length - 5);
      let middle = dataTooltip[(dataTooltip.length - 1) / 2].name;
      // middle = middle.slice(0, middle.length - 5);
      return item === 0
        ? first
        : item === ets.length - 1
        ? last
        : item === (ets.length - 1) / 2
        ? middle
        : null;
    },
    [dataTooltip]
  );
  const chartJsOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false,
        },
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
      },
      animation: {
        duration: ANIMATION_DURATION,
      },

      tooltips: {
        enabled: false,
        rtl: true,
        custom: customTooltip,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              autoSkip: false,
              callback: xAxisCallback,
              fontFamily: "Open Sans Hebrew, sans-serif",
              fontSize: isMobileScreen ? 10 : FONT,
              fontColor: isDarkTheme ? darkText : greyAxis,
              maxRotation: isMobileScreen ? 50 : 0,
              min: -7,
              max: 7,
            },
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: isMobileScreen ? 10 : FONT,
              fontFamily: "Open Sans Hebrew, sans-serif",
            },
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
          },
        ],
      },
    };
  }, [isMobileScreen]);

  const { t } = useTranslation();

  return (
    <Grid container>
      <TitleGrid
        title={t("PosConChart.title")}
        tooltipText={t("PosConChart.tooltip")}
      />

      <Grid item xs={12}>
        <CustomLegend legend={legend} />
        <div
          style={{ minHeight: 300 }}
          onTouchStart={() => {
            disableScrolling();
          }}
          onTouchEnd={() => {
            enableScrolling();
          }}
        >
          <HorizontalBar
            ref={chartRef}
            data={data}
            options={chartJsOptions}
            datasetKeyProvider={datasetKeyProvider}
          />
        </div>
        <CustomTooltip
          tooltipRef={tooltipRef}
          left={position.left}
          top={position.top}
          id="pos-con"
          place="top"
        >
          <TooltipInner
            data0={data.datasets[0].data}
            data1={data.datasets[1].data}
            labels={data.labels}
            currentIndex={index}
            dataTooltip={dataTooltip}
            isDarkTheme={isDarkTheme}
          />
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};

const TooltipInner: React.FC<{
  data0: number[];
  data1: number[];
  labels: string[];
  currentIndex: undefined | number;
  dataTooltip: { label: number; name: string }[];
  isDarkTheme: boolean;
}> = ({ data0, data1, labels, currentIndex, dataTooltip, isDarkTheme }) => {
  const { t } = useTranslation();
  if (
    data0 &&
    data1 &&
    labels &&
    currentIndex !== undefined &&
    currentIndex >= 0 &&
    dataTooltip
  ) {
    const value0 = data0[currentIndex];
    const value1 = data1[currentIndex];

    let name0;
    let name1;

    dataTooltip.forEach((item) => {
      if (value0 === item.label) {
        name0 = item.name;
      }
      if (value1 === item.label) {
        name1 = item.name;
      }
    });

    return (
      <div style={{ borderRadius: "var(--border-radius)" }}>
        <div
          style={{
            backgroundColor: isDarkTheme ? darkBackground : "#fff",
            width: "100%",
            maxWidth: 200,
            borderRadius: "var(--border-radius)",
            padding: 8,
            boxShadow: "0 3px 15px 2px hsla(0,0%,53.3%,.3)",
            border: "none !important",
          }}
        >
          {name0 && name1 && (
            <>
              <Typography style={{ color: isDarkTheme ? darkText : greyAxis }}>
                <bdi>{t("PosConChart.bothEvents")}</bdi>
                {/* &thinsp; */}
                {labels[currentIndex]}
                &thinsp;
                <bdi>{t("PosConChart.mostInfluence")}</bdi>
                {name0}
                {`${t("PosConChart.and")}${name1}.`}
              </Typography>
            </>
          )}
        </div>
      </div>
    );
  } else return null;
};
export default PosConChart;
