import React, {
  ComponentClass,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import Dialog from "@material-ui/core/Dialog";

import { useIsMobileScreen } from "../hooks/useIsMobileScreen";

import Slide from "@material-ui/core/Slide";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { DialogProps } from "@material-ui/core/Dialog/Dialog";

type DialogPropsManager = Omit<DialogProps, "open">;

interface OpenDialogOptions {
  DialogProps?: DialogPropsManager;

  DialogContentProps?: object;
}

interface DialogManagerContextProps {
  closeDialog: (afterClose?: () => void) => void;

  openDialog: (
    Component: FunctionComponent<any> | ComponentClass<any> | string,
    options?: OpenDialogOptions
  ) => void;
}

export const DialogManagerContext = React.createContext<
  DialogManagerContextProps
>({
  closeDialog: () => {},

  openDialog: () => {},
});

const useStylesDialog = makeStyles(() =>
  createStyles({
    pap: { overflowY: "inherit" },
  })
);

/**

 * Represents DialogManager Context + Dialog component.

 * @example Open a dialog. DialogContentComponent is a functional or class component.

 * const {openDialog} = useDialogManager()

 * if (shouldOpenDialog) {

 * 	openDialog(DialogContentComponent, options)

 * }

 *

 */

export function DialogManager({ children }: PropsWithChildren<unknown>) {
  const isMobileScreen = useIsMobileScreen();

  const classes = useStylesDialog();

  const [currentDialogComponent, setCurrentDialogComponent] = useState<
    FunctionComponent<any> | ComponentClass<any> | string
  >();

  const [currentDialogContentProps, setCurrentDialogContentProps] = useState<
    any
  >(undefined);

  const [currentDialogProps, setCurrentDialogProps] = useState<
    DialogPropsManager
  >();

  const [open, setOpen] = useState(false);

  /**

   * Ref to hold actual onDialogExited logic.

   * In case openDialog() from useDialogManager() is called inside another dialog

   * we close current dialog and open elements dialog inside onExited callback of Dialog's transition.

   */

  const onExitedInner = useRef<() => void>();

  const closeDialog = useCallback((afterClose?: () => void) => {
    if (afterClose) {
      onExitedInner.current = () => {
        afterClose();

        onExitedInner.current = undefined;
      };
    } else {
      onExitedInner.current = undefined;
    }

    setOpen(false);

    setCurrentDialogContentProps(undefined);

    setCurrentDialogProps(undefined);
  }, []);

  const onDialogExited = useCallback(() => {
    if (onExitedInner.current) {
      onExitedInner.current();
    }
  }, []);

  const openDialog = useCallback(
    (
      DialogContentComponent:
        | FunctionComponent<any>
        | ComponentClass<any>
        | string,
      options?: OpenDialogOptions
    ) => {
      if (!DialogContentComponent) {
        console.error(
          "Need to pass DialogContentComponent to openDialog callback!"
        );

        return;
      }

      const { DialogProps, DialogContentProps } = options ?? {};

      function setCurrents() {
        setCurrentDialogComponent(() => {
          //need to wrap it in function to pass it as expected.

          //passing this way: setCurrentDialogComponent(DialogContentComponent) doesn't work well since

          //DialogContentComponent is a function by itself

          return DialogContentComponent;
        });

        setCurrentDialogProps(DialogProps);

        setCurrentDialogContentProps(DialogContentProps);
      }

      setOpen((open) => {
        if (!open) {
          //no currently open dialog. Simple open.

          setCurrents();

          return true;
        } else {
          //call to openDialog within another dialog.

          //Close current dialog first, and open elements one inside onExited transition callback.

          onExitedInner.current = () => {
            setOpen(true);

            setCurrents();
          };

          return false;
        }
      });
    },

    []
  );

  return (
    <DialogManagerContext.Provider
      value={{
        closeDialog,

        openDialog,
      }}
    >
      {children}

      <Dialog
        open={open}
        onClose={(ev: any) => {
          if (ev.which === 27) return;
          closeDialog();
        }}
        fullScreen={!!isMobileScreen}
        scroll={isMobileScreen ? "body" : "paper"}
        TransitionComponent={Slide}
        transitionDuration={{ enter: 350, exit: 300 }}
        disableBackdropClick
        // @ts-ignore
        TransitionProps={{ direction: "up", onExited: onDialogExited }}
        maxWidth={"lg"}
        classes={{ paper: classes.pap }}
        {...currentDialogProps}
      >
        {currentDialogComponent !== undefined &&
          React.createElement(
            currentDialogComponent,
            currentDialogContentProps
          )}
      </Dialog>
    </DialogManagerContext.Provider>
  );
}
