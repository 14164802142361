import { makeStyles } from "@material-ui/core/styles";
import {
  borderColor,
  darkBoxes,
  darkText,
  lightBoxes,
} from "../services/colors";
import { LG_PLUS_FONT } from "../services/fonts";

export const useStylesUserPage = makeStyles({
  acquiredReportsContainer: (props) => ({
    padding: 16,
    backgroundColor: props.isDarkTheme ? darkBoxes : lightBoxes,
    color: props.isDarkTheme ? darkText : "",
  }),
  acquiredTitle: (props) => ({
    whiteSpace: "nowrap",
    borderRight: props.isExtraSmallScreen ? "none" : `1px solid ${borderColor}`,
  }),
  acquiredValue: (props) => ({
    fontSize: LG_PLUS_FONT,
    whiteSpace: "nowrap",
    borderRight: props.isExtraSmallScreen ? "none" : `1px solid ${borderColor}`,
  }),
});
