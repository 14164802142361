import React, { createContext, useContext, useEffect, useState } from "react";
import { useDialogManager, useLazyDataApi } from "common-components";
import { AuthorizationContext } from "./AuthorizationContext";
import { DarkThemeContext } from "./DarkThemeContext";
import { useIsIncludes } from "common-components";
import { OpenAccessContext } from "./OpenAccessContext";
import { DirectionContext } from "./DirectionContext";
import { DOMAIN } from "common-components";
//@ts-ignore
import Cookies from "js-cookie";
import { hasValueInStorage } from "../services/helpers";
import { doFetch } from "common-components";
import PurchasePlan from "../dialogs/pricing/PurchasePlan";

export const UserDetailsContext = createContext<any>({});

export const UserDetailsContextProvider: React.FC = ({ children }) => {
  const [direction, setDirection] = useContext(DirectionContext);
  const [isOpenAccess] = useContext(OpenAccessContext);
  const [{ data, error }, lazyFetch] = useLazyDataApi<any>();
  const [userDetails, setUserDetails] = useState<
    | undefined
    | { initials: string; balance: number; acc_type: string; lang: string }
  >(undefined);
  const [authorization] = useContext(AuthorizationContext);
  const [, setDarkTheme] = useContext(DarkThemeContext);
  const isShare = useIsIncludes("share");
  const { openDialog } = useDialogManager();

  useEffect(() => {
    if (authorization) {
      lazyFetch(
        `/user/icon?country=${direction?.country}&region=${direction?.region}&url=${window.location.href}`
      );
    }
  }, [authorization]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (data && !isShare) {
      setUserDetails(data);
      if (data.acc_type === "premium" && !isOpenAccess.access) {
        setDarkTheme(true);
        localStorage.setItem("premium", "1");
      } else {
        setDarkTheme(false);
        localStorage.setItem("premium", "0");
      }
    }
  }, [data, isOpenAccess]);

  useEffect(() => {
    if (data) {
      if (
        data.lang !==
          Cookies.get("lang", {
            domain: DOMAIN,
          }) &&
        hasValueInStorage(
          Cookies.get("lang", {
            domain: DOMAIN,
          })
        )
      ) {
        doFetch(`/user/details/update`, {
          method: "POST",
          body: JSON.stringify({
            lang: Cookies.get("lang", {
              domain: DOMAIN,
            }),
          }),
        });
      }
    }
  }, [data]);

  return (
    <UserDetailsContext.Provider value={[userDetails, setUserDetails]}>
      {children}
    </UserDetailsContext.Provider>
  );
};
