import React, { useContext, useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { CustomLegend } from "./CustomLegend";
import { CustomTooltip, useCustomTooltip } from "./CustomTooltip";
import { addingComma } from "../services/helpers";
import { LegendInterface, StackBarInterface } from "../data/DataStackBarChart";
import { DarkThemeContext } from "../context/DarkThemeContext";
import {
  darkBackground,
  darkText,
  darkThemeGridLines,
  darkThemeSliderRail,
  greyAxis,
} from "../services/colors";
import { ANIMATION_DURATION } from "../services/consts";
import { FONT } from "../services/fonts";
import Typography from "@material-ui/core/Typography";
import { useIsMobileScreen } from "common-components";
import { useTranslation } from "react-i18next";
import {
  yAxisCallbackMillionsIL,
  yAxisCallbackMillionsUS,
} from "./mortgage/MortgageLineChart";
import { DirectionContext } from "../context/DirectionContext";
import Grid from "@material-ui/core/Grid";
import { datasetKeyProvider } from "../components/dashboard-components/tama/LineChart";
import TitleGrid from "../components/TitleGrid";

function xAxisCallback(value: number) {
  return value % 5 === 0 ? value : null;
}

const StackBarChart: React.FC<{
  data: StackBarInterface;
  legend: LegendInterface[];
}> = ({ data, legend }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [direction] = useContext(DirectionContext);
  const chartRef = useRef<any>();
  const [index, setCurrentIndex] = useState();

  const { customTooltip, tooltipRef, position } = useCustomTooltip(
    chartRef,
    setCurrentIndex
  );
  const { t } = useTranslation();

  const chartJsOptions = useMemo(() => {
    return {
      animation: {
        duration: ANIMATION_DURATION,
      },

      plugins: {
        deferred: {
          yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
        },
        datalabels: {
          display: false,
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
        rtl: true,
        custom: customTooltip,
      },

      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: FONT,
              fontFamily: "Open Sans Hebrew, sans-serif",
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              callback: xAxisCallback,
            },
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              fontColor: isDarkTheme ? darkText : greyAxis,
              fontSize: FONT,
              min: 0,
              callback: direction.isRtl
                ? yAxisCallbackMillionsIL
                : yAxisCallbackMillionsUS,
            },
            gridLines: {
              drawOnChartArea: false,
              color: isDarkTheme ? darkThemeGridLines : "rgba(0, 0, 0, 0.1)",
            },
          },
        ],
      },
    };
  }, [direction]);

  const isExtraMobileScreen = useIsMobileScreen("xs");
  return (
    <Grid container>
      <TitleGrid
        tooltipText={t("StackBarChart.tooltip")}
        title={t("StackBarChart.title")}
      />

      <Grid item xs={12}>
        <CustomLegend legend={legend} />
        <div
          style={{ padding: isExtraMobileScreen ? 0 : 15, minHeight: 250 }}
          className="stack-bar-chart"
        >
          <Bar
            ref={chartRef}
            data={data}
            datasetKeyProvider={datasetKeyProvider}
            options={chartJsOptions}
          />
        </div>
        <CustomTooltip
          tooltipRef={tooltipRef}
          left={position.left}
          top={position.top}
          id="stackBar"
          place="top"
        >
          <TooltipInner
            data={data}
            currentIndex={index}
            isDarkTheme={isDarkTheme}
          />
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};

const TooltipInner: React.FC<{
  data: StackBarInterface;
  currentIndex: undefined | number;
  isDarkTheme: boolean;
}> = ({ data, currentIndex, isDarkTheme }) => {
  let value0 = currentIndex ? data.datasets[0].data[currentIndex] : undefined;
  let value1 = currentIndex ? data.datasets[1].data[currentIndex] : undefined;
  let fixed0;
  let fixed1;
  let sum;
  if (value0 !== undefined && value1 !== undefined) {
    fixed0 = parseInt(value0.toFixed(0));
    fixed1 = parseInt(value1.toFixed(0));
    sum = fixed0 + fixed1;
  }
  const { t } = useTranslation();
  return (
    <div style={{ borderRadius: "var(--border-radius)" }}>
      <div
        style={{
          backgroundColor: isDarkTheme ? darkThemeSliderRail : "#f1f4fd",
          width: "100%",
          borderRadius: "var(--br-top)",
          padding: 8,
        }}
      >
        <Typography
          dir="ltr"
          align={"right"}
          style={{
            color: isDarkTheme ? darkText : "black",
          }}
        >
          {`₪` == "₪" && currentIndex ? data.labels[currentIndex] : " "}
          &thinsp; {t("StackBarChart.tooltipTitle")} &thinsp;
          {`₪` !== "₪" && currentIndex ? data.labels[currentIndex] : " "}
        </Typography>
      </div>
      <div
        style={{
          backgroundColor: isDarkTheme ? darkBackground : "white",
          width: "100%",
          borderRadius: "var(--br-bottom)",
          padding: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ color: isDarkTheme ? darkText : greyAxis }}>
            {t(`labels.${data.datasets[1].label}`)}
          </Typography>
          {fixed1 && (
            <Typography style={{ color: data.datasets[1].backgroundColor }}>
              {addingComma(fixed1)} ₪
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ color: isDarkTheme ? darkText : greyAxis }}>
            {t(`labels.${data.datasets[0].label}`)}
          </Typography>
          {fixed0 && (
            <Typography style={{ color: data.datasets[0].backgroundColor }}>
              {addingComma(fixed0)} ₪
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ color: isDarkTheme ? darkText : "black" }}>
            {t("StackBarChart.total")}
          </Typography>
          {sum && (
            <Typography style={{ color: isDarkTheme ? darkText : "black" }}>
              {addingComma(sum)}₪
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default StackBarChart;
