import React, { useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormSelectPropDetails from "./FormSelectPropDetails";
import { useIsMobileScreen } from "common-components";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { PaymentProcessContext } from "../../context/PaymentProcessContext.tsx";
import { date } from "../payment/Payment";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesSelectPropDetails } from "../../styledComponent/SelectPropDetailsStyled";
import SelectPropPropType from "./SelectPropPropType";
import SelectPropRating from "./SelectPropRating";
import { useStylesUserLogin } from "../../styledComponent/UserLoginStyled";
import { useHistory, useLocation } from "react-router-dom";
import ReportsAddProp from "../../outside-components/reports/ReportsAddProp";
import {
  propertyIL,
  PurchasePropertyContext,
} from "../../context/PurchasePropertyContext";
import { useForm } from "react-hook-form";
import CloseButton from "../../components/CloseButton";
import {
  checkSearchAddress,
  SearchAutocomplete,
} from "../../components/SearchPlaces";
import Typography from "@material-ui/core/Typography";
import SliderSwitch from "./SliderSwitch";
import ReportsGushHalka from "../../outside-components/reports/ReportsGushHalka";
import { IMAGES_LINK } from "common-components";
import i18next from "i18next";
import { hasValueInStorage } from "../../services/helpers";
import MessageNoProp from "../../components/dashboard-components/global-summary/MessageNoProp";

export const propTypeConverter = (type) => {
  if (type === "דירה" || type === "Apartment") return "Apartment";
  else if (type === "דירת גג" || type === "Penthouse") return "Penthouse";
  else if (type === "דירת גן" || type === "Garden Apartment")
    return "Garden Apartment";
  else if (type === "בית פרטי" || type === "Single-Family Home")
    return "Single-Family Home";
  else if (type === "דו משפחתי" || type === "Multi-Family Home")
    return "Multi-Family Home";
  else if (type === "קרקע" || type === "Land") return "Land";
};

export const labelsRating = () => {
  return {
    1: i18next.t("labelsRating.1"),
    2: i18next.t("labelsRating.2"),
    3: i18next.t("labelsRating.3"),
    4: i18next.t("labelsRating.4"),
    5: i18next.t("labelsRating.5"),
  };
};

export const PROPERTY_TYPE_APARTMENT = () =>
  i18next.t("PropertyTypes.Apartment");
export const PROPERTY_TYPE_PENTHOUSE = () =>
  i18next.t("PropertyTypes.Penthouse");
export const PROPERTY_TYPE_GARDEN = () =>
  i18next.t("PropertyTypes.Garden Apartment");
export const PROPERTY_TYPE_PRIVATE_HOUSE = () =>
  i18next.t("PropertyTypes.Single-Family Home");
export const PROPERTY_TYPE_TOWN_HOUSE = () =>
  i18next.t("PropertyTypes.Multi-Family Home");
export const PROPERTY_TYPE_LAND = () => i18next.t("PropertyTypes.Land");

export const filterTypes = () => {
  return [
    PROPERTY_TYPE_APARTMENT(),
    PROPERTY_TYPE_PENTHOUSE(),
    PROPERTY_TYPE_GARDEN(),
    PROPERTY_TYPE_PRIVATE_HOUSE(),
    PROPERTY_TYPE_TOWN_HOUSE(),
    PROPERTY_TYPE_LAND(),
  ];
};

export const checkAddress = (property) => {
  // if (property.gush && property.halka) return ""
  if (property.house_num.length > 0)
    return `${property.area.replace(/\s*$/, "")}, ${property.street.replace(
      /\s*$/,
      ""
    )}, ${property.house_num.replace(/\s*$/, "")}`;

  if (property.area)
    return `${property.area.replace(/\s*$/, "")}, ${property.street.replace(
      /\s*$/,
      ""
    )}`;

  return "";
};

export function isMaxRatingFunction(
  constructionYear,
  setProperty,
  setRatingValue,
  setIsMaxRating
) {
  if (Number(constructionYear) >= date.getFullYear() - 1) {
    setProperty((prevState) => ({ ...prevState, condition: 5 }));
    setRatingValue(5);
    setIsMaxRating(true);
  } else setIsMaxRating(false);
}

const SelectPropDetails = () => {
  const [loading, setLoading] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);
  const [property, setProperty] = useContext(PurchasePropertyContext);
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    property,
  });
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [, setPaymentProcess] = useContext(PaymentProcessContext);
  const { openDialog, closeDialog } = useDialogManager();
  const [ratingValue, setRatingValue] = useState(
    property.condition ? property.condition : 3
  );
  const [isMaxRating, setIsMaxRating] = useState(false);
  const [ratingHover, setRatingHover] = useState(-1);
  const [propTypeVal, setPropTypeVal] = useState(
    property.type ? property.type : PROPERTY_TYPE_APARTMENT()
  );
  const [isAddressSwitch, setIsAddressSwitch] = useState(
    !property.gush && !property.halka
  );

  const [addressInput, setAddressInput] = useState({
    address: checkAddress(property),
    area: property.area.replace(/\s*$/, ""),
    house_num: property.house_num.replace(/\s*$/, ""),
    street: property.street.replace(/\s*$/, ""),
  });

  const isSmallScreen = useIsMobileScreen();
  const classes = useStylesUserLogin({
    isDarkTheme,
    isSmallScreen,
    isPropDetails: true,
  });
  const history = useHistory();
  let location = useLocation();
  const classesSelect = useStylesSelectPropDetails({
    isDarkTheme: isDarkTheme,
    font: 14,
  });

  useEffect(() => {
    setProperty((prevState) => ({
      ...prevState,
      condition: ratingValue,
      type: propTypeVal,
    }));
  }, [ratingValue, propTypeVal]);

  const handleClosePayment = () => {
    history.push({
      pathname: location.pathname,
    });
    setProperty(propertyIL);
    setPaymentProcess(false);
    if (hasValueInStorage(localStorage.getItem("noProp"))) {
      openDialog(MessageNoProp, {
        DialogProps: { fullScreen: false, scroll: "paper" },
      });
    } else {
      closeDialog();
    }
  };
  const handleSelect = (address, placeId) => {
    setAddressInput((prevState) => ({ ...prevState, address: address }));
    checkSearchAddress(
      placeId,
      setLoading,
      setAddressInput,
      setPaymentProcess,
      setErrorAddress,
      setProperty
    );
  };
  const handlePropTypeValChange = (value) => {
    setPropTypeVal(value);
  };
  const isMobileScreen = useIsMobileScreen();
  const isExtraMobileScreen = useIsMobileScreen("xs");
  let constructionYear = watch("year_built");

  useEffect(() => {
    isMaxRatingFunction(
      constructionYear,
      setProperty,
      setRatingValue,
      setIsMaxRating
    );
  }, [constructionYear]);

  return (
    <div className={classes.divRootContainer}>
      <Paper elevation={0} className={classesSelect.propertiesRoot}>
        <Grid container alignItems={"center"} justify={"center"} spacing={2}>
          {isExtraMobileScreen ? (
            <ReportsAddProp />
          ) : (
            <Grid container>
              <Grid
                container
                item
                xs={4}
                className={classesSelect.selectContainer}
              >
                <SelectPropPropType
                  handlePropTypeValChange={handlePropTypeValChange}
                  propTypeVal={propTypeVal}
                  sizeIcon={isSmallScreen ? 55 : 60}
                />
              </Grid>
              <Grid
                container
                item
                xs={8}
                className={classesSelect.selectContainerDetails}
                style={{ position: "relative" }}
              >
                <Grid item className={classesSelect.closeContainer}>
                  <CloseButton callback={handleClosePayment} padding={0} />
                </Grid>
                <Grid container item xs={12} style={{ paddingTop: 10 }}>
                  <Grid item xs={12} style={{ paddingBottom: 8 }}>
                    <SliderSwitch
                      setIsAddressSwitch={setIsAddressSwitch}
                      isAddressSwitch={isAddressSwitch}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingLeft: isMobileScreen ? 5 : 8,
                      paddingRight: 8,
                    }}
                  >
                    {isAddressSwitch ? (
                      <Grid item xs={12}>
                        <SearchAutocomplete
                          addressInput={addressInput}
                          handleChange={handleSelect}
                          handleSelect={handleSelect}
                          place={""}
                          setAddressInput={setAddressInput}
                          setErrorAddress={setErrorAddress}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        container
                        alignItems={"center"}
                        spacing={2}
                        item
                        xs={12}
                      >
                        <ReportsGushHalka
                          classes={classes}
                          errors={errors}
                          register={register}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} style={{ padding: "8px" }}>
                    {propTypeVal !== PROPERTY_TYPE_LAND() ? (
                      <SelectPropRating
                        labels={labelsRating()}
                        ratingHover={ratingHover}
                        ratingValue={ratingValue}
                        setRatingHover={setRatingHover}
                        setRatingValue={setRatingValue}
                        disabled={isMaxRating}
                        sizeRating={"medium"}
                        propTypeVal={propTypeVal}
                      />
                    ) : (
                      <div style={{ height: 9.39 }}></div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormSelectPropDetails
                      propTypeVal={propTypeVal}
                      errors={errors}
                      register={register}
                      watch={watch}
                      handleSubmit={handleSubmit}
                      control={control}
                      setValue={setValue}
                      isAddressSwitch={isAddressSwitch}
                      errorAddress={errorAddress}
                      setErrorAddress={setErrorAddress}
                      getValues={getValues}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export function PropertyTypeRadio({ label, size, Icon }) {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const props = { isDarkTheme: isDarkTheme };
  const classes = useStylesSelectPropDetails(props);
  return (
    <>
      <img
        src={`${IMAGES_LINK}/dashboard/${Icon}`}
        alt={Icon}
        style={{ width: size, height: size }}
      />
      <Typography className={classes.paragraphAddProp}>{label}</Typography>
    </>
  );
}

export default SelectPropDetails;
