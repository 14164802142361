import React from "react";
import { useIsMobileScreen } from "common-components";
import { useStylesErrorPage } from "../styledComponent/ErrorPageStyled";
import { useStylesLayout } from "../styledComponent/LayoutStyled";
import Typography from "@material-ui/core/Typography";
import { IMAGES_LINK, LANDING_PAGE_LINK } from "common-components";

const ErrorPage = () => {
  const isMobileScreen = useIsMobileScreen();
  const classes = useStylesErrorPage({ isMobileScreen });
  const classesLA = useStylesLayout();

  return (
    <div style={{ zIndex: 9999 }}>
      <div className={classes.divError}>
        <header className={classes.headerError}>
          <div>
            <div className={classes.logoBoxError}>
              <a
                onClick={() => {
                  window.location.replace(LANDING_PAGE_LINK);
                }}
              >
                <img
                  src={`${IMAGES_LINK}/dashboard/logos/propdo-logo-blue.svg`}
                  alt="Propdo Logo"
                  style={{
                    width: isMobileScreen ? 101 : 185,
                    cursor: "pointer",
                    padding: "0 20px",
                  }}
                />
              </a>
            </div>
          </div>
        </header>
        <div className={classes.divTextBox + " " + classesLA.divWidth}>
          <img
            src={`${IMAGES_LINK}/dashboard/error/error.jpg`}
            alt=""
            style={{ maxWidth: isMobileScreen ? 350 : 500 }}
          />
          <div className={classes.textWrapError}>
            <Typography>האתר ירד לצרכי תחזוקה ועבודות שדרוג המערכת.</Typography>
            <Typography>אנא נסה שנית במועד מאוחר יותר.</Typography>
            <Typography>תודה</Typography>
          </div>
        </div>
        <div style={{ height: isMobileScreen ? 41 : 101 }} />
      </div>
    </div>
  );
};
export default ErrorPage;
