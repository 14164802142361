import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { ResultComponent } from "../charts/mortgage/MortgageResultRow";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useStylesForecast } from "../styledComponent/ForecastStyled";
import DemoExample from "./DemoExample";
import { useIsIncludes } from "common-components";
import { addingComma } from "../services/helpers";
import { DarkThemeContext } from "../context/DarkThemeContext";
import TilesContainer from "./TilesContainer";
import { useStylesAddProp } from "../styledComponent/AddPropStyled";
import { useIsMobileScreen } from "common-components";
import { SPACING_FACTOR } from "./layout/Layout";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  color3,
  darkMainPropColor,
  darkPurple,
  darkText,
  mainPropColor,
} from "../services/colors";
import { useStylesFontMortgage } from "../charts/mortgage/MortgageCalculatorChart";
import SubmitAndClearButtons from "./SubmitAndClearButtons";
import { useStylesCardBorder } from "./dashboard-components/global-summary/PriceForecast";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import TitleGrid from "./TitleGrid";

const useStyles = makeStyles((theme) => ({
  textField: {
    borderRadius: 0,
  },
}));

const MonthlyRentBox = ({
  CV,
  currentRent,
  handleReset,
  handleSetRent,
  loading,
  setLoading,
}) => {
  const inputClasses = useStyles();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classesForecast = useStylesForecast();
  const [myRent, setMyRent] = useState("");
  const isDemo = useIsIncludes("demo");
  const isListings = useIsIncludes("listings");
  const classesLab = useStylesAddProp({ isDarkTheme, font: 12 });
  const classes = useStylesFontMortgage({ isDarkTheme });
  const { t } = useTranslation();

  useEffect(() => {
    setMyRent(currentRent);
  }, [currentRent]);

  const handleInputChange = (event) => {
    event.target.value = event.target.value.replace(",", "");
    if (Number(event.target.value) <= 0 || !Number(event.target.value))
      event.target.value = "";
    setMyRent(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (myRent <= 0) setMyRent("");
  };

  return (
    <TilesContainer
      relative
      style={{
        width: "100%",
      }}
      topBorder
    >
      {isDemo && <DemoExample />}
      <Grid container>
        <TitleGrid title={t("Rent.MonthlyRent.actualRent")} />
        <Grid item container spacing={SPACING_FACTOR}>
          <Grid container item xs={12} sm={6}>
            <div
              style={{
                paddingTop: 15,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <InputLabel className={classesLab.inputLabelSearch} />

              <TextField
                id="monthly-rent"
                name="monthly-rent"
                autoComplete="off"
                variant="outlined"
                disabled={loading}
                style={{
                  width: "100%",
                }}
                className={inputClasses.textField}
                value={addingComma(myRent)}
                onChange={handleInputChange}
                placeholder={`${t("Rent.MonthlyRent.input")}`}
                onBlur={handleBlur}
                error={myRent < 0}
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                    min: 0,
                    style: {
                      padding: "10.5px",
                      width: "90%",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position={"start"}>
                      {" "}
                      <Typography
                        variant={"p"}
                        stlye={{
                          color: isDarkTheme
                            ? `${darkText} !important`
                            : "black !important",
                        }}
                      >
                        ₪
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ResultComponent
              noBorder={true}
              value={
                CV && currentRent > 0 ? (
                  `${(((currentRent * 12) / CV) * 100).toFixed(2)}%`
                ) : (
                  <bdi>&mdash; &mdash;% </bdi>
                )
              }
              classes={classes}
              classesForecast={classesForecast}
              id={t("Rent.MonthlyRent.rentalAnnualYield")}
              tooltip={t("Rent.MonthlyRent.tooltipRentalAnnualYield")}
              borderRadius={"var(--br-right)"}
            />
          </Grid>
          <Grid container item xs={12} justify={"space-between"}>
            <SubmitAndClearButtons
              isLoading={loading}
              onClearBtn={() => {
                setMyRent("");
                handleReset("");
                setLoading(true);
              }}
              submitTitle={t("calculate")}
              disabled={!myRent || loading || isListings}
              onClickBtn={() => {
                setLoading(true);
                handleSetRent(myRent);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </TilesContainer>
  );
};
export default MonthlyRentBox;

export const ConclusionsRentBox = ({ data }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const CV = data.cv;
  const currentRent = data.expected_rent;
  const averageRent = data.rent?.rent;
  const numberOfRooms = data.rent?.num_rooms;
  const potentialYield = (((averageRent * 12) / CV) * 100).toFixed(2);
  const myYield = (((currentRent * 12) / CV) * 100).toFixed(2);
  const isDemo = useIsIncludes("demo");
  const { t } = useTranslation();
  return (
    <TilesContainer
      relative
      style={{
        borderColor: isDarkTheme ? darkPurple : color3,
        transition: currentRent > 0 ? "all 1s ease-in-out" : "none",
      }}
    >
      {isDemo && <DemoExample />}
      {currentRent ? (
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            height: "100%",
          }}
        >
          <ErrorOutlineIcon
            style={{
              color: isDarkTheme ? darkMainPropColor : mainPropColor,
              marginLeft: 5,
            }}
          />
          <div>
            {currentRent > averageRent ? (
              <Typography>
                {`${t(
                  "Rent.ConclusionsRentBox.textHighRent.part1"
                )} ${numberOfRooms} ${t(
                  "Rent.ConclusionsRentBox.textHighRent.part2"
                )}`}
              </Typography>
            ) : currentRent < averageRent ? (
              <Typography>
                {`${t(
                  "Rent.ConclusionsRentBox.textLowRent.part1"
                )} ${numberOfRooms} ${t(
                  "Rent.ConclusionsRentBox.textLowRent.part2"
                )}${addingComma(averageRent - currentRent)} ${t(
                  "Rent.ConclusionsRentBox.textLowRent.part3"
                )}${myYield}%
                ${t(
                  "Rent.ConclusionsRentBox.textLowRent.part4"
                )}${potentialYield}% ${t(
                  "Rent.ConclusionsRentBox.textLowRent.part5"
                )}${(potentialYield - myYield).toFixed(2)}%)`}
              </Typography>
            ) : (
              <Typography>
                {`${t(
                  "Rent.ConclusionsRentBox.textNormalRent.part1"
                )} ${numberOfRooms}
                ${t("Rent.ConclusionsRentBox.textNormalRent.part2")}`}
              </Typography>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            height: "100%",
          }}
        >
          <ErrorOutlineIcon
            style={{ color: isDarkTheme ? darkPurple : color3, margin: 5 }}
          />
          <Typography>{t("Rent.ConclusionsRentBox.textStart")}</Typography>
        </div>
      )}
    </TilesContainer>
  );
};

export const AverangeRentInfoBox = ({ data }) => {
  const classesForecast = useStylesForecast();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStylesFontMortgage({ isDarkTheme, paddingLeft: true });
  const isDemo = useIsIncludes("demo");
  const CV = data.cv;
  const numberOfRooms = data.rent?.num_rooms || "--";
  const averageRent = data.rent?.rent;
  const averageRoomRent = data.rent?.rent_per_room;
  const averageYield = data.rent?.yield;
  const potentialYield = averageRent
    ? (((averageRent * 12) / CV) * 100).toFixed(2)
    : 0;

  const isExtraSmallScreen = useIsMobileScreen("xs");
  const isSmallScreen = useIsMobileScreen();
  const classesBorder = useStylesCardBorder({ isDarkTheme });
  const { t } = useTranslation();

  return (
    <TilesContainer relative>
      {isDemo && <DemoExample />}

      <Grid container style={{ width: "100%" }} alignItems={"stretch"} xs={12}>
        <Grid
          item
          xs={12}
          sm={4}
          className={clsx({
            [classesBorder.border]: !isExtraSmallScreen,
          })}
        >
          <ResultComponent
            paddingTitle={"16px 0"}
            noBorder
            value={
              averageRent ? (
                `${addingComma(averageRent)} ₪`
              ) : (
                <bdi>₪ &mdash; &mdash; </bdi>
              )
            }
            classes={classes}
            classesForecast={classesForecast}
            id={t("Rent.AverageRent.avgMonthRent")}
            tooltip={t("Rent.AverageRent.tooltipAvgMonthRent")}
            borderRadius={"var(--br-right)"}
            noResultHeader
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{
            marginTop: isExtraSmallScreen ? 10 : 0,
          }}
          className={clsx({
            [classesBorder.border]: !isExtraSmallScreen,
          })}
        >
          <ResultComponent
            paddingTitle={"16px 0"}
            noBorder
            value={
              averageRoomRent ? (
                `${addingComma(averageRoomRent)} ₪`
              ) : (
                <bdi>₪ &mdash; &mdash; </bdi>
              )
            }
            classes={classes}
            classesForecast={classesForecast}
            id={t("Rent.AverageRent.avgMonthRentRoom")}
            tooltip={t("Rent.AverageRent.tooltipAvgMonthRentRoom")}
            borderRadius={"var(--br-right)"}
            noResultHeader
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{
            marginTop: isExtraSmallScreen ? 10 : 0,
          }}
        >
          <ResultComponent
            paddingTitle={"16px 0"}
            noBorder
            value={
              potentialYield ? (
                `${potentialYield}%`
              ) : (
                <bdi>&mdash; &mdash;%</bdi>
              )
            }
            classes={classes}
            classesForecast={classesForecast}
            id={t("Rent.AverageRent.avgYearlyRentalYield")}
            tooltip={t("Rent.AverageRent.tooltipAvgYearlyRentalYield")}
            borderRadius={"var(--br-right)"}
            noResultHeader
          />
        </Grid>
      </Grid>
    </TilesContainer>
  );
};
