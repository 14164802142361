import EditCell from "./EditCell";
import React, { useContext } from "react";
import { DataAddressesContext } from "../../../context/DataAddressesContext";
import { doFetch } from "common-components";
import { DirectionContext } from "../../../context/DirectionContext";

const ApartmentNumber = ({ row, value }) => {
  const [addresses, setAddresses] = useContext(DataAddressesContext);
  const [direction] = useContext(DirectionContext);

  const handleSaveApartment = (
    setIsSaved,
    setLoading,
    setIsChangeApartment,
    apartment
  ) => {
    setLoading(true);
    doFetch(
      `/reports/dashboard/overview/update/apartment?id=${row.id}&lang=${
        direction.isRtl ? "he" : "en"
      }`,
      {
        method: "POST",
        body: JSON.stringify({
          apartment: apartment,
        }),
      }
    ).then((data) => {
      setLoading(false);
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
        setIsChangeApartment(false);
      }, 1500);
    });
    row.apartment = apartment;
    const newAddresses = { ...addresses.active };
    newAddresses.properties[row.index].apartment = apartment;
    setAddresses((prevState) => ({ ...prevState, active: newAddresses }));
  };

  return (
    <EditCell value={value} handleSaveValue={handleSaveApartment} justDigits />
  );
};
export default ApartmentNumber;
