import React, { useContext } from "react";
import { DarkThemeContext } from "../context/DarkThemeContext";
import { darkBlue, negativeRedColor, purple } from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useIsIncludes } from "common-components";
import { useIsMobileScreen } from "common-components";
import { XS_PLUS_FONT } from "../services/fonts";
import { useTranslation } from "react-i18next";

interface DemoExampleProps {
  height?: number | string;
  width?: number | string;
  ribbonLeft?: number | string;
  ribbonTop?: number | string;
  isSold?: number;
}
interface Props {
  width?: string | number;
  height?: string | number;
  ribbonLeft?: string | number;
  ribbonTop?: string | number;
  isDarkTheme?: boolean;
  isSold?: number;
}
const useStyles = makeStyles({
  container: (props: Props) => ({
    position: "absolute",
    display: "block",
    top: 0,
    right: 0,
    width: props.width ? props.width : 125,
    height: props.height ? props.height : 125,
    overflow: "hidden",
    zIndex: 0,
  }),
  ribbon: (props: Props) => ({
    position: "relative",
    display: "block",
    right: props.ribbonLeft ? props.ribbonLeft : 21,
    top: props.ribbonTop ? props.ribbonTop : 38,
    width: 189,
    padding: "5px 0",
    fontSize: XS_PLUS_FONT,
    textAlign: "center",
    boxShadow: "0px 0px 4px rgba(0,0,0,0.3)",
    transform: "rotate(45deg) translate3d(0,0,0)",
    backgroundColor: props.isDarkTheme
      ? props.isSold
        ? negativeRedColor
        : darkBlue
      : purple,
    color: "rgb(255, 255, 255)",
    letterSpacing: "0.6px",
    fontWeight: 600,
  }),
});
const DemoExample: React.FC<DemoExampleProps> = ({
  height,
  width,
  ribbonLeft,
  ribbonTop,
  isSold,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const classes = useStyles({
    width,
    height,
    isDarkTheme,
    ribbonLeft,
    ribbonTop,
    isSold,
  });

  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.ribbon}>
        {isSold ? `${t("Tabs.sold")}` : `${t("Tabs.reportDemo")}`}
      </div>
    </div>
  );
};
export default DemoExample;
