import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import {
  FONT_HEADER_LG,
  FONT_TITLE_LG,
  FONT_TITLE_XL,
} from "../../../global/services/constants/fonts";
import { white } from "../../../global/services/colors";
import { useIsLargeScreen } from "../../../global/hooks/useIsLargeScreen";

const useStyles = makeStyles({
  headerTitle: (props: { isDesktopScreen: boolean }) => ({
    color: white,
    fontSize: props.isDesktopScreen ? FONT_HEADER_LG : 30,
    fontWeight: 600,
  }),
  headerSubtitle: (props: { isDesktopScreen: boolean }) => ({
    color: white,
    fontSize: props.isDesktopScreen ? FONT_TITLE_XL : FONT_TITLE_LG,
    fontWeight: 600,
  }),
});

export const Title = () => {
  const isDesktopScreen = useIsLargeScreen("md");
  const classes = useStyles({ isDesktopScreen });
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      item
      xs={12}
      style={{ paddingRight: "20px" }}
    >
      <Grid container item xs={12}>
        <Grid
          container
          item
          justifyContent={!isDesktopScreen ? "center" : "flex-start"}
          xs={12}
          lg={4}
        >
          <Typography className={classes.headerTitle}>
            {t("header.ask")}
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent={!isDesktopScreen ? "center" : "flex-start"}
          xs={12}
          lg={8}
        >
          <Typography className={classes.headerTitle}>
            {t("header.pay")}
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent={!isDesktopScreen ? "center" : "flex-start"}
          xs={12}
        >
          <Typography className={classes.headerSubtitle}>
            {t("header.subtitle")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
