import {
  darkText,
  darkThemeSliderRail,
  mainPropColor,
} from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";
import { MD_FONT, XS_PLUS_FONT } from "../services/fonts";

export const useStylesTopBar = makeStyles({
  topBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  toolbar: {
    "&.MuiToolbar-gutters": {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
    "&.MuiToolbar-regular": {
      minHeight: 64,
    },
  },
  divHeaderActions: {
    width: "6rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingLeft: 15,
    "& ul li": {
      display: "inline",
    },
  },
  divStyleActions: {
    width: "auto",
    height: "100%",
    minHeight: 64,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  divUserActions: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    whiteSpace: "nowrap",
  },
  uLDropdownAvatar: (props) => ({
    width: 150,
    padding: 8,
    borderRadius: "var(--br-bottom)",
    position: "absolute",
    top: 64,
    right: props.isRtl ? 0 : "none",
    left: !props.isRtl ? 0 : "none",
    zIndex: 1000,
    boxShadow: "rgba(0, 0, 0, 0.12) 5px 4px 4px 0px",
    borderTop: "1px solid rgb(240 244 255 / 25%)",
  }),
  lIDropdownAvatar: (props) => ({
    color: props.isDarkTheme ? darkText : "#fff",
    borderRadius: "var(--border-radius)",
    display: "flex !important",
    justifyContent: "center",
    width: "100%",
    transition: "all 0.2s ease",

    "&:hover": {
      color: props.isDarkTheme ? darkText : mainPropColor,
      backgroundColor: props.isDarkTheme ? darkThemeSliderRail : "#f1f4fd",
      cursor: "pointer",
    },
    "&:hover button": {
      color: props.isDarkTheme ? darkText : mainPropColor,
    },
    "& button": {
      color: props.isDarkTheme ? darkText : "#fff",
    },
    "& button > *": {
      color: props.isDarkTheme ? darkText : "#fff",
    },
    "&:hover a": {
      color: props.isDarkTheme ? darkText : mainPropColor,
    },
    "&:hover p": {
      color: props.isDarkTheme ? darkText : mainPropColor,
    },
  }),
  buttonAvatarDropdown: {
    border: "none",
    backgroundColor: "transparent",
    padding: "8px 10px",
    cursor: "pointer",
    width: "100%",
    textAlign: "left",
    color: "white",
    transition: "all 0.3s ease",
    fontSize: MD_FONT,
  },
  divFlexCenter: {
    display: "flex",
    alignItems: "center",
  },
  divContainerLogo: {
    width: "4.8rem",
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
  },
  divBalanceContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 14px",
    borderRight: "1px solid #37405b",
    borderLeft: "1px solid #37405b",
  },
  divBalanceCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: { width: 20, height: 25 },
  divDetailsListContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    width: "fit-content",
  },
  divTopBar: {
    width: "calc(100% - 9rem)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  "@keyframes rtl": {
    "0%": () => ({
      left: "-100vw",
    }),
    "20%": () => ({
      left: 0,
    }),
    "80%": () => ({
      left: 0,
    }),
    "100%": () => ({
      left: "-100vw",
    }),
  },
  "@keyframes demo": {
    "0%": () => ({
      left: "-100vw",
    }),
    "100%": () => ({
      left: 0,
    }),
  },
  listingsBtn: {
    fontSize: XS_PLUS_FONT,
    transform: "scale(1)",
    animation: "$pulse 5s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1.10)",
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)",
    },
    "20%": { transform: "scale(1)", boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)" },
    "40%": {
      transform: "scale(1.10)",
      boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
    },
    "60%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
    },
    "80%": {
      transform: "scale(1.10)",
      boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
    },
    "100%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
    },
  },
});
