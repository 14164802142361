import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { ClassNameMap } from "@material-ui/styles/withStyles";
import Pagination from "@material-ui/lab/Pagination";
import { DataAddressesContext } from "../../context/DataAddressesContext";
import { fetchAcInacProp } from "../../outside-components/reports/TableRepost";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { LoadingButtons } from "../../components/layout/Layout";
import { darkMainPropColor, mainPropColor } from "../../services/colors";
import { DirectionContext } from "../../context/DirectionContext";

interface PaginationFooterProps {
  classes: ClassNameMap;
  count: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  isTableChart?: boolean;
  searchWord?: string;
  active?: boolean;
  sortBy?: string;
  type?: string[];
  order?: number;
  project?: string;
  building?: string;
}

const TablePaginationFooter: React.FC<PaginationFooterProps> = ({
  classes,
  count,
  setPage,
  page,
  isTableChart,
  searchWord,
  active,
  sortBy,
  type,
  order,
  project,
  building,
}) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useContext(DataAddressesContext);
  const [direction] = useContext(DirectionContext);

  return (
    <div className={classes.pagination}>
      <div style={{ padding: 10 }}>
        <Pagination
          page={page}
          count={count}
          onChange={(e, p) => {
            if (isTableChart) {
              fetchAcInacProp(
                direction,
                active,
                p,
                searchWord,
                type,
                sortBy,
                order,
                setAddresses,
                addresses,
                setPage,
                setLoading,
                project,
                building,
                isDarkTheme
              );
            } else {
              setPage(p);
            }
          }}
        />
      </div>
      {loading && (
        <LoadingButtons
          color={isDarkTheme ? darkMainPropColor : mainPropColor}
        />
      )}
    </div>
  );
};

export default TablePaginationFooter;
