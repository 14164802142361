import React from "react";
import { Button, Grid } from "@material-ui/core";
import { LoadingButtons } from "../layout/Layout";

const UserManagementButtons = ({ title, loading }) => {
  return (
    <Grid item xs={12} sm={4} style={{ maxWidth: "100%", flexBasis: "100%" }}>
      <Button type="submit" variant={"contained"} color="primary">
        {loading ? <LoadingButtons style={{ padding: 3 }} /> : title}
      </Button>
    </Grid>
  );
};

export default UserManagementButtons;
