import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { IMAGES_LINK } from "../api/api_links";
import { Auth } from "aws-amplify";

const useStyles = makeStyles({
  rootFacebook: {
    padding: "6px 8px",
    cursor: "pointer",
    borderRadius: 4,
    color: "#3b5998",
    border: "2px solid #3b5998",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgb(0 0 0 / 10%)",
    },
  },
  rootGoogle: {
    padding: "6px 8px",
    cursor: "pointer",
    borderRadius: 4,
    color: "#dd4b39",
    border: "2px solid #dd4b39",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgb(0 0 0 / 10%)",
    },
  },
});

const OAUTH_BUTTONS = () => {
  const classes = useStyles();

  return [
    {
      label: "Facebook",
      provider: CognitoHostedUIIdentityProvider.Facebook,
      img: "logSing-in/facebook-circle.svg",
      classes: { root: classes.rootFacebook },
      color: "#3b5998",
    },
    {
      label: "Google",
      provider: CognitoHostedUIIdentityProvider.Google,
      img: "elements/icon-google.svg",
      classes: {
        root: classes.rootGoogle,
        outlined: classes.outlined,
      },
      color: "#dd4b39",
    },
  ];
};

export const SocialLogin = () => {
  const handleSignIn = async (item) => {
    await Auth.federatedSignIn({
      provider: item.provider,
    });
  };
  
  return (
    <Grid
      container
      item
      xs={12}
      style={{ marginTop: 24 }}
      justifyContent="space-between"
    >
      {OAUTH_BUTTONS().map((item, idx) => {
        return (
          <Grid
            container
            justifyContent="space-around"
            item
            xs={5}
            classes={item.classes}
            onClick={() => handleSignIn(item)}
          >
            <img
              src={IMAGES_LINK + `/dashboard/${item.img}`}
              alt={item.label}
              width={20}
              height={20}
            />
            <Typography style={{ fontFamily: "Arial" }}>
              {item.label}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};
