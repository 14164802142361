import TilesContainer from "../../TilesContainer";
import { color3, darkPurple } from "../../../services/colors";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";
import React, { useContext } from "react";
import { DarkThemeContext } from "../../../context/DarkThemeContext";
import { useTranslation } from "react-i18next";

const ComparativePotential = ({ indexYourProp, comparison, data }) => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { t } = useTranslation();

  return (
    <TilesContainer
      style={{
        display: "flex",
        alignItems: "center",
        borderColor: isDarkTheme ? darkPurple : color3,
      }}
      relative
      padding={16}
    >
      <ErrorOutlineIcon
        style={{
          color: isDarkTheme ? darkPurple : color3,
          margin: 5,
        }}
      />
      <div>
        {indexYourProp !== undefined && (
          <Typography>
            {`${t("ComparativeNote.potentialText")}${
              comparison.toLowerCase() === `${t("countryValue")}`.toLowerCase()
                ? `${t("countryText")}`
                : `${t("surroundingsText")}`
            } ${t("ComparativeNote.is")} `}
            <span style={{ textTransform: "lowercase" }}>
              {t(`labels.${data.labels[indexYourProp]}`)}
            </span>
          </Typography>
        )}
      </div>
    </TilesContainer>
  );
};
export default ComparativePotential;
