import { IMAGES_LINK } from "../../api/api_links";

export const LoadingGif = ({ width = 150 }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={IMAGES_LINK + "/map/loading.svg"}
        alt={"loading"}
        width={width}
      />
    </div>
  );
};
