import React, { useContext, useState } from "react";
import {
  darkMainPropColor,
  darkThemePositiveGreen,
  mainPropColor,
  positiveGreenColor,
} from "../services/colors";
import { PropertyIdContext } from "../context/PropertyIdContext";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CreateIcon from "@material-ui/icons/Create";
import { doFetch } from "common-components";
import { addingComma } from "../services/helpers";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LG_FONT, LG_PLUS_FONT } from "../services/fonts";
import { useStylesRowBox } from "../styledComponent/RowBoxStyled";
import { useNotistack } from "common-components";
import { useIsIncludes } from "common-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../context/DirectionContext";
import { LoadingButtons } from "./layout/Layout";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { log } from "util";

const useStyles = makeStyles({
  icon: {
    color: darkMainPropColor,
    width: 22,
    height: 22,
  },
});

const AskingPrice: React.FC<{ price?: number; fullWidth?: boolean }> = ({
  price,
  fullWidth,
}) => {
  const [basePrice, setBasePrice] = useState(price);
  const [direction] = useContext(DirectionContext);
  const [isChangePrice, setIsChangePrice] = useState(false);
  const [propertyId] = useContext(PropertyIdContext);
  const [updatePrice, setUpdatePrice] = useState(price);
  const [error, setError] = useState(false);
  const isShare = useIsIncludes("share");
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  const classIcon = useStyles();
  const { t } = useTranslation();

  useNotistack(`${t("updateSucceedMsg")}`, isSaved, setIsSaved, "success");
  useNotistack(`${t("updateFailedMsg")}`, error, setError, "error");

  const onChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPrice = e.target.value;
    setUpdatePrice(Number(newPrice));
  };

  const handleSavePrice = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id") ? queryParams.get("id") : "-1";
    setLoading(true);
    doFetch(`/reports/premium/property/update?id=${id}`, {
      method: "POST",
      body: JSON.stringify({
        asking_price: updatePrice,
      }),
    }).then((data) => {
      if (data.message.toLowerCase() === "Premium Updated".toLowerCase()) {
        setIsSaved(true);
        setBasePrice(updatePrice);
        setLoading(false);
        setTimeout(() => {
          setIsChangePrice(false);
        }, 300);
      } else {
        setLoading(false);
        setError(true);
      }
    });
  };

  const classes = useStylesRowBox();
  return (
    <Grid container item xs={12} justify={"space-between"}>
      <Grid container alignItems="center" item xs={6} md={12}>
        <Typography variant={"h5"} align="left">
          {t("AskingPrice.title")}
        </Typography>
      </Grid>
      <Grid
        container
        justify={"flex-start"}
        alignItems="center"
        item
        xs={12}
        direction={"row"}
      >
        {isChangePrice ? (
          <input
            className={classes.inputAskingPrice}
            type="number"
            onChange={onChangePrice}
            value={updatePrice}
            style={{ maxWidth: 100, fontSize: LG_FONT }}
          />
        ) : (
          <Typography style={{ fontSize: LG_PLUS_FONT }}>{`${
            updatePrice === 0 ? "--" : addingComma(updatePrice)
          } ₪`}</Typography>
        )}
        {!isShare && (
          <IconButton
            style={{ marginRight: 5 }}
            // onClick={handleClickBtn}
          >
            {loading ? (
              <LoadingButtons
                color={darkMainPropColor}
                style={{ width: 18, height: 18 }}
              />
            ) : isSaved ? (
              <DoneAllIcon
                className={classIcon.icon}
                style={{
                  color: darkThemePositiveGreen,
                }}
              />
            ) : isChangePrice ? (
              basePrice === updatePrice ? (
                <ClearIcon
                  className={classIcon.icon}
                  onClick={() => setIsChangePrice(false)}
                />
              ) : (
                <CheckIcon
                  className={classIcon.icon}
                  onClick={() => handleSavePrice()}
                />
              )
            ) : (
              <CreateIcon
                className={classIcon.icon}
                onClick={() => setIsChangePrice(true)}
              />
            )}
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
export default AskingPrice;
