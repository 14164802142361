import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import React, { useContext, useEffect, useState } from "react";
import { useIsMobileScreen } from "common-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import { useHistory, useLocation } from "react-router-dom";
import ContactForm from "../../../dialogs/dialog-components/ContactForm";
import { useIsIncludes } from "common-components";
import MenuIcon from "@material-ui/icons/Menu";
import {
  borderColor,
  color3,
  darkBackground,
  darkBlue,
  darkBorderColor,
  darkText,
  lightBoxes,
  links,
  mainPropColor,
} from "../../../services/colors";
import {
  LG_FONT,
  LG_PLUS_FONT,
  MD_PLUS_FONT,
  XL_FONT,
  XS_PLUS_FONT,
} from "../../../services/fonts";
import Typography from "@material-ui/core/Typography";
import { Collapse } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import { detectVal, handleSwitch } from "../../layout/Layout";
import { Routes, RoutesUS } from "../../../services/routes";
import { handleSwitchTab } from "../../layout/ContentAreaLayout";
import UserLoginIframe from "../../../dialogs/login-singup/UserLoginIframe";
import { LANDING_PAGE_LINK } from "common-components";
import { isLoggedIn } from "../../../services/helpers";
import { useTranslation } from "react-i18next";
import { LanguageButton } from "common-components";
import useContextProps from "../../../hooks/useContextProps";
import { Login } from "common-components/src/global/components/login/Login";
import { AuthorizationContext } from "common-components/src/global/context/AuthorizationContext";
import { COMPS_LINK } from "common-components/src/global/api/api_links";

const useStyles = makeStyles({
  avatarContainer: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "calc(100% - 13px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  ulMobile: (props) => ({
    minWidth: 200,
    display: "flex",
    flexDirection: "column",

    "& li, p,a": {
      padding: "5px 20px",
      textAlign: "left",
      fontSize: LG_FONT,
      color: props.isDarkTheme && !props.isListings ? darkText : "black",
      cursor: "pointer",
    },
  }),

  ulTabs: {
    "& li p": {
      fontSize: MD_PLUS_FONT,
    },
  },
  avatarMobile: {
    color: "#fff",
    backgroundColor: "transparent",
    border: "1px solid #fff",
    cursor: "pointer",
    fontSize: LG_PLUS_FONT,
  },
  userName: {
    paddingTop: 5,
  },
  tabs: (props) => ({
    borderLeft: `1px solid ${
      props.isDarkTheme ? darkBorderColor : borderColor
    }`,
  }),
  border: (props) => ({
    borderBottom: `1px solid ${
      props.isDarkTheme ? darkBorderColor : lightBoxes
    }`,
  }),
  //TODO create 1 class ribbon
  ribbon: (props) => ({
    position: "relative",
    "&:after": {
      content: "attr(ribbonText)",
      position: "absolute",
      transform: "rotate(35deg) translate3d(0,0,0)",
      color: "white",
      fontSize: XS_PLUS_FONT,
      height: 15,
      width: 105,
      backgroundColor: props.isDarkTheme ? darkBlue : color3,
      right: "-35px",
      top: 5,
      paddingBottom: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
});

const AntTabs = withStyles({
  root: {
    marginLeft: 50,
  },
  indicator: {
    left: 0,
    backgroundColor: links,
  },
})(Tabs);

const HamburgerMenu = () => {
  const context = useContextProps();
  const [authorization] = useContext(AuthorizationContext);
  const [openTabs, setOpenTabs] = useState(true);
  const [tab, setTab] = useState(detectVal());
  const { openDialog } = useDialogManager();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const isMobileScreen = useIsMobileScreen();
  const classes = useStyles({ isDarkTheme: context.isDarkTheme });
  const isReports = useIsIncludes("reports");
  const isDashboard = useIsIncludes(Routes.dashboard);
  const isUser = useIsIncludes("user");
  let history = useHistory();
  const isDemo = useIsIncludes("demo");
  const isShare = useIsIncludes("share");

  const handleSwitchPages = (
    pathname,
    newValue,
    openAccess,
    property,
    page
  ) => {
    history.push({
      pathname: context.direction.region
        ? Routes[pathname]
        : RoutesUS[pathname],
    });
    context.setOpenAccess({
      access: openAccess,
      path: `property${property}`,
      page: page,
    });
    setIsOpenMenu(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  let location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setTab(detectVal());
  }, [location]);

  return (
    <>
      <MenuIcon
        onClick={() => {
          if (isMobileScreen) {
            setIsOpenMenu(!isOpenMenu);
          }
        }}
        style={{
          color: context.isDarkTheme ? darkText : "white",
          cursor: "pointer",
          fontSize: XL_FONT,
        }}
      />

      <SwipeableDrawer
        anchor={"right"}
        open={isOpenMenu && isMobileScreen}
        onClose={() => setIsOpenMenu(false)}
        onOpen={() => setIsOpenMenu(true)}
      >
        <div style={{ position: "relative", height: 115, width: 300 }}>
          <div
            style={{
              backgroundColor: context.isDarkTheme
                ? darkBackground
                : mainPropColor,
              height: 80,
              marginBottom: 20,
            }}
          />
          <div className={classes.avatarContainer}>
            <div className={classes.center}>
              <Avatar
                className={classes.avatarMobile}
                onClick={() => {
                  if (!context.userDetails)
                    openDialog(Login, { DialogProps: { maxWidth: "xs" } });
                }}
              >
                {context.userDetails?.initials}
              </Avatar>
              <Typography className={classes.userName}>
                {context.userDetails?.name}
              </Typography>
            </div>
          </div>
        </div>
        <ul className={classes.ulMobile}>
          <li
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: openTabs ? "#49a9f512" : "",
            }}
          >
            <Typography
              onClick={() => {
                if (
                  !context.isOpenAccess.access ||
                  context.isOpenAccess.page === "/listings"
                ) {
                  handleSwitchPages(`/dashboard/il`, 0, false, "", "");
                }
              }}
              style={{ color: isDashboard ? links : "" }}
            >
              {!context.isOpenAccess.access
                ? `${t("Tabs.dashboard")}`
                : context.isOpenAccess.page === "demo"
                ? "דוגמה" //TODO*add ltr
                : context.isOpenAccess.page === "/id"
                ? `${t("Tabs.listings")}`
                : isShare
                ? `${t("Tabs.dashboard")}`
                : ""}
            </Typography>
            {openTabs ? (
              <IconButton onClick={() => setOpenTabs(false)}>
                <CloseIcon fontSize={"small"} />
              </IconButton>
            ) : (
              <IconButton onClick={() => setOpenTabs(true)}>
                <AddIcon fontSize={"small"} />
              </IconButton>
            )}
          </li>

          <Collapse in={openTabs} timeout="auto" unmountOnExit>
            <AntTabs
              orientation="vertical"
              value={tab}
              onChange={handleChangeTab}
              className={classes.tabs}
            >
              <TabsLinks
                text={`${t("Tabs.summary")}`}
                callback={() =>
                  handleSwitchTab(
                    handleSwitch(
                      context.direction.region,
                      isDemo,
                      isShare,
                      "Summary"
                    ),
                    0,
                    context.propertyId,
                    history,
                    setTab,
                    setIsOpenMenu
                  )
                }
                selected={tab === 0}
              />
              <TabsLinks
                text={`${t("Tabs.impact")}`}
                callback={() =>
                  handleSwitchTab(
                    handleSwitch(
                      context.direction.region,
                      isDemo,
                      isShare,
                      "Impact"
                    ),
                    1,
                    context.propertyId,
                    history,
                    setTab,
                    setIsOpenMenu
                  )
                }
                selected={tab === 1}
              />
              <TabsLinks
                text={`${t("Tabs.comparisons")}`}
                callback={() =>
                  handleSwitchTab(
                    handleSwitch(
                      context.direction.region,
                      isDemo,
                      isShare,
                      "Comparisons"
                    ),
                    2,
                    context.propertyId,
                    history,
                    setTab,
                    setIsOpenMenu
                  )
                }
                selected={tab === 2}
              />
              <TabsLinks
                text={`${t("Tabs.loans")}`}
                callback={() =>
                  handleSwitchTab(
                    handleSwitch(
                      context.direction.region,
                      isDemo,
                      isShare,
                      "Loans"
                    ),
                    3,
                    context.propertyId,
                    history,
                    setTab,
                    setIsOpenMenu
                  )
                }
                selected={tab === 3}
              />

              <TabsLinks
                comingSoon={context.direction.region === "en"}
                text={`${t("Tabs.tama")}`}
                callback={() =>
                  handleSwitchTab(
                    handleSwitch(
                      context.direction.region,
                      isDemo,
                      isShare,
                      "Tama"
                    ),
                    4,
                    context.propertyId,
                    history,
                    setTab,
                    setIsOpenMenu
                  )
                }
                selected={tab === 4}
                disabled={context.disabledTabs.includes(Routes.dashboardTama)}
              />

              <TabsLinks
                comingSoon
                text={`${t("Tabs.rent")}`}
                callback={() =>
                  handleSwitchTab(
                    handleSwitch(
                      context.direction.region,
                      isDemo,
                      isShare,
                      "Rent"
                    ),
                    5,
                    context.propertyId,
                    history,
                    setTab,
                    setIsOpenMenu
                  )
                }
                selected={tab === 5}
              />
              <TabsLinks
                comingSoon
                text={`${t("Tabs.building-rights")}`}
                callback={() =>
                  handleSwitchTab(
                    handleSwitch(
                      context.direction.region,
                      isDemo,
                      isShare,
                      "BuildingRights"
                    ),
                    6,
                    context.propertyId,
                    history,
                    setTab,
                    setIsOpenMenu
                  )
                }
                selected={tab === 6}
              />
              <TabsLinks
                comingSoon
                text={`${t("Tabs.renovations")}`}
                callback={() =>
                  handleSwitchTab(
                    handleSwitch(
                      context.direction.region,
                      isDemo,
                      isShare,
                      "Renovations"
                    ),
                    7,
                    context.propertyId,
                    history,
                    setTab,
                    setIsOpenMenu
                  )
                }
                selected={tab === 7}
              />
            </AntTabs>
          </Collapse>

          {context.isOpenAccess.access && !isShare && (
            <li>
              <Typography
                onClick={() =>
                  handleSwitchPages(`/dashboard/il`, 0, false, "", "")
                }
              >
                {t("Tabs.dashboard")}
              </Typography>
            </li>
          )}
          {!isShare && !isDemo && (
            <>
              <li>
                <Typography
                  onClick={() => handleSwitchPages("reports", 1, false, "", "")} //TODO*check is working
                  style={{ color: isReports ? links : "" }}
                >
                  {t("Tabs.reports")}
                </Typography>
              </li>
              <li>
                <Typography
                  onClick={() =>
                    handleSwitchPages("user", 2, false, "", "/user")
                  } //TODO*check is working
                  style={{ color: isUser ? links : "" }}
                >
                  {t("Tabs.profile")}
                </Typography>
              </li>
            </>
          )}

          <div
            style={{
              margin: "20px 0",
              borderBottom: "1px solid #e6dddd",
            }}
          />
          <li>
            <a href={COMPS_LINK} target="_blank" rel="noreferrer">
              {t("Tabs.comps")}
            </a>
          </li>
          {authorization && (
            <li>
              <Typography onClick={() => openDialog(ContactForm)}>
                {t("Tabs.contactUs")}
              </Typography>
            </li>
          )}
          <li>
            <a href={LANDING_PAGE_LINK} rel="noreferrer">
              {t("Tabs.homePage")}
            </a>
          </li>
          <LanguageButton isMobile={true} context={context} />
          <li>
            {context.userDetails ? (
              <Typography
                onClick={() => {
                  onLogout();
                }}
              >
                {t("Tabs.logout")}
              </Typography>
            ) : (
              <>
                <Typography
                  onClick={() =>
                    openDialog(Login, { DialogProps: { maxWidth: "xs" } })
                  }
                >
                  {t("Tabs.login")}
                </Typography>
                {isDemo && (
                  <Typography
                    onClick={() =>
                      openDialog(Login, { DialogProps: { maxWidth: "xs" } })
                    }
                  >
                    {t("Tabs.signUp")}
                  </Typography>
                )}
              </>
            )}
          </li>
        </ul>
      </SwipeableDrawer>
    </>
  );
};
export default HamburgerMenu;

export function TabsLinks({ text, callback, selected, comingSoon, disabled }) {
  const context = useContextProps();
  const classes = useStyles({ isDarkTheme: context.isDarkTheme });
  const { t } = useTranslation();

  return (
    <Tab
      className={`${classes.border} ${comingSoon ? classes.ribbon : ""}`}
      onClick={() => callback()}
      disabled={disabled}
      ribbonText={t("beta")}
      label={
        <Typography
          style={{
            width: "100%",
            fontSize: MD_PLUS_FONT,
            color: selected ? links : "",
          }}
        >
          {text}
        </Typography>
      }
    />
  );
}

export function onLogout() {
  window.location.replace(`${LANDING_PAGE_LINK}/?logout=1`);
}
