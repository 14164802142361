import { useCountUp } from "react-countup";
import { TooltipInfoIcon } from "common-components";
import React, { useContext, useEffect } from "react";
import { useStylesRowBox } from "../../../styledComponent/RowBoxStyled";
import { useIsMobileScreen } from "common-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { DirectionContext } from "../../../context/DirectionContext";
import { DarkThemeContext } from "../../../context/DarkThemeContext";

const RowBoxPrice = ({ price, title, tooltipText, interactive = false }) => {
  const [direction] = useContext(DirectionContext);
  const [isDarkTheme] = useContext(DarkThemeContext);
  const { countUp, update } = useCountUp({
    start: 0,
    end: 0,
    delay: 1000,
    duration: 4,
    separator: ",",
    suffix: direction.isRtl ? ` ₪ ` : "",
    prefix: direction.isRtl ? "" : " ₪ ",
  });
  const classes = useStylesRowBox();
  const isSmallScreen = useIsMobileScreen();

  useEffect(() => {
    if (price) {
      update(price);
    }
  }, [price]);

  return (
    <Grid item xs={12}>
      <div className={classes.divTitleBox}>
        <Typography style={{ fontSize: "1.45rem" }}>{title}</Typography>

        <TooltipInfoIcon
          tooltipText={tooltipText}
          interactive={interactive}
          isDarkTheme={isDarkTheme}
        />
      </div>
      <div className={isSmallScreen ? classes.marginPoint : ""}>
        <div className={classes.divNumbers}>
          {!price || price === 0 ? (
            <Typography variant={"h5"}>
              &ensp;&#8212;&thinsp;&#8212; ₪
            </Typography>
          ) : (
            <Typography variant={"h5"}>{countUp}</Typography>
          )}
        </div>
      </div>
    </Grid>
  );
};
export default RowBoxPrice;
