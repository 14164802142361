import React, { createContext, useState } from "react";

export const DisabledTabsContext = createContext({});

export const DisabledTabsContextProvider = ({ children }) => {
  const [disabledTabs, setDisabledTabs] = useState([]);
  return (
    <DisabledTabsContext.Provider value={[disabledTabs, setDisabledTabs]}>
      {children}
    </DisabledTabsContext.Provider>
  );
};
