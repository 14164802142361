import {
  darkElements,
  darkMainPropColor,
  darkText,
  darkThemeSliderRail,
  greyAxis,
  lightBoxes,
} from "../services/colors";
import { makeStyles } from "@material-ui/core/styles";
import { MD_FONT } from "../services/fonts";

export const useStylesSearchPlaces = makeStyles({
  ulDropdownSearch: (props) => ({
    position: "absolute",
    background: props.isDarkTheme ? darkElements : lightBoxes,
    bottom: props.bottom === "bottom" ? "60px" : "inherit",
    listStyle: "none",
    borderRadius: "var(--border-radius)",
    margin: 0,
    padding: 0,
    width: 300,
    maxWidth: 300,
    maxHeight: 200,
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 14px 0px",
    zIndex: 3000,
  }),
  liDropdownSearch: (props) => ({
    fontSize: MD_FONT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
    color: props.isDarkTheme ? darkText : greyAxis,
    padding: "8px 12px",
    transition:
      "background 144ms ease-in-out 32ms, box-shadow 144ms ease-in 0ms",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      background: props.isDarkTheme
        ? darkThemeSliderRail
        : "rgb(255, 255, 255)",
      color: props.isDarkTheme ? darkText : greyAxis,
      boxShadow: "rgba(0, 0, 0, 0.08) 0px 6px 8px 0px",
      transition:
        "background 144ms ease-in-out 0s, box-shadow 144ms ease-in 32ms",
    },
  }),
  customTextFiled: (props) => ({
    "& label.Mui-focused": {
      color: props.isDarkTheme ? darkMainPropColor : "#0b1f64",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.isDarkTheme ? darkMainPropColor : "#0b1f64",
    },
    "& .MuiOutlinedInput-root": {
      borderColor: props.isDarkTheme ? darkMainPropColor : "#0b1f64",
      height: 36,
      "& fieldset": {
        borderColor: props.isDarkTheme ? darkMainPropColor : "#0b1f64",
      },
      "&:hover fieldset": {
        borderColor: props.isDarkTheme ? darkMainPropColor : "#0b1f64",
      },
      "&.Mui-focused fieldset": {
        borderColor: props.isDarkTheme ? darkMainPropColor : "#0b1f64",
      },
    },
  }),
});
