import { Redirect, Route } from "react-router-dom";
import Layout from "../layout/Layout";
import Reports from "../../outside-components/reports/Reports";
import UserManagementLayOut from "../usermanagement/UsermanagementLayout";
import ErrorPage from "../../outside-components/ErrorPage";
import React, { useContext } from "react";
import {
  Dashboard,
  DashboardUS,
  RedirectRoutes,
  RedirectRoutesUS,
  Routes,
  RoutesUS,
} from "../../services/routes";
import { DirectionContext } from "../../context/DirectionContext";
import { SuccessfulPurchase } from "common-components/src/global/components/subscription/purchase/SuccessfulPurchase";
import { ErrorPurchase } from "common-components/src/global/components/subscription/purchase/ErrorPurchase";

const SwitchRoutes = () => {
  const [direction] = useContext(DirectionContext);
  const israelRegion = direction.region === "il";
  return (
    <>
      <Route
        exact
        path={[Routes.dashboard, "/", "/dashboard/", RoutesUS.dashboard]}
      >
        <Redirect
          push
          to={
            israelRegion ? Routes.dashboardSummary : RoutesUS.dashboardSummary
          }
        />
      </Route>
      <Route exact path={Dashboard.concat(DashboardUS)}>
        <Layout />
      </Route>
      <Route exact path={"/demo"}>
        <Redirect
          push
          to={israelRegion ? Routes.demoSummary : RoutesUS.demoSummary}
        />
      </Route>
      <Route exact path={[Routes.dashboard, RoutesUS.dashboard]}>
        <Layout />
      </Route>
      <Route exact path="/reports">
        <Redirect push to={israelRegion ? Routes.reports : RoutesUS.reports} />
      </Route>
      <Route exact path={[Routes.user, RoutesUS.user]}>
        <UserManagementLayOut />
      </Route>
      <Route exact path={[Routes.reports, RoutesUS.reports]}>
        <Reports />
      </Route>
      <Route path={[Routes.error, RoutesUS.error]}>
        <ErrorPage />
      </Route>
      <Route
        path="*"
        children={({ match }) => {
          if (
            match &&
            !RedirectRoutes.concat(RedirectRoutesUS).includes(match.url)
          ) {
            return (
              <Redirect
                push
                to={
                  israelRegion
                    ? Routes.dashboardSummary
                    : RoutesUS.dashboardSummary
                }
              />
            );
          }
        }}
      />
    </>
  );
};
export default SwitchRoutes;
