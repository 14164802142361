import React, { useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { useDialogManager } from "common-components/src/global/hooks/useDialogManager";
import Payment, { purchaseProperty } from "../payment/Payment";
import {
  doFetch,
  DOMAIN,
  LANDING_PAGE_LINK,
  REPORTS_LINK,
  useIsMobileScreen,
} from "common-components";
import { useHistory, useLocation } from "react-router-dom";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useStylesPurchasePlan } from "../../styledComponent/PurchasePlanStyled";
import {
  color3,
  darkText,
  darkThemeNegativeRed,
  mainPropColor,
} from "../../services/colors";
import { ToggleSlider } from "common-components";
import Typography from "@material-ui/core/Typography";
import { LG_PLUS_FONT, MD_PLUS_FONT } from "../../services/fonts";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "../../context/DirectionContext";
import { FramePurchase } from "common-components/src/global/components/subscription/purchase/FramePurchase";
import Cookies from "js-cookie";
import { PurchasePropertyContext } from "../../context/PurchasePropertyContext";
import { Purchase } from "common-components/src/global/components/subscription/purchase/Purchase";

export const handlePurchaseIframe = (
  closeDialog,
  openDialog,
  direction,
  property,
  setProperty,
  t,
  planId
) => {
  window.addEventListener("message", (event) => {
    const msg = event.data;
    if (msg === "close-iframe-success") {
      let frameToRemove = document.getElementById("purchase-iframe");
      if (frameToRemove) {
        frameToRemove.remove();
        closeDialog();
        if (property.type) {
          purchaseProperty(direction.api, property, openDialog, t, setProperty);
        }
      }
    } else if (msg === "close-iframe-err") {
      console.log("error");
      handleIframe(openDialog, planId);
    }
  });
};

export const handleIframe = (openDialog, planId) => {
  const lang = Cookies.get("lang", {
    domain: DOMAIN,
  });

  doFetch(
    `il/purchase/package/iframe-url?lang=${lang}&plan_id=reports_il_${planId}`,
    {
      method: "GET",
    }
  ).then(async (res) => {
    const { url } = res;
    openDialog(FramePurchase, {
      DialogProps: { maxWidth: "sm" },
      DialogContentProps: {
        src:
          url +
          `&success_url_address=${LANDING_PAGE_LINK}/il/payment/success&fail_url_address=${LANDING_PAGE_LINK}/il/payment/error`,
      },
    });
  });
};

export function PricingCard({
  setSelectedPlan,
  selectedPlan,
  options,
  title,
  icon,
}) {
  const [direction] = useContext(DirectionContext);
  const { openDialog, closeDialog } = useDialogManager();
  const [isDarkTheme] = useContext(DarkThemeContext);
  const isMobileScreen = useIsMobileScreen();
  const color = isDarkTheme ? darkText : mainPropColor;
  let { price, properties, days, months, saving, planId, text } = {};
  let currentPlan = {};
  const { t } = useTranslation();
  const [property, setProperty] = useContext(PurchasePropertyContext);

  // useEffect(() => {
  //   handlePurchaseIframe(
  //     closeDialog,
  //     openDialog,
  //     direction,
  //     property,
  //     setProperty,
  //     t,
  //     planId
  //   );
  // }, []);

  for (let option of options) {
    if (option.title === selectedPlan)
      ({
        price,
        properties,
        days,
        months,
        saving,
        planId,
        text,
      } = currentPlan = option);
  }
  const props = { isDarkTheme: isDarkTheme };
  const classes = useStylesPurchasePlan(props);

  return (
    <Grid container item xs={isMobileScreen ? 12 : 6} style={{ width: 550 }}>
      <Grid container item xs={12} className={classes.gridPriceCardContainer}>
        <Grid
          item
          style={{
            margin: "32px auto",
          }}
        >
          <Typography style={{ fontWeight: "bold", fontSize: MD_PLUS_FONT }}>
            {title}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify={"center"}
          spacing={1}
          style={{
            padding: direction.isRtl
              ? "0px 0px 0px 9px"
              : isMobileScreen
              ? "0 20px"
              : "0 0 0 8px",
          }}
        >
          <ToggleSlider
            radius
            value={selectedPlan}
            setValue={setSelectedPlan}
            widthContainer={280}
            widthRoot={70}
            font={direction.isRtl ? false : "0.7rem"}
            list={options.map((option) => ({
              value: option.title,
              text: option.text,
            }))}
            isDarkTheme={isDarkTheme}
          />
        </Grid>

        <Grid item xs={12} style={{ justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            {planId < 8 ? (
              <Typography
                className={classes.paragraphPrice}
                style={{ fontSize: "5rem", color: color }}
              >
                {price}
              </Typography>
            ) : null}
            {1 <= planId && planId <= 4 ? (
              <Typography
                className={classes.paragraphPriceForProp}
                style={{ color: color }}
              >
                &thinsp;{t("PricingCard.propertyPrice")}&thinsp;
              </Typography>
            ) : 5 <= planId && planId <= 7 ? (
              <Typography
                className={classes.paragraphPriceForProp}
                style={{ color: color }}
              >
                &thinsp;{t("PricingCard.monthlyPrice")}&thinsp;
              </Typography>
            ) : null}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          justify={"center"}
          alignItems={"center"}
          style={{ marginBottom: "-10px", marginTop: "-12px" }}
        >
          {saving && (
            <Grid
              container
              item
              spacing={1}
              alignItems={"center"}
              justify={"center"}
            >
              <Grid item>
                <Typography style={{ fontSize: MD_PLUS_FONT, color: color }}>
                  {properties > 49
                    ? `${t("PricingCard.msg1")}` +
                      properties +
                      `${t("PricingCard.msg2")}`
                    : saving + `${t("PricingCard.msg3")}`}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <div style={{ width: "100%", marginTop: "18px" }}>
          <SelectPlanButton options={currentPlan} openDialog={openDialog} />
        </div>
      </Grid>
    </Grid>
  );
}

function SelectPlanButton({ options, openDialog }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { price, months, properties, planId, packageName } = options;
  let location = useLocation();
  const locationPrice = {
    pathname: location.pathname,
    search: location.search,
    hash: `select_${planId}`,
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
      }}
    >
      <Button
        color={"primary"}
        variant={"contained"}
        style={{
          width: "80%",
          fontSize: MD_PLUS_FONT,
          transition: "all 0.5s",
        }}
        onClick={() => {
          history.push(locationPrice);
          pricingToSessionStorage(planId, price, months, properties);
          // handleIframe(openDialog, planId);
          openDialog(Purchase, {
            DialogProps: { maxWidth: "sm" },
            DialogContentProps: {
              projectName: "reports-il",
              currentPlan: {
                name: packageName,
                price: price * properties,
                title: "",
              },
            },
          });
        }}
      >
        {t("PricingCard.buttonBuy")}
      </Button>
    </div>
  );
}
export function pricingToSessionStorage(planId, price, months = 0, properties) {
  sessionStorage.setItem("planId", planId);
  if (months) {
    const res = Number(price) * months;
    sessionStorage.setItem("price", res.toString());
    sessionStorage.setItem("amount", properties);
    sessionStorage.setItem("period", "months");
  } else if (properties) {
    const res = Number(price) * properties;
    sessionStorage.setItem("price", res.toString());
    sessionStorage.setItem("amount", properties);
    sessionStorage.setItem("period", "days");
  } else {
    sessionStorage.setItem("price", price);
    sessionStorage.setItem("amount", "1");
    sessionStorage.setItem("period", "days");
  }
}
