import ShareButton from "../../outside-components/reports/cells/ShareButton";
import Fab from "@material-ui/core/Fab";
import { useContext } from "react";
import { PropertyIdContext } from "../../context/PropertyIdContext";
import { darkText } from "../../services/colors";
import { DarkThemeContext } from "../../context/DarkThemeContext";
import { useIsIncludes } from "common-components";
import { useIsMobileScreen } from "common-components";

const FabShareButton = () => {
  const [isDarkTheme] = useContext(DarkThemeContext);
  const [propertyId] = useContext(PropertyIdContext);
  const isDemo = useIsIncludes("demo");
  const isMobileScreen = useIsMobileScreen();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id") ? queryParams.get("id") : "-1";

  return (
    <div
      style={{
        position: "fixed",
        bottom: isMobileScreen ? 100 : 25,
        zIndex: 1099,
        left: isMobileScreen ? 24 : 70,
      }}
    >
      <Fab
        disabled={isDemo}
        variant="extended"
        size="medium"
        color={isDarkTheme ? "secondary" : "primary"}
        aria-label="add"
      >
        <ShareButton id={id} color={isDemo ? "#00114591" : darkText} />
      </Fab>
    </div>
  );
};
export default FabShareButton;
