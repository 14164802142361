export const API_SERVER_URL = `https://api.${process.env.REACT_APP_BASE_URL}`;

export const IMAGES_LINK = `https://${process.env.REACT_APP_BASE_URL}/images`;

// export const LANDING_PAGE_ORIGIN = `https://${process.env.REACT_APP_BASE_URL}`;
export const LANDING_PAGE_LINK = `https://${process.env.REACT_APP_BASE_URL}`;

// export const DASHBOARD_LINK = `https://app.${process.env.REACT_APP_BASE_URL}`;
export const REPORTS_LINK = `https://app.${process.env.REACT_APP_BASE_URL}`;

export const DOMAIN = window.location.host.includes("localhost")
  ? "localhost"
  : `.${process.env.REACT_APP_BASE_URL}`;

export const MAPBOX_KEY = process.env.REACT_APP_MAPBOX_KEY;
export const GOOGLE_KEY = window.location.host.includes("localhost")
  ? process.env.REACT_APP_GOOGLE_KEY_LOCAL_HOST
  : process.env.REACT_APP_GOOGLE_KEY;
export const TOKEN_EXPIRED_MSG = "The incoming token has expired";
export const NOT_FOUND_MSG = "Property Not Found";
export const SHARE_NOT_FOUND_MSG = "Shared Property Not Found";
export const SHARE_EXPIRED_MSG = "Shared Property Link Expired";
export const FOLDERS_REMOVED_MSG = "Folders Removed";
export const FOLDERS_CANNOT_REMOVED_MSG =
  "Cannot Remove Folder - Property Linked";
export const UNAUTHORIZED = "Unauthorized";
