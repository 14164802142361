import { date } from "../dialogs/payment/Payment";
import { PropDetailImg } from "../outside-components/reports/TableReportsDetails";
import { PropTypeIcon } from "../dialogs/payment/PropAddedPayment";
import { Rating } from "@material-ui/lab";
import React from "react";
import {
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_PRIVATE_HOUSE,
  PROPERTY_TYPE_TOWN_HOUSE,
} from "../dialogs/add-prop/SelectPropDetails";

export const ANIMATION_DURATION = 1000;

export function convertBuildingRightsLevel(english, t) {
  if (english === "standard") {
    return `${t("Const.standard")}`;
  } else if (english === "above") {
    return `${t("Const.above")}`;
  } else if (english === "luxury") {
    return `${t("Const.luxury")}`;
  }
}

export function convertParking(english, t) {
  if (english === "without") {
    return `${t("Const.without")}`;
  } else if (english === "single") {
    return `${t("Const.singleParking")}`;
  } else if (english === "double") {
    return `${t("Const.doubleParking")}`;
  } else if (english === "serial") {
    return `${t("Const.serialParking")}`;
  } else if (english === "**") {
    return "**";
  }
}

export function propDetailsList(
  property_details,
  isDarkTheme,
  classIcon,
  t,
  isListings
) {
  if (property_details[0] === PROPERTY_TYPE_LAND()) {
    return [
      {
        item: property_details[0],
        label: `${t("Const.propertyType")}`,
        Icon: (
          <PropTypeIcon
            item={property_details[0]}
            style={{
              width: 20,
              height: 25,
            }}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[1],
        label: `${t("Const.area")} ${t("areaUnit")}`,
        Icon: (
          <PropDetailImg
            name={"scale"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[2],
        label: `${t("Const.buildingRights")}`,
        Icon: (
          <PropDetailImg
            name={"building_rights_sqm"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: convertBuildingRightsLevel(property_details[3], t),
        label: `${t("Const.buildingRightsLevel")}`,
        Icon: (
          <PropDetailImg
            name={"building_rights_level"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[4],
        label: `${t("Const.buildingRightsApproval")}`,
        Icon: (
          <PropDetailImg
            name={"building_rights_approval"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: `${t("Const.yes")}`,
      },
    ];
  } else {
    return [
      {
        item:
          property_details && property_details.nickname
            ? property_details.nickname
            : undefined,
        label: `${t("Const.type")}`,
        Icon: (
          <PropDetailImg
            name={"user"}
            classes={classIcon}
            isDarkTheme={false}
          />
        ),
        text: false,
      },
      {
        item: t(`PropertyTypes.${property_details[0]}`),
        label: `${t("Const.propertyType")}`,
        Icon: (
          <PropTypeIcon
            item={property_details[0]}
            style={{
              width: 20,
              height: 25,
            }}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[1],
        label: `${t("Const.propertyCondition")}`,
        Icon: (
          <PropDetailImg
            name={"star"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: isListings ? (
          "**"
        ) : (
          <Rating
            name="read-only"
            value={property_details[1]}
            precision={1}
            size="small"
            readOnly
          />
        ),
      },

      {
        item: property_details[2],
        label: `${t("Const.area")} ${t("areaUnit")}`,
        Icon: (
          <PropDetailImg
            name={"scale"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[3],
        label: `${t("Const.yearBuilt")}`,
        Icon: (
          <PropDetailImg
            name={"brickwall"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[4],
        label: `${t("Const.floor")}`,
        Icon: (
          <PropDetailImg
            name={"stairs"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },

      {
        item: property_details[5],
        label: `${t("Const.totalFloors")}`,
        Icon: (
          <PropDetailImg
            name={"totalFloors"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[6],
        label: `${t("Const.numberRooms")}`,
        Icon: (
          <PropDetailImg
            name={"room"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[7],
        label: `${t("Const.elevator")}`,
        Icon: (
          <PropDetailImg
            name={"elevator"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text:
          property_details && property_details[7] === "**"
            ? property_details[7]
            : `${t("Const.yes")}`,
      },
      {
        item: convertParking(property_details[8], t),
        label: `${t("Const.parkingSpace")}`,
        Icon: (
          <PropDetailImg
            name={"parking"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[9],
        label: `${t("Const.storage")}`,
        Icon: (
          <PropDetailImg
            name={"store"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text:
          property_details && property_details[9] === "**"
            ? property_details[9]
            : `${t("Const.yes")}`,
      },
      {
        item: property_details[10],
        label: `${t("Const.secureRoom")}`,
        Icon: (
          <PropDetailImg
            name={"secured-room"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text:
          property_details && property_details[10] === "**"
            ? property_details[10]
            : `${t("Const.yes")}`,
      },
      {
        item: property_details[11],
        label: `${t("Const.balconyArea")} ${t("areaUnit")}`,
        Icon: (
          <PropDetailImg
            name={"balcony"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[12],
        label: `${t("Const.roofArea")}`,
        Icon: (
          <PropDetailImg
            name={"roof"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[13],
        label: `${t("Const.gardenArea")}`,
        Icon: (
          <PropDetailImg
            name={"fence"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[14],
        label: `${t("Const.buildingRights")}`,
        Icon: (
          <PropDetailImg
            name={"building_rights_sqm"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: convertBuildingRightsLevel(property_details[15], t),
        label: `${t("Const.buildingRightsLevel")}`,
        Icon: (
          <PropDetailImg
            name={"building_rights_level"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: false,
      },
      {
        item: property_details[16],
        label: `${t("Const.buildingRightsApproval")}`,
        Icon: (
          <PropDetailImg
            name={"building_rights_approval"}
            classes={classIcon}
            isDarkTheme={isDarkTheme}
          />
        ),
        text: `${t("Const.yes")}`,
      },
    ];
  }
}
export function selectPropDetailsILAll(watch, propTypeVal, t) {
  return [
    {
      label: `${t("Const.propertyArea")}*`,
      name: "sqm",
      placeholder: `${t("Const.propertyArea")}`,
      inputMode: "numeric",
      register: {
        required: "area is required",
        validate: {
          minAll: (value) => Number(value) >= 20,
          maxAll: (value) => {
            const valueSqm = Number(value);
            if (propTypeVal === PROPERTY_TYPE_TOWN_HOUSE()) {
              return valueSqm <= 500;
            } else if (propTypeVal === PROPERTY_TYPE_PRIVATE_HOUSE()) {
              return valueSqm <= 800;
            } else {
              return valueSqm <= 250;
            }
          },
        },
        minCalc: (value) => Number(value) / Number(watch("num_rooms")) >= 8,
        maxCalc: (value) => Number(value) / Number(watch("num_rooms")) <= 80,
      },
      errors: [
        { type: "required", text: `${t("Const.required")}` },
        {
          type: "minAll",
          text: `${t("Const.minAllDetailAll")} ${
            propTypeVal === PROPERTY_TYPE_TOWN_HOUSE()
              ? 500
              : propTypeVal === PROPERTY_TYPE_PRIVATE_HOUSE()
              ? 800
              : 250
          }`,
        },
        {
          type: "maxAll",
          text: `${t("Const.maxAllDetailAll")} ${
            propTypeVal === PROPERTY_TYPE_TOWN_HOUSE()
              ? 500
              : propTypeVal === PROPERTY_TYPE_PRIVATE_HOUSE()
              ? 800
              : 250
          }`,
        },
        { type: "minCalc", text: `${t("Const.minCalcDetailAll")}` },
        { type: "maxCalc", text: `${t("Const.maxCalcDetailAll")}` },
      ],
    },
    {
      label: `${t("Const.numberRooms")}*`,
      name: "num_rooms",
      placeholder: `${t("Const.numberRooms")}`,
      inputMode: "decimal",
      register: {
        required: "rooms is required",
        validate: {
          minVal: (value) => Number(value) >= 1,
          maxVal: (value) => Number(value) <= 12,
          halfVal: (value) =>
            Number(value) % 0.5 === 0 || Number(value) % 1 === 0,
        },
      },
      errors: [
        {
          type: "required",
          text: `${t("Const.required")}`,
        },
        { type: "minVal", text: `${t("Const.roomsPattern")}` },
        { type: "maxVal", text: `${t("Const.roomsPattern")}` },
        { type: "halfVal", text: `${t("Const.roomsPattern")}` },
      ],
    },
    {
      label: `${t("Const.yearBuilt")}*`,
      name: "year_built",
      placeholder: `${t("Const.yearBuilt")}`,
      inputMode: "numeric",
      register: {
        required: `${t("Const.required")}`,
        validate: {
          minYear: (value) => Number(value) >= 1900,
          maxYear: (value) => Number(value) <= date.getFullYear() + 10,
        },
      },
      errors: [
        {
          type: "required",
          text: `${t("Const.required")}`,
        },
        {
          type: "minYear",
          text: `${t("Const.minYear")}`,
        },
        {
          type: "maxYear",
          text: `${t("Const.maxYear")} ` + `${date.getFullYear() + 10}`,
        },
      ],
    },
  ];
}
export function selectPropDetailsILApartment_Penthouse(watch, t) {
  return [
    {
      label: `*${t("Const.floor")}`,
      name: "floor",
      placeholder: `${t("Const.floor")}`,
      inputMode: "numeric",
      register: {
        required: t("Const.required"),
        validate: {
          minFloor: (value) => Number(value) >= -1,
          maxFloor: (value) => Number(value) <= 68,
        },
      },
      errors: [
        {
          type: "required",
          text: t("Const.required"),
        },
        {
          type: "minFloor",
          text: `${t("Const.floorPattern")}`,
        },
        {
          type: "maxFloor",
          text: `${t("Const.floorPattern")}`,
        },
      ],
    },
    {
      label: `*${t("Const.totalFloors")}`,
      name: "num_floors",
      placeholder: `${t("Const.totalFloors")}`,
      inputMode: "numeric",
      register: {
        required: t("Const.required"),
        validate: {
          minFloor: (value) => Number(value) >= 1,
          maxFloor: (value) => Number(value) <= 68,
          watchFloor: (value) => Number(value) >= Number(watch("floor")),
        },
      },
      errors: [
        {
          type: "required",
          text: t("Const.required"),
        },
        {
          type: "minFloor",
          text: `${t("Const.numFloorsPattern")}`,
        },
        {
          type: "maxFloor",
          text: `${t("Const.numFloorsPattern")}`,
        },
        {
          type: "watchFloor",
          text: `${t("Const.numFloorsValidate")}`,
        },
      ],
    },
  ];
}
export function selectPropDetailsILNotPrivateHouse(t) {
  return [
    {
      label: `${t("Const.balconyArea")}`,
      name: "sqm_balcony",
      placeholder: `${t("Const.balconyArea")}`,
      inputMode: "numeric",
      register: {
        validate: {
          min: (value) => Number(value) >= 5 || Number(value) === 0,
          max: (value) => Number(value) <= 250,
        },
      },
      errors: [
        { type: "min", text: `${t("Const.minBalconyArea")}` },
        { type: "max", text: `${t("Const.maxBalconyArea")}` },
      ],
    },
  ];
}
export function selectPropDetailsILPrivate_TownHouse(t) {
  return [
    {
      label: `*${t("Const.totalFloors")}`,
      name: "num_floors",
      placeholder: `${t("Const.totalFloors")}`,
      inputMode: "numeric",
      register: {
        required: t("Const.required"),
        validate: {
          minFloor: (value) => Number(value) >= 1,
          maxFloor: (value) => Number(value) <= 68,
        },
      },
      errors: [
        {
          type: "required",
          text: t("Const.required"),
        },
        {
          type: "minFloor",
          text: `${t("Const.numFloorsPattern")}`,
        },
        {
          type: "maxFloor",
          text: `${t("Const.numFloorsPattern")}`,
        },
      ],
    },
    {
      label: `${t("Const.gardenArea")} ${t("areaUnit")}`,
      name: "sqm_garden",
      inputMode: "numeric",
      placeholder: `${t("Const.gardenArea")} ${t("areaUnit")}`,
      register: {
        validate: {
          min: (value) => Number(value) >= 5 || Number(value) === 0,
          max: (value) => Number(value) <= 1000,
        },
      },
      errors: [
        { type: "min", text: `${t("Const.minGardenArea")}` },
        { type: "max", text: `${t("Const.minGardenArea")}` },
      ],
    },
  ];
}
export function selectPropDetailsILPenthouse(t) {
  return [
    {
      label: `${t("Const.roofArea")}`,
      name: "sqm_balcony_roof",
      placeholder: `${t("Const.roofArea")}`,
      inputMode: "numeric",
      register: {
        validate: {
          min: (value) => Number(value) >= 5 || Number(value) === 0,
          max: (value) => Number(value) <= 500,
        },
      },
      errors: [
        { type: "min", text: `${t("Const.minRoofArea")}` },
        { type: "max", text: `${t("Const.maxRoofArea")}` },
      ],
    },
  ];
}

export function selectPropDetailsILGarden(t) {
  return [
    {
      label: `*${t("Const.totalFloors")}`,
      name: "num_floors",
      placeholder: `${t("Const.totalFloors")}`,
      inputMode: "numeric",
      register: {
        required: t("Const.required"),
        validate: {
          minFloor: (value) => Number(value) >= 1,
          maxFloor: (value) => Number(value) <= 68,
        },
      },
      errors: [
        {
          type: "required",
          text: t("Const.required"),
        },
        {
          type: "minFloor",
          text: `${t("Const.numFloorsPattern")}`,
        },
        {
          type: "maxFloor",
          text: `${t("Const.numFloorsPattern")}`,
        },
      ],
    },
    {
      label: `${t("Const.gardenArea")}`,
      name: "sqm_garden",
      placeholder: `${t("Const.gardenArea")}`,
      inputMode: "numeric",
      register: {
        validate: {
          min: (value) => Number(value) >= 5 || Number(value) === 0,
          max: (value) => Number(value) <= 500,
        },
      },
      errors: [
        { type: "min", text: `${t("Const.minGardenAptArea")}` },
        { type: "max", text: `${t("Const.minGardenAptArea")}` },
      ],
    },
  ];
}

export function selectPropDetailsILExpanded(t) {
  return [
    {
      label: `${t("Const.buildingRights")}`,
      name: "building_rights_sqm",
      placeholder: `${t("Const.quantity")} ${t("areaUnit")}`,
      inputMode: "numeric",
      register: {
        validate: /^\d+$/,
        pattern: /^([1-9][0-9]{0,2}|1000)$/, //0-1000
      },
      errors: [
        {
          type: "validate",
          text: `${t("Const.buildingRightsValidate")}`,
        },
        { type: "pattern", text: `${t("Const.buildingRightsPattern")}` },
      ],
    },
  ];
}

export function selectPropDetailsLand(watch, t) {
  return [
    {
      label: `${t("Const.landArea")} ${t("areaUnit")}*`,
      name: "sqm",
      placeholder: `${t("Const.landArea")} ${t("areaUnit")}`,
      inputMode: "numeric",
      register: {
        required: "area is required",
        validate: {
          minAll: (value) => Number(value) >= 20,
          maxAll: (value) => Number(value) <= 800,
        },
      },
      errors: [
        { type: "required", text: `${t("Const.required")}` },
        { type: "minAll", text: `${t("Const.minAllLand")}` },
        { type: "maxAll", text: `${t("Const.maxAllLand")}` },
        { type: "minCalc", text: `${t("Const.minCalcLand")}` },
        { type: "maxCalc", text: `${t("Const.maxCalcLand")}` },
      ],
    },
    {
      label: `${t("Const.buildingRights")}`,
      name: "building_rights_sqm",
      placeholder: `${t("Const.quantity")} ${t("areaUnit")}`,
      inputMode: "numeric",
      register: {
        validate: /^\d+$/,
        pattern: /^([1-9][0-9]{0,2}|1000)$/, //0-1000
      },
      errors: [
        {
          type: "validate",
          text: `${t("Const.buildingRightsValidate")}`,
        },
        { type: `${t("Const.buildingRightsPattern")}` },
      ],
    },
  ];
}

export function checkboxIlAll(t) {
  return [
    {
      name: "bomb_shelter",
      label: `${t("Const.bombShelter")}`,
    },
    {
      name: "storage",
      label: `${t("Const.storage")}`,
    },
  ];
}

//TAMA
export function tamaInputsOne(t, tamaCode, originalArea) {
  return [
    {
      id: "sqm",
      name: "sqm",
      placeholder: t("Tama.size"),
      inputMode: "numeric",
      register:
        tamaCode === 1 || tamaCode === 0
          ? {
              required: "required",
              pattern: /^\d+$/,
              validate: {
                positive: (value) => Number(value) >= 0,
                lessThanTen: (value) => Number(value) <= 50,
              },
            }
          : {
              required: "required",
              pattern: /^\d+$/,
              validate: {
                positive: (value) => Number(value) >= originalArea,
                lessThanTen: (value) => Number(value) <= originalArea + 50,
              },
            },
      errors: [
        {
          type: "pattern",
          text: t("Tama.int"),
        },
        {
          type: "positive",
          text:
            tamaCode === 1 || tamaCode === 0 ? (
              t("Tama.rule50")
            ) : (
              <bdi>
                {t("Tama.shouldBeBetween")} {originalArea} - {originalArea + 50}{" "}
                {t("meter")}{" "}
              </bdi>
            ),
        },
        {
          type: "lessThanTen",
          text:
            tamaCode === 1 || tamaCode === 0 ? (
              t("Tama.rule50")
            ) : (
              <bdi>
                {t("Tama.shouldBeBetween")} {originalArea + 50} - {originalArea}{" "}
                {t("meter")}{" "}
              </bdi>
            ),
        },
      ],
    },
    {
      id: "sqm_balcony",
      name: "sqm_balcony",
      placeholder: t("Tama.terrace"),
      inputMode: "numeric",
      register: {
        validate: {
          positive: (value) => Number(value) >= 0,
          lessThanTen: (value) => Number(value) <= 50,
        },
      },
      errors: [
        {
          type: "lessThanTen",
          text: t("Tama.rule50"),
        },
      ],
    },
  ];
}
export function tamaInputsTwo(t, tamaCode, num_rooms, watch) {
  return [
    {
      id: "num_rooms",
      name: "num_rooms",
      placeholder: t("Tama.numRooms"),
      inputMode: "decimal",
      register: {
        required: "rooms is required",
        pattern: /^(?:\d*\.(?:5?)|(1[0-5]|[1-9]))$/, //1(.5)-15
        validate: {
          positive: (value) => Number(value) > 0,
          moreThenCurrent: (value) => Number(value) >= num_rooms,
        },
      },
      errors: [
        {
          type: "positive",
          text: t("Tama.rule0rooms"),
        },
        {
          type: "moreThenCurrent",
          text: (
            <bdi>
              {t("Tama.shouldBe")} {num_rooms} {t("Tama.orMoreRooms")}
            </bdi>
          ),
        },
        { type: "pattern", text: t("Tama.noValid") },
      ],
    },
    {
      id: "floor",
      name: "floor",
      placeholder: t("Tama.floor"),
      inputMode: "numeric",
      register: {
        validate: {
          positive: (value) => Number(value) >= -1,
        },
      },
      errors: [
        {
          type: "positive",
          text: t("Tama.range"),
        },
      ],
    },
    {
      id: "totalFloors",
      name: "totalFloors",
      inputMode: "numeric",
      placeholder: t("Tama.totalFloors"),
      register: {
        validate: {
          watch: (value) => Number(value) >= Number(watch("floor")),
        },
      },
      errors: [
        {
          type: "watch",
          text: t("Tama.ruleFloor"),
        },
      ],
    },
  ];
}

//RENOVATIONS
export function renovationDropdownOptions(t) {
  return [
    { value: "without", name: `${t("Renovations.dropdown.without")}` },
    { value: "cosmetic", name: `${t("Renovations.dropdown.cosmetic")}` },
    { value: "standard", name: `${t("Renovations.dropdown.standard")}` },
    { value: "above", name: `${t("Renovations.dropdown.above")}` },
    { value: "luxury", name: `${t("Renovations.dropdown.luxury")}` },
  ];
}
export const renovationMonth = [...Array(19).keys()].map((i) => {
  return { value: i, name: i === 0 ? "--" : i };
});
export function renovationDropdownDetains(t) {
  return [
    { name: "kitchen", title: `${t("Renovations.options.kitchen")}` },
    { name: "livingRoom", title: `${t("Renovations.options.livingRoom")}` },
    {
      name: "showerToilet",
      title: `${t("Renovations.options.bathroomToilet")}`,
    },
    { name: "otherRooms", title: `${t("Renovations.options.otherRooms")}` },
    {
      name: "num_months",
      title: `${t("Renovations.options.renovationMonths")}`,
    },
  ];
}
